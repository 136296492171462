"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    CurrentUser = require('../../shared/CurrentUser'),
    DocumentTitle = require('react-document-title'),
    BS = require('react-bootstrap'),
    FluxBone = require('../../mixins/FluxBone'),
    Widget = require('../../models/Widget'),
    WidgetPartial = require('../../partials/widget/Widget'),
    Cmp = require('../../components'),
    CreateMapWidget = require('../../partials/widget/CreateMapWidget'),
    Link = ReactRouter.Link,
    CancelWidgetEditDialog = require('../../partials/widget/CancelWidgetEditDialog'),
    Validation = require('../../partials/validation');

var WidgetAddEditProjects = React.createClass({displayName: "WidgetAddEditProjects",

    mixins: [
        ReactRouter.Navigation,
        FluxBone.ModelMixin('model', 'sync reset'),
        FluxBone.CollectionMixin('model.listingsCollection', 'sync'),
        React.addons.LinkedStateMixin
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var state = {
            id: this.props.model.id,
            widgetType: parseInt(this.context.router.getCurrentQuery().widgetType),
            projects: this.props.model.listingsCollection.models
        };

        return state;
    },

    getInitialState: function() {
        var inititalState = this.updateState();
        inititalState.showCancelDialog = false;
        return inititalState;
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },
    renderSelectionTitle : function(widgetType){
        var title, desc;
        if (widgetType === 7){
            title = 'Set the location for your widget. Select your market area.';
            desc = 'Use the drop downs to set the location of your market area.';
        } else {
            title = 'Choose the listings.';
            desc = 'Choose the communities or builders to display in your widget.';
        }
        return (
            React.createElement("div", null, 
                React.createElement("h4", {className: "blue-header"}, title), 
                React.createElement("div", {className: "form-group"}, 
                    React.createElement("div", {className: "col-md-8"}, 
                        desc
                    )
                )
            )
        );

    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.model,
            widgetType = state.widgetType,
            id = model && model.id,
            toPrefix = id ? 'WidgetEdit' : 'WidgetAdd',
            backBtnQuery = widgetType === 7 ? {} : {
                widgetType: 'manual'
            },
            defaultAttrs = {
                labelClassName: 'col-md-3',
                wrapperClassName: 'col-md-8'
            },
            title = props.title + ' - ' + model.getWidgetTypeName(widgetType) + ' - Projects';

        return (
            React.createElement("div", null, 
                React.createElement(DocumentTitle, {title: title}), 
                React.createElement(CancelWidgetEditDialog, {show: me.state.showCancelDialog, onHide: me.hideCancelWarning, model: model}), 
                React.createElement(Validation.Form, {ref: "form", horizontal: false, model: props.model, onSubmit: this.saveWidget, forms: ['widget']}, 
                    React.createElement("div", {className: "form-horizontal"}, 
                        React.createElement("div", {className: "row"}, 
                            React.createElement(Link, {to: toPrefix + 'Type', params: {id: model.id}, query: backBtnQuery}, React.createElement(BS.Button, {bsStyle: "link"}, React.createElement("i", {className: "fa fa-arrow-left"}), " Back"))
                         ), 
                         React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-xs-12"}, 
                                React.createElement("h4", null, "Customize your widget")
                            )
                         ), 

                        React.createElement("h4", {className: "blue-header"}, "Give your widget a name."), 
                        React.createElement("p", {className: "help-block"}, "NOTE: This name is not displayed to the public. Name is used to keep track of the widget."), 
                        React.createElement(Validation.Input, React.__spread({},  defaultAttrs, {ref: "name", trigger: "onChange", name: "name", label: "", type: "text", hasFeedback: true})), 

                        this.renderSelectionTitle(widgetType)
                    ), 

                    widgetType === 7 ? (
                        React.createElement(CreateMapWidget, React.__spread({},  this.getWidgetParameters()))
                    ) : (
                        React.createElement(WidgetPartial, React.__spread({},  this.getWidgetParameters()))
                    ), 

                    React.createElement("div", {className: "row"}, 
                        React.createElement("div", {className: "col-md-10"}, 
                            React.createElement("div", {className: "col-md-6"}, 
                                React.createElement(Cmp.Button.Cancel, {onClick: me.showCancelWarning}, "Cancel")
                            ), 
                            React.createElement("div", {className: "col-md-6"}, 
                                React.createElement(Cmp.Button.Save, {className: "pull-right"}, "Next ", React.createElement("i", {className: "fa fa-arrow-right"}))
                            )
                        )
                    )
                )
            )
        );
    },

    validateForm: function(){
        this.refs.form.validate();
    },
    showCancelWarning : function() {
        this.setState({
            showCancelDialog : true
        });
    },
    hideCancelWarning : function() {
        this.setState({
            showCancelDialog : false
        });
    },

    updateModelListings: function(project, selection){
        this.props.model.listingsCollection.reset(selection, {
            silent: true
        });
    },

    getWidgetParameters: function() {
        var me = this,
            props = this.props,
            widget = {
                widgetId: props.model.id || 'new',
                onDeselect: this.updateModelListings,
                onSelect: this.updateModelListings,
                hideFilters: this.state.widgetType !== 7,
                mode: 'create',
                formLinks: {
                    widget: 'model'
                },
                onFormChange: me.validateForm,
                fieldKey: 'widget'
            };

        if (this.state.widgetType !== 7) {
            widget.allowSelection = true;
            widget.selectionLink = this.linkState('projects');
        }

        return widget;
    },

    componentDidUpdate: function(prevProps, prevState) {
        if (prevState.projects !== this.state.projects) {
            this.refs.form.forceUpdate();
        }
    },

    saveWidget: function(e) {
        var me = this,
            model = this.props.model,
            name = this.refs.name.getValue(),
            isNew = !model.id,
            xhr;

        e.preventDefault();

        model.listingsCollection.reset(this.state.projects);
        if (this.state.widgetType === 7) {
            model.set(this.refs.form.getValues());
            xhr = model.save({
                agent: CurrentUser.agentModel.id ? {
                    id: CurrentUser.agentModel.id
                } : undefined
            });
        } else {
            xhr = model.save({
                name: name
            });
        }

        xhr.then(function() {
            if(isNew){
                me.replaceWith('WidgetEditProjects', {
                    id: model.id
                },{
                    widgetType: me.state.widgetType
                });
            }
            me.transitionTo('WidgetEditFinished', {
                id: model.id
            },{
                create: isNew
            });
        });
    }
});
module.exports = WidgetAddEditProjects;
