"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    cx = require('classnames'),
    FluxBone = require('../../mixins/FluxBone'),
    ReactRouter = require('react-router'),
    NavLink = require('../../components/NavLink'),
    ReactCatalyst = require('../../mixins/react-catalyst'),
    DeleteDialog = require('../../partials/DeleteDialog'),
    Pagination = require('../../partials/Pagination'),
    _ = require('underscore'),
    moment = require('moment'),
    Link = ReactRouter.Link,
    toastr = require('toastr');

var LeadList = React.createClass({displayName: "LeadList",
    mixins: [
        FluxBone.ModelMixin('collection', 'sync'),
        ReactCatalyst.LinkedStateMixin
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function(props) {
        var page = parseInt(this.context.router.getCurrentQuery().page, 10) || 1;

        props = props || this.props;

        props.collection.pageNum = page;
        props.collection.fetch();

        return {
            page: page
        };
    },

    componentDidMount: function() {
        React.findDOMNode(this.refs.searchByNameInput).focus();
    },

    onDelete: function(leadId) {
        var lead = this.props.collection.get(leadId);
        this.setState({
            modelToDelete: lead
        });
    },
    afterDelete: function(model) {
        var name = model.toJSON().contactInfo.fullName;

        toastr.success('Success! <br/> Your lead, ' + name + ', has been successfully deleted.');
    },
    onHideDeleteDialog: function() {
        this.setState({
            modelToDelete: undefined
        });
    },

    getInitialState: function() {
        this.updateState();
        return {
            nameFilter: '',
            projectFilter: ''
        };
    },

    getDefaultProps: function() {
        return {
            emptyMessage: 'There are no leads available.'
        };
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function(props) {
        this.setState(this.updateState(props));
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            collection = props.collection,
            leads = props.collection.toJSON(),
            searchInputAttrs = {
                type: 'text',
                labelClassName: 'col-md-4',
                wrapperClassName: 'col-md-8',
                groupClassName: 'row half-gutter'
            };

        return (
            React.createElement("div", null, 
                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-md-9"}, 
                        React.createElement("h1", null, "My Leads"), 
                        React.createElement("p", null, "Below are a list of your leads. Leads are NOT AUTOMATICALLY REGISTERED. Contact the builder to continue with your sales process.")
                    )
                ), 

                React.createElement("div", {className: "row half-gutter form-horizontal"}, 
                    React.createElement("div", {className: "col-sm-6"}, 
                        React.createElement(BS.Input, React.__spread({},  searchInputAttrs, {label: "Search Name", placeholder: "Enter the name of a Lead", ref: "searchByNameInput", valueLink: this.linkState('nameFilter')}))
                    ), 
                    React.createElement("div", {className: "col-sm-6"}, 
                        React.createElement(BS.Input, React.__spread({},  searchInputAttrs, {label: "Search Listing", placeholder: "Enter the name of a Listing", ref: "searchByProjectInput", valueLink: this.linkState('projectFilter')}))
                    )
                ), 

                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-md-7"}, 
                        React.createElement(Pagination, {store: this.props.collection, to: "AgentLeads"})
                    ), 

                    React.createElement("div", {className: "col-md-5 text-right"}, 
                        React.createElement("span", {className: "legend"}, 
                            React.createElement("div", null, React.createElement("b", null, "Legend:")), 
                            React.createElement("div", null, React.createElement("i", {className: "fa fa-search-plus"}), " View Lead"), 
                            React.createElement("div", null, React.createElement("i", {className: "fa fa-trash-o"}), " Delete Lead")
                        )
                    )
                ), 

                React.createElement(BS.Table, {borderless: true, condensed: true, className: "tss-table-vertically-centered"}, 
                    React.createElement("thead", null, 
                    React.createElement("tr", null, 
                        React.createElement("th", null), 
                        React.createElement("th", null, "Lead Name"), 
                        React.createElement("th", null, "Interested In"), 
                        React.createElement("th", null, "Date Received"), 
                        React.createElement("th", null, "Actions")
                    )
                    ), 
                    React.createElement("tbody", null, 
                        collection && _.map(_.filter(_.filter(leads, function(lead) {
                                return !me.state.projectFilter || (lead.listing && lead.listing.name.toLowerCase().indexOf(me.state.projectFilter.toLowerCase()) !== -1);
                            }), function(lead) {
                                return !me.state.nameFilter || (lead.contactInfo && lead.contactInfo.fullName.toLowerCase().indexOf(me.state.nameFilter.toLowerCase()) !== -1);
                            }), function(lead) {

                            var linkParams = {'id': lead.id};
                            return (
                                React.createElement("tr", {key: lead.id}, 
                                    React.createElement("td", {style: {'textAlign': 'center'}}, 
                                        React.createElement("i", {className: lead.hasBeenViewed ? 'fa fa-circle-o' : 'fa fa-exclamation-circle', style: {'fontSize': '16px', 'color': 'grey'}})
                                    ), 
                                    React.createElement("td", null, 
                                        React.createElement(Link, {to: "AgentLead", params: linkParams}, lead.contactInfo && lead.contactInfo.fullName)
                                    ), 
                                    React.createElement("td", null, lead.listing && lead.listing.name), 
                                    React.createElement("td", null, lead.createDate ? moment(new Date(lead.createDate)).format("YYYY-MM-DD hh:mm A") : '-'), 
                                    React.createElement("td", null, 
                                        React.createElement("i", {style: {'cursor': 'pointer'}, onClick: _.bind(me.onDelete, me, lead.id), className: "icon-custom  icon-color-u icon-sm fa fa-trash-o pull-right"}), 
                                        React.createElement(Link, {to: "AgentLead", className: "pull-right", params: linkParams}, 
                                            React.createElement("i", {className: "icon-custom  icon-color-u icon-sm fa fa-search-plus", style: {"marginRight" : "3px"}})
                                        )
                                    )
                                )
                            );
                        })
                    )
                ), 
                props.collection.length === 0 && (
                    React.createElement(BS.Alert, null, 
                        React.createElement("p", {className: "text-center"}, 
                            props.emptyMessage
                        )
                    )
                ), 
                React.createElement(Pagination, {store: this.props.collection, to: "AgentLeads"}), 
                React.createElement(DeleteDialog, {
                        msg: "Please confirm that you would like to DELETE your lead,", 
                        title: "ALERT! You are about to DELETE a lead.", 
                        deleteLabel: "Delete Lead", 
                        modelToDelete: state.modelToDelete, 
                        onHide: me.onHideDeleteDialog, 
                        modelTitle: state.modelToDelete && state.modelToDelete.toJSON().contactInfo.fullName, 
                        onDelete: me.afterDelete})
            )  
        );
    }
});

module.exports = LeadList;
