"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    FluxBone = require('../../mixins/FluxBone'),
    LeadModel = require('../../models/Lead'),
    NavLink = require('../../components/NavLink'),
    SidebarNav = require('../../partials/SidebarNav'),
    ReactRouter = require('react-router'),
    CurrentUser = require('../../shared/CurrentUser'),
    moment = require('moment'),
    Link = ReactRouter.Link,
    _ = require('underscore'),
    LeadDetailsPartial = require('../../partials/lead/LeadDetails'),
    numeral = require('numeral');

var AgentLeadDetails = React.createClass({displayName: "AgentLeadDetails",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            id = params.id;

        this.props.model.clear();
        this.props.model.set('id', id);
        this.props.model.fetch();

        return {
            id: id
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function(){
        return {
            model: new LeadModel()
        };
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.model,
            data = model.toJSON({
                includePromotions: true
            }),
            title = 'Lead Details';

        return (
             React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: 'NCH - ' + title}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Lead Details"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(NavLink, {to: "Dashboard"}, "Dashboard"), 
                            React.createElement(NavLink, {to: "AgentLeads"}, "My Leads")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement("div", {className: "row"}, 
                        React.createElement("div", {className: "col-md-3 md-margin-bottom-40"}, 
                            React.createElement("div", {className: "margin-bottom-20"}, 
                                React.createElement(Link, {to: "AgentLeads"}, React.createElement(BS.Button, {bsStyle: "link"}, React.createElement("i", {className: "fa fa-arrow-left"}), " Back to My Leads"))
                            ), 
                            React.createElement(SidebarNav, null)
                        ), 
                        React.createElement("div", {className: "col-md-9"}, 
                            React.createElement(LeadDetailsPartial, {model: model})
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    }
});
module.exports = AgentLeadDetails;
