"use strict";

var React = require('react'),
    Calendar = require('rc-calendar'),
    Picker = Calendar.Picker,
    DateTimeFormat = require('gregorian-calendar-format'),
    GregorianCalendar = require('gregorian-calendar'),
    Validation = require('../partials/validation'),
    moment = require('moment'),
    cx = require('classnames');


var DatePicker = React.createClass({displayName: "DatePicker",


    getDefaultProps: function() {
        return {
            formatter: new DateTimeFormat('yyyy-MM-dd'),
            name: undefined,
            placeholder: undefined,
            required: false,
            showRequired: true,

            error: undefined
        }
    },

    updateState: function(props) {
        var calendar = new GregorianCalendar();

        this.updateCalendarValue(props, calendar);

        return {
            value: calendar
        }
    },

    updateCalendarValue: function(props, calendar){
        var value = this.getValue(props),
            dateValue;

        if(!value){
            value = Date.now();
        }

        dateValue = moment.utc(value, 'x');

        calendar.set(dateValue.year(), dateValue.month(), dateValue.date());
    },

    getInitialState: function() {
        return this.updateState();
    },

    componentWillReceiveProps: function(newProps) {
        var me = this;
        if (!this.props.disabled && newProps.disabled && newProps.valueLink && newProps.valueLink.requestChange) {
            newProps.valueLink.requestChange('');
        }
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            value = me.getValue(),
            className = cx('input', this.props.className);

        var calendar = React.createElement(Calendar, {orient: ['top', 'left'], onSelect: me.handleCalendarSelect});

        this.updateCalendarValue(props, state.value);
       
        return (
            React.createElement("div", {className: className, "aria-disabled": props.disabled || state.disabled}, 
                React.createElement(Picker, {ref: "picker", formatter: props.formatter, calendar: calendar, onChange: me.onChange, value: value ? state.value : null, disabled: props.disabled || state.disabled}, 
                    React.createElement(Validation.Input, React.__spread({name: props.name},  this.getValidationProperties(), {className: "form-control rc-calendar-picker-input", type: "text", placeholder: props.placeholder}))
                ), 
                React.createElement("i", {className: "icon-append fa fa-calendar", onClick: me.toggle})
            )
        );
    },

    getValidationProperties: function() {
        var props = this.props,
            state = this.state,
            disabled = props.disabled || state.disabled,
            required = props.required && props.showRequired,
            validationProps = {
                bsStyle: !!props.error ? 'error' : props.bsStyle,
                disabled: disabled,
                label: props.label,
                hasFeedback: !!props.error || props.hasFeedback,
                help: props.error || props.help,
                labelClassName: props.labelClassName,
                wrapperClassName: props.wrapperClassName,
                groupClassName: cx(props.groupClassName, {
                    'margin-bottom-0': true,
                    'form-group-required': required && props.showRequired,
                    'hidden': props.hidden
                })
            };

        return validationProps;
    },

    onChange: function(e) {
        if (this.props.valueLink) {
            this.props.valueLink.requestChange(e.time);
        }
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    },

    getValue: function(props) {
        var value;

        props = props || this.props;

        if (props.valueLink) {
            value = props.valueLink.value;
        } else {
            value = props.value;
        }

        return value;
    },

    handleCalendarSelect: function(value) {
        var me = this;
        this.setState({
            value: value
        }, function(){
            if(me.props.onChange){
                me.props.onChange();
            }
        });
    },

    toggle: function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (!this.props.disabled) {
            this.refs.picker.toggle();
        }
    }
});

module.exports = DatePicker;
