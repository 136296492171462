"use strict";

var React = require('react'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    ReactRouterBootstrap = require('react-router-bootstrap'),
    ButtonLink = ReactRouterBootstrap.ButtonLink,
    DashboardItem = require('../../partials/DashboardItem'),

    CurrentUser = require('../../shared/CurrentUser');

var AgentDashboard = React.createClass({displayName: "AgentDashboard",

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        return {};
    },

    getInitialState: function() {
        return this.updateState();
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        return (
            React.createElement("div", null, 
                React.createElement(DocumentTitle, {title: 'NCH - ' + (this.props.user.getEntity().get('isBroker') ? 'Broker' : 'Agent') + ' Dashboard'}), 
                React.createElement("h1", null, "What would you like to do today?"), 
                React.createElement("div", {className: "row"}, 
                    React.createElement(DashboardItem, {blue: true, iconClass: "fa-code", heading: "My Widgets", message: "Access your widgets.", to: "Widgets"}), 

                    React.createElement(DashboardItem, {orange: true, iconClass: "fa-users", heading: "Access My Leads", message: "Access your leads.", to: "AgentLeads"})
                )
            )
        );
    }
});
module.exports = AgentDashboard;
