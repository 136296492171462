"use strict";

var React = require("react"),
  ReactRouter = require("react-router"),
  $ = require("jquery"),
  ReactRouterBootstrap = require("react-router-bootstrap"),
  ButtonLink = ReactRouterBootstrap.ButtonLink,
  BS = require("react-bootstrap"),
  DocumentTitle = require("react-document-title"),
  cx = require("classnames"),
  PageHeader = require("../../partials/PageHeader"),
  PageFooter = require("../../partials/PageFooter"),
  CurrentUser = require("../../shared/CurrentUser"),
  Constants = require("../../constants"),
  UserLogin = require("../../models/UserLogin"),
  UserModel = require("../../models/User"),
  Cmp = require("../../components"),
  Link = ReactRouter.Link,
  Validation = require("../../partials/validation"),
  URIjs = require("urijs");

var Login = React.createClass({displayName: "Login",
  mixins: [ReactRouter.Navigation],
  statics: {
    willTransitionTo: function(transition, params, query) {
      var redirect = Constants.defaultRedirect;

      if (query.redirect) {
        redirect = URIjs(query.redirect);
        redirect = [redirect.path(), undefined, redirect.search(true)];
      }

      if (CurrentUser.isLoggedIn()) {
        transition.redirect.apply(transition, redirect);
      }
    },
    willTransitionFrom: function(transition, me, callback) {
      var path = transition.path,
        isPathForgotPassword = path.indexOf("/forgotpassword") === 0,
        isPathSignup = path.indexOf("/signup") === 0;

      if (
        me &&
        !CurrentUser.isLoggedIn() &&
        !isPathForgotPassword &&
        !isPathSignup
      ) {
        transition.cancel();
        me.goBack();
      } else {
        callback();
      }
    }
  },

  contextTypes: {
    router: React.PropTypes.func
  },

  getDefaultProps: function() {
    return {
      model: new UserLogin()
    };
  },

  updateState: function() {
    var query = this.context.router.getCurrentQuery();
    return {
      success: undefined,
      sessionExpired: !!query.sessionExpired,
      redirect: query.redirect
    };
  },

  getInitialState: function() {
    return this.updateState();
  },

  componentWillMount: function() {
    this.props.model.clear();
  },

  componentWillReceiveProps: function() {
    this.setState(this.updateState());
  },

  render: function() {
    var usernameIcon = React.createElement("i", {className: "fa fa-envelope fa-fw"}),
      passwordIcon = React.createElement("i", {className: "fa fa-lock fa-fw"}),
      message;

    if (this.state.sessionExpired) {
      message =
        "Sorry your session has expired. Please login again to continue.";
    }

    return (
      React.createElement("div", {className: "wrapper"}, 
        React.createElement(DocumentTitle, {title: "NCH - Login"}), 
        React.createElement(PageHeader, null), 
        React.createElement("div", {className: "breadcrumbs"}, 
          React.createElement("div", {className: "container"}, 
            React.createElement("h1", {className: "pull-left"}, "Login"), 
            React.createElement("ul", {className: "pull-right breadcrumb"}, 
              React.createElement(Cmp.NavLink, null, "Home"), 
              React.createElement(Cmp.NavLink, {to: "Login"}, "Login")
            )
          )
        ), 
        React.createElement("div", {className: "container content"}, 
          React.createElement("div", {className: "row"}, 
            React.createElement("div", {className: "col-md-6 col-md-offset-3"}, 
              React.createElement(BS.Panel, {
                className: "panel-split-header margin-bottom-20", 
                header: React.createElement("h2", null, "Log into your New Construction Hub account.")
              }, 
                React.createElement(Validation.Form, {
                  horizontal: false, 
                  ref: "form", 
                  model: this.props.model, 
                  onSubmit: this.onSubmit, 
                  getMessage: this.getMessage, 
                  validateOnLoad: false, 
                  message: message, 
                  success: this.state.success
                }, 
                  React.createElement(Validation.Input, {
                    trigger: "onChange", 
                    addonBefore: usernameIcon, 
                    type: "text", 
                    placeholder: "Email Address", 
                    name: "username"}
                  ), 
                  React.createElement(Validation.Input, {
                    trigger: "onChange", 
                    addonBefore: passwordIcon, 
                    type: "password", 
                    placeholder: "Password", 
                    name: "password"}
                  ), 

                  React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-sm-6"}, 
                      React.createElement(Validation.Input, {
                        type: "checkbox", 
                        useCheckedLink: true, 
                        groupClassName: "margin-bottom-0", 
                        wrapperClassName: "pull-left", 
                        label: "Stay Logged In", 
                        name: "rememberMe"}
                      )
                    ), 
                    React.createElement("div", {className: "col-sm-6"}, 
                      React.createElement(Cmp.Button.Save, {className: "pull-right"}, 
                        "Login"
                      )
                    )
                  )
                ), 

                React.createElement("hr", {className: "clear"}), 

                React.createElement("h4", null, "HELP! I forgot my password."), 
                React.createElement("p", {className: ""}, 
                  React.createElement(Link, {to: "ForgotPassword"}, "CLICK HERE"), " to reset your" + ' ' +
                  "password."
                )
              ), 

              React.createElement(BS.Panel, {className: "text-center"}, 
                React.createElement("h4", null, "Do you need a New Construction Hub account?"), 
                React.createElement("p", null, 
                  React.createElement(ButtonLink, {bsStyle: "primary", type: "submit", to: "Signup"}, 
                    "Create a New Construction Hub account."
                  )
                )
              )
            )
          )
        ), 
        React.createElement(PageFooter, null)
      )
    );
  },

  onSubmit: function(e) {
    this.doLogin();
  },

  doLogin: function() {
    var me = this,
      state = me.state,
      props = me.props,
      redirect = Constants.defaultRedirect,
      query;

    if (me.state.redirect) {
      redirect = URIjs(me.state.redirect);
      redirect = [redirect.path(), undefined, redirect.search(true)];
    }
    if (this.refs.form.isValid()) {
      props.model.login(this.refs.form.getValues()).then(function() {
        var redirectUrl = me.state.redirect;
        if (redirectUrl && redirectUrl.indexOf("agent-shield-1.0")) {
          //hack to allow backend control some redirections
          window.location.href = redirectUrl;
        } else {
          me.transitionTo.apply(me, redirect);
        }
      });
    }
  },

  getMessage: function(type, model_collection, response, options) {
    var message;

    if (type === "error") {
      switch (response.status) {
        case 404:
          message = (
            React.createElement("div", null, 
              React.createElement("h4", null, "Your login failed."), 
              "No Account: There is no account for the email address that you" + ' ' +
              "entered.", 
              React.createElement("br", null), 
              React.createElement("a", {href: "/signup"}, "CLICK HERE"), " to create a new New Construction" + ' ' +
              "Hub account."
            )
          );
          break;
        case 403:
          message = (
            React.createElement("div", null, 
              React.createElement("h4", null, "Your login failed because of a wrong password"), 
              "Enter your password again. Did you forget your password?", 
              React.createElement("br", null), 
              React.createElement("a", {
                href: "#", 
                "data-email": model_collection.get("username"), 
                onClick: this.resetPassword
              }, 
                "CLICK HERE"
              ), " ", 
              "to have your password reset for you."
            )
          );
          break;
        default:
          message = React.createElement("div", null, "Your login failed. Please try again.");
          break;
      }
    }

    return message;
  },

  resetPassword: function(e) {
    var me = this,
      email = $(e.currentTarget).data("email");

    e.preventDefault();

    UserModel.resetPassword({
      email: email
    })
      .fail(function(response) {
        // so the login model can listen in on the errors
        me.props.model.trigger("error", arguments);
      })
      .then(function() {
        me.refs.form.clearMessages();
        me.setState({
          success: (
            React.createElement("div", null, 
              "Success!", 
              React.createElement("br", null), 
              "Your login information was sent to ", email, ". If you do not see it" + ' ' +
              "in your inbox, try checking your junk or spam folder."
            )
          )
        });
      });
  }
});
module.exports = Login;
