"use strict";

var React = require('react'),
    FluxBone = require('../../mixins/FluxBone'),
    $ = require('jquery'),
    cx = require('classnames'),
    Constants = require('../../constants'),
    Cmp = require('../../components'),
    numeral = require('numeral'),
    URI = require('urijs');

var ProjectGallery = React.createClass({displayName: "ProjectGallery",

    imagePlaceholder: Constants.placeholder,

    mixins: [
        FluxBone.CollectionMixin('store', 'sync')
    ],

    getDefaultProps: function() {
        return {
            fixedWidth: false,
            linkToToolbox: false
        }
    },

    getInitialState: function() {
        return {};
    },

    render: function() {
        var me = this,
            props = me.props,
            listingClassName = cx({
                'col-md-12': true,
                'projets-listing': true,
                'projects-listing-fixed-width': props.fixedWidth
            })
        return (
           React.createElement("div", {className: "row"}, 
                React.createElement("div", {className: listingClassName}, 
                    props.store && props.store.map(function(project){
                        var data = project.toJSON(),
                            className = cx('col-lg-15', 'col-sm-4', 'col-xs-6', 'projects-listing-item',{
                                "project-featured": data.hasFeatured
                            }),
                            image = data.image || me.imagePlaceholder;

                        return (
                            React.createElement("div", {className: className, key: data.id}, 
                                React.createElement("div", {className: "thumbnails thumbnail-style"}, 
                                    React.createElement("div", {className: "promotions"}, 
                                        data.hasFeatured && (
                                            React.createElement("i", {className: "fa fa-star featured", title: "Featured"})
                                        ), 
                                        data.hasBuyerSpecial && (
                                            React.createElement("i", {className: "fa fa-usd buyer_specials", title: "Buyer Special"})
                                        ), 
                                        data.hasFlyAndBuySpecial && (
                                            React.createElement("i", {className: "fa fa-paper-plane fly_and_by_specials", title: "Fly & Buy"})
                                        )
                                    ), 
                                    /* <img className="img-responsive" src={image} onError={me.loadPlaceholder}/> */
                                    React.createElement("div", {className: "listing-img", style: { backgroundImage: 'url('+ image +'), url('+ me.imagePlaceholder +')'}, src: image, onError: me.loadPlaceholder, onClick: me.onItemClick.bind(null, project)}), 
                                    React.createElement("div", {className: "caption"}, 
										data.developer && data.developer.id && (
                                        	React.createElement("small", null, "By: ", data.developer.name)
										), 
										React.createElement("h3", null, 
                                            React.createElement("a", {"data-id": data.id, onClick: me.onItemClick.bind(null, project)}, 
                                                React.createElement("span", null, data.name)
                                            )
                                        ), 
                                        React.createElement("p", null, 
                                            data.locationCompact || (data.locationInfo.city.name + ', ' + data.locationInfo.city.state.abbr)
                                        ), 
                                        React.createElement("p", null, 
                                            "From: ", React.createElement("strong", null, data.minPrice && data.minPrice != 0 ? numeral(data.minPrice).format('$0,0[.]00') : 'Please ask')
                                        )
                                    ), 
                                    React.createElement("div", {className: "row"}, 
                                        React.createElement("div", {className: "col-xs-12"}, 
                                            props.linkToToolbox ? (
                                                React.createElement("a", {href: URI(Constants.toolboxHost).addSearch({ API: data.apiKey, bAPI: props.builder ? props.builder.get('apiKey') : undefined }), className: "btn-u btn-u-xs btn-u-default btn-block learnMore", "data-id": data.id, onClick: me.onItemClick.bind(null, project)}, 
                                                    props.linkText || (React.createElement("span", null, "Select"))
                                                )
                                            ) : (
                                                React.createElement("a", {className: "btn-u btn-u-xs btn-u-default btn-block learnMore", "data-id": data.id, onClick: me.onItemClick.bind(null, project)}, 
                                                    props.linkText || (React.createElement("span", null, "View Details"))
                                                )
                                            ), 
                                            React.createElement(Cmp.Tracker, {params: { widgetId: props.widget ? props.widget.id : undefined, agentId: props.widget ? props.widget.agentModel.id : undefined, projectId: data.id, type: 'project-gallery-item'}, timestamp: props.trackerId})
                                        )
                                    )
                                )
                            )
                        );
                    })
                )
           )
        );
    },

    loadPlaceholder: function(e) {
        var img = e.currentTarget;
        img.src = this.imagePlaceholder;
    },

    onItemClick: function(project, e) {
        if (this.props.onItemClick) {
            e.preventDefault();
            this.props.onItemClick(project, e);
        }
    }
});

module.exports = ProjectGallery;
