'use strict';

var React = require('react'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    DocumentTitle = require('react-document-title'),
    $ = require('jquery'),
    cx = require('classnames'),
    CurrentUser = require('../../../shared/CurrentUser'),
    Constants = require('../../../constants'),
    Cmp = require('../../../components'),
    FluxBone = require('../../../mixins/FluxBone'),
    Link = ReactRouter.Link,
    coupons = Constants.coupons,
    numeral = require('numeral');

var SignupAgentUpgrade = React.createClass({displayName: "SignupAgentUpgrade",
    mixins: [ReactRouter.Navigation, FluxBone.ModelMixin('model.agentModel', 'sync reset')],
    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            model: CurrentUser
        };
    },

    updateState: function() {
        return {
            iframeHeight: 400,
            coupon: ''
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    componentWillMount: function() {
        $(window).on('message', this.handleSizeResponse);
    },

    componentWillUnmount: function() {
        $(window).off('message', this.handleSizeResponse);
    },

    componentDidUpdate: function() {
        $(React.findDOMNode(this)).find('.fancybox').fancybox();
    },

    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    renderDiscountTitle: function(discount, price, adjustedPrice) {
        var title,
            isMonthBased = false;

        if (discount) {
            if (typeof discount === 'string') {
                discount = parseInt(discount, 10);
                isMonthBased = true;
            }

            if (isMonthBased) {
                title = discount === 0 ? 'forever' : 'for ' + discount + ' Months';
            }
        }

        return title;
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            optionClassName = 'pricing-v2 membership-pricing ',
            height = '' + state.iframeHeight + 'px',
            defaultLevel = 'FREE',
            entity = props.model.agentModel,
            level = entity.get('agentLevel') || 'FREE',
            coupon = entity.get('coupon'),
            discount = coupon ? coupons[coupon.toUpperCase()] : undefined,
            price = 19,
            adjustedPrice = price,
            priceBasic = 9,
            adjustedPriceBasic = priceBasic,
            discountTitle;

        if (typeof discount === 'string') {
            adjustedPrice = 0;
            adjustedPriceBasic = 0;
        } else if (Number(discount) && discount % 1 === 0) {
            adjustedPrice = price - discount;
            adjustedPriceBasic = priceBasic - discount;
        } else if (Number(discount) && discount % 1 !== 0) {
            adjustedPrice = price - price * discount;
            adjustedPriceBasic = priceBasic - priceBasic * discount;
        }

        if (level === 'PRO') {
            discountTitle = me.renderDiscountTitle(discount, price, adjustedPrice);
            // discountConfirm = me.renderDiscountConfirm(discount, price, adjustedPrice);
        } else if (level === 'BASIC') {
            discountTitle = me.renderDiscountTitle(discount, priceBasic, adjustedPriceBasic);
            // discountConfirm = me.renderDiscountConfirm(discount, priceBasic, adjustedPriceBasic);
        }

        return (
            React.createElement("div", null, 
                React.createElement(DocumentTitle, {title: "NCH - Signup - Agent Upgrade"}), 
                React.createElement("div", {className: "row"}, 
                    level === 'FREE' &&
                        React.createElement("div", {className: "col-xs-12"}, 
                            React.createElement("p", {className: "text-center font-larger"}, 
                                "You currently have a ", React.createElement("strong", null, "FREE Agent Plan"), " which give you access to Agent Portals on community and project websites. To access more features, communities and" + ' ' +
                                "tools to market and sell new construction, please upgrade to one of our paid plans."
                            )
                        ), 
                    React.createElement("div", {className: "col-xs-12 col-md-10 col-md-offset-1"}, 
                        React.createElement("h3", {className: "blue-header text-center margin-bottom-30"}, "Select a plan to continue."), 

                        React.createElement("div", {className: "row pricing-table-v2"}, 
                            React.createElement("div", {className: "col-xs-12 col-sm-6"}, 
                                React.createElement("div", {className: cx(optionClassName, 'pricing-v2-grey', { 'membership-pricing-active': defaultLevel === 'BASIC' }, 'margin-bottom-15')}, 
                                    React.createElement("div", {className: "pricing-v2-head"}, 
                                        React.createElement("h4", {className: "text-center"}, 
                                            "Basic Agent Account", React.createElement("br", null), 
                                            React.createElement("b", null, numeral(adjustedPriceBasic).format('$0,0.00'), "/month"), 
                                            discountTitle && React.createElement("small", null, " ", discountTitle)
                                        )
                                    ), 
                                    React.createElement("ul", {className: "list-unstyled pricing-v2-content"}, 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-check-circle-o"}), " 1 New Construction Widget"), 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-check-circle-o"}), " Basic Branding"), 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-check-circle-o"}), " Basic Customer Support")
                                    )
                                )
                            ), 
                            React.createElement("div", {className: "col-xs-12 col-sm-6"}, 
                                React.createElement("div", {className: cx(optionClassName, 'pricing-v2-blue', { 'membership-pricing-active': defaultLevel === 'PRO' }, 'margin-bottom-15')}, 
                                    React.createElement("div", {className: "pricing-v2-head"}, 
                                        React.createElement("h4", {className: "text-center"}, 
                                            "Premium Agent Account", React.createElement("br", null), 
                                            React.createElement("b", null, numeral(adjustedPrice).format('$0,0.00'), "/month"), 
                                            discountTitle && React.createElement("small", null, " ", discountTitle)
                                        )
                                    ), 
                                    React.createElement("ul", {className: "list-unstyled pricing-v2-content"}, 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-diamond"}), " Unlimited New Construction Widgets"), 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-diamond"}), " Premium Branding"), 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-diamond"}), " Include Profile Photo on Listings"), 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-diamond"}), " Include Phone Number on Listings"), 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-diamond"}), " Premium Customer Support"), 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-diamond"}), " CRM Integration")
                                    )
                                )
                            )
                        ), 

                        React.createElement("div", {className: "row text-center"}, 
                            React.createElement("div", {className: "col-xs-12 col-sm-6 col-sm-offset-3"}, 
                                React.createElement("input", {
                                    className: cx('form-control', 'margin-bottom-15', {
                                        'control-valid': entity.get('coupon') && state.coupon === entity.get('coupon'),
                                        'control-invalid': state.coupon && state.coupon !== entity.get('coupon')
                                    }), 
                                    value: state.coupon, 
                                    onChange: me.onCouponCodeChange, 
                                    placeholder: "Special Code"}
                                )
                            )
                        ), 

                        React.createElement("div", {className: "row text-center margin-bottom-20"}, 
                            React.createElement("div", {className: "col-xs-12 col-sm-6"}, 
                                React.createElement("a", {className: "btn-u btn-u-default btn-block", disabled: level === 'BASIC', onClick: level !== 'BASIC' && this.upgradeAgent.bind(null, 'BASIC')}, 
                                    "Select Basic Agent Account"
                                )
                            ), 
                            React.createElement("div", {className: "col-xs-12 col-sm-6"}, 
                                React.createElement("a", {className: "btn-u btn-u-blue btn-block", onClick: this.upgradeAgent.bind(null, 'PRO')}, "Select Premium Agent Account")
                            )
                        ), 

                        React.createElement("div", {className: "row pricing-table-v2"}, 
                            React.createElement("div", {className: "col-xs-12"}, 
                                React.createElement("div", {className: "pricing-v2 pricing-v2-blue", style: { marginBottom: 0}}, 
                                    React.createElement("a", {onClick: me.toggleLearnMore, className: "btn btn-link btn-block", style: { textAlign: 'left'}}, 
                                        React.createElement("i", {className: "fa fa-fw fa-diamond"}), " Compare Plans. Click Here."
                                    ), 
                                    state.learnMoreExpanded &&
                                        React.createElement("div", {style: { padding: '0px 10px'}}, 
                                            React.createElement("p", null, 
                                                "The comparison of features between the Basic Agent Accounts and Premium Agent Accounts are displayed above. One of the main differences is the added" + ' ' +
                                                "branding for Premium Agents in the widgets. Below are example screenshots between the Basic and Premium."
                                            ), 
                                            React.createElement("a", {href: Constants.imageRoot + 'NCH_AgentBrandingComparison.png', className: "fancybox", title: "Agent Branding Comparison"}, 
                                                React.createElement("img", {src: Constants.imageRoot + 'NCH_AgentBrandingComparison.png', className: "img-responsive"})
                                            ), 
                                            React.createElement("div", {className: "row text-center"}, 
                                                React.createElement("div", {className: "col-xs-12 col-sm-6"}, 
                                                    React.createElement("a", {className: "btn-u btn-u-default btn-block", disabled: level === 'BASIC', onClick: level !== 'BASIC' && this.upgradeAgent.bind(null, 'BASIC')}, 
                                                        "Select Basic Agent Account"
                                                    )
                                                ), 
                                                React.createElement("div", {className: "col-xs-12 col-sm-6"}, 
                                                    React.createElement("a", {className: "btn-u btn-u-blue btn-block", onClick: this.upgradeAgent.bind(null, 'PRO')}, "Upgrade to Premium Agent")
                                                )
                                            ), 
                                            React.createElement("div", {className: "text-center"}, 
                                                React.createElement("a", {className: "btn btn-link blue-text", onClick: me.showPremiumAgentForm}, 
                                                    React.createElement("i", {className: "fa fa-fw fa-question-circle"}), " Still have questions about Premium Agent Accounts? CLICK HERE"
                                                )
                                            )
                                        )
                                )
                            )
                        ), 

                        this.props.Footer && React.createElement(this.props.Footer, {trackerId: Constants.trackerPrefix + 'signup-agent-upgrade'})
                    )
                ), 

                React.createElement(BS.Modal, {show: !!state.showPremiumAgentForm, onHide: me.closePremiumAgentForm, className: "modal-preview modal-lg"}, 
                    React.createElement(BS.Modal.Header, {closeButton: true, style: { zIndex: 1, position: 'relative'}}), 
                    React.createElement(BS.Modal.Body, null, 
                        React.createElement("div", null, 
                            React.createElement("iframe", {
                                key: "header-contact-form", 
                                allowTransparency: "true", 
                                src: "https://secure.jotformpro.com/form/60305098059153", 
                                frameBorder: "0", 
                                style: { width: '100%', height: height, border: 'none', marginTop: '-45px'}, 
                                scrolling: "no"}
                            )
                        )
                    )
                )
            )
        );
    },

    onCouponCodeChange: function(e) {
        var code = e.target.value;

        this.setState({
            coupon: code
        });

        this.props.model.getEntity().set(
            {
                coupon: coupons[code.toUpperCase()] ? e.target.value : undefined
            },
            {
                silent: true
            }
        );
    },

    upgradeAgent: function(level) {
        this.props.model.agentModel.set('agentLevel', level || 'PRO');
        this.transitionTo('UserMembershipBillingInfo');
    },

    showPremiumAgentForm: function() {
        this.setState({
            showPremiumAgentForm: true
        });
    },

    closePremiumAgentForm: function() {
        this.setState({
            showPremiumAgentForm: false
        });
    },

    handleSizeResponse: function(e) {
        if (!e) {
            return;
        }

        var originalEvent = e.originalEvent,
            data = e.originalEvent.data;
        if (originalEvent.origin == 'https://secure.jotformpro.com') {
            data = data.split(':');
            if (data[0] === 'setHeight') {
                this.setState({
                    iframeHeight: data[1]
                });
            }
        }
    },

    toggleLearnMore: function() {
        this.setState({
            learnMoreExpanded: !this.state.learnMoreExpanded
        });
    }
});

module.exports = SignupAgentUpgrade;
