"use strict";

var React = require("react/addons"),
  ReactRouter = require("react-router"),
  Constants = require("../../../constants"),
  CurrentUser = require("../../../shared/CurrentUser"),
  DocumentTitle = require("react-document-title"),
  BS = require("react-bootstrap"),
  FluxBone = require("../../../mixins/FluxBone"),
  Widget = require("../../../models/Widget"),
  Cmp = require("../../../components"),
  ContactWebmaster = require("../../../models/Developer"),
  Validation = require("../../../partials/validation"),
  $ = require("jquery"),
  _ = require("underscore"),
  Link = ReactRouter.Link;

var CompanyImage = React.createClass({displayName: "CompanyImage",
  contextTypes: {
    router: React.PropTypes.func
  },

  mixins: [ReactRouter.Navigation],

  getDefaultProps: function() {
    return {
      backBtnTo: "",
      backBtnLabel: "Back to my Listings",
      nextBtnLabel: "Finish",
      backBtnParams: undefined,
      saveText: "Save"
    };
  },

  getInitialState: function() {
    return {
      updateImage: false
    };
  },

  componentWillMount: function() {
    var model = this.props.model;
    model.validation = model.imageValidation;
  },

  componentWillUnmount: function() {
    var model = this.props.model;
    model.validation = model.constructor.prototype.validation;
  },

  render: function() {
    var me = this,
      props = me.props,
      state = me.state,
      model = props.model,
      listingImage = props.model.get("image");

    if (listingImage) {
      listingImage = listingImage.link;
    }
    return (
      React.createElement("div", null, 
        React.createElement(Validation.Form, {
          ref: "form", 
          model: props.model, 
          className: "form", 
          onSubmit: this.onSaveClick, 
          handleDirty: true
        }, 
          props.backBtnTo && (
            React.createElement("div", {className: "row"}, 
              React.createElement(Link, {
                to: props.backBtnTo, 
                params: 
                  props.backBtnParams || this.context.router.getCurrentParams()
                
              }, 
                React.createElement(BS.Button, {bsStyle: "link"}, 
                  React.createElement("i", {className: "fa fa-arrow-left"}), " ", props.backBtnLabel
                )
              )
            )
          ), 
          React.createElement("div", {className: "col-xs-12"}, 
            React.createElement("h4", null, "Upload your company logo.."), 
            React.createElement("p", null, 
              "This will be the image and thumbnail that shows up in widgets. You" + ' ' +
              "should choose an image that best represents your listing."
            ), 
            props.model && (
              React.createElement("div", {className: "row"}, 
                React.createElement("div", {className: "col-xs-3"}, 
                  React.createElement(Cmp.ImageThumbnail, {
                    showPlaceholder: true, 
                    update: state.updateImage, 
                    onChange: me.onImageChange, 
                    className: "profile-image listing-image", 
                    innerClassName: "profile-image-inner", 
                    src: listingImage, 
                    background: true}
                  )
                ), 
                React.createElement("div", {className: "col-xs-9"}, 
                  React.createElement("div", {className: "text-center"}, 
                    React.createElement("h3", {className: "blue-text"}, 
                      "Photo should be 135 pixels wide by 135 pixels high."
                    ), 
                    React.createElement("p", null, "All other sizes will automatically be cropped.")
                  ), 
                  React.createElement(Cmp.Picker.File, {
                    ref: "filePicker", 
                    dropZoneText: "To upload your image, drag an image from your desktop here or", 
                    triggerSync: true, 
                    groupClassName: "margin-top-20", 
                    name: "listingImage", 
                    trigger: "onChange", 
                    url: props.model.imageUrl(), 
                    label: "", 
                    model: props.model, 
                    onComplete: this.onFileUpload, 
                    onBeforeUpload: this.onBeforeFileUpload, 
                    mime_types: [Constants.imageMimeTypes]}
                  )
                )
              )
            )
          ), 
          React.createElement(Cmp.ValidationMessage, null), 
          React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
            React.createElement(Cmp.Button.Save, {className: "pull-right"}, 
              props.saveText
            )
          )
        )
      )
    );
  },

  onSaveClick: function() {
    var me = this,
      props = me.props,
      model = props.model,
      filePicker = me.refs.filePicker;

    if (filePicker.hasFiles()) {
      filePicker.startUpload();
    } else {
      me.onSave();
    }
  },

  onSave: function() {
    var me = this,
      props = me.props;

    if (props.onSave) {
      props.onSave();
    } else if (props.backBtnTo) {
      me.transitionTo(
        props.backBtnTo,
        props.backBtnParams || me.context.router.getCurrentParams()
      );
    } else {
      me.setState({
        updateImage: true
      });
      me.refs.form.forceUpdate();
    }
  },

  onImageChange: function() {
    var me = this;

    me.setState({
      updateImage: false
    });
    me.refs.form.forceUpdate();
  },

  onFileUpload: function(success) {
    var me = this;
    me.props.model.fetch().then(function() {
      me.onSave();
    });
  }
});
module.exports = CompanyImage;
