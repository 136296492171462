"use strict";

var React = require('react'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    Link = ReactRouter.Link,
    Constants = require('../../../constants'),
    Cmp = require('../../../components'),
    FluxBone = require('../../../mixins/FluxBone'),
    CurrentUser = require('../../../shared/CurrentUser'),
    CrmIntegrationModel = require('../../../models/CrmIntegration'),
    $ = require('jquery'),
    Validation = require('../../../partials/validation');

var CrmIntegration = React.createClass({displayName: "CrmIntegration",

    mixins: [
        FluxBone.ModelMixin('model.agentModel', 'sync change', 10),
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function(props) {
        props = props || this.props;

        return {
            iframeHeight: 500
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        return {
            model: undefined
        };
    },

    componentDidMount: function() {
        $(window).on('message', this.handleSizeResponse);
    },

    componentWillUnmount: function() {
        $(window).off('message', this.handleSizeResponse);
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function(props) {
        this.setState(this.updateState(props));
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            height = '' + state.iframeHeight + 'px',
            data = props.model.agentModel.toJSON();

        return (
            React.createElement("div", null, 
                React.createElement("h1", null, "CRM Integration:"), 
                data.agentLevel === 'BASIC' ? (
                    React.createElement("div", null, 
                        React.createElement(BS.Alert, {bsStyle: "info", className: "alert-with-icon"}, 
                            React.createElement("img", {src: Constants.imageRoot + 'PremiumIcon.png'}), 
                            React.createElement("div", null, 
                                React.createElement("h5", null, "CRM Integration is a Premium Feature."), 
                                React.createElement("p", null, React.createElement(Link, {to: "UserMembership"}, "Click Here"), " to learn more about Premium Agent Accounts.")
                            )
                        ), 

                        React.createElement("h3", null, "Save time by sending your leads directly to your CRM"), 
                        React.createElement("p", null, "To help you work more efficiently, New Construction Hub can send your leads directory into your Customer Relationship Management software. Contact us if you have any questions about CRM integration."), 

                        React.createElement("h3", null, "Direct integrations:"), 
                        React.createElement("p", null, "NCH has a direct integration with the CRM systems below."), 
                        React.createElement("div", {className: "row margin-bottom-10"}, 
                            React.createElement("div", {className: 'col-xs-3 inline-list-item cursor-auto'}, 
                                React.createElement("img", {src: Constants.imageRoot + 'logo/AS_CRM_Logo_LionDesk.png', className: "img-responsive"}), 
                                React.createElement("p", null, "LionDesk")
                            ), 
                            React.createElement("div", {className: 'col-xs-3 inline-list-item cursor-auto'}, 
                                React.createElement("img", {src: Constants.imageRoot + 'logo/AS_CRM_Logo_REfindly.png', className: "img-responsive"}), 
                                React.createElement("p", null, "REfindly")
                            ), 
                            React.createElement("div", {className: 'col-xs-3 inline-list-item cursor-auto'}, 
                                React.createElement("img", {src: Constants.imageRoot + 'logo/AS_CRM_Logo_Contactually.png', className: "img-responsive"}), 
                                React.createElement("p", null, "Contactually")
                            )
                        ), 

                        React.createElement("div", {className: "margin-top-10 margin-bottom-20"}, 
                            React.createElement(Link, {to: "UserMembership"}, React.createElement("img", {src: Constants.imageRoot + 'NCH_CRM_BTN_UpgradePremiumAgent.png'}))
                        ), 

                        React.createElement("h3", null, "Integrations through Zapier:"), 
                        React.createElement("p", null, "Zapier is a 3rd party service that transfers data from one application to another. NCH can send your lead to Zapier supported CRM systems. Below is a list of Zapier supported CRM systems. Zapier may charge a fee for some systems. Please check Zapier.com for their full list of integrations and fees."), 
                        React.createElement("img", {src: Constants.imageRoot + 'Zapier_CRMIcons.png'})
                    )
                ): (
                    React.createElement("div", null, 
                        React.createElement("p", null, "To help you work more efficiently, New Construction Hub can send your leads directory into your Customer Relationship Management software. The CRM systems that we currently integrate with are listed below. If your CRM system is not listed, complete the form below and we will work to integrate your CRM system."), 

                        data.broker && data.broker.id ? (
                            React.createElement(BS.Alert, {bsStyle: "warning"}, 
                                "Your account is part of a Brokerage, please contact your broker, or fill out the form below for help with your CRM integration."
                            )
                        ) : (
                            React.createElement("div", {style: { "zIndex": 1, "position": "relative"}}, 
                                React.createElement("h3", null, "Select your CRM System:"), 
                                React.createElement("div", {className: "row margin-bottom-10"}, 
                                    React.createElement("div", {className: 'col-xs-3 inline-list-item' + (data.crmType === 'DEFAULT' ? '  inline-list-item-selected' : ''), onClick: me.setType.bind(null, 'DEFAULT')}, 
                                        React.createElement("img", {src: Constants.imageRoot + 'logo/AS_CRM_Logo_Default.png', className: "img-responsive"}), 
                                        React.createElement("p", null, "No CRM")
                                    ), 
                                    React.createElement("div", {className: 'col-xs-3 inline-list-item' + (data.crmType === 'LIONDESK' ? '  inline-list-item-selected' : ''), onClick: me.setType.bind(null, 'LIONDESK')}, 
                                        React.createElement("img", {src: Constants.imageRoot + 'logo/AS_CRM_Logo_LionDesk.png', className: "img-responsive"}), 
                                        React.createElement("p", null, "LionDesk")
                                    ), 
                                    React.createElement("div", {className: 'col-xs-3 inline-list-item' + (data.crmType === 'REFINDLY' ? '  inline-list-item-selected' : ''), onClick: me.setType.bind(null, 'REFINDLY')}, 
                                        React.createElement("img", {src: Constants.imageRoot + 'logo/AS_CRM_Logo_REfindly.png', className: "img-responsive"}), 
                                        React.createElement("p", null, "REfindly")
                                    ), 
                                    React.createElement("div", {className: 'col-xs-3 inline-list-item' + (data.crmType === 'ZAPIER' ? '  inline-list-item-selected' : ''), onClick: me.setType.bind(null, 'ZAPIER')}, 
                                        React.createElement("img", {src: Constants.imageRoot + 'logo/AS_CRM_Logo_Zapier.png', className: "img-responsive"}), 
                                        React.createElement("p", null, "Zapier")
                                    ), 
                                    React.createElement("div", {className: 'col-xs-3 inline-list-item' + (data.crmType === 'CONTACTUALLY' ? '  inline-list-item-selected' : ''), onClick: me.setType.bind(null, 'CONTACTUALLY')}, 
                                        React.createElement("img", {src: Constants.imageRoot + 'logo/AS_CRM_Logo_Contactually.png', className: "img-responsive"}), 
                                        React.createElement("p", null, "Contactually")
                                    )
                                ), 

                                data.crmType === 'DEFAULT' && (
                                    React.createElement(Validation.Form, {ref: "form", model: props.model.agentModel, className: "form-horizontal", onSubmit: me.onSaveClick, validateOnly: ['crmEmail', 'crmUserKey']}, 
                                        React.createElement("p", null, React.createElement("i", {className: "fa fa-1-5x fa-question-circle blue-text"}), " ", React.createElement("a", {href: "//newconstructionhub.zendesk.com/?crmhelp", target: "_blank"}, "CLICK HERE"), " for instructions for CRM Integration."), 
                                        React.createElement(Validation.Input, {type: "hidden", name: "crmEmail"}), 
                                        React.createElement(Validation.Input, {type: "hidden", name: "crmUserKey"}), 
                                        React.createElement(Cmp.ValidationMessage, null), 
                                        React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                                            React.createElement(Cmp.Button.Cancel, {to: "UserAccountDashboard"}), 
                                            React.createElement(Cmp.Button.Save, {className: "pull-right"})
                                        )
                                    )
                                ), 

                                data.crmType === 'REFINDLY' && (
                                    React.createElement(Validation.Form, {ref: "form", model: props.model.agentModel, className: "form-horizontal", onSubmit: me.onSaveClick, validateOnly: ['crmEmail', 'crmUserKey']}, 
                                        React.createElement("p", null, React.createElement("i", {className: "fa fa-1-5x fa-question-circle blue-text"}), " ", React.createElement("a", {href: "//newconstructionhub.zendesk.com/?crmhelp", target: "_blank"}, "CLICK HERE"), " for instructions for CRM Integration."), 
                                        React.createElement(Validation.Input, {required: true, name: "crmEmail", label: "Enter your Refindly CRM Routing Email Address:", type: "text", labelClassName: "col-md-5", wrapperClassName: "col-md-7"}), 
                                        React.createElement(Cmp.ValidationMessage, null), 
                                        React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                                            React.createElement(Cmp.Button.Cancel, {to: "UserAccountDashboard"}), 
                                            React.createElement(Cmp.Button.Save, {className: "pull-right"})
                                        )
                                    )
                                ), 

                                data.crmType === 'LIONDESK' && (
                                    React.createElement(Validation.Form, {ref: "form", model: props.model.agentModel, className: "form-horizontal", onSubmit: me.onSaveClick, validateOnly: ['crmEmail', 'crmUserKey']}, 
                                        React.createElement("p", null, React.createElement("i", {className: "fa fa-1-5x fa-question-circle blue-text"}), " ", React.createElement("a", {href: "//newconstructionhub.zendesk.com/?crmhelp", target: "_blank"}, "CLICK HERE"), " for instructions for CRM Integration."), 
                                        React.createElement(Validation.Input, {required: true, name: "crmUserKey", label: "Enter your LionDesk CRM User Key:", type: "text", labelClassName: "col-md-5", wrapperClassName: "col-md-7"}), 
                                        React.createElement(Cmp.ValidationMessage, null), 
                                        React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                                            React.createElement(Cmp.Button.Cancel, {to: "UserAccountDashboard"}), 
                                            React.createElement(Cmp.Button.Save, {className: "pull-right"})
                                        )
                                    )
                                ), 

                                data.crmType === 'CONTACTUALLY' && (
                                    React.createElement(Validation.Form, {ref: "form", model: props.model.agentModel, className: "form-horizontal", onSubmit: me.onSaveClick, validateOnly: ['crmEmail', 'crmUserKey']}, 
                                        React.createElement("p", null, React.createElement("i", {className: "fa fa-1-5x fa-question-circle blue-text"}), " ", React.createElement("a", {href: "//newconstructionhub.zendesk.com/?crmhelp", target: "_blank"}, "CLICK HERE"), " for instructions for CRM Integration."), 
                                        props.model.agentModel.get('defaultCrmType') === 'CONTACTUALLY' ? (
                                            React.createElement("p", null, "Your account is currently connected to Contactually. When you receive leads, we will automatically forward them to Contactually as a new Contact.")
                                        ) : (
                                            React.createElement("div", null, 
                                                React.createElement("p", null, "To start sending leads to Contactually, you'll need to authorize New Construction Hub to use your Contactually account. Click the \"Authorize to use Contactually\" button below to go to the Contactually website to authorize New Construction Hub to send your leads into Contactually."), 
                                                React.createElement("p", {className: "text-center"}, 
                                                    React.createElement("a", {className: "btn-u btn-u-blue", href: Constants.CONTACTUALLY_URL, target: "_blank"}, "Authorize to use Contactually")
                                                )
                                            )
                                        ), 
                                        React.createElement(Validation.Input, {type: "hidden", name: "crmEmail"}), 
                                        React.createElement(Validation.Input, {type: "hidden", name: "crmUserKey"}), 
                                        React.createElement(Cmp.ValidationMessage, null), 
                                        React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                                            React.createElement(Cmp.Button.Cancel, {to: "UserAccountDashboard"}), 
                                            React.createElement(Cmp.Button.Save, {className: "pull-right"})
                                        )
                                    )
                                ), 

                                React.createElement(BS.Modal, {show: !!state.showZapierContactForm, onHide: me.hideZapierContactForm, className: "modal-preview modal-lg"}, 
                                    React.createElement(BS.Modal.Header, {closeButton: true, style: { position: 'relative', zIndex: 1}}, "Activate Zapier"), 
                                    React.createElement(BS.Modal.Body, null, 
                                        React.createElement("div", null, 
                                            React.createElement("iframe", {key: "servicelevel-form", allowTransparency: "true", src: "https://secure.jotformpro.com/form/60833692711154", frameBorder: "0", style: { width:'100%', height:height, border:'none', "marginTop": "0", "marginLeft": "0"}, scrolling: "no"})
                                        )
                                    )
                                ), 
                                false && data.crmType === 'ZAPIER' && (
                                    React.createElement("div", null, 
                                        React.createElement("iframe", {key: "servicelevel-form", allowTransparency: "true", src: "https://secure.jotformpro.com/form/60833692711154", frameBorder: "0", style: { width:'100%', height:height, border:'none', "marginTop": "0", "marginLeft": "0"}, scrolling: "no"})
                                    )
                                )
                            )
                        )
                    )
                ), 
                React.createElement("hr", null), 
                React.createElement("iframe", {key: "crmint-form", allowTransparency: "true", src: "https://secure.jotformpro.com/form/52766377216160", frameBorder: "0", style: { width:'100%', height:height, border:'none', "marginTop": "0px", "marginLeft": "-40px"}, scrolling: "no"})
            )
        );
    },

    showContactForm: function(e) {
        if (e) {
            e.preventDefault();
        }

        if (window.FreshWidget && $(FreshWidget.iframe()).is(':visible')) {
            FreshWidget.hide();
        } else if (window.FreshWidget) {
            FreshWidget.show();
            $(FreshWidget.iframe()).attr('scrolling', 'no');
        } else if (window.zE) {
            zE.activate();
        }
    },

    setType: function(type) {
        this.props.model.agentModel.set({
            crmType: type,
            crmEmail: type === 'DEFAULT' ? '' : this.props.model.agentModel.get('crmEmail'),
            crmUserKey: type === 'DEFAULT' ? '' : this.props.model.agentModel.get('crmUserKey')
        });

        if (type === 'ZAPIER') {
            this.setState({
                showZapierContactForm: true
            });
        }
    },

    hideZapierContactForm: function() {
        this.setState({
            showZapierContactForm: false
        });
    },

    onSaveClick: function() {
        this.props.model.agentModel.save(this.refs.form.getValues(), {
            fields: ['crmType', 'crmEmail', 'crmUserKey']
        });
    },

    handleSizeResponse: function(e) {
        var originalEvent = e.originalEvent,
            data = e.originalEvent.data;
        if (originalEvent.origin == 'https://secure.jotformpro.com') {
            data = data.split(':');
            if (data[0] === 'setHeight') {
                this.setState({
                    iframeHeight: data[1]
                });
            }
        }
    }
});

module.exports = CrmIntegration;
