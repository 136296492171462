"use strict";

var React = require('react/addons'),
    BS = require('react-bootstrap'),
    $ = require('jquery');

var DeleteDialog = React.createClass({displayName: "DeleteDialog",
    getDefaultProps: function() {
        return {
            modelToDelete: undefined,
            msg: 'Are you sure you want to delete',
            title: 'Warning',
            successMessage: 'Success!',
            deleteLabel: 'Remove',
            deleteBsStyle: 'warning',
            dismissSuccessAfter: 3000,
            deleteMethod: 'destroy'
        };
    },
    getInitialState: function() {
        return {
            showDialog: false,
            deleted: false
        };
    },
    closeDeleteDialog: function() {
        var props = this.props;
        this.setState({
            showDialog: false
        });

        if ($.isFunction(props.onHide)) {
            props.onHide();
        }
    },
    componentWillReceiveProps: function(nextProps) {
        this.setState({
            showDialog: !!nextProps.modelToDelete
        });
    },
    removeModel: function() {
        var me = this,
            model = me.props.modelToDelete,
            jqXHR = model[me.props.deleteMethod]();

        jqXHR && jqXHR.then(function() {
            me.closeDeleteDialog();
            me.setState({
                deleted: true
            });
            if (me.props.onDelete) {
                me.props.onDelete(model);
            }
        });
    },
    render: function() {
        var me = this,
            props = me.props,
            msg = props.msg;

        if(props.modelTitle) {
            msg = msg + ' ' + props.modelTitle + '.';
        }

        return (
          React.createElement("div", null, 
            this.props.children && this.state.deleted && (
              React.createElement(BS.Alert, {bsStyle: "success", onDismiss: this.onMessageDismiss, dismissAfter: this.props.dismissSuccessAfter}, 
                  this.props.successMessage
              )
            ), 
            this.props.children, 
            React.createElement(BS.Modal, {show: me.state.showDialog, onHide: me.closeDeleteDialog}, 
                React.createElement(BS.Modal.Header, {closeButton: true}, 
                    React.createElement(BS.Modal.Title, null, props.title)
                ), 
                React.createElement(BS.Modal.Body, null, 
                    msg
                ), 
                React.createElement(BS.Modal.Footer, null, 
                    React.createElement(BS.Button, {className: "pull-left", onClick: me.closeDeleteDialog}, "Cancel"), 
                    React.createElement(BS.Button, {bsStyle: props.deleteBsStyle, className: "pull-right", onClick: me.removeModel}, props.deleteLabel)
                )
            )
          )
      );
    },

    onMessageDismiss: function() {
        this.setState({
            deleted: false
        });
    }

});
module.exports = DeleteDialog;
