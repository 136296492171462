"use strict";

var ModelClass = require('./Model'),
    ProjectImage = require('./ProjectImage'),
    Constants = require('../constants'),
    $ = require('jquery');

var TemplateImage = ModelClass.extend({
    urlBase: Constants.restPath + "/template-images",
    urlRoot: function() {
        var url = Constants.restPath + "/template-images",
            listing = this.get('listing') || {},
            listingId = listing.id;

        // if(listingId) {
        //     url = url + '/' + listingId;
        // }

        return url;
    },
    idAttribute: 'id',
    validation: {
        'listing.id': {
            required: true
        },
        templateKey: {
            required: true
        },
        imageKey: {
            required: true
        },
        fileName: {
            required: true
        }
        // files: function(value, attr, computedState) {
        //     var fileName;
        //     if (attr === 'files'){
        //         var errorMsg = 'File is required';

        //         if ($.isArray(value)) {
        //             if (!value.length) {
        //                 return errorMsg;
        //             }
        //             var item = value[0];

        //             if (!item || (!item.name && !item.link)) {
        //                 return errorMsg;
        //             } else {
        //                 fileName = item.link;
        //             }

        //         } else {
        //             if (!value || !value.name) {
        //                 return errorMsg;
        //             } 
        //             fileName = value.name;
        //         }


        //         if (fileName && !/\.(png)|(jpg)|(gif)$/i.test(fileName)) {
        //            return 'File type is invalid. Only image file allowed: JPG, PNG, GIF.';
        //         }                
        //     }
        // }

    },
    defaults: function() {
        return {
            id: undefined,
            listing: {
                id: undefined
            },
            // email brochure
            templateKey: 'email',
            // FIRST_IMAGE SECOND_IMAGE THIRD_IMAGE
            imageKey: 'FIRST_IMAGE',
            fileName: '',
            link: ''
        };
    },

    toJSON: function(options) {
        var data = ModelClass.prototype.toJSON.apply(this, arguments);

        options = options || {};

        if (options.url) {
            delete data.files;
        }

        data.thumbLink && (data.thumbLink += '?nc=' + new Date().getTime());
        data.link && (data.link += '?nc=' + new Date().getTime());

        return data;
    },

    parse: function(response) {
        if (response && response.link) {
            // response.fileName = response.originalFileName || response.link.match(/[^\\/]+$/)[0];
            response.files = [{
                link: response.link + '?nc=' + new Date().getTime(),
                name: response.fileName
            }];
        }

        return response;
    }
});

module.exports = TemplateImage;
