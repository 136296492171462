"use strict";

var ModelClass = require('./Model'),
    CurrentUser = require('../shared/CurrentUser'),
    User = require('./User');

var UserForgot = ModelClass.extend({
    idAttribute: 'id',
    validation: {
        email: {
            required: true,
            pattern: 'email'
        }
    },
    defaults: function() {
        return {
            email: undefined
        };
    },
    fetch: function() {},
    save: function() {},
    sync: function() {},

    resetPassword: function(attrs, options) {
        var me = this;

        attrs = attrs || {};
        this.set(attrs);

        return User.resetPassword({
            email: attrs.email
        }).fail(function(response) {
            // so the login model can listen in on the errors
            me.trigger('error', me, response);
        });
    }
});

module.exports = UserForgot;
