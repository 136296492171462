"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    FluxBone = require('../mixins/FluxBone'),
    Metros = require('../collections/Metros'),
    Validation = require('../partials/validation');

var MetroSelect = React.createClass({displayName: "MetroSelect",

    mixins: [
        FluxBone.CollectionMixin('store', 'sync')
    ],

    getDefaultProps: function() {
        return {
            store: new Metros(),
            defaultText: 'Select',
            trigger: "onChange",
            checkOnSync: true,
            showNone: false
        };
    },

    getInitialState : function(){
        return {};
    },

    componentWillMount: function() {
        if (this.props.countryId || this.props.stateId) {
            this.props.stateId && this.props.store.fetch({
                stateId: this.props.stateId,
                countryId: this.props.countryId,
                hasProjects: !!this.props.hasProjects
            });
        }
    },

    componentWillReceiveProps: function(props) {
        var me = this;

        if (props.disabled && props.disabled !== this.props.disabled && props.valueLink) {
            props.valueLink.requestChange('');
        }

        this.setState({
            disabled : !props.countryId && !props.stateId
        });

        if (!props.countryId && !props.stateId && me.getValue()) {
            this.resetDeferred();
        } else if (props.countryId !== this.props.countryId || props.stateId !== this.props.stateId) {
            this.clearReset();
            props.stateId && this.props.store.fetch({
                stateId: props.stateId,
                countryId: props.countryId,
                hasProjects: this.props.hasProjects
            }).then(function() {
                var value = me.getValue();

                if (value && me.props.checkOnSync && me.props.valueLink && !me.props.store.get(value)) {
                    me.props.valueLink && me.props.valueLink.requestChange('');
                }
            });
        }
    },

    render: function() {
        var props = this.props,
            state = this.state;

        return (
            React.createElement(Validation.Input, React.__spread({ref: "input", label: "Metros:"},  props, {type: "select", disabled: state.disabled || props.disabled}), 
                React.createElement("option", {value: ""}, props.defaultText), 
                props.showNone && (
                    React.createElement("option", {value: "NONE"}, "No Metro")
                ), 
                props.store.map(function(metro){
                    var data = metro.toJSON();
                    return (
                        React.createElement("option", {key: data.id, value: data.id}, data.dmaRegion)
                    );
                })
            )
        );
    },

    getDisplayValue: function() {
        var value = this.refs.input.getValue(),
            id = value ? parseInt(value) : undefined,
            model,
            displayValue;

        if (id) {
            model = this.props.store.get(id);
        }

        if (model) {
            displayValue = model.get('dmaRegion');
        }

        return displayValue
    },

    resetDeferred: function() {
        var me = this;
        this.clearReset();
        this.resetTimeout = setTimeout(function() {
            me.props.store.reset();
            me.props.valueLink && me.props.valueLink.requestChange('');
        }, 100)
    },

    clearReset: function() {
        clearTimeout(this.resetTimeout);
        delete this.resetTimeout;
    },

    getValue: function() {
        return this.refs.input ? this.refs.input.getValue() : undefined;
    }
});

module.exports = MetroSelect;
