"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    Agent = require('../models/Agent');

var Agents = Collection.extend({
    url: Agent.prototype.urlRoot,
    model: Agent,
    filters: [
        'agentName',
        'email',
        'brokerName',
        'country',
        'state',
        'metro_area',
        'city',
        'broker_id',
        'orderBy'
    ],

    getFilterValues: function() {
        var values = Collection.prototype.getFilterValues.apply(this, arguments);

        if (values.orderBy) {
            values.orderBy = values.orderBy.split(' ');
            values.dir = values.orderBy[1];
            values.orderBy = values.orderBy[0];
        } else {
            values.orderBy = '';
            values.dir = 'ASC';
        }

        return values;
    }
});

module.exports = Agents;
