"use strict";

var React = require('react'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames');

var NotFound = React.createClass({displayName: "NotFound",
    render: function() {
        return (
            React.createElement(DocumentTitle, {title: "NCH - 404 Not Found"}, 
                React.createElement("h1", null, "Not Found")
            )
        );
    }
});
module.exports = NotFound;
