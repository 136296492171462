"use strict";

var React = require('react'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    FluxBone = require('../../mixins/FluxBone'),
    ProjectModel = require('../../models/Project'),
    NavLink = require('../../components/NavLink'),
    SidebarNav = require('../../partials/SidebarNav');

var Project = React.createClass({displayName: "Project",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            id = params.id;

        this.props.model.clear();
        this.props.model.set('id', id);
        this.props.model.fetch();

        return {
            id: id
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function(){
        return {
            model: new ProjectModel(),
            modelLinkToState: true
        };
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.model,
            data = state.model,
            title = 'Project - ' + data.name;
        return (
             React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: "NCH - Project"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Projects"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(NavLink, {to: "ProjectList"}, "Projects"), 
                            React.createElement(NavLink, {to: "Project", params: { id: data.id}}, data.name || data.id)
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement("div", {className: "row half-gutter"}, 
                        React.createElement("div", {className: "col-md-3 md-margin-bottom-40"}, 
                            React.createElement(SidebarNav, null)
                        ), 
                        React.createElement("div", {className: "col-md-9"}, 
                            React.createElement("div", null, model.get('name')), 
                            React.createElement("div", {dangerouslySetInnerHTML: { __html: model.get('summary')}})

                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    }
});
module.exports = Project;
