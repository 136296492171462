"use strict";

var React = require('react/addons'),
    cx = require('classnames'),
    _ = require('underscore');

var ValidationFieldWrapper = React.createClass({displayName: "ValidationFieldWrapper",
    onChangeTimeout: undefined,

    mixins: [
        React.addons.LinkedStateMixin
    ],

    getDefaultProps: function() {
        return {
            required: false,
            formLinks: undefined,
            fieldLinks: undefined,
            valueLink: undefined,
            attachToForm: undefined,
            detachFromForm: undefined,
            validate: undefined,
            value: undefined,
            isValidValue: undefined
        };
    },

    getInitialState: function() {
        return {
            value: this._getValue(),
            isValid: true,
            validationError: undefined,
            required: false,
            formLinks: this.props.formLinks,
            fieldLinks: this.props.fieldLinks
        };
    },

    _getValue: function(props) {
        var value;

        props = props || this.props;

        if (props.valueLink) {
            value = props.valueLink.value;
        } else {
            value = props.value;
        }
        return value;
    },

    componentDidUpdate: function(prevProps, prevState) {
        var me = this;

        if (prevState.value != me.state.value) {
            if(me.props.valueLink){
                me.props.valueLink.requestChange(me.state.value);
            }

            // Try and handle fields which get auto injected with values outside of react
            if (me.triggerOnChange) {
                clearTimeout(me.onChangeTimeout);
                me.onChangeTimeout = setTimeout(function(){
                    delete me.onChangeTimeout;
                    if(me.isMounted()) {
                        me.validate();
                    }
                }, 100)
            }
        }

        if(prevState.required !== this.state.required){
            this.validate();
        }
    },

    componentWillReceiveProps: function(newProps) {
        if (newProps.value !== this.props.value) {
            this.setState({
                value: newProps.value
            });
        } else if (newProps.valueLink && newProps.valueLink.value !== this.props.value) {
            this.setState({
                value: newProps.valueLink.value
            });
        }

        if (newProps.formLinks !== this.props.formLinks) {
            this.setState({
                formLinks: newProps.formLinks
            });
        }

        if (newProps.fieldLinks !== this.props.fieldLinks) {
            this.setState({
                fieldLinks: newProps.fieldLinks
            });
        }

        if (newProps.required !== this.props.required) {
            this.setState({
                required: newProps.required
            });
        }
    },

    render: function() {
        var props = this.props,
            child = props.children;

        return (
            React.createElement("div", React.__spread({},  this.getWrapperProps(), {className: cx('validationWraper', props.validationWrapperClassName)}), 
                React.cloneElement(React.Children.only(child), this.getChildProps(child), child.props.children)
            )
        )
    },

    getWrapperProps: function() {
        return {

        };
    },

    getChildProps: function(child) {
        var props = _.omit(this.props, 'valueLink', 'checkedLink', 'value', 'checked'),
            childProps = _.extend({
                _validate: this.validate,
                required: this.state.required || child.props.required,
                disabled: this.state.disabled || child.props.disabled,
                error: this.state.validationError,
            }, this.state.formLinks, this.state.fieldLinks, props);

        if(!this.props.valueLink && !this.props.checkedLink){
            childProps.value = this.props.value;
            childProps.checked = child.props.useCheckedLink ? !!this.props.value : false;
            childProps.readOnly = true;
        } else if (child.props.useCheckedLink) {
            childProps.checkedLink = this.linkState('value');
        } else {
            childProps.valueLink = this.linkState('value');
        }

        if(child.props.getValues){
            this.getValues = this.getChildValues;
            childProps.ref = 'child';
        }

        if(child.props.trigger === 'onChange'){
            // Change handling is tricky when you are also binding values with valueLink
            this.triggerOnChange = true;
            childProps.trigger = 'onBlur';
        }

        return childProps;
    },

    setValue: function(value) {
        this.setState({
            value: value
        });
    },

    getValue: function() {
        return this.state.value;
    },

    validate: function() {
        if (this.props.validate) {
            this.props.validate(this);
        }
    },

    componentWillMount: function() {
        if (this.props.attachToForm) {
            this.props.attachToForm(this, this.props.children.key || this.props.children.props.fieldKey || this.props.children.props.name || this.props.children.props.id);
        }
    },

    componentWillUnMount: function() {
        if (this.props.detachFromForm) {
            this.props.detachFromForm(this, this.props.children.props.name);
        }
    },

    getName: function() {
        return this.props.children.props.name;
    },

    getChildValues: function(useComponentValues){
        var refKey = 'child';
        if(this.refs[refKey] && this.refs[refKey].getValues){
            return this.refs[refKey].getValues(useComponentValues);
        }
    }
});

module.exports = ValidationFieldWrapper;
