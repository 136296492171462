"use strict";

var React = require('react/addons'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    FluxBone = require('../../mixins/FluxBone'),
    BS = require('react-bootstrap'),
    ProjectModel = require('../../models/Project'),
    DeleteDialog = require('../../partials/DeleteDialog'),
    moment = require('moment'),
    ReactRouter = require('react-router'),
    ListingModelType = require('../../mixins/ListingModelType'),
    Link = ReactRouter.Link;

var ProjectDocuments = React.createClass({displayName: "ProjectDocuments",

    mixins: [
        FluxBone.CollectionMixin('model.documentsCollection', 'sync remove add', 10),
        React.addons.LinkedStateMixin,
        ListingModelType.ListingNavigation('Document')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getInitialState: function() {
        return {
            modelToDelete: false
        };
    },

    getDefaultProps: function() {
        return {
            readOnly: false,
            emptyMessage: 'There are no documents available.'
        };
    },

    onDelete: function(doc, e) {
        e.preventDefault();
        this.setState({
            modelToDelete: doc
        });
    },

    onHideDeleteDialog: function() {
        this.setState({
            modelToDelete: undefined
        });
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            deleteDialogProps = {
                ref: 'deleteDialog',
                modelToDelete: state.modelToDelete,
                onHide: me.onHideDeleteDialog,
                modelTitle: state.modelToDelete ? state.modelToDelete.get('title') : '',
                title: 'ALERT! You are about to DELETE a document.',
                msg: 'Please confirm that you would like to DELETE your document,',
                successMessage: 'Success! Your document has been successfully deleted.',
                deleteLabel: 'Delete Document'
            };

        return (
            React.createElement(DeleteDialog, React.__spread({},  deleteDialogProps), 
                !this.props.hideHeader && (
                React.createElement("div", {className: "row margin-bottom-20 half-gutter"}, 
                    React.createElement("div", {className: props.readOnly ? 'col-md-12' : 'col-md-10'}, 
                        React.createElement("h1", null, me.isDeveloper() ? 'Builder Documents' : 'Listing Documents'), 
                        props.description ? props.description : (
                            React.createElement("h5", null, "This section contains documents that you want agents to access such as contracts, price sheets, etc..")
                        )
                    ), 
                    !props.readOnly && (
                        React.createElement("div", {className: "col-md-2"}, 
                            React.createElement(Link, {to: me.addTo(), className: "btn btn-u pull-right", params: this.context.router.getCurrentParams()}, "Add Document")
                        )
                    )
                )
                ), 
                 React.createElement("div", {className: "row half-gutter"}, 
                    React.createElement("div", {className: "col-sm-12"}, 
                        (!props.readOnly || props.model.documentsCollection.length > 0) && (
                            React.createElement(BS.Table, {condensed: true, hover: true, striped: true, className: "table-borderless"}, 
                                React.createElement("thead", null, 
                                    React.createElement("tr", null, 
                                        React.createElement("th", null), 
                                        React.createElement("th", {className: "col-md-4"}, "Name"), 
                                        React.createElement("th", {className: "col-md-3"}, "Category"), 
                                        React.createElement("th", {className: "col-md-3"}, "Last Updated"), 
                                        !props.readOnly && (
                                            React.createElement("th", null, "Actions")
                                        )
                                    )
                                ), 
                                React.createElement("tbody", null, 
                                props.model.documentsCollection.map(function(doc){
                                    var data = doc.toJSON(),
                                        linkParams = { id: props.model.id, documentId: data.id },
                                        readOnly = props.readOnly || doc.isReadOnly();
                                    return (
                                        React.createElement("tr", {key: data.id}, 
                                            React.createElement("td", null, 
                                                React.createElement("a", {href: data.link}, 
                                                    React.createElement("i", {className: "icon-custom icon-lg icon-color-u fa " + doc.getFileNameIcon()})
                                                )
                                            ), 
                                            React.createElement("td", {style: {'whiteSpace': 'nowrap'}}, 
                                                !readOnly && (
                                                    React.createElement(Link, {to: me.editTo(), params: linkParams}, data.title)
                                                ), 
                                                readOnly && data.title
                                            ), 
                                            React.createElement("td", {style: {'whiteSpace': 'nowrap'}}, data.category), 
                                            React.createElement("td", {style: {'whiteSpace': 'nowrap'}}, 
                                                data.lastUpdatedDate ? moment(new Date(data.lastUpdatedDate)).fromNow() : '-'
                                            ), 
                                            !readOnly && (
                                                React.createElement("td", {style: {'whiteSpace': 'nowrap'}}, 
                                                    React.createElement(Link, {to: me.editTo(), params: linkParams}, 
                                                        React.createElement("i", {className: "icon-custom  icon-color-u icon-sm fa fa-pencil", style: {"marginRight" : "3px"}})
                                                    ), 
                                                    React.createElement("a", {href: "#", onClick: me.onDelete.bind(me, doc)}, 
                                                        React.createElement("i", {className: "icon-custom  icon-color-u icon-sm fa fa-trash-o"})
                                                    )
                                                )
                                            )
                                        )
                                    );
                                })
                                )
                            )
                        )
                    )
                ), 
                props.model.documentsCollection.length === 0 && (
                   React.createElement(BS.Alert, null, 
                        React.createElement("p", {className: "text-center"}, 
                            props.emptyMessage
                        )
                    )
                )
             )
        );
    }
});
module.exports = ProjectDocuments;
