"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    Attribute = require('../models/Attribute'),

    data = [{
        id: 1,
        name: 'Condo'
    }, {
        id: 2,
        name: 'Highrise'
    }, {
        id: 3,
        name: 'Single Family'
    }, {
        id: 4,
        name: 'Hotel Condo'
    }, {
        id: 5,
        name: 'Mixed-use'
    }, {
        id: 6,
        name: 'Mountain Chalets'
    }, {
        id: 7,
        name: 'Mountain Estate Homes'
    }, {
        id: 8,
        name: 'Villas'
    }, {
        id: 9,
        name: 'Casitas'
    }, {
        id: 10,
        name: 'Townhomes'
    }, {
        id: 11,
        name: 'Homesites'
    }, {
        id: 12,
        name: 'Fractional'
    }];

var ListingTypes = Collection.extend({
    model: Attribute,
    constructor: function(models, options) {
        models = data;
        return Collection.prototype.constructor.call(this, models, options);
    }
});

module.exports = ListingTypes;
