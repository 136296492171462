"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    Attribute = require('../models/Attribute');

var Countries = Collection.extend({
    url: function() {
        var url = Constants.restPath;

        if (this.hasProjects) {
            url = url + '/projects';
        }

        url = url + '/countries';

        return url;
    },
    model: Attribute,
    pageSize: null,
    hasProjects: false,

    fetch: function(options) {
        options = options || {};

        if (options.hasProjects !== undefined) {
            this.hasProjects = options.hasProjects;
        }

        return Collection.prototype.fetch.call(this, options);
    }
});

module.exports = Countries;
