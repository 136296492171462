"use strict";

var Constants = require('../../constants'),
    Model = require('../Model'),
    moment = require('moment'),
    $ = require('jquery'),
    Agents = require('../../collections/Agents'),
    _ = require('underscore');

var ListingAgentDashboard = Model.extend({
    urlRoot: Constants.restPath + "/reports/listing-details-agents",
    pageSize: 20,
    hasMany: {
        // agentsCollection: 'agents'
    },
    defaults: function() {
        var to = moment.utc(),
            from = moment.utc(this.project ? this.project.get('dateCreated') : undefined);

        return {
            startDate: from.valueOf(),
            endDate: to.valueOf()
        };
    },

    fetch: function(options) {
        options = options || {};
        options.data = options.data || {};

        _.defaults(options.data, {
            to: moment.utc(this.get('endDate'), 'x').format('YYYY-MM-DD'),
            from: moment.utc(this.get('startDate'), 'x').format('YYYY-MM-DD'),
            listingId: this.get('listingId')
        });

        Model.prototype.fetch.call(this, options);
    },

    parse: function(response) {
        var data = Model.prototype.parse.apply(this, arguments);

        if (data.startDate) {
            data.startDate = moment.utc(data.startDate, 'YYYY-MM-DD').format('x');
        }

        if (data.endDate) {
            data.endDate = moment.utc(data.endDate, 'YYYY-MM-DD').format('x');
        }

        if(data.agents){
            data.agents = _.sortBy(data.agents, function(agent){
                // Sort by clicks/views descending.
                return -agent.views;
            });
        }

        return data;
    },

    sync: function(method, model, options) {
        options = options || {};
        options.data = options.data || {};

        if (options.download) {
            options.data.offset = 0;
            options.data.limit = this.get('total') || 1000000;
            options.data.csv = true;
            window.location.href = model.urlRoot + "?" + $.param(options.data);
            return;
        }

        Model.prototype.sync.apply(this, arguments);
    }
}, {
    agentsCollection: Agents
});

module.exports = ListingAgentDashboard;
