"use strict";

var Constants = require("../constants"),
  Images = require("../collections/ProjectImages"),
  FloorPlans = require("../collections/FloorPlans"),
  Documents = require("../collections/ProjectDocuments"),
  Promotions = require("../collections/Promotions"),
  ProjectContacts = require("../collections/ProjectContacts"),
  utility = require("../shared/Utilities"),
  UserEntity = require("./UserEntity"),
  URI = require("urijs"),
  $ = require("jquery");

var Developer = UserEntity.extend(
  {
    urlRoot: Constants.restPath + "/developers",
    toolboxURL: function() {
      return URI(Constants.toolboxHost)
        .protocol("http")
        .addSearch({
          API: this.get("apiKey")
        })
        .toString();
    },
    idAttribute: "id",
    listingModelType: "developer",

    imageUrl: function() {
      return this.url();
    },
    imageValidation: {
      listingImage: {
        required: true
      }
    },
    validation: {
      website: {
        required: false
      },
      "contactInfo.firstName": {
        required: true,
        pattern: "no_numbers"
      },
      "contactInfo.lastName": {
        required: true,
        pattern: "no_numbers"
      },
      minPrice: {
        required: false,
        pattern: "number"
      },
      maxPrice: {
        required: false,
        pattern: "number"
      },
      realName: {
        required: true
        // pattern: 'no_numbers'
      },
      contactInfo: utility.locationValidationNoAddress
    },
    labels: {
      "contactInfo.phoneWork": "Phone",
      "contactInfo.email": "Email",
      "contactInfo.firstName": "First Name",
      "contactInfo.lastName": "Last Name",
      contactName: "Name",
      realName: "Business Name"
    },
    hasMany: {
      Images: "images",
      FloorPlans: "floorPlans",
      Documents: "documents",
      Promotions: "promotions",
      ProjectContacts: "listingPocs"
    },

    defaults: function() {
      return {
        id: undefined,
        name: undefined,
        logo: undefined,
        website: "",
        createDate: undefined,
        referralCode: "",
        howHeard: "",
        affiliateID: "",
        dataProvider: "",
        importBuilderID: "",
        user: {},
        image: undefined
      };
    },

    getDisplayName: function(contactName) {
      var contactInfo = this.contactInfoModel.toJSON(),
        name;

      if (contactName === true) {
        name = contactInfo
          ? contactInfo.firstName + " " + contactInfo.lastName
          : "";
      } else {
        name = this.get("name");
      }

      return name;
    },

    getServiceLevel: function() {
      return 0;
    },

    parse: function(response) {
      var data = UserEntity.prototype.parse.apply(this, arguments);

      if (data && data.image) {
        // function(image, size, linkAttr, folderName, filenameAttr) {
        this.addImageLink(data, null, "image", null, "image");
        data.fileName = data.image.match(/[^\\/]+$/)[0];
        data.image = {
          link: data.image, // + '?nc=' + new Date().getTime(),
          name: data.fileName
        };
        data.listingImage = [data.image];
      }

      // if (data && data.imageLink) {
      //     this.addImageLink(data, '300px', 'imageLink', 'Pics', 'imageLink');
      //     data.fileName = data.imageLink.match(/[^\\/]+$/)[0];
      //     data.image = {
      //         link: data.imageLink + '?nc=' + new Date().getTime(),
      //         name: data.fileName
      //     };
      // }

      if (data) {
        data.realName = data.name;
        data.name = data.displayName || data.name;

        if (data.imageGallery) {
          this.addImageLinks(data.imageGallery);
        }
      }

      return data;
    },

    addImageLinks: utility.addImageLinks,
    addImageLink: utility.addImageLink,

    toJSON: function(options) {
      var data = UserEntity.prototype.toJSON.apply(this, arguments);

      options = options || {};

      if (options.url) {
        delete data.users; //do not persist users for updates (there may be several users)

        //this is only allowed when developer is created
        if (
          !$.isEmptyObject(data.user) &&
          options.syncMethod &&
          (options.syncMethod == "create" || options.syncMethod == "update")
        ) {
          data.users = [data.user];
          delete data.user;
        }

        data.name = data.realName;
        delete data.realName;
        delete data.listingImage;
        delete data.image;
      }

      return data;
    },

    save: function(attributes, options) {
      var idxOfName;

      attributes = attributes || {};

      if (
        options &&
        options.fields &&
        (idxOfName = options.fields.indexOf("name")) > -1
      ) {
        options.fields[idxOfName] = "realName";
      }

      return UserEntity.prototype.save.call(this, attributes, options);
    },

    isActive: function() {
      return !!this.get("active");
    },

    fetchByAPICode: function(apiCode, options) {
      options = options || {};
      options.data = options.data || {};

      if (this.jsonPSupport && this.usingJsonP) {
        options.dataType = "jsonp";
        options.url = this.rootPath + "/jsonp/developers/by-api/" + apiCode;
      } else {
        options.url = this.urlRoot + "/by-api/" + apiCode;
      }

      return this.fetch(options);
    },

    hasServiceType: function() {
      var typesToCheck = arguments,
        serviceTypes = this.get("serviceTypes") || [],
        hasType = false;

      for (var i = 0, len = typesToCheck.length; i < len; i++) {
        hasType = serviceTypes.indexOf(typesToCheck[i]) !== -1;
        if (!hasType) {
          break;
        }
      }

      return hasType;
    }
  },
  {
    Images: Images,
    FloorPlans: FloorPlans,
    Documents: Documents,
    Promotions: Promotions,
    ProjectContacts: ProjectContacts
  }
);

module.exports = Developer;
