"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    Link = ReactRouter.Link,
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    Constants = require('../../constants'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    NavLink = require('../../components/NavLink'),
    Cmp = require('../../components'),
    SidebarNav = require('../../partials/SidebarNav'),
    FluxBone = require('../../mixins/FluxBone'),
    Developers = require('../../collections/Developers'),
    CurrentUser = require('../../shared/CurrentUser'),
    Validation = require('../../partials/validation'),
    Pagination = require('../../partials/Pagination'),
    Roles = require('../../mixins/Roles'),
    toastr = require('toastr');

var Developers = React.createClass({displayName: "Developers",

    mixins: [
        FluxBone.CollectionMixin('store', 'sync'),
        React.addons.LinkedStateMixin,
        ReactRouter.Navigation,
        Roles
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            store: new Developers()
        };
    },

    updateState: function() {
        var query = this.context.router.getCurrentQuery(),
            store = this.props.store;

        store.pageNum = query.page || 1;
        store.setFilters(query, {
            resetFilters: true
        });
        store.throttledFetch();

        return store.getFilterValues();
    },

    getInitialState: function() {
        var state = this.updateState();

        state.filtersVisible = true;

        return state;
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    toggleFilters: function(e) {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        this.setState({
            filtersVisible: !this.state.filtersVisible
        });
    },

    doSearch: function(e) {
        var me = this,
            query = {
                page: 1
            },
            state = me.state;

        if (e && e.preventDefault) {
            e.preventDefault();
        }

        _.each(me.props.store.filters, function(filter) {
            query[filter] = state[filter];
        });

        this.transitionTo('DeveloperList', undefined, query);
    },

    render: function() {
        var me = this,
            state = me.state,
            props = me.props,
            filterHeader = (
                React.createElement("div", null, 
                    React.createElement("h4", {className: "panel-title"}, 
                        "Filters:", 
                        React.createElement("div", {className: "pull-right", title: "Toggle Filter Visibility"}, 
                            React.createElement("i", {className: 'fa fa-fw ' + (state.filtersVisible ? 'fa-minus' : 'fa-plus')})
                        )
                    )
                )
            ),
            expanded = state.filtersVisible,
            isAdmin = me.hasOneRole(Constants.roles.ADMIN);

        return (
            React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: "NCH - Developers Listing"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Developers"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(NavLink, {to: "DeveloperList"}, "Developers")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement("div", {className: "row half-gutter"}, 
                        React.createElement("div", {className: "col-md-3 md-margin-bottom-40"}, 
                            React.createElement(SidebarNav, null)
                        ), 
                        React.createElement("div", {className: "col-md-9"}, 
                            React.createElement("div", {className: "row half-gutter"}, 
                                React.createElement("div", {className: "col-md-3"}, 
                                    React.createElement("h1", null, "Developers:")
                                ), 
                                React.createElement("div", {className: "col-md-6"}, 
                                    React.createElement("p", null, "Below is a list of Developers.")
                                ), 
                                React.createElement("div", {className: "col-md-3"}, 
                                    React.createElement(Link, {to: "DeveloperAdd", className: "btn btn-default pull-right"}, React.createElement("i", {className: "fa fa-plus"}), " Add Developer")
                                )
                            ), 
                            isAdmin && (
                                React.createElement(BS.Panel, {className: 'panel-table' + (expanded ? 'panel-table-expanded' : ''), collapsible: true, header: filterHeader, expanded: expanded, onSelect: me.toggleFilters}, 
                                    React.createElement("div", {className: "row"}, 
                                        React.createElement("form", {onSubmit: me.doSearch}, 
                                            React.createElement(Validation.Input, {type: "text", valueLink: me.linkState('companyName'), groupClassName: "col-sm-6", placeholder: "Search by Name"}), 
                                            React.createElement(Validation.Input, {type: "text", valueLink: me.linkState('email'), groupClassName: "col-sm-6", placeholder: "Search by Email"}), 
                                            React.createElement(Cmp.Select.Country, {name: "country", valueLink: me.linkState('country'), groupClassName: "col-sm-3"}), 
                                            React.createElement(Cmp.Select.State, {name: "state", valueLink: me.linkState('state'), countryId: state.country, groupClassName: "col-sm-3"}), 
                                            React.createElement(Cmp.Select.Metro, {name: "metro_area", valueLink: me.linkState('metro_area'), stateId: state.state, groupClassName: "col-sm-3"}), 
                                            React.createElement(Cmp.Select.City, {name: "city", valueLink: me.linkState('city'), stateId: state.state, metroId: state.metro_area, groupClassName: "col-sm-3"}), 
                                            React.createElement("div", {className: "col-xs-12"}, 
                                                React.createElement(BS.Button, {className: "pull-right", type: "submit"}, "Search")
                                            )
                                        )
                                    )
                                )
                            ), 
                            React.createElement(Pagination, {store: props.store, to: "DeveloperList", query: props.store.getFilterValues()}), 
                            React.createElement(BS.Table, {striped: true, bordered: true, condensed: true, hover: true, id: "agents-table"}, 
                                React.createElement("thead", null, 
                                    React.createElement("tr", null, 
                                        React.createElement("th", null, React.createElement("i", {className: "fa fa-th-large"}), " Developer Name"), 
                                        isAdmin && (
                                            React.createElement("th", {style: { width: '100px'}}, React.createElement("i", {className: "fa fa-eye"}), " Pay with Check")
                                        ), 
                                        isAdmin && (
                                            React.createElement("th", {style: { width: '100px'}}, React.createElement("i", {className: "fa fa-eye"}), " Claimed")
                                        ), 
                                        React.createElement("th", {style: { width: '100px'}}, React.createElement("i", {className: "fa fa-flash"}), " Website")
                                    )
                                ), 
                                React.createElement("tbody", null, 
                                props.store.map(function(developer){
                                    var data = developer.toJSON(),
                                        email = developer.contactInfoModel.get('email');
                                    return (
                                        React.createElement("tr", {key: developer.id}, 
                                            React.createElement("td", null, React.createElement(Link, {to: "DeveloperEdit", params: { id: data.id}}, data.name)), 
                                            isAdmin && (
                                                React.createElement("td", null, React.createElement("input", {type: "checkbox", checked: data.payWithCheck, onChange: me.toggleCheckbox.bind(null, 'payWithCheck', developer)}))
                                            ), 
                                            isAdmin && (
                                                React.createElement("td", null, React.createElement("input", {type: "checkbox", checked: data.claimed, onChange: me.toggleCheckbox.bind(null, 'claimed', developer)}))
                                            ), 
                                            React.createElement("td", null, 
                                                React.createElement("a", {href: data.website}, React.createElement("i", {className: "fa fa-external-link"})), " ", 
                                                isAdmin && (React.createElement("a", {href: "#", onClick: me.impersonateUser.bind(null, developer)}, React.createElement("i", {className: "fa fa-eye"})))
                                            )
                                        )
                                    );
                                })
                                )
                            ), 
                            React.createElement(Pagination, {store: props.store, to: "DeveloperList", noMargin: true, query: props.store.getFilterValues()})
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    },

    toggleCheckbox: function(field, developer) {
        var data = {};
        data[field]= !developer.get(field);
        developer.save(data, {
            validate: false,
            fields: [field]
        });
    },

    impersonateUser: function(developer, e) {
        e.preventDefault();
        developer.fetch().then(function() {
            var user = developer.get('user') || {},
                username = user.username;

            if(username) {
                CurrentUser.impersonateUser(username);
            } else {
                toastr['warning']('"'+ developer.get('name') + '" can not be impersonated');
            }
        });
    }
});

module.exports = Developers;
