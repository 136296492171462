"use strict";

var React = require("react/addons"),
  Constants = require("../../constants"),
  DocumentTitle = require("react-document-title"),
  cx = require("classnames"),
  FluxBone = require("../../mixins/FluxBone"),
  BS = require("react-bootstrap"),
  ReactRouter = require("react-router"),
  $ = require("jquery"),
  Link = ReactRouter.Link;
var toastr = require("toastr");

require("fancybox");

var ProjectAPIKey = React.createClass({displayName: "ProjectAPIKey",
  contextTypes: {
    router: React.PropTypes.func
  },
  mixins: [ReactRouter.Navigation],

  getInitialState: function() {
    return {};
  },
  getDefaultProps: function() {
    var self = this;
    model: undefined;
  },

  shouldBeDisabled: function(props) {
    return false;
  },

  componentDidMount: function() {
    var head = $("head"),
      existingScript = $("#wistia-popover"),
      script = $(
        '<script id="wistia-popover" type="text/javascript" charset="ISO-8859-1" src="//fast.wistia.com/assets/external/popover-v1.js"></script>'
      );

    if (!existingScript.length) {
      head.append(script);
    }
  },
  componentWillUnmount: function() {
    var existingScript = $("#wistia-popover");

    if (existingScript.length) {
      existingScript.remove();
    }
  },
  handleFileChange:function(event) {
    var self = this;
    var reader = new FileReader();
    reader.addEventListener("load", function(e)  {
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", "SKGlvM8V1U4fAflJemlos3rxoMuYFYDh5qjkoElx");
      myHeaders.append("Content-Type", "multipart/form-data");

      //validate file type
      function hasExtension(fileName, exts) {
        console.log(fileName, exts);
        if (
          fileName.indexOf("png") |
          fileName.indexOf("jpg") |
          fileName.indexOf("jpeg") |
          fileName.indexOf("PNG") |
          fileName.indexOf("JPEG") |
          fileName.indexOf("gif") |
          fileName.indexOf("GIF")
        )
          return true;
        else return false;
      }
      console.log(e.currentTarget);
      if (
        hasExtension(e.currentTarget.result, [".jpg", ".jpeg", ".gif", ".png"])
      ) {
        //myHeaders.append("Access-Control-Allow-Origin", "*"); // Required for CORS support to work
        //myHeaders.append("Access-Control-Allow-Credentials", true); // Required for cookies, authorization headers with HTTPS
        var image = e.currentTarget.result;
        image = image.replace(/^data:image\/[a-z]+;base64,/, "");
        var raw = { body: { base64String: image } };
        raw = JSON.stringify(raw);
        var requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        fetch(
          "https://l7ouls816g.execute-api.us-east-1.amazonaws.com/v1/project",
          requestOptions
        )
          .then(function(response)  {return response.text();})
          .then(function(result) {
            result = JSON.parse(result);
            self.props.model
              .save({ id: self.props.model.id, logo: result.Key })
              .then(function() {
                self.props.model.fetch();
                function refreshIt() {
                  window.location.reload(false);
                }
                setTimeout(refreshIt, 500);
              });
          })
          .catch(function(error)  {return console.log("error", error);});
      } else {
        toastr.error(
          "Wrong File Type! <br/> Only JPG, GIF, or PNG files allowed."
        );
      }
    });
    reader.readAsDataURL(event.target.files[0]);
  },

  render: function() {
    var me = this,
      props = me.props,
      state = me.state,
      isDisabled = this.shouldBeDisabled(),
      toolboxURL = props.model.toolboxURL();
    console.log("props", props, "state", state);
    return (
      React.createElement("div", null, 
        React.createElement("div", {className: "row"}, 
          React.createElement("div", {className: "col-xs-12"}, 
            React.createElement("h1", null, "Agent Outreach Tools:")
          )
        ), 

        isDisabled && !props.model.isLoading() && props.model.isLoaded() && (
          React.createElement("div", null, 
            React.createElement(BS.Row, null, 
              React.createElement(BS.Col, {xs: 12}, 
                React.createElement("p", {className: "green-text"}, 
                  React.createElement("i", {className: "fa fa-exclamation-triangle"}), " Upgrade to" + ' ' +
                  "access the Agent Outreach Tools.", " ", 
                  React.createElement(Link, {
                    to: "UserUpgradeAndBilling", 
                    params: { id: props.model.id}
                  }, 
                    "Click Here"
                  ), " ", 
                  "to upgrade."
                )
              )
            ), 
            React.createElement(BS.Row, null, 
              React.createElement(BS.Col, {xs: 12}, 
                React.createElement("p", null, 
                  "Save time & make your Realtor outreach efforts more" + ' ' +
                  "effective. Agent Outreach Tools include:"
                )
              )
            ), 
            React.createElement(BS.Row, null, 
              React.createElement(BS.Col, {xs: 7}, 
                React.createElement("h3", null, "Agent Tracker:"), 
                React.createElement("p", null, 
                  "Drive more traffic to your website. Agent Tracker is a" + ' ' +
                  "patented technology that tracks which agents are sending" + ' ' +
                  "traffic to your community or company website. It allows agents" + ' ' +
                  "to use your website as a tool to educate their buyers about" + ' ' +
                  "your homes."
                ), 
                React.createElement("h4", null, 
                  React.createElement("a", {
                    href: "//fast.wistia.net/embed/iframe/7f8oo6jra0?popover=true", 
                    className: "orange-text wistia-popover[height=441,playerColor=7b796a,width=720]"
                  }, 
                    "Watch how Agent Tracker works", 
                    React.createElement("div", {className: "no-underline"}, 
                      " ", 
                      React.createElement("i", {className: "icon-custom padding-5 icon-custom-play"}, 
                        React.createElement("span", {className: "fa fa-fw fa-play"})
                      )
                    )
                  )
                ), 

                React.createElement("h3", null, "Agent Toolbox:"), 
                React.createElement("p", null, 
                  "Agent Toolbox is an agent portal designed to save you and your" + ' ' +
                  "team time and effort when working with agents."
                ), 
                React.createElement("h4", null, 
                  React.createElement("a", {
                    href: "//fast.wistia.net/embed/iframe/3stctwosl9?popover=true", 
                    className: "orange-text wistia-popover[height=441,playerColor=7b796a,width=720]"
                  }, 
                    "Watch how Agent Toolbox works", 
                    React.createElement("div", {className: "no-underline"}, 
                      " ", 
                      React.createElement("i", {className: "icon-custom padding-5 icon-custom-play"}, 
                        React.createElement("span", {className: "fa fa-fw fa-play"})
                      )
                    )
                  )
                )
              ), 
              React.createElement(BS.Col, {xs: 5}, 
                React.createElement("br", null), 
                React.createElement("img", {
                  className: "img-responsive", 
                  src: Constants.imageRoot + "agent-tracker.png"}
                ), 
                React.createElement("br", null), 
                React.createElement("br", null), 
                React.createElement("img", {
                  className: "img-responsive", 
                  src: Constants.imageRoot + "agent-toolbox.png"}
                )
              )
            ), 
            React.createElement(BS.Row, null, 
              React.createElement(BS.Col, {xs: 12}, 
                React.createElement("p", {className: "green-text"}, 
                  React.createElement("i", {className: "fa fa-exclamation-triangle"}), " Upgrade to" + ' ' +
                  "access the Agent Outreach Tools.", " ", 
                  React.createElement(Link, {
                    to: "UserUpgradeAndBilling", 
                    params: { id: props.model.id}
                  }, 
                    "Click Here"
                  ), " ", 
                  "to upgrade."
                )
              )
            )
          )
        ), 

        !isDisabled && !props.model.isLoading() && props.model.isLoaded() && (
          React.createElement("div", null, 
            React.createElement("p", null, 
              "Agent Outreach Tools are a suite of tools to help builders work" + ' ' +
              "better with real estate agents. Below are the tools currently" + ' ' +
              "available to you."
              /*To use the tools, you will need to add New Construction Hub code to your community and/or company website. Below are the instructions along with the API key for this listing. We suggest downloading the instructions and then emailing the instructions along with the API key to your webmaster. Let us know if you have any questions. We are here to help!*/
            ), 
            React.createElement("hr", null), 
            React.createElement("h3", null, 
              "API Key:", " ", 
              React.createElement("small", null, 
                "API Key for ", React.createElement("i", null, props.model.get("name")), ":", " ", 
                React.createElement("b", null, props.model.get("apiKey"))
              )
            ), 
            React.createElement("hr", null), 
            React.createElement("div", {className: "row"}, 
              React.createElement("div", {className: "col-xs-4"}, 
                React.createElement("h3", null, "Agent Portal:"), 
                React.createElement("img", {
                  className: "img-responsive", 
                  src: Constants.imageRoot + "agent-toolbox.png"}
                )
              ), 
              React.createElement("div", {className: "col-xs-8"}, 
                React.createElement("p", null, 
                  "The Agent Portal is a section of your website where agents and" + ' ' +
                  "brokers go to access exclusive tools and information to market" + ' ' +
                  "and promote your project to their buyers. Agents can go there" + ' ' +
                  "to access and download documents, images, floor plans, etc. to" + ' ' +
                  "share with their clients. Save time by streamlining the" + ' ' +
                  "process for working with Realtors."
                ), 
                React.createElement("p", null, 
                  "The content and materials that are in your Agent Portal come" + ' ' +
                  "from the information that you add through this administrative" + ' ' +
                  "area."
                ), 
                React.createElement("p", null, 
                  "To use the Agent Portal for", " ", 
                  React.createElement("span", {className: "blue-text"}, props.model.get("name")), "," + ' ' +
                  "add the link below to your project’s website so agents and" + ' ' +
                  "brokers can access."
                ), 
                React.createElement("p", null, 
                  "Here is the URL to the Agent Portal for", " ", 
                  React.createElement("span", {className: "blue-text"}, props.model.get("name")), ":", " ", 
                  React.createElement("a", {target: "_blank", href: toolboxURL}, 
                    toolboxURL
                  )
                )
              )
            ), 
            /*
            <div>
             <div className="row hidden">
                <div className="col-xs-4">
                  <h3>Agent Tracker:</h3>
                  <img
                    className="img-responsive"
                    src={Constants.imageRoot + "agent-tracker2.png"}
                  />
                </div>
                <div className="col-xs-8">
                  <p>
                    Agent Tracker is the feature that personalizes your
                    project’s website for the referring agent. Agent Tracker is
                    a technology that is added to your community or project's
                    website. Agent Tracker instantly creates a personalized
                    version of the website for the agent.
                  </p>
                  <p>
                    Agent Tracker makes is easy for agents to effectively market
                    your community to their database of buyers. Realtors will be
                    willing to market your community because they retain full
                    control of their buyers.
                  </p>
                  <p>
                    <a
                      target="_blank"
                      href={
                        Constants.publicRoot +
                        "docs/Instructions_AddAgentShieldCode_20151224.pdf"
                      }
                    >
                      CLICK HERE
                    </a>{" "}
                    for instructions for adding Agent Tracker to your project's
                    website can be found here.
                  </p>
                </div>
              </div>
            </div>
            */
            React.createElement("hr", null), 
            React.createElement("div", null, 
              React.createElement("div", {className: "row"}, 
                React.createElement("div", {className: "col-xs-4"}, 
                  React.createElement("h3", null, "Agent Portal Logo:"), 
                  React.createElement("img", {
                    className: "img-responsive", 
                    src: 
                      props.model.attributes.logo
                        ? "https://project-logos-1.s3.amazonaws.com/" +
                          props.model.attributes.logo
                        : Constants.imageRoot + "no-agent-portal-logo.png"
                    }
                  )
                ), 
                React.createElement("div", {className: "col-xs-8"}, 
                  React.createElement("p", null, 
                    "Enhance the visual branding on your project's Agent Portal" + ' ' +
                    "by adding your project's logo. The logo uploaded here will" + ' ' +
                    "appear on the Agent Portal for agents and brokers."
                  ), 
                  React.createElement("div", {className: "project-logo-c"}, 
                    React.createElement("h3", null, 
                      "Logo should be 262 pixels wide (max 262 pixels high)"
                    ), 
                    React.createElement("h4", null, "Only JPG, GIF, or PNG files"), 
                    React.createElement("form", {id: "logo-upload", type: "multipart/form-data"}, 
                      React.createElement("input", {
                        type: "file", 
                        id: "logo-upload-button", 
                        name: "pictureFile", 
                        accept: "..jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.gif,.gif", 
                        onChange: this.handleFileChange}
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    );
  }
});
module.exports = ProjectAPIKey;
