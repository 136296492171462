"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    MarketingNetwork = require('../models/MarketingNetwork');

var MarketingNetworks = Collection.extend({
    url: Constants.restPath + '/marketing-networks',
    model: MarketingNetwork
});

module.exports = MarketingNetworks;
