"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    Constants = require('../../constants'),
    CurrentUser = require('../../shared/CurrentUser'),
    DocumentTitle = require('react-document-title'),
    BS = require('react-bootstrap'),
    FluxBone = require('../../mixins/FluxBone'),
    Widget = require('../../models/Widget'),
    Cmp = require('../../components'),
    ContactWebmaster = require('../../models/ContactWebmaster'),
    Widget = require('../../models/Widget'),
    Validation = require('../../partials/validation'),
    RequiredMessage = require('../../partials/RequiredMessage'),
    $ = require('jquery'),
    _ = require('underscore'),
    Link = ReactRouter.Link;

var WidgetHelp = React.createClass({displayName: "WidgetHelp",

    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            contactModel: new ContactWebmaster(),
            model: new Widget()
        };
    },

    updateState: function(props){
        props = props || this.props;

        var params = this.context.router.getCurrentParams(),
            id = parseInt(params.id, 10);
        
        props.contactModel.set({
            agentId: CurrentUser.agentModel.id,
            widgetId: id
        });

        if (id && props.model.id !== id) {
            props.model.clear();
            props.model.set('id', id);
            props.model.fetch();
        }

        return {
            sent: false,
            widgetId: id,
            agentId: CurrentUser.agentModel.id
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    componentWillReceiveProps: function(newProps) {
        this.setState(this.updateState(newProps));
    },

    componentWillUpdate: function(newProps, newState){
        if(this.state.widgetId !== newState.widgetId || this.state.agentId !== newState.agentId){
            this.props.contactModel.clear();
        }
    },

    onSubmit: function(){
        var me = this,
            model = me.props.contactModel;

        model.save(me.refs.form.getValues()).then(function(m, e, r) {
            me.setState({
                sent: true
            });
        });
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.contactModel,
            widgetType = props.model.get('widgetType').id,
            routes = this.context.router.getCurrentRoutes(),
            withinEditWizard = !!_.find(routes, function(route) {
                return route.name === 'WidgetEditHelp';
            }),
            withinSignupWizard = !!_.find(routes, function(route) {
                return route.name === 'SignupWidgetHelp';
            }),
            back,
            defaultControlAttr = {
                type: 'text',
                trigger: 'onChange',
                labelClassName: 'col-md-3',
                wrapperClassName: 'col-md-9'
            },
            title = props.title + ' - ' + props.model.getWidgetTypeName() + ' - Help';

            if(withinEditWizard){
                back = 'WidgetEditCode';
            } else if(withinSignupWizard){
                back = 'SignupWidget';
            } else {
                back = 'WidgetCode'
            }

        if(state.sent) {
            title = title + ' - Sent';
        }

        return (
            React.createElement("div", {className: "col-xs-12"}, 
                React.createElement(DocumentTitle, {title: title}), 
                React.createElement("div", {className: "row"}, 
                    React.createElement(Link, {to: back, params: { id: state.widgetId}}, React.createElement(BS.Button, {bsStyle: "link"}, React.createElement("i", {className: "fa fa-arrow-left"}), " Back"))
                ), 
                 state.sent ? (
                    React.createElement("div", null, 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-xs-12"}, 
                                React.createElement("h1", {className: "text-center blue-text"}, 
                                    React.createElement("i", {className: "fa fa-check-square-o fa-4x center-block"}), 
                                    React.createElement("div", {className: "font-large margin-top-negative-15"}, "Success! ")
                                )
                            )
                        ), 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-xs-12 text-center"}, 
                                React.createElement("p", {className: "font-large"}, 
                                    "We've sent an email to ", React.createElement("span", {className: "blue-text"}, model.get('email')), " with instructions of how to add your widget to your website."
                                ), 
                                React.createElement(Link, {className: "btn-u btn-u-lg btn-u-blue btn-u-inverted btn-u-narrow", to: "Widgets"}, "Done")
                            )
                        )
                    )
                ) : (
                    React.createElement("div", {className: "row"}, 
                        React.createElement("div", {className: "col-xs-12"}, 
                            React.createElement("h2", null, "Get help from your webmaster"), 
                            React.createElement("p", null, "If you have a webmaster who administers your website, complete the form below and we will send your webmaster instructions for adding your widget to your website."), 
                            React.createElement(RequiredMessage, null), 

                            React.createElement(BS.Panel, null, 
                                React.createElement(Validation.Form, {ref: "form", horizontal: true, model: this.props.contactModel, onSubmit: this.onSubmit, validateOnLoad: false}, 
                                    React.createElement(Validation.Input, React.__spread({label: "Webmaster's Name:"},  defaultControlAttr, {name: "name"})), 
                                    React.createElement(Validation.Input, React.__spread({label: "Webmaster's Email Address:"},  defaultControlAttr, {name: "email"})), 
                                    React.createElement(Validation.Input, React.__spread({label: "Message to Webmaster:"},  defaultControlAttr, {rows: "5", type: "textarea", name: "message"})), 
                                    
                                    React.createElement(Cmp.ValidationMessage, {action: "Send"}), 
                                    React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                                            React.createElement(Cmp.Button.Save, {className: "pull-right", label: "Send"})
                                    )
                                )
                            )
                        )
                    )
                )
            ) 
        );
    }
});
module.exports = WidgetHelp;
