"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    ReactRouterBootstrap = require('react-router-bootstrap'),
    ButtonLink = ReactRouterBootstrap.ButtonLink,
    Constants = require('../../constants'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    FluxBone = require('../../mixins/FluxBone'),
    Widget = require('../../models/Widget'),
    NavLink = require('../../components/NavLink'),
    ReactCatalyst = require('../../mixins/react-catalyst'),
    fancybox = require('fancybox'),
    RouteHandler = ReactRouter.RouteHandler,
    $ = require('jquery'),
    _ = require('underscore'),
    Link = ReactRouter.Link,
    Clipboard = require('../../external/clipboard'),
    toastr = require('toastr');

var WidgetCode = React.createClass({displayName: "WidgetCode",

    contextTypes: {
        router: React.PropTypes.func
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.model,
            widgetType = model.get('widgetType').id,
            routes = me.context.router.getCurrentRoutes(),
            isCreate = me.context.router.getCurrentQuery().create === 'true',
            withinEditWizard = !!_.find(routes, function(route) {
                return route.name === 'WidgetEditCode';
            }),
            back,
            help,
            title = props.title + ' - ' + model.getWidgetTypeName() + ' - Code';

        if(isCreate) {
            back = 'WidgetEditFinished';
            help = 'WidgetEditHelp';
        } else if(withinEditWizard) {
            back = 'WidgetEditProjects';
            help = 'WidgetEditHelp';
        } else {
            back = 'Widgets';
            help = 'WidgetHelp';
        }

        return (
            React.createElement("div", null, 
                React.createElement(DocumentTitle, {title: title}), 
                React.createElement("div", {className: "row"}, 
                    React.createElement(Link, {to: back, params: { id: model.id}, query: { widgetType: widgetType, create: isCreate || undefined}}, React.createElement(BS.Button, {bsStyle: "link"}, React.createElement("i", {className: "fa fa-arrow-left"}), " Back"))
                ), 
                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-xs-12 col-sm-8 col-sm-offset-2"}, 
                        React.createElement("h3", {className: "blue-text"}, "Choose 1 of the 2 options to add the widget to your website."), 
                        React.createElement("h4", null, "Option #1: Widget Code ", React.createElement("span", {className: "orange-text"}, "(Most Popular)")), 
                        React.createElement("p", null, 
                            "Copy and past the code below into the ", React.createElement("span", {className: "blue-text"}, "HTML Code"), " or ", React.createElement("span", {className: "blue-text"}, "Source View"), " of your webpage."
                        ), 
                        React.createElement(BS.Input, {readOnly: true, type: "textarea", value: this.getWidgetInsertionCode()}), 
                        Constants.canClipboardCopy && (
                            React.createElement("div", {className: "btn-u btn-u-ultra-narrow btn-u-orange margin-bottom-10", onClick: this.copyToClipboard.bind(null, this.getWidgetInsertionCode())}, "Copy Code to Clipboard")
                        )
                    )
                ), 
                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-xs-12 col-sm-8 col-sm-offset-2"}, 
                        React.createElement("h4", null, "Option #2: Framing/Linking URL ", React.createElement("span", {className: "orange-text"}, "(Note: You MUST use this option if you are adding the widget to a website that does not allow 3rd party code such as websites by Placester.com, MarketLeader.com, etc..)")), 
                        React.createElement("p", null, 
                            "Use this if your website does not support Option #1."
                        ), 
                        React.createElement("p", null, 
                            "Copy and paste the code below into the ", React.createElement("span", {className: "blue-text"}, "HTML Code"), " or ", React.createElement("span", {className: "blue-text"}, "Source View"), " of your webpage."
                        ), 
                        React.createElement(BS.Input, {readOnly: true, type: "textarea", value: this.getWidgetEmbedInsertionCode()}), 
                        Constants.canClipboardCopy && (
                            React.createElement("div", {className: "btn-u btn-u-ultra-narrow btn-u-orange margin-bottom-10", onClick: this.copyToClipboard.bind(null, this.getWidgetEmbedInsertionCode())}, "Copy Code to Clipboard")
                        )
                    )
                ), 

                React.createElement("div", {className: "row"}, 
                    withinEditWizard ? (
                        React.createElement("div", {className: "col-xs-12 col-sm-8 col-sm-offset-2"}, 
                                React.createElement(ButtonLink, {bsStyle: "primary", to: "Widgets"}, "Finished"), " or you can ", React.createElement(Link, {to: help, params: { id: model.id}}, "click here"), " to receive help from your webmaster."
                        )
                    ) : (
                        React.createElement("div", {className: "col-xs-12 col-sm-8 col-sm-offset-2"}, 
                                React.createElement("h4", null, "You can ", React.createElement(Link, {className: "text-uppercase font-bold", to: help, params: { id: model.id}}, "click here"), " to receive help from your webmaster.")
                        )
                    )
                )
            ) 
        );
    },

    getWidgetInsertionCode: function(){
        var code =  "<div id=\"AgentShield_MyDevelopments_$WID$\"></div>\r\n" + 
                    "<script src=\""+Constants.widgetHost+"/$WID$.js\"></script>";

        return code.replace(/(\$WID\$)/g, this.props.model.id);
    },

    getWidgetEmbedInsertionCode: function(){
        var code =  "<embed src=\""+Constants.widgetHost+'/embed/$WID$'+"\" width=\"100%\" height=\"800\"></embed>";

        return code.replace(/(\$WID\$)/g, this.props.model.id);
    },

    copyToClipboard: function(valueToCopy) {
        Clipboard.copy(valueToCopy);
        toastr.success('Copied to Clipboard!');
    }
});
module.exports = WidgetCode;
