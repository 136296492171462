"use strict";

var React = require('react/addons'),
    Link = require('react-router').Link,
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    FluxBone = require('../../mixins/FluxBone'),
    BS = require('react-bootstrap'),
    ProjectModel = require('../../models/Project'),
    DeleteDialog = require('../../partials/DeleteDialog'),
    ListingModelType = require('../../mixins/ListingModelType'),
    Constants = require('../../constants'),
    Numeral = require('numeral');

var ProjectPromotions = React.createClass({displayName: "ProjectPromotions",
    mixins: [
        FluxBone.CollectionMixin('model.promotionsCollection', 'sync remove add', 10),
        ListingModelType.ListingNavigation('Promotion')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getInitialState: function() {
        return {};
    },

    getDefaultProps: function() {
        return {
            readOnly: false,
            emptyMessage: 'There are no promotions available.'
        };
    },

    onHideDeleteDialog: function() {
        this.setState({
            modelToDelete: undefined
        });
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            projectId = this.context.router.getCurrentParams().id,
            deleteDialogProps = {
                ref: 'deleteDialog',
                modelToDelete: state.modelToDelete,
                onHide: me.onHideDeleteDialog,
                modelTitle: state.modelToDelete ? state.modelToDelete.get('name') : '',
                title: 'ALERT! You are about to DELETE a Promotion.',
                msg: 'Please confirm that you would like to DELETE this Promotion.',
                successMessage: 'Success! Your promotion has been successfully deleted.',
                deleteLabel: 'Delete Promotion'
            };

        return (
            React.createElement(DeleteDialog, React.__spread({},  deleteDialogProps), 
                !this.props.hideHeader && (
                React.createElement("div", {className: "row margin-bottom-20 half-gutter"}, 
                    React.createElement("div", {className: props.readOnly ? 'col-md-12' : 'col-md-10'}, 
                        React.createElement("h1", null, me.isDeveloper() ? 'Builder Promotions' : 'Listing Promotions'), 
                        props.description ? props.description : (
                            React.createElement("h5", null, "Add your current and future promotions. ")
                        )
                    ), 
                    !props.readOnly && (
                        React.createElement("div", {className: "col-md-2"}, 
                            React.createElement(Link, {to: me.addTo(), key: "ProjectAddPromotionButton", className: "btn btn-u pull-right", params: {id: projectId}}, "Add Promotion")
                        )
                    )
                )
                ), 
                React.createElement("div", {className: "row half-gutter"}, 
                    React.createElement("div", {className: "col-sm-12"}, 
                        (!props.readOnly || props.model.promotionsCollection.length > 0) && (
                            React.createElement(BS.Table, {condensed: true, hover: true, striped: true, className: "table-borderless"}, 
                                React.createElement("thead", null, 
                                React.createElement("tr", null, 
                                    React.createElement("th", null), 
                                    React.createElement("th", null, "Name"), 
                                    React.createElement("th", null, "Type"), 
                                    React.createElement("th", null, "Promo Dates"), 
                                    React.createElement("th", null, "Active"), 
                                    !props.readOnly && (
                                        React.createElement("th", null, "Actions")
                                    )
                                )
                                ), 
                                React.createElement("tbody", null, 
                                props.model.promotionsCollection.map(function(promotion) {
                                    var data = promotion.toJSON(),
                                        linkParams = { id: projectId, promotionId: data.id },
                                        readOnly = props.readOnly || promotion.isReadOnly();
                                    return (
                                        React.createElement("tr", {key: data.id}, 
                                            React.createElement("td", null, 
                                                React.createElement("a", {href: data.link}, 
                                                    React.createElement("i", {className: "icon-custom icon-lg icon-color-u fa " + (data.link ? promotion.getFileNameIcon() : 'icon-custom-no-icon')}, 
                                                        !data.link && (
                                                            React.createElement("span", null, "No File")
                                                        )
                                                    )
                                                )
                                            ), 
                                            React.createElement("td", {className: "promotion-column-name"}, 
                                                !readOnly && (
                                                    React.createElement(Link, {to: me.editTo(), params: linkParams}, data.name)
                                                ), 
                                                readOnly && data.name
                                            ), 
                                            React.createElement("td", {width: "100"}, data.type ? data.type.name : '-'), 
                                            React.createElement("td", {width: "100"}, 
                                                data.startDate ? new Date(data.startDate).toLocaleDateString() + ' - ' + new Date(data.endDate).toLocaleDateString(): '-'
                                            ), 
                                            React.createElement("td", null, data.active ? 'Yes' : 'No'), 
                                            !readOnly && (
                                                React.createElement("td", null, 
                                                    React.createElement(Link, {to: me.editTo(), params: linkParams}, 
                                                        React.createElement("i", {className: "icon-custom  icon-color-u icon-sm fa fa-pencil"})
                                                    ), " ", 
                                                    React.createElement("a", {href: "#", onClick: me.onDelete.bind(me, promotion)}, 
                                                        React.createElement("i", {className: "icon-custom  icon-color-u icon-sm fa fa-trash-o"})
                                                    )
                                                )
                                            )
                                        )
                                        );
                                })
                                )
                            )
                        ), 
                        props.model.promotionsCollection.length === 0 && (
                            React.createElement(BS.Alert, null, 
                                React.createElement("p", {className: "text-center"}, 
                                    props.emptyMessage
                                )
                            )
                        )
                    )
                )
            )
        );
    },

    onDelete: function(model, e) {
        e.preventDefault();
        this.setState({
            modelToDelete: model
        });
    }
});
module.exports = ProjectPromotions;
