"use strict";

var CryptoJS = require("crypto-js"),
    keyBase64 = 'amFzb25kb2w=',
    ivBase64 = 'EjRWeJCrze8=';

module.exports = {
    encrypt: function(value) {
        value = "" + value;
        return CryptoJS.DES.encrypt(value, CryptoJS.enc.Base64.parse(keyBase64), {
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            iv: CryptoJS.enc.Base64.parse(ivBase64)
        }).toString();
    },

    decrypt: function(value) {
        return CryptoJS.DES.decrypt(value, CryptoJS.enc.Base64.parse(keyBase64), {
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            iv: CryptoJS.enc.Base64.parse(ivBase64)
        }).toString(CryptoJS.enc.Utf8);
    }
};
