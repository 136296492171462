"use strict";
/*
module.exports = {
  trackingPath:
    "//staging-api.agentshield.com/t/1x1clear.gif" +
    "?t=" +
    new Date().getTime(),
  server: "http://staging-www.agentshield.com",
  restPath: "/agent-shield-1.0/rest",
  apiPath: "/agent-shield-1.0",
  widgetHost: "https://staging-widgets.agentshield.com",
  intercomAppId: "ijh052u7",
  intercomAppIdAgent: "ijh052u7",
  rollbarEnabled: true,
  rollbarId: "7a90fad9cc7144689883252a2f5e888f",
  toolboxHost: "//staging-toolbox.agentshield.com"
};
*/
module.exports = {
  trackingPath:
    "//api.agentshield.com/t/1x1clear.gif" + "?t=" + new Date().getTime(),
  server: "http://admin.as",
  restPath: "/agent-shield-1.0/rest",
  apiPath: "/agent-shield-1.0",
  widgetHost: "//widgets.as",
  intercomAppId: "ijh052u7",
  intercomAppIdAgent: "ijh052u7",
  rollbarEnabled: false,
  rollbarId: "7a90fad9cc7144689883252a2f5e888f",
  toolboxHost: "toolbox.as"
};
