"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    ProjectModel = require('../../../models/Project'),
    RouteHandler = ReactRouter.RouteHandler;

var ProjectWizardAdd = React.createClass({displayName: "ProjectWizardAdd",

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            id = params.id ? parseInt(params.id) : undefined,
            model = this.props.model;

        if (!id || id !== model.id) {
            model.clear();
        }

        if (id && id !== model.id) {
            model.set('id', id);
            model.fetch();
        }

        return {
            id: id
        };
    },

    componentWillReceiveProps: function(newProps) {
        this.setState(this.updateState());
    },

    getInitialState: function() {

        return this.updateState();
    },

    getDefaultProps: function() {
        return {
            model: new ProjectModel()
        };
    },

    render: function() {
        var me = this,
            props = me.props;

        return (
             React.createElement(RouteHandler, {model: props.model})
        );
    }
});
module.exports = ProjectWizardAdd;
