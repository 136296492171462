"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    cx = require('classnames'),
    FluxBone = require('../../mixins/FluxBone'),
    BS = require('react-bootstrap'),
    Cmp = require('../../components'),
    Validation = require('../../partials/validation'),
    Roles = require('../../mixins/Roles'),
    Constants = require('../../constants');

var ProjectVisibility = React.createClass({displayName: "ProjectVisibility",

    mixins: [
        ReactRouter.Navigation,
        Roles
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getInitialState: function() {
        return {};
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            defaultControlAttr = {
                type: 'text',
                labelClassName: 'col-md-3',
                wrapperClassName: 'col-md-9'
            };

        return (
            React.createElement("div", null, 
                 React.createElement(Validation.Form, {ref: "form", model: props.model, className: "form form-horizontal", onSubmit: this.onSaveClick, skipValidation: true}, 
                    React.createElement("h4", null, "Listing Visibility: ", props.model.get('name')), 

                    React.createElement("h5", null, "Listing Settings / Status"), 
                    React.createElement(Cmp.RadioGroup, {labelClassName: "col-md-3", wrapperClassName: "col-md-6", type: "radio", label: "Show to Agents:", name: "showToAgents", items: [{id : true,  name : "Yes"}, {id : false,  name : "No"}]}), 
                    React.createElement(Cmp.RadioGroup, {labelClassName: "col-md-3", wrapperClassName: "col-md-6", type: "radio", label: "Agent Tracker Approved:", name: "agentTrackerApproved", items: [{id : true,  name : "Yes"}, {id : false,  name : "No"}]}), 

                    React.createElement("h5", null, "Marketing Channels"), 
                    React.createElement(Cmp.Select.MarketingNetworks, {name: "marketingNetworks"}), 

                    React.createElement(Cmp.ValidationMessage, null), 
                    React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                        React.createElement(Cmp.Button.Cancel, {onClick: this.onCancelClick}), 
                        React.createElement(Cmp.Button.Save, {className: "pull-right"})
                    )
                )
            )
        );
    },

 
    onSaveClick: function(e) {
        var values = this.refs.form.getValues(true);
        this.props.model.save(values, {
            validate: false,
            fields: ['name', 'showToAgents', 'marketingNetworks', 'agentTrackerApproved']
        });
    },

    onCancelClick: function(){
        
    }
});
module.exports = ProjectVisibility;
