"use strict";

var React = require('react'),
    ReactRouterBootstrap = require('react-router-bootstrap'),
    BS = require('react-bootstrap'),
    cx = require('classnames'),
    _ = require('underscore'),
    ButtonLink = ReactRouterBootstrap.ButtonLink;

var SaveButton = React.createClass({displayName: "SaveButton",

    getDefaultProps: function() {
        return {
            label: 'Save',
            type: 'submit',
            bsStyle: 'primary',
            // Used for validation but otherwise does nothing
            formLinks: {
                disabled: '!isValid',
                'aria-hidden': 'readOnly'
            },
            fieldKey: 'save'
        }
    },

    render: function() {
        var props = this.props,
            className = cx(props.className),
            other = _.omit(props, 'className', 'label');

        return props.to ? (
            React.createElement(ButtonLink, React.__spread({bsStyle: "primary", type: "submit"},  other, {className: className}), 
                props.children || props.label
            )
        ) : (
            React.createElement(BS.Button, React.__spread({bsStyle: "primary", type: "submit"},  other, {className: className}), 
                props.children || props.label
            )
        );
    }
});

module.exports = SaveButton;
