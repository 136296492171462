"use strict";

var React = require('react'),
    DocumentTitle = require('react-document-title'),
    PageHeader = require('../partials/PageHeader'),
    PageFooter = require('../partials/PageFooter'),
    ReactRouter = require('react-router'),
    NavLink = require('../components/NavLink'),
    Link = ReactRouter.Link;

var DevelopmentUnavailable = React.createClass({displayName: "DevelopmentUnavailable",

    render: function() {
        return (
            React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: "NCH - Error"}), 
                React.createElement(PageHeader, null), 

                React.createElement("div", {className: "breadcrumbs margin-bottom-40"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Development Unavailable"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(NavLink, {to: "Dashboard"}, "Home"), 
                            React.createElement(NavLink, {className: "active"}, "Oops")
                        )
                    )
                ), 
                React.createElement("div", {className: "container"}, 
                    React.createElement("div", {className: "row"}, 
                        React.createElement("div", {className: "title-box"}, 
                            React.createElement("div", {className: "title-box-text"}, "We apologize but there is a problem reaching the project's website.", React.createElement("br", null), 
                                React.createElement(Link, {to: "Dashboard"}, "To continue, please click here to return to our home page.")
                            )
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    }
});
module.exports = DevelopmentUnavailable;
