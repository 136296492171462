var React = require('react'),
    BS = require('react-bootstrap'),
    defaultMessage = 'is not available yet. Some required information is still missing.';

var ValidationMessage = React.createClass({displayName: "ValidationMessage",
	getDefaultProps: function() {
        return {
            formLinks: {
                'aria-hidden': 'isValid'
            },
            fieldKey: 'saveDesc',
            message: defaultMessage,
            action: 'Save'
        }
    },

    render: function() {
        var props = this.props,
            message = props.message;

        if(message === defaultMessage) {
            message = props.action + ' ' + props.message;
        }

        return (
            React.createElement(BS.Row, React.__spread({},  this.props), 
                React.createElement(BS.Col, {xs: 12}, 
                    React.createElement("p", {className: "pull-right"}, message)
                )
            )
        );
    }
});

module.exports = ValidationMessage;
