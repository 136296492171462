"use strict";

/**
 * The default BS.Carousel is customizable to how we want to theme it. so we use this instead.
 */

var React = require('react'),
    cx = require('classnames'),
    $ = require('jquery'),
    d = window.define;

window.define = undefined;

require('jcarousel');
require('jquery-touchswipe');

window.define = d;

var CarouselItem = React.createClass({displayName: "CarouselItem",

    getDefaultProps: function() {
        return {
            active: false
        };
    },

    render: function() {
        var props = this.props,
            className = cx(props.className);

        return (
            React.createElement("li", React.__spread({},  props, {className: className}), 
                this.props.children
            )
        );
    }
});

var Carousel = React.createClass({displayName: "Carousel",

    getDefaultProps: function() {
        return {
            interval: 5000,
            controls: false
        };
    },

    render: function() {
        var props = this.props,
            className = cx('jcarousel-wrapper', props.className)

        return (
            React.createElement("div", React.__spread({},  props, {className: className}), 
                React.createElement("div", {className: "jcarousel", ref: "carousel"}, 
                    React.createElement("ul", null, 
                        this.props.children
                    )
                ), 
                props.controls && this.props.id && (
                    React.createElement("div", {className: "carousel-arrow"}, 
                        React.createElement("a", {"data-slide": "prev", href: '#' + this.props.id, className: "left carousel-control jcarousel-control-prev"}, 
                            React.createElement("i", {className: "fa fa-angle-left"})
                        ), 
                        React.createElement("a", {"data-slide": "next", href: '#' + this.props.id, className: "right carousel-control jcarousel-control-next"}, 
                            React.createElement("i", {className: "fa fa-angle-right"})
                        )
                    )
                )
            )
        );
    },

    componentDidMount: function() {
        var $carousel = $(React.findDOMNode(this.refs.carousel));

        $carousel.jcarousel({
            wrap: 'both'
        }).jcarouselAutoscroll({
            interval: this.props.interval,
            target: '+=1',
            autostart: true
        }).swipe({
            swipeLeft: function() {
                $carousel.jcarousel('scroll', '+=1');
            },
            swipeRight: function() {
                $carousel.jcarousel('scroll', '-=1');
            }
        });

        $carousel.hover(function() {
            $(this).jcarouselAutoscroll('stop');
        }, function() {
            $(this).jcarouselAutoscroll('start');
        });

        this.setEvents(true);
    },

    componentWillUnmount: function() {
        this.setEvents(false);
    },

    setEvents: function(add) {
        var $this = $(React.findDOMNode(this)),
            action = add ? 'on' : 'off'
        /*
         Prev control initialization
         */
        $this.find('.jcarousel-control-prev')[action]('jcarouselcontrol:active', this.onActive);
        $this.find('.jcarousel-control-prev')[action]('jcarouselcontrol:inactive', this.onInactive);

        /*
         Next control initialization
         */
        $this.find('.jcarousel-control-next')[action]('jcarouselcontrol:active', this.onActive);
        $this.find('.jcarousel-control-next')[action]('jcarouselcontrol:inactive', this.onInactive);

        if(add) {
            $this.find('.jcarousel-control-prev').jcarouselControl({
                // Options go here
                target: '-=1'
            });

            $this.find('.jcarousel-control-next').jcarouselControl({
                // Options go here
                target: '+=1'
            });
        }
    },

    onActive: function(e) {
        $(e.target).removeClass('inactive');
    },

    onInactive: function(e) {
        $(e.target).addClass('inactive');
    }
});
Carousel.Item = CarouselItem;
module.exports = Carousel;
