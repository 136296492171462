"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    cx = require('classnames'),
    FluxBone = require('../../mixins/FluxBone'),
    NavLink = require('../../components/NavLink'),
    moment = require('moment'),
    numeral = require('numeral'),
    _ = require('underscore');

var LeadDetails = React.createClass({displayName: "LeadDetails",

    getDefaultProps: function() {
        return {};
    },

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset')
    ],

    render: function() {
        var me = this,
            props = me.props,
            data = props.model.toJSON({
                includeListingPocs: true
            }),
            listing = data.listing,
            contactInfo = data.contactInfo || {},
            agentName = data.agent ? data.agent.name : '';

        return (
            React.createElement("div", null, 
                React.createElement("h2", null, "Lead Details"), 
                React.createElement("p", null, 
                    "Below is information about your lead. ", React.createElement("br", null), 
                    React.createElement("small", null, "NOTE: Your lead is NOT AUTOMATICALLY REGISTERED. Contact the builder's representative to continue the sales process.")
                ), 
                React.createElement("hr", null), 
                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-md-6"}, 
                        React.createElement("dl", {className: "dl-horizontal details-list"}, 
                            React.createElement("dt", null, React.createElement("strong", null, "Name")), 
                            React.createElement("dd", null, contactInfo.fullName), 
                            React.createElement("hr", null), 

                            React.createElement("dt", null, React.createElement("strong", null, "Phone")), 
                            React.createElement("dd", null, 
                                contactInfo.phoneHome && (
                                    React.createElement("div", null, 
                                        contactInfo.phoneHome
                                    )
                                ), 
                                contactInfo.phoneMobile && (
                                    React.createElement("div", null, 
                                        contactInfo.phoneMobile
                                    )
                                ), 
                                contactInfo.phoneWork && (
                                    React.createElement("div", null, 
                                        contactInfo.phoneWork
                                    )
                                )
                            ), 
                            React.createElement("hr", null), 

                            React.createElement("dt", null, React.createElement("strong", null, "Email")), 
                            React.createElement("dd", null, contactInfo.email), 
                            React.createElement("hr", null), 

                            React.createElement("dt", null, React.createElement("strong", null, "Address")), 
                            React.createElement("dd", null, contactInfo.address1), 
                            React.createElement("hr", null), 
                            React.createElement("dt", null, React.createElement("strong", null, " ")), 
                            React.createElement("dd", null, contactInfo.address2), 
                            React.createElement("hr", null)
                        )
                    ), 
                    React.createElement("div", {className: "col-md-6"}, 
                        React.createElement("dl", {className: "dl-vertical details-list"}, 
                            React.createElement("dt", null, React.createElement("strong", null, "Comments")), 
                            React.createElement("hr", null), 
                            React.createElement("dd", null, React.createElement("p", null, data.comments))
                        )
                    )
                ), 
                data.listing && (
                    React.createElement("div", null, 
                        React.createElement("div", {className: "row half-gutter"}, 
                            React.createElement("div", {className: "col-xs-12"}, 
                                React.createElement("h2", null, "Project Interested In"), 
                                React.createElement("p", null, "Below is the community/builder that your lead is interested in."), 
                                React.createElement("hr", {className: "clear margin-tb-15"})
                            )
                        ), 
                        React.createElement("div", {className: "row half-gutter"}, 
                            React.createElement("div", {className: "col-xs-4 col-sm-3"}, 
                                React.createElement("img", {src: data.listing.image, className: "img-responsive", style: {'maxWidth': '95%'}})
                            ), 
                            React.createElement("div", {className: "col-xs-8 col-sm-9"}, 
                                React.createElement("div", {className: "row no-gutter margin-bottom-10"}, 
                                    React.createElement("h2", {className: "blue-text"}, data.listing.name)
                                ), 
                                React.createElement("div", {className: "row no-gutter margin-bottom-10"}, 
                                    React.createElement("div", {className: "col-xs-3"}, 
                                        React.createElement("strong", null, "Location:")
                                    ), 
                                    React.createElement("div", {className: "col-xs-9"}, 
                                        data.listing.location || 'None'
                                    )
                                ), 
                                React.createElement("div", {className: "row no-gutter margin-bottom-10"}, 
                                    React.createElement("div", {className: "col-xs-3"}, 
                                        React.createElement("strong", null, "Project Type:")
                                    ), 
                                    React.createElement("div", {className: "col-xs-9"}, 
                                        _.map(data.listing.listingTypes, function(type, i){
                                            return (
                                                React.createElement("span", {key: type.id}, 
                                                    type.name, 
                                                    i < data.listing.listingTypes.length-1 && ',', 
                                                    " "
                                                )
                                            );
                                        })
                                    )
                                ), 
                                React.createElement("div", {className: "row no-gutter margin-bottom-10"}, 
                                    React.createElement("div", {className: "col-xs-3"}, 
                                        React.createElement("strong", null, "Price Range:")
                                    ), 
                                    React.createElement("div", {className: "col-xs-9"}, 
                                        numeral(data.listing.minPrice).format('$0,0[.]00'), " - ", numeral(data.listing.maxPrice).format('$0,0[.]00')
                                    )
                                )
                            )
                        ), 
                        React.createElement("div", {className: "row half-gutter row margin-bottom-10"}, 
                            React.createElement("div", {className: "col-xs-12"}, 
                                React.createElement("h3", null, "Description"), 
                                React.createElement("hr", null), 
                                React.createElement("p", null, listing.summary)
                            )
                        ), 
                        React.createElement("div", {className: "row half-gutter"}, 
                            React.createElement("div", {className: "col-xs-12"}, 
                                React.createElement("h3", null, "Contact Info"), 
                                React.createElement("p", null, 
                                    "Below is the contact information for the builder's representative. Contact the builder for all questions. ", React.createElement("br", null), 
                                    React.createElement("small", null, "NOTE: NEW CONSTRUCTION HUB DOES NOT AUTOMATICALLY REGISTER LEADS WITH BUILDERS. Contact the builder to register your client.")
                                ), 
                                React.createElement("hr", null), 
                                _.map(listing.listingPocs, function(poc){
                                    return (
                                        React.createElement("div", {className: "row", key: poc.id}, 
                                            React.createElement("div", {className: "col-xs-12 col-sm-6 col-sm-offset-3"}, 
                                                React.createElement("div", {className: "row"}, 
                                                    React.createElement("div", {className: "col-xs-6 col-sm-"}, "Builder's Representative:"), 
                                                    React.createElement("div", {className: "col-xs-6"}, poc.name || 'No Name')
                                                ), 
                                                React.createElement("div", {className: "row"}, 
                                                    React.createElement("div", {className: "col-xs-6"}, "Phone Number:"), 
                                                    React.createElement("div", {className: "col-xs-6"}, poc.phone || '-')
                                                ), 
                                                React.createElement("div", {className: "row"}, 
                                                    React.createElement("div", {className: "col-xs-6"}, "Email:"), 
                                                    React.createElement("div", {className: "col-xs-6"}, poc.email  || '-')
                                                )
                                            )
                                        )
                                    );
                                })
                            )
                        )
                    )
                ), 
                React.createElement("div", {className: "row half-gutter margin-top-20"}, 
                    React.createElement("div", {className: "col-xs-12"}, 
                        React.createElement("h3", null, "Active Promotions"), 
                        React.createElement("hr", null), 

                        !data.listing || !data.listing.promotions || data.listing.promotions.length === 0 && (
                            React.createElement("p", {className: "text-center"}, "No Active Promotions")
                        ), 

                        data.listing && data.listing.promotions && _.map(data.listing.promotions, function(promotion){
                            return (
                                React.createElement("div", {key: 'promotion-'+promotion.id}, 
                                    React.createElement("div", {className: "row margin-bottom-10"}, 
                                        React.createElement("div", {className: "col-sm-6 col-md-4"}, React.createElement("strong", null, promotion.name)), 
                                        React.createElement("div", {className: "col-sm-6 col-md-5"}, promotion.type.name)
                                    ), 

                                    React.createElement("div", {className: "row margin-bottom-10"}, 
                                        React.createElement("div", {className: "col-xs-6 col-md-2 col-md-offset-1"}, "Value:"), 
                                        React.createElement("div", {className: "col-xs-6 col-md-1"}, numeral(promotion.value).format('$0,0[.]00')), 
                                        React.createElement("div", {className: "col-xs-6 col-md-2 col-md-offset-1"}, "Expires on:"), 
                                        React.createElement("div", {className: "col-xs-6 col-md-2"}, moment(new Date(promotion.endDate)).format('MMMM Do, YYYY'))
                                    ), 

                                    React.createElement("div", {className: "row margin-bottom-10"}, 
                                        React.createElement("div", {className: "col-md-2 col-md-offset-1"}, "Description:"), 
                                        React.createElement("div", {className: "col-md-9"}, promotion.description)
                                    )
                                )
                            )
                        })
                    )
                )
            )
        );
    }
});

module.exports = LeadDetails;
