"use strict";

var React = require('react/addons'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    FluxBone = require('../../mixins/FluxBone'),
    ReactCatalyst = require('../../mixins/react-catalyst'),
    BS = require('react-bootstrap'),
    Cmp = require('../../components'),
    ReactRouter = require('react-router'),
    $ = require('jquery'),
    _ = require('underscore'),
    Link = ReactRouter.Link,
    Amenity = require('../../models/Amenity'),
    Amenities = require('../../collections/Amenities'),
    Validation = require('../../partials/validation'),
    MessagePartial = require('../../partials/Message'),
    ListingModelType = require('../../mixins/ListingModelType'),
    Constants = require('../../constants'),
    CurrentUser = require('../../shared/CurrentUser'),
    ProjectModel = require('../../models/Project');


var ProjectAmenities = React.createClass({displayName: "ProjectAmenities",

    mixins: [
        FluxBone.CollectionMixin('myAmenitiesCollection', 'change add remove'),
        FluxBone.ModelMixin('model', 'sync reset'),
        ReactCatalyst.LinkedStateMixin,
        ListingModelType.ListingNavigation('Amenity')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var myAmenitiesCollection = this.props.myAmenitiesCollection;

        if(!myAmenitiesCollection.isLoading() && !myAmenitiesCollection.isLoaded()) {
            if (CurrentUser.hasRole(Constants.roles.ADMIN)) {
                myAmenitiesCollection.developerId = this.props.model.developerModel.id;
                if (myAmenitiesCollection.developerId) {
                    myAmenitiesCollection.fetch();
                }
            } else {
                myAmenitiesCollection.fetch();
            }
        }
        
        return {};
    },

    getDefaultProps: function(){
        var myAmenitiesCollection = new Amenities(),
            amenity = new Amenity({
                'public' : false
            });

        myAmenitiesCollection.ownOnly = true;

        return { 
            myAmenitiesCollection : myAmenitiesCollection,
            newAmenity : amenity,
            modelLinkToState: true
        };
    },    

    getInitialState: function() {
        return this.updateState();
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    onAddAmenity : function(){
        var me = this,
            project = me.props.model,
            projectAmenities = project.get('amenities'),
            myAmenitiesCollection = me.props.myAmenitiesCollection,
            amenity = me.props.newAmenity;

        if(me.refs.form.validateState()){
            amenity.save(me.refs.form.getValues()).then(function() {
                projectAmenities.push(amenity.toJSON());
                amenity.clear();
                myAmenitiesCollection.fetch();
                me.setState({
                    showSaveNewMessage: true
                });
            });
        }
    },

    onSaveClick : function (){
        var me = this,
            project = me.props.model;
        project.save({
            amenities: me.state.model.amenities
        }, { validate: false}).then(function(){
            me.setState({
                showSaveMessage: true
            });
        });
    },

    dismissSuccessMessage: function() {
        this.setState({
            showSaveMessage: false,
            showSaveNewMessage: false
        });
    },

    getMessage: function() {
        return '';
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            commonAmenities = _.map(props.myAmenitiesCollection.where({public: true}), function(amenity){ 
                return amenity.toJSON();
            }),
            privateAmenities = _.map(props.myAmenitiesCollection.where({public: false}), function(amenity){ 
                return amenity.toJSON();
            });

        return (
            React.createElement("div", {className: ""}, 
                React.createElement(DocumentTitle, {title: "NCH - Edit Project Amenities"}), 
                React.createElement("h1", null,  me.isDeveloper() ? 'Builder Amenities' : 'Listing Amenities'), 
                React.createElement("h5", null, "Select the amenities for this listing. If needed, you can add your own custom amenities."), 

                (state.showSaveMessage || state.showSaveNewMessage) && (
                    React.createElement(BS.Alert, {bsStyle: "success", onDismiss: me.dismissSuccessMessage}, 
                        state.showSaveMessage ? 'Your information has been successfully updated.' : 'Your new custom amenity has been created.'
                    )
                ), 

                React.createElement(BS.Row, null, 
                    React.createElement(BS.Col, {xs: 12, md: 6}, 
                        React.createElement("h3", null, "Standard Amenities")
                    )
                ), 

                React.createElement(BS.Row, null, 
                    React.createElement("form", {className: "form sky-form form-horizontal col-xs-12"}, 
                        React.createElement(Cmp.RadioGroup, {type: "checkbox", className: "col-md-3 col-xs-4", wrapperClassName: "col-md-12", items: commonAmenities, valuesAsObject: true, maintainOutsideValues: true, valueLink: this.linkState('model.amenities'), delimiter: ",", readOnly: props.readOnly})
                    )
                ), 

                React.createElement(BS.Row, null, 
                    React.createElement(BS.Col, {xs: 12, md: 6}, 
                        React.createElement("h3", null, "Custom Amenities")
                    )
                ), 

                React.createElement(BS.Row, null, 
                    React.createElement(Validation.Form, {className: "col-xs-12", ref: "form", model: props.newAmenity, onSubmit: this.onAddAmenity, validateOnLoad: false, skipValidation: props.readOnly, readOnly: props.readOnly, getMessage: me.getMessage}, 
                        React.createElement(BS.Col, {xs: 6, md: 6}, 
                            React.createElement(Validation.Input, {required: true, trigger: "onSubmit", placeholder: "Enter the name of a new amenity", type: "text", name: "name"})
                        ), 
                        React.createElement(BS.Col, {xs: 6, md: 6}, 
                            React.createElement(Cmp.Button.Save, {formLinks: {}, bsStyle: "primary", label: "Add new amenity", "aria-hidden": props.readOnly})
                        )
                    )
                ), 

                React.createElement(BS.Row, null, 
                    React.createElement("form", {className: "form sky-form form-horizontal col-xs-12"}, 
                        /*<MessagePartial ref="message" dispatcher={props.model}/> */
                        React.createElement(Cmp.RadioGroup, {type: "checkbox", className: "col-md-3 col-xs-4", wrapperClassName: "col-md-12", items: privateAmenities, valuesAsObject: true, maintainOutsideValues: true, valueLink: this.linkState('model.amenities'), delimiter: ",", readOnly: props.readOnly})
                    )
                ), 

                React.createElement(Cmp.ValidationMessage, null), 
                React.createElement(BS.ButtonToolbar, {className: "margin-top-20"}, 
                    React.createElement(Cmp.Button.Cancel, {to: this.isDeveloper() ? "CompanyDashboard" : "ProjectDashboard", params: {id: props.model.id}, className: "pull-left"}), 
                    React.createElement(Cmp.Button.Save, {onClick: this.onSaveClick, className: "pull-right", "aria-hidden": props.readOnly})
                )
            )
        );
    }
});
module.exports = ProjectAmenities;
