"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    Project = require('../models/Project'),
    _ = require('underscore'),
    $ = require('jquery');

var Projects = Collection.extend({
    url: Project.prototype.urlRoot,
    model: Project,

    filters: [
        'name',
        'developerName',
        'email',
        'service',
        'showToAgents',
        'country',
        'state',
        'metro_area',
        'city',
        'developer',
        'agentTrackerApproved',
        'needsApprove',
        'listingSource'
    ],

    fetch: function(options) {
        options = options || {};
        options.data = options.data || {};

        if (options.service !== undefined) {
            this.service = options.service;
        }

        if (this.service) {
            options.data.service = this.service;
        }


        if (this.metro_area && this.metro_area !== 'NONE') {
            options.data.metro_area = this.metro_area;
        }

        if (options.data && options.data.metro_area === 'NONE') {
            delete options.data.metro_area;
        }

        if (options.fetchBilling) {
            options.url = _.result(this, 'url') + '/billing';
        }

        if(options.widgetId) {
            options.data.widgetId = options.widgetId;
        }

        return Collection.prototype.fetch.call(this, options);
    },

    fetchProrate: function(options) {
        options = options || {};

        var me = this,
            getOrginalCost = options.getOrginalCost,
            data = _.compact(this.map(function(project) {
                return project.isActive() ? {
                    id: project.id,
                    serviceTypes: project.get(getOrginalCost ? 'defaultServiceTypes' : 'serviceTypes')
                } : undefined;
            }));

        options.abortXhr && me.lastProrateXhr && me.lastProrateXhr.abort();

        me.lastProrateXhr = $.ajax(_.extend({
            url: Constants.restPath + '/projects/billing?prorate=true',
            type: 'POST',
            suppressLoad: true,
            contentType: 'application/json',
            data: JSON.stringify(data)
        }, options));

        me.lastProrateXhr.then(function(response) {
            if (getOrginalCost) {
                me.cost = response.total;
            } else {
                me.lastProrate = response.total;
            }
        });

        return me.lastProrateXhr;
    },

    saveBilling: function(models, options) {
        var billingData = [];

        options = options || {};
        models = models || this.getDirtyModels() || [];

        _.each(models, function(model) {
            billingData.push({
                id: model.id,
                name: model.get('name'),
                serviceTypes: model.get('serviceTypes'),
                active: model.isActive()
            });
        });

        return $.ajax({
            url: Constants.restPath + '/projects/billing',
            type: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify(billingData)
        }).then(function() {
            _.each(models, function(model) {
                model.set({
                    defaultServiceTypes: model.get('serviceTypes'),
                    defaultActive: model.isActive()
                }, {
                    silent: true,
                    commit: true
                });
            })
        })

    },

    isDirty: function(model, options) {
        return model.get('defaultServiceTypes') !== model.get('serviceTypes') || model.get('active') !== model.get('defaultActive');
    },

    parse: function(response, options) {
        options = options || {};

        if (options.fetchBilling && response) {
            this.cost = response.total;
            this.lastProrate = this.cost;
            response = response.listings;
        }

        return Collection.prototype.parse.call(this, response, options);
    }
});

module.exports = Projects;
