"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    _ = require('underscore'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    FluxBone = require('../../mixins/FluxBone'),
    AgentModel = require('../../models/Agent'),
    LeadModel = require('../../models/Lead'),
    NavLink = require('../../components/NavLink'),
    SidebarNav = require('../../partials/SidebarNav'),
    ReactRouter = require('react-router'),
    CurrentUser = require('../../shared/CurrentUser'),
    LeadListPartial = require('../../partials/lead/LeadList'),
    ReactCatalyst = require('../../mixins/react-catalyst'),
    Link = ReactRouter.Link;

var AgentLeads = React.createClass({displayName: "AgentLeads",

    mixins: [],

    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            model: CurrentUser
        };
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.model,
            data = model.toJSON({
                includeLeads: true
            }),
            title = 'Agent - ' + data.agent.name + ' - Leads';

        return (
            React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: 'NCH - ' + title}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "My Leads"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(NavLink, {to: "AgentLeads"}, "My Leads")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement("div", {className: "row"}, 
                        React.createElement("div", {className: "col-md-3 md-margin-bottom-40"}, 
                            React.createElement(SidebarNav, null)
                        ), 
                        React.createElement("div", {className: "col-md-9"}, 
                            React.createElement(LeadListPartial, {collection: this.props.model.agentModel.leadsCollection})
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    }
});
module.exports = AgentLeads;
