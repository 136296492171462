"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    User = require('../models/User');

var Users = Collection.extend({
    url: Constants.restPath + "/users",
    model: User,
    filterUrl: Constants.restPath + "/users/search",
    filters: [
        'username',
        'email',
        'active'
    ]
});

module.exports = Users;
