"use strict";

var React = require('react'),
    Backbone = require('backbone'),
    OrderBy = require('../../components/OrderBy'),
    OrderByCollection = require('../../collections/ProjectOrderBy');

var ProjectOrderBy = React.createClass({displayName: "ProjectOrderBy",

    getDefaultProps: function() {
        return {
            label: 'Order By:',
            store: new OrderByCollection()
        };
    },

    render: function() {
        var props = this.props;

        return (
            React.createElement(OrderBy, React.__spread({defaultText: ""},  props))
        );
    }
});

module.exports = ProjectOrderBy;
