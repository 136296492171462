"use strict";

var React = require('react/addons'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    ReactRouter = require('react-router'),
    Cmp = require('../../components'),
    Constants = require('../../constants'),
    ProjectImageModel = require('../../models/ProjectImage'),
    ListingModelType = require('../../mixins/ListingModelType'),
    RequiredMessage = require('../../partials/RequiredMessage'),
    Validation = require('../../partials/validation');

var ProjectAddEditImage = React.createClass({displayName: "ProjectAddEditImage",

    mixins: [
        ReactRouter.Navigation,
        ListingModelType.ListingNavigation('Image')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            imageModel: new ProjectImageModel(),
            readOnly: false
        }
    },


    updateState: function(props) {
        var params = this.context.router.getCurrentParams(),
            id = params.imageid,
            projectId = this.props.model.id; //params.id;

        this.props.imageModel.clear();

        this.props.imageModel.set({
            id: id,
            listing: {
                id: projectId
            }
        });

        if (id) {
            this.props.imageModel.fetch();
        }

        return {};
    },

    getInitialState: function() {
        return this.updateState(this.props);
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function(nextProps) {
        this.setState(this.updateState());
    },

    onSaveClick: function(e) {
        var me = this,
            filePicker = me.refs.filePicker,
            model = this.props.imageModel,
            isNew = model.isNew();

        model.save(me.refs.form.getValues()).then(function(m, e, r) {
            if(isNew){
                filePicker.setIdUrlPart(model.id);
            }
            if (filePicker.hasFiles()) {
                filePicker.startUpload();
            } else {
                me.backToList();
            }
        });
    },


    render: function() {
        var me = this,
            props = me.props;

        return (
            React.createElement(Validation.Form, {ref: "form", horizontal: false, model: props.imageModel, onSubmit: this.onSaveClick, readOnly: props.readOnly}, 
                React.createElement("h1", null, props.imageModel.id ? 'Edit' : 'Add', " Listing Image"), 
                React.createElement("h5", null, "Use the form below to upload an image for this listing."), 
                React.createElement(RequiredMessage, null), 
                
                React.createElement(Validation.Input, {type: "text", trigger: "onChange", labelClassName: "label", label: "Image Name / Title:", placeholder: "Enter a Name for this Image.", name: "title"}), 
                React.createElement(Validation.Input, {type: "checkbox", labelClassName: "label", label: "Do not provide access to agents to download this image.", name: "private", value: true, useCheckedLink: true}), 
                React.createElement(Validation.Input, {type: "textarea", trigger: "onChange", labelClassName: "label", label: "Image Description:", rows: "3", placeholder: "Provide a description for this Image.", name: "description"}), 
                React.createElement(Cmp.Picker.File, {ref: "filePicker", showPreview: true, trigger: "onChange", name: "files", required: true, url: props.imageModel.url(), label: "Image File:", model: props.imageModel, onComplete: this.onFileUpload, mime_types: [Constants.imageMimeTypes]}), 

                React.createElement(Cmp.ValidationMessage, null), 
                React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                    React.createElement(Cmp.Button.Cancel, {onClick: this.onCancelClick}), 
                    React.createElement(Cmp.Button.Save, {className: "pull-right"})
                )
            )
        );
    },

    onFileUpload: function(success) {
        success && this.backToList();
    },

    onCancelClick: function() {
        this.backToList();
    },

    backToList: function() {
        var me = this;
        me.transitionTo(me.backTo(), me.context.router.getCurrentParams());
    }
});
module.exports = ProjectAddEditImage;
