"use strict";

var CurrentUser = require('../shared/CurrentUser'),
    Constants = require('../constants');

module.exports = {
    hasOneRole: function() {
        var roles = arguments,
            hasRole = false;

        for (var i = 0, len = roles.length; i < len; i++) {
            hasRole = this.hasRole(roles[i]);
            if (hasRole) {
                break;
            }
        }

        return hasRole;
    },

    hasRoles: function() {
        var roles = arguments,
            hasRole = false;

        for (var i = 0, len = roles.length; i < len; i++) {
            hasRole = this.hasRole(roles[i]);
            if (!hasRole) {
                break;
            }
        }

        return hasRole;
    },

    hasRole: function(role) {
        return CurrentUser.hasRole(role);
    },

    isBroker: function() {
        return !!CurrentUser.getEntity().get('isBroker');
    }
};
