"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    Widget = require('../models/Widget');

var Widgets = Collection.extend({
    url: Widget.prototype.urlRoot,
    model: Widget,

    filters: [
        'agentName',
        'username',
        'agentEmail',
        'widgetTypeId',
        'countryId',
        'stateId',
        'metroAreaId',
        'cityId'
    ],

    parse: function(response) {
        var data = Collection.prototype.parse.apply(this, arguments);

        data = data && data.list ? data.list : data;

        if (data && data.length > 1) {
            for (var i = 1, len = data.length; i < len; i++) {
                if (data[i].default) {
                    data.unshift(data.splice(i, 1)[0]);
                    break;
                }
            }
        }

        return data;
    },

    fetch: function(options) {
        options = options || {};
        options.data = options.data || {};

        if (options.agentEmail !== undefined) {
            this.agentEmail = options.agentEmail;
        }

        return Collection.prototype.fetch.call(this, options);
    }

});

module.exports = Widgets;
