"use strict";

var React = require('react'),
    Constants = require('../../constants'),
    BS = require('react-bootstrap'),
    _ = require('underscore'),
    numeral = require('numeral'),
    ProjectContactForm = require('./ProjectContactForm'),
    cx = require('classnames'),
    ProjectsCollection = require('../../collections/Projects'),
    ProjectsMap = require('../../partials/project/ProjectsMap'),
    Cmp = require('../../components');

var ProjectDetail = React.createClass({displayName: "ProjectDetail",
    imagePlaceholder: Constants.placeholder,

    getDefaultProps: function() {
        return {
            model: undefined,
            agent: undefined,
            showAgentDetails: false,
            widget: undefined,
            showSelect: false,
            onSelect: undefined,
            collection: new ProjectsCollection(),
            ProjectMapComponent: ProjectsMap
        };
    },

    getInitialState: function() {
        return {
            summaryExpanded: false,
            showMap: false
        };
    },    

    showProjectDetailsForAgent: function(data){
        var me = this,
            contactInfo = data.contactInfo || {};
        return (
            React.createElement("div", {className: "row margin-bottom-10"}, 
                React.createElement("div", {className: "col-md-2 margin-bottom-10"}, 
                    React.createElement("img", {src: data.image || me.imagePlaceholder, className: "img-responsive img-center", onError: me.loadPlaceholder})
                ), 
                React.createElement("div", {className: "col-md-10"}, 
                    React.createElement("div", {className: "col-md-6 detail-attributes"}, 
                        React.createElement("div", {className: "row no-gutter-md"}, 
                            React.createElement("div", {className: "col-md-4 col-sm-6 col-xs-6 detail-label"}, 
                                React.createElement("i", {className: "fa fa-thumb-tack fa-fw"}), " Location:"
                            ), 
                            React.createElement("div", {className: "col-md-8 col-sm-6 col-xs-6 detail-value"}, 
                                data.locationCompact || data.location
                            )
                        ), 
                        React.createElement("div", {className: "row no-gutter-md"}, 
                            React.createElement("div", {className: "col-md-4 col-sm-6 col-xs-6 detail-label"}, 
                                React.createElement("i", {className: "fa fa-usd fa-fw"}), " Price Range:"
                            ), 
                            React.createElement("div", {className: "col-md-8 col-sm-6 col-xs-6 detail-value"}, 
                                numeral(data.minPrice).format('$0,0[.]00'), " to ", numeral(data.maxPrice).format('$0,0[.]00')
                            )
                        ), 
                        /*
                        <div className="row no-gutter-md">
                            <div className="col-md-4 col-sm-6 col-xs-6 detail-label">
                                <i className="fa fa-phone fa-fw"></i> Phone:
                            </div>
                            <div className="col-md-8 col-sm-6 col-xs-6 detail-value">
                                {contactInfo.phoneMobile && (
                                    <div>{contactInfo.phoneMobile} (Mobile)</div>
                                )}
                                {contactInfo.phoneWork && (
                                    <div>{contactInfo.phoneWork} (Work)</div>
                                )}
                            </div>
                        </div>
                        <div className="row no-gutter-md">
                            <div className="col-md-4 col-sm-6 col-xs-6 detail-label">
                                <i className="fa fa-paper-plane fa-fw"></i> Fax:
                            </div>
                            <div className="col-md-8 col-sm-6 col-xs-6 detail-value">
                                {contactInfo.fax}
                            </div>
                        </div>
                        <div className="row no-gutter-md">
                            <div className="col-md-4 col-sm-6 col-xs-6 detail-label">
                                <i className="fa fa-envelope fa-fw"></i> Email:
                            </div>
                            <div className="col-md-8 col-sm-6 col-xs-6 detail-value">
                                {contactInfo.email}
                            </div>
                        </div>
                        */
                        React.createElement("div", {className: "row no-gutter-md margin-bottom-10"}, 
                            React.createElement("div", {className: "col-md-4 col-sm-6 col-xs-6 detail-label"}, 
                                React.createElement("i", {className: "fa fa-globe fa-fw"}), " Website:"
                            ), 
                            React.createElement("div", {className: "col-md-8 col-sm-6 col-xs-6 detail-value"}, 
                                data.website && (
                                    React.createElement("a", {href: data.website, target: "_blank"}, data.website)
                                )
                            )
                        )
                    ), 
                    React.createElement("div", {className: "col-md-6 detail-attributes"}, 
                        React.createElement("div", {className: "row no-gutter-md"}, 
                            React.createElement("div", {className: "col-md-5 col-sm-6 col-xs-6 detail-label"}, 
                                React.createElement("i", {className: "fa fa-th fa-fw"}), " Type:"
                            ), 
                            React.createElement("div", {className: "col-md-7 col-sm-6 col-xs-6 detail-value"}, 
                                data.listingType
                            )
                        ), 
                        React.createElement("div", {className: "row no-gutter-md"}, 
                            React.createElement("div", {className: "col-md-5 col-sm-6 col-xs-6 detail-label"}, 
                                React.createElement("i", {className: "fa fa-usd fa-fw"}), " Commission Offered:"
                            ), 
                            React.createElement("div", {className: "col-md-7 col-sm-6 col-xs-6 detail-value"}, 
                                data.commissionOffered
                            )
                        ), 
                        React.createElement("div", {className: "row no-gutter-md margin-bottom-10"}, 
                            React.createElement("div", {className: "col-md-5 col-sm-6 col-xs-6 detail-label"}, 
                                React.createElement("i", {className: "fa fa-usd fa-fw"}), " Referral Offered:"
                            ), 
                            React.createElement("div", {className: "col-md-7 col-sm-6 col-xs-6 detail-value"}, 
                                data.outOfStateReferralOffered
                            )
                        )
                    ), 
                    this.props.showSelect && (
                        React.createElement("div", {className: "col-xs-6 margin-bottom-10"}, 
                            React.createElement(BS.Button, {bsStyle: "primary", onClick: this.onSelect}, React.createElement("i", {className: "fa fa-share"}), " Select")
                        )
                    )
                )
            )
        )
    },

    toggleSummaryExpand: function(e) {
        e.preventDefault();

        this.setState({
            summaryExpanded: !this.state.summaryExpanded
        });
    },

    showProjectDetails: function(data){
        var me = this,
            props = me.props,
            state = me.state,
            images = [].concat(data.imageGallery || data.images || []);

        if(props.showAgentDetails){
            return me.showProjectDetailsForAgent(data);
        }

        if(images.length === 0 && data.image) {
            images.push({
                id: 'featured',
                thumbLink: data.image
            });
        }

        for(var i = images.length, len = 3; i < len; i++) {
            images.push({
                id: 'placeholder-' + i,
                thumbLink: Constants.imageRoot + 'ImageComingSoon.png'
            });
        }

        return (
            React.createElement("div", null, 
                React.createElement("div", {className: "row margin-bottom-10"}, 
                    state.showMap && (
                        React.createElement("div", {className: ""}, 
                            React.createElement(props.ProjectMapComponent, {ref: "map", collection: props.collection, id: "project-detail-map"})
                        )
                    ), 
                    !state.showMap && (
                        React.createElement("div", {className: ""}, 
                            images && images.length > 0 ? (
                                React.createElement(Cmp.Carousel, {id: "project-carousel", controls: images.length > 1}, 
                                    _.map(images, function(image, index){
                                        var title = [];
                                        if(image.title){
                                            title.push(image.title);
                                        }
                                        if(image.description){
                                            title.push(image.description);
                                        }
                                        title = title.join(' - ');
                                        return (
                                            React.createElement(Cmp.Carousel.Item, {key: image.id || 'carousel-image-' + index, active: index === 0}, 
                                                React.createElement("img", {className: "img-responsive img-center", src: image.link300px || image.thumbLink || image.link || me.imagePlaceholder, onError: me.loadPlaceholder, alt: title})
                                            )
                                        )
                                    })
                                )
                            ) : (
                                React.createElement("img", {src: data.image || me.imagePlaceholder, className: "img-responsive img-center", onError: me.loadPlaceholder})
                            )
                        )
                    )
                ), 
                React.createElement("div", {className: "row margin-bottom-10"}, 
                    React.createElement("div", {className: "col-md-7"}, 
                        React.createElement("div", {className: cx('summary-text', { "summary-text-truncate": !state.summaryExpanded })}, 
                            React.createElement("p", null, 
                                data.summary, 
                                React.createElement("a", {href: "#", onClick: me.toggleSummaryExpand, className: "btn btn-link btn-block summary-expand"}, state.summaryExpanded ? '... Read Less' : '... Read More')
                            )
                        )
                    ), 
                    props.model.listingModelType === 'developer' ? (
                        React.createElement("div", {className: "col-md-5 detail-attributes"}, 
                            React.createElement("div", {className: "row padding-left-md-0"}, 
                                React.createElement("div", {className: "col-xs-12 font-bold"}, 
                                    data.communityListingCount, " Communities"
                                )
                            ), 
                            React.createElement("div", {className: "row padding-left-md-0"}, 
                                React.createElement("div", {className: "col-xs-12 font-bold"}, 
                                    data.listingType
                                )
                            ), 
                            React.createElement("div", {className: "row padding-left-md-0"}, 
                                React.createElement("div", {className: "col-xs-12 font-bold"}, 
                                    "From: ", data.minPrice && data.minPrice != 0 ? ('Starting at ' + numeral(data.minPrice).format('$0,0[.]00')) : 'Please ask'
                                )
                            ), 
                            React.createElement(Cmp.Tracker, {params: { widgetId: props.widget ? props.widget.id : undefined, agentId: props.widget ? props.widget.agentModel.id : undefined, projectId: data.id, type: 'project-detail'}, timestamp: props.trackerId})
                        )
                    ) : (
                        React.createElement("div", {className: "col-md-5 detail-attributes"}, 
                            React.createElement("div", {className: "row padding-left-md-0"}, 
                                React.createElement("div", {className: "col-md-4 col-sm-6 col-xs-6 detail-label"}, 
                                    React.createElement("i", {className: "fa fa-thumb-tack fa-fw"}), " Location:"
                                ), 
                                React.createElement("div", {className: "col-md-8 col-sm-6 col-xs-6 detail-value"}, 
                                    data.locationCompact || data.location
                                )
                            ), 
                            React.createElement("div", {className: "row padding-left-md-0"}, 
                                React.createElement("div", {className: "col-md-4 col-sm-6 col-xs-6 detail-label"}, 
                                    React.createElement("i", {className: "fa fa-usd fa-fw"}), " Price:"
                                ), 
                                React.createElement("div", {className: "col-md-8 col-sm-6 col-xs-6 detail-value"}, 
                                    data.minPrice && data.minPrice != 0 ? ('Starting at ' + numeral(data.minPrice).format('$0,0[.]00')) : 'Please ask'
                                )
                            ), 
                            React.createElement("div", {className: "row padding-left-md-0 margin-bottom-10"}, 
                                React.createElement("div", {className: "col-md-4 col-sm-6 col-xs-6 detail-label"}, 
                                    React.createElement("i", {className: "fa fa-th fa-fw"}), " Type:"
                                ), 
                                React.createElement("div", {className: "col-md-8 col-sm-6 col-xs-6 detail-value"}, 
                                    data.listingType
                                )
                            ), 
                            React.createElement("div", {className: "row padding-left-md-0-md"}, 
                                this.props.model.hasServiceType('AGENT_PORTAL') && data.agentTrackerApproved && (
                                    React.createElement("div", {className: "col-xs-12 margin-bottom-10"}, 
                                        React.createElement("a", {href: data.websiteRedirect, target: "_blank", className: "btn-u btn-u-orange btn-block btn-details"}, 
                                            React.createElement("i", {className: "fa fa-external-link fa-fw"}), " Go to website"
                                        )
                                    )
                                ), 
                                !me.hideMap && (
                                    React.createElement("div", {className: "col-xs-12 margin-bottom-10"}, 
                                        React.createElement("a", {href: "#", onClick: me.toggleMap, className: "btn-u btn-u-orange btn-u-inverted btn-block btn-details"}, 
                                            React.createElement("i", {className: 'fa fa-fw ' + (state.showMap ? 'fa-picture-o' : 'fa-map-marker')}), " ", state.showMap ? 'Show Image Gallery' : 'View on Map'
                                        )
                                    )
                                ), 
                                props.showSelect && (
                                    React.createElement("div", {className: "col-xs-12 margin-bottom-10"}, 
                                        React.createElement(BS.Button, {bsStyle: "primary", className: "btn-block", onClick: me.onSelect}, React.createElement("i", {className: "fa fa-share"}), " Select")
                                    )
                                )
                            ), 
                            React.createElement(Cmp.Tracker, {params: { widgetId: props.widget ? props.widget.id : undefined, agentId: props.widget ? props.widget.agentModel.id : undefined, projectId: data.id, type: 'project-detail'}, timestamp: props.trackerId})
                        )
                    )
                ), 
                React.createElement("div", {className: "row margin-bottom-10"}, 
                    React.createElement("div", {className: "col-xs-12 col-md-5 col-md-push-7"}, 
                        React.createElement("div", {className: "promotions row"}, 
                            data.hasFeatured && (
                                React.createElement("div", {className: "col-xs-4"}, 
                                    React.createElement("i", {className: "fa fa-star featured", title: "Featured"}), React.createElement("span", {className: "featured"}, "Featured"), "  "
                                )
                            ), 
                            data.hasBuyerSpecial && (
                                React.createElement("div", {className: "col-xs-4 padding-0"}, 
                                    React.createElement("i", {className: "fa fa-usd buyer_specials", title: "Buyer Special"}), React.createElement("span", {className: "buyer_specials"}, "Buyer Specials"), "  "
                                )
                            ), 
                            data.hasFlyAndBuySpecial && (
                                React.createElement("div", {className: "col-xs-4"}, 
                                    React.createElement("i", {className: "fa fa-paper-plane fly_and_by_specials", title: "Fly & Buy"}), React.createElement("span", {className: "fly_and_by_specials"}, "Fly & Buy")
                                )
                            )
                        )
                    )
                )
            )
        );
    },

    toggleMap: function(e) {
        if(e) {
            e.preventDefault();
        }

        this.props.collection.reset([this.props.model]);

        this.setState({
            showMap: !this.state.showMap
        });
    },

    showProjectAdditionalDetails: function(data){
        return ( 
            React.createElement("div", null, 
                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-xs-12"}, 
                        React.createElement("div", {className: "headline"}, 
                            React.createElement("h2", null, "Registration Process:")
                        )
                    ), 
                    React.createElement("div", {className: "col-xs-12"}, 
                        React.createElement("p", null, data.clientRegistrationPolicy)
                    )
                ), 
                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-xs-12"}, 
                        React.createElement("div", {className: "headline"}, 
                            React.createElement("h2", null, "Related Documents:")
                        )
                    ), 
                    React.createElement("div", {className: "col-xs-12"}, 
                        React.createElement("div", {className: "row"}, 
                            _.map(data.documents, function(doc){
                                return (
                                    React.createElement("div", {key: doc.id, className: "col-xs-6"}, 
                                        React.createElement("a", {href: doc.link, target: "_blank"}, 
                                            doc.title
                                        )
                                    )
                                );
                            })
                        )
                    )
                )
            )
        );
    },

    showContactForm: function(data){
        var me = this,
            props = me.props,
            message = (React.createElement("div", null, "Complete the form to get more details about ", React.createElement("span", {className: "blue-text"}, props.model.get('name')), " including any buyer incentives and specials."));

        return (
            React.createElement(ProjectContactForm, {singleColumn: true, hideFormLabels: true, model: props.model, agent: props.agent, widget: props.widget, message: message})
        );
    },

    render: function() {
        var me = this,
            props = me.props,
            data = props.model ? props.model.toJSON({
                includeImages: true,
                includeDocuments: true
            }) : {};

        return (
            React.createElement("div", null, 
               React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-md-12 project-listing"}, 
                        me.showProjectDetails(data)
                    )
                ), 
                me.props.showAgentDetails ? me.showProjectAdditionalDetails(data) : me.showContactForm(data)
                
            )
        );
    },

    close: function(e) {
        if(this.props.onClose){
            this.props.onClose(e);
        }
    },

    loadPlaceholder: function(e) {
        var img = e.currentTarget;
        img.src = this.imagePlaceholder;
    },

    onSelect: function(e){
        if(this.props.onSelect){
            this.props.onSelect(e);
        }
    },

    componentDidUpdate: function() {
        if(this.state.showMap && this.refs.map) {
            this.refs.map.centerMap();
        }
    }
});

module.exports = ProjectDetail;
