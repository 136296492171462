"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    _ = require('underscore'),

    DocumentTitle = require('react-document-title'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    NavLink = require('../../components/NavLink'),
    Cmp = require('../../components'),
    SidebarNav = require('../../partials/SidebarNav'),
    Constants = require('../../constants'),
    FluxBone = require('../../mixins/FluxBone'),
    AgentModel = require('../../models/Agent'),
    WidgetModel = require('../../models/Widget'),
    Widgets = require('../../collections/Widgets'),
    ReactRouter = require('react-router'),
    CurrentUser = require('../../shared/CurrentUser'),
    moment = require('moment'),
    DeleteDialog = require('../../partials/DeleteDialog'),
    Pagination = require('../../partials/Pagination'),
    Validation = require('../../partials/validation'),
    toastr = require('toastr'),
    cx = require('classnames'),
    Link = ReactRouter.Link;

var AgentWidgets = React.createClass({displayName: "AgentWidgets",

    mixins: [
        FluxBone.CollectionMixin('store', 'sync change remove add', 10),
        React.addons.LinkedStateMixin,
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var query = this.context.router.getCurrentQuery(),
            store = this.props.store;

        store.pageNum = query.page || 1;
        store.setFilters(query, {
            resetFilters: true
        });
        store.setFilter('username', CurrentUser.isAdmin() ? undefined : CurrentUser.get('username'));
        store.throttledFetch();

        return store.getFilterValues();
    },

    getInitialState: function() {
        var state = this.updateState();

        state.filtersVisible = true;

        return state;
    },

    getDefaultProps: function() {
        return {
            store: new Widgets(),
            emptyMessage: 'There are no widgets available.'
        };
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    toggleFilters: function(e){
        if(e && e.preventDefault){
            e.preventDefault();
        }

        this.setState({
            filtersVisible: !this.state.filtersVisible
        });
    },

    doSearch: function(e){
        var me = this,
            query = {
                page: 1
            },
            state = me.state;

        if(e && e.preventDefault){
            e.preventDefault();
        }

        _.each(me.props.store.filters, function(filter){
            query[filter] = state[filter];
        });

        this.transitionTo('Widgets', undefined, query);
    },

    onDelete: function(widget, e) {
        e.preventDefault();

        this.setState({
            modelToDelete: widget
        });
    },
    onHideDeleteDialog: function() {
        this.setState({
            modelToDelete: undefined
        });
    },
    afterDelete: function(model) {
        var name = model.get('name');

        toastr.success('Success! <br/> Your widget, ' + name + ', has been successfully deleted.');
    },

    render: function() {
        var me = this,
            filterHeader = (
                React.createElement("div", null, 
                    React.createElement("h4", {className: "panel-title"}, 
                        "Filters:", 
                        React.createElement("div", {className: "pull-right", title: "Toggle Filter Visibility"}, 
                            React.createElement("i", {className: 'fa fa-fw ' + (this.state.filtersVisible ? 'fa-minus' : 'fa-plus')})
                        )
                    )
                )
            ),
            expanded = this.state.filtersVisible,
            props = me.props,
            state = me.state,
            model = props.model,
            title = 'My Widgets',
            entity = CurrentUser.getEntity(),
            defaultLevel = entity.get('defaultAgentLevel') || 'BASIC',
            level = entity.get('agentLevel') || 'BASIC',
            optionClassName = 'pricing-v2 membership-pricing ',
            activeClassName = 'membership-pricing-active';

        return (
            React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: 'NCH - ' + title}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "My Widgets"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(NavLink, {to: "Widgets"}, "My Widgets")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement("div", {className: "row"}, 
                        React.createElement("div", {className: "col-md-3 md-margin-bottom-40"}, 
                            React.createElement(SidebarNav, null)
                        ), 
                        React.createElement("div", {className: "col-md-9"}, 
                            React.createElement("div", {className: "row half-gutter"}, 
                                React.createElement("div", {className: "col-md-8"}, 
                                    React.createElement("h1", null, title), 
                                    React.createElement("h5", null, "Below is a list of your widgets.")
                                ), 
                                React.createElement("div", {className: "col-md-4"}, 
                                    React.createElement("h1", null, 
                                        defaultLevel === 'BASIC' && props.store.isLoaded() && props.store.length >= 1 && (
                                            React.createElement(BS.Button, {bsStyle: "primary", onClick: me.showAgentUpgradeMessage}, 
                                                React.createElement("i", {className: "fa fa-plus"}), " Create New Widget"
                                            )
                                        ), 

                                        (defaultLevel === 'PRO' || !props.store.isLoaded() || props.store.length < 1) && (
                                            React.createElement(Link, {to: "WidgetAdd", className: "pull-right"}, 
                                                React.createElement(BS.Button, {bsStyle: "primary"}, 
                                                    React.createElement("i", {className: "fa fa-plus"}), " Create New Widget"
                                                )
                                            )
                                        )
                                    )
                                ), 
                                React.createElement("div", {className: "col-md-12 text-right"}, 
                                    React.createElement("span", {className: "legend"}, 
                                        React.createElement("div", null, React.createElement("b", null, "Legend:")), 
                                        React.createElement("div", null, React.createElement("i", {className: "fa fa-edit"}), " Edit Widget"), 
                                        React.createElement("div", null, React.createElement("i", {className: "fa fa-code"}), " Get Code"), 
                                        React.createElement("div", null, React.createElement("i", {className: "fa fa-trash-o"}), " Delete Widget"), 
                                        React.createElement("div", null, React.createElement("i", {className: "fa fa-eye"}), " Preview Widget")
                                    )
                                )
                            ), 
                            CurrentUser.hasRole(Constants.roles.ADMIN) && (
                                React.createElement(BS.Panel, {className: 'panel-table' + (expanded ? 'panel-table-expanded' : ''), collapsible: true, header: filterHeader, expanded: expanded, onSelect: this.toggleFilters}, 
                                    React.createElement("form", {onSubmit: this.doSearch}, 
                                        React.createElement("div", {className: "row"}, 
                                            React.createElement(Validation.Input, {type: "text", valueLink: this.linkState('agentName'), groupClassName: "col-sm-6", placeholder: "Search by Agent Name"}), 
                                            React.createElement(Validation.Input, {type: "text", valueLink: this.linkState('agentEmail'), groupClassName: "col-sm-6", placeholder: "Search by Email"}), 
                                            React.createElement(Validation.Input, {type: "select", valueLink: this.linkState('widgetTypeId'), groupClassName: "col-sm-6", placeholder: "Widget Type"}, 
                                                React.createElement("option", {value: ""}, "Widget Type"), 
                                                React.createElement("option", {value: "7"}, "Automatic Map"), 
                                                React.createElement("option", {value: "1"}, "Manual List"), 
                                                React.createElement("option", {value: "6"}, "Manual Gallery")
                                            )
                                        ), 
                                        React.createElement("div", {className: "row"}, 
                                            React.createElement(Cmp.Select.Country, {name: "countryId", valueLink: this.linkState('countryId'), groupClassName: "col-sm-3"}), 
                                            React.createElement(Cmp.Select.State, {name: "stateId", valueLink: this.linkState('stateId'), countryId: state.countryId, groupClassName: "col-sm-3"}), 
                                            React.createElement(Cmp.Select.Metro, {name: "metroAreaId", valueLink: this.linkState('metroAreaId'), stateId: state.stateId, groupClassName: "col-sm-3"}), 
                                            React.createElement(Cmp.Select.City, {name: "cityId", valueLink: this.linkState('cityId'), stateId: state.stateId, metroId: state.metroAreaId, groupClassName: "col-sm-3"}), 
                                            React.createElement("div", {className: "col-xs-12"}, 
                                                React.createElement(BS.Button, {className: "pull-right", type: "submit"}, "Search")
                                            )
                                        )
                                    )
                                )
                            ), 
                            React.createElement(Pagination, {store: this.props.store, to: "Widgets", query: this.props.store.getFilterValues()}), 
                            React.createElement(BS.Table, {borderless: true, condensed: true, className: "tss-table-vertically-centered"}, 
                                React.createElement("thead", null, 
                                React.createElement("tr", null, 
                                    React.createElement("th", null, "Widget Name"), 
                                    React.createElement("th", null, "Widget Type"), 
                                    React.createElement("th", null)
                                )
                                ), 
                                React.createElement("tbody", null, 
                                    props.store.map(function(widget) {
                                        var isDefault = widget.get('default');
                                        return (
                                            React.createElement("tr", {key: widget.id, className: isDefault ? 'default-widget' : ''}, 
                                                React.createElement("td", null, 
                                                isDefault && (
                                                    React.createElement("i", {className: "fa fa-check color-green"})
                                                ), 
                                                    " ", widget.get('name')
                                                ), 
                                                React.createElement("td", null, 
                                                    widget.get('widgetType').name
                                                ), 
                                                React.createElement("td", {className: "text-right actions-column"}, 
                                                    React.createElement(Link, {key: "WidgetEditButton", to: "WidgetEdit", title: "Edit Widget", params: { id: widget.id}}, 
                                                        React.createElement("i", {className: "icon-custom  icon-color-u icon-sm fa fa-edit"})
                                                    ), 
                                                    React.createElement(Link, {key: "WidgetCodeButton", to: "WidgetCode", title: "Get Code", params: { id: widget.id}}, 
                                                        React.createElement("i", {className: "icon-custom  icon-color-u icon-sm fa fa-code"})
                                                    ), 
                                                    !widget.get('default') && (
                                                        React.createElement("a", {href: "#", onClick: me.onDelete.bind(null, widget), title: "Delete Widget", key: "WidgetDeleteButton"}, 
                                                            React.createElement("i", {className: "icon-custom  icon-color-u icon-sm fa fa-trash-o"})
                                                        )
                                                    ), 
                                                    React.createElement("a", {href: widget.getPreviewUrl(), target: "_blank", title: "Preview Widget", key: "WidgetPreviewButton"}, 
                                                        React.createElement("i", {className: "icon-custom  icon-color-u icon-sm fa fa-eye"})
                                                    )
                                                )
                                            )
                                        );
                                    })
                                )
                            ), 
                            props.store.length === 0 && (
                                React.createElement(BS.Alert, null, 
                                    React.createElement("p", {className: "text-center"}, 
                                        props.emptyMessage
                                    )
                                )
                            ), 
                            React.createElement(Pagination, {store: this.props.store, to: "Widgets", noMargin: true, query: this.props.store.getFilterValues()})
                        )
                    )
                ), 
                React.createElement(PageFooter, null), 

                React.createElement(DeleteDialog, {
                        msg: "Please confirm that you would like to DELETE your widget,", 
                        title: "ALERT! You are about to DELETE a widget.", 
                        deleteLabel: "Delete Widget", 
                        modelToDelete: state.modelToDelete, 
                        onHide: me.onHideDeleteDialog, 
                        modelTitle: state.modelToDelete && state.modelToDelete.get('name'), 
                        onDelete: me.afterDelete}), 

                React.createElement(BS.Modal, {show: !!this.state.showAgentUpgradeMessage, onHide: this.hideAgentUpgradeMessage}, 
                    React.createElement(BS.Modal.Header, {closeButton: true}, 
                        React.createElement(BS.Modal.Title, null, "Widget Limit Reached!")
                    ), 
                    React.createElement(BS.Modal.Body, null, 
                        React.createElement("p", null, 
                            "Hi ", CurrentUser.getEntity().getDisplayName(true), ". You currently have a free Basic Agent Account which includes 1 widget. Please upgrade to a Premium Agent Account to create more widgets and access premium features."
                        ), 
                        React.createElement("div", {className: "row pricing-table-v2"}, 
                            React.createElement("div", {className: "col-xs-12 col-sm-6"}, 
                                React.createElement("div", {className: cx(optionClassName, 'pricing-v2-grey', { 'membership-pricing-active': defaultLevel === 'BASIC' })}, 
                                    React.createElement("div", {className: "pricing-v2-head"}, 
                                        React.createElement("h4", {className: "text-center"}, "Basic Agent Account", React.createElement("br", null), React.createElement("b", null, "Free"))
                                    ), 
                                    React.createElement("ul", {className: "list-unstyled pricing-v2-content"}, 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-check-circle-o"}), " 1 New Construction Widget"), 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-check-circle-o"}), " Basic Branding"), 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-check-circle-o"}), " Basic Customer Support")
                                    )
                                )
                            ), 
                            React.createElement("div", {className: "col-xs-12 col-sm-6"}, 
                                React.createElement("div", {className: cx(optionClassName, 'pricing-v2-blue', { 'membership-pricing-active': defaultLevel === 'PRO' })}, 
                                    React.createElement("div", {className: "pricing-v2-head"}, 
                                        React.createElement("h4", {className: "text-center"}, "Premium Agent Account", React.createElement("br", null), React.createElement("b", null, "$19/month"))
                                    ), 
                                    React.createElement("ul", {className: "list-unstyled pricing-v2-content"}, 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-diamond"}), " Unlimited New Construction Widgets"), 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-diamond"}), " Premium Branding"), 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-diamond"}), " Include Profile Photo on Listings"), 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-diamond"}), " Include Phone Number on Listings"), 
                                        React.createElement("li", null, React.createElement("span", {className: "fa fa-diamond"}), " Premium Customer Support")
                                    )
                                )
                            )
                        ), 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-xs-12 col-sm-6"}, 
                                React.createElement("div", {className: "text-center"}, 
                                    React.createElement("a", {className: "btn-link btn-block", style: { 'paddingTop': '12px'}, onClick: this.setLevel.bind(null, 'BASIC')}, 
                                        "You currently have a Basic Agent Account."
                                    )
                                )
                            ), 
                            React.createElement("div", {className: "col-xs-12 col-sm-6"}, 
                                React.createElement("div", {className: "text-center"}, 
                                    React.createElement("a", {href: "#", className: "btn-u btn-u-blue btn-block", onClick: this.setLevel.bind(null, 'PRO')}, "Upgrade to Premium Agent")
                                )
                            )
                        )
                    ), 
                    React.createElement(BS.Modal.Footer, null, 
                        React.createElement(BS.Button, {onClick: this.hideAgentUpgradeMessage}, "Close")
                    )
                )
            )
        );
    },

    showAgentUpgradeMessage: function() {
        this.setState({
            showAgentUpgradeMessage: true
        });
		
        if (window.analytics) {
            analytics.track('VIEW-WIDGETLIMIT');
        }
		
    },

    hideAgentUpgradeMessage: function() {
        this.setState({
            showAgentUpgradeMessage: false
        });  
    },

    setLevel: function(level, e) {
        var me = this,
            entity = CurrentUser.getEntity();

        e.preventDefault();

        if (level !== entity.get('agentLevel')) {
            entity.set('agentLevel', level);

            this.transitionTo('UserMembership');
        }
    }
});

module.exports = AgentWidgets
