"use strict";

var React = require('react'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    DocumentTitle = require('react-document-title'),
    CurrentUser = require('../../../shared/CurrentUser'),
    Constants = require('../../../constants'),
    CompanyImage = require('../account/CompanyImage'),
    Cmp = require('../../../components'),
    Link = ReactRouter.Link;

var Image = React.createClass({displayName: "Image",
    mixins: [
        ReactRouter.Navigation
    ],
    contextTypes: {
        router: React.PropTypes.func
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state;

        return (
            React.createElement("div", null, 
                React.createElement(DocumentTitle, {title: "NCH - Signup - Company Image"}), 
                React.createElement(CompanyImage, {onSave: this.onSave, backBtnTo: "", model: CurrentUser.getEntity(), saveText: "Next"}), 
                this.props.Footer && (
                    React.createElement(this.props.Footer, {trackerId: Constants.trackerPrefix + 'signup-company-image'})
                )
            )
        );
    },

    onSave: function(){
        this.transitionTo('ProjectList');
    }
});
module.exports = Image;
