"use strict";

var React = require('react'),
    FluxBone = require('../../mixins/FluxBone'),
    $ = require('jquery'),
    _ = require('underscore'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    Constants = require('../../constants'),
    Cmp = require('../../components')

var ProjectList = React.createClass({displayName: "ProjectList",

    imagePlaceholder: Constants.placeholder,

    mixins: [
        FluxBone.CollectionMixin('store', 'sync')
    ],

    getDefaultProps: function() {
        return {

            showSelect: false,
            onItemClick: undefined,
            onSelectClick: undefined
        }
    },

    getInitialState: function() {
        return {};
    },

    render: function() {
        var me = this,
            props = me.props,
            selectionValue = props.selectionValue;
        return (
           React.createElement("div", {className: "row"}, 
                React.createElement("div", {className: "col-md-12 projects-listing-list"}, 
                    props.store.map(function(project){
                        var data = project.toJSON(),
                            image = data.image || me.imagePlaceholder,
                            selectDisabled = selectionValue && !!_.find(selectionValue, function(item) {
                                return item.id === project.id;
                            });

                        return (
                            React.createElement("div", {key: data.id, className: "projects-listing-list-item"}, 
                                React.createElement("div", {className: "promotions"}, 
                                    data.hasFeatured && (
                                        React.createElement("i", {className: "fa fa-star featured", title: "Featured"})
                                    ), 
                                    data.hasBuyerSpecial && (
                                        React.createElement("i", {className: "fa fa-usd buyer_specials", title: "Buyer Special"})
                                    ), 
                                    data.hasFlyAndBuySpecial && (
                                        React.createElement("i", {className: "fa fa-paper-plane fly_and_by_specials", title: "Fly & Buy"})
                                    )
                                ), 
                                React.createElement("div", {className: "row"}, 
                                    React.createElement("div", {className: "listing-img-wrap"}, 
                                        /* <img className="img-responsive" src={image} onError={me.loadPlaceholder}/> */
                                        React.createElement("div", {className: "listing-img", style: { backgroundImage: 'url('+ image +')'}, onClick: me.onItemClick.bind(null, project)}), 
                                        React.createElement("p", {className: "location-info"}, 
                                            data.locationCompact || (data.locationInfo.city.name + ', ' + data.locationInfo.city.state.abbr)
                                        )
                                    ), 
                                    React.createElement("div", {className: "col-xs-12 listing-description"}, 
                                        React.createElement("h3", {className: "margin-top-0"}, 
                                            React.createElement("a", {"data-id": data.id, onClick: me.onItemClick.bind(null, project)}, 
                                                React.createElement("span", null, data.name)
                                            )
                                        ), 
                                        React.createElement("p", {className: "summary margin-bottom-10"}, 
                                            data.summary
                                        )
                                    )
                                ), 
                                React.createElement("div", {className: "actions"}, 
                                    React.createElement(BS.Button, {bsStyle: "primary", "data-id": data.id, onClick: me.onItemClick.bind(null, project)}, "View Details"), " ", 
                                    props.showSelect && (
                                        React.createElement(BS.Button, {bsStyle: "primary", disabled: selectDisabled, "data-id": data.id, onClick: me.onSelect.bind(null, project)}, React.createElement("i", {className: "fa fa-share"}), " Select")
                                    ), 
                                    props.mode !== 'create' && (
                                        React.createElement(Cmp.Tracker, {timestamp: props.trackerId, params: { widgetId: props.widget ? props.widget.id : undefined, agentId: props.widget ? props.widget.agentModel.id : undefined, projectId: data.id, type: 'project-list-item'}})
                                    )
                                )
                            )
                        );
                    })
                )
           )
        );
    },

    loadPlaceholder: function(e) {
        var img = e.currentTarget;
        img.src = this.imagePlaceholder;
    },

    onItemClick: function(project, e) {
        if (this.props.onItemClick) {
            e.preventDefault();
            this.props.onItemClick(project, e);
        }
    },

    onSelect: function(project, e) {
        if (this.props.onSelect) {
            e.preventDefault();
            this.props.onSelect(project, e);
        }
    }
});

module.exports = ProjectList;
