"use strict";

var React = require('react'),
    $ = require('jquery'),
    _ = require('underscore'),
    BS = require('react-bootstrap'),
    ValidationMixin = require('../partials/validation/ValidationMixin'),
    Validation = require('../partials/validation');

var LocationSelect = React.createClass({displayName: "LocationSelect",

    mixins: [
        ValidationMixin
    ],

    getDefaultProps: function() {
        return {
            value: undefined,
            valueLink: undefined,
            onChange: undefined,
            precision: 4
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    componentWillReceiveProps: function(props) {
        this.setState(this.updateState(props));
    },

    updateState: function(props) {
        var me = this,
            state = me.state || {},
            newState = {},
            displayValue = me.getDisplayValue(props);

        props = props || me.props;

        if (props.value !== state.value || state.displayValue === undefined) {
            newState.value = props.value;
            newState.displayValue = this.getDisplayValue(props);
        }

        return newState;
    },

    componentDidMount: function() {
        var me = this,
            input = $(React.findDOMNode(this)).find('input').get(0),
            autocomplete = new google.maps.places.Autocomplete(input, { types: ['geocode'] });

        this.input = input;
        this.autocomplete = autocomplete;

        autocomplete.addListener('place_changed', this.onPlaceChanged);
    },


    render: function() {
        var props = _.omit(this.props, 'value', 'onKeyPress', 'valueLink');

        return (
            React.createElement(Validation.Input, React.__spread({ref: "input"},  props, {value: this.state.displayValue, onChange: this.updateDisplayValue, onKeyPress: this.onAddressKeyPress, placeholder: "Enter your address."}))
        );
    },

    getDisplayValue: function(props) {
        var value = this.getValue(),
            displayValue = [];

        props = props || this.props;

        if (value) {
            displayValue.push(value.address1, value.city.name, value.city.state.name, value.city.country.name);
        }

        return _.compact(displayValue).join(', ') || undefined;
    },

    updateDisplayValue: function(e) {
        var me = this,
            value = e && e.target ? e.target.value : e;

        me.setState({
            displayValue: value
        });

        if (value === '') {
            me.clearValue(e);
        }
    },

    onAddressKeyPress: function(e) {
        if (e.key === 'Enter') {
            // Block form submission when using the address auto complete
            e.stopPropagation();
            e.preventDefault();

            this.updateDisplayValue(e);
        }
    },

    componentWillUnmount: function() {
        this.autocomplete.unbind('place_changed', this.onPlaceChanged);

        this.input = this.autocomplete = undefined;
    },

    onPlaceChanged: function() {
        var me = this,
            props = me.props,
            value = me.getValue(),
            autocomplete = me.autocomplete,
            place = autocomplete.getPlace(),
            address = [],
            country, state, city, zip,
            newGeoPosition,
            newValue;

        _.each(place.address_components, function(component) {
            var types = component.types;
            if (address.length < 2 && (types.indexOf('street_number') === 0 || types.indexOf('route') === 0)) {
                address.push(component.long_name);
            } else if (!city && types.indexOf('locality') === 0) {
                city = component.long_name;
            } else if (types.indexOf('administrative_area_level_1') === 0) {
                state = component.long_name;
            } else if (types.indexOf('country') === 0) {
                country = component.long_name;
            } else if (types.indexOf('postal_code') === 0) {
                zip = component.long_name;
            }
        });

        if (place && place.geometry && place.geometry.location) {
            newGeoPosition = {
                latitude: parseFloat(place.geometry.location.lat().toFixed(props.precision)),
                longitude: parseFloat(place.geometry.location.lng().toFixed(props.precision))
            };

            me.setGeoPosition(newGeoPosition);
        }

        newValue = $.extend({}, value, {
            address1: address.join(' '),
            zip: zip,
            city: {
                id: null,
                byName: true,
                name: city,
                state: {
                    id: null,
                    name: state
                },
                country: {
                    id: null,
                    name: country
                }
            }
        });

        me.setValue(newValue);
        me.updateDisplayValue(this.input.value);
        me.onValidateEvent();
    },

    clearValue: function(e) {
        var me = this,
            value = me.getValue(),
            newValue;

        newValue = $.extend({}, value, {
            address1: '',
            zip: '',
            city: {
                id: null,
                name: '',
                state: {
                    id: null,
                    name: ''
                },
                country: {
                    id: null,
                    name: ''
                }
            }
        });

        me.setValue(newValue);
        me.onValidateEvent(e);
    },

    getValue: function(props) {
        var me = this,
            props = props || me.props,
            state = me.state || {},
            defaultValue = { city: { state: {}, country: {} } },
            value;

        if (props.valueLink) {
            value = $.extend(true, defaultValue, props.valueLink.value);
        } else {
            value = $.extend(true, defaultValue, state.value || {});
        }

        return value;
    },

    setValue: function(value) {
        var me = this,
            props = me.props;

        if (props.valueLink && props.valueLink.requestChange) {
            props.valueLink.requestChange(value);
        } else {
            me.setState({
                value: value
            });
        }
    },

    setGeoPosition: function(geoPosition) {
        var me = this,
            props = me.props;

        if (props.geoLink && props.geoLink.requestChange) {
            props.geoLink.requestChange(geoPosition);
        }
    }
});

module.exports = LocationSelect;
