"use strict";

var _ = require('underscore');

/**
 * Simple underscore function to create secure inmutable (recursive deep copy) JavaScript Objects 
 * @param {Object/Array} obj Target Object. Required
 * @param {Number} depth Depth level. Defaults to 1
 * @return {Object/Array) Super inmutable new Object
 * @method deepClone
 */
_.deepClone = function deepClone(obj, depth) {
    var clone, key;
    depth = depth || 1;
    if (typeof obj !== 'object' || obj === null) {
        return obj; }
    if (_.isString(obj)) {
        return obj.splice(); }
    if (_.isDate(obj)) {
        return new Date(obj.getTime()); }
    if (_.isFunction(obj.clone)) {
        return obj.clone(); }
    clone = _.isArray(obj) ? obj.slice() : _.extend({}, obj);
    if (depth !== undefined && depth > 0) {
        for (key in clone) {
            clone[key] = deepClone(clone[key], depth - 1);
        }
    }
    return clone;
}

function getIn(object, path) {
    var stack = path.split('.');
    while (stack.length > 1) {
        object = object[stack.shift()];
    }
    return object[stack.shift()];
}

module.exports = {

    ModelMixin: function(model_name, event_name, buffer) {
        var eventCallbackName = model_name + '_' + event_name,
            linkToStateName = model_name + 'LinkToState',
            linkToStateOptions = model_name + 'LinkToStateOptions',
            getStateName = model_name + 'GetState',
            updateStateName = model_name + 'UpdateState',
            addEventsName = model_name + 'AddEvents',
            removeEventsName = model_name + 'RemoveEvents',
            timeoutName = model_name + 'TimeoutID',
            mixin = {
                componentWillReceiveProps: function(props) {
                    var model = getIn(this.props, model_name),
                        newModel = getIn(props, model_name);

                    if (model !== newModel) {
                        this[removeEventsName](model);
                        this[addEventsName](newModel);
                    }
                },
                getInitialState: function() {
                    return this[getStateName]();
                },
                componentWillMount: function() {
                    this.setState(this[getStateName]());
                },
                componentDidMount: function() {
                    this[addEventsName](getIn(this.props, model_name));
                },
                componentWillUnmount: function() {
                    this[removeEventsName](getIn(this.props, model_name));
                }
            };

        mixin[addEventsName] = function(model) {
            if (model) {
                model.on(event_name, this[eventCallbackName]);
            }
        };

        mixin[removeEventsName] = function(model) {
            if (model) {
                model.off(event_name, this[eventCallbackName]);
            }
        };

        mixin[getStateName] = function() {
            var state = {};
            if (this.props[linkToStateName]) {
                state[model_name] = getIn(this.props, model_name).toJSON(this.props[linkToStateOptions]);
            }
            return state;
        };

        mixin[eventCallbackName] = function() {
            var me = this;
            clearTimeout(this[timeoutName]);
            this[timeoutName] = setTimeout(function() {
                var state;

                if (!me.isMounted()) {
                    return;
                }

                if (me[updateStateName] || me.props[linkToStateName]) {
                    state = {};
                    state[model_name] = _.deepClone(getIn(me.props, model_name).toJSON(me.props[linkToStateOptions]));
                }

                if (me.props[linkToStateName]) {
                    me.setState(state, function() {
                        if (me[updateStateName]) {
                            me[updateStateName](state[model_name]);
                        }
                    });
                } else {
                    me.forceUpdate();

                    if (me[updateStateName]) {
                        me[updateStateName](state[model_name]);
                    }
                }
            }, buffer || 0);
        };

        return mixin;
    },

    CollectionMixin: function(collection_name, event_name, buffer) {
        var eventCallbackName = collection_name + '_' + event_name,
            getStateName = collection_name + 'GetState',
            addEventsName = collection_name + 'AddEvents',
            removeEventsName = collection_name + 'RemoveEvents',
            timeoutName = collection_name + 'TimeoutID',
            mixin = {
                componentWillReceiveProps: function(props) {
                    var collection = getIn(this.props, collection_name),
                        newCollection = getIn(props, collection_name);

                    if (collection !== newCollection) {
                        this[removeEventsName](collection);
                        this[addEventsName](newCollection);
                    }
                },
                componentWillMount: function() {
                    this[addEventsName](getIn(this.props, collection_name));
                },
                componentWillUnmount: function() {
                    this[removeEventsName](getIn(this.props, collection_name));
                }
            };

        mixin[addEventsName] = function(collection) {
            if (collection) {
                collection.on(event_name, this[eventCallbackName]);
            }
        };

        mixin[removeEventsName] = function(collection) {
            if (collection) {
                collection.off(event_name, this[eventCallbackName]);
            }
        };

        mixin[getStateName] = function() {
            var state = {};
            if (this.props[linkToStateName]) {
                state[collection_name] = getIn(this.props, collection_name).toJSON()
            }
            return state;
        };

        mixin[eventCallbackName] = function() {
            var me = this;
            clearTimeout(this[timeoutName]);
            this[timeoutName] = setTimeout(function() {
                me.isMounted() && me.forceUpdate();
            }, buffer || 0);
        };

        return mixin;
    }
};
