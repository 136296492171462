"use strict";

var React = require('react'),
    _ = require('underscore'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    Link = ReactRouter.Link,
    cx = require('classnames'),
    Constants = require('../../../constants'),
    Cmp = require('../../../components'),
    FluxBone = require('../../../mixins/FluxBone'),
    ReportModel = require('../../../models/reports/ListingOverview'),
    Validation = require('../../../partials/validation'),
    Charts = require('../../../partials/chart'),
    numeral = require('numeral');


var ProjectReportDashboard = React.createClass({displayName: "ProjectReportDashboard",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset'),
        FluxBone.ModelMixin('report', 'sync'),
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function(props) {
        var query = this.context.router.getCurrentQuery(),
            params = this.context.router.getCurrentParams(),
            startDate,
            endDate,
            listingId,
            defaults,
            state = this.state || {};

        props = props || this.props;

        props.report.project = props.model;

        defaults = props.report.defaults();
        startDate = query.startDate || defaults.startDate;
        endDate = query.endDate || defaults.endDate;
        listingId = params.id || props.model.id || props.report.get('listingId');

        if (state.startDate !== startDate || state.endDate !== endDate || state.listingId !== listingId) {
            props.report.clear();

            props.report.set({
                startDate: startDate,
                endDate: endDate,
                listingId: listingId
            });

            this.props.report.fetch();
        }

        return {
            startDate: startDate,
            endDate: endDate,
            listingId: listingId
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        return {
            report: new ReportModel()
        };
    },
    /**
     * This is to ensure that when subroutes transition they trigger recheck router state and render
     */
    componentWillReceiveProps: function(props) {
        this.setState(this.updateState(props));
    },

    shouldDisableDatefields: function(props){
        props = props || this.props;
        return props.model.get('serviceTypes').length === 0; 
    },


    render: function() {
        var props = this.props,
            state = this.state,
            reportData = props.report.toJSON({
                includeListings: true
            }),
            aggregateStats = reportData.aggregateStats,
            queryParams = {
                // startDate: state.startDate,
                // endDate: state.endDate
            },
            currentRoutes = this.context.router.getCurrentRoutes(),
            currentRoute = currentRoutes[currentRoutes.length - 1],
            agentsDashboardRouteName = currentRoute.name === 'BuilderReportDashboard' ? 'BuilderProjectReportAgentDashboard' : 'ProjectReportAgentDashboard',
            performanceDashboardRouteName = currentRoute.name === 'BuilderReportDashboard' ? 'BuilderProjectReportPerformanceDashboard' : 'ProjectReportPerformanceDashboard',
            dateFieldsDisabled = this.shouldDisableDatefields();

        return (
            React.createElement("div", null, 
                React.createElement("h1", null, "Report: ", props.model.get('name')), 
                React.createElement("h5", {className: "margin-bottom-20"}, 
                    React.createElement("p", null, 
                        "Below are summary reports of your listing. The summary provides an overview of your listing's performance."
                    ), 
                    React.createElement("p", null, 
                        "NOTE: The default date range of the stats are from the day your listing went active in New Construction Hub."
                    )
                ), 
                React.createElement("div", {className: "margin-bottom-20"}, 
                    dateFieldsDisabled && props.model.isLoaded() && !props.model.isLoading() && (
                        React.createElement(BS.Row, null, 
                            React.createElement(BS.Col, {xs: 12}, 
                                React.createElement("p", {className: "green-text"}, 
                                    React.createElement("i", {className: "fa fa-exclamation-triangle"}), " Upgrade to filter data by date. ", React.createElement(Link, {to: "UserUpgradeAndBilling", params: { id: props.model.id}}, "Click Here"), " to upgrade."
                                )
                            )
                        )
                    ), 
                    React.createElement(Validation.Form, {ref: "form", model: props.report, onSubmit: this.onSaveClick}, 
                        React.createElement(BS.Row, null, 
                            React.createElement(BS.Col, {sm: 6, xs: 12}, 
                                React.createElement(Cmp.Picker.Date, {disabled: dateFieldsDisabled, labelClassName: "col-xs-5", wrapperClassName: "col-xs-7", label: "Filter by Date:", placeholder: "Start", name: "startDate"})
                            ), 
                            React.createElement(BS.Col, {sm: 4, xs: 12}, 
                                React.createElement(Cmp.Picker.Date, {disabled: dateFieldsDisabled, labelClassName: "col-xs-5 col-sm-2", wrapperClassName: "col-xs-7 col-sm-10", label: "to", placeholder: "Start", name: "endDate"})
                            ), 
                            React.createElement(BS.Col, {sm: 2, xs: 12}, 
                                React.createElement(Cmp.Button.Save, {disabled: dateFieldsDisabled, formLinks: false}, "Go")
                            )
                        )
                    )
                ), 

                aggregateStats && (
                    React.createElement("div", {className: "dashboard-stats row margin-bottom-50"}, 
                        React.createElement("div", {className: "dashboard-stat col-xs-3"}, 
                            React.createElement("div", {className: "dashboard-stat-inner"}, 
                                React.createElement("h4", {title: "Agents"}, React.createElement("i", {className: "fa fa-fw fa-users"}), "Agents", React.createElement("small", null, "promoting your listings")), 
                                React.createElement("div", {className: "orange-text"}, numeral(aggregateStats.agents).format('0,0[.]00'))
                            )
                        ), 
                        /*
                        <div className="dashboard-stat col-xs-3">
                            <div className="dashboard-stat-inner">
                                <h4 title="Views"><i className="fa fa-fw fa-eye"/>Views<small>of your listings</small></h4>
                                <div className="blue-text">{numeral(aggregateStats.views).format('0,0[.]00')}</div>
                            </div>
                        </div>
                        */
                        React.createElement("div", {className: "dashboard-stat col-xs-3"}, 
                            React.createElement("div", {className: "dashboard-stat-inner"}, 
                                React.createElement("h4", {title: "Views"}, React.createElement("i", {className: "fa fa-fw fa-eye"}), "Views", React.createElement("small", null, "on your listings")), 
                                React.createElement("div", {className: "blue-text"}, numeral(aggregateStats.views).format('0,0[.]00'))
                            )
                        ), 
                        React.createElement("div", {className: "dashboard-stat col-xs-3"}, 
                            React.createElement("div", {className: "dashboard-stat-inner"}, 
                                React.createElement("h4", {title: "Clicks"}, React.createElement("i", {className: "fa fa-fw fa-external-link"}), "Clicks", React.createElement("small", null, "to your listing website")), 
                                React.createElement("div", {className: "blue-text"}, numeral(aggregateStats.clicks).format('0,0[.]00'))
                            )
                        ), 
                        React.createElement("div", {className: "dashboard-stat col-xs-3"}, 
                            React.createElement("div", {className: "dashboard-stat-inner"}, 
                                React.createElement("h4", {title: "Leads"}, React.createElement("i", {className: "fa fa-fw fa-fire"}), "Leads", React.createElement("small", null, "of your listings")), 
                                React.createElement("div", {className: "blue-text"}, numeral(aggregateStats.leads).format('0,0[.]00'))
                            )
                        )
                    )
                ), 
                aggregateStats && (
                    React.createElement("div", {className: "margin-bottom-50"}, 
                        React.createElement("div", null, 
                            React.createElement("h1", {className: "pull-left"}, "Top 10 Agents:"), 
                            props.model.hasServiceType('AGENT_PORTAL') && (
                                React.createElement(Link, {to: agentsDashboardRouteName, params: { id: props.model.id}, query: queryParams, className: "btn-u btn-u-blue btn-u-inverted btn-u-ultra-narrow pull-right"}, React.createElement("i", {className: "fa fa-fw fa-line-chart"}), " View Full Report")
                            )
                        ), 
                        React.createElement("h5", {className: "margin-bottom-20"}, 
                            "Below are your top 10 performing agents. ", React.createElement(Link, {to: agentsDashboardRouteName, params: { id: props.model.id}, query: queryParams}, "CLICK HERE"), " to view full report."
                        ), 

                        React.createElement(BS.Table, {striped: true, bordered: true, condensed: true, hover: true, className: "stats-table agent-stats-table"}, 
                            React.createElement("thead", null, 
                                React.createElement("tr", null, 
                                    React.createElement("th", null, "Name & Brokerage"), 
                                    /*<th>Impressions</th>*/
                                    React.createElement("th", null, "Views"), 
                                    React.createElement("th", null, "Clicks"), 
                                    React.createElement("th", null, "Leads Generated")
                                )
                            ), 
                            React.createElement("tbody", null, 
                            _.map(reportData.topAgents, function(agent){
                                return (
                                    React.createElement("tr", {key: agent.id}, 
                                        React.createElement("td", null, agent.name, React.createElement("br", null), agent.brokerage), 
                                        /*<td>{agent.views}</td>*/
                                        React.createElement("td", null, agent.views), 
                                        React.createElement("td", null, agent.clicks), 
                                        React.createElement("td", null, agent.leads)
                                    )
                                );
                            })
                            )
                        )
                    )
                ), 
                aggregateStats && (
                    React.createElement("div", null, 
                        React.createElement("div", null, 
                            React.createElement("h1", {className: "pull-left"}, "Listing Performance:"), 
                            props.model.hasServiceType('AGENT_PORTAL') && (
                                React.createElement(Link, {to: performanceDashboardRouteName, params: { id: props.model.id}, query: queryParams, className: "btn-u btn-u-blue btn-u-inverted btn-u-ultra-narrow pull-right"}, React.createElement("i", {className: "fa fa-fw fa-line-chart"}), " View Full Report")
                            )
                        ), 
                        React.createElement("h5", {className: "margin-bottom-20"}, 
                            "Below is your listing's performance. ", React.createElement(Link, {to: performanceDashboardRouteName, params: { id: props.model.id}, query: queryParams}, "CLICK HERE"), " to view the full report."
                        ), 

                      React.createElement(Charts.ListingPerformance, {showViews: true, showClicks: true, showLeads: true, model: props.model, startDate: state.startDate, endDate: state.endDate, handleLoad: true})
                    )
                )
            )
        );
    },

    onSaveClick: function() {
        var data = this.refs.form.getValues(),
            currentRoutes = this.context.router.getCurrentRoutes(),
            currentRoute = currentRoutes[currentRoutes.length - 1];
        this.transitionTo(currentRoute.name, {
            id: this.props.model.id
        }, {
            startDate: data.startDate,
            endDate: data.endDate,
            page: 1
        });
    }
});

module.exports = ProjectReportDashboard;
