"use strict";

var React = require('react/addons'),
    Router = require('react-router'),
    Link = Router.Link,
    Cmp = require('../../components'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    DocumentModel = require('../../models/ProjectDocument'),
    ListingModelType = require('../../mixins/ListingModelType'),
    RequiredMessage = require('../../partials/RequiredMessage'),
    Validation = require('../../partials/validation');


var ProjectDocument = React.createClass({displayName: "ProjectDocument",

    mixins: [
        Router.Navigation,
        ListingModelType.ListingNavigation('Document')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    CATEGORIES: [{
        id: "Policy",
        name: "Policy"
    }, {
        id: "Marketing",
        name: "Marketing"
    }, {
        id: "Forms",
        name: "Forms"
    }, {
        id: "Other",
        name: "Other"
    }],

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            id = params.documentId,
            projectId = this.props.model.id;

        this.props.documentModel.clear();

        this.props.documentModel.set({
            id: id,
            listing: {
                id: projectId
            }
        });

        if (id) {
            this.props.documentModel.fetch();
        }

        return {};
    },

    getDefaultProps: function() {
        return {
            documentModel: new DocumentModel(),
            readOnly: false
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    onSaveClick: function(e) {
        var me = this,
            model = me.props.documentModel,
            filePicker = me.refs.filePicker,
            isNew = model.isNew();

        model.save(me.refs.form.getValues()).then(function(m, e, r) {
            if(isNew){
                filePicker.setIdUrlPart(model.id);
            }
            if (filePicker.hasFiles()) {
                filePicker.startUpload();
            } else {
                me.backToList();
            }
        });
    },

    render: function() {
        var params = this.context.router.getCurrentParams(),
            defaultControlAttr = {
                type: 'text',
                labelClassName: 'label',
                trigger: 'onChange'
            },
            props = this.props;

        return (
            React.createElement(Validation.Form, {ref: "form", horizontal: false, model: props.documentModel, onSubmit: this.onSaveClick, readOnly: props.readOnly}, 
                React.createElement("h1", null, props.documentModel.id ? 'Edit' : 'Add', " Listing Document"), 
                React.createElement("h5", null, "Use this section to add/edit documents. Agents and Brokers will be able to access these documents."), 
                React.createElement(RequiredMessage, null), 
                
                    React.createElement(Validation.Input, React.__spread({},  defaultControlAttr, {placeholder: "Enter a Name for this Document.", name: "title", label: "Document Name/Title:"})), 
                    React.createElement(Validation.Input, React.__spread({},  defaultControlAttr, {rows: "3", type: "textarea", placeholder: "Provide a description for this Document.", name: "description", label: "Document Description:"})), 
                    React.createElement(Cmp.RadioGroup, React.__spread({},  defaultControlAttr, {label: "Category:", type: "radio", items: this.CATEGORIES, name: "category"})), 
                    React.createElement(Cmp.Picker.File, React.__spread({},  defaultControlAttr, {ref: "filePicker", name: "files", url: props.documentModel.url(), label: "Document File:", onComplete: this.onFileUpload})), 
                
                React.createElement(Cmp.ValidationMessage, null), 
                React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                    React.createElement(Cmp.Button.Cancel, {onClick: this.onCancelClick}), 
                    React.createElement(Cmp.Button.Save, {className: "pull-right"})
                )
            )
        );
    },

    onFileUpload: function() {
        this.backToList();
    },

    onCancelClick: function() {
        this.backToList();
    },

    backToList: function() {
        var params = this.context.router.getCurrentParams();
        this.transitionTo(this.backTo(), params);
    }
});
module.exports = ProjectDocument;
