"use strict";

var Model = require('./Model');

var MarketingNetwork = Model.extend({
    defaults: function() {
        return {
            name: undefined,
            webSite: undefined,
            active: false,
            createDate: new Date()
        };
    }
});

module.exports = MarketingNetwork;
