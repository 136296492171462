"use strict";

module.exports = {
    Add: require('./ProjectAdd'),
    Details: require('./ProjectAddDetails'),
    Document: require('./ProjectAddDocument'),
    Image: require('./ProjectAddImage'),
    WidgetImage: require('./ProjectAddWidgetImage'),
    FloorPlan: require('./ProjectAddFloorPlan'),
    Promotion: require('./ProjectAddPromotion'),
    Steps: require('./ProjectAddSteps'),
    Contact: require('./ProjectAddContact')
};
