"use strict";

var React = require('react'),
    ReactRouter = require('react-router'),
    Link = require('./Link'),
    cx = require('classnames'),
    _ = require('underscore');

var NavLink = React.createClass({displayName: "NavLink",
    mixins: [ReactRouter.State],
    getDefaultProps: function() {
        return {
            to: undefined,
            // Routes can be an array of various to, params, and query properties to also match against
            routes: undefined,
            childrenOnly: false
        }
    },
    contextTypes: {
        router: React.PropTypes.func,
    },
    propTypes: {
        optionalRoutes: React.PropTypes.array
    },
    render: function() {
        var props = this.props,
            router = this.context.router,
            active = this.isLinkActive(),
            className = cx({
                'active': active,
                'disabled': props.disabled
            }, props.className),
            linkProps = {
                role: 'button',
                onClick: this.onClick,
                className: props.linkClassName
            };

        // var { className, ...other } = props;
        var other = _.omit(props, 'className', 'linkClassName');

        return (
            React.createElement("li", {className: className, role: "presentation"}, 
                 props.childrenOnly && props.children, 
                 !props.childrenOnly && props.to && (
                    React.createElement(Link, React.__spread({},  linkProps,  other), 
                        props.children
                    )
                ), 
                 !props.childrenOnly && !props.to && (
                    React.createElement("a", React.__spread({},  linkProps,  other, {"aria-disabled": other.disabled}), 
                        props.children
                    )
                )
            )
        );
    },
    onClick: function(e) {
        var props = this.props;

        if (props.disabled || (!props.to && !props.href)) {
            e.preventDefault();
        }

        if (!props.disabled && props.onClick) {
            props.onClick(this, e);
        }
    },

    isLinkActive: function() {
        var me = this,
            props = me.props,
            isActive = props.active || (props.to ? me.isActive(props.to, props.params, props.query) : false);

        if (isActive === false && props.routes) {
            _.some(props.routes, function(route) {
                isActive = me.isActive(route.to, route.params, route.query);
                return isActive;
            });
        }

        return isActive;
    }
});

module.exports = NavLink;
