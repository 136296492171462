'use strict';

var React = require('react'),
    ReactRouter = require('react-router'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    Constants = require('../../constants'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    Cmp = require('../../components'),
    SidebarNav = require('../../partials/SidebarNav'),
    FluxBone = require('../../mixins/FluxBone'),
    UserDashboard = require('./UserDashboard'),
    AdminDashboard = require('./AdminDashboard'),
    AgentDashboard = require('./AgentDashboard'),
    DeveloperDashboard = require('./DeveloperDashboard'),
    BrokerDashboard = require('./BrokerDashboard'),
    BuilderDashboard = require('./BuilderDashboard');

var Dashboard = React.createClass({displayName: "Dashboard",
    mixins: [ReactRouter.Navigation, FluxBone.ModelMixin('CurrentUser', 'sync')],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        return {};
    },

    getInitialState: function() {
        return this.updateState();
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        var Dashboard = this.getDashboardForUser(),
            name = this.getName(),
            entity = this.props.CurrentUser.getEntity();

        return entity.get('agentLevel') === 'FREE'
            ? null
            : React.createElement("div", {className: "wrapper"}, 
                  React.createElement(PageHeader, null), 
                  React.createElement("div", {className: "breadcrumbs"}, 
                      React.createElement("div", {className: "container"}, 
                          React.createElement("h1", {className: "pull-left"}, name), 
                          React.createElement("ul", {className: "pull-right breadcrumb"}, 
                              React.createElement(Cmp.NavLink, {to: "Dashboard"}, name)
                          )
                      )
                  ), 
                  React.createElement("div", {className: "container content"}, 
                      React.createElement("div", {className: "row half-gutter"}, 
                          React.createElement("div", {className: "col-md-3 md-margin-bottom-40"}, 
                              React.createElement(SidebarNav, null)
                          ), 
                          React.createElement("div", {className: "col-md-9"}, 
                              Dashboard && React.createElement(Dashboard, {user: this.props.CurrentUser})
                          )
                      )
                  ), 
                  React.createElement(PageFooter, null)
              );
    },

    getName: function() {
        var type = this.props.CurrentUser.getType();

        if (type === 'Developer') {
            type = 'Builder';
        } else if (type === 'Agent' && this.props.CurrentUser.agentModel.get('isBroker')) {
            type = 'Broker';
        }

        return type + ' Dashboard';
    },

    getDashboardForUser: function() {
        var dashboard,
            CurrentUser = this.props.CurrentUser;

        if (CurrentUser.hasRole(Constants.roles.ADMIN)) {
            dashboard = AdminDashboard;
        } else if (CurrentUser.hasRole(Constants.roles.AGENT)) {
            dashboard = AgentDashboard;
        } else if (CurrentUser.hasRole(Constants.roles.DEVELOPER)) {
            dashboard = DeveloperDashboard;
        } else if (CurrentUser.hasRole(Constants.roles.BUILDER)) {
            dashboard = BuilderDashboard;
        } else if (CurrentUser.hasRole(Constants.roles.BROKER)) {
            dashboard = BrokerDashboard;
        }

        return dashboard;
    },

    componentDidMount: function() {
        this.handleAgentPlan();
    },

    componentDidUpdate: function() {
        this.handleAgentPlan();
    },

    handleAgentPlan: function() {
        var entity = this.props.CurrentUser.getEntity(),
          defaultAgentLevel = entity.get('defaultAgentLevel');

        if (defaultAgentLevel === 'FREE' ) {
            entity.set('agentLevel', 'FREE');
            this.transitionTo('SignupUserMembership');
        }
    }
});

Dashboard.USER = UserDashboard;
Dashboard.ADMIN = AdminDashboard;
Dashboard.AGENT = AgentDashboard;
Dashboard.DEVELOPER = DeveloperDashboard;
Dashboard.BROKER = BrokerDashboard;
Dashboard.BUILDER = BuilderDashboard;

module.exports = Dashboard;
