"use strict";

var React = require('react'),
    cx = require('classnames'),
    ReactRouterBootstrap = require('react-router-bootstrap'),
    Router = require('react-router'),
    ButtonLink = ReactRouterBootstrap.ButtonLink;

var DashboardItem = React.createClass({displayName: "DashboardItem",
    mixins : [
        Router.Navigation,
    ],
    getDefaultProperties : function() {
        return {
            small : false
        }
    },

    updateState: function() {
        return {};
    },

    getInitialState: function() {
        return this.updateState();
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        var props = this.props;

        return (
            React.createElement("div", {onClick: this.doNavigation, className: cx("panel", {"panel-blue" : props.blue, "panel-orange" : props.orange, "panel-grey" : props.small, "dashboard-choice" : !props.small, "listing-dashboard-choice" : props.small})}, 
                React.createElement("i", {className: cx("icon-custom", "icon-line", "icon-lg", "rounded-x", "fa", props.iconClass, {"icon-bg-blue" : props.blue, "icon-bg-orange" : props.orange, "icon-bg-grey" : props.small})}), 
                React.createElement("h4", {className: cx({"color-blue" : props.blue, "color-orange" : props.orange})}, props.heading), 
                props.message && React.createElement("p", null, props.message), 
                React.createElement(ButtonLink, {to: props.to, params: props.linkParam, className: cx("btn-u", "btn-brd",  {"btn-u-blue" : props.blue, "btn-u-orange" : props.orange, "btn-u-default" : props.small, "btn-u-lg" : !props.small})}, "Click Here")
            )
        );
    },
    doNavigation : function() {
        var props = this.props;
        this.transitionTo(props.to, props.linkParam);
    }
});
module.exports = DashboardItem;