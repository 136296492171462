"use strict";

var React = require('react/addons'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    FluxBone = require('../../mixins/FluxBone'),
    BS = require('react-bootstrap'),
    DeleteDialog = require('../../partials/DeleteDialog'),
    ReactRouter = require('react-router'),
    Link = ReactRouter.Link,
    moment = require('moment'),
    ListingModelType = require('../../mixins/ListingModelType'),
    ProjectModel = require('../../models/Project'),
    Numeral = require('numeral');

var ProjectFloorPlans = React.createClass({displayName: "ProjectFloorPlans",

    mixins: [
        FluxBone.CollectionMixin('model.floorPlansCollection', 'sync remove add', 10),
        React.addons.LinkedStateMixin,
        ListingModelType.ListingNavigation('FloorPlan')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },
    getDefaultProps: function() {
        return {
            readOnly: false,
            emptyMessage: 'There are no floor plans available.',
            floorplanOnly: false
        };
    },
    getInitialState: function() {
        return {};
    },

    onDelete: function(floorPlan, e) {
        e.preventDefault();
        this.setState({
            modelToDelete: floorPlan
        });
    },

    onHideDeleteDialog: function() {
        this.setState({
            modelToDelete: undefined
        });
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            isDeveloper = me.isDeveloper(),
            deleteDialogProps = {
                ref: 'deleteDialog',
                modelToDelete: state.modelToDelete,
                onHide: me.onHideDeleteDialog,
                modelTitle: state.modelToDelete ? state.modelToDelete.get('floorPlanName') : '',
                title: 'ALERT! You are about to DELETE a Floor Plan.',
                msg: 'Please confirm that you would like to DELETE this Floor Plan.',
                successMessage: 'Success! Your floor plan has been successfully deleted.',
                deleteLabel: 'Delete Floor Plan'
            };

        return (
                    React.createElement(DeleteDialog, React.__spread({},  deleteDialogProps), 
                            !this.props.hideHeader && (
                            React.createElement("div", {className: "row margin-bottom-20 half-gutter"}, 
                                React.createElement("div", {className: props.readOnly ? 'col-md-12' : 'col-md-10'}, 
                                    React.createElement("h1", null, isDeveloper ? 'Builder Floor Plans' : 'Listing Floor Plans'), 
                                    props.description ? props.description : (
                                        React.createElement("h5", null, "This section enables you to manage your floor plans for this listing. Add/edit/delete the floor plans. Agents will have access to the floor plans to provide them to their buyers.")
                                    )
                                ), 
                                !props.readOnly && (
                                    React.createElement("div", {className: "col-md-2"}, 
                                        React.createElement(Link, {to: me.addTo(), key: "ProjectAddFloorPlanButton", className: "btn btn-u pull-right", params: {id: props.model.id}}, "Add Floor Plan")
                                    )
                                )
                            )
                            ), 
                            (!props.readOnly || props.model.floorPlansCollection.length > 0) && (
                                React.createElement("div", {className: "table-responsive"}, 
                                    React.createElement("table", {className: "table table-striped table-borderless table-hover table-condensed"}, 
                                        React.createElement("thead", null, 
                                            React.createElement("tr", null, 
                                                React.createElement("th", {className: "col-md-3"}, "Name"), 
                                                React.createElement("th", null, "Bedrooms"), 
                                                React.createElement("th", {className: "col-md-2"}, "Full Baths"), 
                                                React.createElement("th", {className: "col-md-2"}, "Price Range"), 
                                                React.createElement("th", {className: "col-md-2"}, "Last Updated"), 
                                                React.createElement("th", null, "Attachments"), 
                                                !props.readOnly && (
                                                    React.createElement("th", null, "Actions")
                                                )
                                            )
                                        ), 
                                        React.createElement("tbody", null, 
                                            props.model.floorPlansCollection.map(function(floorPlan){
                                                var data = floorPlan.toJSON(),
                                                    linkParams = { 
                                                        id : props.model.id,
                                                        floorplanid: floorPlan.id 
                                                    },
                                                    price = data.endingPrice ? [Numeral(data.startingPrice).format('0a'), Numeral(data.endingPrice).format('0a')].join(' - ') : Numeral(data.startingPrice).format('0.0a'),
                                                    readOnly = props.readOnly || floorPlan.isReadOnly();

                                                if(props.floorplanOnly && data.imageType !== undefined && data.imageType !== 'floorplan'){
                                                    return null;
                                                }

                                                return ([
                                                    React.createElement("tr", {key: floorPlan.id}, 
                                                        React.createElement("td", null, 
                                                            !readOnly && (
                                                                React.createElement(Link, {to: me.editTo(), params: linkParams}, data.floorPlanName)
                                                            ), 
                                                            readOnly && data.floorPlanName
                                                        ), 
                                                        React.createElement("td", null, data.bedrooms), 
                                                        React.createElement("td", null, data.fullBaths), 
                                                        React.createElement("td", null, price), 
                                                        React.createElement("td", null, moment(new Date(data.lastUpdated)).fromNow()), 
                                                        props.floorplanOnly ? (
                                                            React.createElement("td", null, 
                                                                _.map(data.files, function(file){
                                                                    return (
                                                                        React.createElement("div", {key: '' + floorPlan.id + '_' + file.id}, 
                                                                            React.createElement("a", {href: file.link, target: "_blank", title: file.name}, file.name)
                                                                        )
                                                                    );
                                                                })
                                                            )
                                                        ) : (
                                                            React.createElement("td", null, data.files.length)
                                                        ), 
                                                        React.createElement("td", {style: {'whiteSpace': 'nowrap'}}, 
                                                            !props.readOnly && (
                                                                React.createElement(Link, {to: me.editTo(), params: linkParams}, 
                                                                    readOnly ? (
                                                                        React.createElement("i", {className: "icon-custom  icon-color-u icon-sm fa fa-eye", style: {"marginRight" : "3px"}})
                                                                    ) : (
                                                                        React.createElement("i", {className: "icon-custom  icon-color-u icon-sm fa fa-pencil", style: {"marginRight" : "3px"}})
                                                                    )
                                                                )
                                                            ), 
                                                            !readOnly && (
                                                                React.createElement("a", {href: "#", onClick: me.onDelete.bind(me, floorPlan)}, 
                                                                    React.createElement("i", {className: "icon-custom  icon-color-u icon-sm fa fa-trash-o"})
                                                                )
                                                            )
                                                        )
                                                    )
                                                ]);
                                        })
                                        )
                                    )
                                )
                            ), 
                            props.model.floorPlansCollection.length === 0 && (
                                React.createElement(BS.Alert, null, 
                                    React.createElement("p", {className: "text-center"}, 
                                        props.emptyMessage
                                    )
                                )
                            )
                    )
                );
    }
});
module.exports = ProjectFloorPlans;
