"use strict";

module.exports = {
	List: require('./Projects'),
	View: require('./ProjectDetails'),
	AddEdit: require('./ProjectAddEdit'),
	AddEditDetails: require('./ProjectAddEditDetails'),
	AddEditDetails2: require('./ProjectAddEditDetails2'),
	Images: require('./ProjectImages'),
	ImageHandler: require('./ProjectImageHandler'),
	FloorPlanHandler: require('./ProjectFloorPlanHandler'),
	AddEditImage: require('./ProjectAddEditImage'),
	FloorPlans: require('./ProjectFloorPlans'),
	AddEditFloorPlan: require('./ProjectAddEditFloorPlan'),
	Documents: require('./ProjectDocuments'),
	AddEditDocument: require('./ProjectAddEditDocument'),
	Promotions: require('./ProjectPromotions'),
	AddEditPromotion: require('./ProjectAddEditPromotion'),
	Amenities: require('./ProjectAmenities'),
	Reports: require('./report'),
	APIKey: require('./ProjectAPIKey'),
	Wizard: require('./wizard'),
	Dashboard : require('./Dashboard'),
	Visibility : require('./ProjectVisibility'),
	Contacts : require('./ProjectContacts'),
	AddEditContact: require('./ProjectAddEditContact'),
	PersonalizedAgentEmail: require('./PersonalizedAgentEmail'),
	PersonalizedAgentBrochure: require('./PersonalizedAgentBrochure')
};