"use strict";

var React = require('react/addons'),
    $ = require('jquery'),
    ReactRouter = require('react-router'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    FluxBone = require('../../mixins/FluxBone'),
    ProjectModel = require('../../models/Project'),
    NavLink = require('../../components/NavLink'),
    ReactCatalyst = require('../../mixins/react-catalyst'),
    CurrentUser = require('../../shared/CurrentUser'),
    RouteHandler = ReactRouter.RouteHandler,
    Link = ReactRouter.Link;


var ListingInfo = React.createClass({displayName: "ListingInfo",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset'),
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var me = this,
            model = this.props.model,
            developer = model && model.getEntity() ? model.getEntity() : undefined;

        developer && developer.id && developer.fetch({
            success : function(){
                setTimeout(function() {
                    me.isMounted() && me.forceUpdate();
                }, 0);
            }
        });

        return {
            developer : developer
        };
    },
    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function(){
        return {
            model: CurrentUser
        };
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    getBreadcrumbTitle : function(){
        var title = 'Company Listing: ' + this.state.developer.get('name'),
            routes = this.context.router.getCurrentRoutes();

        for(var i = routes.length - 1; i > 0; i--){
            if(this['getBreadcrumbTitleFor' + routes[i].name]){
                title = this['getBreadcrumbTitleFor' + routes[i].name]();
                break;
            }
        }
        return title;
    },

    getBreadcrumbTitleForBuilderReports : function(){
        return 'Company Listing: ' + this.state.developer.get('name') + ': Reports';
    },

    getExtraBreadcrumbItems : function(){
        var routes = this.context.router.getCurrentRoutes(), components;

        for (var i = routes.length - 1; i > 0; i--){
            if (this['getExtraBreadcrumbItemsFor' + routes[i].name]){
                components = this['getExtraBreadcrumbItemsFor' + routes[i].name](routes[i]);
                return $.isArray(components) ? components : [components];
            }
        }
        return [];

    },
    getExtraBreadcrumbItemsForBuilderEditFloorPlans : function() {
        return (
            React.createElement(NavLink, {active: true}, "Floor Plans")
        );
    },
    getExtraBreadcrumbItemsForBuilderEditImages : function(){
        return (
            React.createElement(NavLink, {active: true}, "Images")
        );
    },
    getExtraBreadcrumbItemsForBuilderEditAmenities : function(){
        return (
            React.createElement(NavLink, {active: true}, "Amenities")
        );
    },
    getExtraBreadcrumbItemsForBuilderEditDocuments : function(){
        return (
            React.createElement(NavLink, {active: true}, "Documents")
        );
    },
    getExtraBreadcrumbItemsForBuilderEditPromotions : function(){
        return (
            React.createElement(NavLink, {active: true}, "Promotions")
        );
    },
    getExtraBreadcrumbItemsForBuilderEditAPIKey : function(){
        return (
            React.createElement(NavLink, {active: true}, "Agent Outreach Tools")
        );
    },
    getExtraBreadcrumbItemsForBuilderReports : function(){
        return (
            React.createElement(NavLink, {active: true}, "Reports")
        );
    },
    getExtraBreadcrumbItemsForBuilderEditContacts: function() {
        return (
            React.createElement(NavLink, {active: true}, "Contacts")
        );
    },


    getExtraBreadcrumbItemsForBuilderEditFloorPlan : function() {
        return this.getBreadcrumbItemsForSubEditScreen('floorplanid', 'Floor Plans', 'floorPlanName', 'floorPlansCollection', 'BuilderEditFloorPlans');
    },
    getExtraBreadcrumbItemsForBuilderEditImage : function() {
        return this.getBreadcrumbItemsForSubEditScreen('imageid', 'Images', 'title', 'imagesCollection', 'BuilderEditImages');
    },
    getExtraBreadcrumbItemsForBuilderEditDocument : function() {
        return this.getBreadcrumbItemsForSubEditScreen('documentId', 'Documents', 'title', 'documentsCollection', 'BuilderEditDocuments');
    },
    getExtraBreadcrumbItemsForBuilderEditPromotion : function() {
        return this.getBreadcrumbItemsForSubEditScreen('promotionId', 'Promotions', 'name', 'promotionsCollection', 'BuilderEditPromotions');
    },

    getBreadcrumbItemsForSubEditScreen : function(paramName, linkTitle, titleProperty, collectionProperty, route){
        var params = this.context.router.getCurrentParams(),
            subItemId = params[paramName],
            model = this.props.model,
            linkParam = {
                id: model.id
            },
            subItemModel = model[collectionProperty] && model[collectionProperty].get(subItemId),
            title;

        subItemModel && (title = subItemModel.get(titleProperty));

        return [
            React.createElement(NavLink, {params: linkParam, to: route}, linkTitle),
            React.createElement(NavLink, {active: true}, title ? title  : '...')
        ];

    },
    getExtraBreadcrumbItemsForBuilderEditDetails : function(){
        return React.createElement(NavLink, {active: true}, "Information");

    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            data = state.developer,
            linkParam = undefined,
            displaySidebar = this.displaySidebar(),
            breadcrumbTitle = this.getBreadcrumbTitle(),
            extraBreadcrumbItems = me.getExtraBreadcrumbItems(),
            routes = this.context.router.getCurrentRoutes(),
            routeName = routes.length >= 2 ? routes[routes.length-2].name : undefined;
            

        return (
             React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: 'NCH - ' + breadcrumbTitle}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, breadcrumbTitle), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(NavLink, {to: "ProjectList"}, "Projects"), 
                             extraBreadcrumbItems.length ? 
                                React.createElement(NavLink, {to: "CompanyDashboard", params: linkParam}, data.get('name'))
                            :
                                React.createElement(NavLink, {active: true}, data.id ? data.get('name') : ''), 
                            
                            extraBreadcrumbItems
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 

                    React.createElement("div", {className: "row"}, 
                        React.createElement("div", {className: "col-md-3 md-margin-bottom-40", "aria-hidden": !displaySidebar}, 
                            React.createElement("div", {className: "margin-bottom-20"}, 
                                routeName && routeName === 'BuilderReports' ? (
                                    React.createElement(Link, {to: "CompanyDashboard", params: linkParam}, React.createElement(BS.Button, {bsStyle: "link"}, React.createElement("i", {className: "fa fa-fw fa-arrow-left"}), " Back to Listing"))
                                ) : (
                                    React.createElement(Link, {to: "ProjectList"}, React.createElement(BS.Button, {bsStyle: "link"}, React.createElement("i", {className: "fa fa-fw fa-arrow-left"}), " Back to My Listings"))
                                )
                            ), 
                            routeName === 'BuilderReports' ? (
                                React.createElement("ul", {className: "list-group sidebar-nav-v1 margin-bottom-40"}, 
                                    React.createElement(NavLink, {to: "BuilderReportDashboard", params: linkParam, className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-fw fa-tachometer"}), " Report Dashboard"
                                    ), 
                                    React.createElement(NavLink, {to: "BuilderProjectReportAgentDashboard", params: linkParam, className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-fw fa-users"}), " Agent Report"
                                    ), 
                                    React.createElement(NavLink, {to: "BuilderProjectReportPerformanceDashboard", params: linkParam, className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-fw fa-line-chart"}), " Performance Report"
                                    )
                                )
                            ) : (
                                React.createElement("ul", {className: "list-group sidebar-nav-v1 margin-bottom-40"}, 
                                    React.createElement(NavLink, {to: "CompanyDashboard", params: linkParam, className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-tachometer"}), " Listing Dashboard"
                                    ), 

                                    React.createElement(NavLink, {to: "BuilderEditDetails", params: linkParam, className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-file-text-o"}), " Builder Information"
                                    ), 
                                    React.createElement(NavLink, {to: "BuilderImage", params: linkParam, className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-image"}), " Builder Image"
                                    ), 
                                    React.createElement(NavLink, {to: "BuilderEditContacts", params: linkParam, className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-file-text-o"}), " Builder Contacts"
                                    ), 

                                    React.createElement(NavLink, {to: "BuilderEditImages", params: linkParam, className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-th-large"}), " Builder Images"
                                    ), 
                                    React.createElement(NavLink, {to: "BuilderEditFloorPlans", params: linkParam, className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-line-chart"}), " Builder Floor Plans"
                                    ), 
                                    React.createElement(NavLink, {to: "BuilderEditDocuments", params: linkParam, className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-cogs"}), " Builder Documents"
                                    ), 
                                    React.createElement(NavLink, {to: "BuilderEditPromotions", params: linkParam, className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-lightbulb-o"}), " Builder Promotions"
                                    ), 
                                    React.createElement(NavLink, {to: "BuilderReports", params: linkParam, className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-line-chart"}), " Builder Listing Reports"
                                    ), 
                                    React.createElement(NavLink, {to: "BuilderEditAmenities", params: linkParam, className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-coffee"}), " Builder Amenities"
                                    ), 
                                    React.createElement(NavLink, {to: "BuilderEditAPIKey", params: linkParam, className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-users"}), " Agent Outreach Tools"
                                    )

                                )
                            )
                        ), 
                        React.createElement("div", {className: displaySidebar ? 'col-sm-9' : 'col-xs-12'}, 
                            React.createElement(RouteHandler, React.__spread({},  props, {model: state.developer}))
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )


        );
    },

    displaySidebarForBuilderAddImage: false,
    displaySidebarForBuilderEditImage: false,
    displaySidebarForBuilderEditFloorPlan: false,
    displaySidebarForBuilderAddFloorPlan: false,
    displaySidebarForBuilderAddContact: false,
    displaySidebarForBuilderEditDocument: false,
    displaySidebarForBuilderAddDocument: false,
    displaySidebarForBuilderAddPromotion: false,
    displaySidebarForBuilderEditPromotion: false,
    displaySidebarForBuilderEditContact: false,
    displaySidebar: function(){
        var display,
            routes = this.context.router.getCurrentRoutes();

        for(var i = routes.length - 1; i > 0; i--){
            display = this['displaySidebarFor' + routes[i].name];
            if(_.isFunction(display)){
                display = display();
            }
            if(display !== undefined){
                break;
            }
        }

        return display !== false;
    }
});
module.exports = ListingInfo;
