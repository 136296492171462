"use strict";

module.exports = {
  trackingPath:
    "//staging-api.agentshield.com/t/1x1clear.gif" +
    "?t=" +
    new Date().getTime(),
  server: "http://staging-app.agentshield.com",
  restPath: "/agent-shield-1.0/rest",
  apiPath: "/agent-shield-1.0",
  widgetHost: "https://staging-widgets.agentshield.com",
  intercomAppId: "ijh052u7",
  intercomAppIdAgent: "ijh052u7",
  toolboxHost: "//staging-toolbox.agentshield.com",
  rollbarEnabled: true,
  rollbarId: "7a90fad9cc7144689883252a2f5e888f",
  CONTACTUALLY_URL:
    "https://www.contactually.com/oauth2/authorize?client_id=f3d065018d27d92cc3ebc93325e60bfca7a293fb3e430b37167c7153aae197f0&amp;redirect_uri=https%3A%2F%2Fstaging-app.agentshield.com%2Fagent-shield-1.0%2Frest%2Fcallback&amp;response_type=code&amp;scope=user:login+contacts:manage+interactions:manage+buckets:view"
};
