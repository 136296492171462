"use strict";

var React = require('react'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    CurrentUser = require('../../shared/CurrentUser');

var UserDashboard = React.createClass({displayName: "UserDashboard",

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        return {};
    },

    getInitialState: function() {
        return this.updateState();
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        return (
            React.createElement("div", null, 
                React.createElement(DocumentTitle, {title: "NCH - User Dashboard"}), 
                React.createElement("h1", null, "User Dashboard")
            )
        );
    }
});
module.exports = UserDashboard;
