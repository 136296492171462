'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _gregorianCalendarFormat = require('gregorian-calendar-format');

var _gregorianCalendarFormat2 = _interopRequireDefault(_gregorianCalendarFormat);

var _rcUtil = require('rc-util');

var _rcUtil2 = _interopRequireDefault(_rcUtil);

var _rcAlign = require('rc-align');

var _rcAlign2 = _interopRequireDefault(_rcAlign);

var _rcAnimate = require('rc-animate');

var _rcAnimate2 = _interopRequireDefault(_rcAnimate);

var toFragment = _rcUtil2['default'].Children.mapSelf;

var orientMap = {
  tl: ['top', 'left'],
  tr: ['top', 'right'],
  bl: ['bottom', 'left'],
  br: ['bottom', 'right']
};

function getImmutableOrient(orient) {
  if (orient) {
    for (var i in orientMap) {
      if (orientMap.hasOwnProperty(i)) {
        var original = orientMap[i];
        if (original[0] === orient[0] && original[1] === orient[1]) {
          return original;
        }
      }
    }
  }
}

function prevent(e) {
  e.preventDefault();
}

function noop() {}

function refFn(field, component) {
  this[field] = component;
}

/**
 * DatePicker = wrap input using Calendar
 */
var Picker = _react2['default'].createClass({
  displayName: 'Picker',

  propTypes: {
    onChange: _react2['default'].PropTypes.func,
    onOpen: _react2['default'].PropTypes.func,
    onClose: _react2['default'].PropTypes.func,
    calendar: _react2['default'].PropTypes.element,
    style: _react2['default'].PropTypes.object,
    open: _react2['default'].PropTypes.bool,
    defaultOpen: _react2['default'].PropTypes.bool,
    prefixCls: _react2['default'].PropTypes.string,
    adjustOrientOnCalendarOverflow: _react2['default'].PropTypes.oneOfType([_react2['default'].PropTypes.bool, _react2['default'].PropTypes.object])
  },

  getDefaultProps: function getDefaultProps() {
    return {
      prefixCls: 'rc-calendar-picker',
      adjustOrientOnCalendarOverflow: true,
      style: {},
      defaultOpen: false,
      onChange: noop,
      onOpen: noop,
      onClose: noop,
      formatter: new _gregorianCalendarFormat2['default']('yyyy-MM-dd')
    };
  },

  getInitialState: function getInitialState() {
    var props = this.props;
    var open = undefined;
    if ('open' in props) {
      open = props.open;
    } else {
      open = props.defaultOpen;
    }
    var value = props.value || props.defaultValue;
    this.saveCalendarRef = refFn.bind(this, 'calendarInstance');
    this.saveInputRef = refFn.bind(this, 'inputInstance');
    return { open: open, value: value };
  },

  componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
    var value = nextProps.value;
    if (value !== undefined) {
      // null special meaning
      value = value || nextProps.defaultValue || null;
      this.setState({
        value: value
      });
    }
    if ('open' in nextProps) {
      this.setState({
        open: nextProps.open
      });
    }
  },

  componentDidUpdate: function componentDidUpdate() {
    if (this.haveOpened) {
      _react2['default'].render(this.getCalendarElement(), this.getCalendarContainer());
    }
  },

  componentWillUnmount: function componentWillUnmount() {
    if (this.calendarContainer) {
      _react2['default'].unmountComponentAtNode(this.calendarContainer);
      this.calendarContainer.parentNode.removeChild(this.calendarContainer);
      this.calendarContainer = null;
    }
  },

  onCalendarAlign: function onCalendarAlign(node, align) {
    var points = align.points;
    var newOrient = orientMap[points[0]];
    this.calendarInstance.setOrient(newOrient);
    // focus after align
    _react2['default'].findDOMNode(this.calendarInstance).focus();
  },

  onInputClick: function onInputClick() {
    this.toggle();
  },

  onTriggerClick: function onTriggerClick() {
    this.toggle();
  },

  onKeyDown: function onKeyDown(e) {
    // down
    if (e.keyCode === _rcUtil.KeyCode.DOWN) {
      e.preventDefault();
      this.open();
    }
  },

  onCalendarKeyDown: function onCalendarKeyDown(e) {
    if (e.keyCode === _rcUtil.KeyCode.ESC) {
      e.stopPropagation();
      this.close(this.focusInput);
    }
  },

  onCalendarSelect: function onCalendarSelect(value) {
    var currentValue = this.state.value;
    this.setState({
      value: value
    });
    if (!this.props.calendar.props.showTime) {
      this.close(this.focusInput);
    }
    if (!currentValue || currentValue.getTime() !== value.getTime()) {
      this.props.onChange(value);
    }
  },

  onCalendarBlur: function onCalendarBlur() {
    if (document.activeElement === this.getInputDOMNode()) {
      return;
    }
    // if invisible, will not trigger blur
    // do not set if already false, avoid ruin animate
    this.close();
  },

  onCalendarOk: function onCalendarOk() {
    this.close(this.focusInput);
  },

  onCalendarClear: function onCalendarClear() {
    this.setState({
      value: null
    });
    this.close(this.focusInput);
    if (this.state.value !== null) {
      this.props.onChange(null);
    }
  },

  onCalendarChange: function onCalendarChange(value) {
    this.calendarInstance.setState({
      value: value
    });
  },

  getInputDOMNode: function getInputDOMNode() {
    return _react2['default'].findDOMNode(this.inputInstance);
  },

  getTransitionName: function getTransitionName() {
    var props = this.props;
    var transitionName = props.transitionName;
    if (!transitionName && props.animation) {
      transitionName = props.prefixCls + '-' + props.animation;
    }
    return transitionName;
  },

  getCalendarContainer: function getCalendarContainer() {
    if (!this.calendarContainer) {
      this.calendarContainer = document.createElement('div');
      this.calendarContainer.className = this.props.prefixCls + '-container';
      document.body.appendChild(this.calendarContainer);
    }
    return this.calendarContainer;
  },

  getAlign: function getAlign(orient) {
    var points = ['tl', 'bl'];
    var offset = [0, 5];
    var adjustOrientOnCalendarOverflow = this.props.adjustOrientOnCalendarOverflow;
    if (orient.indexOf('top') !== -1 && orient.indexOf('left') !== -1) {
      points = ['tl', 'bl'];
    } else if (orient.indexOf('top') !== -1 && orient.indexOf('right') !== -1) {
      points = ['tr', 'br'];
    } else if (orient.indexOf('bottom') !== -1 && orient.indexOf('left') !== -1) {
      points = ['bl', 'tl'];
      offset = [0, -5];
    } else if (orient.indexOf('bottom') !== -1 && orient.indexOf('right') !== -1) {
      points = ['br', 'tr'];
      offset = [0, -5];
    }
    var adjustX = undefined;
    var adjustY = undefined;
    if (adjustOrientOnCalendarOverflow === true) {
      adjustX = adjustY = true;
    } else if (!adjustOrientOnCalendarOverflow) {
      adjustX = adjustY = false;
    } else {
      adjustX = adjustOrientOnCalendarOverflow.x;
      adjustY = adjustOrientOnCalendarOverflow.y;
    }
    return {
      points: points,
      offset: offset,
      overflow: {
        adjustX: adjustX,
        adjustY: adjustY
      }
    };
  },

  getCalendarElement: function getCalendarElement() {
    var props = this.props;
    var state = this.state;
    var calendarProp = props.calendar;
    var orient = undefined;
    // re align when open
    if (state.open) {
      orient = getImmutableOrient(calendarProp.props.orient) || orientMap.tl;
    }
    var calendarElement = _react2['default'].cloneElement(calendarProp, {
      ref: (0, _rcUtil.createChainedFunction)(calendarProp.ref, this.saveCalendarRef),
      value: state.value,
      visible: state.open,
      orient: orient,
      onBlur: this.onCalendarBlur,
      onKeyDown: this.onCalendarKeyDown,
      onChange: (0, _rcUtil.createChainedFunction)(calendarProp.props.onChange, this.onCalendarChange),
      onOk: (0, _rcUtil.createChainedFunction)(calendarProp.props.onOk, this.onCalendarOk),
      onSelect: (0, _rcUtil.createChainedFunction)(calendarProp.props.onSelect, this.onCalendarSelect),
      onClear: (0, _rcUtil.createChainedFunction)(calendarProp.props.onClear, this.onCalendarClear)
    });
    return _react2['default'].createElement(
      _rcAnimate2['default'],
      {
        component: '',
        exclusive: true,
        transitionAppear: true,
        showProp: 'calendarOpen',
        transitionName: this.getTransitionName() },
      _react2['default'].createElement(
        _rcAlign2['default'],
        { target: this.getInputDOMNode,
          key: 'calendar',
          onAlign: this.onCalendarAlign,
          calendarOpen: state.open,
          disabled: !state.open,
          align: orient && this.getAlign(orient) },
        calendarElement
      )
    );
  },

  render: function render() {
    var _classes;

    var props = this.props;
    var disabled = props.disabled;
    var prefixCls = props.prefixCls;
    var input = props.children;
    var state = this.state;
    var value = state.value;
    this.haveOpened = this.haveOpened || state.open;
    var inputValue = '';
    if (value) {
      inputValue = props.formatter.format(value);
    }
    input = _react2['default'].cloneElement(input, {
      ref: (0, _rcUtil.createChainedFunction)(input.ref, this.saveInputRef),
      disabled: disabled,
      onChange: noop,
      onClick: disabled ? noop : this.onInputClick,
      value: inputValue,
      onKeyDown: disabled ? noop : this.onKeyDown
    });
    var classes = (_classes = {}, _defineProperty(_classes, prefixCls, 1), _defineProperty(_classes, prefixCls + '-open', state.open), _defineProperty(_classes, prefixCls + '-disabled', disabled), _classes);
    var trigger = props.trigger;
    if (trigger) {
      trigger = _react2['default'].cloneElement(trigger, {
        unselectable: true,
        onMouseDown: prevent,
        onClick: disabled ? noop : this.onTriggerClick
      });
    }
    return _react2['default'].createElement(
      'span',
      { className: (0, _rcUtil.classSet)(classes), style: props.style },
      toFragment([input, trigger])
    );
  },

  focusInput: function focusInput() {
    if (!this.state.open) {
      this.getInputDOMNode().focus();
    }
  },

  setOpen: function setOpen(open, callback) {
    if (this.state.open !== open) {
      this.setState({
        open: open
      }, callback);
      var _event = {
        open: open
      };
      if (open) {
        this.props.onOpen(_event);
      } else {
        this.props.onClose(_event);
      }
    }
  },

  toggle: function toggle() {
    if (this.state.open) {
      this.close();
    } else {
      this.open();
    }
  },

  open: function open(callback) {
    this.setOpen(true, callback);
  },

  close: function close(callback) {
    this.setOpen(false, callback);
  }
});

exports['default'] = Picker;
module.exports = exports['default'];