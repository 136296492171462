"use strict";

var Constants = require('../../constants'),
    Report = require('./Report'),
    moment = require('moment'),
    $ = require('jquery');

var NewUserAccountDetails = Report.extend({
    urlRoot: Constants.restPath + "/reports/created-account-details",
    defaults: function() {
        var now = moment.valueOf().toString();
        return {
            from: now,
            to: now
        };
    }
});

module.exports = NewUserAccountDetails;
