"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    Constants = require('../../../constants'),
    DocumentTitle = require('react-document-title'),
    PageHeader = require('../../../partials/PageHeader'),
    PageFooter = require('../../../partials/PageFooter'),
    RouteHandler = ReactRouter.RouteHandler;

var EmailTools = React.createClass({displayName: "EmailTools",
    render: function() {
        return (
            React.createElement(RouteHandler, null)
        );
    }
});
module.exports = EmailTools;