"use strict";

var React = require('react/addons'),
    FluxBone = require('../../../mixins/FluxBone'),
    ReactRouter = require('react-router'),
    Roles = require('../../../mixins/Roles'),
    DashboardItem = require('../../../partials/DashboardItem'),
    Constants = require('../../../constants'),
    ReactRouterBootstrap = require('react-router-bootstrap'),
    ButtonLink = ReactRouterBootstrap.ButtonLink,
    Link = ReactRouter.Link;

var UserAccountDashboard = React.createClass({displayName: "UserAccountDashboard",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset'),
        ReactRouter.Navigation,
        Roles
    ],

    contextTypes: {
        router: React.PropTypes.func
    },


    getInitialState: function() {
        var entity = this.props.model.getEntity();
        entity.fetch();
        return {
            entity: entity
        };
    },

    render: function() {
        var me = this,
            roles = Constants.roles;
        

        return (
            React.createElement("div", null, 
                React.createElement("h1", null, "Account Dashboard"), 
                React.createElement("h4", null, "Welcome to the dashboard for your account. What would you like to do today?"), 
                    this.hasOneRole(roles.BUILDER, roles.DEVELOPER, roles.AGENT) && 
                        React.createElement(DashboardItem, {small: true, iconClass: "fa-file-text-o", heading: "My Contact Info", to: "UserAccountContact"}), 
                    

                    React.createElement(DashboardItem, {small: true, iconClass: "fa-envelope", heading: "My Email Address", to: "UserAccountEmail"}), 

                    React.createElement(DashboardItem, {small: true, iconClass: "fa-lock", heading: "Change Password", to: "UserAccountEdit"}), 

                    this.hasOneRole(roles.AGENT) && !this.isBroker() && 
                        React.createElement(DashboardItem, {small: true, iconClass: "fa-cloud-upload", heading: "CRM Integration", to: "AgentCrmIntegration"}), 
                    

                    this.hasOneRole(roles.BUILDER, roles.DEVELOPER) && !this.hasRole(roles.LISTING_USER) && 
                        React.createElement(DashboardItem, {small: true, iconClass: "fa-usd", heading: "Upgrade & Billing", to: "UserUpgradeAndBilling"}), 
                    

                    this.hasOneRole(roles.AGENT) && !this.isBroker() && 
                        React.createElement(DashboardItem, {small: true, iconClass: "fa-diamond", heading: "Premium Agent Settings", to: "UserMembership"})
                    
            ));
    }
});
module.exports = UserAccountDashboard;
