"use strict";

var ModelClass = require('./Model'),
    CurrentUser = require('../shared/CurrentUser');

var UserLogin = ModelClass.extend({
    idAttribute: 'id',
    validation: {
        username: {
            required: true,
            pattern: 'email'
        },
        password: {
            required: true
        }
    },
    labels: {
        "username": 'Email'
    },
    defaults: function() {
        return {
            username: CurrentUser.get('email'),
            password: '',
            rememberMe: false
        };
    },
    fetch: function() {},
    save: function() {},
    sync: function() {},

    login: function(attrs, options) {
        var me = this;
        attrs = attrs || {};
        this.set(attrs);
        return CurrentUser.login(attrs.username, attrs.password, attrs.rememberMe).fail(function(response) {
            // so the login model can listen in on the errors
            me.trigger('error', me, response);
        });
    }
});

module.exports = UserLogin;
