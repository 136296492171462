"use strict";

var Attribute = require('./Attribute');

var State = Attribute.extend({
    toJSON: function(options) {
        var data = Attribute.prototype.toJSON.apply(this, arguments);

        options = options || {};

        if(options.url && data.id !== null){
        	delete data.name;
        } else if(options.url) {
            delete data.id;
        }

        return data;
    }
});

module.exports = State;
