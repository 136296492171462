"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    Attribute = require('../models/Attribute');

var States = Collection.extend({
    url: function() {
        var url = Constants.restPath;

        if (this.hasProjects) {
            url = url + '/projects';
        }

        if (this.countryId) {
            url = url + '/countries/' + this.countryId + '/states';
        } else {
            url = url + '/states';
        }

        return url;
    },
    model: Attribute,
    pageSize: null,
    hasProjects: false,

    countryId: undefined,

    fetch: function(options) {
        options = options || {};
        options.data = options.data || {};

        if (options.countryId !== undefined) {
            this.countryId = options.countryId;
        }

        if (this.countryId && this.countryId !== 'NONE') {
            options.data.countryId = this.countryId;
        }

        if (options.hasProjects !== undefined) {
            this.hasProjects = options.hasProjects;
        }

        return Collection.prototype.fetch.call(this, options);
    }
});

module.exports = States;
