'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _gregorianCalendarFormat = require('gregorian-calendar-format');

var _gregorianCalendarFormat2 = _interopRequireDefault(_gregorianCalendarFormat);

var _gregorianCalendar = require('gregorian-calendar');

var _gregorianCalendar2 = _interopRequireDefault(_gregorianCalendar);

var _rcUtil = require('rc-util');

var _dateDateTable = require('./date/DateTable');

var _dateDateTable2 = _interopRequireDefault(_dateDateTable);

var _calendarCalendarHeader = require('./calendar/CalendarHeader');

var _calendarCalendarHeader2 = _interopRequireDefault(_calendarCalendarHeader);

var _calendarCalendarFooter = require('./calendar/CalendarFooter');

var _calendarCalendarFooter2 = _interopRequireDefault(_calendarCalendarFooter);

var _mixinCalendarMixin = require('./mixin/CalendarMixin');

var _mixinCalendarMixin2 = _interopRequireDefault(_mixinCalendarMixin);

function noop() {}

function goStartMonth() {
  var next = this.state.value.clone();
  next.setDayOfMonth(1);
  this.setValue(next);
}

function goEndMonth() {
  var next = this.state.value.clone();
  next.setDayOfMonth(next.getActualMaximum(_gregorianCalendar2['default'].MONTH));
  this.setValue(next);
}

function goMonth(direction) {
  var next = this.state.value.clone();
  next.addMonth(direction);
  this.setValue(next);
}

function goYear(direction) {
  var next = this.state.value.clone();
  next.addYear(direction);
  this.setValue(next);
}

function goWeek(direction) {
  var next = this.state.value.clone();
  next.addWeekOfYear(direction);
  this.setValue(next);
}

function goDay(direction) {
  var next = this.state.value.clone();
  next.addDayOfMonth(direction);
  this.setValue(next);
}

var Calendar = _react2['default'].createClass({
  displayName: 'Calendar',

  mixins: [_mixinCalendarMixin2['default']],

  propTypes: {
    value: _react2['default'].PropTypes.object,
    defaultValue: _react2['default'].PropTypes.object,
    className: _react2['default'].PropTypes.string,
    orient: _react2['default'].PropTypes.arrayOf(_react2['default'].PropTypes.oneOf(['left', 'top', 'right', 'bottom'])),
    locale: _react2['default'].PropTypes.object,
    showWeekNumber: _react2['default'].PropTypes.bool,
    style: _react2['default'].PropTypes.object,
    showToday: _react2['default'].PropTypes.bool,
    visible: _react2['default'].PropTypes.bool,
    showTime: _react2['default'].PropTypes.bool,
    onSelect: _react2['default'].PropTypes.func,
    onOk: _react2['default'].PropTypes.func,
    prefixCls: _react2['default'].PropTypes.string,
    onKeyDown: _react2['default'].PropTypes.func,
    onClear: _react2['default'].PropTypes.func,
    onChange: _react2['default'].PropTypes.func,
    onFocus: _react2['default'].PropTypes.func,
    onBlur: _react2['default'].PropTypes.func
  },

  getDefaultProps: function getDefaultProps() {
    return {
      showToday: true,
      onClear: noop,
      onOk: noop
    };
  },

  getInitialState: function getInitialState() {
    var props = this.props;
    this.dateFormatter = new _gregorianCalendarFormat2['default'](props.locale.dateFormat);
    var orient = props.orient;
    // bind methods
    this.nextMonth = goMonth.bind(this, 1);
    this.previousMonth = goMonth.bind(this, -1);
    this.nextYear = goYear.bind(this, 1);
    this.previousYear = goYear.bind(this, -1);
    return { orient: orient };
  },

  componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
    if (nextProps.orient) {
      this.setState({
        orient: nextProps.orient
      });
    }
    if (nextProps.locale !== this.props.locale) {
      this.dateFormatter = new _gregorianCalendarFormat2['default'](nextProps.locale.dateFormat);
    }
  },

  onKeyDown: function onKeyDown(e) {
    var keyCode = e.keyCode;
    // mac
    var ctrlKey = e.ctrlKey || e.metaKey;
    switch (keyCode) {
      case _rcUtil.KeyCode.DOWN:
        goWeek.call(this, 1);
        e.preventDefault();
        return 1;
      case _rcUtil.KeyCode.UP:
        goWeek.call(this, -1);
        e.preventDefault();
        return 1;
      case _rcUtil.KeyCode.LEFT:
        if (ctrlKey) {
          this.previousYear();
        } else {
          goDay.call(this, -1);
        }
        e.preventDefault();
        return 1;
      case _rcUtil.KeyCode.RIGHT:
        if (ctrlKey) {
          this.nextYear();
        } else {
          goDay.call(this, 1);
        }
        e.preventDefault();
        return 1;
      case _rcUtil.KeyCode.HOME:
        goStartMonth.call(this);
        e.preventDefault();
        return 1;
      case _rcUtil.KeyCode.END:
        goEndMonth.call(this);
        e.preventDefault();
        return 1;
      case _rcUtil.KeyCode.PAGE_DOWN:
        this.nextMonth();
        e.preventDefault();
        return 1;
      case _rcUtil.KeyCode.PAGE_UP:
        this.previousMonth();
        e.preventDefault();
        return 1;
      case _rcUtil.KeyCode.ENTER:
        this.onSelect(this.state.value);
        e.preventDefault();
        return 1;
      default:
        this.props.onKeyDown(e);
        return 1;
    }
  },

  onClear: function onClear() {
    this.props.onClear();
  },

  onOk: function onOk() {
    this.props.onOk(this.state.value);
  },

  render: function render() {
    var props = this.props;
    var locale = props.locale;
    var state = this.state;
    var value = state.value;
    var prefixCls = props.prefixCls;
    var children = _react2['default'].createElement(
      'div',
      { style: { outline: 'none' } },
      _react2['default'].createElement(_calendarCalendarHeader2['default'], {
        locale: locale,
        onValueChange: this.setValue,
        previousYear: this.previousYear,
        previousMonth: this.previousMonth,
        nextMonth: this.nextMonth,
        nextYear: this.nextYear,
        value: value,
        prefixCls: prefixCls }),
      _react2['default'].createElement(
        'div',
        { className: prefixCls + '-calendar-body' },
        _react2['default'].createElement(_dateDateTable2['default'], {
          locale: locale,
          value: value,
          prefixCls: prefixCls,
          dateRender: props.dateRender,
          onSelect: this.onSelect,
          disabledDate: props.disabledDate,
          showWeekNumber: props.showWeekNumber,
          dateFormatter: this.dateFormatter })
      ),
      _react2['default'].createElement(_calendarCalendarFooter2['default'], {
        locale: locale,
        showClear: props.showClear,
        showOk: props.showOk,
        prefixCls: prefixCls,
        showToday: props.showToday,
        showTime: props.showTime,
        value: value,
        disabledDate: props.disabledDate,
        dateFormatter: this.dateFormatter,
        onClear: this.onClear,
        onOk: this.onOk,
        onSelect: this.onSelect,
        onToday: this.chooseToday
      })
    );

    return this.renderRoot({
      children: children,
      className: props.showWeekNumber ? prefixCls + '-week-number' : ''
    });
  },

  chooseToday: function chooseToday() {
    var today = this.state.value.clone();
    today.setTime(Date.now());
    this.onSelect(today);
  }
});

exports['default'] = Calendar;
module.exports = exports['default'];