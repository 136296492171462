"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    ProjectContact = require('../models/ProjectContact');

var ProjectContacts = Collection.extend({
    url: ProjectContact.prototype.urlRoot,
    model: ProjectContact
});

module.exports = ProjectContacts;
