"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    Developer = require('../models/Developer');

var Developers = Collection.extend({
    url: Developer.prototype.urlRoot,
    model: Developer,

    filters: [
        'companyName',
        'email',
        'country',
        'state',
        'metro_area',
        'city'
    ],

    fetch: function(options) {
        options = options || {};
        options.data = options.data || {};

        if (options.countryId !== undefined) {
            this.countryId = options.countryId;
        }

        if (options.stateId !== undefined) {
            this.stateId = options.stateId;
        }

        if (options.metroId !== undefined) {
            this.metroId = options.metroId;
        }

        if (options.cityId !== undefined) {
            this.cityId = options.cityId;
        }

        if (this.countryId && this.countryId !== 'NONE') {
            options.data.country = this.countryId;
        }

        if (this.stateId && this.stateId !== 'NONE') {
            options.data.state = this.stateId;
        }

        if (this.metroId && this.metroId !== 'NONE') {
            options.data.metro_area = this.metroId;
        }

        if (this.cityId && this.cityId !== 'NONE') {
            options.data.city = this.cityId;
        }

        if (options.data && options.data.metro_area === 'NONE') {
            delete options.data.metro_area;
        }

        return Collection.prototype.fetch.call(this, options);
    }
});

module.exports = Developers;
