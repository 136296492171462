"use strict";

var ModelClass = require('./Model'),
    FileNameUtils = require('../shared/FileNameUtils'),
    Constants = require('../constants'),
    _ = require('underscore'),
    utilities = require('../shared/Utilities');

var Promotion = ModelClass.extend({
    urlRoot: Constants.restPath + "/promotions",
    idAttribute: 'id',
    validation: {
        name: {
            required: true
        },
        value: {
            pattern: 'number',
            required: false
        },
        currency: {
            oneOf: ['USD', 'EUR', 'JPY']
        },
        endDate: function(value, attr, customValue, model) {
            return customValue.hasDates ? utilities.isValidDateRange(customValue.startDate, value, true) : undefined;
        },
        startDate: function(value, attr, customValue, model) {
            return customValue.hasDates ? utilities.isValidDateRange(value, customValue.endDate, true) : undefined;
        }
    },
    validationUses: {
        startDate: ['endDate', 'hasDates'],
        endDate: ['startDate', 'hasDates']
    },
    defaults: function() {
        return {
            name: '',
            description: '',
            type: {
                id: 1
            },
            fileName: 'Current File',
            active: false,
            startDate: '',
            endDate: '',
            currency: 'USD',
            value: null,
            files: [],
            link: '',
            hasDates: false
        };
    },
    getFileNameIcon: function() {
        return FileNameUtils.fileNameToFaIcon(this.get('link'));
    },

    toJSON: function(options) {
        var data = ModelClass.prototype.toJSON.apply(this, arguments);

        options = options || {};

        if (options.url) {
            delete data.files;
        }

        return data;
    },

    parse: function(response) {
        if (response) {
            response.hasDates = !!(response.startDate || response.endDate);
        }
        if (response && response.link) {
            response.fileName = response.originalFileName || response.link.match(/[^\\/]+$/)[0];
            response.files = [{
                link: response.link,
                name: response.fileName
            }];
        }
        return response;
    }
});

module.exports = Promotion;
