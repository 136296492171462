'use strict';

var keyMirror = require('keymirror'),
    _ = require('underscore'),
    MobileDetect = require('mobile-detect'),
    roles = keyMirror({
        USER: null,
        ADMIN: null,
        AGENT: null,
        DEVELOPER: null,
        BUILDER: null,
        BROKER: null,
        LISTING_USER: null
    }),
    md = new MobileDetect(window.navigator.userAgent),
    mobile = md.mobile();

var constants = {
    md: md,
    canClipboardCopy: mobile !== 'iPhone' && mobile !== 'iPad',
    CONTACTUALLY_URL: 'https://www.contactually.com/oauth2/authorize?client_id=747d21c056aa450038b3c281ee54ad507e4bf7a694c1d568b91722b2eaafc308&amp;redirect_uri=https%3A%2F%2Fadmin.as%2Fagent-shield-1.0%2Frest%2Fcallback&amp;response_type=code&amp;scope=user:login+contacts:manage+interactions:manage+buckets:view',
    root: '/',
    env: window._AS_ENV_,
    imageRoot: "production" === 'development' ? '/public/resources/images/' : '/resources/images/',
    publicRoot: "production" === 'development' ? '/public/' : '/',
    trackingPath: ("production" === 'development' ? '//dev-api.agentshield.com/t/1x1clear.gif' : '//api.agentshield.com/t/1x1clear.gif') + '?t=' + new Date().getTime(),
    server: "production" === 'development' ? 'http://dev.agentshield.com' : 'http://app.agentshield.com',
    restPath: '/agent-shield-1.0/rest',
    apiPath: '/agent-shield-1.0',
    widgetHost: '//widgets.agentshield.com',
    defaultRedirect: ['Dashboard'],
    roles: roles,
    jsonPSupport: false,
    placeholder: require('../images/placeholder.js'),
    trackerPrefix: 'agent-shield-',
    intercomAppId: "production" === 'development' ? 'fglggop8' : 'fglggop8',
    intercomAppIdAgent: 'fglggop8',
    googleAnalyticsId: 'UA-462934-5',
    segmentId: 'PRVG6s9TWd0fg3eyIPrGuJ4xNbRhwQYS',
    stripeKey: 'pk_test_XLAxw9tynHDXOZtjqd6DdgJ9',
    imageMimeTypes: { title: 'Image files', extensions: 'jpg,jpeg,gif,png' },
    thumbRoot: '//s3.amazonaws.com/thumbs.agentshield.com/',
    publicSite: '//newconstructionhub.com',
    coupons: {},
    // intercomAppId: process.env.NODE_ENV === "development" ? 'fglggop8' : 'eovn4bxl',
    userTypes: [{
        id: 'Agent',
        name: 'Agent'
    }, {
        id: 'Broker',
        name: 'Broker'
    }, {
        id: 'Developer',
        name: 'Real Estate Builder/Developer'
    }],
    routes: {
        AGENT: [{
            to: 'AgentDetails'
        }, {
            to: 'AgentAdd'
        }, {
            to: 'AgentEdit'
        }, {
            to: 'AgentList'
        }],

        PROJECT: [{
            to: 'Project'
        }, {
            to: 'ProjectAdd'
        }, {
            to: 'ProjectList'
        }],

        BROKER: [{
            to: 'BrokerDetails'
        }, {
            to: 'BrokerAdd'
        }, {
            to: 'BrokerList'
        }],

        DEVELOPER: [{
            to: 'DeveloperDetails'
        }, {
            to: 'DeveloperAdd'
        }, {
            to: 'DeveloperList'
        }],

        USER: [{
            to: 'UserDetails'
        }, {
            to: 'UserAdd'
        }, {
            to: 'UserList'
        }, {
            to: 'UserAccount'
        }]
    }
};

constants.coupons[require('../shared/Crypto').decrypt('6hNNEB3dpfTg28FMpl0FNvvKNNpEC8+7')] = '2'; // TESTIMONIALTREE2
constants.coupons[require('../shared/Crypto').decrypt('6hNNEB3dpfRdrUwalCZfWg==')] = '1'; // TESTIMONIALTREE
constants.coupons[require('../shared/Crypto').decrypt('pYLdYKuyuxnXLRLJtcRzjJAh8lJVB7s1')] = '2'; // NEWHOMECOBROKER2
constants.coupons[require('../shared/Crypto').decrypt('pYLdYKuyuxlZ+AxRI3/7uQ==')] = '1'; // NEWHOMECOBROKER
constants.coupons[require('../shared/Crypto').decrypt('WJDdhVFrwv7KF2oYKqA5TA==')] = '2'; // CONTACTUALLY2
constants.coupons[require('../shared/Crypto').decrypt('WJDdhVFrwv4s+vsvxZv1eQ==')] = '1'; // CONTACTUALLY
constants.coupons[require('../shared/Crypto').decrypt('2jKcn8iWVYotNin5KGpPUQ==')] = '2'; // REFINDLY2
constants.coupons[require('../shared/Crypto').decrypt('2jKcn8iWVYprdcI7WK25ug==')] = '1'; // REFINDLY
constants.coupons[require('../shared/Crypto').decrypt('bZksILUFc2/vRp/gNc8Vsg==')] = '1'; // SPECIAL1
constants.coupons[require('../shared/Crypto').decrypt('gDT+slQjX5u3R8p8VSye5w==')] = '0'; // NCHSUPERAGENT
constants.coupons[require('../shared/Crypto').decrypt('4d3QZ+wyiBxjqaIcfWTJWw==')] = 0.5; // SALESALE

constants.coupons[require('../shared/Crypto').decrypt('hM2wTiqCB+E=')] = 9; // RF
constants.coupons[require('../shared/Crypto').decrypt('mZ2IYcROQWY=')] = 9; // RF
constants.coupons[require('../shared/Crypto').decrypt('azR0iUX8yD4=')] = 9; // RF
constants.coupons[require('../shared/Crypto').decrypt('9cYBqXNe0MI=')] = '1'; // NCH10
constants.coupons[require('../shared/Crypto').decrypt('OzLWuZVnJ8c=')] = '1'; // NCH15
constants.coupons[require('../shared/Crypto').decrypt('aMVFqIki/ds=')] = '1'; // NCH20
constants.coupons[require('../shared/Crypto').decrypt('HBB2DV+QcgU=')] = '1'; // NCH25
constants.coupons[require('../shared/Crypto').decrypt('jKjZKrzL07Y=')] = '1'; // 10NCH
constants.coupons[require('../shared/Crypto').decrypt('LfiIwOOjMyY=')] = '1'; // 15NCH
constants.coupons[require('../shared/Crypto').decrypt('ELIwdbDWKLc=')] = '1'; // 20NCH
constants.coupons[require('../shared/Crypto').decrypt('D8BIjPWjwfU=')] = '1'; // 25NCH
constants.coupons[require('../shared/Crypto').decrypt('bZksILUFc2+L4mSTZ0TnzQ==')] = '1'; // SPECIAL10
constants.coupons[require('../shared/Crypto').decrypt('bZksILUFc28WM7TVEHdlSA==')] = '1'; // SPECIAL15
constants.coupons[require('../shared/Crypto').decrypt('A6qMvuoG7DMJ7V5Up7iY6w==')] = '1'; // SPECIAL20
constants.coupons[require('../shared/Crypto').decrypt('A6qMvuoG7DOdb3nPdJQGvQ==')] = '1'; // SPECIAL25

switch (window._AS_ENV_) {
    case 'dev':
        _.extend(constants, require('./config_dev'));
        break;
    case 'prod':
        _.extend(constants, require('./config_prod'));
        break;
    case 'staging':
        _.extend(constants, require('./config_staging'));
        break;
}

module.exports = constants;
