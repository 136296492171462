"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    cx = require('classnames'),
    _ = require('underscore'),
    $ = require('jquery'),

    CountrySelect = require('../../components/CountrySelect'),
    StateSelect = require('../../components/StateSelect'),
    CitySelect = require('../../components/CitySelect'),
    DeveloperSelect = require('../../components/DeveloperSelect'),
    MetroSelect = require('../../components/MetroSelect'),
    Validation = require('../validation');

var ProjectFilters = React.createClass({displayName: "ProjectFilters",
    getDefaultProps: function() {
        return {
            namePrefix: undefined,
            country: undefined,
            state: undefined,
            metro: undefined,
            city: undefined,
            developer: undefined,
            showMapButton: false,
            showMap: false,

            hidden: false,
            loading: false,

            countryLink: undefined,
            stateLink: undefined,
            metroLink: undefined,
            cityLink: undefined,
            developerLink: undefined,
            showMapLink: false,

            showCountryAndState: false
        };
    },

    getInitialState: function() {
        return {};
    },

    render: function() {
        var me = this,
            props = me.props,
            show = props.show,
            namePrefix = props.namePrefix,
            className = cx('modal-container', props.className),
            countryVal = me.getFieldValue('country'),
            stateVal = me.getFieldValue('state'),
            metroVal = me.getFieldValue('metro'),
            cityVal = me.getFieldValue('city'),
            communityVal = me.getFieldValue('community'),
            developerVal = me.getFieldValue('developer'),
            showMapVal = me.getFieldValue('showMap'),
            filterClassName = cx({
                'col-sm-3': props.showCountryAndState,
                'col-sm-6': !props.showCountryAndState,
                'col-xs-12': true,
                'padding-side-8': true,
                'filter': true
            }),
            developerClassName = cx({
                'col-sm-6': true,
                'col-md-3': true,
                'col-xs-12': true,
                'col-divider-md': true
            });

        return (
           React.createElement("div", {className: "panel panel-default projects-listing-filters", style: {display: !props.hidden ? 'block': 'none'}}, 
                React.createElement("div", {className: "panel-body"}, 
                    React.createElement("div", {className: "col-md-6 col-xs-12"}, 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-xs-12 padding-side-8"}, 
                                React.createElement("label", {className: "filter-label"}, "Filter By Location:"), 
                                React.createElement("i", {className: "fa fa-spinner fa-pulse fa-1-5x pull-right", style: {display: props.loading ? 'block' : 'none'}})
                            )
                        ), 

                        React.createElement("div", {className: "row half-gutter"}, 
                            React.createElement("div", {className: cx(filterClassName, 'padding-side-8'), style: { display: props.showCountryAndState ? 'block' : 'none'}}, 
                                React.createElement(CountrySelect, {name: namePrefix+'country', hasProjects: true, value: countryVal, onChange: this.updateCountryState, label: "", defaultText: "View All Countries"})
                            ), 
                            React.createElement("div", {className: filterClassName, style: { display: props.showCountryAndState && countryVal ? 'block' : 'none'}}, 
                                React.createElement(StateSelect, {name: namePrefix+'state', hasProjects: true, value: stateVal, onChange: this.updateStateState, countryId: countryVal, label: "", defaultText: "View All States/Regions"})
                            ), 
                            React.createElement("div", {className: filterClassName, style: {display: stateVal ? 'block' : 'none'}}, 
                                React.createElement(MetroSelect, {name: namePrefix+'metro', hasProjects: true, value: metroVal, onChange: this.updateMetroState, countryId: countryVal, stateId: stateVal, label: "", defaultText: "View All Metro Areas"})
                            ), 
                            React.createElement("div", {className: filterClassName, style: {display: stateVal ? 'block' : 'none'}}, 
                                React.createElement(CitySelect, {name: namePrefix+'city', hasProjects: true, value: cityVal, onChange: this.updateCityState, countryId: countryVal, stateId: stateVal, metroId: metroVal, label: "", defaultText: "View All Cities"})
                            )
                        )
                    ), 
                    React.createElement("div", {className: developerClassName}, 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-xs-12 padding-side-8"}, 
                                React.createElement("label", null, "Filter By Builder:")
                            )
                        ), 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-xs-12 padding-side-8"}, 
                                React.createElement(DeveloperSelect, {name: namePrefix+'developer', hasProjects: true, value: developerVal, onChange: this.updateDeveloperState, countryId: countryVal, stateId: stateVal, metroId: metroVal, cityId: cityVal, label: "", defaultText: "Show All Builders"})
                            )
                        )
                    ), 
                    React.createElement("div", {className: developerClassName}, 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-xs-12 padding-side-8"}, 
                                React.createElement("label", null, "Filter By Community Name:")
                            )
                        ), 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-xs-12 padding-side-8 input-with-button"}, 
                                React.createElement(Validation.Input, {type: "text", showClear: props.showSearchClear, clearable: true, name: namePrefix+'community', value: communityVal, onKeyUp: this.onCommunityKeyUp, onClear: this.clearCommunity, onChange: this.updateCommunityValue, label: "", defaultText: "Filter By Community Name"}), 
                                React.createElement("a", {className: "btn-u btn-u-blue", onClick: props.onGoClick}, "Go")
                            )
                        )
                    )
                )
            )
        );
    },

    onCommunityKeyUp: function(e) {
        if(e.key === 'Enter' && this.props.onGoClick) {
            this.props.onGoClick(e);
        }
    },

    clearCommunity: function(e) {
        var me = this;

        me.updateFilter('community', '');
        setTimeout(function() {
            me.props.onGoClick(e);
        }, 100);
    },

    onShowMapButtonClick: function(e) {
        e.preventDefault();
        this.updateFilter('showMap', !this.getFieldValue('showMap'));
    },

    updateFilter: function(name, value) {
        var linkName = name + 'Link';
        if (this.props[linkName] && this.props[linkName].requestChange) {
            this.props[linkName].requestChange(value);
        }
    },

    updateCommunityValue: function(e) {
        this.updateFilter('community', e.target.value);
    },

    getFieldValue: function(name){
        var linkName = name + 'Link',
            value = this.props[name];

        if(this.props[linkName]){
            value = this.props[linkName].value;
        }

        return value;
    },

    updateCountryState: function(e) {
        this.updateFilter('country', e.currentTarget.value);
        this.updateFilter('state', '');
        this.updateFilter('metro', '');
        this.updateFilter('city', '');
        this.updateFilter('developer', '');
    },

    updateStateState: function(e) {
        this.updateFilter('state', e.currentTarget.value);
        this.updateFilter('metro', '');
        this.updateFilter('city', '');
        this.updateFilter('developer', '');
    },

    updateMetroState: function(e) {
        this.updateFilter('metro', e.currentTarget.value);
        this.updateFilter('city', '');
        this.updateFilter('developer', '');
    },

    updateCityState: function(e) {
        this.updateFilter('city', e.currentTarget.value);
        this.updateFilter('developer', '');
    },

    updateDeveloperState: function(e) {
        this.updateFilter('developer', e.currentTarget.value);
    },
});

module.exports = ProjectFilters;
