"use strict";
var _define = window.define;
window.define = void 0;

var Backbone = require('backbone'),
    _ = require('underscore'),
    Constants = require('../constants'),
    URI = require('urijs');
    
window.define = _define;
require('backbone-validation');

Backbone.Validation.configure({
    labelFormatter: 'label'
});

var Model = Backbone.Model.extend({
    synced: false,
    loading: false,
    lastSynced: undefined,
    restoreDefaults: true,
    strictSave: false,
    setAssociations: true,
    jsonPSupport: Constants.jsonPSupport,
    hasOne: undefined,
    hasMany: undefined,
    rootPath: Constants.restPath,
    includeAssociations: true,
    validation: {},
    usingJsonP: false,

    limitParam: 'limit',
    startParam: 'offset',
    pageSize: undefined,
    pageNum: 1,
    total: undefined,
    validateOnSave: false,

    constructor: function(attributes, options) {
        options = options || {};

        if (options.includeAssociations !== undefined) {
            this.includeAssociations = options.includeAssociations;
        }

        if (this.includeAssociations) {
            this.initHasOnes(options);
            this.initHasMany(options);
        }

        Backbone.Model.prototype.constructor.apply(this, arguments);

        this.usingJsonP = URI(window.location.href).host() !== URI(this.rootPath).host();
    },

    initialize: function() {
        this.on('sync', this.onSync, this);
        this.on('request', this.onRequest, this);
        this.on('error', this.onError, this);

        return Backbone.Model.prototype.initialize.apply(this, arguments);
    },

    onSync: function() {
        var args;
        this.synced = true;
        this.lastSynced = (new Date()).getTime();
        this.loading = false;

        if (this.parent && this.parent.hasOne[this.parentChildReference].triggerSync) {
            args = Array.prototype.slice.call(arguments);
            this.parent.trigger.apply(this.parent, ['sync sync:' + this.parentChildReference].concat(args));
        }
    },

    onRequest: function() {
        this.loading = true;
    },

    isLoaded: function() {
        return this.synced;
    },

    isLoading: function() {
        return this.loading;
    },

    onError: function() {
        this.loading = false;
    },

    getUrl: function(method, collection, options) {
        var url = _.result(this, 'url'),
            u = URI;

        if (this.jsonPSupport && this.usingJsonP) {
            options.dataType = 'jsonp';
            url = url.replace(this.rootPath, '');
            url = this.rootPath + '/jsonp' + url;
        }

        return url;
    },

    fetch: function(options) {
        var me = this;

        options = options || {};

        if (options.pageSize) {
            me.pageSize = options.pageSize;
        }

        if (!options.fetchAll && me.pageSize) {
            options.data[me.limitParam] = me.pageSize;
            options.data[me.startParam] = me.getStart();
        }

        return Backbone.Model.prototype.fetch.call(this, options);
    },

    getStart: function() {
        return ((this.pageNum - 1) * this.pageSize);
    },

    getTotal: function() {
        return this.total || this.length || 0;
    },

    /**
     * Returns the current paging values.
     * @return {Object} [description]
     *         {Object.start}
     *         {Object.end}
     *         {Object.total}
     */
    getRange: function() {
        var pageNum = parseInt(this.pageNum || 1, 10),
            start = this.getStart(),
            end = start + this.pageSize - 1,
            total = this.getTotal();

        if (end >= total) {
            end = total - 1;
        }

        return {
            start: start,
            end: end,
            total: total,
            pageNum: pageNum,
            pageSize: this.pageSize
        };
    },

    sync: function(method, model, options) {
        options = options || {};
        options.syncMethod = method;

        options.url = options.url || this.getUrl(method, model, options);

        return Backbone.Model.prototype.sync.call(this, method, model, options);
    },

    /**
     *  Looks for the 'parent' property on either the model itself
     *  or if the model belongs to a collection it does the lookup there.
     */
    getParent: function() {
        var parent = this.parent;
        if (!parent && this.collection && this.collection.parent) {
            parent = this.collection.parent;
        }
        return parent;
    },

    clear: function(options) {
        var silent;

        options = options || {};
        silent = options.silent;
        this.total = 0;

        this.clearAssociations(options);

        if (this.restoreDefaults) {
            options.silent = true;
            Backbone.Model.prototype.clear.call(this, options);
            options.silent = silent;
            this.set(_.result(this, 'defaults'), options);
        } else {
            Backbone.Model.prototype.clear.call(this, options);
            if (!options.silent) {
                this.trigger('reset', this, options);
            }
        }
    },

    /**
     * Extended to support passing 'fields' which dictate what gets saved.
     * @param  {Object} options [description]
     * @return {Object}         [description]
     */
    toJSON: function(options) {
        var data = Backbone.Model.prototype.toJSON.apply(this, arguments);

        options = options || {};

        this.toJSONAssociations(data, options);

        if (options.fields && options.fields.length) {
            data = _.deepPick(data, options.fields);
        }

        return data;
    },

    save: function(attributes, options) {
        var me = this;

        attributes = attributes || {};
        options = options || {};

        if (this.strictSave) {
            attributes = _.pick.apply(_, [attributes || {}].concat(_.keys(this.defaults() || {})));
        }

        options.validate = !!options.validate || !!this.validateOnSave;

        return Backbone.Model.prototype.save.call(this, attributes, options);
    },

    set: function(key, val, options) {
        // Handle both `"key", value` and `{key: value}` -style arguments.
        var attrs, attr;
        if (key == null || typeof key === 'object') {
            attrs = key;
            options = val;
        } else {
            (attrs = {})[key] = val;
        }

        if (!this.setAssociations || !this.hasOne) {
            return Backbone.Model.prototype.set.apply(this, arguments);
        }

        for (attr in this.hasOne) {
            if (_.has(attrs, this.hasOne[attr].responseKey)) {
                this[this.hasOne[attr].key].set(attrs[this.hasOne[attr].responseKey], options);
                delete attrs[this.hasOne[attr].responseKey];
            }
        }
        return Backbone.Model.prototype.set.call(this, attrs, options);
    },

    initHasOnes: function(options) {
        var me = this,
            hasOne = me.hasOne = _.extend(me.hasOne || {}, options.hasOne),
            model;

        _.each(hasOne, function(responseKey, modelKey) {
            var _Model = options[modelKey] || me[modelKey] || me.constructor[modelKey],
                hasOneOptions = _.isObject(responseKey) ? responseKey : {
                    responseKey: responseKey
                };

            responseKey = hasOneOptions.responseKey;
            _.defaults(hasOneOptions, {
                key: hasOneOptions.responseKey + 'Model'
            });
            hasOne[modelKey] = hasOneOptions;

            if (!_Model) {
                throw 'Model missing for association: ' + modelKey + ' | ' + responseKey;
            }

            model = new _Model(undefined, {
                includeAssociations: hasOneOptions.includeAssociations
            });
            model.parent = me;
            model.parentChildReference = modelKey;
            me[hasOneOptions.key] = model
        });
    },

    initHasMany: function(options) {
        var me = this,
            hasMany = me.hasMany = _.extend(me.hasMany || {}, options.hasMany);

        _.each(hasMany, function(responseKey, collectionKey) {
            var _Collection = options[collectionKey] || me[collectionKey] || me.constructor[collectionKey],
                hasManyOptions = _.isObject(responseKey) ? responseKey : {
                    responseKey: responseKey
                };

            responseKey = hasManyOptions.responseKey;
            _.defaults(hasManyOptions, {
                key: responseKey + 'Collection',
                includeKey: 'include' + responseKey.charAt(0).toUpperCase() + responseKey.slice(1)
            });
            hasMany[collectionKey] = hasManyOptions;

            if (!_Collection) {
                throw 'Collection missing for association: ' + collectionKey + ' | ' + responseKey;
            }

            me[hasManyOptions.key] = new _Collection(undefined, {
                parent: me
            });
        });

    },

    parse: function(response) {
        var data = Backbone.Model.prototype.parse.apply(this, arguments);

        if (data && this.includeAssociations) {
            this.parseHasOnes(data);
            this.parseHasMany(data);
        }

        this.total = response ? response.total : undefined;

        return data;
    },

    parseHasOnes: function(response) {
        var me = this,
            hasOne = me.hasOne;

        _.each(hasOne, function(options, modelKey) {
            var modelInstance = me[options.key];

            if (response[options.responseKey]) {
                if (_.isArray(response[options.responseKey])) {
                    response[options.responseKey] = response[options.responseKey][0];
                }
                modelInstance.clear({
                    silent: true
                });
                modelInstance.set(modelInstance.parse(response[options.responseKey]));
                delete response[options.responseKey];
            }
        });
    },

    parseHasMany: function(response) {
        var me = this,
            hasMany = me.hasMany;

        _.each(hasMany, function(options, collectionKey) {
            var collectionInstance = me[options.key];

            if (response[options.responseKey]) {
                collectionInstance.reset(response[options.responseKey], {
                    parse: true
                });
                collectionInstance.synced = true;
                delete response[options.responseKey];
            }
        });
    },

    toJSONAssociations: function(data, options) {
        var me = this,
            hasOne = me.hasOne,
            hasMany = me.hasMany;

        if (me.includeAssociations) {
            _.each(hasOne, function(hasOneOptions, modelKey) {
                var modelInstance = me[hasOneOptions.key],
                    modelOptions = _.extend({}, options);

                delete modelOptions.fields;

                if (modelInstance.id || hasOneOptions.alwaysInclude) {
                    data[hasOneOptions.responseKey] = modelInstance.toJSON(modelOptions);
                }
            });

            _.each(hasMany, function(hasManyOptions, collectionKey) {
                var collectionInstance = me[hasManyOptions.key];

                if (options[hasManyOptions.includeKey]) {
                    data[hasManyOptions.responseKey] = collectionInstance.toJSON(options);
                }
            });
        }

        return data;
    },

    clearAssociations: function(options) {
        var me = this,
            hasOne = me.hasOne,
            hasMany = me.hasMany;

        if (me.includeAssociations) {
            _.each(hasOne, function(hasManyOptions, modelKey) {
                var modelInstance = me[hasManyOptions.key];

                if (modelInstance) {
                    modelInstance.clear(options);
                }
            });

            _.each(hasMany, function(hasManyOptions, collectionKey) {
                var collectionInstance = me[hasManyOptions.key];

                if (collectionInstance) {
                    collectionInstance.reset();
                }
            });
        }
    },

    isReadOnly: function() {
        return !!this.get('listingSource') && this.get('listingSource') !== 'agentshield';
    },

    hasServiceType: function() {
        return false;
    }
});

_.extend(Model.prototype, Backbone.Validation.mixin, {
    validate: function(attrs, options) {
        var me = this,
            existingValidateFilter,
            validation;

        // Allow associations to be validated
        attrs = attrs || this.toJSON();
        options = options || {};

        if (options.filter) {
            existingValidateFilter = this.validateFilter;
            this.validateFilter = options.filter;
        }

        validation = Backbone.Validation.mixin.validate.call(me, options.fields ? _.deepPick(attrs, options.fields) : _.isEmpty(attrs) ? undefined : attrs, options);

        if (options.fields) {
            validation = _.pick(validation, options.fields);
            if (_.keys(validation).length === 0) {
                validation = undefined;
            }
        }

        if (me.includeAssociations) {
            _.each(me.hasOne, function(hasOneOptions) {
                if (!hasOneOptions.validate) {
                    return;
                }

                var hasOneAttrs = attrs[hasOneOptions.responseKey],
                    hasOneValidation = hasOneAttrs && me[hasOneOptions.key].validate(hasOneAttrs, options);

                if (hasOneValidation) {
                    validation = validation || {};
                    _.each(hasOneValidation, function(error, key) {
                        var validationKey = hasOneOptions.responseKey + '.' + key;
                        if (!attrs[validationKey]) {
                            validation[validationKey] = error;
                        }
                    })
                }

            });
        }

        if (options.filter) {
            this.validateFilter = existingValidateFilter;
        }

        return validation;
    }
});

_.each(Backbone.Validation.messages, function(value, key) {
    Backbone.Validation.messages[key] = value + '.';
});

Backbone.Validation.patterns.no_numbers = /^[^0-9]+$/;
Backbone.Validation.messages.no_numbers = "{0} can not contain numbers.";
module.exports = Model;
