"use strict";

var Constants = require('../constants'),
    UserEntity = require('./UserEntity'),
    $ = require('jquery');

var Broker = UserEntity.extend({
    urlRoot: Constants.restPath + "/agents",
    idAttribute: 'id',

    validation: {
        website: {
            required: false
        },
        "contactInfo.firstName": {
            required: true,
            pattern: 'no_numbers'
        },
        "contactInfo.lastName": {
            required: true,
            pattern: 'no_numbers'
        },
        "teamName": {
            required: function(value, attributes, computedState) {
                if (computedState.nameToDisplay === 'TeamName' && !value) {
                    return "Team Name is required";
                }
            },
            pattern: 'no_numbers'
        }
    },

    validationUses: {
        "teamName": ['nameToDisplay']
    },

    labels: {
        "contactInfo.firstName": 'First Name',
        "contactInfo.lastName": 'Last Name'
    },

    defaults: function() {
        return {
            id: undefined,
            website: '',
            howHeard: '',
            createDate: new Date(),
            brokerageName: '',
            nameToDisplay: '',
            teamName: '',
            isBroker: true,
            user: {}
        };
    },

    toJSON: function(options) {
        var data = UserEntity.prototype.toJSON.apply(this, arguments);

        options = options || {};

        if (!options.url) { //read
            data.name = this.getDisplayName();
        } else { //write
            if (!$.isEmptyObject(data.user)) {
                data.users = [data.user];
                delete data.user;
            }
        }

        return data;
    },

    getDisplayName: function(contactName) {
        var nameToDisplay = this.get('nameToDisplay'),
            contactInfo = this.contactInfoModel.toJSON(),
            name;

        if (contactName === true) {
            nameToDisplay = 'FirstAndLast';
        }

        switch (nameToDisplay) {
            case 'Brokerage':
                name = this.get('brokerageName');
                break;
            case 'TeamName':
                name = this.get('teamName');
                break;

            case 'FirstAndLast':
            default:
                name = contactInfo ? (contactInfo.firstName + ' ' + contactInfo.lastName) : '';
                break;
        }

        return name;
    },
    save: function() {
        var users = this.get('users'),
            email = this.contactInfoModel.get('email');

        users && users.length == 1 && (users[0].email = email); //only if single user is attached


        return UserEntity.prototype.save.apply(this, arguments);
    }
});

module.exports = Broker;
