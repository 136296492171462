"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    FluxBone = require('../../mixins/FluxBone'),
    ProjectModel = require('../../models/Project'),
    ReactCatalyst = require('../../mixins/react-catalyst'),
    RouteHandler = ReactRouter.RouteHandler;

var ImageHandler = React.createClass({displayName: "ImageHandler",

    mixins: [
        FluxBone.ModelMixin('model.imagesCollection', 'change'),
    ],
    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            model =  this.props.model,
            imageId = params.imageid,
            imageModel;


            model.imagesCollection && (imageModel = model.imagesCollection.get(imageId));

        return {
            imageModel : imageModel
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function(){
        return {
            model: new ProjectModel()
        };
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state;

        return (
            React.createElement(RouteHandler, {model: props.model, image: state.imageModel, readOnly: props.readOnly})
        );
    }
});
module.exports = ImageHandler;
