"use strict";

var _ = require('underscore'),
    Constants = require('../constants');

// https://gist.github.com/CalebGrove/c285a9510948b633aa47
// USAGE:
// abbrState('ny', 'name');
// --> 'New York'
// abbrState('New York', 'abbr');
// --> 'NY'
// 
var states = [
    ['Arizona', 'AZ'],
    ['Alabama', 'AL'],
    ['Alaska', 'AK'],
    ['Arizona', 'AZ'],
    ['Arkansas', 'AR'],
    ['California', 'CA'],
    ['Colorado', 'CO'],
    ['Connecticut', 'CT'],
    ['Delaware', 'DE'],
    ['Florida', 'FL'],
    ['Georgia', 'GA'],
    ['Hawaii', 'HI'],
    ['Idaho', 'ID'],
    ['Illinois', 'IL'],
    ['Indiana', 'IN'],
    ['Iowa', 'IA'],
    ['Kansas', 'KS'],
    ['Kentucky', 'KY'],
    ['Kentucky', 'KY'],
    ['Louisiana', 'LA'],
    ['Maine', 'ME'],
    ['Maryland', 'MD'],
    ['Massachusetts', 'MA'],
    ['Michigan', 'MI'],
    ['Minnesota', 'MN'],
    ['Mississippi', 'MS'],
    ['Missouri', 'MO'],
    ['Montana', 'MT'],
    ['Nebraska', 'NE'],
    ['Nevada', 'NV'],
    ['New Hampshire', 'NH'],
    ['New Jersey', 'NJ'],
    ['New Mexico', 'NM'],
    ['New York', 'NY'],
    ['North Carolina', 'NC'],
    ['North Dakota', 'ND'],
    ['Ohio', 'OH'],
    ['Oklahoma', 'OK'],
    ['Oregon', 'OR'],
    ['Pennsylvania', 'PA'],
    ['Rhode Island', 'RI'],
    ['South Carolina', 'SC'],
    ['South Dakota', 'SD'],
    ['Tennessee', 'TN'],
    ['Texas', 'TX'],
    ['Utah', 'UT'],
    ['Vermont', 'VT'],
    ['Virginia', 'VA'],
    ['Washington', 'WA'],
    ['West Virginia', 'WV'],
    ['Wisconsin', 'WI'],
    ['Wyoming', 'WY'],
];


function abbrState(input, to) {
    if (to == 'abbr') {
        input = input.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
        for (var i = 0; i < states.length; i++) {
            if (states[i][0] == input) {
                return (states[i][1]);
            }
        }
    } else if (to == 'name') {
        input = input.toUpperCase();
        for (i = 0; i < states.length; i++) {
            if (states[i][1] == input) {
                return (states[i][0]);
            }
        }
    }
}

// Returns nothing if valid
var isValidDate = function(date, required) {
    date = date && !_.isDate(date) ? new Date(date) : date;

    if (required && !date) {
        return '{0} is required';
    } else if (date && _.isNaN(date.getTime())) {
        return '{0} is invalid';
    }
};

// Use native trim when defined
var trim = String.prototype.trim ?
    function(text) {
        return text === null ? '' : String.prototype.trim.call(text);
    } :
    function(text) {
        var trimLeft = /^\s+/,
            trimRight = /\s+$/;

        return text === null ? '' : text.toString().replace(trimLeft, '').replace(trimRight, '');
    };

var hasValue = function(value) {
    return !(_.isNull(value) || _.isUndefined(value) || (_.isString(value) && trim(value) === '') || (_.isArray(value) && _.isEmpty(value)));
};

var addImageLink = function(image, size, linkAttr, folderName, filenameAttr) {
    linkAttr = linkAttr || 'link';
    folderName = folderName || image['folder'] || image['imageFolder'] || 'Pics';
    filenameAttr = filenameAttr || 'filename';

	// @TODO: cleanup this ugly hack, where we need to check for the folder so we know which dimension to use.
	size = size || (folderName == "As_Thumbs" ? '135x130' : '300px');

    if (image[filenameAttr] && image[filenameAttr].toLowerCase().indexOf('http') === -1 && image[filenameAttr].toLowerCase().indexOf('//') !== 0) {
        image[linkAttr] = Constants.thumbRoot + folderName + '/' + size + '/' + image[filenameAttr];
    } else {
        image[linkAttr] = image[filenameAttr];
    }
};

var utils = {
    addImageLinks: function(images, size, linkAttr, folderAttr, filenameAttr) {
        for (var i = 0, len = images.length; i < len; i++) {
            addImageLink(images[i], size, linkAttr, folderAttr, filenameAttr);
        }
    },

    addImageLink: addImageLink,

    abbrState: abbrState,
    // Returns nothing if valid
    isValidDate: isValidDate,
    // Returns nothing if valid
    isValidDateRange: function(startDate, endDate, required) {
        var startDateIsValid = !isValidDate(startDate, required),
            endDateIsValid = !isValidDate(endDate, required);

        startDate = startDate && startDate.getTime ? startDate.getTime() : startDate;
        endDate = endDate && endDate.getTime ? endDate.getTime() : endDate;

        if (startDateIsValid && endDateIsValid && startDate > endDate) {
            return 'Start date must be before end date.';
        } else if (required && (!startDateIsValid || !endDateIsValid)) {
            return 'Invalid date range.';
        } else if (required && startDateIsValid && endDateIsValid) {
            return;
        } else if (required) {
            return 'Date is required.';
        }
    },
    hasValue: hasValue,
    trim: trim,

    locationValidation: function(value, attr, computedState) {
        var msg;

        if (computedState && computedState.customLocation === true) {
            return;
        } else if (!hasValue(value)) {
            msg = 'Address is required. Do not include suite or unit numbers in this field.';
        } else if (!hasValue(value.address1)) {
            msg = 'Address is required. Do not include suite or unit numbers in this field.';
        }
        /* else if (!hasValue(value.zip)) {
                    msg = 'Zip is required.';
                }*/
        else if (!value.city || (!hasValue(value.city.id) && !hasValue(value.city.name))) {
            // msg = 'City / Town is required.';
            msg = 'Address is required. Do not include suite or unit numbers in this field.';
        } else if (!value.city.state || (!hasValue(value.city.state.id) && !hasValue(value.city.state.name))) {
            // msg = 'State / Region is required.';
            msg = 'Address is required. Do not include suite or unit numbers in this field.';
        } else if (!value.city.country || (!hasValue(value.city.country.id) && !hasValue(value.city.country.name))) {
            // msg = 'Country is required.';
            msg = 'Address is required. Do not include suite or unit numbers in this field.';
        }

        return msg;
    },

    locationValidationNoAddress: function(value, attr, computedState) {
        var msg;

        if (computedState && computedState.customLocation === true) {
            return;
        } else if (!hasValue(value)) {
            msg = 'Address is required. Do not include suite or unit numbers in this field.';
        } else if (!value.city || (!hasValue(value.city.id) && !hasValue(value.city.name))) {
            // msg = 'City / Town is required.';
            msg = 'Address is required. Do not include suite or unit numbers in this field.';
        } else if (!value.city.state || (!hasValue(value.city.state.id) && !hasValue(value.city.state.name))) {
            // msg = 'State / Region is required.';
            msg = 'Address is required. Do not include suite or unit numbers in this field.';
        } else if (!value.city.country || (!hasValue(value.city.country.id) && !hasValue(value.city.country.name))) {
            // msg = 'Country is required.';
            msg = 'Address is required. Do not include suite or unit numbers in this field.';
        }

        return msg;
    },

    expandProperty: function(name, value, newValue) {
        var newValue = newValue || {},
            currentScope = newValue,
            nameParts = name.split('.'),
            numParts = nameParts.length;

        _.each(nameParts, function(namePart, index) {
            if (index === numParts - 1) {
                currentScope[namePart] = value;
            } else {
                currentScope[namePart] = currentScope[namePart] || {};
                currentScope = currentScope[namePart];
            }
        });

        return newValue;
    }
}

_.mixin({
    deepPick: function(obj) {
        var ArrayProto = Array.prototype;
        var copy = {};
        var keys = ArrayProto.concat.apply(ArrayProto, ArrayProto.slice.call(arguments, 1));
        _.each(keys, function(key) {
            var val = _.deep(obj, key);
            if (!_.isUndefined(val)) _.deep(copy, key, val);
        });
        return copy;

    },

    // Get/set the value of a nested property
    deep: function(obj, key, value) {

        var keys = key.replace(/\[(["']?)([^\1]+?)\1?\]/g, '.$2').replace(/^\./, '').split('.'),
            root,
            i = 0,
            n = keys.length;

        // Set deep value
        if (arguments.length > 2) {

            root = obj;
            n--;

            while (i < n) {
                key = keys[i++];
                obj = obj[key] = _.isObject(obj[key]) ? obj[key] : {};
            }

            obj[keys[i]] = value;

            value = root;

            // Get deep value
        } else {
            while ((obj = obj[keys[i++]]) != null && i < n) {};
            value = i < n ? void 0 : obj;
        }

        return value;
    }
});

module.exports = utils;
