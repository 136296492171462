"use strict";

var React = require('react'),
    $ = require('jquery'),
    ReactRouter = require('react-router'),
    RouteHandler = ReactRouter.RouteHandler,
    BS = require('react-bootstrap'),
    CurrentUser = require('../shared/CurrentUser'),
    URI = require('urijs'),
    AjaxLoadingMask = require('../partials/AjaxLoadingMask');

var AjaxErrorHandler = React.createClass({displayName: "AjaxErrorHandler",
    mixins: [
        ReactRouter.Navigation
    ],
    contextTypes: {
        router: React.PropTypes.func
    },
    getInitialState: function() {
        return {};
    },

    gotoLogin: function() {
        var me = this,
            query = {
                sessionExpired: true,
                redirect: window.location.pathname + window.location.search
            };

        if (me.props.query && me.props.query.API) {
            query.API = me.props.query.API;
        }

        me.replaceWith('Login', undefined, query);
    },

    componentDidMount: function() {
        var me = this,
            urlsToIgnore = ['/rest/users/current', '/rest/login', '/rest/users/resetpassword', '/rest/users/validate', '/rest/users/updatepassword', '/rest/agents', '/rest/developers'];

        $(document).ajaxSend(function(e, jqXHR, options) {
            var url = options.url.toLowerCase().replace(/(.*)(agent-shield-1.0)/, '');

            if (!CurrentUser.isLoggedIn() && urlsToIgnore.indexOf(url) === -1) {
                jqXHR.abort();
                me.gotoLogin();
            }
        });

        $(document).ajaxError(function(e, response, options) {
            var message = 'Ajax Error Occurred. Please Try Again.',
                jsonResponse = response && response.responseJSON ? response.responseJSON : undefined,
                path = me.context.router.getCurrentPathname(),
                API = me.context.router.getCurrentQuery().API;

            options = options || {};

            if (options.suppressError) {
                return;
            }

            for (var i = 0; i < urlsToIgnore.length; i++) {
                if (options.url.indexOf(urlsToIgnore[i]) > -1) {
                    return;
                }
            }

            if ([404, 403, 500].indexOf(response.status) !== -1) {
                if (jsonResponse && jsonResponse.developerMessage === 'Widget count limit reached') {
                    me.setState({
                        ajaxError: jsonResponse.developerMessage
                    });
                    return;
                } else if (!jsonResponse || (jsonResponse.userMessage !== 'Session is expired' && jsonResponse.userMessage !== 'No anonymous access allowed')) {
                    if (path.indexOf('/error-') === -1 && options.type === 'GET') {
                        me.replaceWith('Error' + response.status, undefined, {
                            page: me.context.router.getLocation().getCurrentPath(),
                            API: API ? API : undefined,
                            response: JSON.stringify(jsonResponse)
                        });
                    }
                    return;
                }
            }

            if (jsonResponse) {
                if (jsonResponse.userMessage === 'Session is expired' || jsonResponse.userMessage === 'No anonymous access allowed') {
                    CurrentUser.destroyCookie();
                    me.transitionTo('Login', undefined, {
                        sessionExpired: true,
                        redirect: window.location.pathname + window.location.search,
                        API: API ? API : undefined
                    });
                    return;
                }
                message = jsonResponse.userMessage || jsonResponse.message || jsonResponse.developerMessage;
            }

            response && !response.handled && response.responseJSON && me.setState(function() {
                return {
                    ajaxError: message
                }
            });
        });
    },
    closeDialog: function() {
        this.setState(function() {
            return {
                ajaxError: undefined
            }
        });
    },

    render: function() {
        return (
            React.createElement(BS.Modal, {show: !!this.state.ajaxError, onHide: this.closeDialog}, 
                React.createElement(BS.Modal.Header, {closeButton: true}, 
                    React.createElement(BS.Modal.Title, null, "API Error")
                ), 
                React.createElement(BS.Modal.Body, null, 
                    this.state.ajaxError
                ), 
                React.createElement(BS.Modal.Footer, null, 
                    React.createElement(BS.Button, {onClick: this.closeDialog}, "Ok")
                )
            )
        );
    }
});

module.exports = AjaxErrorHandler;
