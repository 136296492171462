"use strict";

var React = require('react'),
    cx = require('classnames'),
    URI = require('urijs'),
    Constants = require('../constants');

var ImageThumbnail = React.createClass({displayName: "ImageThumbnail",

    getDefaultProps: function() {
        return {
            background: false,
            update: false,
            attemptTimeout: 1000,
            loadingMessage: '',
            maxAttempts: 5,
            showPlaceholder: false
        };
    },

    getInitialState: function() {
        return {
            lastWidth: undefined,
            lastHeight: undefined,
            lastImage: undefined,
            rand: Math.random() * 1000,
            attempts: 0,
            attemptTimer: undefined
        };
    },

    render: function() {
        var me = this,
            state = me.state,
            props = _.omit(me.props, 'innerClassName', 'src', 'rand'),
            className = cx(props.className, 'image-thumbnail', {
                'image-thumbnail-background': !!props.background,
                'image-thumbnai-loading': props.update
            }),
            innerClassName = cx(me.props.innerClassName, 'image-thumbnail-inner'),
            useBackground = me.props.background === true,
            src = me.props.src;

        if (!src && props.showPlaceholder) {
            src = Constants.placeholder;
        } else if (state.rand && src) {
            src = URI(src).search({
                rand: state.rand
            }).toString();
        }

        return (
            React.createElement("div", React.__spread({},  props, {className: className}), 
                React.createElement("img", {src: src, className: innerClassName, onLoad: me.onImageLoad, onError: me.onImageError, style: { display: useBackground ? 'none' : 'block'}}), 
                useBackground && (
                    React.createElement("div", {className: innerClassName, style: { backgroundImage: 'url(' + src + ')'}})
                ), 
                props.update && (
                    React.createElement("div", {className: "load-mask"}, 
                        React.createElement("i", {className: "fa fa-spinner fa-spin fa-2x fa-fw"}), 
                        this.props.loadingMessage
                    )
                )
            )
        );
    },

    onImageError: function(e) {
        var me = this,
            state = me.state,
            props = me.props,
            timer;

        if (state.attempts < props.maxAttempts) {
            clearTimeout(state.attemptTimer);

            timer = setTimeout(function() {
                me.setState({
                    attempts: state.attempts + 1,
                    rand: Math.random() * 1000
                });
            }, props.attemptTimeout);

            me.setState({
                attemptTimer: timer
            });
        }
    },

    onImageLoad: function(e) {
        var me = this,
            props = me.props,
            state = me.state,
            newImage = me.getImageData(e.target),
            timer;
        if (props.showPlaceholder && !props.src) {

        } else if (state.lastImage === undefined) {
            me.setState({
                lastImage: newImage.data,
                lastHeight: newImage.height,
                lastWidth: newImage.width,
                attempts: 0
            });
            if (props.update) {
                if (props.onChange) {
                    props.onChange(e);
                }
            }
        } else if ((props.update && newImage.data !== state.lastImage) || state.attempts >= props.maxAttempts) {
            if (props.onChange) {
                props.onChange(e);
            }

            me.setState({
                lastImage: newImage.data,
                lastHeight: newImage.height,
                lastWidth: newImage.width,
                attemptTimer: undefined,
                attempts: 0
            });

        } else if (props.update && state.attempts < props.maxAttempts) {
            clearTimeout(state.attemptTimer);

            timer = setTimeout(function() {
                me.setState({
                    attempts: state.attempts + 1,
                    rand: Math.random() * 1000
                });
            }, props.attemptTimeout);

            me.setState({
                attemptTimer: timer
            });
        }
    },

    getImageData: function(imageToGet) {
        var canvas = document.createElement('CANVAS'),
            ctx = canvas.getContext('2d'),
            img = new Image();

        img.crossOrigin = 'Anonymous';
        img.src = imageToGet.src;

        canvas.height = imageToGet.naturalHeight;
        canvas.width = imageToGet.naturalWidth;
        ctx.drawImage(img, 0, 0);

        return {
            data: canvas.toDataURL(),
            height: canvas.height,
            width: canvas.width
        };
    },

    componentDidUpdate: function() {

    },

    componentWillReceiveProps: function(newProps) {
        var me = this,
            props = this.props;

        if (newProps.update && props.update !== newProps.update) {
            me.setState({
                rand: Math.random() * 1000,
                attempts: 0
            });
        }
    },

    componentWillUpdate: function(nextProps, nextState) {
        var me = this,
            props = me.props,
            state = me.state;

    }
});

module.exports = ImageThumbnail;
