"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    Constants = require('../../constants'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    FluxBone = require('../../mixins/FluxBone'),
    Widget = require('../../models/Widget'),
    NavLink = require('../../components/NavLink'),
    ReactCatalyst = require('../../mixins/react-catalyst'),
    CancelWidgetEditDialog = require('../../partials/widget/CancelWidgetEditDialog'),
    fancybox = require('fancybox'),
    RouteHandler = ReactRouter.RouteHandler,
    $ = require('jquery'),
    Link = ReactRouter.Link;

var WidgetAddEditType = React.createClass({displayName: "WidgetAddEditType",

    contextTypes: {
        router: React.PropTypes.func
    },

    getInitialState: function() {
        return {
            showCancelDialog: false
        };
    },

    showCancelWarning: function() {
        this.setState({
            showCancelDialog: true
        });
    },
    hideCancelWarning: function() {
        this.setState({
            showCancelDialog: false
        });
    },

    renderWidgetTypes: function() {
        var me = this,
            state = me.state,
            props = me.props,
            widgetType = me.context.router.getCurrentQuery().widgetType,
            title = props.title + (widgetType === 'manual' ? ' - Manual' : '') + ' - Type';

        return (
            React.createElement("div", null, 
                React.createElement(DocumentTitle, {title: title}), 
                React.createElement(CancelWidgetEditDialog, {show: state.showCancelDialog, onHide: me.hideCancelWarning, model: props.model}), 
                widgetType === 'manual' ?  me.renderWidgetsChoicesManual() : me.renderWidgetsChoices(), 
                React.createElement("div", {className: "row"}, 
                    React.createElement(BS.Button, {onClick: me.showCancelWarning}, "Cancel")
                )
            )
        );
    },
    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.model,
            id = model && model.id,
            toPrefix = id ? 'WidgetEdit' : 'WidgetAdd';

        return me.renderWidgetTypes();
    },

    renderWidgetsChoices: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.model,
            id = model && model.id,
            toPrefix = id ? 'WidgetEdit' : 'WidgetAdd',
            currentType = model && model.get('widgetType') || {};

        return (
            React.createElement("div", null, 
                React.createElement("div", {className: "row"}, 
                    React.createElement(Link, {to: "Widgets"}, React.createElement(BS.Button, {bsStyle: "link"}, React.createElement("i", {className: "fa fa-arrow-left"}), " Back to My Widgets"))
                 ), 
                 React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-xs-12"}, 
                        React.createElement("h4", null, "Start by selecting a widget type:")
                    )
                 ), 

                React.createElement("div", {className: "row margin-bottom-20"}, 
                    React.createElement("div", {className: "col-md-6"}, 
                        React.createElement("div", {className: "row margin-bottom-20"}, 
                            React.createElement("div", {className: "col-md-2"}

                            ), 
                            React.createElement("div", {className: "col-md-10 widget-description"}, 
                                React.createElement("h4", null, "Automatic Widgets:"), 
                                React.createElement("p", null, 
                                    "Automatic Widgets display ", React.createElement("em", null, "ALL"), " communities in a given area. Similar to your MLS/IDX, Automatic Widgets are designed to require little or no work from you. When listings of new projects come online, they will be automatically added to your widget."
                                )
                            )
                        ), 
                        React.createElement("div", {className: "col-md-3 col-xs-3"}), 
                        React.createElement("div", {className: "col-md-7 col-xs-6"}, 
                            React.createElement("div", {className: cx('widget-block', 'thumbnails', 'thumbnail-style', 'thumbnail-kenburn', { selected : currentType && currentType.id === 7})}, 
                                React.createElement("div", {className: "caption"}, 
                                    React.createElement("h3", null, "Automatic Widgets")
                                ), 
                                React.createElement("div", {className: "bottom"}), 
                                React.createElement("p", null, 
                                    "Choose this option if you want to market ", React.createElement("em", null, "ALL"), " new projects ", React.createElement("em", null, "in your area"), "."
                                ), 
                                React.createElement(Link, {to: toPrefix + 'Projects', params: { id: id}, query: { widgetType: '7'}, className: "btn-u btn-u-lg btn-u-blue btn-u-inverted btn-u-narrow"}, "Select")
                            )
                        )
                    ), 
                    React.createElement("div", {className: "col-md-6"}, 
                        React.createElement("div", {className: "row margin-bottom-20"}, 
                            React.createElement("div", {className: "col-md-2"}

                            ), 
                            React.createElement("div", {className: "col-md-10 widget-description"}, 
                                React.createElement("h4", null, "Manual Widgets:"), 
                                React.createElement("p", null, 
                                    "Manual Widgets ", React.createElement("em", null, "ONLY"), " display projects that have ", React.createElement("em", null, "Agent Tracker"), ". Agent Tracker is a tracking technology that enables agents to use a project's website as a marketing tool while maintaining full control of their buyers."
                                )
                            )
                        ), 
                        React.createElement("div", {className: "col-md-3 col-xs-3"}), 
                        React.createElement("div", {className: "col-md-7 col-xs-6"}, 
                            React.createElement("div", {className: cx('widget-block', 'thumbnails', 'thumbnail-style', 'thumbnail-kenburn', { selected : currentType && currentType.id === 6 || currentType.id === 1})}, 
                                React.createElement("div", {className: "caption"}, 
                                    React.createElement("h3", null, "Manual Widgets")
                                ), 
                                React.createElement("div", {className: "bottom"}), 
                                React.createElement("p", null, "Choose this option if you want to market ", React.createElement("em", null, "SPECIFIC"), " new projects."), 
                                React.createElement(Link, {to: toPrefix + 'Type', params: { id: id}, query: { widgetType: 'manual'}, className: "btn-u btn-u-lg btn-u-blue btn-u-inverted btn-u-narrow"}, "Select")
                            )
                        )
                    )
                )
            )
        );
    },
    renderWidgetsChoicesManual: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.model,
            id = model && model.id,
            toPrefix = id ? 'WidgetEdit' : 'WidgetAdd',
            currentType = model && model.get('widgetType') || {};

        return (
            React.createElement("div", null, 
                React.createElement("div", {className: "row"}, 
                    React.createElement(Link, {to: toPrefix + 'Type', params: { id: id}}, React.createElement(BS.Button, {bsStyle: "link"}, React.createElement("i", {className: "fa fa-arrow-left"}), " Back"))
                 ), 
                 React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-xs-12"}, 
                        React.createElement("h4", null, "Start by selecting a widget type:")
                    )
                 ), 
                React.createElement("div", {className: "row margin-bottom-10"}, 
                    React.createElement("div", {className: "col-md-2"}), 
                    React.createElement("div", {className: "col-md-10"}, 
                        React.createElement("p", null, 
                            "Manual Widgets enable you to pick and choose specific new communities/projects that you want to promote.", React.createElement("em", null, " Choose this option"), " if you want to promote ", React.createElement("em", null, "ONLY SPECIFIC new communities/projects.")
                        )
                    )
                ), 
                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-md-3"}), 
                    React.createElement("div", {className: "col-md-3 col-xs-6"}, 
                        React.createElement("div", {className: cx('widget-block', 'thumbnails', 'thumbnail-style', 'thumbnail-kenburn', { selected : currentType && currentType.id === 1})}, 
                            React.createElement("div", {className: "caption"}, 
                                React.createElement("h3", null, "List Widget")
                            ), 
                            React.createElement("div", {className: "bottom"}), 
                            React.createElement("ul", {className: "row portfolio-box"}, 
                                React.createElement("li", null, 
                                    React.createElement("a", {className: "thumbnail fancybox", "data-rel": "gallery", href: Constants.imageRoot + 'SelectWidgetType_ListPreview.jpg'}, 
                                        React.createElement("img", {src: Constants.imageRoot + 'SelectWidgetType_ListThumbnail.jpg', className: "img-responsive"}), 
                                        React.createElement("span", {className: "portfolio-box-in"}, 
                                            React.createElement("i", {className: "rounded-x icon-magnifier-add"})
                                        )
                                    )
                                )
                            ), 
                            React.createElement("div", {className: "bottom"}), 
                            React.createElement("div", {className: "caption"}, 
                                React.createElement("p", null, "Display your selected communities in a list view with a brief description.")
                            ), 
                            React.createElement(Link, {to: toPrefix + 'Projects', params: { id: id}, query: { widgetType: 1}, className: "btn-u btn-u-lg btn-u-blue btn-u-inverted btn-u-narrow"}, "Select")
                        )
                    ), 
                    React.createElement("div", {className: "col-md-3 col-xs-6"}, 
                        React.createElement("div", {className: cx('widget-block', 'thumbnails', 'thumbnail-style', 'thumbnail-kenburn', { selected : currentType && currentType.id === 6})}, 
                            React.createElement("div", {className: "caption"}, 
                                React.createElement("h3", null, "Gallery Widget")
                            ), 
                            React.createElement("div", {className: "bottom"}), 
                            React.createElement("ul", {className: "row portfolio-box"}, 
                                React.createElement("li", null, 
                                    React.createElement("a", {className: "thumbnail fancybox", "data-rel": "gallery", href: Constants.imageRoot + 'SelectWidgetType_GalleryPreview.jpg'}, 
                                        React.createElement("img", {src: Constants.imageRoot + 'SelectWidgetType_GalleryThumbnail.jpg', className: "img-responsive"}), 
                                        React.createElement("span", {className: "portfolio-box-in"}, 
                                            React.createElement("i", {className: "rounded-x icon-magnifier-add"})
                                        )
                                    )
                                )
                            ), 
                            React.createElement("div", {className: "bottom"}), 
                            React.createElement("div", {className: "caption"}, 
                                React.createElement("p", null, "Displays your selected communities in a visual gallery with links..")
                            ), 
                            React.createElement(Link, {to: toPrefix + 'Projects', params: { id: id}, query: { widgetType: 6}, className: "btn-u btn-u-lg btn-u-blue btn-u-inverted btn-u-narrow"}, "Select")
                        )
                    )
                )
            )
        );
    },

    componentDidMount: function() {
        $(React.findDOMNode(this)).find('a.fancybox').fancybox();
    }
});
module.exports = WidgetAddEditType;
