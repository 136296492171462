'use strict';

var React = require('react'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    DocumentTitle = require('react-document-title'),
    PageHeader = require('../../../partials/PageHeader'),
    PageFooter = require('../../../partials/PageFooter'),
    CurrentUser = require('../../../shared/CurrentUser'),
    Footer = require('../../toolbox/Footer'),
    Cmp = require('../../../components'),
    RouteHandler = ReactRouter.RouteHandler;

var SignupContainer = React.createClass({displayName: "SignupContainer",
    contextTypes: {
        router: React.PropTypes.func
    },
    render: function() {
        return (
            React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: "NCH - Signup"}), 
                !this.props.hideHeaderAndFooter && React.createElement(PageHeader, {hideAgentMessage: true}), 
                !this.props.hideHeaderAndFooter &&
                    React.createElement("div", {className: "breadcrumbs"}, 
                        React.createElement("div", {className: "container"}, 
                            React.createElement("h1", {className: "pull-left"}, this.getNavTitle()), 
                            React.createElement("ul", {className: "pull-right breadcrumb"}, 
                                React.createElement(Cmp.NavLink, null, "Home"), 
                                React.createElement(Cmp.NavLink, {to: "Signup"}, this.getNavTitle())
                            )
                        )
                    ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement(RouteHandler, {Footer: this.props.hideHeaderAndFooter ? Footer : null})
                ), 
                !this.props.hideHeaderAndFooter && React.createElement(PageFooter, null)
            )
        );
    },

    getNavTitle: function() {
        var title = 'Sign Up',
            routes = this.context.router.getCurrentRoutes(),
            route = routes[routes.length - 1];

        if (route && route.name === 'SignupUserMembership') {
            title = 'Select Plan';
        }

        return title;
    }
});
module.exports = SignupContainer;
