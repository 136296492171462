"use strict";

var ModelClass = require('./Model'),
    LocationInfo = require('./LocationInfo'),
    _ = require('underscore');

var ContactInfo = LocationInfo.extend({
    defaults: function() {
        var defaults = LocationInfo.prototype.defaults.apply(this, arguments);

        _.extend(defaults, {
            email: '',
            phoneMobile: '',
            phoneHome: '',
            phoneWork: '',
            fax: '',
            firstName: '',
            lastName: ''
        });

        return defaults;
    },

    toJSON: function(options) {
        var data = LocationInfo.prototype.toJSON.apply(this, arguments);

        options = options || {};

        if (options.url) {
            delete data.id;

            if (!data.email) {
                delete data.email;
            }
        } else {
            data.fullName = data.firstName + ' ' + data.lastName;
        }

        return data;
    }
});

module.exports = ContactInfo;
