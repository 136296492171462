"use strict";

var React = require('react'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    DocumentTitle = require('react-document-title'),
    CurrentUser = require('../../../shared/CurrentUser'),
    Constants = require('../../../constants'),
    Cmp = require('../../../components'),
    Widget = require('../../../models/Widget'),
    FluxBone = require('../../../mixins/FluxBone'),
    Link = ReactRouter.Link;

var DeveloperFinish = React.createClass({displayName: "DeveloperFinish",
    mixins: [
        ReactRouter.Navigation,
        FluxBone.ModelMixin('model', 'sync reset')
    ],
    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            model: new Widget()
        };
    },

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            query = this.context.router.getCurrentQuery(),
            routes = this.context.router.getCurrentRoutes(),
            id = params.id ? parseInt(params.id) : undefined,
            model = this.props.model;

        if (id && model.id !== id) {
            model.clear();
            model.set('id', id);
            model.fetch();
        }

        return {
            id: id
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    componentWillMount: function() {
        this.props.model.clear();
    },

    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state;

        return (
            React.createElement("div", null, 
                React.createElement(DocumentTitle, {title: "NCH - Signup - Developer Finish"}), 
                React.createElement("div", {className: "row text-center"}, 
                    React.createElement("div", {className: "col-md-8 col-md-offset-2 text-center"}, 
                        React.createElement("h5", {className: "text-center blue-text"}, 
                            React.createElement("i", {className: "fa fa-check-square-o fa-4x center-block"}), 
                            React.createElement("div", {className: "font-large"}, "Success! ")
                        ), 
                        React.createElement("p", null, 
                            "Your New Construction Hub agent account was successfully created."
                        )
                    )
                ), 
                React.createElement("hr", null), 
                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-md-8 col-md-offset-2"}, 
                        React.createElement("h3", {className: "blue-header"}, 
                            "Next Step: Finish creating your builder listing.", React.createElement("br", null)
                        ), 
                        React.createElement("p", null, "Add the information needed to complete your builder listing. All the information is optional" + ' ' + 
                        "but the more information you can provide the better results you'll receive."), 
    
                        React.createElement("div", {className: "text-center"}, 
                            React.createElement(Link, {to: "UserAccountCompany"}, 
                                React.createElement(BS.Button, {bsStyle: "primary"}, 
                                    "Edit your company listing!"
                                )
                            ), 
                            React.createElement("div", null, " or "), 
                            React.createElement(Link, {to: "Dashboard"}, 
                                React.createElement(BS.Button, {bsStyle: "primary"}, 
                                    "Finish"
                                )
                            )
                        ), 

                        React.createElement("div", {className: "text-center margin-top-20"}, 
                            React.createElement(Link, {to: "Dashboard"}, React.createElement(BS.Button, {bsStyle: "link"}, "Skip adding information for my builder listing"))
                        ), 

                        this.props.Footer && (
                            React.createElement(this.props.Footer, {trackerId: Constants.trackerPrefix + 'signup-developer-finish'})
                        )
                    )
                )
            )
        );
    }
});
module.exports = DeveloperFinish;
