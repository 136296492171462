"use strict";

var React = require('react/addons'),
    _ = require('underscore'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    FluxBone = require('../../mixins/FluxBone'),
    BS = require('react-bootstrap'),
    ReactRouter = require('react-router'),
    DeleteDialog = require('../../partials/DeleteDialog'),
    ListingModelType = require('../../mixins/ListingModelType'),
    $ = require('jquery'),
    Link = ReactRouter.Link,
    ProjectModel = require('../../models/Project'),
    ImageThumbnail = require('../../components/ImageThumbnail');

require('fancybox');

var ProjectImages = React.createClass({displayName: "ProjectImages",

    mixins: [
        FluxBone.CollectionMixin('model.imagesCollection', 'sync remove add change', 10),
        ListingModelType.ListingNavigation('Image')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },
    getInitialState: function() {
        return {
            modelToDelete: false
        };
    },
    getDefaultProps: function() {
        return {
            readOnly: false,
            emptyMessage: 'There are no images available.',
            hidePrivate: false
        };
    },
    onDelete: function(image, e) {
        e.preventDefault();
        this.setState({
            modelToDelete: image
        });
    },
    componentDidMount: function() {
        $('.fancybox').fancybox();
    },
    onHideDeleteDialog: function() {
        this.setState({
            modelToDelete: undefined
        });
    },
    render: function() {
        //based on page_home7.html
        var me = this,
            props = me.props,
            state = me.state,
            deleteDialogProps = {
                ref: 'deleteDialog',
                modelToDelete: state.modelToDelete,
                onHide: me.onHideDeleteDialog,
                modelTitle: state.modelToDelete ? state.modelToDelete.get('title') : '',
                title: 'ALERT! You are about to DELETE an image.',
                msg: 'Please confirm that you would like to DELETE this image.',
                successMessage: 'Success! Your image has been successfully deleted.',
                deleteLabel: 'Delete Image'
            },
            images = props.model.imagesCollection ? props.model.imagesCollection.filter(this.filterImages) : undefined;

        return (
                React.createElement(DeleteDialog, React.__spread({},  deleteDialogProps), 
                    !this.props.hideHeader && (
                    React.createElement("div", {className: "row margin-bottom-20"}, 
                        React.createElement("div", {className: props.readOnly ? 'col-md-12' : 'col-md-10'}, 
                            React.createElement("h1", null,  me.isDeveloper() ? 'Builder Images' : 'Listing Images'), 
                            props.description ? props.description : (
                                React.createElement("h5", null, "Upload and manage images for this ", me.isDeveloper() ? 'builder' : 'community', ". The images are displayed in the listings and are available for agents to download from the Agent Portal.")
                            )
                        ), 
                        !props.readOnly && (
                            React.createElement("div", {className: "col-md-2"}, 
                                React.createElement(Link, {to: me.addTo(), key: "ProjectAddImageButton", className: "btn btn-u pull-right", params: {id: props.model.id}}, "Add Image")
                            )
                        )
                    )
                    ), 
                    images && images.length > 0 && (
                        React.createElement("div", {className: "sorting-block"}, 
                            React.createElement("ul", {className: "row sorting-grid portfolio-box project-images"}, 
                                _.map(images, function(image){
                                    var data = image.toJSON(),
                                        linkParams = { 
                                            id : props.model.id,
                                            imageid: image.id 
                                         },
                                        title = data.title || 'No Title',
                                        description = data.description || 'No Description',
                                        readOnly = props.readOnly || image.isReadOnly();
                                    return (
                                            React.createElement("li", {key: data.id, className: "col-xs-6 col-sm-4 col-md-3 project-image"}, 
                                                React.createElement("a", {rel: "project-images-gallery", className: "thumbnail fancybox mix  mix_all", "data-rel": "gallery", href: data.link, title: data.title}, 
                                                    /* <img src={data.thumbLink || data.link} className="img-responsive"/> */
                                                    React.createElement(ImageThumbnail, {update: !data.loaded, onChange: me.onImageChange.bind(null, image), className: "", innerClassName: "img-responsive", src: data.thumbLink || data.link}), 
                                                    React.createElement("span", {className: "portfolio-box-in"}, 
                                                        React.createElement("i", {className: "icon-magnifier-add"})
                                                    ), 
                                                    data.private && (
                                                        React.createElement("i", {className: "fa fa-fw fa-lock"})
                                                    )
                                                ), 

                                                data.title && !readOnly && (
                                                    React.createElement(Link, {key: "ProjectEditImageButtonTitle", className: "sorting-cover", to: me.editTo(), params: linkParams, title: data.title}, 
                                                        React.createElement("div", {className: "project-image-title"}, title)
                                                    )
                                                ), 
                                                data.title && readOnly && (
                                                    React.createElement("div", {key: "ProjectEditImageButtonTitle", className: "sorting-cover"}, 
                                                        React.createElement("div", {className: "project-image-title"}, title)
                                                    )
                                                ), 
                                                !data.title && (
                                                    React.createElement("div", {className: "project-image-title"}, title)
                                                ), 
                                                React.createElement("div", {className: "project-image-description", title: description}, description), 
                                                props.showDownload && (
                                                    React.createElement("a", {href: data.link, target: "_blank"}, "Download")
                                                ), 
                                                !readOnly && (
                                                    React.createElement(Link, {key: "ProjectEditImageButton", className: "sorting-cover", to: me.editTo(), params: linkParams}, 
                                                        React.createElement("i", {className: "icon-custom  icon-color-u icon-sm fa fa-pencil", style: {"marginRight" : "3px"}})
                                                    )
                                                ), 
                                                !readOnly && (
                                                    React.createElement("a", {href: "#", onClick: me.onDelete.bind(me, image)}, 
                                                        React.createElement("i", {className: "icon-custom  icon-color-u icon-sm fa fa-trash-o"})
                                                    )
                                                )
                                            )
                                        );

                                })
                            ), 

                            React.createElement("div", {className: "clearfix"})
                        )
                    ), 
                    images && images.length === 0 && (
                        React.createElement(BS.Alert, null, 
                            React.createElement("p", {className: "text-center"}, 
                                props.emptyMessage
                            )
                        )
                    )
                )
        );
    },

    onImageChange: function(image) {
        image.set('loaded', true);
    },

    filterImages: function(model){
        return !this.props.hidePrivate || !model.get('private');
    }
});
module.exports = ProjectImages;
