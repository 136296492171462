"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    DocumentTitle = require('react-document-title'),
    BS = require('react-bootstrap'),
    Cmp = require('../../../components'),
    PageHeader = require('../../../partials/PageHeader'),
    PromotionModel = require('../../../models/Promotion'),
    PageFooter = require('../../../partials/PageFooter'),
    Validation = require('../../../partials/validation');

var ProjectWizardPromotion = React.createClass({displayName: "ProjectWizardPromotion",

    TYPES: [{
        id: 1,
        name: 'Buyers Promotions'
    }, {
        id: 2,
        name: 'Real Estate Professional Promotions'
    }, {
        id: 3,
        name: 'Fly and Buy Promotions'
    }],

    IS_ACTIVE: [{
        id: true,
        name: 'Active'
    }, {
        id: false,
        name: 'Not Active'
    }],


    mixins: [
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            entity: new PromotionModel()
        };
    },

    updateState: function() {
        this.props.entity.set({
            listing: {
                id: this.props.model.id
            }
        });
        return {
            success: undefined
        }
    },

    getInitialState: function() {
        return this.updateState();
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.reset();
    },

    render: function() {
        var me = this,
            props = me.props,
            model = props.model,
            state = me.state,
            fieldDefaults = {
                labelClassName: 'label',
                type: 'text'
            };


        return (
            React.createElement("div", {className: "wrapper widgets"}, 
                React.createElement(DocumentTitle, {title: "NCH - Create Listing - Add Promotion"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Create Listing - Step 2D: Add Promotion"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(Cmp.NavLink, {to: "ProjectList"}, "Projects"), 
                            React.createElement(Cmp.NavLink, {to: "ProjectWizardSteps", params: { id: model.id}}, "Additional Info"), 
                            React.createElement(Cmp.NavLink, {to: "ProjectWizardPromotion", params: { id: model.id}}, "Add Promotion")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    state.success && (
                        React.createElement(BS.Alert, {bsStyle: "success", className: "text-center"}, 
                            React.createElement("h4", null, "Promotion Added Successfully"), 
                            React.createElement("div", {className: "row"}, 
                                React.createElement(BS.ButtonToolbar, {className: "center-block-inline"}, 
                                    React.createElement(Cmp.Button.Save, {onClick: this.reset}, "Add Another Promotion"), 
                                    React.createElement("span", {className: "pull-left btn-divider"}, "or"), 
                                    React.createElement(Cmp.Button.Cancel, {onClick: this.gotoSteps}, "Continue ", React.createElement("i", {className: "fa fa-arrow-right"}))
                                )
                            )
                        )
                    ), 
                    !state.success && (
                        React.createElement(Validation.Form, {ref: "form", horizontal: false, model: props.entity, onSubmit: this.onSaveClick}, 
                            React.createElement("h1", null, "Add Promotion"), 

                            React.createElement(Cmp.RadioGroup, {label: "Promotion Type:", type: "radio", name: "type.id", items: this.TYPES}), 
                            React.createElement(Cmp.RadioGroup, {type: "radio", name: "active", label: "Is it Active:", items: this.IS_ACTIVE}), 
                            React.createElement(Validation.Input, React.__spread({},  fieldDefaults, {label: "Promotion Name / Title:", placeholder: "Enter a Name for this Promotion:", name: "name"})), 
                            React.createElement(Validation.Input, React.__spread({},  fieldDefaults, {type: "textarea", rows: "3", label: "Promotion Description:", placeholder: "Provide a description for this Promotion", name: "description"})), 
                            React.createElement(Validation.Input, React.__spread({},  fieldDefaults, {type: "", label: "Maximum Promotion Value:"}), 
                                React.createElement(Validation.Input, React.__spread({},  fieldDefaults, {groupClassName: "col-xs-9", wrapperClassName: "row", placeholder: "Enter a Maximum Promotion Value:", name: "value"})), 
                                React.createElement(Validation.Input, React.__spread({},  fieldDefaults, {type: "select", groupClassName: "col-xs-2 col-xs-offset-1", wrapperClassName: "row", name: "currency"}), 
                                    React.createElement("option", {value: "USD"}, "USD"), 
                                    React.createElement("option", {value: "EUR"}, "EUR"), 
                                    React.createElement("option", {value: "JPY"}, "JPY")
                                )
                            ), 
                                    
                            React.createElement("div", {className: "form-group"}, 
                                React.createElement("label", {className: "label"}, "Duration:"), 
                                React.createElement("section", null, 
                                    React.createElement("div", {className: "row"}, 
                                        React.createElement("div", {className: "col col-1"}, React.createElement("label", {className: "label"}, "Start:")), 
                                        React.createElement("div", {className: "col col-5"}, 
                                            React.createElement(Cmp.Picker.Date, {placeholder: "Starting date", name: "startDate"})
                                        ), 
                                        React.createElement("div", {className: "col col-1"}, React.createElement("label", {className: "label"}, "End:")), 
                                        React.createElement("div", {className: "col col-5"}, 
                                            React.createElement(Cmp.Picker.Date, {placeholder: "Expiration date", name: "endDate"})
                                        )
                                    )
                                )
                            ), 

                            React.createElement(Cmp.Picker.File, {ref: "filePicker", name: "files", url: props.entity.url(), label: "Promotion File", onComplete: this.onFileUpload}), 

                            React.createElement(Cmp.ValidationMessage, null), 
                            React.createElement(BS.ButtonToolbar, null, 
                                React.createElement(Cmp.Button.Cancel, {onClick: this.gotoSteps}, React.createElement("i", {className: "fa fa-arrow-left"}), " Back to Steps"), 
                                React.createElement(Cmp.Button.Save, {className: "pull-right"}, "Save & Continue ", React.createElement("i", {className: "fa fa-arrow-right"}))
                            )
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    },

    onSaveClick: function() {
        var me = this,
            filePicker = me.refs.filePicker,
            model = this.props.entity,
            isNew = model.isNew();

        model.save(me.refs.form.getValues()).then(function(m, e, r) {
            if(isNew){
                filePicker.setIdUrlPart(model.id);
            }
            if (filePicker.hasFiles()) {
                filePicker.startUpload();
            } else {
                me.setState({success : true }, function() {
                    me.refs.form && me.refs.form.forceUpdate();
                });
            }
        });

    },

    onFileUpload: function(success) {
        var me = this;
        if (success) {
            me.props.entity.clear();
            me.setState({success : true }, function() {
                me.refs.form && me.refs.form.forceUpdate();
            });
        };
    },

    gotoSteps: function() {
        this.transitionTo('ProjectWizardSteps', {
            id: this.props.model.id
        });
    },

    reset: function() {
        var me = this;
        me.props.entity.clear();
        me.setState(me.updateState(), function() {
            me.refs.form && me.refs.form.forceUpdate();
        });
    }
});
module.exports = ProjectWizardPromotion;
