"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    _ = require('underscore'),
    cx = require('classnames'),
    Validation = require('../partials/validation');

var RadioGroup = React.createClass({displayName: "RadioGroup",
    onChangeTimeout: undefined,
    mixins: [
        Validation.Mixin
    ],

    getDefaultProps: function() {
        return {
            items: [],
            type: "checkbox",
            required: false,
            showRequired: true,
            delimiter: undefined,
            valuesAsObject: false,
            selectAll: false,
            selectAllLabel: 'Select All',
            valueAsInteger: false,
            maintainOutsideValues: false,
            trigger: 'onChange'
        };
    },

    getInitialState: function() {
        return {};
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            values = _.pluck(props.items, 'id'),
            value = me.getValue(),
            help = props.error ? (props.invalidMsg ? props.invalidMsg : props.error) : props.help,
            groupClassName = cx('form-group', props.groupClassName, {
                'has-feedback': !!props.error || props.hasFeedback,
                'has-error': !!props.error,
                'form-group-required': props.required && props.showRequired,
                'disabled': props.disabled
            }),
            wrapperClassName = cx('inline-group', props.wrapperClassName),
            labelClassName = cx('control-label', props.labelClassName),
            className = cx(props.type, props.className),
            hasEmptyValue = me.hasEmptyValue();

        return (
            React.createElement("div", {className: groupClassName}, 
                props.hideLabel !== true && (
                    React.createElement("label", {className: labelClassName}, 
                        React.createElement("span", null, props.label)
                    )
                ), 
                React.createElement("div", {className: wrapperClassName}, 
                    props.selectAll && props.items && props.items.length > 0 && (
                        React.createElement("div", null, 
                            React.createElement("label", {className: "radio", key: "selectAll"}, 
                                React.createElement("input", {disabled: props.disabled, type: "radio", checked: hasEmptyValue, onChange: me.onChange, value: ""}), 
                                React.createElement("i", {className: "rounded-x"}), 
                                props.selectAllLabel
                            ), 
                            React.createElement("div", {className: "clear"})
                        )
                    ), 
                    _.map(props.items, function(item){
                        var isInValue = me.isInValue(value, item.id);

                        return (
                            React.createElement("label", {className: className, key: item.id}, 
                                React.createElement("input", {type: props.type, disabled: props.disabled, checked: isInValue, onChange: me.onChange, value: item.id}), 
                                React.createElement("i", {className: props.type === 'radio' ? 'rounded-x' : ''}), 
                                item.name
                            )


                        );
                    }), 
                    React.createElement("input", {type: "hidden", name: props.name, id: props.id, value: this.getValue(true)})
                ), 
                help && (
                    React.createElement("span", {className: "help-block", style: {"paddingTop" : "10px"}}, help)
                )
            )
        );
    },

    onChange: function(e) {
        var me = this,
            childValue = e.currentTarget.value,
            checked = e.currentTarget.checked,
            value = childValue,
            currentValue = this.getValue(),
            indexOfValue;

        if (this.props.readOnly) {
            e.preventDefault();
            return;
        }

        if (this.props.valueAsInteger) {
            value = parseInt(value, 10);
        } else if (value === 'true') {
            value = true;
        } else if (value === 'false') {
            value = false;
        }

        if (this.props.selectAll && value === '') {
            // Select all logic
            if (this.props.delimiter) {
                currentValue = [];
            } else {
                currentValue = value;
            }
        } else {
            if (this.props.delimiter) {
                indexOfValue = this.indexInValue(currentValue, value);
                if (indexOfValue === -1 && checked) {
                    currentValue.push({
                        id: value
                    });
                } else if (indexOfValue !== -1 && !checked) {
                    currentValue.splice(indexOfValue, 1);
                }
            } else {
                currentValue = value;
            }
        }

        if (me.props.valueLink) {
            me.props.valueLink.requestChange(currentValue);
        }

        if (me.onChangeTimeout) {
            clearTimeout(me.onChangeTimeout)
        }

        me.onChangeTimeout = setTimeout(function() {
            me.onChangeTimeout = undefined;
            me.onValidateEvent(e)
        }, 10);
    },

    getValue: function(forDom) {
        var me = this,
            props = this.props,
            value = props.value;

        if (props.valueLink) {
            value = props.valueLink.value;
        }

        if (forDom && props.delimiter && props.valuesAsObject) {
            value = _.map(value, function(v) {
                return v.id;
            }).join(props.delimiter) || [];
        } else if (forDom && props.delimiter && !props.valuesAsObject) {
            value = value ? value.join(',') : '';
        } else if (props.delimiter && !props.valuesAsObject) {
            value = value ? String(value).split(props.delimiter) : [];
        } else if (props.delimiter) {
            value = value || [];
        }

        return value;
    },

    isInValue: function(value, valueToCheck) {
        var isInValue = false;

        if (this.props.delimiter) {
            isInValue = this.indexInValue(value, valueToCheck) !== -1;
        } else {
            isInValue = value == valueToCheck;
        }

        return isInValue;
    },

    indexInValue: function(value, valueToCheck) {
        var me = this,
            index = _.findIndex(value, function(v) {
                if (me.props.valuesAsObject) {
                    return v.id == valueToCheck;
                } else {
                    return v == valueToCheck
                }
            });

        return index;
    },

    hasEmptyValue: function() {
        var value = this.getValue();

        return !value || value === '' || value.length === 0;
    },

    componentDidUpdate: function(oldProps, oldState) {
        var me = this,
            props = me.props,
            value = me.getValue(),
            newValue = [];

        if (value && oldProps.items !== props.items && oldProps.items.length !== props.items.length) {

            if (props.delimiter && !props.valuesAsObject) {
                value = value.split(props.delimiter);
            }

            if (props.maintainOutsideValues) {
                newValue = [].concat(value);
            }

            _.each(value, function(val) {
                var indexOfValue = _.findIndex(props.items, function(item) {
                    if (props.valuesAsObject) {
                        return item.id == val.id;
                    } else {
                        return item.id == val;
                    }
                });

                if (indexOfValue !== -1 && !props.maintainOutsideValues) {
                    newValue.push(val);
                }
            });

            if (value.length !== newValue.length && me.props.valueLink) {
                if (props.delimiter && !props.valuesAsObject) {
                    newValue = newValue.join(value.delimiter);
                }
                me.props.valueLink.requestChange(newValue);
            }
        }
    }
});

module.exports = RadioGroup;
