"use strict";

var Attribute = require('./Attribute'),
    State = require('./State'),
    Country = require('./Country');

var City = Attribute.extend({
    hasOne: {
        Country: {
            responseKey: 'country',
            alwaysInclude: true
        },
        State: {
            responseKey: 'state',
            alwaysInclude: true
        }
    },
    toJSON: function(options) {
        var data = Attribute.prototype.toJSON.apply(this, arguments);

        options = options || {};

        if (options.url && data.id !== null) {
            delete data.name;
        } else if(options.url) {
            delete data.id;
        }

        return data;
    }
}, {
    State: State,
    Country: Country
});

module.exports = City;
