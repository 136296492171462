"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    DocumentTitle = require('react-document-title'),
    BS = require('react-bootstrap'),
    Cmp = require('../../../components'),
    Constants = require('../../../constants'),
    PageHeader = require('../../../partials/PageHeader'),
    ImageModel = require('../../../models/ProjectImage'),
    PageFooter = require('../../../partials/PageFooter'),
    Validation = require('../../../partials/validation');

var ProjectWizardImage = React.createClass({displayName: "ProjectWizardImage",

    mixins: [
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            entity: new ImageModel()
        };
    },

    updateState: function() {
        this.props.entity.set({
            listing: {
                id: this.props.model.id
            }
        });
        return {
            success: undefined,
            isFirst: this.context.router.getCurrentQuery().isFirst === 'True'
        }
    },

    getInitialState: function() {
        return this.updateState();
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.reset();
    },

    render: function() {
        var me = this,
            props = me.props,
            model = props.model,
            state = me.state;

        return (
            React.createElement("div", {className: "wrapper widgets"}, 
                React.createElement(DocumentTitle, {title: "NCH - Create Listing - Upload Image"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Create Listing - Step 2: Upload Image"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(Cmp.NavLink, {to: "ProjectList"}, "Projects"), 
                            React.createElement(Cmp.NavLink, {to: "ProjectWizardSteps", params: { id: model.id}}, "Additional Info"), 
                            React.createElement(Cmp.NavLink, {to: "ProjectWizardImage", params: { id: model.id}}, "Upload Image")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    state.success && (
                        React.createElement(BS.Alert, {bsStyle: "success", className: "text-center"}, 
                            React.createElement("h4", null, "Image Added Successfully"), 
                            React.createElement("div", {className: "row"}, 
                                React.createElement(BS.ButtonToolbar, {className: "center-block-inline"}, 
                                    React.createElement(Cmp.Button.Save, {onClick: this.reset}, "Add Another Image"), 
                                    React.createElement("span", {className: "pull-left btn-divider"}, "or"), 
                                    React.createElement(Cmp.Button.Cancel, {onClick: this.gotoSteps}, "Continue ", React.createElement("i", {className: "fa fa-arrow-right"}))
                                )
                            )
                        )
                    ), 
                    !state.success && (
                        React.createElement(Validation.Form, {ref: "form", model: props.entity, onSubmit: this.onSaveClick}, 
                            React.createElement("h1", null, "Add Image"), 
                            state.isFirst && (
                                React.createElement("div", null, 
                                    React.createElement("p", null, 
                                        "Showcase your community by uploading  images of the community and homes that you build."
                                    )
                                )
                            ), 

                            React.createElement(Validation.Input, {type: "text", labelClassName: "label", label: "Image Name / Title", placeholder: "Enter a Name for this Image", name: "title"}), 
                            React.createElement(Validation.Input, {type: "checkbox", labelClassName: "label", label: "Do not provide access to agents to download this document for you use in their marketing", name: "private", value: true, useCheckedLink: true}), 
                            React.createElement(Validation.Input, {type: "textarea", labelClassName: "label", label: "Image Description", rows: "3", placeholder: "Provide a description for this Image", name: "description"}), 
                            React.createElement(Cmp.Picker.File, {ref: "filePicker", name: "files", trigger: "onChange", url: props.entity.url(), label: "Image File Upload Image File (Only image file allowed: JPG, PNG, GIF)", model: props.entity, onComplete: this.onFileUpload, mime_types: [Constants.imageMimeTypes]}), 

                            React.createElement(Cmp.ValidationMessage, null), 
                            React.createElement(BS.ButtonToolbar, null, 
                                React.createElement(Cmp.Button.Cancel, {onClick: this.gotoSteps}, React.createElement("i", {className: "fa fa-arrow-left"}), " Back"), 
                                React.createElement(Cmp.Button.Save, {className: "pull-right"}, "Save & Continue ", React.createElement("i", {className: "fa fa-arrow-right"}))
                            )
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    },

    onSaveClick: function() {
        var me = this,
            filePicker = me.refs.filePicker,
            model = this.props.entity,
            isNew = model.isNew();

        model.save(me.refs.form.getValues()).then(function(m, e, r) {
            if(isNew){
                filePicker.setIdUrlPart(model.id);
            }
            if (filePicker.hasFiles()) {
                filePicker.startUpload();
            } 
        });

    },

    onFileUpload: function(success) {
        var me = this;
        if (success) {
            me.props.entity.clear();
            me.setState({success : true }, function() {
                me.refs.form && me.refs.form.forceUpdate();
            });
        };
    },

    gotoSteps: function() {
        this.transitionTo('ProjectWizardSteps', {
            id: this.props.model.id
        });
    },

    reset: function() {
        var me = this;
        me.setState(me.updateState(), function() {
            me.refs.form && me.refs.form.forceUpdate();
        });
    }
});
module.exports = ProjectWizardImage;
