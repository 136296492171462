"use strict";

var React = require('react/addons'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    ContactModel = require('../../../models/ProjectContact'),
    Cmp = require('../../../components'),
    ReactRouter = require('react-router'),
    PageHeader = require('../../../partials/PageHeader'),
    ListingModelType = require('../../../mixins/ListingModelType'),
    RequiredMessage = require('../../../partials/RequiredMessage'),
    PageFooter = require('../../../partials/PageFooter'),
    Validation = require('../../../partials/validation');

var ProjectVisibility = React.createClass({displayName: "ProjectVisibility",

    mixins: [
        ReactRouter.Navigation,
        ListingModelType.ListingNavigation('Contact')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            id = params.contactId,
            projectId = this.props.model.id;

        this.props.contactModel.clear();

        this.props.contactModel.set({
            id: id,
            listing: {
                id: projectId
            }
        });

        if (id) {
            this.props.contactModel.fetch();
        }

        return {};
    },

    getDefaultProps: function() {
        return {
            contactModel: new ContactModel(),
            readOnly: false
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.reset();
        this.setState(this.updateState());
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.model,
            defaultControlAttr = {
                type: 'text',
                labelClassName: 'label',
                trigger: 'onChange'
            };

        return (
            React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: "NCH - Create Listing - Add Contact"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Create Listing - Step 2E: Add Contact"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(Cmp.NavLink, {to: "ProjectList"}, "Projects"), 
                            React.createElement(Cmp.NavLink, {to: "ProjectWizardSteps", params: { id: model.id}}, "Additional Info"), 
                            React.createElement(Cmp.NavLink, {to: "ProjectWizardContact", params: { id: model.id}}, "Add Contact")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    state.success && (
                        React.createElement(BS.Alert, {bsStyle: "success", className: "text-center"}, 
                            React.createElement("h4", null, "Contact Added Successfully"), 
                            React.createElement("div", {className: "row"}, 
                                React.createElement(BS.ButtonToolbar, {className: "center-block-inline"}, 
                                    React.createElement(Cmp.Button.Save, {onClick: this.reset}, "Add Another Contact"), 
                                    React.createElement("span", {className: "pull-left btn-divider"}, "or"), 
                                    React.createElement(Cmp.Button.Cancel, {onClick: this.gotoSteps}, "Continue ", React.createElement("i", {className: "fa fa-arrow-right"}))
                                )
                            )
                        )
                    ), 
                    !state.success && (
                         React.createElement(Validation.Form, {ref: "form", horizontal: false, model: props.contactModel, onSubmit: this.onSaveClick}, 
                            React.createElement("h1", null, props.contactModel.id ? 'Edit' : 'Add', " Contact"), 

                            React.createElement(Validation.Input, React.__spread({name: "name", label: "Contact Name:"},  defaultControlAttr, {placeholder: "Enter a Name for this contact"})), 
                            React.createElement(Validation.Input, React.__spread({name: "email", label: "Contact Email:"},  defaultControlAttr, {placeholder: "Enter an Email for this contact"})), 
                            React.createElement(Validation.Input, React.__spread({name: "phone", label: "Contact Phone:"},  defaultControlAttr, {placeholder: "Enter a phone number for this contact"})), 
                            React.createElement(Validation.Input, {type: "checkbox", labelClassName: "label", label: "Receive Email Notifications", name: "receiveNotification", value: true, useCheckedLink: true}), 

                            React.createElement(Cmp.ValidationMessage, null), 
                            React.createElement(BS.ButtonToolbar, null, 
                                React.createElement(Cmp.Button.Cancel, {onClick: this.gotoSteps}, React.createElement("i", {className: "fa fa-arrow-left"}), " Back to Steps"), 
                                React.createElement(Cmp.Button.Save, {className: "pull-right"}, "Save & Continue ", React.createElement("i", {className: "fa fa-arrow-right"}))
                            )
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    },

 
    onSaveClick: function(e) {
        var values = this.refs.form.getValues(true);
        this.props.contactModel.save(values).then(this.gotoSteps);
    },

    gotoSteps: function() {
        this.transitionTo('ProjectWizardSteps', {
            id: this.props.model.id
        });
    },

    reset: function() {
        var me = this;
        me.props.entity.clear();
        me.setState(me.updateState(), function() {
            me.refs.form && me.refs.form.forceUpdate();
        });
    }
});
module.exports = ProjectVisibility;
