"use strict";

var React = require('react'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    $ = require('jquery'),
    ReactRouterBootstrap = require('react-router-bootstrap'),
    ButtonLink = ReactRouterBootstrap.ButtonLink,
    Validation = require('../../partials/validation'),
    FluxBone = require('../../mixins/FluxBone'),
    BS = require('react-bootstrap'),
    Cmp = require('../../components'),
    Constants = require('../../constants'),
    CurrentUser = require('../../shared/CurrentUser');

var PersonalizedAgentEmail = React.createClass({displayName: "PersonalizedAgentEmail",

    mixins: [
        FluxBone.CollectionMixin('model.emailImagesCollection', 'sync', 10)
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function(props) {
        var state = this.state || {},
            templateImages;

        props = props || this.props;
        templateImages = props.model ? props.model.emailImagesCollection : undefined;

        if(templateImages && templateImages.length === 0 && !templateImages.isLoading() && templateImages) {
            templateImages.fetch();
        }

        return {
            id: props.model.id
        };
    },

    getInitialState: function() {
        return this.updateState();
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function(props) {
        this.setState(this.updateState(props));
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.model,
            linkParam = {
                id: model.id
            },
            includeOptions = {
                includeEmailImages: true
            },
            sizingText = (
                React.createElement("h5", {className: "blue-text no-margin"}, "Image should be 560 x 420")
            ),
            instructionText = (
                React.createElement("div", {className: "margin-bottom-10"}, "All other sizes will be automatically cropped. Only image files allowed: JPG, PNG, GIF.")
            );
        
        return (
            React.createElement("div", null, 
                React.createElement(BS.Modal, {className: "modal-preview modal-md", show: state.showPreview, onHide: this.closePreviewDialog}, 
                    React.createElement(BS.Modal.Body, {className: ""}, 
                        React.createElement("div", {dangerouslySetInnerHTML: {__html: state.previewHtml}})
                    )
                ), 
                React.createElement("h1", null, "Agent Branded Emails"), 
                React.createElement("p", null, 
                    "This feature makes it easy for agents to promote your new construction to their buyers through email. The feature automatically creates HTML emails with your listing’s information while personalized with the agent’s information. Agents can easily send these emails to their buyers to gain their interest about your new construction." 
                ), 
                React.createElement("h2", null, "Email Creator:"), 
                React.createElement("p", null, 
                    "Use the email creator to create the Agent Branded Email." + ' ' + 
                    "The images and text correspond to the template image on the right." 
                ), 
                React.createElement(Validation.Form, {ref: "form", modelLinkToStateOptions: includeOptions, model: props.model, className: "form", onSubmit: this.onSaveClick, validateFilter: "agentEmail", validateOnly: ['marketingTagLineEmail', 'marketingMessageEmail']}, 
                    React.createElement("div", {className: "row"}, 
                        React.createElement("div", {className: "col-xs-8"}, 
                            model.emailImagesCollection.map(function(image, i) {
                                var label;
                                switch(image.get('imageKey')) {
                                    case 'FIRST_IMAGE':
                                        label = 'Image 1:';
                                        break;
                                    case 'SECOND_IMAGE':
                                        label = 'Image 2:';
                                        break;
                                    case 'THIRD_IMAGE':
                                        label = 'Image 3:';
                                        break;
                                }
                                return (
                                    React.createElement(Cmp.Picker.File, {
                                        formGroup: false, 
                                        showPreview: true, 
                                        multipart: true, 
                                        file_data_name: "image", 
                                        onBeforeUpload: me.onBeforeUploadFile.bind(null, image), 
                                        ref: image.get('imageKey'), 
                                        key: image.get('imageKey'), 
                                        name: 'emailImages.' + i + '.files', 
                                        sizingText: sizingText, 
                                        instructionText: instructionText, 
                                        trigger: "onChange", url: image.url(), 
                                        label: label, 
                                        model: image, 
                                        onComplete: me.onFileUpload.bind(null, image), 
                                        mime_types: [Constants.imageMimeTypes]}
                                    )
                                );

                            }), 
                            React.createElement(Validation.Input, {name: "marketingTagLineEmail", required: true, label: "Content Tagline:", type: "text"}), 
                            React.createElement(Validation.Input, {name: "marketingMessageEmail", required: true, rows: "5", label: "Content:", type: "textarea"}), 
                            React.createElement(Cmp.ValidationMessage, null), 
                            React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                                React.createElement(Cmp.Button.Save, {className: "pull-right"})
                            )
                        ), 
                        React.createElement("div", {className: "col-xs-4"}, 
                            React.createElement("label", {className: "control-label label"}, React.createElement("b", null, "Email Template:")), 
                            React.createElement("p", null, 
                                "The images and text correspond to", 
                                React.createElement("br", null), 
                                "the template design below."
                            ), 
                            React.createElement("img", {className: "margin-bottom-15", src: Constants.imageRoot + 'AS_ExampleAgentBrochure.png'}), 
                            React.createElement(BS.Button, {bsStyle: "warning", validationWrapperClassName: "text-right", key: "previewButton", formLinks: { disabled: 'isDirty'}, onClick: this.onPreview}, "Preview Email"), 
                            React.createElement("p", {key: "previewInfo", formLinks: { 'aria-hidden': this.hidePreviewInfo}}, 
                                "Preview is not available until form is saved."
                            )
                        )
                    )
                )

            )
        );
    },
    onFileUpload: function(image) {
        var me = this,  
            filePicker1 = me.refs.FIRST_IMAGE,
            filePicker2 = me.refs.SECOND_IMAGE,
            filePicker3 = me.refs.THIRD_IMAGE;

        if(!me.isMounted()) {
            return;
        }

        if (!filePicker1.hasFiles() && !filePicker2.hasFiles() && !filePicker3.hasFiles()) {
            me.props.model.emailImagesCollection.fetch().then(function(){
                me.props.model.trigger('sync', me.props.model, undefined, {});
            });
        }
    },
    onBeforeUploadFile: function(image, uploader, domFile) {
        uploader.settings.url = image.urlBase;
        uploader.settings.multipart_params  = {
            imageKey: image.get('imageKey'),
            templateKey: image.get('templateKey'),
            listingId: this.props.model.id,
        };
    },
    onPreview: function() {
        var me = this;
        $.ajax({
            url: Constants.restPath + '/agents/brochure',
            data: {
                format: 'html',
                listingId: me.props.model.id,
                template: 'basic'
            }
        }).then(function(response) {
            me.setState(function() {
                return {
                    showPreview: true,
                    previewHtml: response
                }
            });
        });
    },
    closePreviewDialog: function() {
        this.setState(function() {
            return {
                showPreview: false
            }
        });
    },
    onSaveClick: function(e) {
        var me = this,  
            filePicker1 = me.refs.FIRST_IMAGE,
            filePicker2 = me.refs.SECOND_IMAGE,
            filePicker3 = me.refs.THIRD_IMAGE;

        me.props.model.save(me.refs.form.getValues(), {
            fields: ['id', 'name', 'marketingTagLineEmail', 'marketingMessageEmail']
        }).then(function() {
            // me.props.model.emailImagesCollection.each(function(model) {
            //     model.destroy();
            // });
            if (filePicker1.hasFiles()) {
                filePicker1.startUpload();
            }
            if (filePicker2.hasFiles()) {
                filePicker2.startUpload();
            }
            if (filePicker3.hasFiles()) {
                filePicker3.startUpload();
            }
        });
    },

    hidePreviewInfo: function(state) {
        return !state.isDirty || !state.isValid ? 'true' : undefined;
    }

});
module.exports = PersonalizedAgentEmail;
