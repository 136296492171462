"use strict";

var ModelClass = require('./Model'),
    Constants = require('../constants'),
    _ = require('underscore'),
    $ = require('jquery'),
    moment = require('moment'),
    utility = require('../shared/Utilities');

window.Stripe && Stripe.setPublishableKey(Constants.stripeKey);

var Billing = ModelClass.extend({
    url: Constants.restPath + '/users/current/billing',
    putURL: Constants.restPath + '/users',
    idAttribute: 'last4',
    validation: {
        'cc.num': function(value, attr, computedState) {
            var cvc;

            computedState = computedState.billing ? computedState.billing : computedState;
            cvc = computedState.cc ? computedState.cc.cvc : computedState['billing.cc.cvc'];

            if (value && cvc && !Payment.fns.validateCardNumber(value)) {
                return 'Invalid Credit Card Number.';
            } else if (!value) {
                return 'Credit Card Number is Required.';
            }
        },
        'cc.exp': function(value, attr, computedState) {
            if (value && !Payment.fns.validateCardExpiry.apply(Payment.fns, value.split(' / '))) {
                return 'Invalid Expiration Date.';
            } else if (!value) {
                return 'Expiration Date is Required.';
            }
        },
        'cc.cvc': function(value, attr, computedState) {
            if (value && !Payment.fns.validateCardCVC(value)) {
                return 'Invalid CVC (Security Code).';
            } else if (!value) {
                return 'CVC (Security Code) is Required.';
            }
        },
        'cc.name': function(value, attr, computedState) {
            if (!utility.hasValue(value)) {
                return 'Name is required.';
            }
        }
    },
    labels: {
        'cc.name': 'Name'
    },
    validationUses: {
        "cc.num": ['cc.cvc'],
        "billing.cc.num": ['billing.cc.cvc']
    },
    defaults: function() {
        return {
            last4: undefined,
            brand: '',
            expMonth: '',
            expYear: '',
            // This represents the CC details before hashed
            cc: {},
            // This is the hashed CC details for sending
            stripeHash: undefined
        };
    },

    parse: function(response) {
        var data = ModelClass.prototype.parse.apply(this, arguments);

        if (data && data.expMonth && data.expMonth < 10) {
            data.expMonth = '0' + data.expMonth;
        }

        if (data && data.serviceSubscribeDate) {
            data.serviceSubscribeDate = moment.utc(data.serviceSubscribeDate, 'X');
        }

        return data;
    },

    toJSON: function(options) {
        var data = ModelClass.prototype.toJSON.apply(this, arguments);

        options = options || {};

        if (options.url) {
            data = { stripeHash: data.stripeHash };
        } else {
            _.defaults(data.cc, this.parseCCData(data));
            data.cc.num = data.cc.num ? data.cc.num.replace(/\s/g, '').replace(/(.{4})/g, '$1 ') : undefined;
            if (data.cc.num && data.cc.num[data.cc.num.length - 1] === ' ') {
                data.cc.num = data.cc.num.substring(0, data.cc.num.length - 1);
            }
        }

        return data;
    },

    save: function(attributes, options) {
        var me = this,
            promise = $.Deferred(),
            cc = attributes && attributes.cc ? attributes.cc : me.get('cc'),
            cc_exp = cc.exp ? cc.exp.split(' / ') : [];

        options = options || {};

        if (!me.validate({ cc: cc })) {
            Stripe.card.createToken({
                number: cc.num,
                cvc: cc.cvc,
                exp_month: cc_exp[0],
                name: cc.name,
                exp_year: cc_exp[1]
            }, me._onStripeCardHandled.bind(me, promise, attributes, options));
        } else {
            promise.rejectWith(me, [{}, 'Model Invalid', 'Model Invalid'])
        }

        return promise;
    },

    _onStripeCardHandled: function(promise, attributes, options, responseCode, response) {
        var me = this;

        if (response && response.id) {
            attributes = _.extend(attributes || {}, {
                last4: response.card.last4,
                stripeHash: response.id,
                expMonth: response.card.exp_month < 10 ? '0' + response.card.exp_month : response.card.exp_month,
                expYear: response.card.exp_year,
                brand: response.card.brand
            });
            attributes.cc = _.extend(me.parseCCData(attributes), {
                name: attributes.cc.name
            });
            options.url = this.putURL + '/' + this.parent.id;
            options.validate = false;
            ModelClass.prototype.save.call(me, attributes, options).then(function() {
                if (options.fetchOnSave) {
                    me.fetch().then(function() {
                        promise.resolveWith(me, arguments);
                    }).fail(function() {
                        promise.rejectWith(me, arguments);
                    });
                } else {
                    promise.resolveWith(me, arguments);
                }
            }).fail(function() {
                promise.rejectWith(me, arguments);
            });
        } else if (response && response.error) {
            promise.rejectWith(me, [me, { responseJSON: response.error }, options]);
        }
    },

    parseCCData: function(data) {
        return {
            num: data.last4 ? ('XXXXXXXXXXXX' + data.last4) : undefined,
            exp: data.expMonth && data.expYear ? [data.expMonth, data.expYear].join(' / ') : undefined,
            name: data.name,
            cvc: data.cvc || ''
        };
    }
});

module.exports = Billing;
