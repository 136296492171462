"use strict";

var Constants = require('../../constants'),
    Report = require('./Report'),
    moment = require('moment'),
    $ = require('jquery');

var NumberOfWidget = Report.extend({
    urlRoot: Constants.restPath + "/reports/widget-performance",
    defaults: function() {
        var now = moment.valueOf().toString();
        return {
            from: now,
            to: now
        };
    }
});

module.exports = NumberOfWidget;
