'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _localeEnUs = require('../locale/en-us');

var _localeEnUs2 = _interopRequireDefault(_localeEnUs);

var _rcUtil = require('rc-util');

var _gregorianCalendar = require('gregorian-calendar');

var _gregorianCalendar2 = _interopRequireDefault(_gregorianCalendar);

function noop() {}

function getNow() {
  var value = new _gregorianCalendar2['default']();
  value.setTime(Date.now());
  return value;
}

function getNowByCurrentStateValue(value) {
  var ret = undefined;
  if (value) {
    ret = value.clone();
    ret.setTime(Date.now());
  } else {
    ret = getNow();
  }
  return ret;
}

var CalendarMixin = {
  propTypes: {
    value: _react2['default'].PropTypes.object,
    defaultValue: _react2['default'].PropTypes.object,
    className: _react2['default'].PropTypes.string,
    orient: _react2['default'].PropTypes.arrayOf(_react2['default'].PropTypes.oneOf(['left', 'top', 'right', 'bottom'])),
    locale: _react2['default'].PropTypes.object,
    style: _react2['default'].PropTypes.object,
    visible: _react2['default'].PropTypes.bool,
    onSelect: _react2['default'].PropTypes.func,
    prefixCls: _react2['default'].PropTypes.string,
    onKeyDown: _react2['default'].PropTypes.func,
    onChange: _react2['default'].PropTypes.func,
    onFocus: _react2['default'].PropTypes.func,
    onBlur: _react2['default'].PropTypes.func
  },

  getDefaultProps: function getDefaultProps() {
    return {
      locale: _localeEnUs2['default'],
      style: {},
      visible: true,
      prefixCls: 'rc-calendar',
      className: '',
      onKeyDown: noop,
      onSelect: noop,
      onChange: noop,
      onFocus: noop,
      onBlur: noop
    };
  },

  getInitialState: function getInitialState() {
    var props = this.props;
    var value = props.value || props.defaultValue || getNow();
    return { value: value };
  },

  componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
    var value = nextProps.value;
    if (value !== undefined) {
      value = value || nextProps.defaultValue || getNowByCurrentStateValue(this.state.value);
      this.setState({
        value: value
      });
    }
  },

  shouldComponentUpdate: function shouldComponentUpdate(nextProps) {
    return this.props.visible || nextProps.visible;
  },

  onSelect: function onSelect(value, keyDownEvent) {
    this.setValue(value);
    if (!keyDownEvent) {
      if (this.isAllowedDate(value)) {
        this.props.onSelect(value);
      }
    }
  },

  onFocus: function onFocus() {
    if (this._blurTimer) {
      clearTimeout(this._blurTimer);
      this._blurTimer = null;
    } else {
      this.props.onFocus();
    }
  },

  onBlur: function onBlur() {
    var _this = this;

    if (this._blurTimer) {
      clearTimeout(this._blurTimer);
    }
    this._blurTimer = setTimeout(function () {
      _this.props.onBlur();
    }, 100);
  },

  renderRoot: function renderRoot(newProps) {
    var _className;

    var props = this.props;
    var state = this.state;
    var prefixCls = props.prefixCls;

    var className = (_className = {}, _defineProperty(_className, prefixCls, 1), _defineProperty(_className, prefixCls + '-hidden', !props.visible), _defineProperty(_className, props.className, !!props.className), _className);

    var orient = state.orient;
    if (orient) {
      orient.forEach(function (o) {
        className[prefixCls + '-orient-' + o] = 1;
      });
    }

    return _react2['default'].createElement(
      'div',
      { className: (0, _rcUtil.classSet)(className) + ' ' + newProps.className, style: this.props.style,
        tabIndex: '0', onFocus: this.onFocus,
        onBlur: this.onBlur, onKeyDown: this.onKeyDown },
      newProps.children
    );
  },

  setValue: function setValue(value) {
    if (!('value' in this.props)) {
      this.setState({
        value: value
      });
    }
    this.props.onChange(value);
  },

  isAllowedDate: function isAllowedDate(value) {
    var disabledDate = this.props.disabledDate;
    return !disabledDate || !disabledDate(value);
  },

  setOrient: function setOrient(orient) {
    // FIXME: hack to prevent breaking rc-animate
    if (this.state.orient === orient) {
      return;
    }
    this.state.orient = orient;
    var prefixCls = this.props.prefixCls;
    var root = _react2['default'].findDOMNode(this);
    var className = root.className.replace(new RegExp(prefixCls + '-orient-\\w+', 'g'), '');
    if (orient) {
      orient.forEach(function (o) {
        className += ' ' + prefixCls + '-orient-' + o;
      });
    }
    root.className = className;
  }
};

exports['default'] = CalendarMixin;
module.exports = exports['default'];