"use strict";

var React = require('react/addons'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    ContactModel = require('../../models/ProjectContact'),
    Cmp = require('../../components'),
    ReactRouter = require('react-router'),
    ListingModelType = require('../../mixins/ListingModelType'),
    RequiredMessage = require('../../partials/RequiredMessage'),
    Validation = require('../../partials/validation');

var ProjectVisibility = React.createClass({displayName: "ProjectVisibility",

    mixins: [
        ReactRouter.Navigation,
        ListingModelType.ListingNavigation('Contact')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            id = params.contactId ? parseInt(params.contactId, 10) : params.contactId,
            projectId = this.props.model.id ? parseInt(this.props.model.id, 10) : this.props.model.id,
            contactModel = this.props.contactModel,
            listingId = contactModel.get('listing') ? contactModel.get('listing').id : undefined;

        if (id !== contactModel.id || projectId !== listingId) {
            contactModel.clear();

            this.props.contactModel.set({
                id: id,
                listing: {
                    id: projectId
                }
            });

            if(id){
                this.props.contactModel.fetch();
            }
        }

        return {};
    },

    getDefaultProps: function() {
        return {
            contactModel: new ContactModel(),
            readOnly: false
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            defaultControlAttr = {
                type: 'text',
                labelClassName: 'label',
                trigger: 'onChange'
            };

        return (
            React.createElement("div", null, 
                 React.createElement(Validation.Form, {ref: "form", horizontal: false, model: props.contactModel, onSubmit: this.onSaveClick}, 
                    React.createElement("h1", null, props.contactModel.id ? 'Edit' : 'Add', " Contact"), 
                    React.createElement("h5", null, "Use the form to add/edit the contact."), 
                    React.createElement(RequiredMessage, null), 

                    React.createElement(Validation.Input, React.__spread({name: "name", label: "Contact Name:"},  defaultControlAttr, {placeholder: "Enter a Name for this contact"})), 
                    React.createElement(Validation.Input, React.__spread({name: "email", label: "Contact Email:"},  defaultControlAttr, {placeholder: "Enter an Email for this contact"})), 
                    React.createElement(Validation.Input, React.__spread({name: "phone", label: "Contact Phone:"},  defaultControlAttr, {placeholder: "Enter a phone number for this contact"})), 
                    React.createElement(Validation.Input, {type: "checkbox", labelClassName: "label", label: "Receive Email Notifications", name: "receiveNotification", value: true, useCheckedLink: true}), 

                    React.createElement(Cmp.ValidationMessage, null), 
                    React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                        React.createElement(Cmp.Button.Cancel, {onClick: this.onCancelClick}), 
                        React.createElement(Cmp.Button.Save, {className: "pull-right"})
                    )
                )
            )
        );
    },


    onSaveClick: function(e) {
        var values = this.refs.form.getValues(true);
        this.props.contactModel.save(values).then(this.backToList);
    },

    onCancelClick: function() {
        this.backToList();
    },

    backToList: function() {
        this.transitionTo(this.backTo(), this.context.router.getCurrentParams());
    }
});
module.exports = ProjectVisibility;
