"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    Constants = require('../../constants'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    Projects = require('../../collections/Projects'),
    Roles = require('../../mixins/Roles'),
    FluxBone = require('../../mixins/FluxBone'),
    ReactRouter = require('react-router'),
    NavLink = require('../../components/NavLink'),
    Cmp = require('../../components'),
    Link = ReactRouter.Link,
    CurrentUser = require('../../shared/CurrentUser'),
    Validation = require('../../partials/validation'),
    SidebarNav = require('../../partials/SidebarNav'),
    $ = require('jquery'),
    Pagination = require('../../partials/Pagination'),
    moment = require('moment');

$.ajaxSettings.traditional = true;

var Projects = React.createClass({displayName: "Projects",

    mixins: [
        FluxBone.CollectionMixin('store', 'sync'),
        Roles,
        React.addons.LinkedStateMixin,
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            store: new Projects()
        };
    },

    updateState: function() {
        var query = this.context.router.getCurrentQuery(),
            store = this.props.store,
            options = {
                data: {
                    extra: ['images', 'floorPlans', 'promotions', 'documents']
                }
            };

        if (!CurrentUser.isAdmin()) {
            options.data.ownedOnly = true;
        }

        store.pageNum = query.page || 1;
        store.setFilters(query, {
            resetFilters: true
        });
        store.throttledFetch(options);

        return store.getFilterValues();
    },

    getInitialState: function() {
        var state = this.updateState();

        state.filtersVisible = true;

        return state;
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    toggleFilters: function(e) {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        this.setState({
            filtersVisible: !this.state.filtersVisible
        });
    },

    doSearch: function(e) {
        var me = this,
            query = {
                page: 1
            },
            state = me.state;

        if (e && e.preventDefault) {
            e.preventDefault();
        }

        _.each(me.props.store.filters, function(filter) {
            query[filter] = state[filter] === false || state[filter] === '' ? undefined : state[filter];
        });

        this.transitionTo('ProjectList', undefined, query);
    },

    renderTableHeader: function(nameHeader) {
        var isAdmin = this.hasOneRole(Constants.roles.ADMIN);
        return  (
            React.createElement("thead", null, 
                React.createElement("tr", null, 
                    React.createElement("th", null, React.createElement("i", {className: "fa fa-file-text-o"}), " ", nameHeader), 
                    React.createElement("th", null, React.createElement("i", {className: "fa fa-flash"}), " Active"), 
                    this.hasOneRole(Constants.roles.ADMIN) && (
                        React.createElement("th", {style: {width: 75}}, "Approved")
                    ), 
                    this.hasOneRole(Constants.roles.ADMIN) && (
                        React.createElement("th", {style: {width: 75}}, "Approval Date")
                    ), 
                    this.hasOneRole(Constants.roles.ADMIN) && (
                        React.createElement("th", {style: {width: 75}}, "Last Update Date")
                    ), 
                    this.hasOneRole(Constants.roles.ADMIN) && (
                        React.createElement("th", {style: {width: 75}}, React.createElement("i", {className: "fa fa-eye"}), " Show to Agents")
                    ), 
                    this.hasOneRole(Constants.roles.ADMIN) && (
                        React.createElement("th", {style: {width: 75}}, React.createElement("i", {className: "fa fa-eye"}), " Agent Tracker Approved")
                    ), 
                    !isAdmin && (React.createElement("th", null, React.createElement("i", {className: "fa fa-image"}), " Images")), 
                    !isAdmin && (React.createElement("th", null, React.createElement("i", {className: "fa fa-file-image-o"}), " Floor Plans")), 
                    !isAdmin && (React.createElement("th", null, React.createElement("i", {className: "fa fa-dollar"}), " Promotions")), 
                    !isAdmin && (React.createElement("th", null, React.createElement("i", {className: "fa fa-files-o"}), " Documents")), 
                    React.createElement("th", null)
                )
            )
        );
    },

    render: function() {
        var me = this,
            filterHeader = (
                React.createElement("div", null, 
                    React.createElement("h4", {className: "panel-title"}, 
                        "Filters:", 
                        React.createElement("div", {className: "pull-right", title: "Toggle Filter Visibility"}, 
                            React.createElement("i", {className: 'fa fa-fw ' + (this.state.filtersVisible ? 'fa-minus' : 'fa-plus')})
                        )
                    )
                )
            ),
            expanded = this.state.filtersVisible,
            state = this.state,
            developer = CurrentUser.getEntity(),
            isAdmin = me.hasOneRole(Constants.roles.ADMIN);

        return (
            React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: "NCH - My Listings"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "My Listings"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(NavLink, {to: "ProjectList"}, "My Listings")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement("div", {className: "row"}, 
                        React.createElement("div", {className: "col-md-3 md-margin-bottom-40"}, 
                            React.createElement(SidebarNav, null)
                        ), 
                        React.createElement("div", {className: "col-md-9"}, 
                            React.createElement("h1", null, "My Listings"), 

                            this.hasRole('DEVELOPER') && 
                            React.createElement("div", null, 
                                React.createElement("h4", null, "My Builder Listing:"), 
                                React.createElement("p", {className: "margin-bottom-20"}, 
                                    "Below is your company listing." 
                                ), 

                                React.createElement(BS.Table, {striped: true, bordered: true, condensed: true, hover: true, className: "margin-bottom-40", id: "listing-table"}, 
                                    this.renderTableHeader('Listing Name'), 
                                    React.createElement("tbody", null, 
                                        React.createElement("tr", {key: developer.id}, 
                                            React.createElement("td", null, React.createElement(Link, {to: "UserAccountCompany"}, developer.get('name') || 'NO NAME')), 
                                            React.createElement("td", null, developer.isActive() ?  'Yes' : 'No'), 
                                            !isAdmin && (React.createElement("td", null, React.createElement(Link, {to: "BuilderEditImages"}, developer.imagesCollection.length))), 
                                            !isAdmin && (React.createElement("td", null, React.createElement(Link, {to: "BuilderEditFloorPlans"}, developer.floorPlansCollection.length))), 
                                            !isAdmin && (React.createElement("td", null, React.createElement(Link, {to: "BuilderEditPromotions"}, developer.promotionsCollection.length))), 
                                            !isAdmin && (React.createElement("td", null, React.createElement(Link, {to: "BuilderEditDocuments"}, developer.documentsCollection.length))), 
                                            React.createElement("td", null, React.createElement(Link, {to: "BuilderReportDashboard"}, React.createElement("i", {className: "fa fa-line-chart"})))
                                        )
                                    )
                                )
                            ), 


                            React.createElement("h4", null, "My Community Listings:"), 

                            React.createElement("div", {className: "row half-gutter margin-bottom-20"}, 
                                React.createElement("div", {className: "col-md-8"}, 
                                    React.createElement("p", null, 
                                        "Below are your community listings. Click on the community name to view and edit."
                                    )
                                ), 
                                React.createElement("div", {className: "col-md-4"}, 
                                    React.createElement(Link, {to: "ProjectWizard", className: "btn btn-default pull-right"}, React.createElement("i", {className: "fa fa-plus"}), " Add Community")
                                )
                            ), 
                            CurrentUser.hasRole(Constants.roles.ADMIN) && (
                                React.createElement(BS.Panel, {className: 'panel-table' + (expanded ? 'panel-table-expanded' : ''), collapsible: true, header: filterHeader, expanded: expanded, onSelect: this.toggleFilters}, 
                                    React.createElement("form", {onSubmit: this.doSearch}, 
                                        React.createElement("div", {className: "row"}, 
                                            React.createElement(Validation.Input, {type: "text", valueLink: this.linkState('name'), groupClassName: "col-sm-6", placeholder: "Search by Name"}), 
                                            React.createElement(Validation.Input, {type: "text", valueLink: this.linkState('developerName'), groupClassName: "col-sm-6", placeholder: "Search by Developer Name"}), 
                                            React.createElement(Validation.Input, {type: "text", valueLink: this.linkState('email'), groupClassName: "col-sm-6", placeholder: "Search by Email"}), 
                                            React.createElement(Validation.Input, {type: "text", valueLink: this.linkState('listingSource'), groupClassName: "col-sm-6", placeholder: "Search by Listing Source"})
                                        ), 

                                        React.createElement("div", {className: "row"}, 
                                            React.createElement(Validation.Input, {type: "select", valueLink: this.linkState('showToAgents'), groupClassName: "col-sm-4", label: "Show to Agents:"}, 
                                                React.createElement("option", {value: ""}, "All"), 
                                                React.createElement("option", {value: "true"}, "True"), 
                                                React.createElement("option", {value: "false"}, "False")
                                            ), 
                                            React.createElement(Validation.Input, {type: "select", valueLink: this.linkState('agentTrackerApproved'), groupClassName: "col-sm-4", label: "Agent Tracker Approved:"}, 
                                                React.createElement("option", {value: ""}, "All"), 
                                                React.createElement("option", {value: "true"}, "True"), 
                                                React.createElement("option", {value: "false"}, "False")
                                            ), 
                                            React.createElement(Validation.Input, {type: "select", valueLink: this.linkState('needsApprove'), groupClassName: "col-sm-4", label: "Needs Approval:"}, 
                                                React.createElement("option", {value: ""}, "All"), 
                                                React.createElement("option", {value: "true"}, "True"), 
                                                React.createElement("option", {value: "false"}, "False")
                                            )
                                        ), 

                                        React.createElement("div", {className: "row"}, 
                                            React.createElement(Cmp.Select.Country, {name: "country", valueLink: this.linkState('country'), groupClassName: "col-sm-3"}), 
                                            React.createElement(Cmp.Select.State, {name: "state", valueLink: this.linkState('state'), countryId: state.country, groupClassName: "col-sm-3"}), 
                                            React.createElement(Cmp.Select.Metro, {name: "metro_area", valueLink: this.linkState('metro_area'), stateId: state.state, groupClassName: "col-sm-3"}), 
                                            React.createElement(Cmp.Select.City, {name: "city", valueLink: this.linkState('city'), stateId: state.state, metroId: state.metro_area, groupClassName: "col-sm-3"})
                                        ), 
                                        React.createElement("div", {className: "row"}, 
                                            React.createElement("div", {className: "col-xs-12"}, 
                                                React.createElement(BS.Button, {className: "pull-right", type: "submit"}, "Search")
                                            )
                                        )
                                    )
                                )
                            ), 
                            React.createElement(Pagination, {store: this.props.store, to: "ProjectList", query: this.props.store.getFilterValues()}), 
                            CurrentUser.hasRole(Constants.roles.ADMIN) ? (
                                React.createElement(BS.Table, {striped: true, bordered: true, condensed: true, hover: true, id: "projects-table"}, 
                                    this.renderTableHeader('Project Name'), 
                                    React.createElement("tbody", null, 
                                    this.props.store.map(function(project){
                                        var data = project.toJSON(),
                                            linkParams = { id: data.id },
                                            developerEmail = project.developerModel.contactInfoModel.get('email');
                                        return (
                                            React.createElement("tr", {key: project.id}, 
                                                React.createElement("td", null, 
                                                    React.createElement("img", {src: data.image || Constants.placeholder, onError: me.loadPlaceholder, width: "75", height: "75", style: { position: 'absolute'}}), 
                                                    React.createElement("div", {style: { display: 'inline-block', paddingLeft: '85px', verticalAlign: 'top'}}, 
                                                        React.createElement(Link, {to: "Project", params: linkParams}, data.name || 'NO NAME'), 
                                                        React.createElement("div", null, data.locationCompact || 'No Location'), 
                                                        React.createElement("br", null), 
                                                        data.developer && data.developer.id && (
                                                            React.createElement("div", null, data.developer.name, " ", data.listingSource ? ('(' + data.listingSource + ')') : '')
                                                        )
                                                    )
                                                ), 
                                                React.createElement("td", null, project.isActive() ?  'Yes' : 'No'), 
                                                React.createElement("td", null, React.createElement("input", {type: "checkbox", checked: !data.needsApprove, onChange: me.toogleNeedsApprove.bind(null, project)})), 
                                                React.createElement("td", null, data.dateApproved ? data.dateApproved.format('YYYY-MM-DD') : 'n/a'), 
                                                React.createElement("td", null, data.lastUpdated ? data.lastUpdated.format('YYYY-MM-DD') : ''), 
                                                React.createElement("td", null, React.createElement("input", {type: "checkbox", checked: data.showToAgents, onChange: me.toggleBoolean.bind(null, 'showToAgents', project)})), 
                                                React.createElement("td", null, React.createElement("input", {type: "checkbox", checked: data.agentTrackerApproved, onChange: me.toggleBoolean.bind(null, 'agentTrackerApproved', project)})), 
                                                React.createElement("td", null, 
                                                    React.createElement(Link, {to: "ProjectReports", params: linkParams}, React.createElement("i", {className: "fa fa-line-chart"})), " ", 
                                                    isAdmin && developerEmail && (React.createElement("a", {href: "#", onClick: me.impersonateUser.bind(null, project)}, React.createElement("i", {className: "fa fa-eye"})))
                                                )
                                            )
                                        );
                                    })
                                    )
                                )
                            ) : (
                                React.createElement(BS.Table, {striped: true, bordered: true, condensed: true, hover: true, id: "projects-table"}, 
                                    this.renderTableHeader('Project Name'), 
                                    React.createElement("tbody", null, 
                                    this.props.store.map(function(project){
                                        var data = project.toJSON(),
                                            linkParams = { id: data.id },
                                            developerEmail = project.developerModel.contactInfoModel.get('email');
                                        return (
                                            React.createElement("tr", {key: project.id}, 
                                                React.createElement("td", null, React.createElement(Link, {to: "Project", params: linkParams}, data.name || 'NO NAME')), 
                                                React.createElement("td", null, project.isActive() ?  'Yes' : 'No'), 
                                                isAdmin && (
                                                    React.createElement("td", null, React.createElement("input", {type: "checkbox", checked: data.showToAgents, onChange: me.toggleBoolean.bind(null, 'showToAgents', project)}))
                                                ), 
                                                isAdmin && (
                                                    React.createElement("td", null, React.createElement("input", {type: "checkbox", checked: data.agentTrackerApproved, onChange: me.toggleBoolean.bind(null, 'agentTrackerApproved', project)}))
                                                ), 
                                                !isAdmin && (React.createElement("td", null, React.createElement(Link, {to: "ProjectEditImages", params: linkParams}, project.imagesCollection.length))), 
                                                !isAdmin && (React.createElement("td", null, React.createElement(Link, {to: "ProjectEditFloorPlans", params: linkParams}, project.floorPlansCollection.length))), 
                                                !isAdmin && (React.createElement("td", null, React.createElement(Link, {to: "ProjectEditPromotions", params: linkParams}, project.promotionsCollection.length))), 
                                                !isAdmin && (React.createElement("td", null, React.createElement(Link, {to: "ProjectEditDocuments", params: linkParams}, project.documentsCollection.length))), 
                                                React.createElement("td", null, 
                                                    React.createElement(Link, {to: "ProjectReports", params: linkParams}, React.createElement("i", {className: "fa fa-line-chart"})), " ", 
                                                    isAdmin && developerEmail && (React.createElement("a", {href: "#", onClick: me.impersonateUser.bind(null, project)}, React.createElement("i", {className: "fa fa-eye"})))
                                                )
                                            )
                                        );
                                    })
                                    )
                                )
                            ), 
                            React.createElement(Pagination, {store: this.props.store, to: "ProjectList", noMargin: true, query: this.props.store.getFilterValues()})
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    },

    loadPlaceholder: function(e) {
        var img = e.currentTarget;
        img.src = Constants.placeholder;
    },

    toggleBoolean: function(field, project) {
        var data = {};
        data[field] = !project.get(field);
        project.save(data, {
            validate: false,
            fields: [field, 'name']
        });
    },

    toogleNeedsApprove: function(project) {
        var nowDate = moment.utc().format('x');
        if(project.get('needsApprove')) {
            project.save({
                dateApproved: nowDate,
                lastUpdated: nowDate,
                needsApprove: false
            }, {
                validate: false,
                fields: ['name', 'needsApprove']
            });
        } else {
            project.save({
                dateApproved: null,
                lastUpdated: nowDate,
                needsApprove: true
            }, {
                validate: false,
                fields: ['name', 'needsApprove']
            });
        }
    },

    impersonateUser: function(project, e) {
        e.preventDefault();
        CurrentUser.impersonateUser(project.developerModel.contactInfoModel.get('email'));
    }
});
module.exports = Projects;
