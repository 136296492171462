"use strict";

var React = require('react'),
    _ = require('underscore'),
    $ = require('jquery'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    Link = ReactRouter.Link,
    cx = require('classnames'),
    Constants = require('../../constants'),
    Cmp = require('../../components'),
    FluxBone = require('../../mixins/FluxBone'),
    ReportModel = require('../../models/reports/ListingPerformance'),
    LineChart = require("react-chartjs").Line,
    moment = require('moment'),
    numeral = require('numeral');


var ListingPerformanceChart = React.createClass({displayName: "ListingPerformanceChart",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset'),
        FluxBone.ModelMixin('report', 'sync'),
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function(props) {
        var startDate,
            endDate,
            listingId,
            grouping,
            state = this.state || {},
            redraw = false

        props = props || this.props;

        startDate = props.startDate || props.report.get('startDate');
        endDate = props.endDate || props.report.get('endDate');
        grouping = props.grouping || props.report.get('grouping');
        listingId = props.model ? props.model.id : props.report.get('listingId');

        if (state.startDate !== startDate || state.endDate !== endDate || state.listingId !== listingId || state.grouping !== grouping) {

            if (props.handleLoad) {
                this.props.report.clear();

                props.report.set({
                    startDate: startDate,
                    endDate: endDate,
                    listingId: listingId,
                    grouping: grouping
                });

                this.props.report.fetch();
            }

            redraw = true;
        }

        return {
            startDate: startDate,
            endDate: endDate,
            listingId: listingId,
            grouping: grouping,
            redraw: redraw
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        return {
            report: new ReportModel(),
            handleLoad: false,
            showViews: false,
            showClicks: false,
            showLeads: false
        };
    },
    /**
     * This is to ensure that when subroutes transition they trigger recheck router state and render
     */
    componentWillReceiveProps: function(props) {
        this.setState(this.updateState(props));
    },

    render: function() {
        var props = this.props,
            state = this.state,
            reportData = props.report.toJSON(),
            listingPerformanceStats = reportData.listingPerformanceStats,
            chartData = this.getChartData(),
            chartOptions = this.getChartOptions();

        return (
            React.createElement("div", {className: "as-chart", style: { height: 350}}, 
                listingPerformanceStats && (
                    React.createElement(LineChart, {data: chartData, options: chartOptions, ref: "chart", redraw: state.redraw})
                ), 
                listingPerformanceStats && (
                    React.createElement("div", {className: "legend", ref: "legend"})
                )
            )
        );
    },

    getChartData: function() {
        var props = this.props,
            state = this.state,
            model = props.model,
            report = props.report,
            views = {},
            clicks = {},
            leads = {},
            datasets = [],
            labels = [],
            data = {
                datasets: datasets,
                labels: labels
            };

        if(props.showViews){
            datasets.push(views);
        }

        if(props.showClicks){
            datasets.push(clicks);
        }

        if(props.showLeads){
            datasets.push(leads);
        }

        // Impression Numbers
        _.extend(views, {
            label: 'Views',
            fillColor: 'rgba(172,219,252,0.5)',
            strokeColor: 'rgba(172,219,252,1)',
            pointColor: 'rgba(172,219,252,1)',
            pointStrokeColor: '#fff',
            pointHighlightFill: '#fff',
            pointHighlightStroke: 'rgba(172,219,252,1)',
            data: []
        });

        // Clicks
        _.extend(clicks, {
            label: 'Clicks',
            fillColor: 'rgba(184,233,134,0.5)',
            strokeColor: 'rgba(184,233,134,1)',
            pointColor: 'rgba(184,233,134,1)',
            pointStrokeColor: '#fff',
            pointHighlightFill: '#fff',
            pointHighlightStroke: 'rgba(184,233,134,1)',
            data: []
        });

        // leads
        _.extend(leads, {
            label: 'Leads',
            fillColor: 'rgba(248,231,28,0.5)',
            strokeColor: 'rgba(248,231,28,1)',
            pointColor: 'rgba(248,231,28,1)',
            pointStrokeColor: '#fff',
            pointHighlightFill: '#fff',
            pointHighlightStroke: 'rgba(248,231,28,1)',
            data: []
        });

        _.each(report.get('listingPerformanceStats'), function(stats, date) {
            var dateObj = moment(date, 'YYYY-MM-DD');

            if(dateObj.isBefore('2015-11-26')){
                return;
            }

            if (state.grouping === 'MONTH') {
                labels.push(dateObj.format('MMM YY\''));
            } else if (state.grouping === 'WEEK') {
                labels.push(dateObj.format('MMM YY\' - [WK]ww'));
            }

            views.data.push(stats.views);
            clicks.data.push(stats.clicks);
            leads.data.push(stats.leads);
        });

        return data;
    },

    getChartOptions: function() {
        var options = {
            legendTemplate: "\
                <ul class=\"<%=name.toLowerCase()%>-legend\">\
                    <% for (var i=0; i<datasets.length; i++){%>\
                        <li>\
                            <span style=\"background-color:<%=datasets[i].strokeColor%>\"></span>\
                            <%if(datasets[i].label){%><%=datasets[i].label%><%}%>\
                        </li>\
                    <%}%>\
                </ul>",
            maintainAspectRatio: false
        };

        return options;
    },

    componentDidUpdate: function() {
        this.generateLegend();
    },

    componentDidMount: function() {
        this.generateLegend();
    },

    generateLegend: function() {
        var $legend = $(React.findDOMNode(this.refs.legend));
        if (!this.props.report.get('listingPerformanceStats')) {
            $legend.html('');
        } else {
            $legend.html(this.refs.chart.generateLegend());
        }

    }
});

module.exports = ListingPerformanceChart;
