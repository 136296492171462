"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    FluxBone = require('../mixins/FluxBone'),
    Validation = require('../partials/validation');

var ListingSelect = React.createClass({displayName: "ListingSelect",

    mixins: [
        FluxBone.CollectionMixin('store', 'sync')
    ],

    getDefaultProps: function() {
        return {
            defaultText: 'Select',
            trigger: "onChange",
            checkOnSync: true
        };
    },

    componentWillReceiveProps: function(newProps) {
        var me = this,
            props = this.props;

        if (newProps.disabled && newProps.disabled !== props.disabled && newProps.valueLink) {
            newProps.valueLink.requestChange('');
        }
    },

    render: function() {
        var props = this.props;

        return (
            React.createElement(Validation.Input, React.__spread({ref: "input", label: "Listings:"},  props, {type: "select"}), 
                React.createElement("option", {value: ""}, props.defaultText), 
                props.store.map(function(model){
                    var data = model.toJSON();
                    return (
                        React.createElement("option", {key: data.id, value: data.id}, data.name)
                    );
                })
            )
        );
    },

    getDisplayValue: function() {
        var value = this.refs.input.getValue(),
            id = value ? parseInt(value) : undefined,
            model,
            displayValue;

        if (id) {
            model = this.props.store.get(id);
        }

        if (model) {
            displayValue = model.get('name');
        }

        return displayValue
    },

    resetDeferred: function() {
        var me = this;
        this.resetTimeout = setTimeout(function() {
            me.props.store.reset();
            me.props.valueLink && me.props.valueLink.requestChange('');
        }, 100)
    },

    clearReset: function() {
        clearTimeout(this.resetTimeout);
        delete this.resetTimeout;
    },

    getValue: function() {
        return this.refs.input ? this.refs.input.getValue() : undefined;
    }
});

module.exports = ListingSelect;
