"use strict";

var React = require("react/addons"),
  Constants = require("../../../constants"),
  DocumentTitle = require("react-document-title"),
  cx = require("classnames"),
  FluxBone = require("../../../mixins/FluxBone"),
  BS = require("react-bootstrap"),
  ReactRouter = require("react-router"),
  Validation = require("../../../partials/validation"),
  Roles = require("../../../mixins/Roles"),
  RequiredMessage = require("../../../partials/RequiredMessage"),
  Cmp = require("../../../components"),
  $ = require("jquery"),
  Link = ReactRouter.Link;

var UserCompany = React.createClass({displayName: "UserCompany",
  mixins: [
    FluxBone.ModelMixin("model", "sync reset"),
    ReactRouter.Navigation,
    Roles
  ],

  contextTypes: {
    router: React.PropTypes.func
  },

  getInitialState: function() {
    return {};
  },
  render: function() {
    var me = this,
      props = me.props,
      state = me.state,
      defaultControlAttr = {
        type: "text",
        labelClassName: "col-md-3",
        wrapperClassName: "col-md-9"
      },
      roles = Constants.roles;

    return (
      React.createElement(Validation.Form, {
        ref: "form", 
        model: props.model, 
        className: "form form-horizontal", 
        onSubmit: this.onSaveClick
      }, 
        React.createElement("h4", null, 
          "Company Information:", " ", 
          React.createElement("small", null, 
            "Edit details about this project. ", React.createElement(RequiredMessage, null)
          )
        ), 
        React.createElement(Validation.Input, React.__spread({
          name: "realName", 
          label: "Company Name:"}, 
          defaultControlAttr)
        ), 
        React.createElement(Validation.Input, React.__spread({
          name: "website", 
          label: "Company URL:"}, 
          defaultControlAttr)
        ), 
        this.hasOneRole(roles.ADMIN) && (
          React.createElement("div", null, 
            React.createElement(Cmp.RadioGroup, {
              labelClassName: "col-md-3", 
              wrapperClassName: "col-md-6", 
              type: "radio", 
              label: "Show to Agents:", 
              name: "showToAgents", 
              items: [{ id: true, name: "Yes" }, { id: false, name: "No" }]}
            )
          )
        ), 
        React.createElement(Cmp.Select.Listing, {name: "listingTypes"}), 
        React.createElement(Validation.Input, React.__spread({
          name: "minPrice", 
          label: "Starting Price (USD):"}, 
          defaultControlAttr)
        ), 
        React.createElement(Validation.Input, React.__spread({
          name: "maxPrice", 
          label: "Max Price (USD):"}, 
          defaultControlAttr)
        ), 
        React.createElement(Validation.Input, React.__spread({
          name: "summary", 
          rows: "5", 
          label: "Company Summary:"}, 
          defaultControlAttr, 
          {type: "textarea"})
        ), 
        React.createElement(Validation.Input, React.__spread({
          name: "description", 
          rows: "5", 
          label: "Company Description:"}, 
          defaultControlAttr, 
          {type: "textarea"})
        ), 

        React.createElement("h4", null, 
          "Company Location: ", React.createElement("br", null), 
          React.createElement("small", null, 
            "Where is the company located? Enter the address for the company."
          )
        ), 
        React.createElement(Cmp.LocationSelect, React.__spread({
          ref: "contactInfo", 
          name: "contactInfo", 
          label: "Address:"}, 
          defaultControlAttr)
        ), 
        /* <Validation.Input ref="address1" name="contactInfo.address1" label="Address:" {...defaultControlAttr}/> */
        React.createElement(Validation.Input, React.__spread({
          name: "contactInfo.address2", 
          label: "Unit / Suite:"}, 
          defaultControlAttr)
        ), 
        /*
                <Cmp.Select.Country ref="country" invalidMsg="Country is required" name="contactInfo.city.country.id" label="Country:" {...defaultControlAttr}/>
                <Cmp.Select.State ref="state" name="contactInfo.city.state.id" invalidMsg="State / Region / Province is required" formLinks={{countryId:'model.contactInfo.city.country.id'}} label="State / Region:" {...defaultControlAttr}/>
                <Cmp.Select.City ref="city" name="contactInfo.city.id" invalidMsg="City / Town is required" formLinks={{stateId:'model.contactInfo.city.state.id'}} label="City / Town:" {...defaultControlAttr}/>

                <Validation.Input ref="zip" name="contactInfo.zip" label="Zip / Postal Code:" {...defaultControlAttr}/>
                */
        React.createElement("h4", null, 
          "Info for Agents and Brokers Only:", " ", 
          React.createElement("small", null, "Information will only be displayed to agents.")
        ), 
        React.createElement(Validation.Input, React.__spread({
          name: "clientRegistrationPolicy", 
          rows: "5", 
          label: "Registration Instructions:"}, 
          defaultControlAttr, 
          {type: "textarea"})
        ), 
        React.createElement(Validation.Input, React.__spread({
          label: "Message to Agents:", 
          name: "specialOffers"}, 
          defaultControlAttr, 
          {rows: "5", 
          type: "textarea"})
        ), 
        React.createElement(Validation.Input, React.__spread({
          name: "commissionOffered", 
          label: "Commission Offered:"}, 
          defaultControlAttr, 
          {addonAfter: "%"})
        ), 
        React.createElement(Validation.Input, React.__spread({
          name: "outOfStateReferralOffered", 
          label: "Referral Fee Offered:"}, 
          defaultControlAttr, 
          {addonAfter: "%"})
        ), 

        React.createElement(Cmp.ValidationMessage, null), 
        React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
          React.createElement(Cmp.Button.Cancel, {onClick: this.onCancelClick}), 
          React.createElement(Cmp.Button.Save, {className: "pull-right"})
        )
      )
    );
  },
  onSaveClick: function(e) {
    var me = this,
      props = this.props;

    props.model.save(me.refs.form.getValues()).then(function() {
      me.props.model.fetch();
    });
  },

  onCancelClick: function() {
    this.transitionTo("ProjectList");
  }
});
module.exports = UserCompany;
