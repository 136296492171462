"use strict";

var Backbone = require('backbone'),
    ModelClass = require('./Model'),
    CurrentUser = require('../shared/CurrentUser'),
    Constants = require('../constants'),
    _ = require('underscore');


var ContactWebmaster = ModelClass.extend({
    idAttribute: 'id',
    url: Constants.restPath + '/mail/contactwebmaster',
    validation: {
        "name": {
            required: true
        },
        "email": {
            required: true,
            pattern: 'email'
        },
        "message": {
            required: true
        }
    },
    defaults: function() {
        return {
            name: '',
            email: '',
            message: '',
            agentId: undefined,
            widgetId: undefined
        };
    },
    fetch: function() {},
    sync: function(method, model, options) {
        options = options || {};
        options.method = 'POST';
        options.contentType = 'application/x-www-form-urlencoded';
        options.data = Backbone.$.param(this.toJSON());
        return ModelClass.prototype.sync.call(this, method, model, options);
    }
});

module.exports = ContactWebmaster;
