"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    Amenity = require('../models/Amenity'),
    CurrentUser = require('../shared/CurrentUser');

var Amenities = Collection.extend({
    url: function() {
        var url = Constants.restPath + '/amenities',
            devId;

        // devId = CurrentUser.get('actualId') && CurrentUser.hasRole(Constants.roles.DEVELOPER) ? CurrentUser.getEntity().id : CurrentUser.hasRole(Constants.roles.ADMIN) ? this.developerId : undefined;

        if (devId) {
            url = url + '/by-developer/' + devId;
        } else if (this.ownOnly) {
            url = url + '/my';
        }

        return url;
    },
    model: Amenity,
    pageSize: null,
    ownOnly: false,
    developerId: undefined
});

module.exports = Amenities;
