"use strict";

var ModelClass = require('../Model'),
    $ = require('jquery');

var Report = ModelClass.extend({
    sync: function(method, model, options) {

        var params = $.param(model.toJSON(options));

        window.location.href = model.urlRoot + "?" + params;
    }
});

module.exports = Report;
