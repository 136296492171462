"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    FluxBone = require('../../mixins/FluxBone'),
    BS = require('react-bootstrap'),
    ProjectModel = require('../../models/Project'),
    Cmp = require('../../components'),
    Validation = require('../../partials/validation'),
    Roles = require('../../mixins/Roles'),
    Constants = require('../../constants'),
    CancelProjectEdit = require('../../partials/project/CancelProjectEdit'),
    RequiredMessage = require('../../partials/RequiredMessage'),

    ProjectsMap = require('../../partials/project/ProjectsMap');

var ProjectDetails2 = React.createClass({displayName: "ProjectDetails2",

    mixins: [
        ReactRouter.Navigation,
        Roles
    ],

    statics: {
        willTransitionFrom: function(transition, me, callback) {
            var continueAnyways;

            if (me && me.refs && me.refs.form && me.refs.form.isDirty()) {
                continueAnyways = window.confirm(me.refs.form.getDirtyMessage());
                if(continueAnyways){
                    callback();
                } else {
                    transition.cancel();
                    me.goBack();
                }
            } else {
                callback();
            }
        }
    },

    contextTypes: {
        router: React.PropTypes.func
    },

    getInitialState: function() {
        return {
            showCancelDialog : false  
        };
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            defaultControlAttr = {
                type: 'text',
                labelClassName: 'col-md-3',
                wrapperClassName: 'col-md-9'
            },
            roles = Constants.roles,
            listingImage = props.model.get('image'),
            validateOnly = ['clientRegistrationPolicy', 'specialOffers', 'commissionOffered', 'outOfStateReferralOffered', 'listingImage'];

        if(listingImage) {
            listingImage = listingImage;
        }

        return (
            React.createElement("div", null, 
                 React.createElement(Validation.Form, {ref: "form", model: props.model, className: "form form-horizontal", onSubmit: this.onSaveClick, handleDirty: true, validateOnly: validateOnly}, 
                    React.createElement("h4", null, "Listing Realtor Info: ", React.createElement("br", null), React.createElement("small", null, "This information is only displayed to agents and brokers.")), 
                    React.createElement(Validation.Input, React.__spread({name: "clientRegistrationPolicy", rows: "5", label: "Registration Instructions:"},  defaultControlAttr, {type: "textarea"})), 
                    React.createElement(Validation.Input, React.__spread({label: "Message to Agents:", name: "specialOffers"},  defaultControlAttr, {rows: "5", type: "textarea"})), 
                    React.createElement(Validation.Input, React.__spread({name: "commissionOffered", label: "Commission Offered:"},  defaultControlAttr, {addonAfter: "%"})), 
                    React.createElement(Validation.Input, React.__spread({name: "outOfStateReferralOffered", label: "Referral Fee Offered:"},  defaultControlAttr, {addonAfter: "%"})), 
                    /*
                    <h4>Community&#39;s Point of Contact: <br/><small>Provide contact information for this community&#39;s point of contact. Agents and brokers will be instructed to contact this person with questions and inquiries.</small></h4>
                    <div className="form-group">
                        <label className="col-md-3">Primary Point of Contact</label>
                    </div>
                    <Validation.Input name="contactName" label="Name:" {...defaultControlAttr}/>
                    <Validation.Input name="contactInfo.email" invalidMsg="Contact email is required" label="Email Address:" {...defaultControlAttr}/>
                    <Validation.Input name="contactInfo.phoneWork" label="Phone Number:" {...defaultControlAttr}/>
                    <p>Would you like to have this person receive an email to notify them when an agent generated a new lead for this community?</p>
                    <Cmp.RadioGroup labelClassName="col-md-3" wrapperClassName="col-md-6" type="radio" label="Receive Notification Emails:" name="receiveNotificationEmails" items={[{id : true,  name : "Yes"}, {id : false,  name : "No"}]}/>
                    */

                    /*
                    <div className="form-group">
                        <label className="col-md-3">Receive Notification Emails:</label>
                        <div className="col-md-7">

                            <Validation.Input label="Yes" groupClassName="col-md-3" {...defaultControlAttr} type="radio"/>
                            <Validation.Input label="No" groupClassName="col-md-3" {...defaultControlAttr} type="radio"/>
                        </div>
                    </div>
                    */

                    /*
                    <div className="form-group">
                        <label className="col-md-3">Secondary Point of Contact</label>
                    </div>
                    <Validation.Input name="contactName" label="Name:" {...defaultControlAttr}/>
                    <Validation.Input name="contactInfo.email" label="Email Address:" {...defaultControlAttr}/>
                    <Validation.Input name="contactInfo.phoneWork" label="Phone Number:" {...defaultControlAttr}/>
                    <div className="form-group">
                        <label className="col-md-3">Receive Notification Emails:</label>
                        <div className="col-md-9">
                            <Validation.Input label="Yes" groupClassName="col-md-3" labelClassName="col-md-12" {...defaultControlAttr} type="radio"/>
                            <Validation.Input label="No" groupClassName="col-md-3" labelClassName="col-md-12" {...defaultControlAttr} type="radio"/>
                        </div>
                    </div>
                    */
                   React.createElement("div", {className: "row"}, 
                        React.createElement("div", {className: "col-xs-12"}, 
                            React.createElement("h4", null, "Thumbnail Image for Widgets"), 
                            React.createElement("p", null, "This will be the image and thumbnail that shows up in widgets. You should choose an image that best represents your listing."), 
                            props.model && (
                                React.createElement("div", {className: "row"}, 
                                    React.createElement("div", {className: "col-xs-3"}, 
                                        React.createElement(Cmp.ImageThumbnail, {showPlaceholder: true, update: state.updateImage, onChange: me.onImageChange, className: "profile-image listing-image", innerClassName: "profile-image-inner", src: listingImage, background: true})
                                    ), 
                                    React.createElement("div", {className: "col-xs-9"}, 
                                        React.createElement("div", {className: "text-center"}, 
                                            React.createElement("h3", {className: "blue-text"}, "Photo should be 135 pixels wide by 135 pixels high."), 
                                            React.createElement("p", null, "All other sizes will automatically be cropped.")
                                        ), 
                                        React.createElement(Cmp.Picker.File, {ref: "filePicker", dropZoneText: "To upload your image, drag an image from your desktop here or", triggerSync: true, groupClassName: "margin-top-20", name: "listingImage", trigger: "onChange", url: props.model.url(), label: "", model: props.model, onComplete: this.onFileUpload, onBeforeUpload: this.onBeforeFileUpload, mime_types: [Constants.imageMimeTypes]})
                                    )
                                )
                            )
                        )
                    ), 
                    React.createElement(Cmp.ValidationMessage, null), 
                    React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                        React.createElement(Cmp.Button.Cancel, {onClick: this.onCancelClick}), 
                        React.createElement(Cmp.Button.Save, {className: "pull-right"})
                    )
                ), 
                React.createElement(CancelProjectEdit, {model: props.model, show: state.showCancelDialog, onHide: this.onHideCancelDialog, onCancel: this.doFormReset})
            )
        );
    },

    getMapZoom: function(state){
        var zoom = 3;

        if(state.customLocation || this.props.model.get('geoPosition')){
            zoom = 10;
        }

        return zoom;
    },

    getGeoQueryFormLink: function(formState){
        var me = this,
            refs = me.refs,
            form = refs.form,
            model = me.props.model,

            address1 = refs.address1,
            city = refs.city,
            state = refs.state,
            country = refs.country,

            value;

        if(formState.model /*&& formState.model.customLocation*/ && country && state && city && address1){
            value = [
                address1.getValue() || '', 
                city.getDisplayValue() || '', 
                state.getDisplayValue() || '', 
                country.getDisplayValue() || ''
            ].join(' ');
        }

        return value;
    },

    onSaveClick: function(e) {
        var me = this,
            filePicker = me.refs.filePicker;

        this.props.model.save(this.refs.form.getValues()).then(function(){
            if (filePicker.hasFiles()) {
                filePicker.startUpload();
            }
        });
    },

    onFileUpload: function(uploader, file) {
        var me = this;

        me.doFormReset(true);        
    },

    onImageChange: function() {
        var me = this;

        me.setState({
            updateImage: false
        });
        me.refs.form.forceUpdate();
    },

    doFormReset : function(fromSave){
        var me = this;
        this.props.model.fetch().then(function(){
            me.setState({
              showCancelDialog : false
            });

            if(fromSave) {
                me.setState({
                    updateImage: true
                });
                me.refs.form.forceUpdate();
            }
        });
    },

    onHideCancelDialog : function(){
        this.setState({
          showCancelDialog : false
        });

    },

    onCancelClick: function(){
        this.setState({
          showCancelDialog : true
        });
    }
});
module.exports = ProjectDetails2;
