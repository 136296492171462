"use strict";

var Backbone = require('backbone'),
    _ = require('underscore'),
    Constants = require('../constants'),
    URI = require('urijs'),
    $ = require('jquery');

var Collection = Backbone.Collection.extend({
    pageSize: 20,
    pageNum: 1,
    parent: undefined,
    total: undefined,
    jsonPSupport: Constants.jsonPSupport,
    synced: false,
    loading: false,
    rootPath: Constants.restPath,
    mode: 'remote',

    limitParam: 'limit',
    startParam: 'offset',

    constructor: function(models, options) {
        var throttledFetch;
        options = options || {};

        if (options.parent) {
            this.parent = options.parent;
        }

        throttledFetch = _.throttle(this.fetch, 200, {
            leading: false
        });
        this.throttledFetch = function() {
            this.loading = true;
            return throttledFetch.apply(this, arguments);
        };

        this.initEvents();

        return Backbone.Collection.prototype.constructor.apply(this, arguments);
    },

    getUrl: function(method, collection, options) {
        var url = _.result(this, 'url'),
            u = URI;

        if (this.jsonPSupport && URI(window.location.href).host() !== URI(this.rootPath).host()) {
            options.dataType = 'jsonp';
            url = url.replace(this.rootPath, '');
            url = this.rootPath + '/jsonp' + url;
        }

        return url;
    },

    initEvents: function() {
        this.on('request', this.onRequest, this);
        this.on('sync', this.onSync, this);
        this.on('error', this.onError, this);
    },

    onRequest: function(collection, xhr, options) {
        if (options.syncMethod === 'read') {
            this.loading = true;
        }
    },

    onSync: function(collection, response, options) {
        this.loading = false;
        if (options.syncMethod === 'read') {
            this.synced = true;
        }
    },

    onError: function(collection, response, options) {
        this.loading = false;
    },

    fetch: function(options) {
        var me = this,
            hasFilters = false;

        options = options || {};
        options.data = options.data || {};

        if (options.pageNum) {
            me.pageNum = options.pageNum;
        }

        if (options.pageSize) {
            me.pageSize = options.pageSize;
        }

        if (!options.fetchAll && me.pageSize && me.mode === 'remote') {
            options.data[me.limitParam] = me.pageSize;
            options.data[me.startParam] = me.getStart();
        }

        if (options.abortXhr && this.lastXhr) {
            me.lastXhr.abort();
        }

        if (me.filters && !me.disableFilters) {
            _.each(me.filters, function(filter) {
                options.data[filter] = me[filter];

                if (me[filter]) {
                    hasFilters = true;
                }
            });

            if (hasFilters && me.filterUrl && !options.url) {
                options.url = _.result(me, 'filterUrl');
            }
        }

        me.lastXhr = Backbone.Collection.prototype.fetch.apply(me, [options]);
        me.lastXhrOptions = options;
        return me.lastXhr;
    },

    sync: function(method, collection, options) {
        options = options || {};
        options.syncMethod = method;

        options.url = options.url || this.getUrl(method, collection, options);

        return Backbone.Collection.prototype.sync.call(this, method, collection, options);
    },

    getStart: function() {
        return ((this.pageNum - 1) * this.pageSize);
    },

    getTotal: function() {
        return this.total || this.length || 0;
    },

    /**
     * Returns the current paging values.
     * @return {Object} [description]
     *         {Object.start}
     *         {Object.end}
     *         {Object.total}
     */
    getRange: function() {
        var pageNum = parseInt(this.pageNum || 1, 10),
            start = this.getStart(),
            end = start + this.pageSize - 1,
            total = this.getTotal();

        if (end >= total) {
            end = total - 1;
        }

        return {
            start: start,
            end: end,
            total: total,
            pageNum: pageNum,
            pageSize: this.pageSize
        };
    },

    getOrFetch: function(id, attributes, options) {
        var me = this,
            model = this.get(id),
            isNew = false;

        options = options || {};

        if (!model) {
            model = attributes || {};
            model[this.model.prototype.idAttribute] = id;
            model = this.add(model);
            isNew = true;
        }

        if (isNew) {
            model.fetch(options).then(function() {
                me.add(model);
            });
        } else if (options.forceFetch) {
            model.fetch(options);
        }

        return model;
    },

    reset: function() {
        this.total = 0;
        return Backbone.Collection.prototype.reset.apply(this, arguments);
    },

    parse: function(response) {
        var me = this,
            data = Backbone.Collection.prototype.parse.apply(this, arguments),
            list = data && data.list ? data.list || [] : data || [],
            total = data ? data.total || list.length : list.length;

        this.total = total;

        return list;
    },

    isLoaded: function() {
        return this.isSynced();
    },

    isSynced: function() {
        return this.synced;
    },

    isLoading: function() {
        return this.loading;
    },

    getPage: function() {
        var range = this.getRange(),
            models = this.models || [];

        return models.slice(range.start, range.end + 1);
    },

    map: function() {
        if (this.mode === 'local' && this.pageSize) {
            return _.map.apply(_, [this.getPage(), arguments[0]]);
        } else {
            return Backbone.Collection.prototype.map.apply(this, arguments);
        }
    },

    setFilter: function(name, value, options) {
        var oldValue,
            filters = this.filters;

        options = options || {};

        if (options.clearEmpty !== false && value !== false) {
            value = value || undefined;
        }

        if (!filters) {
            return;
        }

        if (filters.indexOf(name) !== -1) {
            oldValue = this[name];
            this[name] = value;
        }

        if (options.forceRefresh || oldValue !== value) {
            this.throttledFetch(options);
        }
    },

    setFilters: function(filters, options) {
        if (options.resetFilters) {
            this.resetFilters();
        }

        for (var key in filters) {
            this.setFilter(key, filters[key], options);
        }
    },

    getFilterValues: function() {
        var filterValues = {};

        for (var i = 0, len = this.filters.length; i < len; i++) {
            filterValues[this.filters[i]] = this[this.filters[i]];
        }

        return filterValues;
    },

    resetFilters: function() {
        if (!this.filters) {
            return;
        }

        for (var i = 0, len = this.filters.length; i < len; i++) {
            this.setFilter(this.filters[i], undefined);
        }
    },

    getDirtyModels: function(options) {
        var me = this;

        return me.filter(function(model) {
            return me.isDirty(model, options);
        });
    },

    isDirty: function(model, options) {
        return false;
    }
}, {
    shared: function() {
        if (!this.sharedInstance) {
            this.sharedInstance = new this();
        }

        return this.sharedInstance;
    }
});

module.exports = Collection;
