"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    FluxBone = require('../mixins/FluxBone'),
    ListingTypes = require('../collections/ListingTypes'),
    _ = require('underscore'),
    cx = require('classnames'),
    Validation = require('../partials/validation');

var ListingTypeSelect = React.createClass({displayName: "ListingTypeSelect",

    onChangeTimeout: undefined,

    mixins: [
        FluxBone.CollectionMixin('store', 'sync'),
        Validation.Mixin
    ],

    getDefaultProps: function() {
        return {
            trigger: 'onChange',
            store: new ListingTypes(),
            required: false,
            showRequired: true
        };
    },

    getInitialState: function() {
        return {};
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            values = _.pluck(me.getValue() || [], 'id'),
            help = props.error || props.help,
            other = _.omit(props, 'store', 'className', 'trigger');

        return (
            React.createElement("div", React.__spread({},  other, {className: this.getClassName()}), 
                React.createElement("label", {className: "control-label col-md-3"}, React.createElement("span", null, "Project Type:")), 
                React.createElement("div", {className: "col-md-9 checkbox-group"}, 
                    props.store.map(function(listing){
                        var data = listing.toJSON(),
                            isInValue = values.indexOf(data.id) !== -1,
                            inputProps = {
                                key: data.id, 
                                type: 'checkbox', 
                                groupClassName: 'col-md-4',
                                value: data.id,
                                label: data.name,
                                checked: isInValue,
                                onChange: me.onChange,
                                readOnly: props.readOnly
                            };
                            
                        return (
                            React.createElement(Validation.Input, React.__spread({},  inputProps))
                        );
                    }), 
                    help && (
                        React.createElement("span", {className: "help-block"}, help)
                    )
                )
            )
        );
    },

    onChange: function(e) {
        var me = this,
            childValue = parseInt(e.currentTarget.value),
            checked = e.currentTarget.checked,
            value = (me.getValue() || []).slice(),
            childValueKey = _.findIndex(value, function(v) {
                return v.id === childValue;
            }),
            childValueObj = value[childValueKey];

        if (checked) {
            value.push({
                id: childValue
            });
        } else if (childValueKey === 0) {
            value.shift();
        } else if (childValueKey !== -1) {
            value.splice(childValueKey, 1);
        }

        if (me.props.valueLink) {
            me.props.valueLink.requestChange(value);
        }

        if (me.onChangeTimeout) {
            clearTimeout(me.onChangeTimeout)
        }

        me.onChangeTimeout = setTimeout(function() {
            me.onChangeTimeout = undefined;
            me.onValidateEvent(e)
        }, 10);
    },

    getValue: function() {
        if (this.props.valueLink) {
            return this.props.valueLink.value;
        } else {
            return this.props.value;
        }
    },

    getClassName: function() {
        var props = this.props,
            state = this.state,
            className = cx('form-group', {
                'has-feedback': !!props.error || props.hasFeedback,
                'has-error': !!props.error,
                'form-group-required': props.required && props.showRequired
            });

        return className;
    }
});

module.exports = ListingTypeSelect;
