"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    cx = require('classnames'),
    FluxBone = require('../mixins/FluxBone'),
    NavLink = require('../components/NavLink'),
    _ = require('underscore');

var Pagination = React.createClass({displayName: "Pagination",

    getDefaultProps: function() {
        return {
            local: false,
            noMargin: false,
            numPagesToDisplay: 5,
            to: undefined,
            params: undefined,
            query: undefined,
            store: undefined,
            showTotal: false,
            totalSuffixText: ''
        };
    },

    mixins: [
        FluxBone.CollectionMixin('store', 'sync')
    ],

    render: function() {
        var me = this,
            props = me.props,
            className = cx('pagination', 'pagination-sm', {
                "no-margin": props.noMargin
            }),
            numPages = me.getNumPages(),
            startPage = me.getStartPage(),
            pageNum = props.store.pageNum,
            numToDisplay = me.getNumToDisplay(),
            range = _.range(startPage, startPage + numToDisplay),
            params = props.params,
            prevPageNum = startPage < 2 ? 1 : startPage - 1,
            nextPageNum = startPage + numToDisplay > numPages ? numPages : startPage + numToDisplay,
            totalText,
            storeRange = props.store.getRange(),
            nextPageDisabled = nextPageNum === pageNum || nextPageNum > numPages,
            prevPageDisabled = storeRange.pageNum === 1;

        if(props.showTotal){
            totalText = 'Showing ' + (storeRange.total > 0 ? ((storeRange.start+1) + '-' + (storeRange.end+1) + ' of ' + storeRange.total) : 0);
        }
        
        return (
            React.createElement("nav", {className: props.className}, 
                props.showTotal && (
                    React.createElement("div", {className: 'summary hidden-sm hidden-md hidden-lg hidden-xl' + (props.noMargin ? ' no-margin margin-left-20' : '')}, totalText + ' ' + props.totalSuffixText)
                ), 
                React.createElement("ul", {className: className}, 
                    React.createElement(NavLink, {to: props.to, title: !prevPageDisabled ? 'Page ' + prevPageNum : '', disabled: prevPageDisabled, params: params, query: _.defaults({ page: prevPageNum}, props.query), "data-page": prevPageNum, onClick: me.onClick}, React.createElement("span", {dangerouslySetInnerHTML: { __html: '&laquo;'}})), 
                    _.map(range, function(i){
                        return React.createElement(NavLink, {key: i, title: 'Page ' + i, to: props.to, active: pageNum === i, params: params, query: _.defaults({ page: i}, props.query), "data-page": i, onClick: me.onClick}, i)
                    }), 
                    React.createElement(NavLink, {to: props.to, title: !nextPageDisabled ? 'Page ' + nextPageNum : '', disabled: nextPageDisabled, params: params, query: _.defaults({ page: nextPageNum}, props.query), "data-page": nextPageNum, onClick: me.onClick}, React.createElement("span", {dangerouslySetInnerHTML: { __html: '&raquo;'}}))
                ), 
                props.showTotal && (
                    React.createElement("div", {className: 'summary hidden-xs' + (props.noMargin ? ' no-margin margin-left-20' : '')}, totalText + ' ' + props.totalSuffixText)
                ), 
                this.props.children && (
                    React.createElement("div", {className: 'pull-right' + (props.noMargin ? ' no-margin margin-left-20' : ' margin-20 margin-right-0')}, 
                        this.props.children
                    )
                )
            )
        );
    },

    getNumPages: function() {
        var props = this.props,
            store = props.store,
            numPages = 1;

        if (store.getTotal() > 0) {
            numPages = Math.ceil(store.getTotal() / store.pageSize);
        }

        return numPages;
    },

    getStartPage: function() {
        var props = this.props,
            store = props.store,
            numPages = this.getNumPages(),
            numPagesToDisplay = props.numPagesToDisplay,
            startPage = store.pageNum - Math.floor(numPagesToDisplay / 2);

        if (startPage <= 0) {
            startPage = 1;
        } else if (startPage + numPagesToDisplay > numPages + 1) {
            startPage = numPages + 1 - numPagesToDisplay;
        }

        if(startPage < 1){
            startPage = 1;
        }

        return startPage;
    },

    getNumToDisplay: function() {
        var props = this.props,
            store = props.store,
            numPagesToDisplay = props.numPagesToDisplay,
            total = store.getTotal(),
            numToDisplay = numPagesToDisplay,
            numPages = this.getNumPages();

        if (this.getStartPage() + numToDisplay > numPages + 1) {
            if(numPages < numToDisplay){
                numToDisplay = numPages;
            } else {
                numToDisplay = numPages - this.getStartPage();
            }
        }

        if (numToDisplay < 1) {
            numToDisplay = 1;
        }

        return numToDisplay;
    },

    onClick: function(e) {
        if (this.props.onClick) {
            this.props.onClick(e, this);
        }
    }
});

module.exports = Pagination;
