"use strict";

var Collection = require('./Collection'),
    ProjectImages = require('./ProjectImages'),
    Constants = require('../constants'),
    TemplateImage = require('../models/TemplateImage');

var TemplateImages = Collection.extend({
    url: TemplateImage.prototype.urlRoot,
    model: TemplateImage,

    comparator: function(item) {
        var idx;
        switch (item.get('imageKey')) {
            case 'FIRST_IMAGE':
                idx = 0;
                break;
            case 'SECOND_IMAGE':
                idx = 1;
                break;
            case 'THIRD_IMAGE':
                idx = 2;
                break;
        }
        return idx;
    },

    fetch: function(options) {
        options = options || {};
        options.data = options.data || {};

        if (this.parent) {
            options.data.listingId = this.parent.id;
        }
        options.data.templateKey = this.templateKey || 'email';

        this.setupDefaults();

        return Collection.prototype.fetch.call(this, options);
    },

    setupDefaults: function() {
    	this.add([{
    		imageKey: 'FIRST_IMAGE',
    		listing: {
    			id: this.parent.id
    		},
    		templateKey: this.templateKey
    	},{
    		imageKey: 'SECOND_IMAGE',
    		listing: {
    			id: this.parent.id
    		},
    		templateKey: this.templateKey
    	},{
    		imageKey: 'THIRD_IMAGE',
    		listing: {
    			id: this.parent.id
    		},
    		templateKey: this.templateKey
    	}]);
    },

    parse: function(response, options) {
        var defaults = {
                templateKey: this.templateKey,
                listing: {
                    id: this.parent.id
                }
            },
            needsFirst = true,
            needsSecond = true,
            needsThird = true;

        response = response || {};
        response.list = response.list || [];

        for (var i = 0, len = response.list.length; i < len; i++) {
            switch (response.list[i].imageKey) {
                case 'FIRST_IMAGE':
                    needsFirst = false;
                    break;
                case 'SECOND_IMAGE':
                    needsSecond = false;
                    break;
                case 'THIRD_IMAGE':
                    needsThird = false;
                    break;
            }
        }

        if (needsFirst) {
            response.list.push(_.extend({
                imageKey: 'FIRST_IMAGE'
            }, defaults))
        }

        if (needsSecond) {
            response.list.push(_.extend({
                imageKey: 'SECOND_IMAGE'
            }, defaults))
        }

        if (needsThird) {
            response.list.push(_.extend({
                imageKey: 'THIRD_IMAGE'
            }, defaults))
        }

        return Collection.prototype.parse.call(this, response, options);
    }
});

module.exports = TemplateImages;
