"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    Attribute = require('../models/Attribute'),

    data = [
        /*{
                id: 'HAS_FEATURED',
                name: 'Featured'
            },*/
        {
            id: 'MIN_PRICE_ASC',
            name: 'Start Price: $ - $$'
        }, {
            id: 'MIN_PRICE_DESC',
            name: 'Start Price: $$ - $'
        }, {
            id: 'NAME_ASC',
            name: 'Name A - Z'
        }, {
            id: 'NAME_DESC',
            name: 'Name Z - A'
        }
        /*,{
                id: 'HAS_BUYER_SPECIAL',
                name: 'Buyer Specials'
            },{
                id: 'HAS_FLY_AND_BUY_SPECIAL',
                name: 'Fly & Buy Specials'
            }*/
    ];

var ProjectOrderBy = Collection.extend({
    model: Attribute,
    constructor: function(models, options) {
        models = data;
        return Collection.prototype.constructor.call(this, models, options);
    }
});

module.exports = ProjectOrderBy;
