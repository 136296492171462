"use strict";

var React = require("react/addons"),
  FluxBone = require("../../../mixins/FluxBone"),
  OpenSupport = require("../../../mixins/OpenSupport"),
  ReactRouter = require("react-router"),
  BS = require("react-bootstrap"),
  Cmp = require("../../../components"),
  Validation = require("../../../partials/validation"),
  MessagePartial = require("../../../partials/Message"),
  CurrentUser = require("../../../shared/CurrentUser"),
  Constants = require("../../../constants"),
  Projects = require("../../../collections/Projects"),
  Roles = require("../../../mixins/Roles"),
  $ = require("jquery"),
  _ = require("underscore"),
  moment = require("moment"),
  numeral = require("numeral"),
  Link = ReactRouter.Link,
  cx = require("classnames"),
  Pagination = require("../../../partials/Pagination");

var UpgradeAndBilling = React.createClass({displayName: "UpgradeAndBilling",
  statics: {
    willTransitionTo: function(transition, params) {
      if (
        CurrentUser.isLoggedIn() &&
        CurrentUser.hasRole(Constants.roles.LISTING_USER)
      ) {
        transition.redirect(params.id ? "Project" : "UserAccount", params);
      }
    },

    willTransitionFrom: function(transition, me, callback) {
      if (!me) {
        callback();
        return;
      }

      var props = me.props,
        continueAnyways,
        path = transition.path,
        isDirty = me.isDirty();

      if (
        isDirty &&
        path.indexOf("/users/myaccount/billing") !== 0 &&
        path.indexOf("/billing") !== 0
      ) {
        continueAnyways = window.confirm(props.dirtyMessage);
        if (continueAnyways) {
          me.resetServiceTypes();
          callback();
        } else {
          transition.cancel();
          me.goBack();
        }
      } else {
        callback();
      }
    }
  },

  mixins: [
    FluxBone.CollectionMixin("store", "sync change"),
    FluxBone.ModelMixin("project", "sync change"),
    ReactRouter.Navigation,
    Roles,
    OpenSupport
  ],

  contextTypes: {
    router: React.PropTypes.func
  },

  getDefaultProps: function() {
    return {
      store: new Projects(),
      readOnly: false,
      dirtyMessage: "There are unsaved changes. Are you sure you want to leave?"
    };
  },

  isDirty: function() {
    var me = this,
      props = me.props,
      store = props.store,
      project = props.project,
      store = props.store,
      isDirty = false;

    if (
      project &&
      (project
        .get("serviceTypes")
        .sort()
        .join("") !==
        project
          .get("defaultServiceTypes")
          .sort()
          .join("") ||
        project.get("active") !== project.get("defaultActive"))
    ) {
      isDirty = true;
    } else {
      store.some(function(project) {
        isDirty =
          project.get("serviceTypes").join("") !==
            project.get("defaultServiceTypes").join("") ||
          project.get("active") !== project.get("defaultActive");
        return isDirty;
      });
    }

    return isDirty;
  },

  resetServiceTypes: function() {
    var me = this,
      props = me.props;

    if (props.project) {
      props.project.set({
        serviceTypes: props.project.get("defaultServiceTypes"),
        active: props.project.get("defaultActive")
      });
    }

    if (props.store) {
      props.store.each(function(project) {
        project.set({
          serviceTypes: project.get("defaultServiceTypes"),
          active: project.get("defaultActive")
        });
      });
    }
  },

  updateState: function(props) {
    props = props || this.props;

    var query = this.context.router.getCurrentQuery(),
      store = props.store,
      fetching = false,
      thankyou = query.thankyou === "true",
      options = {
        data: {},
        fetchBilling: true
      };

    if (!CurrentUser.isAdmin()) {
      store.pageSize = undefined;
      // options.data.ownedOnly = true;
      options.data.developerId = CurrentUser.getEntity().id;
    } else {
      options.pageSize = 20;
    }

    if (!props.project && store.length === 0) {
      store.pageNum = query.page || 1;
      store.setFilters(_.omit(query, "thankyou"), {
        resetFilters: true
      });
      fetching = true;
      store.throttledFetch(options);
    }

    return _.extend(
      {
        thankyou: thankyou,
        cost: props.store.cost,
        savePrompt: query.save === "true",
        updatingPrice: fetching,
        newCost:
          props.store.lastProrate !== undefined
            ? props.store.lastProrate
            : props.store.cost
      },
      store.getFilterValues()
    );
  },

  getInitialState: function() {
    return this.updateState();
  },
  /**
   * This is to ensure that when subroutes transistion they trigger recheck router state and render
   */
  componentWillReceiveProps: function(newProps) {
    var state = this.updateState(newProps);

    this.setState(state);

    if (this.isMounted() && state.thankyou) {
      window.scrollTo(0, 0);
    }
  },

  render: function() {
    var me = this,
      props = me.props,
      state = me.state,
      route = this.getCurrentRoute(),
      params = this.context.router.getCurrentParams(),
      query = _.extend(
        this.context.router.getCurrentQuery(),
        props.store.getFilterValues()
      ),
      billingTo =
        route.name === "ProjectEditUpgradeAndBilling"
          ? "ProjectEditUpgradeAndBillingInfo"
          : "UserUpgradeAndBillingInfo",
      subscriptionDate = CurrentUser.billingModel.get("serviceSubscribeDate"),
      subscriptionDayOfMonth = subscriptionDate
        ? parseInt(subscriptionDate.format("D"))
        : undefined,
      isAdmin = CurrentUser.isAdmin(),
      cost = this.getCost();

    if (state.savePrompt) {
      var savedCost = this.getCost(true),
        currentDayOfMonth = parseInt(moment.utc().format("D"), 10),
        prorateDays,
        prorate;

      if (
        subscriptionDayOfMonth &&
        subscriptionDayOfMonth > currentDayOfMonth
      ) {
        prorateDays = subscriptionDayOfMonth - currentDayOfMonth;
      } else if (
        subscriptionDayOfMonth &&
        subscriptionDayOfMonth < currentDayOfMonth
      ) {
        prorateDays =
          moment.utc().daysInMonth() -
          currentDayOfMonth +
          subscriptionDayOfMonth;
      }

      prorate =
        cost > savedCost && prorateDays > 0
          ? Math.round(((cost - savedCost) / 31) * prorateDays)
          : 0;
    }

    return (
      React.createElement("div", {className: "row"}, 
        React.createElement("div", {className: "margin-bottom-20"}, 
          React.createElement(Link, {to: "Dashboard"}, 
            React.createElement(BS.Button, {bsStyle: "link"}, 
              React.createElement("i", {className: "fa fa-arrow-left"}), " Back to Dashboard"
            )
          )
        ), 

        state.thankyou ? (
          React.createElement("div", {className: "col-xs-12 col-sm-8 col-sm-push-2"}, 
            React.createElement(BS.Panel, {
              className: "panel-split-header", 
              header: React.createElement("h2", null, "Thank You!")
            }, 
              "Your changes have been completed. Someone from New Construction" + ' ' +
              "Hub will be in touch with you shortly. ", React.createElement("br", null), 
              React.createElement(Link, {
                to: route.name, 
                query: _.omit(query, "thankyou"), 
                params: params
              }, 
                "Return to Upgrade And Billing"
              ), 
              "."
            )
          )
        ) : state.savePrompt &&
          cost > 0 &&
          !CurrentUser.billingModel.get("cc").num &&
          !CurrentUser.getEntity().get("payWithCheck") ? (
          React.createElement("div", {className: "col-xs-12 col-sm-8 col-sm-push-2"}, 
            React.createElement(BS.Panel, {
              className: "panel-split-header", 
              header: React.createElement("h2", null, "Confirm Change!")
            }, 
              React.createElement("div", null, 
                React.createElement("div", {className: "margin-bottom-20"}, 
                  "Credit Card not on file. Please click", " ", 
                  React.createElement(Link, {to: billingTo, params: params}, 
                    "Add a Credit Card"
                  ), " ", 
                  "to continue."
                ), 
                React.createElement("div", {className: "text-center"}, 
                  React.createElement(Link, {to: billingTo, params: params}, 
                    React.createElement(BS.Button, null, "Add a Credit Card")
                  ), 
                  React.createElement("a", {className: "btn btn-link", onClick: this.cancelChange}, 
                    "Cancel"
                  )
                )
              )
            )
          )
        ) : state.savePrompt ? (
          React.createElement("div", {className: "col-xs-12 col-sm-8 col-sm-push-2"}, 
            React.createElement(BS.Panel, {
              className: "panel-split-header", 
              header: React.createElement("h2", null, "Confirm Change!")
            }, 
              React.createElement("div", null, 
                React.createElement("div", {className: "margin-bottom-20"}, 
                  "Please confirm the change to your account.", 
                  React.createElement("br", null), 
                  "Your new monthly total is ", cost, " USD.", 
                  React.createElement("br", null), 
                  "Your monthly renewal date is", " ", 
                  subscriptionDate
                    ? subscriptionDate.format("YYYY-MM-DD")
                    : moment.utc().format("YYYY-MM-DD"), 
                  ".", React.createElement("br", null), 
                  prorate
                    ? "Your will be charged a prorated amount of " +
                      prorate +
                      " USD now."
                    : ""
                ), 
                React.createElement("div", {className: "text-center"}, 
                  React.createElement(BS.Button, {
                    bsStyle: "primary", 
                    disabled: state.updatingPrice, 
                    onClick: this.saveUpgrades
                  }, 
                    "Confirm Change"
                  ), 
                  React.createElement("a", {className: "btn btn-link", onClick: this.cancelChange}, 
                    "Cancel"
                  )
                )
              )
            )
          )
        ) : (
          React.createElement("div", {className: "col-xs-12"}, 
            React.createElement("div", {className: "blog margin-bottom-20 "}, 
              React.createElement("h1", {className: "orange-header text-center margin-bottom-20"}, 
                React.createElement("span", null, "Simple, no risk pricing."), 
                React.createElement("br", null), 
                React.createElement("small", null, 
                  "We are here to earn your business. No contracts. Cancel at" + ' ' +
                  "anytime."
                )
              )
            ), 

            React.createElement("h5", null, 
              "All features are ", React.createElement("span", {className: "orange-text"}, "separate"), " and" + ' ' +
              "are ", React.createElement("span", {className: "orange-text"}, "independent of each other"), 
              ". Choose and pay for only the features and services that you need." + ' ' +
              "Contact us if you have any questions about pricing."
            ), 
            React.createElement("div", {className: "row pricing-table-v4"}, 
              React.createElement("div", {className: "col-md-4 col-sm-4 col-xs-6"}, 
                React.createElement("div", {className: "pricing-v4 pricing-v4-grey "}, 
                  React.createElement("div", {className: "pricing-v4-head"}, 
                    React.createElement("h4", {className: "text-center"}, 
                      React.createElement("small", {className: "light_text"}, "INCLUDED"), 
                      React.createElement("br", null), 
                      "Free Listing"
                    ), 
                    React.createElement("div", {className: "pricing-rounded"}, 
                      React.createElement("h5", {className: "text-center"}, 
                        React.createElement("span", null, "$"), "0", React.createElement("i", null, "Month")
                      )
                    ), 
                    React.createElement("a", {href: "#", className: "btn-u btn-u-grey"}, 
                      React.createElement("i", {className: "price-corner"}), 
                      React.createElement("i", {className: "price-corner-2"}), "INCLUDED"
                    )
                  ), 
                  React.createElement("ul", {className: "list-unstyled pricing-v4-content"}, 
                    React.createElement("li", null, 
                      "Would you like to receive free advertising on agent and" + ' ' +
                      "broker websites?", 
                      React.createElement("br", null), 
                      React.createElement("br", null), 
                      "Choose this option for a listing with images and" + ' ' +
                      "information about your community.", 
                      React.createElement("br", null), 
                      React.createElement("br", null), 
                      React.createElement("b", null, "Feature includes:")
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "icon-list"}), " Free Community Listing"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "icon-speedometer"}), " Basic Reporting"
                    )
                  )
                )
              ), 
              React.createElement("div", {className: "col-md-4 col-sm-4 col-xs-6"}, 
                React.createElement("div", {className: "pricing-v4 pricing-v4-grey"}, 
                  React.createElement("div", {className: "pricing-v4-head"}, 
                    React.createElement("h4", {className: "text-center"}, 
                      React.createElement("small", {className: "light_text"}, "UPGRADED PER LISTING"), 
                      React.createElement("br", null), 
                      "Website White Labeling"
                    ), 
                    React.createElement("div", {className: "pricing-rounded"}, 
                      React.createElement("h5", {className: "text-center"}, 
                        React.createElement("span", null, "$"), "200", React.createElement("i", null, "Month")
                      )
                    ), 
                    React.createElement("a", {href: "#", className: "btn-u btn-u-orange"}, 
                      React.createElement("i", {className: "price-corner"}), 
                      React.createElement("i", {className: "price-corner-2"}), 
                      "ADD-ON FEATURE"
                    )
                  ), 
                  React.createElement("ul", {className: "list-unstyled pricing-v4-content"}, 
                    React.createElement("li", null, 
                      "The Website White Labeling feature brands and personalizes" + ' ' +
                      "your project's website for the referring agent.", 
                      React.createElement("br", null), 
                      React.createElement("br", null), 
                      "Choose this feature if you would like to empower and" + ' ' +
                      "incentivize agents to promote and sell your new homes.", 
                      React.createElement("br", null), 
                      React.createElement("br", null), 
                      React.createElement("b", null, "Feature includes:")
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "icon-pin"}), " Website White Labeling"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "icon-speedometer"}), " Advance" + ' ' +
                      "Reporting"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "icon-envelope"}), " Lead Notifications"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "icon-briefcase"}), " Agent Portal"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("small", {className: "orange-text"}, 
                        "** Note: Requires $500 setup fee."
                      )
                    )
                  )
                )
              ), 
              React.createElement("div", {className: "col-md-4 col-sm-4 col-xs-6"}, 
                React.createElement("div", {className: "pricing-v4 pricing-v4-grey"}, 
                  React.createElement("div", {className: "pricing-v4-head"}, 
                    React.createElement("h4", {className: "text-center"}, 
                      React.createElement("small", {className: "light_text"}, "UPGRADED PER LISTING"), 
                      React.createElement("br", null), 
                      React.createElement("span", null, 
                        "Agent Branded", 
                        React.createElement("br", null), 
                        "Emails & Brochures"
                      )
                    ), 
                    React.createElement("div", {className: "pricing-rounded"}, 
                      React.createElement("h5", {className: "text-center"}, 
                        React.createElement("span", null, "$"), "175", React.createElement("i", null, "Month")
                      )
                    ), 
                    React.createElement("a", {href: "#", className: "btn-u btn-u-orange"}, 
                      React.createElement("i", {className: "price-corner"}), 
                      React.createElement("i", {className: "price-corner-2"}), "ADD-ON FEATURE"
                    )
                  ), 
                  React.createElement("ul", {className: "list-unstyled pricing-v4-content"}, 
                    React.createElement("li", null, 
                      "The Agent Branded Emails and Brochures feature" + ' ' +
                      "automatically create emails and brochures with your" + ' ' +
                      "community’s information but branded for agents.", 
                      React.createElement("br", null), 
                      React.createElement("br", null), 
                      "Choose this feature if you would like to provide emails" + ' ' +
                      "and brochures that agents and brokers can use to market to" + ' ' +
                      "their buyers.", 
                      React.createElement("br", null), 
                      React.createElement("br", null), 
                      React.createElement("b", null, "Feature includes:")
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "icon-share-alt"}), " Agent Branded" + ' ' +
                      "Emails"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "icon-docs"}), " Agent Branded" + ' ' +
                      "Brochures"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "icon-speedometer"}), " Advance" + ' ' +
                      "Reporting"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "icon-envelope"}), " Lead Notifications"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "icon-briefcase"}), " Agent Portal"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("small", {className: "orange-text"}, 
                        "** Note: Requires $500 setup fee."
                      )
                    )
                  )
                )
              )
            ), 

            React.createElement("h4", null, 
              "Your Listings:", " ", 
              React.createElement("small", null, 
                "Select the features for each listing. To deactivate a listing," + ' ' +
                "click on the \"Deactivate\" button."
              )
            ), 

            React.createElement("form", {className: "form sky-form", onSubmit: this.onSubmit}, 
              React.createElement(MessagePartial, {
                ref: "message", 
                dispatcher: props.project || props.store, 
                singleMessageOfType: true, 
                getMessage: this.getMessage}
              ), 
              isAdmin && !props.project && (
                React.createElement(Pagination, {
                  store: props.store, 
                  to: route.name, 
                  query: query, 
                  params: params}
                )
              ), 
              React.createElement(BS.Table, {borderless: true, condensed: true, className: "listing-levels"}, 
                React.createElement("thead", null, 
                  React.createElement("tr", null, 
                    React.createElement("th", null, 
                      "Feature:", React.createElement("div", null, "( Monthly price for each feature. )")
                    ), 
                    React.createElement("th", null, 
                      "Listing", React.createElement("div", null, "( Free )")
                    ), 
                    React.createElement("th", null, 
                      "Website White Labeling", React.createElement("div", null, "( $200 )")
                    ), 
                    React.createElement("th", null, 
                      "Agent Branded Emails & Brochures", React.createElement("div", null, "( $175 )")
                    ), 
                    /*<th>Marketing Services<div>( $1,600 )</div></th>*/
                    React.createElement("th", null)
                  )
                ), 
                React.createElement("tbody", null, 
                  props.project ? (
                    React.createElement("tr", null, 
                      React.createElement("td", null, props.project.get("name")), 
                      React.createElement("td", null, 
                        React.createElement(BS.Input, {
                          type: "checkbox", 
                          "aria-disabled": !props.project.isActive(), 
                          checked: props.project.isActive(), 
                          onClick: me.toggleActive.bind(null, props.project), 
                          onChange: me.toggleActive.bind(null, props.project)
                        }, 
                          React.createElement("i", null)
                        )
                      ), 
                      React.createElement("td", null, 
                        React.createElement(BS.Input, {
                          type: "checkbox", 
                          "aria-disabled": !props.project.isActive(), 
                          checked: props.project.hasServiceType("AGENT_PORTAL"), 
                          onClick: me.toggleServiceType.bind(
                            null,
                            props.project,
                            "AGENT_PORTAL"
                          ), 
                          onChange: me.toggleServiceType.bind(
                            null,
                            props.project,
                            "AGENT_PORTAL"
                          )
                        }, 
                          React.createElement("i", null)
                        )
                      ), 
                      React.createElement("td", null, 
                        React.createElement(BS.Input, {
                          type: "checkbox", 
                          "aria-disabled": !props.project.isActive(), 
                          checked: props.project.hasServiceType(
                            "PERSONALIZED_BROCHURES"
                          ), 
                          onClick: me.toggleServiceType.bind(
                            null,
                            props.project,
                            "PERSONALIZED_BROCHURES"
                          ), 
                          onChange: me.toggleServiceType.bind(
                            null,
                            props.project,
                            "PERSONALIZED_BROCHURES"
                          )
                        }, 
                          React.createElement("i", null)
                        )
                      ), 
                      /*<td><BS.Input type="checkbox" aria-disabled={!props.project.isActive()} checked={props.project.hasServiceType('MARKETING_SERVICES')} onClick={me.toggleServiceType.bind(null, props.project, 'MARKETING_SERVICES')} onChange={me.toggleServiceType.bind(null, props.project, 'MARKETING_SERVICES')}><i/></BS.Input></td>*/
                      React.createElement("td", null, 
                        React.createElement("button", {
                          className: cx(
                            "btn-u btn-u-ultra-narrow btn-u-inverted",
                            {
                              "btn-u-green": !props.project.isActive(),
                              "btn-u-red": props.project.isActive()
                            }
                          ), 
                          onClick: me.toggleActive.bind(null, props.project)
                        }, 
                          props.project.isActive() ? "Deactivate" : "Activate"
                        )
                      )
                    )
                  ) : (
                    props.store.map(function(project) {
                      var listingLevel = project.get("serviceLevel"),
                        isActive = project.isActive();

                      return (
                        React.createElement("tr", {key: project.id}, 
                          React.createElement("td", null, project.get("name")), 
                          React.createElement("td", null, 
                            React.createElement(BS.Input, {
                              type: "checkbox", 
                              "aria-disabled": !isActive, 
                              checked: isActive, 
                              onClick: me.toggleActive.bind(null, project), 
                              onChange: me.toggleActive.bind(null, project)
                            }, 
                              React.createElement("i", null)
                            )
                          ), 
                          React.createElement("td", null, 
                            React.createElement(BS.Input, {
                              type: "checkbox", 
                              "aria-disabled": !isActive, 
                              checked: project.hasServiceType("AGENT_PORTAL"), 
                              onClick: me.toggleServiceType.bind(
                                null,
                                project,
                                "AGENT_PORTAL"
                              ), 
                              onChange: me.toggleServiceType.bind(
                                null,
                                project,
                                "AGENT_PORTAL"
                              )
                            }, 
                              React.createElement("i", null)
                            )
                          ), 
                          React.createElement("td", null, 
                            React.createElement(BS.Input, {
                              type: "checkbox", 
                              "aria-disabled": !isActive, 
                              checked: project.hasServiceType(
                                "PERSONALIZED_BROCHURES"
                              ), 
                              onClick: me.toggleServiceType.bind(
                                null,
                                project,
                                "PERSONALIZED_BROCHURES"
                              ), 
                              onChange: me.toggleServiceType.bind(
                                null,
                                project,
                                "PERSONALIZED_BROCHURES"
                              )
                            }, 
                              React.createElement("i", null)
                            )
                          ), 
                          /*<td><BS.Input type="checkbox" aria-disabled={!isActive} checked={project.hasServiceType('MARKETING_SERVICES')} onClick={me.toggleServiceType.bind(null, project, 'MARKETING_SERVICES')} onChange={me.toggleServiceType.bind(null, project, 'MARKETING_SERVICES')}><i/></BS.Input></td>*/
                          React.createElement("td", null, 
                            React.createElement("button", {
                              className: cx(
                                "btn-u btn-u-ultra-narrow btn-u-inverted",
                                {
                                  "btn-u-green": !isActive,
                                  "btn-u-red": isActive
                                }
                              ), 
                              onClick: me.toggleActive.bind(null, project)
                            }, 
                              isActive ? "Deactivate" : "Activate"
                            )
                          )
                        )
                      );
                    })
                  )
                  /*
                                    <tr>
                                        <td colSpan="3" className="light_text">
                                            <p className="light_text" style={{ maxWidth: 440 }}>
                                                The billing cycle starts on the {numeral(subscriptionDayOfMonth).format('0o')} of each month. Your credit card will be charged for the monthly total each month. Any mid month changes in service level will be prorated. Those changes will be changed immediately.
                                            </p>
                                        </td>
                                        <td colSpan="2">Edit Credit Card</td>
                                        <td className="light_text">
                                            <span className="blue-text">{numeral(cost).format('$0,0[.]00')}</span> Monthly Total
                                            <br/>
                                            {(!props.project || !props.project.isReadOnly()) && (
                                                <BS.Button type="submit" className="btn-u btn-u-blue btn-u-inverted btn-u-ultra-narrow">Update</BS.Button>
                                            )}
                                        </td>
                                    </tr>
                                    */
                )
              ), 
              isAdmin && !props.project && (
                React.createElement(Pagination, {
                  store: props.store, 
                  to: route.name, 
                  noMargin: true, 
                  query: query, 
                  params: params}
                )
              ), 
              React.createElement("div", {className: "row light_text"}, 
                React.createElement("div", {className: "col-xs-12 col-md-6"}, 
                  React.createElement("p", {className: "light_text"}, 
                    "The billing cycle starts on the 1st day of each month. Your" + ' ' +
                    "credit card will be charged for the monthly total each" + ' ' +
                    "month. Any mid-month changes in service level will be" + ' ' +
                    "prorated. Those changes will be changed immediately."
                  )
                ), 
                React.createElement("div", {className: "col-xs-12 col-md-6"}, 
                  React.createElement("div", {className: "pull-right"}, 
                    React.createElement("span", {className: "blue-text"}, 
                      numeral(this.getCost()).format("$0,0[.]00")
                    ), " ", 
                    "Monthly Total", 
                    React.createElement("br", null), 
                    (!props.project || !props.project.isReadOnly()) && (
                      React.createElement(BS.Button, {
                        type: "submit", 
                        disabled: state.updatingPrice, 
                        className: "btn-u btn-u-blue btn-u-lg btn-u-inverted btn-u-ultra-narrow pull-right"
                      }, 
                        "Update"
                      )
                    )
                  ), 
                  React.createElement("div", {className: "pull-right"}, 
                    React.createElement("br", null), 
                    React.createElement("h3", {className: "margin-0 margin-right-10"}, 
                      React.createElement(Link, {to: billingTo}, 
                        React.createElement("i", {className: "fa fa-fw fa-credit-card"}), 
                        CurrentUser.billingModel.get("cc").num
                          ? "Edit"
                          : "Add", " ", 
                        "Credit Card"
                      )
                    )
                  )
                )
              )
            )
          )
        ), 
        React.createElement(BS.Modal, {
          show: !!me.state.showContactForm, 
          onHide: me.closeContactForm
        }, 
          React.createElement(BS.Modal.Header, {closeButton: true}), 
          React.createElement(BS.Modal.Body, null, 
            React.createElement("h2", null, "Contact Sales"), 
            React.createElement("p", null, 
              "Complete the form to contact sales about our marketing services."
            ), 
            React.createElement("div", {id: "hubspot-contact-form", className: "form"})
          )
        )
      )
    );
  },

  componentDidUpdate: function(prevProps, prevState) {
    if (
      this.state.showContactForm &&
      prevState.showContactForm !== this.state.showContactForm &&
      window.hbspt
    ) {
      hbspt.forms.create({
        portalId: "729019",
        formId: "17fb5f67-252e-4303-be39-5dbd84c41ff6",
        target: "#hubspot-contact-form",
        inlineMessage:
          "Thank you for contacting us. We will contact you shortly."
      });
    }
  },

  showContactForm: function() {
    this.setState({
      showContactForm: true
    });
  },

  closeContactForm: function() {
    this.setState({
      showContactForm: false
    });
  },

  toggleActive: function(project, e) {
    project.set({
      active: !project.isActive(),
      serviceTypes: []
    });

    if (e && e.preventDefault) {
      e.preventDefault();
    }

    this.updateCurrentPrice();
  },

  toggleServiceType: function(project, serviceType) {
    var serviceTypes = [].concat(project.get("serviceTypes") || []),
      serviceTypeIdx = serviceTypes.indexOf(serviceType);

    if (!project.isActive()) {
      return;
    }

    if (serviceTypeIdx === -1) {
      serviceTypes.push(serviceType);
    } else if (serviceTypeIdx === 0) {
      serviceTypes.shift();
    } else {
      serviceTypes.splice(serviceTypeIdx, 1);
    }

    project.set("serviceTypes", serviceTypes);

    this.updateCurrentPrice();
  },

  onSubmit: function(e) {
    var me = this,
      updatingProjects = [],
      router = this.context.router,
      routes = router.getCurrentRoutes(),
      route = routes[routes.length - 1],
      props = me.props,
      saveOptions = {
        validate: false,
        fields: ["serviceTypes", "name", "active"]
      },
      billingTo =
        route.name === "ProjectEditUpgradeAndBilling"
          ? "ProjectEditUpgradeAndBillingInfo"
          : "UserUpgradeAndBillingInfo";

    e && e.preventDefault();

    if (
      !CurrentUser.billingModel.get("last4") &&
      this.getCost() > 0 &&
      !CurrentUser.getEntity().get("payWithCheck")
    ) {
      this.transitionTo(billingTo, undefined, { save: true });
    } else {
      this.transitionTo(
        route.name,
        router.getCurrentParams(),
        _.extend(router.getCurrentQuery(), { save: true })
      );
      window.scrollTo(0, 0);
    }
  },

  cancelChange: function() {
    var router = this.context.router,
      routes = router.getCurrentRoutes(),
      route = routes[routes.length - 1];

    this.resetServiceTypes();

    if (window.Intercom) {
      window.Intercom("trackEvent", "upgrade-canceled");
    }
    if (window.analytics) {
      analytics.track("upgrade-canceled");
    }

    this.transitionTo(
      route.name,
      router.getCurrentParams(),
      _.extend({}, router.getCurrentQuery(), { save: undefined })
    );
  },

  saveUpgrades: function() {
    var me = this,
      props = me.props,
      promise;

    if (props.project) {
      if (
        props.project.get("defaultServiceTypes") !==
          props.project.get("serviceTypes") ||
        props.project.get("active") !== props.project.get("defaultActive")
      ) {
        promise = props.store.saveBilling([props.project]);
      }
    } else {
      promise = props.store.saveBilling();
    }

    if (promise) {
      promise.then(function() {
        var route = me.getCurrentRoute(),
          params = me.context.router.getCurrentParams(),
          query = _.extend(
            me.context.router.getCurrentQuery(),
            props.store.getFilterValues()
          );

        me.setState({
          cost: props.store.lastProrate,
          newCost: undefined
        });

        if (window.Intercom) {
          window.Intercom("trackEvent", "upgrade-updated");
        }

        if (window.analytics) {
          analytics.track("upgrade-updated");
        }

        me.transitionTo(
          route.name,
          params,
          _.extend({}, query, { thankyou: true, save: undefined })
        );
      });
    }
  },

  getCost: function(savedCost) {
    var me = this,
      cost =
        savedCost || me.state.newCost === undefined
          ? me.state.cost
          : me.state.newCost;

    return cost || 0;
  },

  getMessage: function(type) {
    var message;

    if (type === "success") {
      message = "Your listing level has been successfully updated.";
    }

    return message;
  },

  componentDidMount: function() {
    if (
      window.Intercom &&
      this.hasOneRole(Constants.roles.BUILDER, Constants.roles.DEVELOPER)
    ) {
      window.Intercom("trackEvent", "VIEWPAGE-LISTINGUPGRADE");
    }
    if (
      window.analytics &&
      this.hasOneRole(Constants.roles.BUILDER, Constants.roles.DEVELOPER)
    ) {
      analytics.track("VIEWPAGE-LISTINGUPGRADE");
    }

    this.props.store.on("sync reset", this.onSyncReset);
  },

  componentWillUnmount: function() {
    this.props.store.off("sync reset", this.onSync);
  },

  onSyncReset: function() {
    this.setState({
      cost: this.props.store.cost,
      newCost: this.props.store.cost,
      updatingPrice: false
    });
  },

  getCurrentRoute: function() {
    var routes = this.context.router.getCurrentRoutes();

    return routes[routes.length - 1];
  },

  updateCurrentPrice: function(getOrginalCost) {
    var me = this,
      store = me.props.store;

    me.setState({
      updatingPrice: true
    });

    if (store.length > 0) {
      store
        .fetchProrate({ abortXhr: true, getOrginalCost: getOrginalCost })
        .then(function(response) {
          if (getOrginalCost) {
            me.setState({
              cost: response.total
            });
          } else {
            me.setState({
              newCost: response.total
            });
          }
        })
        .always(function() {
          me.setState({
            updatingPrice: false
          });
        });
    }
  }
});
module.exports = UpgradeAndBilling;
