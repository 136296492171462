"use strict";

var Attribute = require('./Attribute'),
    Constants = require('../constants'),
    Developer = require('./Developer');

var Amenity = Attribute.extend({
    urlRoot : Constants.restPath + '/amenities',
    validation : {
        name : {
            required : false
        }
    },
    hasOne: {
        Developer: {
            responseKey: 'owner'
        }
    }
}, {
    Developer: Developer
});

module.exports = Amenity;
