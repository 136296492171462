"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    FluxBone = require('../mixins/FluxBone'),
    Widgets = require('../collections/Widgets'),
    Validation = require('../partials/validation');

var WidgetSelect = React.createClass({displayName: "WidgetSelect",

    mixins: [
        FluxBone.CollectionMixin('store', 'sync')
    ],

    getDefaultProps: function() {
        var store = new Widgets();
        store.pageSize = null;
        // store.disableFilters = true;
        return {
            store: store,
            defaultText: 'Select',
            trigger: "onChange",
            checkOnSync: true
        };
    },

    componentWillMount: function() {
        var props = this.props;
        if (props.agent && props.store.length === 0) {
            props.store.fetch({
                agentEmail: props.agent.contactInfoModel.get('email')
            });
        }
    },

    componentWillReceiveProps: function(newProps) {
        var me = this,
            props = this.props;

        if (newProps.disabled && newProps.disabled !== props.disabled && newProps.valueLink) {
            newProps.valueLink.requestChange('');
        }

        if (!props.agent && me.getValue()) {
            this.resetDeferred();
        } else if (newProps.agent !== props.agent) {
            props.store.fetch({
                agentEmail: newProps.agent.contactInfoModel.get('email')
            }).then(function() {
                var value = me.getValue();

                if (value && me.props.checkOnSync && me.props.valueLink && !me.props.store.get(value)) {
                    me.props.valueLink && me.props.valueLink.requestChange('');
                }
            });
        }
    },

    render: function() {
        var props = this.props;

        return (
            React.createElement(Validation.Input, React.__spread({ref: "input", label: "Widgets:"},  props, {type: "select"}), 
                React.createElement("option", {value: ""}, props.defaultText), 
                props.store.map(function(developer){
                    var data = developer.toJSON();
                    return (
                        React.createElement("option", {key: data.id, value: data.id}, data.name + ' (' + data.widgetType.name + ')')
                    );
                })
            )
        );
    },

    getDisplayValue: function() {
        var value = this.refs.input.getValue(),
            id = value ? parseInt(value) : undefined,
            model,
            displayValue;

        if (id) {
            model = this.props.store.get(id);
        }

        if (model) {
            displayValue = model.get('name') + ' (' + model.get('widgetType').name + ')';
        }

        return displayValue
    },

    resetDeferred: function() {
        var me = this;
        this.resetTimeout = setTimeout(function() {
            me.props.store.reset();
            me.props.valueLink && me.props.valueLink.requestChange('');
        }, 100)
    },

    clearReset: function() {
        clearTimeout(this.resetTimeout);
        delete this.resetTimeout;
    },

    getValue: function() {
        return this.refs.input ? this.refs.input.getValue() : undefined;
    },

    getWidgetById: function(id){
        return this.props.store.get(parseInt(id, 10));
    }
});

module.exports = WidgetSelect;
