"use strict";

var React = require('react'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    ReactRouterBootstrap = require('react-router-bootstrap'),
    ButtonLink = ReactRouterBootstrap.ButtonLink,
    DashboardItem = require('../../partials/DashboardItem'),
    ListingModelType = require('../../mixins/ListingModelType'),
    CurrentUser = require('../../shared/CurrentUser');

var ListingDashboard = React.createClass({displayName: "ListingDashboard",

    mixins: [
        ListingModelType.ListingNavigation('Dashboard')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        return {};
    },

    getInitialState: function() {
        return this.updateState();
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        var me = this,
            props = me.props,
            model = props.model,
            linkParam = {
                id: model.id
            },
            isDeveloper = me.isDeveloper(),
            headerPrefix = isDeveloper ? 'Builder' : 'Listing',
            toPrefix = isDeveloper ? 'Builder' : 'Project';

        return (
            React.createElement("div", null, 
                React.createElement("h1", null, headerPrefix + ' Dashboard: ' + (model.get('name') || '')), 
                React.createElement("h4", null, "Welcome to the dashboard for your listing. What would you like to do today?"), 
                React.createElement("div", {className: "row"}, 
                    React.createElement(DashboardItem, {small: true, iconClass: "fa-file-text-o", linkParam: linkParam, heading: headerPrefix + ' Information', to: toPrefix + 'EditDetails'}), 

                    React.createElement(DashboardItem, {small: true, iconClass: "fa-picture-o", linkParam: linkParam, heading: headerPrefix + ' Images', to: toPrefix + 'EditImages'}), 

                    React.createElement(DashboardItem, {small: true, iconClass: "fa-file-image-o", linkParam: linkParam, heading: headerPrefix + ' Floor Plans', to: toPrefix + 'EditFloorPlans'}), 

                    React.createElement(DashboardItem, {small: true, iconClass: "fa-list-ol", linkParam: linkParam, heading: headerPrefix + ' Amenities', to: toPrefix + 'EditAmenities'})
                ), 
                React.createElement("div", {className: "row"}, 
                    React.createElement(DashboardItem, {small: true, iconClass: "fa-copy", linkParam: linkParam, heading: headerPrefix + ' Documents', to: toPrefix + 'EditDocuments'}), 

                    React.createElement(DashboardItem, {small: true, iconClass: "fa-usd", linkParam: linkParam, heading: headerPrefix + ' Promotions', to: toPrefix + 'EditPromotions'}), 

                    React.createElement(DashboardItem, {small: true, iconClass: "fa-users", linkParam: linkParam, heading: headerPrefix + ' Outreach Tools', to: toPrefix + 'EditAPIKey'}), 

                    React.createElement(DashboardItem, {small: true, iconClass: "fa-line-chart", linkParam: linkParam, heading: headerPrefix + ' Reports', to: toPrefix + 'Reports'})
                )
            )
        );
    }
});
module.exports = ListingDashboard;
