"use strict";

var React = require('react'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    FluxBone = require('../../mixins/FluxBone'),
    AgentModel = require('../../models/Agent'),
    BrokerModel = require('../../models/Broker'),
    Validation = require('../../partials/validation'),
    WidgetSelect = require('../../components/WidgetSelect'),
    SaveButton = require('../../components/buttons/SaveButton'),
    ValidationMessage = require('../../components/ValidationMessage'),
    LocationSelect = require('../../components/LocationSelect'),
    utility = require('../../shared/Utilities');

var BrokerAddEdit = React.createClass({displayName: "BrokerAddEdit",

    mixins: [
        FluxBone.ModelMixin('broker', 'request sync reset')
    ],

    updateState: function(props) {
        var id;

        props = props || this.props;
        id = props.brokerId;

        props.model.clear();
        props.model.set({
            broker: {
                id: id
            },
            defaultBrokerWidget: {
                id: undefined
            }
        });

        if (id && (props.model.id !== id)) {
            props.broker.clear();
            props.broker.set('id', id);
            props.broker.fetch();
        }

        return {
            id: id
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        var model = new AgentModel();

        model.validation = {
            "contactInfo.firstName": {
                required: true,
                pattern: 'no_numbers'
            },

            "contactInfo.lastName": {
                required: true,
                pattern: 'no_numbers'
            },

            "user.email": {
                required: true,
                pattern: 'email'
            },

            "contactInfo.phoneWork": {
                required: true
            },

            "contactInfo": utility.locationValidationNoAddress


            // "defaultBrokerWidget.id": {
            //     required: true
            // }
        };

        model.labels = {
            "contactInfo.firstName": 'First Name',
            "contactInfo.lastName": 'Last Name',
            "user.email": "Email",
            "contactInfo.phoneWork": "Phone",
            "defaultBrokerWidget.id": "Default Widget"
        };

        return {
            model: model,
            broker: new BrokerModel()
        };
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function(nextProps) {
        this.setState(this.updateState(nextProps));
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            isLoading = props.broker.isLoading(),
            defaultControlAttr = {
                type: 'text',
                labelClassName: 'col-xs-12 col-md-3',
                wrapperClassName: 'col-xs-12 col-md-9'
            };

        return (
            React.createElement("div", null, 
                React.createElement("h2", {className: "text-center"}, "Create a new Agent for ", props.broker.get('brokerageName')), 
                props.broker.id ? (
                    React.createElement(BS.Panel, null, 
                        isLoading ? (
                            React.createElement(BS.Alert, {bsStyle: "success", className: "no-margin"}, 
                                "Broker Loading..."
                            )
                        ) : (
                            React.createElement(Validation.Form, {model: props.model, ref: "form", className: "margin-bottom-15", onSubmit: this.onSubmit, validateOnLoad: false}, 
                                React.createElement("div", {className: "form-group"}, 
                                    React.createElement("div", {className: "control-label col-xs-12 col-md-3"}, "Brokerage Name:"), 
                                    React.createElement("div", {className: "form-control-static col-xs-12 col-md-9"}, props.broker.get('brokerageName'))
                                ), 
                                React.createElement(Validation.Input, React.__spread({name: "contactInfo.firstName", label: "First Name:"},  defaultControlAttr)), 
                                React.createElement(Validation.Input, React.__spread({name: "contactInfo.lastName", label: "Last Name:"},  defaultControlAttr)), 
                                React.createElement(Validation.Input, React.__spread({name: "user.email", label: "Email:"},  defaultControlAttr)), 
                                React.createElement(Validation.Input, React.__spread({name: "contactInfo.phoneWork", label: "Phone Number:"},  defaultControlAttr)), 
                                React.createElement(Validation.Input, React.__spread({name: "website", label: "Website:"},  defaultControlAttr)), 
                                React.createElement("div", null, 
                                    React.createElement(LocationSelect, React.__spread({ref: "contactInfo", name: "contactInfo", label: "Location:"},  defaultControlAttr)), 
                                    React.createElement(Validation.Input, React.__spread({name: "contactInfo.address2", label: "Unit / Suite:"},  defaultControlAttr))
                                ), 
                                React.createElement(WidgetSelect, React.__spread({name: "defaultBrokerWidget.id", label: "Default Widget:"},  defaultControlAttr, {ref: "widgetSelect", defaultText: "Broker Default", agent: props.broker})), 
                                React.createElement(ValidationMessage, {action: "Create"}), 
                                React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                                    React.createElement(SaveButton, {className: "pull-right", label: "Create Agent"})
                                )
                            )
                        )
                    )
                ) : (
                    React.createElement(BS.Panel, null, 
                        React.createElement(BS.Alert, {bsStyle: "danger", className: "no-margin"}, 
                            "No brokerId specified!"
                        )
                    )
                )
            )
        );
    },

    onSubmit: function() {
        var me = this;

        me.props.model.save(me.refs.form.getValues()).then(function() {
            me.props.model.clear();
            me.props.model.set({
                broker: {
                    id: me.props.brokerId
                },
                defaultBrokerWidget: {
                    id: undefined
                }
            });
            
            // Force refresh of form
            me.props.broker.fetch();
        });
    }
});

module.exports = BrokerAddEdit;
