"use strict";

var React = require('react/addons'),
    FluxBone = require('../../../mixins/FluxBone'),
    OpenSupport = require('../../../mixins/OpenSupport'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    Cmp = require('../../../components'),
    Validation = require('../../../partials/validation'),
    User = require('../../../models/User'),
    CurrentUser = require('../../../shared/CurrentUser'),
    Link = ReactRouter.Link;

var UserAccountEmail = React.createClass({displayName: "UserAccountEmail",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset'),
        ReactRouter.Navigation,
        OpenSupport
    ],

    contextTypes: {
        router: React.PropTypes.func
    },


    getInitialState: function() {
        return {};
    },

    componentWillMount: function() {
        this.props.model.changingEmail = true;
    },

    componentWillUnmount: function() {
        this.props.model.changingEmail = false;
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            fieldAttrs = {
                labelClassName: 'col-md-3',
                type: 'text',
                trigger: "onChange",
                wrapperClassName: 'col-md-9'
            },
            isReadOnly = props.model.getEntity() ? props.model.getEntity().isReadOnly() : false;

        return (
            React.createElement("div", {className: "row"}, 
                React.createElement("div", {className: "col-xs-12"}, 
                    isReadOnly && (
                        React.createElement(BS.Alert, {bsStyle: "danger"}, 
                            "This form is a ", React.createElement("b", null, "READ ONLY"), ". Please ", React.createElement("a", {href: "#", onClick: this.openSupport}, "CLICK HERE"), " to contact us with questions or to request changes."
                        )
                    ), 

                    React.createElement(Validation.Form, {ref: "form", model: props.model, readOnly: isReadOnly, onSubmit: this.onSaveClick, getMessage: this.getMessage, validateOnly: ['email', 'username', 'confirmEmail']}, 
                        React.createElement("h1", null, "My Email:"), 
                        React.createElement("p", null, "You can change your email address below. Only 1 New Construction Hub account per email address."), 

                        React.createElement(Validation.Input, React.__spread({},  fieldAttrs, {validateAlso: "confirmEmail", name: "email", label: "Email:"})), 
                        React.createElement(Validation.Input, React.__spread({},  fieldAttrs, {validateAlso: "email", name: "confirmEmail", label: "Confirm Email:"})), 

                        React.createElement(Cmp.ValidationMessage, null), 
                        React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                            React.createElement(Cmp.Button.Cancel, {to: "UserAccountDashboard"}), 
                            React.createElement(Cmp.Button.Save, {className: "pull-right"})
                        )
                    )
                )
            )
        );
    },
    
    getMessage: function(status, model, xhr) {
        if (status === "success") {
            return "Success! Your email was updated successfully";
        } else {
            if (xhr.status === 409) {
                return (
                    React.createElement("span", null, 
                        "The email ", React.createElement("b", null, this.props.model.get("email")), " is already in use with another New Construction Hub account."
                    )
                );
            } else {
                return (
                    React.createElement("span", null, "Cancelled! ", React.createElement("br", null), "You email change was cancelled. No changes were made to your account.")
                );
            }
        }
    },

    onSaveClick: function(e) {
        var me = this,
            props = this.props,
            state = this.state,
            values = me.refs.form.getValues(),
            email = values.email;

        me.props.model.save(values, {
            fields: ['username', 'email', 'confirmEmail']
        }).then(function() {
            CurrentUser.set("username", email);
            CurrentUser.set("email", email);
        });
    }
});
module.exports = UserAccountEmail;
