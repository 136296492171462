    _ = require('underscore');

var icons =	{
		'fa-file-excel-o' : ['xls', 'xlsx', 'ods'],
		'fa-file-pdf-o' : ['pdf'],
		'fa-file-audio-o' : ['mp3'],
		'fa-file-word-o' : ['doc', 'docx', 'odt'],
		'fa-file-archive-o' : ['rar', 'zip', 'arj', 'tar'],
		'fa-file-image-o' : ['jpg', 'png'],
		'fa-file-text-o' : ['txt'],
		'fa-file-video-o' : ['mp4, mov, avi'],
		'fa-file-powerpoint-o' : ['ppt']
	};


var FileNameUtils = {
	fileNameToFaIcon : function(filename){
		var parts = filename.split(/\./),
			fileExt = parts[parts.length - 1],
			found = false,
			result = 'fa-file-o';

		_.map(icons, function(extensions, className){
			_.map(extensions, function(ext){
				if (ext == fileExt){
					result = className;
					found = true;
					return false;
				}
			});
			if (found){
				return false;
			}
		});

		return result;
	}
}

module.exports = FileNameUtils;