"use strict";

var User = require('../models/User'),
    Constants = require('../constants'),
    Cookies = require('cookies-js'),
    $ = require('jquery'),
    _ = require('underscore'),
    URI = require('urijs'),
    Billing = require('../models/Billing'),
    Payment = require('payment');

var CurrentUser = User.extend({
    url: Constants.restPath + '/users/current',
    loginUrl: Constants.restPath + '/login',
    subscriptionUrl: Constants.restPath + '/users/current/billing/subscription',
    hasOne: _.defaults({
        Billing: {
            responseKey: 'billing',
            alwaysInclude: true,
            triggerSync: true
        }
    }, User.prototype.hasOne),
    validation: _.extend({
        'billing.cc.num': Billing.prototype.validation['cc.num'],
        'billing.cc.exp': Billing.prototype.validation['cc.exp'],
        'billing.cc.cvc': Billing.prototype.validation['cc.cvc'],
        'billing.cc.name': Billing.prototype.validation['cc.name']
    }, User.prototype.validation),
    labels: _.extend({
        'billing.cc.num': Billing.prototype.labels['cc.num'],
        'billing.cc.exp': Billing.prototype.labels['cc.exp'],
        'billing.cc.cvc': Billing.prototype.labels['cc.cvc'],
        'billing.cc.name': Billing.prototype.labels['cc.name']
    }, User.prototype.labels),
    validationUses: _.extend({}, User.prototype.validationUses, Billing.prototype.validationUses),

    defaults: function() {
        var defaults = User.prototype.defaults.apply(this, arguments);
        defaults.payment = {};
        return defaults;
    },
    isLoaded: function() {
        return !!this.id;
    },
    isLoggedIn: function() {
        return !!Cookies.get('tss-cookie') || this.isRemembered();
    },
    isRemembered: function() {
        return !!Cookies.get('rememberMe');
    },
    impersonateUser: function(username) {
        var me = this;

        if (me.hasRole(Constants.roles.ADMIN)) {
            me.clear();

            if (window.Intercom) {
                Intercom('shutdown');
            }

            return me.fetch({
                impersonate: username
            });
        }
    },

    toJSON: function(options) {
        var data = User.prototype.toJSON.apply(this, arguments);

        options = options || {};

        if (!options.url) {
            if (this.developerModel && this.developerModel.id) {
                data.payWithCheck = this.developerModel.get('payWithCheck');
            }
        } else {
            // Fix empty coupon code string causing backend issues.
            if (!(data.coupon || '').trim()) {
                delete data.coupon;
            }
        }

        return data;
    },

    fetch: function(options) {
        var me = this,
            promise = $.Deferred();

        options = options || {};

        if (options.impersonate) {
            options.url = Constants.restPath + '/users/runas';
            options.data = options.data || {};
            options.data.username = options.impersonate;
        }

        User.prototype.fetch.call(me, options).then(function() {
            if (options.impersonate) {
                User.prototype.fetch.call(me).then(function() {
                    promise.resolveWith(me, arguments);
                }).fail(function() {
                    promise.rejectWith(me, arguments);
                });
            } else {
                promise.resolveWith(me, arguments);
            }
        }).fail(function() {
            promise.rejectWith(me, arguments);
        });

        return promise;
    },

    revertImpersonation: function() {
        return this.destroyImpersonation();
    },

    destroyImpersonation: function() {
        var me = this,
            promise = $.Deferred();

        if (me.get('actualId')) {
            me.clear();

            me.fetch({
                url: Constants.restPath + '/users/releaserunas'
            }).then(function() {
                me.fetch().then(function() {
                    promise.resolveWith(me, arguments);
                }).fail(function() {
                    promise.rejectWith(me, arguments);
                });
            }).fail(function() {
                promise.rejectWith(me, arguments);
            });
        } else {
            promise.resolveWith(me);
        }

        return promise;
    },

    intercomLogin: function() {
        var me = this,
            firstName = this.getFirstName(),
            lastName = this.getLastName(),
            fullName = this.getFullName(true),
            email = this.get('email'),
            entity = this.getEntity(),
            contactInfoModel = entity.contactInfoModel ? entity.contactInfoModel.toJSON() : {
                city: { state: {}, country: {} }
            },
            identify;

        if (firstName || lastName) {
            identify = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                city: contactInfoModel.city.name,
                state: contactInfoModel.city.state.name,
                country: contactInfoModel.city.country.name,
                brokerage_name: entity.get('brokerageName')
            };
        } else {
            identify = {
                firstName: fullName || email,
                lastName: '',
                email: email,
                city: contactInfoModel.city.name,
                state: contactInfoModel.city.state.name,
                country: contactInfoModel.city.country.name,
                brokerage_name: entity.get('brokerageName')
            };
        }

        // if (window.Intercom && !(this.hasRole(Constants.roles.ADMIN) || !!this.get('actualId'))) {
        //     Intercom('boot', {
        //         app_id: this.hasRole(Constants.roles.AGENT) ? Constants.intercomAppIdAgent : Constants.intercomAppId,
        //         email: this.get('email'),
        //         // name: this.getFullName(true),
        //         user_id: this.id,
        //         url: window.location.href
        //             /*,
        //                             widget: {
        //                                 activator: '#IntercomDefaultWidget'
        //                             }*/
        //     });
        // }

        if (window.Rollbar && !this.get('actualId')) {
            Rollbar.configure({
                payload: {
                    person: {
                        id: this.id,
                        name: fullName,
                        email: email
                    }
                }
            });
        }

        if (window.FreshWidget) {
            $('body').addClass('display-fresh-widget');
            $(FreshWidget.iframe()).attr('scrolling', 'no');
        }

        if (window.zE && zE.identify && !this.get('actualId')) {
            $('body').addClass('display-ze-widget');
            zE(function() {
                var info = {
                        name: fullName,
                        email: email
                    },
                    type = me.getType();

                if ((type === 'Agent' || type === 'Broker') && me.getEntity().get('agentLevel') === 'PRO') {
                    info.organization = 'NCH Premium Agent'
                }

                zE.identify(info);
            });
        }

        if (window.analytics && !this.get('actualId')) {
            analytics.identify(this.id, identify);
            analytics.page();
        }
    },
    login: function(username, password, rememberMe, blockFetch, blockIntercom) {
        var me = this,
            promise = $.Deferred(),
            jqXHRPromise;

        me.clear();

        me.set({
            username: username
        }, {
            silent: true
        });

        jqXHRPromise = me.fetch({
            type: 'POST',
            url: me.loginUrl,
            data: {
                username: username || me.get('username'),
                password: password,
                rememberMe: rememberMe || me.get('rememberMe')
            }
        }).then(function() {
            promise.resolveWith(me, arguments);
            if (!blockIntercom) {
                me.intercomLogin();
            }
        }).fail(function() {
            promise.rejectWith(me, arguments);
        });

        return promise;
    },
    logout: function() {
        var me = this;
        return me.fetch({
            url: Constants.restPath + '/logout'
        }).always(function(response, options) {
            me.destroyCookie();
            me.clear();
            me.trigger('sync', me, response, options);

            if (window.Intercom) {
                Intercom('shutdown');
            }

            if (window.analytics) {
                analytics.reset();
            }

            if (window.FreshWidget) {
                FreshWidget.close();
                $('body').removeClass('display-fresh-widget');
            }

            if (window.zE && window.zE.hide) {
                $('body').removeClass('display-ze-widget');
                zE.hide();
                zE.logout();
            }
        });
    },
    destroyCookie: function() {
        Cookies.expire('tss-cookie');
        Cookies.expire('rememberMe');
    },

    save: function(attributes, options) {
        options = options || {};
        options.url = options.url || Constants.restPath + '/users/' + (this.id);

        return User.prototype.save.call(this, attributes, options);
    },

    getTrackerUrl: function() {
        var id = this.agentModel.id || this.id;
        return URI(Constants.trackingPath).addSearch({
            as: id
        });
    },

    getEditUrl: function() {
        return URI(Constants.server).path('users/myaccount');
    },

    checkIfUserExists: function(email) {
        var me = this,
            promise = $.Deferred(),
            jqXHRPromise = $.ajax({
                suppressError: true,
                type: 'POST',
                url: Constants.restPath + '/users/validate',
                data: {
                    email: email
                }
            });

        jqXHRPromise.then(function(data, textStatus, jqXHR) {
            promise.resolveWith(me, [jqXHR.status !== 204]);
        }).fail(function(jqXHR, textStatus, errorThrown) {
            promise.resolveWith(me, [true]);
        });

        return promise;
    },

    setSubscription: function(level, attributes, options) {
        var me = this,
            entity = me.getEntity(),
            defaultAgentLevel = entity.get('defaultAgentLevel'),
            method;

        switch (level) {
            case 'PRO':
            case 'BASIC':
                method = 'POST';
                break;
            case 'FREE':
                method = 'DELETE';
                break;
        }

        options = _.defaults(options || {}, {
            url: me.subscriptionUrl,
            method: method,
            fields: []
        });

        return me.save(attributes, options).then(function() {
            entity.setSubscription(level);
        });
    },

    track: function() {
        if (window.analytics && !(this.hasRole(Constants.roles.ADMIN) || !!this.get('actualId'))) {
            analytics.page();
        }
    }
}, {
    Billing: Billing
});

_.defaults(CurrentUser, User);

var SharedUser = new CurrentUser();
/*
SharedUser.fetch().fail(function() {
    SharedUser.destroyCookie();
});
*/


module.exports = SharedUser;
