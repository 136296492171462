"use strict";

var React = require('react'),
    ReactRouter = require('react-router'),
    Constants = require('./constants'),
    DefaultRoute = ReactRouter.DefaultRoute,
    Route = ReactRouter.Route,
    NotFoundRoute = ReactRouter.NotFoundRoute,
    RouteHandler = ReactRouter.RouteHandler,
    _ = require('underscore'),
    CurrentUser = require('./shared/CurrentUser');

var App = require('./views/Application'),
    Dashboard = require('./views/dashboard'),
    Project = require('./views/project'),
    Agent = require('./views/agent'),
    Broker = require('./views/broker'),
    Developer = require('./views/developer'),
    CurrentUserViews = require('./views/currentuser'),
    Lead = require('./views/lead'),
    User = require('./views/user'),
    Widget = require('./views/widget'),
    Report = require('./views/reports/Report'),
    ListingInfo = require('./views/listing/ListingInfo'),
    NotFound = require('./views/NotFound'),
    Handler = require('./views/Handler'),
    DevelopmentUnavailable = require('./views/DevelopmentUnavailable'),
    Error404 = require('./views/errors/404'),
    UserAccount = CurrentUserViews.Account;

var routes = (
    React.createElement(Route, {handler: App, path: Constants.root}, 
        React.createElement(DefaultRoute, {handler: Dashboard, name: "Dashboard"}), 

        React.createElement(Route, {handler: CurrentUserViews.Login, name: "Login", path: "login"}), 
        React.createElement(Route, {handler: CurrentUserViews.ForgotPassword, name: "ForgotPassword", path: "forgotpassword"}), 
        React.createElement(Route, {handler: CurrentUserViews.ResetPassword, name: "ResetPassword", path: "resetpassword/:token"}), 

        React.createElement(Route, {handler: CurrentUserViews.Signup.Container, name: "Signup", path: "signup"}, 
            React.createElement(DefaultRoute, {handler: CurrentUserViews.Signup.EmailCheck, name: "SignupEmailCheck"}), 
            React.createElement(Route, {handler: CurrentUserViews.Signup.Create, name: "SignupCreate", path: "create/:email"}), 
            React.createElement(Route, {handler: CurrentUserViews.Signup.AgentDefaultWidget, name: "SignupWidget", path: "widgets/:id"}), 
            React.createElement(Route, {handler: Widget.Help, name: "SignupWidgetHelp", path: "widgets/:id/help"}), 
            React.createElement(Route, {handler: CurrentUserViews.Signup.AgentUpgrade, name: "SignupUserMembership", path: "membership"}), 
            React.createElement(Route, {handler: CurrentUserViews.Signup.Image, name: "SignupImage", path: "image"}), 
            React.createElement(Route, {handler: CurrentUserViews.Signup.DeveloperFinish, name: "SignupCompany", path: "company"})
        ), 


        React.createElement(Route, {handler: UserAccount.Handler, name: "UserAccount", path: "users/myaccount"}, 
            React.createElement(DefaultRoute, {handler: UserAccount.Dashboard, name: "UserAccountDashboard"}), 
            React.createElement(Route, {handler: UserAccount.Contact, name: "UserAccountContact", path: "contact"}), 
            React.createElement(Route, {handler: UserAccount.Account, name: "UserAccountEdit", path: "password"}), 
            React.createElement(Route, {handler: UserAccount.CrmIntegration, name: "AgentCrmIntegration", path: "crm"}), 
            React.createElement(Route, {handler: UserAccount.Email, name: "UserAccountEmail", path: "email"}), 
            React.createElement(Route, {handler: Handler, name: "UserUpgradeAndBillingHandler", path: "billing"}, 
                React.createElement(DefaultRoute, {handler: UserAccount.UpgradeAndBilling, name: "UserUpgradeAndBilling"}), 
                React.createElement(Route, {handler: UserAccount.BillingInfo, name: "UserUpgradeAndBillingInfo", path: "billinginfo"})
            ), 
            React.createElement(Route, {handler: UserAccount.ProfileImage, name: "UserAccountImage", path: "image"}), 
            React.createElement(Route, {handler: UserAccount.BillingInfo, name: "UserBilling", path: "billinginfo"}), 
            React.createElement(Route, {handler: Handler, name: "UserMembership", path: "membership"}, 
                React.createElement(DefaultRoute, {handler: UserAccount.Membership, name: "UserMembershipOptions"}), 
                React.createElement(Route, {handler: UserAccount.BillingInfo, name: "UserMembershipBillingInfo", path: "billinginfo"})
            )
        ), 

        React.createElement(Route, {handler: ListingInfo, name: "UserAccountCompany", path: "company"}, 
            React.createElement(DefaultRoute, {handler: Project.Dashboard, name: "CompanyDashboard"}), 
            React.createElement(Route, {handler: UserAccount.Company, name: "BuilderEditDetails", path: "edit"}), 
            React.createElement(Route, {handler: UserAccount.CompanyImage, name: "BuilderImage", path: "image"}), 

            React.createElement(Route, {handler: Project.FloorPlanHandler, name: "BuilderFloorPlanHandler", path: "floorplans"}, 
                React.createElement(DefaultRoute, {handler: Project.FloorPlans, name: "BuilderEditFloorPlans"}), 
                React.createElement(Route, {handler: Project.AddEditFloorPlan, name: "BuilderAddFloorPlan", path: "new"}), 
                React.createElement(Route, {handler: Project.AddEditFloorPlan, name: "BuilderEditFloorPlan", path: ":floorplanid"})
            ), 

            React.createElement(Route, {handler: Project.ImageHandler, name: "BuilderImageHandler", path: "images"}, 
                React.createElement(DefaultRoute, {handler: Project.Images, name: "BuilderEditImages"}), 
                React.createElement(Route, {handler: Project.AddEditImage, name: "BuilderAddImage", path: "new"}), 
                React.createElement(Route, {handler: Project.AddEditImage, name: "BuilderEditImage", path: ":imageid"})
            ), 

            React.createElement(Route, {handler: Project.Contacts, name: "BuilderEditContacts", path: "contacts"}), 
            React.createElement(Route, {handler: Project.AddEditContact, name: "BuilderEditContact", path: "contacts/edit/:contactId"}), 
            React.createElement(Route, {handler: Project.AddEditContact, name: "BuilderAddContact", path: "contacts/new"}), 

            React.createElement(Route, {handler: Project.Documents, name: "BuilderEditDocuments", path: "documents"}), 
            React.createElement(Route, {handler: Project.AddEditDocument, name: "BuilderEditDocument", path: "documents/edit/:documentId"}), 
            React.createElement(Route, {handler: Project.AddEditDocument, name: "BuilderAddDocument", path: "documents/new"}), 


            React.createElement(Route, {handler: Project.Promotions, name: "BuilderEditPromotions", path: "promotions"}), 
            React.createElement(Route, {handler: Project.AddEditPromotion, name: "BuilderAddPromotion", path: "promotions/new"}), 
            React.createElement(Route, {handler: Project.AddEditPromotion, name: "BuilderEditPromotion", path: "promotions/edit/:promotionId"}), 

            React.createElement(Route, {handler: Project.Reports.Handler, name: "BuilderReports", path: "reports"}, 
                React.createElement(DefaultRoute, {handler: Project.Reports.ProjectDashboard, name: "BuilderReportDashboard"}), 
                React.createElement(Route, {handler: Project.Reports.Agents, name: "BuilderProjectReportAgentDashboard", path: "agents"}), 
                React.createElement(Route, {handler: Project.Reports.Performance, name: "BuilderProjectReportPerformanceDashboard", path: "performance"})
            ), 
            React.createElement(Route, {handler: Project.APIKey, name: "BuilderEditAPIKey", path: "apikey"}), 

            React.createElement(Route, {handler: Project.Amenities, name: "BuilderEditAmenities", path: "amenities"})
        ), 

        React.createElement(Route, {handler: NotFound, name: "Tips", path: "tips"}), 
        React.createElement(Route, {handler: NotFound, name: "Help", path: "help"}), 
        React.createElement(Route, {handler: Report, name: "Reports", path: "reports"}), 
        React.createElement(Route, {handler: NotFound, name: "Terms", path: "terms"}), 
        React.createElement(Route, {handler: DevelopmentUnavailable, name: "DevelopmentUnavailable", path: "development-unavailable"}), 

        React.createElement(Route, {handler: Widget.List, name: "Widgets", path: "widgets"}), 

        React.createElement(Route, {handler: Widget.AddEdit, name: "WidgetView", path: "widgets/:id/code"}, 
            React.createElement(DefaultRoute, {handler: Widget.Code, name: "WidgetCode"}), 
            React.createElement(Route, {handler: Widget.Help, name: "WidgetHelp", path: "help"})
        ), 

        React.createElement(Route, {handler: Widget.AddEdit, name: "WidgetEdit", path: "widgets/edit/:id"}, 
            React.createElement(DefaultRoute, {handler: Widget.AddEditType, name: "WidgetEditType"}), 
            React.createElement(Route, {handler: Widget.AddEditProjects, name: "WidgetEditProjects", path: "projects"}), 
            React.createElement(Route, {handler: Widget.Finished, name: "WidgetEditFinished", path: "finished"}), 
            React.createElement(Route, {handler: Widget.Code, name: "WidgetEditCode", path: "code"}), 
            React.createElement(Route, {handler: Widget.Help, name: "WidgetEditHelp", path: "help"})
        ), 
        React.createElement(Route, {handler: Widget.AddEdit, name: "WidgetAdd", path: "widgets/new"}, 
            React.createElement(DefaultRoute, {handler: Widget.AddEditType, name: "WidgetAddType"}), 
            React.createElement(Route, {handler: Widget.AddEditProjects, name: "WidgetAddProjects", path: "projects"})
        ), 
        React.createElement(Route, {handler: NotFound, name: "WidgetPreview", path: "widgets/preview/:id"}), 

        React.createElement(Route, {handler: Agent.EmailTools.EmailTools, name: "EmailTools", path: "emailtools"}, 
            React.createElement(DefaultRoute, {handler: Agent.EmailTools.DevelopmentSelector, name: "EmailToolsSelectDevelopments"}), 
            React.createElement(Route, {handler: Agent.EmailTools.EmailDevelopments, name: "EmailToolsEmail", path: "email"})
        ), 

        React.createElement(Route, {handler: NotFound, name: "SocialTools", path: "socialtools"}), 

        React.createElement(Route, {handler: Project.List, name: "ProjectList", path: "projects"}), 

        React.createElement(Route, {handler: Project.Wizard.Add, name: "ProjectWizard", path: "projects/new"}, 
            React.createElement(DefaultRoute, {handler: Project.Wizard.Details, name: "ProjectWizardAdd"}), 
            React.createElement(Route, {handler: Project.Wizard.Steps, name: "ProjectWizardSteps", path: ":id"}), 
            React.createElement(Route, {handler: Project.Wizard.Details, name: "ProjectWizardDetails", path: ":id/details"}), 
            React.createElement(Route, {handler: Project.Wizard.Contact, name: "ProjectWizardContact", path: ":id/contacts"}), 
            React.createElement(Route, {handler: Project.Wizard.Image, name: "ProjectWizardImage", path: ":id/images"}), 
            React.createElement(Route, {handler: Project.Wizard.WidgetImage, name: "ProjectWizardWidgetImage", path: ":id/image"}), 
            React.createElement(Route, {handler: Project.Wizard.FloorPlan, name: "ProjectWizardFloorPlan", path: ":id/floorplans"}), 
            React.createElement(Route, {handler: Project.Wizard.Promotion, name: "ProjectWizardPromotion", path: ":id/promotions"}), 
            React.createElement(Route, {handler: Project.Wizard.Document, name: "ProjectWizardDocument", path: ":id/documents"})
        ), 

        React.createElement(Route, {handler: Project.AddEdit, name: "Project", path: "projects/:id"}, 
            React.createElement(DefaultRoute, {handler: Project.Dashboard, name: "ProjectDashboard"}), 

            React.createElement(Route, {handler: Project.AddEditDetails, name: "ProjectEditDetails", path: "edit"}), 
            React.createElement(Route, {handler: Project.AddEditDetails2, name: "ProjectEditDetails2", path: "edit2"}), 

            React.createElement(Route, {handler: Project.ImageHandler, name: "ProjectImageHandler", path: "images"}, 
                React.createElement(DefaultRoute, {handler: Project.Images, name: "ProjectEditImages"}), 
                React.createElement(Route, {handler: Project.AddEditImage, name: "ProjectAddImage", path: "new"}), 
                React.createElement(Route, {handler: Project.AddEditImage, name: "ProjectEditImage", path: ":imageid"})
            ), 

            React.createElement(Route, {handler: Project.FloorPlanHandler, name: "ProjectFloorPlanHandler", path: "floorplans"}, 
                React.createElement(DefaultRoute, {handler: Project.FloorPlans, name: "ProjectEditFloorPlans"}), 
                React.createElement(Route, {handler: Project.AddEditFloorPlan, name: "ProjectAddFloorPlan", path: "new"}), 
                React.createElement(Route, {handler: Project.AddEditFloorPlan, name: "ProjectEditFloorPlan", path: ":floorplanid"})
            ), 

            React.createElement(Route, {handler: Project.Amenities, name: "ProjectEditAmenities", path: "amenities"}), 
            React.createElement(Route, {handler: Project.PersonalizedAgentEmail, name: "ProjectPersonalizedAgentEmail", path: "agent-email"}), 
            React.createElement(Route, {handler: Project.PersonalizedAgentBrochure, name: "ProjectPersonalizedAgentBrochure", path: "agent-brochure"}), 


            React.createElement(Route, {handler: Project.Contacts, name: "ProjectEditContacts", path: "contacts"}), 
            React.createElement(Route, {handler: Project.AddEditContact, name: "ProjectEditContact", path: "contacts/edit/:contactId"}), 
            React.createElement(Route, {handler: Project.AddEditContact, name: "ProjectAddContact", path: "contacts/new"}), 

            React.createElement(Route, {handler: Project.Documents, name: "ProjectEditDocuments", path: "documents"}), 
            React.createElement(Route, {handler: Project.AddEditDocument, name: "ProjectEditDocument", path: "documents/edit/:documentId"}), 
            React.createElement(Route, {handler: Project.AddEditDocument, name: "ProjectAddDocument", path: "documents/new"}), 


            React.createElement(Route, {handler: Project.Promotions, name: "ProjectEditPromotions", path: "promotions"}), 
            React.createElement(Route, {handler: Project.AddEditPromotion, name: "ProjectAddPromotion", path: "promotions/new"}), 
            React.createElement(Route, {handler: Project.AddEditPromotion, name: "ProjectEditPromotion", path: "promotions/edit/:promotionId"}), 

            React.createElement(Route, {handler: Project.Reports.Handler, name: "ProjectReports", path: "reports"}, 
                React.createElement(DefaultRoute, {handler: Project.Reports.ProjectDashboard, name: "ProjectReportDashboard"}), 
                React.createElement(Route, {handler: Project.Reports.Agents, name: "ProjectReportAgentDashboard", path: "agents"}), 
                React.createElement(Route, {handler: Project.Reports.Performance, name: "ProjectReportPerformanceDashboard", path: "performance"})
            ), 
            React.createElement(Route, {handler: Project.APIKey, name: "ProjectEditAPIKey", path: "apikey"}), 
            React.createElement(Route, {handler: Handler, name: "ProjectEditUpgradeAndBillingHandler", path: "billing"}, 
                React.createElement(DefaultRoute, {handler: UserAccount.UpgradeAndBilling, name: "ProjectEditUpgradeAndBilling"}), 
                React.createElement(Route, {handler: UserAccount.BillingInfo, name: "ProjectEditUpgradeAndBillingInfo", path: "billinginfo"})
            ), 
            React.createElement(Route, {handler: Project.Visibility, name: "ProjectEditVisibility", path: "visibility"})
        ), 

        React.createElement(Route, {handler: Agent.Leads, name: "AgentLeads", path: "agents/leads"}), 
        React.createElement(Route, {handler: Lead.View, name: "AgentLead", path: "agents/leads/view/:id"}), 
        React.createElement(Route, {handler: Agent.List, name: "AgentList", path: "agents"}), 
        React.createElement(Route, {handler: Agent.AddEdit, name: "AgentEdit", path: "agents/edit/:id"}), 
        React.createElement(Route, {handler: Agent.AddEdit, name: "AgentAdd", path: "agents/new"}), 

        React.createElement(Route, {handler: Broker.List, name: "BrokerList", path: "brokers"}), 
        React.createElement(Route, {handler: Broker.View, name: "BrokerDetails", path: "brokers/view/:id"}), 
        React.createElement(Route, {handler: Broker.AddEdit, name: "BrokerEdit", path: "brokers/edit/:id"}), 
        React.createElement(Route, {handler: Broker.AddEdit, name: "BrokerAdd", path: "brokers/new"}), 
        React.createElement(Route, {handler: Broker.AddAgent, name: "BrokerAgentAdd", path: "brokers/newagent"}), 

        React.createElement(Route, {handler: Developer.List, name: "DeveloperList", path: "developers"}), 
        React.createElement(Route, {handler: Developer.View, name: "DeveloperDetails", path: "developers/view/:id"}), 
        React.createElement(Route, {handler: Developer.AddEdit, name: "DeveloperEdit", path: "developers/edit/:id"}), 
        React.createElement(Route, {handler: Developer.AddEdit, name: "DeveloperAdd", path: "developers/new"}), 

        React.createElement(Route, {handler: User.List, name: "UserList", path: "users"}), 
        React.createElement(Route, {handler: User.View, name: "UserDetails", path: "users/view/:id"}), 
        React.createElement(Route, {handler: User.AddEdit, name: "UserEdit", path: "users/edit/:id"}), 
        React.createElement(Route, {handler: User.AddEdit, name: "UserAdd", path: "users/new"}), 


        React.createElement(Route, {name: "Error403", handler: NotFound, path: "error-403"}), 
        React.createElement(Route, {name: "Error404", handler: NotFound, path: "error-404"}), 
        React.createElement(Route, {name: "Error500", handler: NotFound, path: "error-500"}), 
        React.createElement(NotFoundRoute, {handler: NotFound})
    )
);

// ReactRouter.run(routes, function (Handler) {
//   React.render(<Handler/>, document.body);
// });

var router = ReactRouter.create({
        routes: routes,
        location: ReactRouter.HistoryLocation
    }),
    anonymousRoutes = ['/signup', '/forgotpassword', '/resetpassword', '/login', '/error-403', '/error-404', '/error-500', '/development-unavailable'],
    isLoading = true,
    loggedIn = false;

CurrentUser.once('sync', function(){
    isLoading = false;
    loggedIn = true;
});

function redirectToLogin(){
    isLoading = false;
    CurrentUser.destroyCookie();
    if(!isCurrentPathAnonymousOrNotfound()){
        router.transitionTo('Login', undefined, {
            redirect: window.location.pathname + window.location.search
        });
    }
}

function isCurrentPathAnonymousOrNotfound(){
    var pathname = window.location.pathname.toLowerCase(),
        matchedRoutes = router.match(router.getLocation().getCurrentPath()),
        anonymousRoute = matchedRoutes.routes.pop().isNotFound || _.find(anonymousRoutes, function(path){
            var isAnonymous =  pathname.indexOf(path) === 0;

            if(path === '/signup'){
                isAnonymous = pathname === '/signup' || pathname.indexOf('/signup/create') === 0;
            }

            return isAnonymous;
        });

    return anonymousRoute;
}

function intercomLogin(){
    CurrentUser.intercomLogin();
}

function start() {
    var anonymousRoute = isCurrentPathAnonymousOrNotfound();

    if (window.analytics && !CurrentUser.isLoggedIn()) {
        analytics.page();
    }

    if(!!anonymousRoute){
        if(CurrentUser.isLoggedIn()){
            CurrentUser.fetch().always(function(){
                router.run(function(Handler, state) {
                    React.render(React.createElement(Handler, React.__spread({},  state)), document.getElementById('app'));
                });
            }).fail(function(){
                CurrentUser.destroyCookie();
            }).then(function(){
                intercomLogin();
            });
        } else {
            router.run(function(Handler, state) {
                React.render(React.createElement(Handler, React.__spread({},  state)), document.getElementById('app'));
            });
        }

    } else {
        if(CurrentUser.isLoggedIn()){
            CurrentUser.fetch().fail(function() {
                CurrentUser.destroyCookie();
                router.transitionTo('Login', undefined, {
                    redirect: window.location.pathname + window.location.search
                });
                router.run(function(Handler, state) {
                    React.render(React.createElement(Handler, React.__spread({},  state)), document.getElementById('app'));
                });
            }).then(function(){
                intercomLogin();
                router.run(function(Handler, state) {
                    React.render(React.createElement(Handler, React.__spread({},  state)), document.getElementById('app'));
                });
            });
        } else {
            CurrentUser.destroyCookie();
            router.transitionTo('Login', undefined, {
                redirect: window.location.pathname + window.location.search
            });
            router.run(function(Handler, state) {
                React.render(React.createElement(Handler, React.__spread({},  state)), document.getElementById('app'));
            });
        }
    }
}

module.exports = {
    router: router,
    start: start,
    isLoading: function(){
        return isLoading;
    }
};
