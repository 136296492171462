'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

var _get = function get(_x, _x2, _x3) { var _again = true; _function: while (_again) { var object = _x, property = _x2, receiver = _x3; desc = parent = getter = undefined; _again = false; if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { _x = parent; _x2 = property; _x3 = receiver; _again = true; continue _function; } } else if ('value' in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } } };

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

function _inherits(subClass, superClass) { if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _timeTime = require('../time/Time');

var _timeTime2 = _interopRequireDefault(_timeTime);

var _rcUtil = require('rc-util');

var _rcUtil2 = _interopRequireDefault(_rcUtil);

var toFragment = _rcUtil2['default'].Children.mapSelf;

var CalendarFooter = (function (_React$Component) {
  _inherits(CalendarFooter, _React$Component);

  function CalendarFooter() {
    _classCallCheck(this, CalendarFooter);

    _get(Object.getPrototypeOf(CalendarFooter.prototype), 'constructor', this).apply(this, arguments);
  }

  _createClass(CalendarFooter, [{
    key: 'getTodayTimeStr',
    value: function getTodayTimeStr() {
      var today = this.getTodayTime();
      return this.props.dateFormatter.format(today);
    }
  }, {
    key: 'getTodayTime',
    value: function getTodayTime() {
      var value = this.props.value;
      var today = value.clone();
      today.setTime(Date.now());
      return today;
    }
  }, {
    key: 'render',
    value: function render() {
      var props = this.props;
      var value = props.value;
      var locale = props.locale;
      var prefixCls = props.prefixCls;
      var footerEl = null;
      if (props.showToday || props.showTime) {
        var nowEl = undefined;
        var localeNow = locale.today;
        if (props.showTime) {
          localeNow = locale.now || locale.today;
        }
        if (props.showToday) {
          var disabledToday = false;
          var disabledTodayClass = '';
          if (props.disabledDate) {
            disabledToday = props.disabledDate(this.getTodayTime(), value);
            if (disabledToday) {
              disabledTodayClass = prefixCls + '-today-btn-disabled';
            }
          }
          nowEl = _react2['default'].createElement(
            'a',
            { className: prefixCls + '-today-btn ' + disabledTodayClass,
              role: 'button',
              onClick: disabledToday ? null : props.onToday,
              title: this.getTodayTimeStr() },
            localeNow
          );
        }
        var clearEl = undefined;
        if (props.showClear) {
          clearEl = _react2['default'].createElement(
            'a',
            { className: prefixCls + '-clear-btn',
              role: 'button',
              onClick: props.onClear },
            locale.clear
          );
        }
        var okBtn = undefined;
        if (props.showOk) {
          okBtn = _react2['default'].createElement(
            'a',
            { className: prefixCls + '-ok-btn',
              role: 'button',
              onClick: props.onOk },
            locale.ok
          );
        }
        var footerBtn = undefined;
        if (nowEl || clearEl) {
          footerBtn = _react2['default'].createElement(
            'span',
            { className: prefixCls + '-footer-btn' },
            toFragment([nowEl, okBtn, clearEl])
          );
        }
        var timeEl = undefined;
        if (props.showTime) {
          timeEl = _react2['default'].createElement(_timeTime2['default'], { value: value, prefixCls: prefixCls, locale: locale, onChange: props.onSelect });
        }
        footerEl = _react2['default'].createElement(
          'div',
          { className: prefixCls + '-footer' },
          timeEl,
          footerBtn
        );
      }

      return footerEl;
    }
  }]);

  return CalendarFooter;
})(_react2['default'].Component);

exports['default'] = CalendarFooter;
module.exports = exports['default'];