"use strict";

var ModelClass = require('./Model'),
    Constants = require('../constants');

var MetroArea = ModelClass.extend({
	urlRoot: Constants.restPath + "/metroAreas",
    idAttribute: 'id',
    defaults: function() {
        return {
            id: undefined,
            dmaRegion: '',
            dmaRegionCode: ''
        };
    }
});

module.exports = MetroArea;
