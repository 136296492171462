"use strict";

module.exports = {
    List: require('./Widgets'),
    AddEdit: require('./WidgetAddEdit'),
    AddEditType: require('./WidgetAddEditType'),
    AddEditProjects: require('./WidgetAddEditProjects'),
    Code: require('./WidgetCode'),
    Finished: require('./WidgetFinished'),
    Help: require('./WidgetHelp')
};
