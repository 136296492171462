"use strict";

var React = require('react'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    CurrentUser = require('../../shared/CurrentUser'),
    UserReset = require('../../models/UserReset'),
    Constants = require('../../constants'),
    Cmp = require('../../components'),
    Link = ReactRouter.Link,
    Validation = require('../../partials/validation');

var ResetPassword = React.createClass({displayName: "ResetPassword",
    mixins: [
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            model: new UserReset()
        };
    },

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            token = params.token;

        this.props.model.clear();
        this.props.model.set({
            token: token
        });

        return {
            token: token,
            success: false
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    render: function() {
        return (
            React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: "NCH - Update Password"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Update Password"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(Cmp.NavLink, null, "Home"), 
                            React.createElement(Cmp.NavLink, {to: "ResetPassword", params: {token: this.state.token}}, "Update Password")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement("div", {className: "row"}, 
                        React.createElement("div", {className: "col-md-6 col-md-offset-3"}, 
                            React.createElement(BS.Panel, {className: "panel-split-header", header: (React.createElement("h2", null, "Update Password"))}, 
                                this.state.success && (
                                    React.createElement(BS.Alert, {bsStyle: "success"}, 
                                        "Password updated successfully. Please ", React.createElement(Link, {to: "Login"}, "Login"), " to continue."
                                    )
                                ), 
                                React.createElement(Validation.Form, {hidden: this.state.success, horizontal: false, ref: "form", model: this.props.model, onSubmit: this.onSubmit, success: this.state.success, validateOnLoad: false, getMessage: this.getMessage}, 
                                    React.createElement(Validation.Input, {trigger: "onChange", type: "password", label: "Password", placeholder: "Password", name: "password"}), 
                                    React.createElement(Validation.Input, {trigger: "onChange", type: "password", label: "Confirm Password", placeholder: "Confirm Password", name: "passwordConfirm"}), 

                                    React.createElement(Cmp.Button.Save, {className: "btn-block"}, "Submit")
                                )
                            )
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    },

    onSubmit: function(e) {
        var me = this,
            state = me.state,
            props = me.props;

        if (this.refs.form.isValid()) {
            props.model.updatePassword(this.refs.form.getValues()).then(function() {
                me.setState({
                    success: true
                });
            });
        }
    },

    getMessage: function(type) {
        var message;

        if(type === 'error') {
            message = (
                React.createElement("div", null, "We could not reset your password. It appears the link you used is either expired, or is invalid. Please ", React.createElement("a", {href: "/forgotpassword"}, "click here"), ", and we will send you a new link to use.")
            );
        }

        return message;
    }
});
module.exports = ResetPassword;
