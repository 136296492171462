"use strict";

var React = require('react/addons'),
    Constants = require('../../../constants'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    FluxBone = require('../../../mixins/FluxBone'),
    BS = require('react-bootstrap'),
    Cmp = require('../../../components'),
    ReactRouter = require('react-router'),
    Validation = require('../../../partials/validation'),
    $ = require('jquery'),
    Link = ReactRouter.Link;

var UserAccount = React.createClass({displayName: "UserAccount",
    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        model: undefined
    },

    componentWillMount: function() {
        this.props.model.changingPassword = true;
    },

    componentWillUnmount: function() {
        this.props.model.changingPassword = false;
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            fieldAttrs = {
                labelClassName: 'col-md-3',
                wrapperClassName: 'col-md-9'
            };

        return (
            React.createElement("div", {className: "row"}, 
                React.createElement("div", {className: "col-xs-12"}, 
                    React.createElement(Validation.Form, {ref: "form", model: props.model, onSubmit: this.onSaveClick, getMessage: this.getMessage, validateOnly: ['password', 'passwordConfirm']}, 
                        React.createElement("h1", null, "Change Password:"), 
                        React.createElement("p", null, "Enter a new password below to change your password."), 

                        React.createElement(Validation.Input, React.__spread({},  fieldAttrs, {name: "password", validateAlso: "passwordConfirm", type: "password", label: "Password:"})), 
                        React.createElement(Validation.Input, React.__spread({},  fieldAttrs, {name: "passwordConfirm", validateAlso: "password", type: "password", label: "Confirm Password:"})), 

                        React.createElement(Cmp.ValidationMessage, null), 
                        React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                            React.createElement(Cmp.Button.Cancel, {to: "UserAccountDashboard"}), 
                            React.createElement(Cmp.Button.Save, {className: "pull-right"})
                        )
                    )
                )
            )
        );
    },

    getMessage: function(status) {
        return status === "success" ? (
            React.createElement("span", null, "Success! ", React.createElement("br", null), "Your new password has been successfully updated.")
        ) : (
            React.createElement("span", null, "Cancelled! ", React.createElement("br", null), "Your password was not updated.")
        );
    },

    onSaveClick: function(e) {
        var me = this,
            props = this.props,
            state = this.state;

        me.props.model.save(me.refs.form.getValues(), {
            fields: ['password', 'passwordConfirm']
        }).then(function() {});
    }
});
module.exports = UserAccount;
