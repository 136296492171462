"use strict";

var React = require('react'),
    ReactRouterBootstrap = require('react-router-bootstrap'),
    BS = require('react-bootstrap'),
    cx = require('classnames'),
    _ = require('underscore'),
    ButtonLink = ReactRouterBootstrap.ButtonLink;

var CancelButton = React.createClass({displayName: "CancelButton",

    getDefaultProps: function(){
        return {
            label: 'Cancel'
        }
    }, 

    render: function() {
        var props = this.props,
            className = cx(props.className),
            other = _.omit(props, 'className', 'label');

        return props.to ? (
            React.createElement(ButtonLink, React.__spread({},  other, {className: className}), 
                props.children || props.label
            )
        ) : (
            React.createElement(BS.Button, React.__spread({},  other, {className: className}), 
                props.children || props.label
            )
        );
    }
});

module.exports = CancelButton;
