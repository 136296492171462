"use strict";

var ModelClass = require('./Model'),
    Constants = require('../constants'),
    Project = require('./Project'),
    ContactInfo = require('./ContactInfo'),
    _ = require('underscore');

var Lead = ModelClass.extend({
    urlRoot: Constants.restPath + "/leads",
    idAttribute: 'id',
    hasOne: {
        Agent: {
            responseKey: 'agent',
            alwaysInclude: true
        },
        Project: 'listing',
        ContactInfo: {
            responseKey: 'contactInfo',
            alwaysInclude: true
        }
    },
    validation: {
        'agent.id': {
            required: true
        },
        contactMethod: {
            required: true
        },
        'contactInfo.firstName': {
            required: true
        },
        'contactInfo.lastName': {
            required: true
        },
        'contactInfo.email': {
            required: true,
            pattern: 'email'
        },
        'collectedFromUrl': {
            required: true
        }
    },

    labels: {
        'contactInfo.firstName': 'First Name',
        'contactInfo.lastName': 'Last Name',
        'contactInfo.email': 'Email'
    },

    defaults: function() {
        return {
            id: undefined,
            brokerName: '',
            brokerPhone: '',
            brokerEmail: '',
            companyName: '',
            interestedIn: '',
            priceRangeMin: 0,
            priceRangeMax: 0,
            ageRange: '',
            numberInHousehold: 0,
            heardHow: '',
            comments: '',
            contactMethod: '',
            collectedFromUrl: window.location.href,
            widget: undefined
        };
    },

    constructor: function() {
        if (!Lead.Agent) {
            Lead.Agent = Lead.Agent(); // Dependency Injection from models/Agent
        }
        return ModelClass.prototype.constructor.apply(this, arguments);
    },

    sync: function(method, model, options) {
        options = options || {};
        options.data = options.data || {};

        if (method === 'create') {
            _.extend(options, {
                type: 'GET',
                url: this.usingJsonP ? Constants.restPath + '/leads/jsonp/submit' : Constants.restPath + '/leads/submit',
                dataType: 'jsonp',
                contentType: 'application/x-www-form-urlencoded',
                processData: true,
                syncMethod: method
            });
            options.data = model.toJSON(options);
        } else if(Constants.agentId){
            options.data.agentId = Constants.agentId;
        }

        return ModelClass.prototype.sync.call(this, method, model, options);
    },

    toJSON: function(options) {
        var data = ModelClass.prototype.toJSON.apply(this, arguments);

        options = options || {};

        if (options.syncMethod === 'create') {
            data = {
                firstName: data.contactInfo.firstName,
                lastName: data.contactInfo.lastName,
                phoneHome: data.contactInfo.phoneHome,
                email: data.contactInfo.email,
                address1: data.contactInfo.address1,
                address2: data.contactInfo.address2,
                cityId: data.contactInfo.city && data.contactInfo.city.id ? data.contactInfo.city.id : undefined,
                stateId: data.contactInfo.state && data.contactInfo.state.id ? data.contactInfo.state.id : undefined,
                zip: data.contactInfo.zip,
                countryId: data.contactInfo.country && data.contactInfo.country.id ? data.contactInfo.country.id : undefined,
                agentId: data.agent ? data.agent.id : undefined,
                listingId: data.listing ? data.listing.id : undefined,
                widgetId: data.widget ? data.widget.id : undefined,
                contactMethod: data.contactMethod,
                comments: data.comments,
                collectedFromUrl: data.collectedFromUrl,
                brochureTemplate: data.brochureTemplate
            };
        }

        return data;
    }

}, {
    Project: Project,
    ContactInfo: ContactInfo
        // Agent: Lead.Agent // Dependency Injected from ./Agent
});

module.exports = Lead;
