"use strict";

var ModelClass = require('./Model'),
    Constants = require('../constants'),
    Agent = require('./Agent'),
    Developer = require('./Developer'),
    Broker = require('./Broker'),
    $ = require('jquery'),
    _ = require('underscore');

var User = ModelClass.extend({
    urlRoot: Constants.restPath + "/users",
    idAttribute: 'id',
    hasOne: {
        Agent: 'agent',
        Developer: 'developer'
    },
    validation: {
        email: {
            required: true,
            pattern: 'email'
        },
        confirmEmail: function(value, attr, computedState) {
            if (this.changingEmail && !computedState.confirmEmail) {
                return 'Confirm Email can not be blank.';
            } else if (this.changingEmail && computedState.confirmEmail !== computedState.email) {
                return "Email does not match.";
            }
        },
        username: {
            required: true
        },
        password: {
            required: true,
            equalTo: 'passwordConfirm'
        },
        passwordConfirm: function(value, attr, computedState) {
            if (this.changingPassword && !computedState.passwordConfirm) {
                return 'Confirm Password can not be blank.';
            } else if (this.changingPassword && computedState.passwordConfirm !== computedState.password) {
                return "Password does not match.";
            }
        }
    },
    validationUses: {
        password: ['passwordConfirm'],
        passwordConfirm: ['password'],
        email: ['confirmEmail'],
        confirmEmail: ['email']
    },

    defaults: function() {
        return {
            id: undefined,
            username: '',
            active: '',
            needsReset: '',
            email: '',
            image: '',
            roles: [],
            groups: [],

            agent: undefined,
            developer: undefined
        };
    },

    isAdmin: function() {
        return this.hasRole(Constants.roles.ADMIN);
    },

    toJSON: function(options) {
        var data = ModelClass.prototype.toJSON.apply(this, arguments);

        options = options || {};

        return data;
    },

    parse: function(response, options) {
        response = response && response.currentUser ? response.currentUser : response;
        return ModelClass.prototype.parse.call(this, response, options);
    },

    hasRole: function(role) {
        var hasRole;

        if (role === Constants.roles.BROKER) {
            hasRole = this.hasRole(Constants.roles.AGENT) && !!this.getEntity().get('isBroker');
        } else {
            hasRole = this.get('roles').indexOf(role) > -1;
        }

        // [AS-1359] - UI handling of users that are agents and POC users
        if ((role === Constants.roles.BUILDER || role === Constants.roles.DEVELOPER) && this.hasRole(Constants.roles.AGENT)) {
            hasRole = false;
        }

        return hasRole;
    },

    getType: function() {
        var type = 'User';

        if (this.hasRole(Constants.roles.ADMIN)) {
            type = 'Admin';
        } else if (this.hasRole(Constants.roles.AGENT)) {
            type = 'Agent';
        } else if (this.hasRole(Constants.roles.DEVELOPER)) {
            type = 'Developer';
        } else if (this.hasRole(Constants.roles.BUILDER)) {
            type = 'Builder';
        }

        return type;
    },

    getEntity: function() {
        var type = this.getType(),
            entity;

        switch (type) {
            case 'Agent':
                entity = this.agentModel;
                break;
            case 'Developer':
                entity = this.developerModel;
                break;
            case 'Builder':
                entity = this.builderModel;
                break;
            case 'User':
            case 'Admin':
            default:
                entity = this;
                break;
        }

        return entity;
    },
    getFullName: function(contactName) {
        var entity = this.getEntity(),
            name = entity !== this && entity.getDisplayName ? entity.getDisplayName(contactName) : undefined;


        return !name ? this.get('username') : name
    },
    getDisplayName: function(contactName) {
        return this.getFullName(contactName);
    },

    getFirstName: function() {
        var contactInfoModel = this.getEntity().contactInfoModel,
            firstName;

        if (contactInfoModel) {
            firstName = contactInfoModel.get('firstName');
        }

        return firstName;
    },

    getLastName: function() {
        var contactInfoModel = this.getEntity().contactInfoModel,
            lastName;

        if (contactInfoModel) {
            lastName = contactInfoModel.get('lastName');
        }

        return lastName;
    }

}, {
    Agent: Agent,
    Developer: Developer,
    Broker: Broker,

    resetPassword: function(data, options) {
        return $.ajax(_.extend({
            url: Constants.restPath + '/users/resetpassword',
            method: 'POST',
            suppressError: true,
            data: {
                username: data.username || data.email
            }
        }, options));
    },

    updatePassword: function(data, options) {
        return $.post(Constants.restPath + '/users/updatepassword', {
            password: data.password,
            token: data.token
        });
    }
});

module.exports = User;
