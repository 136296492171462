"use strict";

var React = require('react/addons'),
    BS = require('react-bootstrap'),
    Widget = require('../../models/Widget'),
    Constants = require('../../constants'),
    FluxBone = require('../../mixins/FluxBone'),
    Cmp = require('../../components'),
    _ = require('underscore'),
    $ = require('jquery'),
    Validation = require('../validation');

var CreateMapWidget = React.createClass({displayName: "CreateMapWidget",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset')
    ],

    getDefaultProps: function() {
        var model = new Widget();
        return {
            widgetId: 'new',
            model: model,
            standalone: false,
            getValues: true
        };
    },

    getInitialState: function() {
        var props = this.props;

        return {
            loading: true
        };
    },

    componentWillMount: function() {
        this.fetchWidget();
    },

    componentDidUpdate: function(oldProps) {
        if (this.props.widget !== oldProps.widget) {
            this.fetchWidget();
        }
    },

    render: function() {
        var me = this,
            props = this.props,
            state = this.state,
            namePrefix = 'map_' + props.widgetId,
            defaultAttrs = {
                wrapperClassName: 'col-md-8',
                labelClassName: 'col-md-3'
            };

        return (
            React.createElement("div", {className: "wrapper agent-shield-widget"}, 
                React.createElement(Validation.Form, {ref: "form", className: "col-xs-12", onChange: props.onChange, onFormChange: props.onFormChange, onValidityChange: props.onValidityChange, horizontal: true, model: props.model, legacy: true}, 
                    React.createElement("div", {className: "row"}, 
                        React.createElement(Cmp.Select.Country, React.__spread({},  defaultAttrs, {invalidMsg: "** Required", label: "", defaultText: "Select a Country", name: "country.id", id: namePrefix + 'country', hasProjects: true}))
                    ), 
                    React.createElement("div", {className: "row"}, 
                        React.createElement(Cmp.Select.State, React.__spread({},  defaultAttrs, {invalidMsg: "** Required", label: "", defaultText: "Select a State/Region", name: "state.id", id: namePrefix + 'state', formLinks: { disabled: '!model.country.id', countryId: 'model.country.id'}, hasProjects: true}))
                    ), 
                    /*
                    <div className="row">
                        <Cmp.RadioGroup formLinks={{ disabled: '!model.state.id', countryId: 'model.country.id' }} name="metroOrCity" wrapperClassName="col-md-8" groupClassName="col-xs-12" className="col-xs-6 col-md-3" type="radio" items={[{ id: 'metro', name: 'Select By Metro Areas'}, { id: 'city', name: 'Select by Cities/Towns' }]}/>
                    </div>
                    */
                    React.createElement("div", {className: "row", fieldKey: "metrowrap", formLinks: { /*style: this.showHideMetroes*/ }}, 
                        React.createElement(Cmp.Select.Metro, React.__spread({showNone: true},  defaultAttrs, {invalidMsg: "** Required", label: "", defaultText: "Select a Metro Area", formLinks: { disabled: this.isMetroDisabled, countryId: 'model.country.id', stateId: 'model.state.id'}, name: "metroArea.id", id: namePrefix + 'metro', hasProjects: true}))
                    ), 
                    React.createElement("div", {className: "row", fieldKey: "citywrap", formLinks: { style: this.showHideCities}}, 
                        React.createElement("h4", {className: "blue-header"}, "Select Cities/Towns:"), 
                        React.createElement(Cmp.Select.CityCheckboxGroup, {
                            formLinks: { selectAll: this.showSelectAll, disabled: this.isCitiesDisabled, countryId: 'model.country.id', stateId: 'model.state.id', metroId: this.getCitiesMetroId}, 
                            name: "cities", 
                            id: namePrefix + 'cities', 
                            groupClassName: "col-xs-12", 
                            selectAllLabel: "Use All Cities", 
                            wrapperClassName: "col-md-8", 
                            className: "col-xs-6 col-sm-4 col-md-3", 
                            invalidMsg: "Select at least one City", 
                            label: "", 
                            hideLabel: true, 
                            delimiter: ",", 
                            hasProjects: true, 
                            valuesAsObject: true})
                    )
                )
            )
        );
    },

    isMetroDisabled: function(formState) {
        var model = formState.model;
        return !(model.state.id);
    },

    showHideMetroes: function(formState) {
        var model = formState.model;
        return {
            display: model.metroOrCity === 'metro' && !!model.state.id ? 'block' : 'none'
        };
    },

    showHideCities: function(formState) {
        var model = formState.model;
        return {
            display: model.state.id && model.metroArea.id ? 'block' : 'none'
        };
    },

    showSelectAll: function(formState) {
        var model = formState.model;
        return (model.metroArea && model.metroArea.id && model.metroArea.id !== 'NONE');
    },

    isCitiesDisabled: function(formState) {
        var model = formState.model;
        return !model.state.id || (model.metroOrCity === 'metro' && !model.metroArea.id);
    },

    getCitiesMetroId: function(formState) {
        var model = formState.model;
        return model.metroOrCity === 'metro' ? model.metroArea.id : null;
    },

    fetchWidget: function() {
        var me = this,
            props = this.props;

        if (props.widget) {
            props.model.set(props.model.parse(props.widget));
            // Fix for form not updating with new values when being passed an object
            props.model.trigger('sync', props.model, props.widget, {});
            me.onWidgetLoad();
        } else if (props.widgetId === 'new') {
            me.onWidgetLoad();
        } else if (props.model && !props.model.isLoaded() && !props.model.isLoading()) {
            props.model.set('id', props.widgetId);
            props.model.fetch().then(me.onWidgetLoad).always(me.onLoadFinished);
        } else if (props.model && !props.model.isLoaded()) {
            props.model.once('sync', me.onWidgetLoad);
        } else if (props.model) {
            me.onWidgetLoad();
        }
    },

    onWidgetLoad: function() {
        var me = this;
        me.updateStateFromModel();
    },

    updateStateFromModel: function() {
        var me = this,
            data = me.props.model.toJSON();

        me.setState({
            loaded: true,
            loading: false
        });
    },

    onLoadFinished: function() {
        this.setState({
            loading: false
        });
    },

    getValues: function(useComponentValues){
        return this.refs.form.getValues(useComponentValues);
    }
});

module.exports = CreateMapWidget;
