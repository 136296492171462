"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    DocumentTitle = require('react-document-title'),
    BS = require('react-bootstrap'),
    Cmp = require('../../../components'),
    Constants = require('../../../constants'),
    FluxBone = require('../../../mixins/FluxBone'),
    PageHeader = require('../../../partials/PageHeader'),
    ImageModel = require('../../../models/ProjectImage'),
    PageFooter = require('../../../partials/PageFooter'),
    Validation = require('../../../partials/validation');

var ProjectWizardWidgetImage = React.createClass({displayName: "ProjectWizardWidgetImage",

    mixins: [
        ReactRouter.Navigation,
        FluxBone.ModelMixin('model', 'sync', 10)
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {};
    },

    updateState: function() {
        return {};
    },

    getInitialState: function() {
        return this.updateState();
    },

    componentWillMount: function() {
        var model = this.props.model;
        model.validation = model.imageValidation;
    },

    componentWillUnmount: function() {
        var model = this.props.model;
        model.validation = model.constructor.prototype.validation;
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.reset();
    },

    render: function() {
        var me = this,
            props = me.props,
            model = props.model,
            state = me.state,
            listingImage = props.model.get('image');

        if (listingImage) {
            listingImage = listingImage + "?rand=" + (Math.random() * 1000);
        } else {
            listingImage = Constants.placeholder;
        }

        return (
            React.createElement("div", {className: "wrapper widgets"}, 
                React.createElement(DocumentTitle, {title: "NCH - Create Listing - Upload Image"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Create Listing - Step 2: Upload Image"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(Cmp.NavLink, {to: "ProjectList"}, "Projects"), 
                            React.createElement(Cmp.NavLink, {to: "ProjectWizardSteps", params: { id: model.id}}, "Additional Info"), 
                            React.createElement(Cmp.NavLink, {to: "ProjectWizardImage", params: { id: model.id}}, "Upload Image")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement(Validation.Form, {ref: "form", model: props.model, onSubmit: this.onSaveClick}, 
                        React.createElement("h1", null, "Thumbnail Image for Widgets"), 
                        React.createElement("p", null, "This will be the image and thumbnail that shows up in widgets. You should choose an image that best represents your listing."), 

                        props.model && (
                            React.createElement("div", {className: "row"}, 
                                React.createElement("div", {className: "col-xs-3"}, 
                                    React.createElement("div", {className: "profile-image listing-image"}, 
                                        React.createElement("div", {className: "profile-image-inner", style: { "backgroundImage": "url(" + listingImage +")"}})
                                    )
                                ), 
                                React.createElement("div", {className: "col-xs-9"}, 
                                    React.createElement("div", {className: "text-center"}, 
                                        React.createElement("h3", {className: "blue-text"}, "Photo should be 135 pixels wide by 135 pixels high."), 
                                        React.createElement("p", null, "All other sizes will automatically be cropped.")
                                    ), 
                                    React.createElement(Cmp.Picker.File, {ref: "filePicker", dropZoneText: "To upload your image, drag an image from your desktop here or", triggerSync: true, groupClassName: "margin-top-20", name: "listingImage", trigger: "onChange", url: props.model.url(), label: "", model: props.model, onComplete: this.onFileUpload, onBeforeUpload: this.onBeforeFileUpload, mime_types: [Constants.imageMimeTypes]})
                                )
                            )
                        ), 

                        React.createElement(Cmp.ValidationMessage, null), 
                        React.createElement(BS.ButtonToolbar, null, 
                            React.createElement(Cmp.Button.Cancel, {onClick: this.goBackToDetails}, React.createElement("i", {className: "fa fa-arrow-left"}), " Back"), 
                            React.createElement(Cmp.Button.Save, {className: "pull-right"}, "Save & Finish ", React.createElement("i", {className: "fa fa-arrow-right"}))
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    },

    onSaveClick: function() {
        var me = this,
            filePicker = me.refs.filePicker,
            model = this.props.model;

        model.save(me.refs.form.getValues()).then(function(m, e, r) {
            if (filePicker.hasFiles()) {
                filePicker.startUpload();
            } else {
                me.onFileUpload(true);
            }
        });

    },

    onFileUpload: function(success) {
        var me = this;

        if (success) {
            me.transitionTo('Project', {
                id: me.props.model.id
            });
        };
    },

    goBackToDetails: function() {
        this.transitionTo('ProjectWizardDetails', {
            id: this.props.model.id
        });
    },

    reset: function() {
        var me = this;

        me.setState(me.updateState(), function() {
            me.refs.form && me.refs.form.forceUpdate();
        });
    }
});
module.exports = ProjectWizardWidgetImage;
