"use strict";

var ModelClass = require('./Model'),
    Backbone = require('backbone'),
    Constants = require('../constants'),
    _ = require('underscore'),
    ProjectImages = require('../collections/ProjectImages'),
    TemplateImages = require('../collections/TemplateImages'),
    ProjectFloorPlans = require('../collections/FloorPlans'),
    ProjectDocuments = require('../collections/ProjectDocuments'),
    ProjectPromotions = require('../collections/Promotions'),
    ProjectContacts = require('../collections/ProjectContacts'),
    LocationInfo = require('./LocationInfo'),
    ContactInfo = require('./ContactInfo'),
    Developer = require('./Developer'),
    Crypto = require('../shared/Crypto'),
    URI = require('urijs'),
    utility = require('../shared/Utilities'),
    moment = require('moment');

var Project = ModelClass.extend({
    urlRoot: Constants.restPath + "/projects",
    toolboxURL: function() {
        return URI(Constants.toolboxHost).protocol('http').addSearch({
            API: this.get('apiKey')
        }).toString();
    },
    idAttribute: 'id',
    strictSave: false,
    hasMany: {
        ProjectImages: 'images',
        BrochureImages: 'brochureImages',
        EmailImages: 'emailImages',
        ProjectFloorPlans: 'floorPlans',
        ProjectDocuments: 'documents',
        ProjectPromotions: 'promotions',
        ProjectContacts: 'listingPocs'
    },
    hasOne: {
        Developer: 'developer',
        ContactInfo: {
            responseKey: 'contactInfo',
            alwaysInclude: true
        },
        LocationInfo: {
            responseKey: 'locationInfo',
            alwaysInclude: true
        }
    },
    imageValidation: {
        listingImage: {
            required: true
        }
    },
    validation: {
        realName: {
            required: true
        },

        website: {
            required: true
                // pattern: 'url'
        },
        listingTypes: {
            required: true
                // Custom logic needed
        },
        minPrice: {
            required: true,
            pattern: 'number'
        },
        maxPrice: {
            required: false,
            pattern: 'number'
        },
        numberOfUnits: {
            required: false,
            pattern: 'number'
        },
        numberOfFloors: {
            required: false,
            pattern: 'number'
        },
        developmentPhase: {
            required: false
        },
        summary: {
            required: true
        },
        locationInfo: utility.locationValidationNoAddress,
        marketingTagLineEmail: function(value, attr, computedState) {
            if (!value && this.validateFilter === 'agentEmail') {
                return 'Tagline is required.';
            }
        },
        marketingMessageEmail: function(value, attr, computedState) {
            if (!value && this.validateFilter === 'agentEmail') {
                return 'Content is required.';
            }
        },
        marketingTagLineBrochure: function(value, attr, computedState) {
            if (!value && this.validateFilter === 'agentBrochure') {
                return 'Tagline is required.';
            }
        },
        marketingMessageBrochure: function(value, attr, computedState) {
            if (!value && this.validateFilter === 'agentBrochure') {
                return 'Content is required.';
            }
        }

    },
    validationUses: {
        "locationInfo": ['customLocation']
    },
    labels: {
        "realName": "Project Name",
        "website": "Project URL",
        "listingTypes": "Project Type",
        "minPrice": "Starting Price (USD)",
        "maxPrice": "Max Price (USD)",
        "summary": "Project Summary"
    },
    defaults: function() {
        return {
            id: undefined,
            listingTypes: [{
                id: 1,
                name: 'Condo'
            }],
            claimed: false,
            serviceTypes: [],
            apiKey: '',
            image: null,
            iconUrl: null,
            imageUrl: null,
            name: '',
            minPrice: null,
            maxPrice: null,
            expirationDate: undefined,
            showDisclaimer: false,
            showAgentToolbar: false,
            dateCreated: undefined,
            outOfStateReferralOffered: '',
            requiresApproval: false,
            showToAgents: false,
            certAppendQueryStringToAllLinks: false,
            dataProvider: '',
            importDevelopmentId: undefined,
            receiveNotificationEmails: true,
            geoPosition: {
                latitude: 26.1161,
                longitude: -81.6005
            },
            listingSource: 'agentshield',
            // locationLat: null,
            // locationLong: null,
            amenities: [],
            marketingNetworks: [],
            widgetImages: [],
            hasFeatured: false,
            hasFlyAndBuySpecial: false,
            hasBuyerSpecial: false,
            website: '',
            description: '',
            summary: '',
            contactName: '',
            developerNotes: ''
        };
    },

    toJSON: function(options) {
        var data = ModelClass.prototype.toJSON.apply(this, arguments),
            currentUser = require('../shared/CurrentUser'),
            marketingNetworks = [],
            agentString;

        options = options || {}

        if (!options.fields || options.fields.indexOf('locationInfo') !== -1 || options.fields.indexOf('location') !== -1) {
            data.location = data.locationInfo && data.locationInfo.city ? _.compact([data.locationInfo.city.name, data.locationInfo.city.state.name, data.locationInfo.city.country.name]).join(', ') : '';
        }

        if (!data.listingType && (!options.fields || options.fields.indexOf('listingTypes') !== -1 || options.fields.indexOf('listingType') !== -1)) {
            data.listingType = _.uniq(_.map(data.listingTypes, function(listingType, index) {
                return listingType.name;
            })).join(', ');
        }

        if (options.url) {
            // Sync occurring
            if (!currentUser.hasRole(Constants.roles.ADMIN)) {
                delete data.marketingNetworks;
            }
            delete data.defaultServiceTypes;
            delete data.defaultActive;
            delete data.widgetImages;

            data.minPrice && (data.minPrice = parseInt(String(data.minPrice).replace(/,|\s*/g, ''), 10));
            data.maxPrice && (data.maxPrice = parseInt(String(data.maxPrice).replace(/,|\s*/g, ''), 10));

            if (data.geoPosition && !data.geoPosition.latitude) {
                delete data.geoPosition;
            }

            data.name = data.realName;
            delete data.realName;
            delete data.listingImage;
            delete data.location;
            delete data.image;

            // [AS-1446] - Change Location section in Listing information screen   
            if (data.customLocation && data.locationInfo) {
                delete data.locationInfo.address1;
                delete data.locationInfo.address2;
                delete data.locationInfo.zip;
                // delete data.locationInfo.city;
            }
        } else {
            data.websiteRedirect = Constants.server + '/go/' + this.id + '?';

            agentString = this.getAgentId();

            if (agentString) {
                data.websiteRedirect = data.websiteRedirect + 'as=' + encodeURIComponent(Crypto.encrypt(agentString));
            }

            // data.websiteRedirect = data.websiteRedirect + 't=' + encodeURIComponent(Crypto.encrypt(moment.utc().format('x')));

            if (data.thumbLink) {
                data.imageFullSize = data.image;
                data.image = data.thumbLink;
            }

            if (data.lastUpdated) {
                data.lastUpdated = moment.utc(data.lastUpdated, 'x');
            }

            if (data.dateApproved) {
                data.dateApproved = moment.utc(data.dateApproved, 'x');
            }
        }

        return data;
    },

    parse: function(response) {
        var data = ModelClass.prototype.parse.apply(this, arguments),
            location;

        if (!data) {
            data = {};
        } else {
            data.realName = data.name;
            data.name = data.displayName || data.name;
        }

        if (data.charges) {
            data.serviceTypes = _.keys(data.charges);
            data.id = data.listingId || data.id;
        }

        if (data.geoPosition && ((data.geoPosition.latitude == 4 && data.geoPosition.longitude == -72) || (data.geoPosition.latitude == 0 && data.geoPosition.longitude == 0))) {
            data.geoPosition = { latitude: undefined, longitude: undefined };
        } else if (data.geoPosition && data.geoPosition.latitude && data.geoPosition.longitude && !this.locationInfoModel.get('address1')) {
            // [AS-1446] - Change Location section in Listing information screen   
            data.customLocation = true;
        }
        if (!data.marketingMessageEmail && data.summary) {
            data.marketingMessageEmail = data.summary;
        }

        if (!data.marketingMessageBrochure && data.summary) {
            data.marketingMessageBrochure = data.summary;
        }

        data.defaultServiceTypes = data.serviceTypes || this.get('serviceTypes') || [];
        data.defaultActive = !!(data.active || this.get('active'));

        if (data.imageGallery) {
            this.addImageLinks(data.imageGallery);
        }

        if (!data.location && this.locationInfoModel.id && this.locationInfoModel.cityModel.id) {
            location = this.locationInfoModel.toJSON();
            data.locationCompact = _.compact([location.city.name, location.city.state.abbr]).join(', ');
        }

        if (data.location) {
            location = data.location.split(', ');
            data.locationFull = _.compact([location[0], location[1], location[2]]).join(', ');
            data.locationFullCompact = _.compact([location[0], location[1], utility.abbrState(location[2], 'abbr')]).join(', ');
            data.locationCompact = _.compact([location[1], utility.abbrState(location[2], 'abbr')]).join(', ');
        }
        // function(image, size, linkAttr, folderName, filenameAttr) {
        // this.addImageLink(data, '300px', 'image', 'Pics', 'image');

        if (data.image) {
            this.addImageLink(data, null, 'image', null, 'image');
            data.image = data.image ? data.image.replace('http://', '//').replace('//www.agentshield.com.s3.amazonaws.com', '//s3.amazonaws.com/www.agentshield.com') : undefined;

            // data.listingImage = data.image;
            data.listingImage = [{
                link: data.image,
                name: data.image.match(/[^\\/]+$/)[0]
            }];
        }

        return data;
    },

    addImageLinks: utility.addImageLinks,
    addImageLink: utility.addImageLink,

    save: function(attributes, options) {
        var idxOfName;

        attributes = attributes || {};
        options = options || {};

        if (options.fields && (idxOfName = options.fields.indexOf('name')) > -1) {
            options.fields[idxOfName] = 'realName';
        }

        if (!this.developerModel.id) {
            var developerId, currentUser = require('../shared/CurrentUser');
            if (currentUser.developerModel && currentUser.developerModel.id) {
                this.developerModel.set('id', currentUser.developerModel.id);
            }
        }

        if (this.id) {
            // [AS-1791] - SyntaxError: Unexpected token < in JSON at position 0
            // jquery-migrate IE fix
            // Caused by 204 No Content response without a response type set.
            options.dataType = 'text';
        }

        return ModelClass.prototype.save.call(this, attributes, options);
    },

    fetchByAPICode: function(apiCode, options) {
        options = options || {};
        options.data = options.data || {};

        if (this.jsonPSupport && this.usingJsonP) {
            options.dataType = 'jsonp';
            options.url = this.rootPath + '/jsonp/projects/by-api/' + apiCode;
        } else {
            options.url = this.urlRoot + '/by-api/' + apiCode;
        }

        return this.fetch(options);
    },

    contactBuilder: function(data, options) {
        options = options || {};
        data = data || {};

        _.extend(data, {
            agent: this.getAgentId(),
            project: this.id
        });

        _.extend(options, {
            validate: false,
            method: 'POST',
            url: Constants.restPath + '/mail/contactdeveloper',
            contentType: 'application/x-www-form-urlencoded',
            data: Backbone.$.param(data)
        });

        return ModelClass.prototype.save.call(this, undefined, options);
    },

    getAgentId: function() {
        var as,
            CurrentUser = require('../shared/CurrentUser');

        if (this.collection && this.collection.parent && this.collection.parent.agentModel && this.collection.parent.agentModel.id) {
            as = this.collection.parent.agentModel.id;
        } else if (CurrentUser.agentModel && CurrentUser.agentModel.id) {
            as = CurrentUser.agentModel.id;
        }
        return as;
    },
    getServiceLevel: function() {
        return 0;
    },

    hasServiceType: function() {
        var typesToCheck = arguments,
            serviceTypes = this.get('serviceTypes') || [],
            hasType = false;

        for (var i = 0, len = typesToCheck.length; i < len; i++) {
            hasType = serviceTypes.indexOf(typesToCheck[i]) !== -1;
            if (!hasType) {
                break;
            }
        }

        return hasType;
    },

    isActive: function() {
        return !!this.get('active');
    },

    initHasMany: function() {
        ModelClass.prototype.initHasMany.apply(this, arguments);
        this.emailImagesCollection.templateKey = 'email';
        this.brochureImagesCollection.templateKey = 'brochure';
    }
}, {
    ProjectImages: ProjectImages,
    BrochureImages: TemplateImages,
    EmailImages: TemplateImages,
    ProjectFloorPlans: ProjectFloorPlans,
    ProjectDocuments: ProjectDocuments,
    ProjectPromotions: ProjectPromotions,
    ProjectContacts: ProjectContacts,
    Developer: Developer,
    ContactInfo: ContactInfo,
    LocationInfo: LocationInfo
});

module.exports = Project;
