"use strict";

module.exports = {
    trackingPath: '//api.agentshield.com/t/1x1clear.gif' + '?t=' + (new Date()).getTime(),
    server: 'http://app.agentshield.com',
    restPath: '/agent-shield-1.0/rest',
    apiPath: '/agent-shield-1.0',
    widgetHost: 'https://widgets.agentshield.com',
    intercomAppId: 'ms2t4wtj',
    intercomAppIdAgent: 'ms2t4wtj',
    segmentId: 'V51M9LOJIz3xt5Sce07Cn2AfekK6Ocye',
    rollbarEnabled: true,
    rollbarId: "7a90fad9cc7144689883252a2f5e888f",
    googleAnalyticsId: 'UA-462934-5',
    stripeKey: 'pk_live_3Gx7VPCKnQ61bFXPIQB9IKLf',
    toolboxHost: '//toolbox.agentshield.com',
	CONTACTUALLY_URL: "https://www.contactually.com/oauth2/authorize?client_id=dcaaaff73d22b5deb98355be3f61b4e7c751c8d4ba3826d7cd4efee37f1da528&amp;redirect_uri=https%3A%2F%2Fapp.agentshield.com%2Fagent-shield-1.0%2Frest%2Fcallback&amp;response_type=code&amp;scope=user:login+contacts:manage+interactions:manage+buckets:view"
};


