module.exports = {

        ListingNavigation : function(collectionName){
            getListingModelType = function(){
                var routes = this.context.router.getCurrentRoutes();

                for(var i = routes.length - 1; i > 0; i--){
                    var route = routes[i].name;
                    if (route.indexOf('Builder') > -1){
                        return 'Builder';
                    }
                    if (route.indexOf('Project') > -1){
                        return 'Project';
                    }
                }
            };
            var mixin = {
                addTo : function(){
                    var entityType = getListingModelType.call(this);
                    return entityType + 'Add' + collectionName;
                },
                editTo : function(){
                    var entityType = getListingModelType.call(this);
                    return entityType + 'Edit' + collectionName;
                },
                backTo : function(){
                    var entityType = getListingModelType.call(this);
                    return entityType + 'Edit' + collectionName + 's';
                },
                isDeveloper : function() {
                    return this.props.model.listingModelType === 'developer';
                }
            }
            return mixin;
        }

};