"use strict";

// Partially dervived from https://github.com/christianalfoni/formsy-react

var React = require('react/addons'),
    BS = require('react-bootstrap'),
    cx = require('classnames'),
    ReactCatalyst = require('../../mixins/react-catalyst'),
    FluxBone = require('../../mixins/FluxBone'),
    _ = require('underscore'),
    MessagePartial = require('../Message'),
    Utility = require('../../shared/Utilities'),
    ValidationFieldWrapper = require('./ValidationFieldWrapper'),
    $ = require('jquery');

var ValidationForm = React.createClass({displayName: "ValidationForm",

    mixins: [
        ReactCatalyst.LinkedStateMixin,
        FluxBone.ModelMixin('model', 'sync reset')
    ],

    getDefaultProps: function() {
        return {
            model: undefined,
            modelLinkToState: true,
            onValidityChange: undefined,
            message: undefined,
            error: undefined,
            success: undefined,
            clearMessagesOnSubmit: true,
            horizontal: true,
            validateOnLoad: true,
            skipValidation: false,
            readOnly: false,
            handleDirty: false,
            showReadOnlyMessage: false,
            validateFilter: undefined,
            disableValidation: false,
            readOnlyMessage: (React.createElement("div", null, "This form is ", React.createElement("b", null, "Read Only"), ". You will not be able to make changes to this screen."))
        };
    },

    componentWillMount: function() {
        this.inputs = {};
    },

    componentDidMount: function() {
        if (this.props.model.id) {
            this._onFormChange();
        } else {
            this.modelUpdateState();
        }

        this.applyHandleDirty(this.props.handleDirty);
    },

    componentWillUnmount: function(){
        this.applyHandleDirty(false);
    },

    modelUpdateState: function() {
        if (this.props.validateOnLoad) {
            this.validateState();
        }
        this.setState({
            isDirty: false
        });
    },

    updateState: function(props){
        props = props || this.props;
        return {
            isValid: props.skipValidation || props.disableValidation ? true : false,
            readOnly: props.readOnly
        };
    },

    getInitialState: function() {
        var state = this.updateState();

        this._onFormChange = _.debounce(this.onFormChange, 10);
        state.isDirty = false;

        return state;
    },

    componentWillReceiveProps: function(newProps) {
        if(newProps.readOnly !== this.props.readOnly){
            this.setState(this.updateState(newProps));
        }

        if(newProps.handleDirty !== this.props.handleDirty){
            this.applyHandleDirty(newProps.handleDirty);
        }
    },

    applyHandleDirty: function(handleDirty){
        if(handleDirty){
            $(window).bind('beforeunload', this.onBeforeUnload);
        } else {
            $(window).unbind('beforeunload', this.onBeforeUnload);
        }
    },

    isDirty: function(){
        return this.state.isDirty;
    },

    getDirtyMessage: function(){
        if(this.isDirty()){
            return 'Form has unsaved changes. Are you sure you want to continue?';
        }
    },

    onBeforeUnload: function(e){
        var message = this.getDirtyMessage();

        if(message){
            e.returnValue = message;
        }
        
        return message || undefined;
    },

    // Traverse the children and children of children to find
    // all inputs by checking the name prop. Maybe do a better
    // check here
    traverseChildrenAndRegisterInputs: function(children) {

        if (typeof children !== 'object' || children === null) {
            return children;
        }
        return React.Children.map(children, function(child) {

            if (typeof child !== 'object' || child === null) {
                return child;
            }

            if (child.props && (child.props.name || child.props.formLinks || child.props.fieldLinks)) {
                return React.createElement(ValidationFieldWrapper, this.getWrapperProps(child), React.cloneElement(child, {}, this.traverseChildrenAndRegisterInputs(child.props && child.props.children)));
            } else {
                return React.cloneElement(child, {}, this.traverseChildrenAndRegisterInputs(child.props && child.props.children));
            }

        }, this);

    },

    validateState: function(validateAll) {
        var me = this, isValid = true,
            validateOnly = me.props.validateOnly;

        _.every(me.inputs, function(input, name) {
            if(!validateOnly || validateOnly.indexOf(name) !== -1) {
                isValid = me.validate(input).isValid;
            }
            return validateAll === true || isValid;
        });

        return isValid;
    },

    getWrapperProps: function(child) {
        var readOnly = this.props.readOnly,
            props = {
                attachToForm: this.attachToForm,
                detachFromForm: this.detachFromForm,
                validate: this.validate,
                valueLink: readOnly ? undefined : this.setupValueLink(child),
                value: readOnly ? this.getInState('model.' + child.props.name) : undefined,
                formLinks: this.getFormLinks(child),
                fieldLinks: this.getFieldLinks(child),
                getValues: child.props.getValues,
                hasName: !!child.props.name,
                validateAlso: child.props.validateAlso,
                ignoreChange: child.props.ignoreChange,
                isValidValue: function(component, value) {
                    return this.runValidation(component, value)._isValid;
                }.bind(this)
            },
            validation = this.props.model.validation[child.props.name],
            required = validation && validation.required;

        props.required = child.props.required || required && (_.isFunction(required) ? required(this.getInState('model.' + child.props.name), child.props.name, this.state.model) : !!required);

        if(required === undefined && _.isFunction(validation)){
            props.required = child.props.required || !!validation.call(this.props.model, this.getInState('model.' + child.props.name), child.props.name, this.state.model);
        }

        if(this.props.disableValidation) {
            props.required = false;
        }

        return props;
    },

    // These links are two way. Form <-> Child. Works the same way as checkedLink or valueLink work in normal components.
    getFieldLinks: function(child) {
        var fieldLinks, me = this;

        if (child.props.fieldLinks) {
            fieldLinks = {};
            _.each(child.props.fieldLinks, function(name, key) {
                var requestChange,
                    // fieldLinks must reference model state
                    link = name.indexOf('model.') === 0 ? name : 'model.' + name;
                fieldLinks[key] = this.linkState(link);
                requestChange = fieldLinks[key].requestChange;
                fieldLinks[key].requestChange = function(value) {
                    var input = me.inputs[name];
                    requestChange(value);

                    if (input && input.getValue() !== value) {
                        input.setValue(value);
                    }

                };
            }.bind(this));
        }

        return fieldLinks;
    },

    // These links are one way. Form -> Child. Basically a way to get the readOnly state of a form state value.
    getFormLinks: function(child) {
        var formLinks;

        if (child.props.formLinks) {
            formLinks = {};
            _.each(child.props.formLinks, function(link, key) {
                if (_.isFunction(link)) {
                    formLinks[key] = link(this.state);
                } else {
                    formLinks[key] = this.getInState(link);
                }
            }.bind(this));
        }

        return formLinks;
    },

    updateLinks: function() {
        var me = this,
            validation = this.props.model.validation;

        _.each(this.inputs, function(input, key) {
            var formLinks = me.getFormLinks(input.props.children),
                fieldLinks = me.getFieldLinks(input.props.children),
                childValidation = validation[key],
                required = childValidation && childValidation.required,
                isRequired, state = {};

            if (formLinks || fieldLinks) {
                input.setState({
                    formLinks: formLinks,
                    fieldLinks: fieldLinks
                });
            }

            isRequired = required && (_.isFunction(required) ? required(me.getInState('model.' + key), key, me.state.model) : !!required);

            if(_.isFunction(childValidation)){
                isRequired = childValidation.call(me.props.model, me.getInState('model.' + key), key, me.state.model);
            }

            state.required = !!isRequired;
            if(state.required && _.isString(isRequired)) {
                state.validationError = isRequired;
            }

            input.setState(state);

        });
    },

    getInState: function(mapping) {
        var me = this,
            state = me.state,
            value, stack,
            isNegated = false,
            isBoolean = false;

        if (typeof mapping === 'function') {
            value = mapping(state);
        } else if (typeof mapping === 'string') {
            value = state;
            if(mapping[0] === '!' && mapping[1] === '!') {
                isBoolean = true;
                mapping = mapping.substring(2);
            } else if (mapping[0] === '!') {
                isNegated = true;
                mapping = mapping.substring(1);
            }
            stack = mapping.split('.');
            while (stack.length > 1) {
                value = value[stack.shift()];
            }
            value = value[stack.shift()];

            if (isNegated) {
                value = !value;
            } else if(isBoolean) {
                value = !!value;
            }
        }

        return value;
    },

    updateIn: function(object, path, value) {
        var current = object, stack = path.split('.'), key;
        while (stack.length > 1) {
            key = stack.shift();
            if(!current[key]){
                current[key] = {};
            }
            current = current[key];
        }
        current[stack.shift()] = value;
        return object;
    },

    shouldComponentUpdate: function(nextProps, nextState) {
        var props = this.props,
            state = this.state;
        // console.log('should update?', this.props.legacy, nextState.isValid !== this.state.isValid || nextState.model !== this.state.model);
        return props.children !== nextProps.children || nextProps.hidden !== props.hidden || nextState.isValid !== state.isValid || nextState.model !== state.model || nextProps.success !== props.success || nextProps.error !== props.error || nextProps.message !== props.message || props.readOnly !== nextProps.readOnly;
    },

    componentDidUpdate: function() {
        this._onFormChange();
    },

    validate: function(component) {
        var validation;
        // Run through the validations, split them up and call
        // the validator IF there is a value or it is required
        if (component) {
            validation = this.runValidation(component);
            component.setState({
                isValid: validation.isValid,
                validationError: validation.error
            }, this._onFormChange);
        } else {
            this._onFormChange();
        }

        return validation;
    },

    onFormChange: function() {
        var validation = this.runValidation(),
            wasValid = this.state.isValid;

        this.isMounted() && this.setState({
            isValid: validation.isValid,
            validationError: validation.error
        }, function() {
            if (wasValid !== this.state.isValid) {
                if (this.props.onValidityChange) {
                    this.props.onValidityChange(this.state.isValid);
                }
                if(this.state.isValid){
                    this.clearErrors();
                }
            } else {
                this.updateLinks();
            }
            if (this.props.onFormChange) {
                this.props.onFormChange(this.getValues());
            }
        });
    },

    // Checks validation on current value or a passed value
    runValidation: function(component, value) {
        var me = this,
            model = me.props.model,
            name = component && component.getName(),
            validationUses = me.props.model.validationUses,
            attrs = {},
            options = {
                fields: me.props.validateOnly,
                filter: me.props.validateFilter
            },
            errors;

        if(me.props.skipValidation || me.props.disableValidation){
            return {
                isValid: true,
                error: undefined
            };
        }

        if (component && name) {
            attrs = me.expandProperty(name, value !== undefined ? value : me.getValue(component));
            // attrs[name] = value !== undefined ? value : me.getValue(component);
            if (validationUses && validationUses[name]) {
                _.each(validationUses[name], function(fieldName) {
                    var referencedComponent = me.inputs[fieldName];
                    if (referencedComponent) {
                        me.expandProperty(fieldName, me.getValue(referencedComponent), attrs);
                    }
                });
            }
        } else {
            attrs = me.getValues();
            options.forceReturn = true;
        }

        errors = me.parseErrors(model.validate(_.isEmpty(attrs) ? undefined : attrs, options), _.compact([name].concat(component ? component.props.validateAlso : undefined)));

        // console.log(errors);

        return {
            isValid: !errors,
            error: errors
        };
    },

    parseErrors: function(errors, names) {
        var error;

        if(errors && names && names.length > 0) {
            _.every(names, function(name) {
                if(errors[name]) {
                    error = errors[name];
                    return false;
                }
            });
        } else {
            error = errors;
        }

        return error;
    },

    expandProperty: Utility.expandProperty,

    attachToForm: function(component, name) {
        name = name || component.props.name;

        if (!name) {
            throw '"Name", "key", "id" not found for component. Anything using [formLinks] or [fieldLinks] must have a name, key, or id.'
        }
        this.inputs[name] = component;
    },

    detachFromForm: function(component, name) {
        name = name || component.props.name;
        delete this.inputs[name];
    },

    render: function() {
        var children = this.traverseChildrenAndRegisterInputs(this.props.children),
            className = cx('form', 'sky-form', {
                "form-horizontal": this.props.horizontal
            }, this.props.className),
            props = _.omit(this.props, 'className', 'onSubmit', 'success', 'error', 'getMessage'),
            FormTag = this.props.legacy ? 'div' : 'form';

        return (
            React.createElement(FormTag, React.__spread({className: className},  props, {onSubmit: this.onSubmit}), 
                React.createElement(MessagePartial, {ref: "message", dispatcher: props.model, getMessage: this.props.getMessage, singleMessageOfType: true}), 

                this.props.error && (
                    React.createElement(BS.Alert, {bsStyle: "danger", onDismiss: this.props.onMessageDismiss ?  this.onMessageDismiss.bind(null, 'error') : undefined}, 
                        this.props.error
                    )
                ), 
                this.props.success && (
                    React.createElement(BS.Alert, {bsStyle: "success", onDismiss: this.props.onMessageDismiss ?  this.onMessageDismiss.bind(null, 'success') : undefined}, 
                        this.props.success
                    )
                ), 
                this.props.message && (
                    React.createElement(BS.Alert, {bsStyle: "info", onDismiss: this.props.onMessageDismiss ?  this.onMessageDismiss.bind(null, 'message') : undefined}, 
                        this.props.message
                    )
                ), 
                this.props.readOnly && this.props.showReadOnlyMessage && (
                    React.createElement(BS.Alert, {bsStyle: "warning"}, 
                        this.props.readOnlyMessage
                    )
                ), 
                children
            )
        );
    },


    setupValueLink: function(component) {
        var me = this,
            valueLink = component.props.valueLink,
            valueLinkReal;
        if (!valueLink && component.props.name) {
            valueLinkReal = this.linkState('model.' + component.props.name);
            valueLink = {
                value: valueLinkReal.value,
                requestChange: function(value, ignoreChange){
                    var props = component.props;
                    if(props.integer) {
                        value = parseInt(value, 10);
                    } else if(props.float) {
                        value = parseFloat(value);

                        if(props.precision) {
                            value = parseFloat(value.toFixed(props.precision));
                        }
                    }
                    valueLinkReal.requestChange(value);
                    if(!component.props.ignoreChange && ignoreChange !== true && valueLinkReal.value !== value){
                        if(!me.state.isDirty){
                            me.setState({ isDirty: true });
                        }
                        if(component.props.onValueChange){
                            component.props.onValueChange(value);
                        }
                    }
                }
            }
        }
        return valueLink;
    },


    onSubmit: function(e) {
        if (e) {
            e.preventDefault();
        }

        if (this.props.clearMessagesOnSubmit) {
            this.clearMessages();
        }

        if (this.props.onSubmit) {
            this.props.onSubmit(e);
        }
    },

    getValue: function(component) {
        return component.getValue()
    },

    isValid: function() {
        return this.state.isValid;
    },

    getValues: function(useComponentValues) {
        var me = this,
            values;

        if(useComponentValues){
            values = {};
            _.each(me.inputs, function(component, name){
                if(component.props.hasName){
                    me.updateIn(values, name, me.getValue(component));
                }
            });
        } else {
            values = this.state.model;
        }

        if(this.props.forms){
            _.extend(values, this.getLinkedFormValues());
        }
        
        return $.extend(true, {}, values);
    },

    clearErrors: function(){
        var me = this;

        _.each(me.inputs, function(component, name){
            component.setState({
                isValid: true,
                validationError: undefined
            });
        });
    },

    onMessageDismiss: function(property) {
        if (this.props.onMessageDismiss) {
            this.props.onMessageDismiss(property);
        }
    },

    clearMessages: function() {
        this.refs.message.clearMessages();
    },

    getLinkedFormValues: function(){
        var me = this,
            values = {};

        _.each(me.props.forms, function(name){
            if(me.inputs[name] && me.inputs[name].getValues){
                _.extend(values, me.inputs[name].getValues(true));
            }
        });

        return values;
    }
});

module.exports = ValidationForm;
