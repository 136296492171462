'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var _Calendar = require('./Calendar');

var _Calendar2 = _interopRequireDefault(_Calendar);

var _Picker = require('./Picker');

var _Picker2 = _interopRequireDefault(_Picker);

var _MonthCalendar = require('./MonthCalendar');

var _MonthCalendar2 = _interopRequireDefault(_MonthCalendar);

_Calendar2['default'].Picker = _Picker2['default'];
_Calendar2['default'].MonthCalendar = _MonthCalendar2['default'];

exports['default'] = _Calendar2['default'];
module.exports = exports['default'];