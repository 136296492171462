"use strict";

var ModelClass = require('./Model'),
    Constants = require('../constants'),
    _ = require('underscore');

var FloorPlan = ModelClass.extend({
    urlRoot: Constants.restPath + "/floor-plans",
    idAttribute: 'id',
    validation: {
        floorPlanName: {
            required: true
        },
        startingPrice: {
            pattern: 'number',
            required: false
        },
        endingPrice: {
            pattern: 'number',
            required: false
        },
        bedrooms: {
            pattern: 'number',
            oneOf: [0, 1, 2, 3, 4, 5],
            required: false
        },
        fullBaths: {
            pattern: 'number',
            oneOf: [0, 1, 2, 3, 4, 5],
            required: false
        },
        halfBaths: {
            pattern: 'number',
            oneOf: [0, 1, 2, 3, 4, 5],
            required: false
        },
        livingAreaSqft: {
            pattern: 'number',
            required: false
        },
        totalAreaSqft: {
            pattern: 'number',
            required: false
        }
    },
    defaults: function() {
        return {
            id: undefined,
            floorPlanName: '',
            description: '',
            bedrooms: 0,
            fullBaths: 0,
            halfBaths: 0,
            livingAreaSqft: null,
            totalAreaSqft: null,
            startingPrice: null,
            endingPrice: null,
            createDate: new Date(),
            lastUpdated: new Date(),
            files: []
                // file: null,
                // link: '',
                // fileName: 'Current File'
        };
    },

    parse: function(response) {
        var data = ModelClass.prototype.parse.apply(this, arguments);

        if (data && data.files) {
            _.each(data.files, function(file) {
                file.name = file.originalFileName || (file.link.match(/[^\\/]+$/) || [])[0] || file.title;
                file.status = 5; // plupload.DONE
            });
        }

        return data;
    },

    toJSON: function(options) {
        var data = ModelClass.prototype.toJSON.apply(this, arguments);

        options = options || {};

        if (options.url) {
            data.startingPrice && (data.startingPrice = parseInt(String(data.startingPrice).replace(/,|\s*/g, ''), 10));
            data.endingPrice && (data.endingPrice = parseInt(String(data.endingPrice).replace(/,|\s*/g, ''), 10));

            delete data.files;
        }

        // if (options.url) {
        //     delete data.file;
        // } else if (!data.file && data.link) {
        //     data.file = {
        //         link: data.link,
        //         name: data.fileName
        //     };
        // }

        return data;
    }

});

module.exports = FloorPlan;
