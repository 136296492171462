"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    _ = require('underscore'),
    FluxBone = require('../mixins/FluxBone'),
    Developers = require('../collections/Developers'),
    Validation = require('../partials/validation');

var DeveloperSelect = React.createClass({displayName: "DeveloperSelect",

    mixins: [
        FluxBone.CollectionMixin('store', 'sync')
    ],

    getDefaultProps: function() {
        var store = new Developers();
        store.pageSize = null;
        store.disableFilters = true;
        return {
            store: store,
            defaultText: 'Select',
            trigger: "onChange",
            checkOnSync: true
        };
    },

    componentWillMount: function() {
        var me = this,
            props = me.props;

        if (props.stateId || props.countryId || props.cityId || props.metroId) {
            props.store.fetch({
                stateId: props.stateId,
                countryId: props.countryId,
                cityId: me.normalizeFetchProps(props.cityId),
                metroId: props.metroId,
                traditional: true
            });
        }
    },

    componentWillReceiveProps: function(newProps) {
        var me = this,
            props = this.props;

        if (newProps.disabled && newProps.disabled !== props.disabled && newProps.valueLink) {
            newProps.valueLink.requestChange('');
        }

        if (!props.countryId && !props.stateId && !props.metroid && !props.cityId && me.getValue()) {
            this.resetDeferred();
        } else if (newProps.countryId !== props.countryId || newProps.stateId !== props.stateId || newProps.metroId !== props.metroId || newProps.cityId !== props.cityId) {
            props.store.fetch({
                stateId: newProps.stateId,
                countryId: newProps.countryId,
                cityId: me.normalizeFetchProps(newProps.cityId),
                metroId: newProps.metroId,
                traditional: true
            }).then(function() {
                var value = me.getValue();

                if (value && me.props.checkOnSync && me.props.valueLink && !me.props.store.get(value)) {
                    me.props.valueLink && me.props.valueLink.requestChange('');
                }
            });
        }
    },

    normalizeFetchProps: function(value) {
        var newValue;

        if (_.isArray(value) && value.length > 0 && _.isObject(value[0])) {
            newValue = _.map(value, function(obj) {
                return obj.id;
            });
        } else {
            newValue = value;
        }

        return newValue;
    },

    render: function() {
        var props = this.props;

        return (
            React.createElement(Validation.Input, React.__spread({ref: "input", label: "Developers:"},  props, {type: "select"}), 
                React.createElement("option", {value: ""}, props.defaultText), 
                props.store.map(function(developer){
                    var data = developer.toJSON();
                    return (
                        React.createElement("option", {key: data.id, value: data.id}, data.name)
                    );
                })
            )
        );
    },

    getDisplayValue: function() {
        var value = this.refs.input.getValue(),
            id = value ? parseInt(value) : undefined,
            model,
            displayValue;

        if (id) {
            model = this.props.store.get(id);
        }

        if (model) {
            displayValue = model.get('name');
        }

        return displayValue
    },

    resetDeferred: function() {
        var me = this;
        this.resetTimeout = setTimeout(function() {
            me.props.store.reset();
            me.props.valueLink && me.props.valueLink.requestChange('');
        }, 100)
    },

    clearReset: function() {
        clearTimeout(this.resetTimeout);
        delete this.resetTimeout;
    },

    getValue: function() {
        return this.refs.input ? this.refs.input.getValue() : undefined;
    }
});

module.exports = DeveloperSelect;
