"use strict";

function getIn(object, path) {
    var stack = path.split('.');
    while (stack.length > 1) {
        object = object[stack.shift()];
    }
    return object[stack.shift()];
}

module.exports = function(model_name, event_name, callback_name) {
    var mixin = {
        componentWillReceiveProps: function(props) {
            var model = getIn(this.props, model_name),
                newModel = getIn(props, model_name);

            if (model !== newModel) {
                if (model) {
                    model.off(event_name, this[callback_name]);
                }
                if (newModel) {
                    model.on(event_name, this[callback_name]);
                }
            }
        },

        componentDidMount: function() {
            var model = getIn(this.props, model_name);

            if (model) {
                model.on(event_name, this[callback_name]);
            }
        },

        componentWillUnmount: function() {
            var model = getIn(this.props, model_name);

            if (model) {
                model.off(event_name, this[callback_name]);
            }
        }
    };

    return mixin;
};
