"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    FluxBone = require('../../mixins/FluxBone'),
    ProjectModel = require('../../models/Project'),
    ReactCatalyst = require('../../mixins/react-catalyst'),
    RouteHandler = ReactRouter.RouteHandler;

var FloorPlanHandler = React.createClass({displayName: "FloorPlanHandler",

    mixins: [
        FluxBone.ModelMixin('model.floorPlansCollection', 'change'),
    ],
    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            model =  this.props.model,
            floorPlanId = params.floorplanid,
            floorPlanModel;


            model.floorPlansCollection && (floorPlanModel = model.floorPlansCollection.get(floorPlanId));

        return {
            floorPlanModel : floorPlanModel
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function(){
        return {
            model: new ProjectModel()
        };
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state;

        return (
            React.createElement(RouteHandler, {model: props.model, floorplan: state.floorPlanModel, readOnly: props.readOnly || (state.floorPlanModel && state.floorPlanModel.isReadOnly())})
        );
    }
});
module.exports = FloorPlanHandler;
