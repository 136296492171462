'use strict';

var React = require('react'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    DocumentTitle = require('react-document-title'),
    CurrentUser = require('../../../shared/CurrentUser'),
    Constants = require('../../../constants'),
    Cmp = require('../../../components'),
    Widget = require('../../../models/Widget'),
    FluxBone = require('../../../mixins/FluxBone'),
    Link = ReactRouter.Link;

var AgentDefaultWidget = React.createClass({displayName: "AgentDefaultWidget",
    mixins: [ReactRouter.Navigation, FluxBone.ModelMixin('model', 'sync reset')],
    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            model: new Widget()
        };
    },

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            query = this.context.router.getCurrentQuery(),
            routes = this.context.router.getCurrentRoutes(),
            id = params.id ? parseInt(params.id) : undefined,
            model = this.props.model;

        if (id && model.id !== id) {
            model.clear();
            model.set('id', id);
            model.fetch();
        }

        return {
            id: id
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    componentWillMount: function() {
        this.props.model.clear();
    },

    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state;

        return (
            React.createElement("div", null, 
                React.createElement(DocumentTitle, {title: "NCH - Signup - Default Widget"}), 
                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-xs-12 col-md-10 col-md-offset-1"}, 
                        React.createElement("h3", {className: "blue-header margin-bottom-30"}, 
                            "Next Step: Add your New Construction Widget to your website.", React.createElement("br", null), 
                            React.createElement("small", null, 
                                "We have automatically created a widget with new construction for you." + ' ' +
                                "Your widget is ready to be added to your website."
                            )
                        ), 

                        React.createElement("h4", {className: "margin-bottom-20"}, 
                            "Instructions: Add the new construction widget to your website.", React.createElement("br", null), 
                            React.createElement("small", null, "Below are the instructions and your widget code.")
                        ), 

                        React.createElement("p", null, 
                            React.createElement("span", {className: "badge rounded-2x badge-blue margin-right-10"}, "1"), 
                            "Create a new page on your website called \"New Construction\"."
                        ), 

                        React.createElement("p", null, 
                            React.createElement("span", {className: "badge rounded-2x badge-blue margin-right-10"}, "2"), 
                            "Copy and paste the code below ", React.createElement("strong", null, "into"), " the ", React.createElement("strong", null, "HTML Code"), " or ", React.createElement("strong", null, "Source View"), " of your webpage.", 
                            React.createElement(BS.Input, {readOnly: true, type: "textarea", groupClassName: "padding-left-30", value: this.getWidgetInsertionCode()})
                        ), 

                        React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                            React.createElement(Link, {to: "SignupWidgetHelp", params: { id: state.id}}, 
                                React.createElement(BS.Button, {bsStyle: "link", className: "no-margin padding-left-0"}, 
                                    React.createElement("span", {className: "badge rounded-2x badge-black margin-right-10"}, "?"), 
                                    React.createElement("strong", null, "CLICK HERE"), " to get help from your webmaster."
                                )
                            ), 
                            React.createElement(Link, {to: "Dashboard"}, React.createElement(BS.Button, {bsStyle: "primary", className: "pull-right"}, "Next"))
                        ), 

                        React.createElement("div", {className: "text-center margin-top-20"}, 
                            React.createElement(Link, {to: "Dashboard"}, React.createElement(BS.Button, {bsStyle: "link"}, "Skip adding widget to my website"))
                        ), 

                        this.props.Footer && React.createElement(this.props.Footer, {trackerId: Constants.trackerPrefix + 'signup-agent-widget'})
                    )
                )
            )
        );
    },

    getWidgetInsertionCode: function() {
        var code = '<div id="AgentShield_MyDevelopments_$WID$"></div>\r\n' + '<script src="' + Constants.widgetHost + '/$WID$.js"></script>';

        return code.replace(/(\$WID\$)/g, this.state.id);
    }
});
module.exports = AgentDefaultWidget;
