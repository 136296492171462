"use strict";

var React = require('react'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    Link = ReactRouter.Link,
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    Constants = require('../../constants'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    NavLink = require('../../components/NavLink'),
    Cmp = require('../../components'),
    SidebarNav = require('../../partials/SidebarNav'),
    FluxBone = require('../../mixins/FluxBone'),
    Users = require('../../collections/Users'),
    CurrentUser = require('../../shared/CurrentUser'),
    Roles = require('../../mixins/Roles'),
    Validation = require('../../partials/validation'),
    Pagination = require('../../partials/Pagination');

var Users = React.createClass({displayName: "Users",

    mixins: [
        FluxBone.CollectionMixin('store', 'sync'),
        Roles,
        React.addons.LinkedStateMixin,
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            store: new Users()
        };
    },

    updateState: function() {
        var query = this.context.router.getCurrentQuery(),
            store = this.props.store;

        store.pageNum = query.page || 1;
        store.setFilters(query, {
            resetFilters: true
        });
        store.throttledFetch();

        return store.getFilterValues();
    },

    getInitialState: function() {
        var state = this.updateState();

        state.filtersVisible = true;

        return state;
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    toggleFilters: function(e){
        if(e && e.preventDefault){
            e.preventDefault();
        }

        this.setState({
            filtersVisible: !this.state.filtersVisible
        });
    },

    doSearch: function(e){
        var me = this,
            query = {
                page: 1
            },
            state = me.state;

        if(e && e.preventDefault){
            e.preventDefault();
        }

        _.each(me.props.store.filters, function(filter){
            query[filter] = state[filter];
        });

        this.transitionTo('UserList', undefined, query);
    },

    render: function() {
        var me = this,
            filterHeader = (
                React.createElement("div", null, 
                    React.createElement("h4", {className: "panel-title"}, 
                        "Filters:", 
                        React.createElement("div", {className: "pull-right", title: "Toggle Filter Visibility"}, 
                            React.createElement("i", {className: 'fa fa-fw ' + (this.state.filtersVisible ? 'fa-minus' : 'fa-plus')})
                        )
                    )
                )
            ),
            expanded = this.state.filtersVisible,
            state = this.state;

        return (
        	React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: "NCH - Users Listing"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Users"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(NavLink, {to: "UserList"}, "Users")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement("div", {className: "row half-gutter"}, 
                        React.createElement("div", {className: "col-md-3 md-margin-bottom-40"}, 
                            React.createElement(SidebarNav, null)
                        ), 
                        React.createElement("div", {className: "col-md-9"}, 
                            React.createElement("div", {className: "row half-gutter"}, 
                                React.createElement("div", {className: "col-md-3"}, 
                                    React.createElement("h1", null, "Users:")
                                ), 
                                React.createElement("div", {className: "col-md-6"}, 
                                    React.createElement("p", null, "Below is a list of agents.")
                                ), 
                                React.createElement("div", {className: "col-md-3"}, 
                                    React.createElement(Link, {to: "UserAdd", className: "btn btn-default pull-right"}, React.createElement("i", {className: "fa fa-plus"}), " Add User")
                                )
                            ), 
                            CurrentUser.hasRole(Constants.roles.ADMIN) && (
                                React.createElement(BS.Panel, {className: 'panel-table' + (expanded ? 'panel-table-expanded' : ''), collapsible: true, header: filterHeader, expanded: expanded, onSelect: this.toggleFilters}, 
                                    React.createElement("div", {className: "row"}, 
                                        React.createElement("form", {onSubmit: this.doSearch}, 
                                            React.createElement(Validation.Input, {type: "text", valueLink: this.linkState('username'), groupClassName: "col-sm-6", placeholder: "Search by Username"}), 
                                            React.createElement(Validation.Input, {type: "text", valueLink: this.linkState('email'), groupClassName: "col-sm-6", placeholder: "Search by Email"}), 
                                            React.createElement(Validation.Input, {type: "checkbox", checkedLink: this.linkState('isActive'), value: true, groupClassName: "col-sm-6", label: "Is Active"}), 
                                            React.createElement("div", {className: "col-xs-12"}, 
                                                React.createElement(BS.Button, {className: "pull-right", type: "submit"}, "Search")
                                            )
                                        )
                                    )
                                )
                            ), 
                            React.createElement(Pagination, {store: this.props.store, to: "UserList", query: this.props.store.getFilterValues()}), 
                            React.createElement(BS.Table, {striped: true, bordered: true, condensed: true, hover: true, id: "agents-table"}, 
                                React.createElement("thead", null, 
                                    React.createElement("tr", null, 
                                        React.createElement("th", null, React.createElement("i", {className: "fa fa-fw fa-flash"}), " Username"), 
                                        CurrentUser.hasRole(Constants.roles.ADMIN) && (
                                            React.createElement("th", null, React.createElement("i", {className: "fa fa-fw fa-flash"}), " Active")
                                        ), 
                                        React.createElement("th", null, React.createElement("i", {className: "fa fa-fw fa-flash"}), " Actions")
                                    )
                                ), 
                                React.createElement("tbody", null, 
                                this.props.store.map(function(agent){
                                    var data = agent.toJSON();
                                    return (
                                        React.createElement("tr", {key: agent.id}, 
                                            React.createElement("td", null, 
                                                React.createElement(Link, {to: "UserEdit", params: { id: data.id}}, data.username)
                                            ), 
                                            CurrentUser.hasRole(Constants.roles.ADMIN) && (
                                                React.createElement("td", null, React.createElement("input", {type: "checkbox", checked: data.active, onChange: me.toggleBoolean.bind(null, 'active', agent)}))
                                            ), 
                                            React.createElement("td", null, 
                                                me.hasRole(Constants.roles.ADMIN) && (
                                                    React.createElement("a", {href: "#", onClick: me.impersonateUser.bind(null, agent)}, React.createElement("i", {className: "fa fa-eye"}), " Impersonate")
                                                )
                                            )
                                        )
                                    );
                                })
                                )
                            ), 
                            React.createElement(Pagination, {store: this.props.store, to: "UserList", noMargin: true, query: this.props.store.getFilterValues()})
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    },

    toggleBoolean: function(field, record) {
        var data = {};
        data[field] = !record.get(field);
        record.save(data, {
            validate: false,
            fields: [field]
        });
    },

    impersonateUser: function(user, e){
        var me = this;
        e.preventDefault();
        CurrentUser.impersonateUser(user.get('username')).then(function(){
            me.transitionTo('Dashboard');
        });
    }
});

module.exports = Users;
