"use strict";

var React = require("react/addons"),
  FluxBone = require("../../../mixins/FluxBone"),
  ReactRouter = require("react-router"),
  DocumentTitle = require("react-document-title"),
  BS = require("react-bootstrap"),
  Cmp = require("../../../components"),
  Validation = require("../../../partials/validation"),
  MessagePartial = require("../../../partials/Message"),
  CurrentUser = require("../../../shared/CurrentUser"),
  ProjectModel = require("../../../models/Project"),
  Constants = require("../../../constants"),
  Roles = require("../../../mixins/Roles"),
  ProfileImage = require("./ProfileImage"),
  ProjectsMap = require("../../../partials/project/ProjectsMap"),
  ProjectPopup = require("../../../partials/project/ProjectPopup"),
  WidgetsCollection = require("../../../collections/Widgets"),
  cx = require("classnames"),
  $ = require("jquery"),
  _ = require("underscore"),
  numeral = require("numeral"),
  Link = ReactRouter.Link,
  coupons = Constants.coupons;

var Membership = React.createClass({displayName: "Membership",
  mixins: [
    FluxBone.ModelMixin("model.agentModel", "sync change"),
    FluxBone.ModelMixin("model.billingModel", "sync change"),
    FluxBone.ModelMixin("project", "sync"),
    ReactRouter.Navigation,
    Roles
  ],

  contextTypes: {
    router: React.PropTypes.func
  },

  statics: {
    willTransitionFrom: function(transition, me, callback) {
      var continueAnyways,
        entity = me ? me.props.model.getEntity() : undefined;

      if (
        me &&
        entity &&
        entity.get("defaultAgentLevel") !== entity.get("agentLevel") &&
        transition.path.indexOf("/users/myaccount/membership/billinginfo") !== 0
      ) {
        continueAnyways = window.confirm(
          "You have selected a different membership level. Are you sure you want to cancel?"
        );
        if (continueAnyways) {
          me.setState({
            levelChange: undefined
          });
          callback();
        } else {
          transition.cancel();
          me.goBack();
        }
      } else {
        callback();
      }
    }
  },

  getDefaultProps: function() {
    return {
      model: CurrentUser,
      project: new ProjectModel({ id: 26761 }),
      widgets: new WidgetsCollection()
    };
  },

  updateState: function(props, options) {
    props = props || this.props;

    var query = this.context.router.getCurrentQuery(),
      entity = props.model.getEntity(),
      defaultLevel = entity.get("defaultAgentLevel") || "BASIC",
      level = entity.get("agentLevel") || "BASIC",
      title = this.getTitle(
        props,
        this.state || {
          thankyou: showThankyou
        }
      ),
      showThankyou = query.thankyou === "true";

    return {
      thankyou: showThankyou,
      showPreview: false,
      iframeHeight: 400,
      title: title,
      coupon: entity.get("coupon")
    };
  },

  getTitle: function(props, state) {
    props = props || this.props;
    state = state || this.state;

    var entity = props.model.getEntity(),
      defaultLevel = entity.get("defaultAgentLevel") || "BASIC",
      level = entity.get("agentLevel") || "BASIC",
      title = props.title + " - " + level,
      showThankyou = state.thankyou;

    if (showThankyou && level === defaultLevel) {
      title = title + " - Thank You";
    } else if (defaultLevel === "BASIC" && level === "BASIC") {
      title = title.replace("Premium Agent Settings", "Pricing Page");
    } else if (
      level &&
      level !== defaultLevel &&
      level !== "FREE" &&
      !props.model.billingModel.get("last4")
    ) {
      title = title + " - Upgrade Need CC Details";
    } else if (level && level !== defaultLevel) {
      title =
        title +
        (level === "PRO" ? " - Confirm Upgrade" : " - Confirm Downgrade");
    } else {
      title = title;
    }

    return title;
  },

  getInitialState: function() {
    return this.updateState();
  },
  /**
   * This is to ensure that when subroutes transistion they trigger recheck router state and render
   */
  componentWillReceiveProps: function(newProps) {
    var state = this.updateState(newProps);

    this.setState(state);
  },

  componentWillMount: function() {
    var model = this.props.model.getEntity();
    model.validation = model.imageValidation;
    $(window).on("message", this.handleSizeResponse);
  },

  componentWillUnmount: function() {
    var model = this.props.model.getEntity();
    model.validation = model.constructor.prototype.validation;
    $(window).off("message", this.handleSizeResponse);
  },

  componentDidUpdate: function(prevProps, prevState) {
    var title = this.getTitle();

    $(React.findDOMNode(this))
      .find(".fancybox")
      .fancybox();

    if (title !== this.state.title) {
      this.setState({
        title: title
      });
    } else if (this.state.title !== prevState.title) {
      CurrentUser.track();
    }
  },

  renderDiscountTitle: function(discount, price, adjustedPrice) {
    var title,
      isMonthBased = false;

    if (discount) {
      if (typeof discount === "string") {
        discount = parseInt(discount, 10);
        isMonthBased = true;
      }

      if (isMonthBased) {
        title = discount === 0 ? "forever" : "for " + discount + " Months";
      }
    }

    return title;
  },

  renderDiscountConfirm: function(discount, price, adjustedPrice) {
    var model = this.props.model.getEntity(),
      defaultAgentLevel = model.get("defaultAgentLevel"),
      agentLevel = model.get("agentLevel"),
      oldPlanTitle = this.getLevelText(defaultAgentLevel),
      newPlanTitle = this.getLevelText(agentLevel),
      isDowngrading =
        defaultAgentLevel === "PRO" && agentLevel !== defaultAgentLevel,
      changeText = isDowngrading ? "downgrading" : "upgrading",
      msg =
        "You are " +
        changeText +
        " your account from our " +
        oldPlanTitle +
        " Agent Account to our " +
        newPlanTitle +
        " Agent Account. We will charge your credit card " +
        numeral(adjustedPrice).format("$0,0.00") +
        " ",
      isMonthBased = false;

    if (discount) {
      if (typeof discount === "string") {
        discount = parseInt(discount, 10);
        isMonthBased = true;
      }

      if (isMonthBased) {
        msg =
          msg +
          (discount === 0
            ? "per month."
            : "for " +
              discount +
              " months, then $" +
              price +
              " on every month after.");
      }
    }

    if (discount !== 0 && (!discount || !isMonthBased)) {
      msg = msg + "immediately, and then on every month.";
    }

    if (!isDowngrading && defaultAgentLevel !== "FREE") {
      msg = msg + " The current month will be prorated.";
    }

    return msg;
  },

  getLevelText: function(plan) {
    var levelText;

    switch (plan) {
      case "FREE":
        levelText = "Free";
        break;
      case "BASIC":
        levelText = "Basic";
        break;
      case "PRO":
        levelText = "Premium";
        break;
    }

    return levelText;
  },

  render: function() {
    var me = this,
      props = me.props,
      state = me.state,
      entity = me.props.model.getEntity(),
      defaultLevel = entity.get("defaultAgentLevel") || "FREE",
      level = entity.get("agentLevel") || "FREE",
      optionClassName = "pricing-v2 membership-pricing ",
      activeClassName = "membership-pricing-active",
      route = me.getCurrentRoute(),
      params = me.context.router.getCurrentParams(),
      query = me.context.router.getCurrentQuery(),
      image = !!entity.get("profileImage") ? entity.get("profileImage").replace("http://", "//").replace('//www.agentshield.com.s3.amazonaws.com', '//s3.amazonaws.com/www.agentshield.com') : '',
      height = "" + state.iframeHeight + "px",
      coupon = entity.get("coupon"),
      discount = coupon ? coupons[coupon.toUpperCase()] : undefined,
      price = 19,
      adjustedPrice = price,
      priceBasic = 9,
      adjustedPriceBasic = priceBasic,
      discountTitle,
      discountConfirm,
      oldLevelText = this.getLevelText(defaultLevel),
      levelText = this.getLevelText(level),
      showPlanSelection = state.showPlanSelection;

    if (image && _.isString(image)) {
      image = image + "?rand=" + Math.random() * 1000;
    } else {
      image = Constants.placeholder;
    }

    if (typeof discount === "string") {
      adjustedPrice = 0;
      adjustedPriceBasic = 0;
    } else if (Number(discount) && discount % 1 === 0) {
      adjustedPrice = price - discount;
      adjustedPriceBasic = priceBasic - discount;
    } else if (Number(discount) && discount % 1 !== 0) {
      adjustedPrice = price - price * discount;
      adjustedPriceBasic = priceBasic - priceBasic * discount;
    }

    if (level === "PRO") {
      discountTitle = me.renderDiscountTitle(discount, price, adjustedPrice);
      discountConfirm = me.renderDiscountConfirm(
        discount,
        price,
        adjustedPrice
      );
    } else if (level === "BASIC") {
      discountTitle = me.renderDiscountTitle(
        discount,
        priceBasic,
        adjustedPriceBasic
      );
      discountConfirm = me.renderDiscountConfirm(
        discount,
        priceBasic,
        adjustedPriceBasic
      );
    }

    return (
      React.createElement("div", {classNameName: "row"}, 
        React.createElement(DocumentTitle, {title: state.title}), 
        me.state.thankyou && level === defaultLevel && (
          React.createElement("div", {classNameName: "col-xs-12"}, 
            React.createElement(BS.Panel, {
              className: "panel-split-header", 
              bsStyle: "success", 
              header: React.createElement("h2", null, "Thank You!")
            }, 
              "Your membership level has been changed. Thank you."
              /*<Link to={route.name} query={_.omit(query, 'thankyou')} params={params}>Return to Membership Page</Link>.*/
            )
          )
        ), 
        !showPlanSelection && level === "PRO" && level === defaultLevel && (
          React.createElement("div", {classNameName: "col-xs-12"}, 
            React.createElement(Validation.Form, {
              ref: "form", 
              horizontal: false, 
              model: props.model.getEntity(), 
              onSubmit: this.onSubmit, 
              validateOnLoad: false
            }, 
              React.createElement("h2", {className: "margin-bottom-20"}, "Premium Agent Settings"), 
              React.createElement("p", null, 
                "This page contains the settings for the Premium Agent upgrades." + ' ' +
                "Premium Agent features include:"
              ), 
              React.createElement("div", {className: "row"}, 
                React.createElement("div", {className: "col-xs-12"}, 
                  React.createElement("ol", {className: "pull-left"}, 
                    React.createElement("li", null, "Unlimited new construction widgets"), 
                    React.createElement("li", null, "Premium and priority customer support."), 
                    React.createElement("li", null, 
                      "Enhanced branding with phone number and profile image."
                    )
                  ), 
                  React.createElement("a", {
                    className: "btn-u btn-u-orange pull-right text-center", 
                    style: { width: "150px"}, 
                    onClick: this.showPreview
                  }, 
                    "Preview"
                  )
                )
              ), 
              React.createElement("h2", null, "Display Phone Number:"), 
              React.createElement("p", null, 
                "Enable buyers to call you directly by including a phone number." + ' ' +
                "Enter the phone number to display."
              ), 
              React.createElement(Validation.Input, {type: "text", name: "contactInfo.phoneMobile"}), 

              React.createElement("div", {className: "row"}, 
                React.createElement("div", {className: "col-xs-12"}, 
                  React.createElement("h2", null, "Profile Image:"), 
                  React.createElement("p", null, 
                    "Increase your brand recognition by adding a photo to listing" + ' ' +
                    "results."
                  ), 
                  entity && (
                    React.createElement("div", {className: "row"}, 
                      React.createElement("div", {className: "col-xs-3"}, 
                        React.createElement("div", {className: "profile-image"}, 
                          React.createElement("img", {src: image, style: { maxWidth: "100%"}})
                        )
                      ), 
                      React.createElement("div", {className: "col-xs-9"}, 
                        React.createElement("div", {className: "text-center"}, 
                          React.createElement("h3", {className: "blue-text"}, 
                            "Photo should be 150 pixels wide by 200 pixels high."
                          ), 
                          React.createElement("p", null, "All other sizes will automatically be cropped.")
                        ), 
                        React.createElement(Cmp.Picker.File, {
                          ref: "filePicker", 
                          triggerSync: true, 
                          groupClassName: "margin-top-20", 
                          trigger: "onChange", 
                          name: "profileImage", 
                          url: _.result(entity, "url"), 
                          label: "", 
                          model: entity, 
                          onComplete: this.onFileUpload, 
                          mime_types: [Constants.imageMimeTypes]}
                        )
                      )
                    )
                  )
                )
              ), 

              React.createElement(Cmp.ValidationMessage, null), 
              React.createElement(BS.ButtonToolbar, null, 
                React.createElement(Cmp.Button.Save, {className: "pull-right", label: "Save"})
              ), 

              React.createElement("div", null, 
                React.createElement(BS.Button, {
                  className: "btn-primary pull-left", 
                  onClick: this.showPlanSelection.bind(null, true)
                }, 
                  "Change Your Account Plan"
                ), 
                React.createElement(Link, {
                  className: "btn-link pull-right", 
                  style: { paddingTop: "12px"}, 
                  to: "UserMembershipBillingInfo"
                }, 
                  React.createElement("i", {className: "fa fa-credit-card"}), " Edit payment information"
                )
              )
            )
          )
        ), 

        level && level !== defaultLevel && (
          React.createElement("div", {classNameName: "col-xs-12"}, 
            React.createElement(BS.Panel, {
              className: "panel-split-header", 
              header: React.createElement("h2", null, "Confirm Change!")
            }, 
              level === "PRO" || level === "BASIC" ? (
                props.model.billingModel.get("last4") ? (
                  React.createElement("div", null, 
                    React.createElement("div", {className: "margin-bottom-20"}, discountConfirm), 
                    React.createElement("div", {className: "text-center"}, 
                      React.createElement("a", {className: "btn-u btn-u-blue", onClick: this.saveLevel}, 
                        "Confirm", " ", 
                        (defaultLevel === "FREE" ||
                          defaultLevel === "BASIC") &&
                        (level === "PRO" || level === "BASIC")
                          ? "Upgrade"
                          : "Downgrade", " ", 
                        "to ", levelText, " Agent"
                      ), 
                      React.createElement("a", {className: "btn btn-link", onClick: this.cancelChange}, 
                        "Cancel"
                      )
                    )
                  )
                ) : (
                  React.createElement("div", null, 
                    React.createElement("div", {className: "margin-bottom-20"}, 
                      "Credit Card not on file. Please click", " ", 
                      React.createElement(Link, {to: "UserMembershipBillingInfo"}, 
                        "Add a Credit Card"
                      ), " ", 
                      "to continue."
                    ), 
                    React.createElement("div", {className: "text-center"}, 
                      React.createElement(Link, {to: "UserMembershipBillingInfo"}, 
                        React.createElement(BS.Button, null, "Add a Credit Card")
                      ), 
                      React.createElement("a", {className: "btn btn-link", onClick: this.cancelChange}, 
                        "Cancel"
                      )
                    )
                  )
                )
              ) : (
                React.createElement("div", null, 
                  React.createElement("div", {className: "margin-bottom-20"}, 
                    "You are downgrading your account from our ", oldLevelText, " ", 
                    "Agent Account to our ", levelText, " Agent Account. All", " ", 
                    oldLevelText, " benefits will no longer be available."
                  ), 
                  React.createElement("div", {className: "text-center"}, 
                    React.createElement("a", {className: "btn-u btn-u-blue", onClick: this.saveLevel}, 
                      "Confirm downgrade to ", levelText, " Account"
                    ), 
                    React.createElement("a", {className: "btn btn-link", onClick: this.cancelChange}, 
                      "Cancel"
                    )
                  )
                )
              )
            )
          )
        ), 
        (level !== "PRO" || showPlanSelection) && level === defaultLevel && (
          React.createElement("div", {classNameName: "col-xs-12"}, 
            React.createElement("h3", {className: "margin-bottom-20 blue-text"}, 
              "You currently have a ", levelText, " Agent Account."
            ), 

            React.createElement("div", {className: "row pricing-table-v2"}, 
              React.createElement("div", {className: "col-xs-12 col-sm-6"}, 
                React.createElement("div", {
                  className: cx(optionClassName, "pricing-v2-grey", {
                    "membership-pricing-active": defaultLevel === "BASIC"
                  })
                }, 
                  React.createElement("div", {className: "pricing-v2-head"}, 
                    React.createElement("h4", {className: "text-center"}, 
                      "Basic Agent Account", 
                      React.createElement("br", null), 
                      React.createElement("b", null, 
                        numeral(adjustedPriceBasic).format("$0,0.00"), "/month"
                      ), 
                      discountTitle && React.createElement("small", null, " ", discountTitle)
                    )
                  ), 
                  React.createElement("ul", {className: "list-unstyled pricing-v2-content"}, 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "fa fa-check-circle-o"}), " 1 New" + ' ' +
                      "Construction Widget"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "fa fa-check-circle-o"}), " Basic Branding"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "fa fa-check-circle-o"}), " Basic Customer" + ' ' +
                      "Support"
                    )
                  )
                )
              ), 
              React.createElement("div", {className: "col-xs-12 col-sm-6"}, 
                React.createElement("div", {
                  className: cx(optionClassName, "pricing-v2-blue", {
                    "membership-pricing-active": defaultLevel === "PRO",
                    "margin-bottom-15": defaultLevel === "BASIC"
                  })
                }, 
                  React.createElement("div", {className: "pricing-v2-head"}, 
                    React.createElement("h4", {className: "text-center"}, 
                      "Premium Agent Account", 
                      React.createElement("br", null), 
                      React.createElement("b", null, numeral(adjustedPrice).format("$0,0.00"), "/month"), 
                      discountTitle && React.createElement("small", null, " ", discountTitle)
                    )
                  ), 
                  React.createElement("ul", {className: "list-unstyled pricing-v2-content"}, 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "fa fa-diamond"}), " Unlimited New" + ' ' +
                      "Construction Widgets"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "fa fa-diamond"}), " Premium Branding"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "fa fa-diamond"}), " Include Profile Photo" + ' ' +
                      "on Listings"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "fa fa-diamond"}), " Include Phone Number on" + ' ' +
                      "Listings"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "fa fa-diamond"}), " Premium Customer" + ' ' +
                      "Support"
                    ), 
                    React.createElement("li", null, 
                      React.createElement("span", {className: "fa fa-diamond"}), " CRM Integration"
                    )
                  )
                )
              )
            ), 
            React.createElement("div", {className: "row"}, 
              React.createElement("div", {className: "col-xs-12 col-md-6 col-md-offset-3"}, 
                React.createElement("input", {
                  className: cx("form-control", "margin-bottom-15", {
                    "control-valid":
                      entity.get("coupon") &&
                      state.coupon === entity.get("coupon"),
                    "control-invalid":
                      state.coupon && state.coupon !== entity.get("coupon")
                  }), 
                  value: state.coupon, 
                  onChange: me.onCouponCodeChange, 
                  placeholder: "Special Code"}
                )
              )
            ), 
            React.createElement("div", {className: "row text-center"}, 
              React.createElement("div", {className: "col-xs-12 col-sm-6"}, 
                React.createElement("a", {
                  className: "btn-u btn-u-default btn-block", 
                  disabled: level === "BASIC", 
                  onClick: 
                    level !== "BASIC" && this.setLevel.bind(null, "BASIC")
                  
                }, 
                  "Select Basic Agent Account"
                )
              ), 
              React.createElement("div", {className: "col-xs-12 col-sm-6"}, 
                React.createElement("a", {
                  className: "btn-u btn-u-blue btn-block", 
                  disabled: level === "PRO", 
                  onClick: level !== "PRO" && this.setLevel.bind(null, "PRO")
                }, 
                  "Upgrade to Premium Agent"
                )
              )
            ), 

            React.createElement("div", {className: "row pricing-table-v2 margin-top-20"}, 
              React.createElement("div", {className: "col-xs-12"}, 
                React.createElement("div", {
                  className: "pricing-v2 pricing-v2-blue", 
                  style: { marginBottom: 0}
                }, 
                  React.createElement("a", {
                    onClick: me.toggleLearnMore, 
                    className: "btn btn-link btn-block", 
                    style: { textAlign: "left"}
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-diamond"}), " Compare plans. Click" + ' ' +
                    "Here."
                  ), 
                  state.learnMoreExpanded && (
                    React.createElement("div", {style: { padding: "0px 10px"}}, 
                      React.createElement("p", null, 
                        "The comparison of features between the Basic Agent" + ' ' +
                        "Accounts and Premium Agent Accounts are displayed above." + ' ' +
                        "One of the main differences is the added branding for" + ' ' +
                        "Premium Agents in the widgets. Below are example" + ' ' +
                        "screenshots between the Basic and Premium."
                      ), 
                      React.createElement("a", {
                        href: 
                          Constants.imageRoot +
                          "NCH_AgentBrandingComparison.png", 
                        
                        className: "fancybox", 
                        title: "Agent Branding Comparison"
                      }, 
                        React.createElement("img", {
                          src: 
                            Constants.imageRoot +
                            "NCH_AgentBrandingComparison.png", 
                          
                          className: "img-responsive"}
                        )
                      ), 
                      React.createElement("div", {className: "row text-center"}, 
                        React.createElement("div", {className: "col-xs-12 col-sm-6"}, 
                          React.createElement("a", {
                            className: "btn-u btn-u-default btn-block", 
                            disabled: level === "BASIC", 
                            onClick: 
                              level !== "BASIC" &&
                              this.setLevel.bind(null, "BASIC")
                            
                          }, 
                            "Select Basic Agent Account"
                          )
                        ), 
                        React.createElement("div", {className: "col-xs-12 col-sm-6"}, 
                          React.createElement("a", {
                            href: "#", 
                            className: "btn-u btn-u-blue btn-block", 
                            onClick: this.setLevel.bind(null, "PRO")
                          }, 
                            "Upgrade to Premium Agent"
                          )
                        )
                      ), 
                      React.createElement("div", {className: "text-center"}, 
                        React.createElement("a", {
                          className: "btn btn-link blue-text", 
                          disabled: level === "PRO", 
                          onClick: level !== "PRO" && me.showPremiumAgentForm
                        }, 
                          React.createElement("i", {className: "fa fa-fw fa-question-circle"}), " Still" + ' ' +
                          "have questions about Premium Agent Accounts? CLICK" + ' ' +
                          "HERE"
                        )
                      )
                    )
                  )
                )
              )
            ), 
            (level === "BASIC" || showPlanSelection) &&
              level === defaultLevel && (
                React.createElement("div", {className: "row margin-top-10"}, 
                  React.createElement("div", {className: "col-xs-6 text-left"}, 
                    showPlanSelection && (
                      React.createElement("a", {
                        className: "btn btn-link", 
                        onClick: this.showPlanSelection.bind(null, false)
                      }, 
                        "Cancel Change"
                      )
                    )
                  ), 
                  React.createElement("div", {className: "col-xs-6 text-right"}, 
                    React.createElement(BS.Button, {
                      className: "btn-primary pull-left", 
                      onClick: this.setLevel.bind(null, "FREE")
                    }, 
                      "Click Here to Downgrade to Free Account."
                    )
                  )
                )
              )
          )
        ), 

        React.createElement(ProjectPopup, {
          ProjectMapComponent: ProjectsMap, 
          showAgentDetails: false, 
          ref: "projectDetail", 
          show: state.showPreview && props.project.isLoaded(), 
          model: props.project, 
          agent: entity, 
          onClose: this.hidePopup}
        ), 

        React.createElement(BS.Modal, {
          show: !!state.showPremiumAgentForm, 
          onHide: me.closePremiumAgentForm, 
          className: "modal-preview modal-lg"
        }, 
          React.createElement(BS.Modal.Header, {
            closeButton: true, 
            style: { zIndex: 1, position: "relative"}}
          ), 
          React.createElement(BS.Modal.Body, null, 
            React.createElement("div", null, 
              React.createElement("iframe", {
                key: "header-contact-form", 
                allowTransparency: "true", 
                src: "https://secure.jotformpro.com/form/60305098059153", 
                frameBorder: "0", 
                style: {
                  width: "100%",
                  height: height,
                  border: "none",
                  marginTop: "-45px"
                }, 
                scrolling: "no"}
              )
            )
          )
        )
      )
    );
  },

  onCouponCodeChange: function(e) {
    var code = e.target.value;

    this.setState({
      coupon: code
    });

    this.props.model.getEntity().set(
      {
        coupon: coupons[code.toUpperCase()] ? e.target.value : undefined
      },
      {
        silent: true
      }
    );
  },

  showPremiumAgentForm: function() {
    this.setState({
      showPremiumAgentForm: true
    });
  },

  closePremiumAgentForm: function() {
    this.setState({
      showPremiumAgentForm: false
    });
  },

  handleSizeResponse: function(e) {
    var originalEvent = e.originalEvent,
      data = e.originalEvent.data;
    if (originalEvent.origin == "https://secure.jotformpro.com") {
      data = data.split(":");
      if (data[0] === "setHeight") {
        this.setState({
          iframeHeight: data[1]
        });
      }
    }
  },

  toggleLearnMore: function() {
    this.setState({
      learnMoreExpanded: !this.state.learnMoreExpanded
    });
  },

  setLevel: function(level, e) {
    var me = this,
      entity = me.props.model.getEntity();

    e.preventDefault();

    if (level !== entity.get("agentLevel")) {
      entity.set("agentLevel", level);

      if (
        !me.props.model.billingModel.get("last4") &&
        (entity.get("agentLevel") === "PRO" ||
          entity.get("agentLevel") === "BASIC")
      ) {
        this.transitionTo("UserMembershipBillingInfo");
      }
    }
  },

  showPlanSelection: function(show, e) {
    e.preventDefault();

    this.setState({
      showPlanSelection: show
    });
  },

  cancelChange: function() {
    var me = this,
      entity = me.props.model.getEntity();

    entity.set("agentLevel", entity.get("defaultAgentLevel"));

    if (window.Intercom) {
      window.Intercom("trackEvent", "membership-level-canceled");
    }
    if (window.analytics) {
      analytics.track("membership-level-canceled");
    }
  },

  saveLevel: function() {
    var me = this,
      model = me.props.model,
      widgets = me.props.widgets,
      entity = model.getEntity(),
      level = entity.get("agentLevel"),
      defaultAgentLevel = entity.get("defaultAgentLevel"),
      route = me.getCurrentRoute(),
      params = me.context.router.getCurrentParams(),
      query = _.extend({ thankyou: true }, me.context.router.getCurrentQuery());

    me.props.model
      .setSubscription(
        level,
        {
          agentLevel: level,
          coupon: (entity.get("coupon") || "").toUpperCase()
        },
        {
          fields: ["agentLevel", "coupon"]
        }
      )
      .then(function() {
        me.props.model.fetch().then(function() {
          if (window.Intercom) {
            window.Intercom("trackEvent", "membership-level-updated");
          }
          if (window.analytics) {
            analytics.track("membership-level-updated");
          }

          if (level !== "FREE" && defaultAgentLevel === "FREE") {
            widgets.username = model.get("username");
            widgets
              .fetch({
                pageSize: 2
              })
              .then(function() {
                var widget = widgets.findWhere({ default: true });
                if (widget && widgets.length === 1) {
                  me.transitionTo("SignupWidget", {
                    id: widget.id
                  });
                } else {
                  me.setState({
                    showPlanSelection: false
                  });
                  me.transitionTo(route.name, params, query);
                }
              });
          } else {
            me.setState({
              showPlanSelection: false
            });
            me.transitionTo(route.name, params, query);
          }
        });
      });
  },

  getCurrentRoute: function() {
    var routes = this.context.router.getCurrentRoutes();

    return routes[routes.length - 1];
  },

  onSubmit: function() {
    var me = this,
      props = me.props,
      model = props.model,
      entity = model.getEntity(),
      filePicker = me.refs.filePicker;

    entity
      .save(this.refs.form.getValues(), {
        fields: ["contactInfo"]
      })
      .then(function() {
        if (filePicker.hasFiles()) {
          filePicker.startUpload();
        } else {
          me.onFileUpload();
        }
      });
  },

  onFileUpload: function() {
    this.props.model.getEntity().fetch();
  },

  showPreview: function() {
    var project = this.props.project;

    if (!project.isLoaded() && !project.isLoading()) {
      project.fetch();
    }

    this.setState({
      showPreview: true
    });
  },

  hidePopup: function() {
    this.setState({
      showPreview: false
    });
  }
});
module.exports = Membership;
