"use strict";

var React = require('react'),
    ReactRouter = require('react-router'),
    Link = ReactRouter.Link,
    ReactRouterBootstrap = require('react-router-bootstrap'),
    ButtonLink = ReactRouterBootstrap.ButtonLink,
    BS = require('react-bootstrap'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    CurrentUser = require('../../shared/CurrentUser'),
    UserForgot = require('../../models/UserForgot'),
    Constants = require('../../constants'),
    Cmp = require('../../components'),
    Validation = require('../../partials/validation');

var ForgotPassword = React.createClass({displayName: "ForgotPassword",
    mixins: [
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            model: new UserForgot()
        };
    },

    getInitialState: function() {
        return {
            success: undefined
        };
    },

    componentWillMount: function(){
        this.props.model.clear();
    },

    render: function() {
        var usernameIcon = (
                React.createElement("i", {className: "fa fa-user fa-fw"})
            ), 
            emailIcon = (
                React.createElement("i", {className: "fa fa-envelope fa-fw"})
            );

        return (
            React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: "NCH - Forgot Password"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Recover Password"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(Cmp.NavLink, null, "Home"), 
                            React.createElement(Cmp.NavLink, {to: "ForgotPassword"}, "Recover Password")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement("div", {className: "row"}, 
                        React.createElement("div", {className: "col-md-6 col-md-offset-3"}, 
                            React.createElement(BS.Panel, {className: "panel-split-header", header: (React.createElement("h2", null, "Recover Password"))}, 
                                this.state.success && [
                                    React.createElement(BS.Alert, {bsStyle: "success"}, 
                                        "Success! An email to reset your password has been sent to ", React.createElement("b", null, this.props.model.get('email')), ". Please check your email.",  
                                        React.createElement("br", null), 
                                        "If you do not see the email, be sure to check your spam or junk folder."
                                    ),
                                    React.createElement("div", {className: "text-center"}, 
                                        React.createElement(ButtonLink, {bsStyle: "primary", type: "submit", to: "Login"}, "Click here to login")
                                    )

                                ], 
                                (this.state.success === undefined) &&
                                React.createElement(Validation.Form, {horizontal: false, ref: "form", model: this.props.model, onSubmit: this.onSubmit, success: this.state.success, getMessage: this.getMessage, validateOnLoad: false}, 
                                    React.createElement("div", {className: "margin-bottom-20 text-center"}, 
                                        React.createElement("strong", null, "Enter your email below and we will email you a link to reset your password.")
                                    ), 
                                    React.createElement(Validation.Input, {trigger: "onBlur", addonBefore: emailIcon, type: "text", placeholder: "Email Address", name: "email"}), 

                                    React.createElement(Cmp.Button.Save, {formLinks: {}, className: "btn-block"}, "Submit")
                                )
                                
                            )
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    },

    onSubmit: function(e) {
         var me = this,
            state = me.state,
            props = me.props;

        if (this.refs.form.validateState()) {
            props.model.resetPassword(this.refs.form.getValues()).then(function() {
                me.setState({
                    success: true
                });
            });
        }
    },

    getMessage: function(type, model_collection, response, options) {
        //Using <Link> here causes strange issues 
        return (React.createElement("span", null, 
                    "Opps! There is no New Construction Hub account for ", React.createElement("b", null, this.props.model.get('email')), ".", React.createElement("br", null), 
                    "Please try again or ", React.createElement("a", {href: "/signup", style: {'text-decoration' : 'underline'}}, "CLICK HERE"), " to create a new New Construction Hub account."
                ));
    }
});
module.exports = ForgotPassword;
