"use strict";

var ModelClass = require('./Model'),
    Constants = require('../constants');

var Attribute = ModelClass.extend({
    idAttribute: 'id',
    defaults: function() {
        return {
            id: undefined,
            name: ''
        };
    }
});

module.exports = Attribute;
