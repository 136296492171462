"use strict";

var ModelClass = require('./Model'),
    City = require('./City'),
    utility = require('../shared/Utilities');


var LocationInfo = ModelClass.extend({
    idAttribute: 'id',
    hasOne: {
        City: {
            responseKey: 'city',
            alwaysInclude: true
        }
    },

    defaults: function() {
        return {
            id: undefined,
            address1: '',
            address2: '',
            zip: ''
        };
    },

    toJSON: function(options) {
        var data = ModelClass.prototype.toJSON.apply(this, arguments);

        options = options || {};

        if (options.url) {
            delete data.id;
            if (!this.cityModel.id && data.city.byName !== true) { // city can't be created here
                delete data.city;
            } else if (data.city && data.city.byName === true) {
                delete data.city.byName;
                delete data.city.id;
                data.cityName = data.city.name;
                data.stateName = data.city.state.name;
                data.countryName = data.city.country.name;
                delete data.city;
            }
        } else {
            if (data.city && data.city.state && data.city.state.name) {
                data.city.state.abbr = utility.abbrState(data.city.state.name, 'abbr');
            }
        }

        return data;
    }
}, {
    City: City
});

module.exports = LocationInfo;
