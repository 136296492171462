"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    FluxBone = require('../mixins/FluxBone'),
    Cities = require('../collections/Cities'),
    RadioGroup = require('./RadioGroup');

var CityCheckboxGroup = React.createClass({displayName: "CityCheckboxGroup",

    mixins: [
        FluxBone.CollectionMixin('store', 'sync')
    ],

    getDefaultProps: function() {
        return {
            store: new Cities(),
            checkOnSync: true
        };
    },

    componentWillMount: function() {
        if (this.props.stateId || (this.props.metroId && this.props.metroId !== 'NONE')) {
            this.props.store.fetch({
                stateId: this.props.stateId,
                metroId: this.props.metroId,
                hasProjects: this.props.hasProjects
            });
        }
    },

    componentWillReceiveProps: function(props) {
        var me = this;

        if (props.disabled && props.disabled !== this.props.disabled && props.valueLink) {
            props.valueLink.requestChange('');
        }
        
        if (!props.stateId && !props.metroId && me.getValue()) {
            this.resetDeferred();
        } else if ((props.stateId || (props.metroId && props.metroId !== 'NONE')) && (props.stateId !== this.props.stateId || props.metroId !== this.props.metroId)) {
            this.props.store.fetch({
                stateId: props.stateId,
                metroId: props.metroId,
                hasProjects: props.hasProjects
            }).then(function() {
                var value = me.getValue();

                if (value && me.props.checkOnSync && me.props.valueLink && !me.props.store.get(value)) {
                    me.props.valueLink && me.props.valueLink.requestChange('');
                }
            });;
        }
    },

    render: function() {
        var props = this.props;

        return (
            React.createElement(RadioGroup, React.__spread({items: this.getCities()},  this.props))
        );
    },

    getCities: function() {
        var cities = this.props.store.map(function(city) {
            return {
                id: city.id,
                name: city.get('name')
            };
        });

        return cities;
    },

    resetDeferred: function() {
        var me = this;
        this.resetTimeout = setTimeout(function() {
            me.props.store.reset();
            me.props.valueLink && me.props.valueLink.requestChange('');
        }, 100)
    },

    clearReset: function() {
        clearTimeout(this.resetTimeout);
        delete this.resetTimeout;
    },

    getValue: function() {
        return this.refs.input ? this.refs.input.getValue() : undefined;
    }
});

module.exports = CityCheckboxGroup;
