"use strict";

var ModelClass = require('./Model'),
    ContactInfo = require('./ContactInfo'),
    _ = require('underscore'),
    $ = require('jquery');

var UserEntity = ModelClass.extend({
    idAttribute: 'id',
    hasOne: {
        ContactInfo: {
            responseKey: 'contactInfo',
            alwaysInclude: true
        }
    },

    defaults: function() {
        return {
            id: undefined,
            user: {}
        };
    },

    parse: function(response) {
        var data = ModelClass.prototype.parse.apply(this, arguments);

        if (data && _.isArray(data.users)) {
            data.user = data.users[0] || {};
        }
        return data;
    }

}, {
    ContactInfo: ContactInfo
});

module.exports = UserEntity;
