"use strict";

var React = require('react/addons'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    FluxBone = require('../../mixins/FluxBone'),
    BS = require('react-bootstrap'),
    ProjectModel = require('../../models/Project'),
    DeleteDialog = require('../../partials/DeleteDialog'),
    moment = require('moment'),
    ReactRouter = require('react-router'),
    ListingModelType = require('../../mixins/ListingModelType'),
    Link = ReactRouter.Link;

var ProjectContacts = React.createClass({displayName: "ProjectContacts",

    mixins: [
        FluxBone.CollectionMixin('model.listingPocsCollection', 'sync remove add change', 10),
        React.addons.LinkedStateMixin,
        ListingModelType.ListingNavigation('Contact')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getInitialState: function() {
        return {
            modelToDelete: false
        };
    },

    getDefaultProps: function() {
        return {
            readOnly: false,
            emptyMessage: 'There are no contacts available.'
        };
    },

    onDelete: function(doc, e) {
        e.preventDefault();
        this.setState({
            modelToDelete: doc
        });
    },

    onHideDeleteDialog: function() {
        this.setState({
            modelToDelete: undefined
        });
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            collection = props.model ? props.model.listingPocsCollection : undefined,
            deleteDialogProps = {
                ref: 'deleteDialog',
                modelToDelete: state.modelToDelete,
                onHide: me.onHideDeleteDialog,
                modelTitle: state.modelToDelete ? state.modelToDelete.get('name') : '',
                title: 'ALERT! You are about to DELETE a contact.',
                msg: 'Please confirm that you would like to DELETE this contact,',
                successMessage: 'Success! Contact has been successfully deleted.',
                deleteLabel: 'Delete Contact'
            };

        return (
            React.createElement(DeleteDialog, React.__spread({},  deleteDialogProps), 
                !this.props.hideHeader && (
                React.createElement("div", {className: "row margin-bottom-20 half-gutter"}, 
                    React.createElement("div", {className: props.readOnly ? 'col-md-12' : 'col-md-10'}, 
                        React.createElement("h1", null, me.isDeveloper() ? 'Builder Contacts' : 'Listing Contacts'), 
                        React.createElement("h5", null, "This section contains contacts which will be notified when leads occur.")
                    ), 
                    !props.readOnly && (
                        React.createElement("div", {className: "col-md-2"}, 
                            React.createElement(Link, {to: me.addTo(), className: "btn btn-u pull-right", params: this.context.router.getCurrentParams()}, "Add Contact")
                        )
                    )
                )
                ), 
                 React.createElement("div", {className: "row half-gutter"}, 
                    React.createElement("div", {className: "col-sm-12"}, 
                        (!props.readOnly || (collection && collection.length > 0)) && (
                            React.createElement(BS.Table, {condensed: true, hover: true, striped: true, className: "table-borderless"}, 
                                React.createElement("thead", null, 
                                    React.createElement("tr", null, 
                                        React.createElement("th", null, "Name / Phone"), 
                                        React.createElement("th", {className: "col-md-3"}, "Email"), 
                                        React.createElement("th", {className: "col-md-3"}, "Receive Notifications"), 
                                        !props.readOnly && (
                                            React.createElement("th", null, "Actions")
                                        )
                                    )
                                ), 
                                React.createElement("tbody", null, 
                                collection && collection.map(function(contact){
                                    var data = contact.toJSON(),
                                        linkParams = { id: props.model.id, contactId: data.id },
                                        readOnly = props.readOnly || contact.isReadOnly();
                                    return (
                                        React.createElement("tr", {key: contact.id}, 
                                            React.createElement("td", null, 
                                                data.name, 
                                                data.phone && (React.createElement("br", null)), 
                                                data.phone && data.phone
                                            ), 
                                            React.createElement("td", null, data.email), 
                                            React.createElement("td", null, data.receiveNotification ? 'Yes' : 'No'), 
                                            !readOnly && (
                                                React.createElement("td", {style: {'whiteSpace': 'nowrap'}}, 
                                                    React.createElement(Link, {to: me.editTo(), params: linkParams}, 
                                                        React.createElement("i", {className: "icon-custom  icon-color-u icon-sm fa fa-pencil", style: {"marginRight" : "3px"}})
                                                    ), 
                                                    React.createElement("a", {href: "#", onClick: me.onDelete.bind(me, contact)}, 
                                                        React.createElement("i", {className: "icon-custom  icon-color-u icon-sm fa fa-trash-o"})
                                                    )
                                                )
                                            )
                                        )
                                    );
                                })
                                )
                            )
                        )
                    )
                ), 
                collection && collection.length === 0 && (
                   React.createElement(BS.Alert, null, 
                        React.createElement("p", {className: "text-center"}, 
                            props.emptyMessage
                        )
                    )
                )
             )
        );
    }
});
module.exports = ProjectContacts;
