"use strict";

var React = require('react/addons'),
    ReactRouterBootstrap = require('react-router-bootstrap'),
    ReactRouter = require('react-router'),
    DocumentTitle = require('react-document-title'),
    BS = require('react-bootstrap'),
    Cmp = require('../../../components'),
    cx = require('classnames'),
    PageHeader = require('../../../partials/PageHeader'),
    PageFooter = require('../../../partials/PageFooter'),
    Validation = require('../../../partials/validation'),
    FluxBone = require('../../../mixins/FluxBone'),
    ButtonLink = ReactRouterBootstrap.ButtonLink,

    ProjectNewImages = require('../ProjectImages'),
    ProjectNewDocuments = require('../ProjectDocuments'),
    ProjectNewPromotions = require('../ProjectPromotions'),
    ProjectNewFloorPlans = require('../ProjectFloorPlans'),
    ProjectNewContacts = require('../ProjectContacts');

var ProjectWizardSteps = React.createClass({displayName: "ProjectWizardSteps",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset'),
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            id = params.id,
            model = this.props.model;

        model.clear();

        if(id){
            model.set('id', id);
            model.fetch();
        }

        return {};
    },

    getInitialState: function() {
        return this.updateState();
    },

    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        var me = this,
            props = me.props,
            model = props.model,
            state = me.state;

        return (
            React.createElement("div", {className: "wrapper widgets"}, 
                React.createElement(DocumentTitle, {title: "NCH - Create Listing"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Create Listing - Step 2: Add Additional Info"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(Cmp.NavLink, {to: "ProjectList"}, "My Listings"), 
                            React.createElement(Cmp.NavLink, {to: "ProjectWizardDetails", params: { id: model.id}}, "New Listing Details"), 
                            React.createElement(Cmp.NavLink, {to: "ProjectWizardSteps", params: { id: model.id}}, "Additional Info")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    /* <p>Complete creating a project by completing the next sections</p> */

                    this.addSection({
                        title: '2A: Add Images', 
                        collection: model.imagesCollection,
                        emptyMessage: 'At least one image is required',
                        completedMessage: 'Add additional images if you want',
                        to: 'ProjectWizardImage',
                        buttonText: 'Add Image',
                        tableComponent : (React.createElement(ProjectNewImages, {collection: model.imagesCollection, model: model, hideHeader: true})),
                        required: true
                    }), 
                    React.createElement("hr", null), 
                    this.addSection({
                        title: '2B: Add Floor Plans', 
                        collection: model.floorPlansCollection,
                        emptyMessage: 'Add Floor Plans (optional)',
                        completedMessage: 'Add additional floor plans if you want',
                        to: 'ProjectWizardFloorPlan',
                        buttonText: 'Add Floor Plan',
                        tableComponent : (React.createElement(ProjectNewFloorPlans, {collection: model.floorPlansCollection, model: model, hideHeader: true})),
                        required: false
                    }), 
                    React.createElement("hr", null), 
                    this.addSection({
                        title: '2C: Add Documents', 
                        collection: model.documentsCollection,
                        emptyMessage: 'Add Documents (options)',
                        completedMessage: 'Add additional documents if you want',
                        to: 'ProjectWizardDocument',
                        tableComponent : (React.createElement(ProjectNewDocuments, {collection: model.documentsCollection, model: model, hideHeader: true})),
                        buttonText: 'Add Document',
                        required: false
                    }), 
                    React.createElement("hr", null), 
                    this.addSection({
                        title: '2D: Add Promotions', 
                        collection: model.promotionsCollection,
                        emptyMessage: 'Add Promotions (optional)',
                        completedMessage: 'Add additional promotions if you want',
                        to: 'ProjectWizardPromotion',
                        tableComponent : (React.createElement(ProjectNewPromotions, {collection: model.promotionsCollection, model: model, hideHeader: true})),
                        buttonText: 'Add Promotion',
                        required: false
                    }), 
                    React.createElement("hr", null), 
                    this.addSection({
                        title: '2E: Add Contacts', 
                        collection: model.listingPocsCollection,
                        emptyMessage: 'Add Contacts (optional)',
                        completedMessage: 'Add additional contacts if you want',
                        to: 'ProjectWizardContact',
                        tableComponent : (React.createElement(ProjectNewContacts, {collection: model.listingPocsCollection, model: model, hideHeader: true})),
                        buttonText: 'Add Contact',
                        required: false
                    }), 
                    React.createElement("hr", null), 
                    React.createElement(BS.ButtonToolbar, null, 
                        React.createElement(Cmp.Button.Cancel, {onClick: this.gotoDetail}, React.createElement("i", {className: "fa fa-arrow-left"}), " Step 1: Listing Details"), 
                        React.createElement(Cmp.Button.Save, {className: "pull-right", onClick: this.completeWizard}, "Save & Finish ", React.createElement("i", {className: "fa fa-arrow-right"}))
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    },

    addSection: function(options){
        var model = this.props.model,
            isLoaded = model.isLoaded(),
            hasNone =  options.collection.length === 0,
            bsStyle = cx({
                danger: hasNone && isLoaded && options.required,
                warning: hasNone && isLoaded && !options.required,
                success: !hasNone,
                default: !isLoaded && hasNone
            }),
            iconClassName = cx('fa', 'fa-fw',{
                'text-danger fa-exclamation': hasNone && isLoaded && options.required,
                'text-warning fa-exclamation-triangle': hasNone && isLoaded && !options.required,
                'text-success fa-check': !hasNone

            }),
            routeParams = {
                id: model.id
            },
            header = (
                React.createElement("h2", null, 
                    React.createElement("i", {className: iconClassName}), 
                    React.createElement("span", null, " ", options.title), 
                    React.createElement(ButtonLink, {to: options.to, params: routeParams, bsStyle: "primary", className: "pull-right"}, options.buttonText)
                )
            );            

        return (
            React.createElement("div", {className: options.className}, 
                header, 
                React.createElement("div", {className: "row"}, 
                    model.isLoaded() && model.imagesCollection.length === 0 && (
                        React.createElement("div", {className: "col-xs-12 text-center"}, 
                            options.emptyMessage
                        )
                    ), 
                    !hasNone && (
                        React.createElement("div", {className: "col-xs-12"}, 
                            options.tableComponent, 
                            options.completedMessage
                        )
                    )
                )
            )
        );
    },

    gotoDetail: function() {
        this.transitionTo('ProjectWizardDetails', {
            id: this.props.model.id
        });
    },

    completeWizard: function() {
        this.transitionTo('Project', {
            id: this.props.model.id
        });
    }
});
module.exports = ProjectWizardSteps;
