"use strict";

var React = require('react'),
    ReactRouter = require('react-router'),
    Card = require('react-credit-card'),
    BS = require('react-bootstrap'),
    Constants = require('../../constants'),
    FluxBone = require('../../mixins/FluxBone'),
    CurrentUser = require('../../shared/CurrentUser'),
    DeleteDialog = require('../DeleteDialog'),
    Link = ReactRouter.Link;

var CreditCard = React.createClass({displayName: "CreditCard",

    mixins: [
        FluxBone.ModelMixin('model', 'sync destroy')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function(props) {
        var state = this.state || {};

        props = props || this.props;

        if (!props.model.billingModel.isLoaded() && !props.model.billingModel.isLoading()) {
            props.model.billingModel.fetch();
        }

        return {
            id: props.model.id,
            modelToDelete: undefined
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        return {
            model: CurrentUser,
            to: 'UserBilling',
            params: undefined,
            query: undefined
        };
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function(props) {
        this.setState(this.updateState(props));
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.model,
            billing = model.billingModel.toJSON()

        return (
            React.createElement("div", {className: "react-credit-card__container__small center-block"}, 
                React.createElement("h5", {className: "font-bold"}, "Payment Information"), 
                billing.last4 && (
                    React.createElement(Card, {number: billing.last4 ? ('XXXXXXXXXXXX' + billing.last4) : '', name: billing.name, expiry: billing.expMonth && billing.expYear ? ('' + billing.expMonth + billing.expYear) : ''})
                ), 
                !billing.last4 && (
                    React.createElement("div", {className: "react-credit-card__container"}, 
                        React.createElement("div", {className: "react-credit-card react-credit-card--unknown"}, 
                            React.createElement("div", {className: "react-credit-card__front"}, 
                                React.createElement("div", {className: "react-credit-card__lower"}, 
                                    React.createElement("div", {className: "react-credit-card__number react-credit-card__display"}, 
                                        "No payment info on file."
                                    )
                                )
                            )
                        )
                    )
                ), 
                model.billingModel.isLoaded() && billing.last4 && Constants.env !== 'prod' && (
                    React.createElement(BS.Button, {className: "pull-left margin-top-10", bsStyle: "warning", onClick: this.removeCard}, "Delete")
                ), 
                model.billingModel.isLoaded() && (
                    React.createElement("div", null, 
                        React.createElement("div", {className: "margin-top-10 text-center"}, 
                            React.createElement("i", {className: "fa fa-fw fa-2x fa-cc-visa"}), " ", React.createElement("i", {className: "fa fa-fw fa-2x fa-cc-mastercard"}), " ", React.createElement("i", {className: "fa fa-fw fa-2x fa-cc-amex"}), " ", React.createElement("i", {className: "fa fa-fw fa-2x fa-cc-discover"})/*&nbsp;<i className="fa fa-fw fa-2x fa-cc-diners-club"/> */
                        ), 
                        React.createElement(Link, {to: props.to, params: props.params, query: props.query, className: "btn btn-default pull-right margin-top-10"}, billing.cc.num ? 'Update Card' : 'Add a Credit Card')
                    )
                ), 
                React.createElement(DeleteDialog, {
                    modelToDelete: state.modelToDelete, 
                    title: "Delete Saved Credit Card?", 
                    deleteLabel: "Delete", 
                    showDialog: state.showDeleteDialog, 
                    msg: "Please confirm that you would like to DELETE your credit card data.", 
                    onHide: me.onHideDeleteDialog, 
                    onDelete: this.onDeleteCard})
            )
        );
    },

    removeCard: function() {
        this.setState({
            modelToDelete: this.props.model.billingModel
        });
    },

    onHideDeleteDialog: function() {
        this.setState({
            modelToDelete: undefined
        });
    },

    onDeleteCard: function() {
        var me = this,
            model = me.props.model,
            billingModel = model.billingModel;

        if (window.Intercom) {
            window.Intercom('trackEvent', 'card-deleted');
        }
        if (window.analytics) {
            analytics.track('card-deleted');
        }

        billingModel.clear();

        me.forceUpdate();
    }
});
module.exports = CreditCard;
