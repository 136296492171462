"use strict";

var React = require('react'),
    $ = require('jquery'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    PageHeader = require('../partials/PageHeader'),
    PageFooter = require('../partials/PageFooter'),
    ReactRouter = require('react-router'),
    NavLink = require('../components/NavLink'),
    BS = require('react-bootstrap'),
    CurrentUser = require('../shared/CurrentUser'),
    ContactForm = require('../partials/ContactForm'),
    Link = ReactRouter.Link;

var NotFound = React.createClass({displayName: "NotFound",

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var model = this.props.contactModel,
            query = this.context.router.getCurrentQuery(),
            route = this.getCurrentRoute(),
            errorCode = route.isNotFound ? '404' : undefined,
            response = query.response ? JSON.parse(query.response) : {};

        errorCode = errorCode || route.name.match(/Error([0-9]{3})/)[1] || 404;

        return {
            submitted: false,
            errorCode: errorCode,
            name: CurrentUser.getFullName(),
            email: CurrentUser.get('email'),
            code: 'toolbox',
            subject: 'Message from ' + errorCode + ' error page',
            url: query.page,
            userMessage: response.userMessage,
            developerMessage: response.developerMessage,
            message: response.message
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        return {};
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    componentDidMount: function() {
        var head = $('head'),
            existingScript = $('#testimonialsNotFound'),
            script = $('<script id="testimonials" type="text/javascript" src="http://testimonialtree.com/widgets/?widgetid=388"></script>');

        if (!existingScript.length) {
            head.append(script);
        }

        this.logError();
    },

    componentDidUpdate: function(prevProps, prevState){
        if(prevState.url !== this.state.url || prevState.errorCode !== this.state.errorCode) {
            this.logError();
        }
    },

    logError: function() {
        var route = this.getCurrentRoute();
        if (window.Intercom) {
            window.Intercom('trackEvent', route.name, this.state);
        }

        if (window.analytics) {
            analytics.track(route.name, this.state);
        }
    },

    componentWillUnmount: function() {
        var existingScript = $('#testimonialsNotFound');

        if (existingScript.length) {
            existingScript.remove();
        }
    },

    render: function() {
        var props = this.props,
            state = this.state,
            contactFormProps = {
                onSubmit: this.onSubmit,
                name: state.name,
                email: state.email,
                code: state.code,
                subject: state.subject,
                url: state.url,
                errorCode: state.errorCode
            };

        return (
            React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: "NCH - Error"}), 
                 !this.props.hideHeaderAndFooter && (
                    React.createElement(PageHeader, null)
                ), 

                 !this.props.hideHeaderAndFooter && (
                    React.createElement("div", {className: "breadcrumbs margin-bottom-40"}, 
                        React.createElement("div", {className: "container"}, 
                            React.createElement("h1", {className: "pull-left"}, "Oops :)"), 
                            React.createElement("ul", {className: "pull-right breadcrumb"}, 
                                React.createElement(NavLink, {to: "Dashboard"}, "Home"), 
                                React.createElement(NavLink, {className: "active"}, "Oops")
                            )
                        )
                    )
                ), 
                React.createElement("div", {className: "container"}, 
                    this.state.submitted && 
                        React.createElement("div", {className: "title-box"}, 
                            React.createElement("div", {className: "title-box-text"}, "Thank you for contacting New Construction Hub. ", React.createElement("br", null), 
                                React.createElement(Link, {to: "Dashboard"}, "To continue, please click here to return to our home page.")
                            )
                        ), 
                    
                    !this.state.submitted && 

                    React.createElement("div", {className: "row"}, 
                        React.createElement("div", {className: "title-box"}, 
                            React.createElement("div", {className: "title-box-text"}, "We apologize but the page you are looking for is not currently available.", React.createElement("br", null), 
                                React.createElement(Link, {to: "Dashboard"}, "To continue, please click here to return to our home page.")
                            )
                        ), 
                        React.createElement("div", null, 
                            React.createElement("div", {className: "col-md-6"}, 
                                React.createElement("div", {className: "headline"}, 
                                    React.createElement("h2", null, "What our Clients are saying:")
                                ), 
                                React.createElement("div", {className: "bg-light"}, 
                                    React.createElement("div", {id: "TestimonialTree_Widget_388"})
                                )
                            )
                        ), 
                        React.createElement("div", {className: "col-md-6"}, 
                            React.createElement("div", {className: "headline"}, 
                                React.createElement("h2", null, "Help us fix this. ", React.createElement("br", null), "Please tell us how you got to this page.")
                            ), 
                            React.createElement(ContactForm, React.__spread({},  contactFormProps))
                        )
                    )
                    
                ), 
                 !this.props.hideHeaderAndFooter && (
                    React.createElement(PageFooter, null)
                ), 
                this.props.Footer && (
                    React.createElement(this.props.Footer, {trackerId: Constants.trackerPrefix + 'not-found'})
                )
            )
        );
    },

    onSubmit: function(model) {
        this.setState({
            submitted: true
        });
    },

    getCurrentRoute: function() {
        var routes = this.context.router.getCurrentRoutes();

        return routes[routes.length - 1];
    }
});
module.exports = NotFound;
