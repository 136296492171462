"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    Lead = require('../models/Lead');

var Lead = Collection.extend({
    url: Lead.prototype.urlRoot,
    model: Lead,

    sync: function(method, colection, options) {
        options = options || {};
        options.data = options.data || {};

        if (this.parent) {
            options.data.agentId = this.parent.id;
        } else if(Constants.agentId){
            options.data.agentId = Constants.agentId;
        }

        return Collection.prototype.sync.call(this, method, colection, options);
    }
});

module.exports = Lead;
