"use strict";

var React = require('react'),
    $ = require('jquery'),
    BS = require('react-bootstrap'),
    ValidationMixin = require('./ValidationMixin'),
    cx = require('classnames'),
    fieldsThatForceUpdate = ['error', 'hasFeedback', 'readOnly', 'disabled', 'groupClassName', 'showRequired', 'hidden', 'readOnly', 'className', 'value', 'valueLink', 'checked', 'checkedLink', 'children', 'showClear'];

var ValidationInput = React.createClass({displayName: "ValidationInput",

    mixins: [
        ValidationMixin
    ],

    getDefaultProps: function() {
        return {
            trigger: 'onChange',
            fieldsThatForceUpdate: undefined,
            type: undefined,
            required: false,
            showRequired: true,
            disabled: false,
            error: undefined,
            inline: false
        };
    },

    shouldComponentUpdate: function(props) {
        var me = this,
            shouldUpdate = false,
            oldProps = me.props;

        if (me.getValue(props) !== me.getValue(oldProps)) {
            shouldUpdate = true;
        } else {
            _.some(fieldsThatForceUpdate, function(field) {
                shouldUpdate = oldProps[field] !== props[field];
                return shouldUpdate;
            });
            !shouldUpdate && props.fieldsThatForceUpdate &&_.some(props.fieldsThatForceUpdate, function(field) {
                shouldUpdate = oldProps[field] !== props[field];
                return shouldUpdate;
            });
        }

        return shouldUpdate;
    },

    render: function() {
        var props = this.props,
            validationProperties = this.getValidationProperties(),
            value = this.getValue(),
            placeholder = props.placeholder;

        if(props.hideLabel) {
            props = _.omit(props, 'label');
            placeholder = placeholder || this.props.label;
        }

        if(placeholder && props.required && props.showRequired) {
            placeholder = placeholder + ' **';
        }

        if(props.type === 'checkbox' || props.type === 'radio') {
            return (
                React.createElement(BS.Input, React.__spread({},  props,  validationProperties, {onClick: this.onClick}), 
                    React.createElement("i", {className: props.iconClassName})
                )
            );
        } else if(props.clearable) {
            return (
                React.createElement(BS.Input, React.__spread({},  props,  validationProperties, {onClick: this.onClick, placeholder: placeholder}), 
                    React.createElement("i", {className: "fa fa-times form-control-feedback", "aria-hidden": value || props.showClear ? undefined : true, onClick: this.onClearClick})
                )
            );
        } else {
            return (
                React.createElement(BS.Input, React.__spread({},  props,  validationProperties, {onClick: this.onClick, placeholder: placeholder}))
            );
        }
    },

    onClearClick: function(e) {
        if(this.props.valueLink && this.props.valueLink.requestChange) {
            this.props.valueLink.requestChange('');
        }

        if(this.props.onClear) {
            this.props.onClear(e);
        }
    },

    getValidationProperties: function() {
        var props = this.props,
            state = this.state,
            required = props.required && props.showRequired,
            validationProps,
            hasError = !!props.error;

        if (this.props.type === 'hidden') {
            hasError = false;
        }

        validationProps = {
            bsStyle: hasError ? 'error' : props.bsStyle,
            hasFeedback: hasError || props.hasFeedback,
            readOnly: props.readOnly,
            help: hasError ? (props.invalidMsg ? props.invalidMsg : props.error) : props.help,
            groupClassName: cx(props.groupClassName, {
                'form-group-required': required && props.showRequired,
                'hidden': props.hidden,
                'form-group-inline': props.inline,
                'has-feedback': props.clearable
            }),
            className: cx(props.className, {
                'readonly': props.readOnly
            })
        };

        if (this.props._validate) {
            validationProps[props.trigger] = this.onValidateEvent;
        }

        return validationProps;
    },

    getValue: function(props) {
        props = props || this.props;

        var value = props.value;

        if (props.valueLink) {
            value = props.valueLink.value;
        }
        if (props.checkedLink) {
            value = props.checkedLink.value;
        }

        return value;
    },

    componentWillUpdate: function(props, state) {
        this.checkAndFixDirtyInput(props, state);
    },

    checkAndFixDirtyInput: function(props, state) {
        var value = this.getValue(props) || '',
            domValue = $(React.findDOMNode(this)).find('input, textarea, select').val() || '';

        if (domValue && value !== domValue && this.tempValue !== value && this.props.valueLink && this.props.valueLink.requestChange) {
            this.tempValue = domValue;
            this.props.valueLink.requestChange(domValue, true);
        }
    },

    onClick: function(e) {
        if (this.props.disabled) {
            e.preventDefault();
            e.stopPropagation();
        } else if (this.props.onClick) {
            this.props.onClick(e);
        }
    }
});

module.exports = ValidationInput;
