"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    Cmp = require('../../components'),
    Validation = require('../../partials/validation'),
    defaultControlAttr = {
        type: 'text',
        labelClassName: 'col-md-5',
        wrapperClassName: 'col-md-7'
    },
    getTypeSpecificFields = function(cmp, model, type, isAdmin) {
        var fields = null,
            teamNameLabel = (
                React.createElement("div", null, 
                    "Your Team Name:", 
                    React.createElement("br", null), 
                    "(if applicable)"
                )
            );

        switch (type) {
            case 'Agent':
                fields = (
                    React.createElement("div", null, 
                        React.createElement("h2", null, "Agent Info"), 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-md-6"}, 
                                React.createElement(Validation.Input, React.__spread({name: "brokerageName", label: "Brokerage Name:"},  defaultControlAttr)), 
                                React.createElement(Validation.Input, React.__spread({name: "teamName", label: teamNameLabel},  defaultControlAttr)), 
                                React.createElement(Validation.Input, React.__spread({name: "nameToDisplay", label: "Name to Display:"},  defaultControlAttr, {type: "select"}), 
                                    React.createElement("option", {value: ""}, "Select"), 
                                    React.createElement("option", {value: "Brokerage"}, "Brokerage"), 
                                    React.createElement("option", {value: "FirstAndLast"}, "First and Last"), 
                                    React.createElement("option", {value: "TeamName"}, "Team Name")
                                )
                            )
                        )
                    )
                );
                break;
            case 'Developer':
                fields = (
                    React.createElement("div", null, 
                        React.createElement("h2", null, "Developer Info"), 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-md-6"}, 
                                React.createElement(Validation.Input, React.__spread({name: "realName", label: "Business Name:"},  defaultControlAttr))
                            )
                        )
                    )
                );
                break;
            case 'Builder':
                fields = (
                    React.createElement("div", null, 
                        React.createElement("h2", null, "Builder Info"), 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-md-6"}, 
                                React.createElement(Validation.Input, React.__spread({name: "realName", label: "Business Name:"},  defaultControlAttr))
                            )
                        )
                    )
                );
                break;
            case 'Broker':
                fields = (
                    React.createElement("div", null, 
                        React.createElement("h2", null, "Broker Info"), 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-md-6"}, 
                                React.createElement(Validation.Input, React.__spread({name: "brokerageName", label: "Brokerage Name:"},  defaultControlAttr))
                            )
                        )
                    )
                );
                break;
        }

        return fields;
    },
    UserAddEdit = function(cmp, model, type, isAdmin) {

        return (
            React.createElement("div", null, 
                React.createElement("h2", null, "Login Info:"), 
                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-md-6"}, 
                        React.createElement(Validation.Input, React.__spread({name: "user.username", label: "Username:"},  defaultControlAttr)), 
                        React.createElement(Validation.Input, {name: "user.password", type: "password", label: "Password:", labelClassName: "col-md-5", wrapperClassName: "col-md-7"}), 
                        React.createElement(Validation.Input, {name: "user.passwordConfirm", type: "password", label: "Confirm Password:", labelClassName: "col-md-5", wrapperClassName: "col-md-7"})
                    ), 
                    React.createElement("div", {className: "col-md-6"}, 
                        isAdmin && (
                            React.createElement(Validation.Input, {name: "user.email", type: "text", label: "Email:", labelClassName: "col-md-5", wrapperClassName: "col-md-7"})
                        )
                    )
                ), 

                React.createElement("h2", null, "Contact Info:"), 
                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-md-6"}, 
                        React.createElement(Validation.Input, React.__spread({name: "contactInfo.firstName", label: "First Name:"},  defaultControlAttr)), 
                        React.createElement(Validation.Input, React.__spread({name: "contactInfo.lastName", label: "Last Name:"},  defaultControlAttr)), 
                        React.createElement(Validation.Input, React.__spread({name: "contactInfo.email", label: "Email Address:"},  defaultControlAttr)), 
                        React.createElement(Validation.Input, React.__spread({name: "contactInfo.emailConfirm", label: "Confirm Email Address:"},  defaultControlAttr)), 
                        React.createElement(Validation.Input, React.__spread({name: "website", label: type === 'Agent' ? 'Agent Website:' : 'Project URL:'},  defaultControlAttr)), 
                        React.createElement(Validation.Input, React.__spread({name: "contactInfo.phoneHome", label: "Home Phone:"},  defaultControlAttr)), 
                        React.createElement(Validation.Input, React.__spread({name: "contactInfo.phoneMobile", label: "Mobile Phone:"},  defaultControlAttr)), 
                        React.createElement(Validation.Input, React.__spread({name: "contactInfo.phoneWork", label: "Work Phone:"},  defaultControlAttr)), 
                        React.createElement(Validation.Input, React.__spread({name: "contactInfo.fax", label: "Fax:"},  defaultControlAttr))
                    ), 
                    React.createElement("div", {className: "col-md-6"}, 
                        React.createElement(Validation.Input, React.__spread({name: "contactInfo.address1", label: "Address:"},  defaultControlAttr)), 
                        React.createElement(Validation.Input, React.__spread({name: "contactInfo.address2", label: "Address 2:"},  defaultControlAttr)), 
                        React.createElement(Cmp.Select.Country, React.__spread({name: "contactInfo.city.country.id", label: "Country:"},  defaultControlAttr)), 
                        React.createElement(Cmp.Select.State, React.__spread({name: "contactInfo.city.state.id", formLinks: {countryId:'model.contactInfo.city.country.id'}, label: "State / Region:"},  defaultControlAttr)), 
                        React.createElement(Cmp.Select.City, React.__spread({name: "contactInfo.city.id", formLinks: {stateId:'model.contactInfo.city.state.id'}, label: "City / Town:"},  defaultControlAttr)), 
                        React.createElement(Validation.Input, React.__spread({name: "contactInfo.zip", label: "Zip:"},  defaultControlAttr))
                    )
                ), 

                getTypeSpecificFields(cmp, model, type, isAdmin)
            )
        );
    };


module.exports = UserAddEdit;
