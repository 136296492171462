"use strict";

var React = require('react'),
    ReactRouter = require('react-router'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    FluxBone = require('../../mixins/FluxBone'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    BrokerModel = require('../../models/Broker'),
    Cmp = require('../../components'),
    SidebarNav = require('../../partials/SidebarNav'),
    Validation = require('../../partials/validation'),
    CurrentUser = require('../../shared/CurrentUser'),
    AddEditAgent = require('../../partials/broker/AddEditAgent');

var BrokerAddAgent = React.createClass({displayName: "BrokerAddAgent",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset'),
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function(props) {
        return {};
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        return {
            model: new BrokerModel()
        };
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },


    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = CurrentUser.getEntity(),
            name = model.getDisplayName(),
            title = 'Broker - ' + name,
            nav;

        nav = (
            React.createElement("ul", {className: "pull-right breadcrumb"}, 
                React.createElement(Cmp.NavLink, {to: "AgentList"}, "Agents"), 
                React.createElement(Cmp.NavLink, {to: "BrokerAgentAdd", params: { id: model.id}}, "New Agent")
            )
        );

         return (
             React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: 'NCH - ' + title}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Brokers"), 
                        nav
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement("div", {className: "row half-gutter"}, 
                        React.createElement("div", {className: "col-md-3 md-margin-bottom-40"}, 
                            React.createElement(SidebarNav, null)
                        ), 
                        React.createElement("div", {className: "col-md-9"}, 
                            React.createElement(AddEditAgent, {brokerId: model.id, broker: model})
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    }
});

module.exports = BrokerAddAgent;
