// Generated by CoffeeScript 1.8.0
(function() {
  var Payment, React, exp, images, validate;

  React = require('react');

  Payment = require('payment');

  images = require('./card-images.js');

  validate = Payment.fns;

  module.exports = React.createClass({
    displayName: 'Card',
    getDefaultProps: function() {
      return {
        number: null,
        cvc: null,
        name: '',
        expiry: '',
        focused: null,
        expiryBefore: 'month/year',
        expiryAfter: 'valid thru',
        shinyAfterBack: ''
      };
    },
    render: function() {
      return React.createElement("div", {
        "className": "" + exp.prefix + "__container"
      }, React.createElement("div", {
        "className": ("" + exp.prefix + " ") + this.typeClassName() + (this.props.focused === "cvc" ? " " + exp.prefix + "--flipped" : "")
      }, React.createElement("div", {
        "className": "" + exp.prefix + "__front"
      }, React.createElement("div", {
        "className": "" + exp.prefix + "__lower"
      }, React.createElement("div", {
        "className": "" + exp.prefix + "__shiny"
      }), React.createElement("img", {
        "className": ("" + exp.prefix + "__logo ") + this.typeClassName(),
        "src": images[this.state.type.name]
      }), React.createElement("div", {
        "className": this.displayClassName("number")
      }, this.getValue("number")), React.createElement("div", {
        "className": this.displayClassName("name")
      }, this.getValue("name")), React.createElement("div", {
        "className": this.displayClassName("expiry"),
        "data-before": this.props.expiryBefore,
        "data-after": this.props.expiryAfter
      }, this.getValue("expiry")))), React.createElement("div", {
        "className": "" + exp.prefix + "__back"
      }, React.createElement("div", {
        "className": "" + exp.prefix + "__bar"
      }), React.createElement("div", {
        "className": this.displayClassName("cvc")
      }, this.getValue("cvc")), React.createElement("div", {
        "className": "" + exp.prefix + "__shiny",
        "data-after": this.props.shinyAfterBack
      }))));
    },
    displayClassName: function(base) {
      var className;
      className = ("" + exp.prefix + "__") + base + (" " + exp.prefix + "__display");
      if (this.props.focused === base) {
        className += " " + exp.prefix + "--focused";
      }
      return className;
    },
    typeClassName: function() {
      return ("" + exp.prefix + "--") + this.state.type.name;
    },
    getValue: function(name) {
      return this[name]();
    },
    componentWillMount: function() {
      return this.updateType(this.props);
    },
    componentWillReceiveProps: function(nextProps) {
      return this.updateType(nextProps);
    },
    getInitialState: function() {
      return {
        type: {
          name: "unknown",
          length: 16
        }
      };
    },
    updateType: function(props) {
      var type;
      if (!props.number) {
        return this.setState({
          type: {
            name: "unknown",
            length: 16
          }
        });
      }
      if (type = validate.cardType(props.number)) {
        return this.setState({
          type: {
            name: type,
            length: 16
          }
        });
      }
      return this.setState({
        type: {
          name: "unknown",
          length: 16
        }
      });
    },
    number: function() {
      var amountOfSpaces, i, maxLength, space_index, string, _i;
      if (!this.props.number) {
        string = "";
      } else {
        string = this.props.number.toString();
      }
      maxLength = this.state.type.length;
      if (string.length > maxLength) {
        string = string.slice(0, maxLength);
      }
      while (string.length < maxLength) {
        string += "•";
      }
      amountOfSpaces = Math.ceil(maxLength / 4);
      for (i = _i = 1; 1 <= amountOfSpaces ? _i < amountOfSpaces : _i > amountOfSpaces; i = 1 <= amountOfSpaces ? ++_i : --_i) {
        space_index = i * 4 + (i - 1);
        string = string.slice(0, space_index) + " " + string.slice(space_index);
      }
      return string;
    },
    name: function() {
      if (this.props.name === "") {
        return "FULL NAME";
      } else {
        return this.props.name;
      }
    },
    expiry: function() {
      var expiry, expiryMaxLength;
      if (this.props.expiry === "") {
        return "••/••";
      } else {
        expiry = this.props.expiry.toString();
        expiryMaxLength = 6;
        if (expiry.match(/\//)) {
          expiry = expiry.replace("/", "");
        }
        if (!expiry.match(/^[0-9]*$/)) {
          return "••/••";
        }
        while (expiry.length < 4) {
          expiry += "•";
        }
        expiry = expiry.slice(0, 2) + "/" + expiry.slice(2, expiryMaxLength);
        return expiry;
      }
    },
    cvc: function() {
      if (this.props.cvc === null) {
        return "•••";
      } else {
        if (this.props.cvc.toString().length <= 4) {
          return this.props.cvc;
        } else {
          return this.props.cvc.toString().slice(0, 4);
        }
      }
    }
  });

  exp = module.exports;

  exp.prefix = "react-credit-card";

}).call(this);
