"use strict";

var ModelClass = require('./Model'),
    Constants = require('../constants'),
    $ = require('jquery');

var ProjectContact = ModelClass.extend({
    urlRoot: Constants.restPath + "/listing-poc",
    idAttribute: 'id',
    validation: {
        name: {
            required: true
        },
        email: {
            required: true,
            pattern: 'email'
        }
    },
    defaults: function() {
        return {
            id: undefined,
            name: '',
            phone: '',
            email: '',
            receiveNotification: false
        };
    }
});

module.exports = ProjectContact;
