"use strict";

var React = require('react'),
    cx = require('classnames'),
    Constants = require('../constants'),
    ReactRouter = require('react-router'),
    NavLink = require('../components/NavLink'),
    FluxBone = require('../mixins/FluxBone'),
    CurrentUser = require('../shared/CurrentUser'),
    Link = require('../components/Link'),
    Roles = require('../mixins/Roles');

var SidebarNav = React.createClass({displayName: "SidebarNav",

    mixins: [
        FluxBone.ModelMixin('currentUser', 'sync'),
        Roles
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        return {};
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function(){
        return {
            currentUser: CurrentUser
        };
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },
    
    render: function() {
        var loggedIn = this.props.currentUser.isLoggedIn(),
            roles = Constants.roles;

        return (
            React.createElement("div", null, 
                React.createElement("ul", {className: "list-group sidebar-nav-v1 margin-bottom-20"}, 
                    loggedIn && (
                        React.createElement(NavLink, {to: "Dashboard", className: "list-group-item"}, 
                            React.createElement("i", {className: "fa fa-fw fa-home"}), " Dashboard"
                        )
                    ), 

                    this.hasOneRole(roles.DEVELOPER, roles.ADMIN) && (
                        React.createElement(NavLink, {to: "ProjectList", routes: Constants.routes.PROJECT, className: "list-group-item"}, 
                            React.createElement("i", {className: "fa fa-fw fa-th-large"}), " My Listings"
                        )
                    ), 

                    this.hasOneRole(roles.ADMIN) && (
                        React.createElement(NavLink, {to: "BrokerList", routes: Constants.routes.BROKER, className: "list-group-item"}, 
                            React.createElement("i", {className: "fa fa-fw fa-th-large"}), " Brokers"
                        )
                    ), 

                    this.hasOneRole(roles.ADMIN) && (
                        React.createElement(NavLink, {to: "DeveloperList", routes: Constants.routes.DEVELOPER, className: "list-group-item"}, 
                            React.createElement("i", {className: "fa fa-fw fa-th-large"}), " Developers"
                        )
                    ), 

                    this.hasOneRole(roles.ADMIN) && (
                        React.createElement(NavLink, {to: "Widgets", className: "list-group-item"}, 
                            React.createElement("i", {className: "fa fa-fw fa-lightbulb-o"}), " Widgets"
                        )
                    ), 

                    
                    this.hasOneRole(roles.ADMIN) && (
                        React.createElement(NavLink, {to: "UserList", routes: Constants.routes.USER, className: "list-group-item"}, 
                            React.createElement("i", {className: "fa fa-fw fa-th-large"}), " Users"
                        )
                    ), 

                    this.hasOneRole(roles.ADMIN, roles.BROKER) && (
                        React.createElement(NavLink, {to: "AgentList", routes: Constants.routes.AGENT, className: "list-group-item"}, 
                            React.createElement("i", {className: "fa fa-fw fa-street-view"}), " Agents"
                        )
                    ), 
                    
                    this.hasOneRole(roles.DEVELOPER, roles.BUILDER, roles.ADMIN) && (
                        React.createElement(NavLink, {to: "Reports", className: "list-group-item"}, 
                            React.createElement("i", {className: "fa fa-fw fa-line-chart"}), " Reports"
                        )
                    ), 

                    
                        this.hasOneRole(roles.AGENT, roles.BROKER) && [

                        React.createElement(NavLink, {to: "Widgets", key: "widgets-sidebar-link", className: "list-group-item"}, 
                            React.createElement("i", {className: "fa fa-fw fa-code"}), " My Widgets"
                        ),

                        React.createElement(NavLink, {to: "AgentLeads", key: "leads-sidebar-link", routes: Constants.routes.LEADS, className: "list-group-item"}, 
                            React.createElement("i", {className: "fa fa-fw fa-users"}), " My Leads"
                        )
                        ], 
                    

                    loggedIn && (
                        React.createElement(NavLink, {to: "UserAccount", className: "list-group-item"}, 
                            React.createElement("i", {className: "fa fa-fw fa-cogs"}), " Edit Account"
                        )
                    ), 

                    this.hasOneRole() && (
                        React.createElement(NavLink, {to: "Tips", className: "list-group-item"}, 
                            React.createElement("i", {className: "fa fa-lightbulb-o"}), " Tips"
                        )
                    ), 

                    this.hasOneRole(roles.AGENT) && !this.hasOneRole(roles.BROKER) && 
                        React.createElement(NavLink, {to: "UserMembership", className: "list-group-item"}, 
                            React.createElement("i", {className: "fa fa-fw fa-diamond"}), " Premium Agent Settings ", React.createElement("img", {src: Constants.imageRoot + 'PremiumIcon.png', className: "badge"})
                        ), 
                    

                    /*this.hasOneRole(roles.AGENT, roles.BROKER) && (
                        <NavLink to="EmailTools" className="list-group-item">
                            <i className="fa fa-lightbulb-o"></i> Email Tools
                        </NavLink>
                    )*/

                    /*this.hasOneRole(roles.AGENT, roles.BROKER) && (
                        <NavLink to="SocialTools" className="list-group-item">
                            <i className="fa fa-lightbulb-o"></i> Social Tools
                        </NavLink>
                    )*/

                    React.createElement(NavLink, {linkClassName: "help-link", className: "list-group-item"}, 
                        React.createElement("i", {className: "fa fa-fw fa-question-circle"}), " Help"
                    )
                ), 
                this.hasOneRole(roles.DEVELOPER) && (
                    React.createElement(Link, {to: "UserUpgradeAndBilling", className: "btn-u btn-u-orange btn-block"}, 
                        React.createElement("i", {className: "fa fa-fw fa-3x fa-paper-plane pull-left"}), 
                        React.createElement("div", {className: "text-center"}, "Upgrades & Billing", React.createElement("br", null), React.createElement("small", null, "Click here to access."))
                    )
                )
            )
        );
    }
});
module.exports = SidebarNav;
