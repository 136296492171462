'use strict';

var React = require('react'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    ModelClass = require('../../../models/Model'),
    UserLogin = require('../../../models/UserLogin'),
    UserForgot = require('../../../models/UserForgot'),
    DocumentTitle = require('react-document-title'),
    CurrentUser = require('../../../shared/CurrentUser'),
    Constants = require('../../../constants'),
    Cmp = require('../../../components'),
    Link = ReactRouter.Link,
    Validation = require('../../../partials/validation');

var UserCheck = ModelClass.extend({
    validation: {
        email: {
            required: true,
            pattern: 'email'
        }
    }
});

var EmailCheck = React.createClass({displayName: "EmailCheck",
    mixins: [ReactRouter.Navigation],
    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            userCheck: new UserCheck(),
            userLogin: new UserLogin(),
            userForgot: new UserForgot()
        };
    },

    updateState: function() {
        return {
            email: undefined,
            reset: undefined
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    componentWillMount: function() {
        this.props.userCheck.clear();
        this.props.userLogin.clear();
        this.props.userForgot.clear();
    },

    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    componentDidUpdate: function(prevProps, prevState) {
        if ((prevState.email !== this.state.email || prevState.reset !== this.state.reset) && window.analytics && !(CurrentUser.hasRole(Constants.roles.ADMIN) || !!CurrentUser.get('actualId'))) {
            analytics.page();
        }
    },

    render: function() {
        var inputParams = {
            type: 'text',
            trigger: 'onChange'
        },
            usernameIcon = React.createElement("i", {className: "fa fa-envelope fa-fw"}),
            passwordIcon = React.createElement("i", {className: "fa fa-lock fa-fw"});

        this.props.userLogin.set('username', this.state.email);

        return this.state.email
            ? this.state.reset
              ? React.createElement("div", null, 
                    React.createElement(DocumentTitle, {title: "NCH - Signup - Reset Password"}), 
                    React.createElement("div", {className: "row text-center margin-bottom-20"}, 
                        React.createElement("div", {className: "col-md-6 col-md-offset-3"}, 
                            React.createElement("h5", {className: "text-center blue-text"}, 
                                React.createElement("i", {className: "fa fa-check-square-o fa-4x center-block"}), 
                                React.createElement("div", {className: "font-large"}, "Success! ")
                            ), 
                            React.createElement("p", null, 
                                "Your login information was sent to ", this.state.email, ". If you do not" + ' ' +
                                "see it in your inbox, try checking your junk or spam folder."
                            )
                        )
                    ), 
                    React.createElement("div", {key: "loginFormFull", className: "row margin-top-20"}, 
                        React.createElement("div", {className: "col-md-6 col-md-offset-3"}, 
                            React.createElement(BS.Panel, {className: "panel-split-header margin-bottom-20", header: React.createElement("h2", null, "Log into your New Construction Hub account")}, 
                                React.createElement(Validation.Form, {
                                    ref: "loginFormFull", 
                                    horizontal: false, 
                                    getMessage: this.getLoginErrorMessage, 
                                    model: this.props.userLogin, 
                                    onSubmit: this.onUserLoginSubmit, 
                                    validateOnLoad: false}, 
                                    React.createElement(Validation.Input, {trigger: "onChange", addonBefore: usernameIcon, type: "text", placeholder: "Email Address", name: "username"}), 
                                    React.createElement(Validation.Input, {trigger: "onChange", addonBefore: passwordIcon, type: "password", placeholder: "Password", name: "password"}), 

                                    React.createElement("div", {className: "row"}, 
                                        React.createElement("div", {className: "col-sm-6"}, 
                                            React.createElement(Validation.Input, {type: "checkbox", useCheckedLink: true, groupClassName: "margin-bottom-0", wrapperClassName: "pull-left", label: "Stay Logged In", name: "rememberMe"})
                                        ), 
                                        React.createElement("div", {className: "col-sm-6"}, 
                                            React.createElement(Cmp.Button.Save, {className: "pull-right"}, React.createElement("i", {className: "fa"}), "Login")
                                        )
                                    )
                                )
                            ), 
                            this.props.Footer && React.createElement(this.props.Footer, {trackerId: Constants.trackerPrefix + 'signup-login'})
                        )
                    )
                )
              : React.createElement("div", null, 
                    React.createElement(DocumentTitle, {title: "NCH - Signup - Account Already Exists"}), 
                    React.createElement("div", {className: "row"}, 
                        React.createElement("div", {className: "col-md-8 col-md-offset-2"}, 
                            React.createElement("h2", null, "Looks like you already have a New Construction Hub Account!")
                        )
                    ), 
                    React.createElement("div", {key: "loginForm", className: "row"}, 
                        React.createElement("div", {className: "col-md-6 col-md-offset-3"}, 
                            React.createElement(BS.Panel, null, 
                                React.createElement(Validation.Form, {ref: "loginForm", getMessage: this.getLoginErrorMessage, model: this.props.userLogin, onSubmit: this.onUserLoginSubmit, validateOnLoad: false}, 
                                    React.createElement("h3", null, "Log into your New Construction Hub account:"), 
                                    React.createElement("p", null, "Your account email address: ", this.state.email), 
                                    React.createElement(Validation.Input, {name: "username", type: "hidden"}), 
                                    React.createElement(Validation.Input, React.__spread({}, 
                                        inputParams, 
                                        {addonBefore: passwordIcon, 
                                        name: "password", 
                                        type: "password", 
                                        label: "Password:", 
                                        labelClassName: "col-md-3", 
                                        wrapperClassName: "col-md-9"})
                                    ), 
                                    React.createElement(Cmp.Button.Save, {className: "pull-right"}, "Login")
                                )
                            )
                        )
                    ), 
                    React.createElement("div", {className: "row"}, 
                        React.createElement("div", {className: "col-md-6 col-md-offset-3"}, 
                            React.createElement(BS.Panel, null, 
                                React.createElement("h3", {className: "text-center"}, "Forgot your password?"), 
                                React.createElement("p", {className: "text-center"}, "No problem", React.createElement("br", null), "Click on the link below to reset your password"), 
                                React.createElement(Cmp.Button.Save, {className: "center-block", onClick: this.resetPassword}, "Reset my password")
                            ), 
                            this.props.Footer && React.createElement(this.props.Footer, {trackerId: Constants.trackerPrefix + 'signup-login'})
                        )
                    )
                )
            : React.createElement("div", null, 
                  React.createElement(DocumentTitle, {title: "NCH - Signup - Email Check"}), 
                  React.createElement("div", {className: "row"}, 
                      React.createElement("div", {className: "col-md-6 col-md-offset-3"}, 
                          React.createElement("h3", {className: "text-center"}, "Create your New Construction Hub account."), 
                          React.createElement(BS.Panel, null, 
                              React.createElement(Validation.Form, {horizontal: false, ref: "userCheckForm", model: this.props.userCheck, onSubmit: this.onUserCheckSubmit, validateOnLoad: false}, 
                                  React.createElement(Validation.Input, React.__spread({addonBefore: usernameIcon},  inputParams, {name: "email", label: "To start, enter your email address:"})), 
                                  React.createElement("p", {className: "theme-grey-2"}, "By creating an account, you are agreeing to all terms & conditions."), 
                                  React.createElement(Cmp.Button.Save, {className: "pull-right"}, "Next")
                              )
                          ), 
                          this.props.Footer && React.createElement(this.props.Footer, {trackerId: Constants.trackerPrefix + 'signup-email-check'})
                      )
                  )
              );
    },

    getLoginErrorMessage: function(type, model_collection, response, options) {
        return 'Login Failed. Please try again.';
    },

    resetPassword: function() {
        var me = this;
        this.props.userForgot
            .resetPassword({
                email: this.state.email
            })
            .then(function() {
                me.setState({
                    reset: true
                });
            });
    },

    onUserLoginSubmit: function(e) {
        var me = this,
            form = me.refs.loginForm || me.refs.loginFormFull,
            values = form.getValues(),
            userLogin = me.props.userLogin;

        if (form.isValid()) {
            userLogin.login(values).then(function() {
                me.transitionTo.apply(me, Constants.defaultRedirect);
            });
        }
    },

    onUserCheckSubmit: function(e) {
        var me = this,
            form = me.refs.userCheckForm,
            values = form.getValues();

        if (form.isValid()) {
            CurrentUser.checkIfUserExists(values.email).then(function(doesUserExist) {
                var API = me.context.router.getCurrentQuery().API;
                if (doesUserExist) {
                    me.setState({
                        email: values.email
                    });
                } else {
                    me.transitionTo(
                        'SignupCreate',
                        {
                            email: values.email
                        },
                        API ? { API: API } : undefined
                    );
                }
            });
        }
    }
});
module.exports = EmailCheck;
