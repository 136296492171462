"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    FloorPlan = require('../models/FloorPlan');

var FloorPlans = Collection.extend({
    url: FloorPlan.prototype.urlRoot,
    model: FloorPlan
});

module.exports = FloorPlans;
