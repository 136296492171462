var React = require('react'),
    $ = require('jquery'),
    BS = require('react-bootstrap');

var AjaxLoadingMask = React.createClass({displayName: "AjaxLoadingMask",
    getInitialState: function() {
        var me = this;

        $(document).ajaxSend(function(e, jqXHR, options) {
            if (options.suppressLoad) {
                return;
            }
            me.showIndicator();
        });

        $(document).ajaxStop(function() {
            me.hideIndicator();
        });

        return {
            loading: false
        };
    },
    hideIndicator: function() {
        if (this.isMounted()) {
            this.setState({
                loading: false
            });
        }
    },
    showIndicator: function() {
        if (this.isMounted()) {
            this.setState({
                loading: true
            });
        }
    },
    componentDidMount: function() {},
    closeDialog: function() {},

    render: function() {
        return (
            React.createElement(BS.Modal, {show: this.state.loading > 0, onHide: this.closeDialog, dialogClassName: "loading-mask"}, 
                React.createElement(BS.Modal.Header, null, 
                    React.createElement(BS.Modal.Title, null, "Processing")
                ), 
                React.createElement(BS.Modal.Body, null, 
                    React.createElement(BS.ProgressBar, {striped: true, bsStyle: "success", now: 100})
                )
            )
        );
    }
});

module.exports = AjaxLoadingMask;
