"use strict";

var React = require('react');

var ValidationMixin = {

    propTypes: {
        requiredTrigger: React.PropTypes.string
    },

    getInitialState: function() {
        return {};
    },

    onValidateEvent: function(e) {
        if (this.props[this.props.trigger]) {
            this.props[this.props.trigger](e);
        }

        if ((!e || !e.isDefaultPrevented()) && this.props._validate) {
            this.props._validate(this);
        }
    }
};

module.exports = ValidationMixin;
