"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    ProjectImage = require('../models/ProjectImage');

var ProjectImages = Collection.extend({
    url: ProjectImage.prototype.urlRoot,
    model: ProjectImage,
 	comparator: function(item) {
        return item.get('orderNumber')+0;
    }
});

module.exports = ProjectImages;
