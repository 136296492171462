"use strict";

var React = require('react'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    Link = ReactRouter.Link,
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    Constants = require('../../constants'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    NavLink = require('../../components/NavLink'),
    SidebarNav = require('../../partials/SidebarNav'),
    FluxBone = require('../../mixins/FluxBone'),
    Brokers = require('../../collections/Brokers'),
    CurrentUser = require('../../shared/CurrentUser'),
    Pagination = require('../../partials/Pagination');

var Brokers = React.createClass({displayName: "Brokers",

    mixins: [
        FluxBone.CollectionMixin('store', 'all')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            store: new Brokers()
        };
    },

    updateState: function() {
        this.props.store.pageNum = this.context.router.getCurrentQuery().page || 1;
        this.props.store.fetch({data: {isBroker: true}});
        return {};
    },

    getInitialState: function() {
        return this.updateState();
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        return (
        	React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: "NCH - Brokers Listing"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Brokers"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(NavLink, {to: "BrokerList"}, "Brokers")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement("div", {className: "row half-gutter"}, 
                        React.createElement("div", {className: "col-md-3 md-margin-bottom-40"}, 
                            React.createElement(SidebarNav, null)
                        ), 
                        React.createElement("div", {className: "col-md-9"}, 
                            React.createElement("div", {className: "row half-gutter"}, 
                                React.createElement("div", {className: "col-md-3"}, 
                                    React.createElement("h1", null, "Broker:")
                                ), 
                                React.createElement("div", {className: "col-md-6"}, 
                                    React.createElement("p", null, "Below is a list of Brokers.")
                                ), 
                                React.createElement("div", {className: "col-md-3"}, 
                                    React.createElement(Link, {to: "BrokerAdd", className: "btn btn-default pull-right"}, React.createElement("i", {className: "fa fa-plus"}), " Add Broker")
                                )
                            ), 
                            React.createElement(Pagination, {store: this.props.store, to: "BrokerList"}), 
                            React.createElement(BS.Table, {striped: true, bordered: true, condensed: true, hover: true, id: "brokers-table"}, 
                                React.createElement("thead", null, 
                                    React.createElement("tr", null, 
                                        React.createElement("th", null, React.createElement("i", {className: "fa fa-th-large"}), " Broker Name"), 
                                        React.createElement("th", null, React.createElement("i", {className: "fa fa-flash"}), " Website"), 
                                        React.createElement("th", null, "Create Date"), 
                                        React.createElement("th", null, "How heard")
                                    )
                                ), 
                                React.createElement("tbody", null, 
                                this.props.store.map(function(agent){
                                    var data = agent.toJSON();
                                    return (
                                        React.createElement("tr", {key: agent.id}, 
                                            React.createElement("td", null, React.createElement(Link, {to: "BrokerEdit", params: { id: data.id}}, data.name)), 
                                            React.createElement("td", null, React.createElement("a", {href: data.website}, React.createElement("i", {className: "fa fa-external-link"}))), 
                                            React.createElement("td", null, new Date(data.createDate).toLocaleString()), 
                                            React.createElement("td", null, data.howHeard)
                                        )
                                    );
                                })
                                )
                            ), 
                            React.createElement(Pagination, {store: this.props.store, to: "BrokerList", noMargin: true})
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    }
});

module.exports = Brokers;
