"use strict";

var React = require('react'),
    ReactRouter = require('react-router'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    FluxBone = require('../../mixins/FluxBone'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    BrokerModel = require('../../models/Broker'),
    Cmp = require('../../components'),
    SidebarNav = require('../../partials/SidebarNav'),
    Validation = require('../../partials/validation'),
    UserAddEdit = require('../../partials/user/UserAddEdit'),
    CurrentUser = require('../../shared/CurrentUser');

var BrokerAddEdit = React.createClass({displayName: "BrokerAddEdit",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset'),
        FluxBone.ModelMixin('currentUser', 'sync reset'),
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            id = params.id;

        this.props.model.clear();

        if (id) {
            this.props.model.set('id', id);
            this.props.model.fetch();
        }

        return {
            id: id
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        return {
            model: new BrokerModel(),
            currentUser: CurrentUser
        };
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },


    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.model,
            currentUser = props.currentUser,
            isAdmin = currentUser.isAdmin(),
            name = model.getDisplayName(),
            title = 'Broker - ' + name,
            currentRoute = model.id ? 'BrokerEdit' : 'BrokerAdd',
            defaultControlAttr = {
                type: 'text',
                labelClassName: 'col-md-5',
                wrapperClassName: 'col-md-7'
            },
            nav;

        if (model.id) {
            nav = (
                React.createElement("ul", {className: "pull-right breadcrumb"}, 
                    React.createElement(Cmp.NavLink, {to: "BrokerList"}, "Brokers"), 
                    React.createElement(Cmp.NavLink, {to: "BrokerDetails", params: { id: model.id}}, name), 
                    React.createElement(Cmp.NavLink, {to: currentRoute, params: { id: model.id}}, model.id ? 'Edit' : 'New')
                )
            );
        } else {
            nav = (
                React.createElement("ul", {className: "pull-right breadcrumb"}, 
                    React.createElement(Cmp.NavLink, {to: "BrokerList"}, "Brokers"), 
                    React.createElement(Cmp.NavLink, {to: currentRoute, params: { id: model.id}}, model.id ? 'Edit' : 'New')
                )
            );
        }

         return (
             React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: 'NCH - ' + title}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Brokers"), 
                        nav
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement("div", {className: "row half-gutter"}, 
                        React.createElement("div", {className: "col-md-3 md-margin-bottom-40"}, 
                            React.createElement(SidebarNav, null)
                        ), 
                        React.createElement("div", {className: "col-md-9"}, 
                            React.createElement(Validation.Form, {ref: "form", model: props.model, className: "form-horizontal", onSubmit: this.onSaveClick, disableValidation: isAdmin}, 
                                UserAddEdit(this, model, 'Broker', isAdmin), 
                                React.createElement(Cmp.ValidationMessage, null), 
                                React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                                    React.createElement(Cmp.Button.Save, null), 
                                    React.createElement(Cmp.Button.Cancel, {onClick: this.onCancelClick})
                                )
                            )
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    },

    onSaveClick: function() {
        this.props.model.save(this.refs.form.getValues());
    },

    onCancelClick: function() {
        this.transitionTo('BrokerList');
    }
});
module.exports = BrokerAddEdit;
