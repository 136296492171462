"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    cx = require('classnames'),
    _ = require('underscore'),
    $ = require('jquery'),
    Constants = require('../../constants'),
    ProjectDetail = require('./ProjectDetail'),
    URI = require('urijs');

var ProjectPopup = React.createClass({displayName: "ProjectPopup",
    getDefaultProps: function() {
        return {
            onClose: undefined,
            model: undefined,
            agent: undefined,
            widget: undefined,
            show: false,
            showSelect: false,
            onSelect: undefined
        };
    },

    getInitialState: function() {
        return {
            trackerId: (new Date()).getTime()
        };
    },

    componentWillReceiveProps: function(props) {
        if (props.show && props.show !== this.props.show) {
            this.setState({
                trackerId: (new Date()).getTime()
            });
        }
    },

    componentDidUpdate: function() {
        if (this.props.show) {
            $('html').addClass('modal-open');
        } else {
            $('html').removeClass('modal-open');
        }
    },

    render: function() {
        var me = this,
            props = me.props,
            show = props.show,
            className = cx('modal-container project-popup', props.className);

        return (
           React.createElement("div", React.__spread({className: className},  _.omit(props, 'className', 'model', 'agent', 'showAgentDetails', 'showSelect', 'onSelect')), 
                React.createElement(BS.Modal, {show: show, bsSize: "large", onHide: this.close, container: this, "aria-labelledby": "contained-modal-title"}, 
                    React.createElement(BS.Modal.Header, null, 
                        React.createElement("button", {className: "close", "aria-label": "Close", style: { marginTop: -2}, dangerouslySetInnerHTML: { __html: '&times;'}, onClick: this.close}), 
                        React.createElement(BS.Modal.Title, {id: "contained-modal-title"}, props.model ? props.model.get('name') : '')
                    ), 

                    React.createElement(BS.Modal.Body, null, 
                        props.model && (
                            React.createElement(ProjectDetail, {ProjectMapComponent: props.ProjectMapComponent, trackerId: this.state.trackerId, showSelect: props.showSelect, onSelect: props.onSelect, showAgentDetails: props.showAgentDetails, model: props.model, agent: props.agent, widget: props.widget})
                        )
                    ), 

                    React.createElement(BS.Modal.Footer, null, 
                        props.model && props.model.get('listingSource') === 'bdx' && (
                            React.createElement("p", {className: "listing-source pull-left"}, 
                                "Source: NewHomeSource.com"
                            )
                        ), 
                        React.createElement("div", {className: "logo pull-right"}, 
                            React.createElement("a", {href: URI(Constants.publicSite).addSearch({ source: 'amwlogo' }), target: "_blank"}, 
                                React.createElement("img", {src: Constants.imageRoot + 'NCH_Logo_PoweredBy_162x25.png'})
                            )
                        )
                    )
                )
           )
        );
    },

    close: function(e) {
        if(e && e.preventDefault) {
            e.preventDefault();
        }
        
        if (this.props.onClose) {
            this.props.onClose(e);
        }
    }
});

module.exports = ProjectPopup;
