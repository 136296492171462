"use strict";

var ModelClass = require('./Model'),
    Constants = require('../constants'),
    utility = require('../shared/Utilities'),
    $ = require('jquery');

var ProjectImage = ModelClass.extend({
    urlRoot: Constants.restPath + "/project-images",
    idAttribute: 'id',
    validation: {
        title: {
            required: true
        },
        files: function(value, attr, computedState) {
            var fileName;
            if (attr === 'files') {
                var errorMsg = 'File is required';

                if ($.isArray(value)) {
                    if (!value.length) {
                        return errorMsg;
                    }
                    var item = value[0];

                    if (!item || (!item.name && !item.link)) {
                        return errorMsg;
                    } else {
                        fileName = item.link;
                    }

                } else {
                    if (!value || !value.name) {
                        return errorMsg;
                    }
                    fileName = value.name;
                }


                if (fileName && !/\.(png)|(jpg)|(gif)$/i.test(fileName)) {
                    return 'File type is invalid. Only image file allowed: JPG, PNG, GIF.';
                }
            }
        }

    },
    defaults: function() {
        return {
            id: undefined,
            title: '',
            description: '',
            fileName: '',
            orderNumber: '',
            thumbLink: '',
            link: '',
            files: [],
            private: false
        };
    },

    toJSON: function(options) {
        var data = ModelClass.prototype.toJSON.apply(this, arguments);

        options = options || {};

        if (options.url) {
            delete data.files;
        }

        data.thumbLink && (data.thumbLink += '?nc=' + new Date().getTime());
        data.link && (data.link += '?nc=' + new Date().getTime());

        return data;
    },

    parse: function(response) {
        if (response && response.link) {
            // @TODO: clean this up. The last release added a new fileName propert that conflicts with this.
            // response.fileName = response.originalFileName || response.link.match(/[^\\/]+$/)[0];
            response.link = response.link.replace('/dev.', '/www.').replace('http://', '//').replace('//www.agentshield.com.s3.amazonaws.com', '//s3.amazonaws.com/www.agentshield.com');
            response.thumbLink = response.thumbLink ? response.thumbLink.replace('http://', '//') : undefined;

            // function(image, size, linkAttr, folderName, filenameAttr) 
            // utility.addImageLink(response, '190x110', 'thumbLink', 'Pics', 'thumbFilename');
            utility.addImageLink(response, '190x110', 'thumbLink', 'Pics', 'fileName');
            utility.addImageLink(response, '300px', 'link300px', 'Pics', 'fileName');

            response.files = [{
                link: response.link,
                name: response.fileName,
                thumbLink: response.thumbLink
            }];

            response.loaded = false;
        }

        return response;
    }
});

module.exports = ProjectImage;
