"use strict";

var React = require('react/addons'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    FloorPlanModel = require('../../models/FloorPlan'),
    Cmp = require('../../components'),
    ReactRouter = require('react-router'),
    ListingModelType = require('../../mixins/ListingModelType'),
    RequiredMessage = require('../../partials/RequiredMessage'),
    Validation = require('../../partials/validation');


var ProjectFloorPlan = React.createClass({displayName: "ProjectFloorPlan",

    mixins: [
        ReactRouter.Navigation,
        ListingModelType.ListingNavigation('FloorPlan')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            id = params.floorplanid,
            projectId = this.props.model ? this.props.model.id : undefined;


        this.props.floorPlanModel.clear();

        this.props.floorPlanModel.set({
            id: id,
            listing: {
                id: projectId
            }
        });

        if (id) {
            this.props.floorPlanModel.fetch();
        }

        return {};
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        return {
            floorPlanModel: new FloorPlanModel(),
            readOnly: false
        }
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function(nextProps) {
        this.setState(this.updateState());
    },

    onSaveClick: function(e) {
        var me = this,
            filePicker = me.refs.filePicker,
            model = this.props.floorPlanModel,
            isNew = model.isNew();

        model.save(me.refs.form.getValues()).then(function(m, e, r) {
            if(isNew){
                filePicker.setIdUrlPart(model.id);
            }
            if (filePicker.hasFiles()) {
                filePicker.startUpload();
            } else {
                me.backToList();
            }
        });
    },

    render: function() {
        var props = this.props,
            defaultControlAttr = {
                type: 'text',
                labelClassName: 'label',
                trigger: 'onChange'
            },
            bedroomItems = [
                { id: 0, name : 'Studio'},
                { id: 1, name : '1'},
                { id: 2, name : '2'},
                { id: 3, name : '3'},
                { id: 4, name : '4'},
                { id: 5, name : '5+'}
            ],
            bathRoomItems = [
                { id: 0, name : '0'},
                { id: 1, name : '1'},
                { id: 2, name : '2'},
                { id: 3, name : '3'},
                { id: 4, name : '4'},
                { id: 5, name : '5+'}
            ],
            priceAttr = {
                labelClassName: 'col-xs-2',
                wrapperClassName: 'col-xs-10'
            };

        return (
            React.createElement(Validation.Form, {ref: "form", horizontal: false, model: props.floorPlanModel, onSubmit: this.onSaveClick, readOnly: props.readOnly}, 
                React.createElement("h1", null, props.floorPlanModel.id ? 'Edit' : 'Add', " Listing Floor Plan"), 
                React.createElement("h5", null, "Use the form to add/edit the floor plan. Both agents and buyers will have access to this floor plan."), 
                React.createElement(RequiredMessage, null), 

                React.createElement(Validation.Input, React.__spread({name: "floorPlanName", label: "Floor Plan Name:"},  defaultControlAttr, {placeholder: "Enter a Name for this Floor Plan."})), 
                React.createElement(Validation.Input, React.__spread({name: "description", label: "Description:"},  defaultControlAttr, {type: "textarea", placeholder: "Provide a description for this Floor Plan.", className: "floor-plan-description"})), 

                React.createElement(Validation.Input, React.__spread({},  defaultControlAttr, {type: "", label: "Price Range:"}), 
                    React.createElement(BS.Row, null, 
                        React.createElement(BS.Col, {md: 6}, 
                            React.createElement(Validation.Input, React.__spread({name: "startingPrice", label: "From:"},  defaultControlAttr,  priceAttr, {placeholder: "Starting Price"}))
                        ), 
                        React.createElement(BS.Col, {md: 6}, 
                            React.createElement(Validation.Input, React.__spread({name: "endingPrice", label: "To:"},  defaultControlAttr,  priceAttr, {placeholder: "Max Price"}))
                        )
                    )
                ), 
                React.createElement(Cmp.RadioGroup, React.__spread({valueAsInteger: true},  defaultControlAttr, {label: "Bedrooms:", name: "bedrooms", type: "radio", items: bedroomItems, className: "bedrooms"})), 
                React.createElement(Cmp.RadioGroup, React.__spread({valueAsInteger: true},  defaultControlAttr, {label: "Full Bathrooms:", name: "fullBaths", type: "radio", items: bathRoomItems})), 
                React.createElement(Cmp.RadioGroup, React.__spread({valueAsInteger: true},  defaultControlAttr, {label: "Half Bathrooms:", name: "halfBaths", type: "radio", items: bathRoomItems})), 

                React.createElement(Validation.Input, React.__spread({},  defaultControlAttr, {name: "livingAreaSqft", label: "Living Area (SqFt):"},  defaultControlAttr, {placeholder: "Enter the size of the Living Area."})), 
                React.createElement(Validation.Input, React.__spread({},  defaultControlAttr, {name: "totalAreaSqft", label: "Total Area (SqFt):"},  defaultControlAttr, {placeholder: "Enter the total size of the Floor Plan."})), 

                React.createElement(Cmp.Picker.File, {ref: "filePicker", name: "files", url: props.floorPlanModel.url(), label: "Floorplan File:", onComplete: this.onFileUpload, multiple: true}), 
                
                React.createElement(Cmp.ValidationMessage, null), 
                React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                    React.createElement(Cmp.Button.Cancel, {onClick: this.onCancelClick}), 
                    React.createElement(Cmp.Button.Save, {className: "pull-right"})
                )
                
            )
        );
    },

    onFileUpload: function() {
        this.backToList();
    },

    onCancelClick: function() {
        this.backToList();
    },

    backToList: function() {
        this.transitionTo(this.backTo(), this.context.router.getCurrentParams());
    }
});
module.exports = ProjectFloorPlan;
