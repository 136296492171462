"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    ReactRouterBootstrap = require('react-router-bootstrap'),
    ButtonLink = ReactRouterBootstrap.ButtonLink,
    Constants = require('../../constants'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    FluxBone = require('../../mixins/FluxBone'),
    Widget = require('../../models/Widget'),
    NavLink = require('../../components/NavLink'),
    ReactCatalyst = require('../../mixins/react-catalyst'),
    fancybox = require('fancybox'),
    RouteHandler = ReactRouter.RouteHandler,
    $ = require('jquery'),
    _ = require('underscore'),
    Link = ReactRouter.Link;

var WidgetFinished = React.createClass({displayName: "WidgetFinished",

    contextTypes: {
        router: React.PropTypes.func
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.model,
            widgetType = model.get('widgetType').id,
            routes = this.context.router.getCurrentRoutes(),
            withinEditWizard = !!_.find(routes, function(route) {
                return route.name === 'WidgetEditFinished';
            }),
            back = withinEditWizard || props.create ? 'WidgetEditProjects' : 'Widgets',
            query = withinEditWizard || props.create ? { widgetType: widgetType } : undefined,
            params  = withinEditWizard || props.create ? { id: model.id } : undefined,
            title = props.title + ' - Finished';

        if(props.create) {
            title = title + ' - ' + model.getWidgetTypeName() + ' Created';
        } else {
            title = title + ' - ' + model.getWidgetTypeName() + ' Updated';
        }

        return (
            React.createElement("div", null, 
                React.createElement(DocumentTitle, {title: title}), 
                React.createElement("div", {className: "row"}, 
                    React.createElement(Link, {to: back, params: params, query: query}, React.createElement(BS.Button, {bsStyle: "link"}, React.createElement("i", {className: "fa fa-arrow-left"}), " Back"))
                ), 
                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-xs-12 col-sm-10 col-sm-offset-1"}, 
                        React.createElement("h3", {className: "text-center blue-text"}, 
                            React.createElement("i", {className: "fa fa-check-square-o fa-2x center-block"}), 
                            React.createElement("div", {className: "font-large"}, "Success! ")
                        )
                    )
                ), 
                 props.create ? (
                    React.createElement("div", null, 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-xs-12 col-sm-10 col-sm-offset-1 text-center"}, 
                                React.createElement("p", {className: "font-large"}, 
                                    "Congratulations! Your widget ", React.createElement("span", {className: "blue-text"}, model.get('name')), " has been successfully created!"
                                )
                            )
                        ), 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-xs-12 col-sm-10 col-sm-offset-1 text-center"}, 
                                React.createElement("hr", null)
                            )
                        ), 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("p", {className: "text-center font-large"}, 
                                "The final step is to add your new widget to your website. Please select one of the options below."
                            )
                        ), 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-xs-6 col-sm-4 col-sm-offset-2 text-center"}, 
                                React.createElement(Link, {to: "WidgetEditCode", params: { id: model.id}, query: { create: props.create || undefined}}, 
                                    React.createElement("i", {className: "fa fa-code fa-4x green-text"}), 
                                    React.createElement("br", null), 
                                    React.createElement("span", {className: "green-text"}, "Add the code myself."), React.createElement("br", null), 
                                    React.createElement("span", null, "I manage my own website and know how to add the widget code myself."), React.createElement("br", null), 
                                    React.createElement("span", {className: "green-text"}, "Click Here"), React.createElement("br", null)
                                )
                            ), 
                            React.createElement("div", {className: "col-xs-6 col-sm-4 text-center"}, 
                                React.createElement(Link, {to: "WidgetEditHelp", params: { id: model.id}, query: { create: props.create || undefined}}, 
                                    React.createElement("i", {className: "fa fa-envelope-o fa-4x orange-text"}), 
                                    React.createElement("br", null), 
                                    React.createElement("span", {className: "orange-text"}, "Send code to my webmaster."), React.createElement("br", null), 
                                    "I have a webmaster that manages my website. I would like to send the code and instructions to my webmaster.", React.createElement("br", null), 
                                    React.createElement("span", {className: "orange-text"}, "Click Here")
                                )
                            )
                        )
                    ) 
                ) : (
                    React.createElement("div", null, 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-xs-12 col-sm-10 col-sm-offset-1 text-center"}, 
                                React.createElement("p", {className: "font-large"}, 
                                    "Congratulations! Your widget ", React.createElement("span", {className: "blue-text"}, model.get('name')), " has been successfully updated!", 
                                    React.createElement("br", null), 
                                    "If you already added the widget code to your website you're all done."
                                ), 
                                React.createElement(ButtonLink, {className: "btn-u btn-u-lg btn-u-blue btn-u-inverted btn-u-narrow", to: "Widgets"}, "Done")
                            )
                        ), 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-xs-12 col-sm-10 col-sm-offset-1 text-center"}, 
                                React.createElement("hr", null)
                            )
                        ), 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("p", {className: "text-center"}, "Do you need to add your widget to your website again?")
                        ), 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col-xs-6 col-sm-4 col-sm-offset-2 text-center"}, 
                                React.createElement("i", {className: "fa fa-code fa-2x green-text"}), 
                                React.createElement("br", null), 
                                React.createElement("p", null, 
                                    "Add the widget myself.", React.createElement("br", null), 
                                    React.createElement(Link, {to: "WidgetEditCode", params: { id: model.id}}, "[Click Here]")
                                )
                            ), 
                            React.createElement("div", {className: "col-xs-6 col-sm-4 text-center"}, 
                                React.createElement("i", {className: "fa fa-envelope-o fa-2x orange-text"}), 
                                React.createElement("br", null), 
                                React.createElement("p", null, 
                                    "Get help from my webmaster.", React.createElement("br", null), 
                                    React.createElement(Link, {to: "WidgetEditHelp", params: { id: model.id}}, "[Click Here]")
                                )
                            )
                        )
                    )
                )
            )
        );
    },

    getWidgetInsertionCode: function(){
        var code =  "<div id=\"AgentShield_MyDevelopments_$WID$\"></div>\r\n" + 
                    "<script src=\""+Constants.widgetHost+"/bootstrap.js?wid=$WID$\"></script>";

        return code.replace(/(\$WID\$)/g, this.props.model.id);
    },

    getWidgetEmbedInsertionCode: function(){
        var code =  "<embed src=\""+Constants.widgetHost+'?wid=$WID$'+"\" width=\"100%\" height=\"800\"></embed>";

        return code.replace(/(\$WID\$)/g, this.props.model.id);
    }
});
module.exports = WidgetFinished;
