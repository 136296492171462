"use strict";

var React = require('react'),
    URI = require('urijs'),
    Constants = require('../constants');

var Tracker = React.createClass({displayName: "Tracker",

    getDefaultProps: function() {
        return {
            timestamp: (new Date()).getTime(),
            params: {}
        };
    },

    render: function() {
        var props = this.props;

        return Constants.trackingPath ? (
            React.createElement("img", {src: this.getUrl(), className: "as-t"})
        ) : null;
    },

    getUrl: function() {
        var url = URI(Constants.trackingPath).addSearch(this.props.params).addSearch({
            timestamp: this.props.timestamp
        });
        return url;
    }
});

module.exports = Tracker;
