"use strict";

var React = require('react/addons'),
    Router = require('react-router'),
    Cmp = require('../../components'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    ListingModelType = require('../../mixins/ListingModelType'),
    Promotion = require('../../models/Promotion'),    
    RequiredMessage = require('../../partials/RequiredMessage'),
    Validation = require('../../partials/validation');

var ProjectPromotion = React.createClass({displayName: "ProjectPromotion",

    TYPES: [{
        id: 1,
        name: 'Buyers Promotions'
    }, {
        id: 2,
        name: 'Real Estate Professional Promotions'
    }, {
        id: 3,
        name: 'Fly and Buy Promotions'
    }],

    IS_ACTIVE: [{
        id: true,
        name: 'Yes'
    }, {
        id: false,
        name: 'No'
    }],

    mixins: [
        Router.Navigation,
        ListingModelType.ListingNavigation('Promotion')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            id = params.promotionId,
            projectId = this.props.model.id;

        this.props.promotion.clear();

        this.props.promotion.set({
            id: id,
            listing: {
                id: projectId
            }
        });

        if (id) {
            this.props.promotion.fetch();
        }

        return {};
    },

    getDefaultProps: function() {
        return {
            promotion: new Promotion(),
            readOnly: false
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    onSaveClick: function(e) {
        var me = this,
            model = me.props.promotion,
            filePicker = me.refs.filePicker,
            isNew = model.isNew();

        model.save(me.refs.form.getValues()).then(function(m, e, r) {
            if(isNew){
                filePicker.setIdUrlPart(model.id);
            }
            if (filePicker.hasFiles()) {
                filePicker.startUpload();
            } else {
                me.backToList();
            }
        });
    },

    render: function() {
        var me = this,
            props = me.props,
            fieldDefaults = {
                labelClassName: 'label',
                type: 'text',
                trigger: 'onChange'
            };

        return (
            React.createElement(Validation.Form, {ref: "form", horizontal: false, model: props.promotion, onSubmit: this.onSaveClick, readOnly: props.readOnly}, 
                React.createElement("h1", null, props.promotion.id ? 'Edit' : 'Add', " Listing Promotion"), 
                React.createElement("h5", null, "Fill in the information about your promotion."), 
                React.createElement(RequiredMessage, null), 

                React.createElement(Cmp.RadioGroup, React.__spread({},  fieldDefaults, {label: "Promotion Type:", type: "radio", name: "type.id", items: this.TYPES})), 
                React.createElement(Cmp.RadioGroup, React.__spread({},  fieldDefaults, {type: "radio", name: "active", label: "Activate Promotion:", items: this.IS_ACTIVE})), 
                React.createElement(Validation.Input, React.__spread({},  fieldDefaults, {label: "Promotion Name / Title:", placeholder: "Enter a Name for this Promotion.", name: "name"})), 
                React.createElement(Validation.Input, React.__spread({},  fieldDefaults, {type: "textarea", rows: "3", label: "Promotion Description:", placeholder: "Provide a description for this Promotion.", name: "description"})), 
                React.createElement(Validation.Input, React.__spread({},  fieldDefaults, {type: "", label: "Maximum Promotion Value:"}), 
                    React.createElement(Validation.Input, React.__spread({},  fieldDefaults, {groupClassName: "col-xs-12", wrapperClassName: "row", placeholder: "Enter a Maximum Promotion Value.", name: "value"}))
                    /*<Validation.Input {...fieldDefaults} type="select" groupClassName="col-xs-2 col-xs-offset-1" wrapperClassName="row" name="currency">
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                        <option value="JPY">JPY</option>
                    </Validation.Input>*/
                ), 

                React.createElement("div", {className: "row"}, 
                    React.createElement(BS.Col, {xs: 6, sm: 5}, 
                        React.createElement("label", {className: "label margin-bottom-0 margin-top-5"}, "Duration: Does this promotion have specific dates?")
                    ), 
                    React.createElement(BS.Col, {xs: 6, sm: 7}, 
                        React.createElement(Cmp.RadioGroup, React.__spread({hideLabel: true},  fieldDefaults, {type: "radio", name: "hasDates", items: this.IS_ACTIVE}))
                    )
                ), 
                        
                React.createElement("div", {className: "form-group"}, 
                    React.createElement("section", null, 
                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: "col col-1"}, React.createElement("label", {className: "label"}, "Start:")), 
                            React.createElement("div", {className: "col col-5"}, 
                                React.createElement(Cmp.Picker.Date, React.__spread({},  fieldDefaults, {placeholder: "Starting date", trigger: "onChange", name: "startDate", onChange: this.validateState, formLinks: { disabled: '!model.hasDates'}}))
                            ), 
                            React.createElement("div", {className: "col col-1"}, React.createElement("label", {className: "label"}, "End:")), 
                            React.createElement("div", {className: "col col-5"}, 
                                React.createElement(Cmp.Picker.Date, React.__spread({},  fieldDefaults, {placeholder: "Expiration date", trigger: "onChange", name: "endDate", onChange: this.validateState, formLinks: { disabled: '!model.hasDates'}}))
                            )
                        )
                    )
                ), 

                React.createElement(Cmp.Picker.File, {ref: "filePicker", name: "files", url: props.promotion.url(), label: "Promotion File", onComplete: this.onFileUpload}), 

                React.createElement(Cmp.ValidationMessage, null), 
                React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                    React.createElement(Cmp.Button.Cancel, {onClick: this.onCancelClick}), 
                    React.createElement(Cmp.Button.Save, {className: "pull-right"})
                )
            )
        );
    },

    validateState: function(){
        this.refs.form.validateState(true);
    },

    onFileUpload: function(){
        this.backToList();
    },

    onCancelClick: function(){
        this.backToList();
    },

    backToList: function(){
        this.transitionTo(this.backTo(), this.context.router.getCurrentParams());
    }
});
module.exports = ProjectPromotion;
