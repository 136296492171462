"use strict";

var ModelClass = require('./Model'),
    User = require('./User');

var UserReset = ModelClass.extend({
    idAttribute: 'id',
    validation: {
        password: {
            required: true,
            equalTo: 'passwordConfirm'
        },
        passwordConfirm: {
            required: true,
            equalTo: 'password'
        },
        token: {
            required: true
        }
    },
    validationUses: {
        password: ['passwordConfirm'],
        passwordConfirm: ['password']
    },
    defaults: function() {
        return {
            password: '',
            passwordConfirm: '',
            token: ''
        };
    },
    fetch: function() {},
    save: function() {},
    sync: function() {},

    updatePassword: function(attrs, options) {
        var me = this;

        attrs = attrs || {};
        this.set(attrs);

        return User.updatePassword({
            password: attrs.password,
            token: attrs.token
        }).fail(function(response) {
            // so the login model can listen in on the errors
            me.trigger('error', me, response);
        });
    }
});

module.exports = UserReset;
