"use strict";

var React = require('react'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    Link = ReactRouter.Link,
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    Constants = require('../../constants'),
    Cmp = require('../../components'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    NavLink = require('../../components/NavLink'),
    SidebarNav = require('../../partials/SidebarNav'),
    Roles = require('../../mixins/Roles'),

    NewUserAccountDetails = require('./NewUserAccountDetails'),
    NewUserAccountStats = require('./NewUserAccountStats'),
    NumberOfActiveListings = require('./NumberOfActiveListings'),
    NumberOfWidgets = require('./NumberOfWidgets'),
    ProjectReportsDashboard = require('../project/report/ProjectReportsDashboard');

var Report = React.createClass({displayName: "Report",

    mixins: [
        ReactRouter.Navigation,
        Roles
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {

        return {
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        return {
        };
    },
    /**
     * This is to ensure that when subroutes transition they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        var props = this.props,
            roles = Constants.roles;

        return (
             React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: "NCH - Reports"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Reports")
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement("div", {className: "row half-gutter"}, 
                        React.createElement("div", {className: "col-md-3 md-margin-bottom-40"}, 
                            React.createElement(SidebarNav, null)
                        ), 
                        React.createElement("div", {className: "col-md-9"}, 
                            this.hasOneRole(roles.ADMIN) && React.createElement(NewUserAccountDetails, null), 
                            this.hasOneRole(roles.ADMIN) && React.createElement(NewUserAccountStats, null), 
                            this.hasOneRole(roles.ADMIN) && React.createElement(NumberOfActiveListings, null), 
                            this.hasOneRole(roles.ADMIN) && React.createElement(NumberOfWidgets, null), 
                            this.hasOneRole(roles.ADMIN, roles.DEVELOPER, roles.BUILDER) && React.createElement(ProjectReportsDashboard, null)
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    }
});

module.exports = Report;