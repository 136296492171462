"use strict";

var React = require('react'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    ReactRouterBootstrap = require('react-router-bootstrap'),
    ButtonLink = ReactRouterBootstrap.ButtonLink,
    DashboardItem = require('../../partials/DashboardItem'),

    CurrentUser = require('../../shared/CurrentUser');

var DeveloperDashboard = React.createClass({displayName: "DeveloperDashboard",

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        return {};
    },

    getInitialState: function() {
        return this.updateState();
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        return (
            React.createElement("div", null, 
                React.createElement(DocumentTitle, {title: "NCH - Developer Dashboard"}), 
                React.createElement("h1", null, "What would you like to do today?"), 
                React.createElement("div", {className: "row"}, 
                    React.createElement(DashboardItem, {blue: true, iconClass: "fa-th-large", heading: "View My Listings", message: "Access your listings", to: "ProjectList"}), 
                    React.createElement(DashboardItem, {orange: true, iconClass: "fa-line-chart", heading: "View Reports", message: "Access the reports for your listings.", to: "Reports"})
                )
            )
        );
    }
});
module.exports = DeveloperDashboard;
