"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    FluxBone = require('../../mixins/FluxBone'),
    Widget = require('../../models/Widget'),
    NavLink = require('../../components/NavLink'),
    ReactCatalyst = require('../../mixins/react-catalyst'),
    RouteHandler = ReactRouter.RouteHandler;

var WidgetAddEdit = React.createClass({displayName: "WidgetAddEdit",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            query = this.context.router.getCurrentQuery(),
            routes = this.context.router.getCurrentRoutes(),
            id = params.id ? parseInt(params.id) : undefined,
            widgetType = query.widgetType ? parseInt(query.widgetType) : undefined,
            model = this.props.model;


        if (routes && routes.length > 0 && routes[routes.length - 1].name === (id ? 'WidgetEditType' : 'WidgetAddType') || model.id !== id || !widgetType) {
            model.clear();
        }

        if (widgetType) {
            model.set({
                widgetType: {
                    id: widgetType
                },
                name: model.get('name') || this.getDefaultNameForType(widgetType)
            });
        }

        if (id && model.id !== id) {
            model.set('id', id);
            model.fetch();

            if(widgetType !== 7){
                model.listingsCollection.fetch({
                    fetchAll: true,
                    data: {
                        widgetId: model.id,
                        showToAgents: true
                    }
                });
            }
        }

        return {
            id: id,
            create: this.context.router.getCurrentQuery().create == 'true'
        };
    },

    getDefaultNameForType: function(widgetType){
        var name;
        if(widgetType === 7){
            name = 'Default Automatic Map Widget';
        } else {
            name = 'New Developments';
        }

        return name;
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        return {
            model: new Widget()
        };
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            title = state.id && !state.create ? 'Edit Widget: ' + props.model.get('name') : 'Create Widget',
            linkParam = {
                id: state.id
            };

        return (
             React.createElement("div", {className: "wrapper widgets"}, 
                React.createElement(DocumentTitle, {title: 'NCH - ' + title}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, title), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(NavLink, {to: "Widgets"}, "Widgets"), 
                            React.createElement(NavLink, {active: true}, state.id ? props.model.get('name') : 'New Widget')
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement(RouteHandler, {model: props.model, create: state.create, title: 'NCH - ' + title})
                ), 
                React.createElement(PageFooter, null)
            )
        );
    }
});
module.exports = WidgetAddEdit;
