"use strict";

var React = require("react"),
  cx = require("classnames"),
  ReactRouter = require("react-router"),
  Constants = require("../constants"),
  Link = ReactRouter.Link;

var PageFooter = React.createClass({displayName: "PageFooter",
  getDefaultProps: function() {
    return {
      isApp: true
    };
  },
  render: function() {
    return (
      React.createElement("div", {className: "footer-default sticky-footer"}, 
        React.createElement("div", {className: "footer"}, 
          React.createElement("div", {className: "container"})
        ), 
        React.createElement("div", {className: "copyright"}, 
          React.createElement("div", {className: "container"}, 
            React.createElement("div", {className: "row"}, 
              React.createElement("div", {className: "col-md-6 no-space text"}, 
                React.createElement("p", null, 
                  new Date().getFullYear(), " © Agent Shield Inc. All Rights" + ' ' +
                  "Reserved."
                )
              ), 
              React.createElement("div", {className: "col-md-6 no-space logo"}, 
                this.props.isApp ? (
                  React.createElement(Link, {to: "Dashboard", className: "pull-right"}, 
                    React.createElement("img", {
                      src: Constants.imageRoot + "NCH_Logo_200x46-power-by.png"}
                    )
                  )
                ) : (
                  React.createElement("a", {href: "#", className: "pull-right"}, 
                    React.createElement("img", {
                      src: Constants.imageRoot + "NCH_Logo_200x46-power-by.png"}
                    )
                  )
                )
              )
            )
          )
        )
      )
    );
  }
});
module.exports = PageFooter;
