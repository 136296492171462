"use strict";
var $ = require('jquery');

module.exports = {
    openSupport: function(e) {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        if (window.Intercom) {
            window.Intercom('show');
        }

        if(window.FreshWidget && $(FreshWidget.iframe()).is(':visible')) {
            FreshWidget.hide();
        } else if(window.FreshWidget) {
            FreshWidget.show();
            $(FreshWidget.iframe()).attr('scrolling', 'no');
        }
    }

};
