"use strict";

var React = require('react/addons'),
    Constants = require('../../../constants'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    FluxBone = require('../../../mixins/FluxBone'),
    OpenSupport = require('../../../mixins/OpenSupport'),
    BS = require('react-bootstrap'),
    ReactRouter = require('react-router'),
    $ = require('jquery'),
    Validation = require('../../../partials/validation'),
    RequiredMessage = require('../../../partials/RequiredMessage'),
    Cmp = require('../../../components'),
    Link = ReactRouter.Link;

var UserContact = React.createClass({displayName: "UserContact",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset'),
        ReactRouter.Navigation,
        OpenSupport
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function(){
        return {
            saveText: undefined,
            onSave: undefined,
            blockBack: undefined
        };
    },

    getInitialState: function() {
        var entity = this.props.model.getEntity();
        entity.fetch();
        return {
            entity: entity
        };
    },

    renderUserFields : function(){
        var model = this.props.model,
            type = model.getType();

        switch(type){
            case 'Broker':
            case 'Agent':
                return this.renderAgentFields();
            break;
            case 'Developer':
            case 'Builder':
                return this.renderBuilderFields();
            break;
        }

        return (React.createElement("div", null, "You account does not have contact info"));
    },
    renderAgentFields : function(){
        var defaultControlAttr = {
                type: 'text',
                labelClassName: 'col-md-5',
                wrapperClassName: 'col-md-7'
            };
        return  (
            React.createElement("div", null, 
                React.createElement("div", {className: "col-xs-12"}, 
                    React.createElement(Validation.Input, React.__spread({name: "brokerageName", label: "Brokerage Name:"},  defaultControlAttr)), 
                    React.createElement(Validation.Input, React.__spread({name: "teamName", label: "Your Team Name:"},  defaultControlAttr)), 
                    React.createElement(Validation.Input, React.__spread({name: "contactInfo.firstName", label: "First Name:"},  defaultControlAttr)), 
                    React.createElement(Validation.Input, React.__spread({name: "contactInfo.lastName", label: "Last Name:"},  defaultControlAttr)), 

                    React.createElement(Validation.Input, React.__spread({name: "website", label: "Your Website:"},  defaultControlAttr)), 

                    React.createElement(Validation.Input, React.__spread({name: "contactInfo.phoneWork", label: "Phone:"},  defaultControlAttr)), 
                    this.renderAddress({ addressLabel: 'Your Address:' }), 
                    React.createElement(Validation.Input, React.__spread({name: "nameToDisplay", label: "Display Name:", help: "How would you like your contact information displayed to the public?"},  defaultControlAttr, {type: "select"}), 
                        React.createElement("option", {value: "FirstAndLast"}, "Display my name and brokerage only"), 
                        React.createElement("option", {value: "Brokerage"}, "Display my brokerage name only"), 
                        React.createElement("option", {value: "TeamName"}, "Display my team name and brokerage only")
                    )
                )
            )
        );
    },
    renderBuilderFields : function(){
        var defaultControlAttr = {
                type: 'text',
                labelClassName: 'col-md-5',
                wrapperClassName: 'col-md-7'
            };
        return (
            React.createElement("div", null, 
                React.createElement("div", {className: "col-xs-12"}, 
                    React.createElement(Validation.Input, React.__spread({name: "realName", label: "Business Name:"},  defaultControlAttr)), 
                    React.createElement(Validation.Input, React.__spread({name: "contactInfo.firstName", label: "First Name:"},  defaultControlAttr)), 
                    React.createElement(Validation.Input, React.__spread({name: "contactInfo.lastName", label: "Last Name:"},  defaultControlAttr)), 
                    React.createElement(Validation.Input, React.__spread({name: "website", label: "Website:"},  defaultControlAttr)), 

                    React.createElement(Validation.Input, React.__spread({name: "contactInfo.phoneWork", label: "Phone:"},  defaultControlAttr)), 
                    this.renderAddress()
                )
            )
        );

    },
    renderAddress : function(options){
        var defaultControlAttr = {
                type: 'text',
                labelClassName: 'col-md-5',
                wrapperClassName: 'col-md-7'
            };
        options = options || {};
        return (
            React.createElement("div", null, 
                React.createElement(Cmp.LocationSelect, React.__spread({ref: "contactInfo", name: "contactInfo", label: options.addressLabel || 'Address:'},  defaultControlAttr)), 
                React.createElement(Validation.Input, React.__spread({name: "contactInfo.address2", label: "Unit / Suite:"},  defaultControlAttr))
                /*
                <Validation.Input ref="city" name="contactInfo.city.name" readOnly label="City / Town:" {...defaultControlAttr}/>
                <Validation.Input ref="state" name="contactInfo.city.state.name" readOnly label="State / Region:" {...defaultControlAttr}/>
                <Validation.Input ref="country" name="contactInfo.city.country.name" readOnly label="Country:" {...defaultControlAttr}/>
                <Validation.Input name="contactInfo.zip" label="Zip:" {...defaultControlAttr}/>
                */
            )
        );

    },


    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            defaultControlAttr = {
                type: 'text',
                labelClassName: 'col-md-5',
                wrapperClassName: 'col-md-7'
            },
            isReadOnly = props.model.getEntity() ? props.model.getEntity().isReadOnly() : false;


        return (
                React.createElement(Validation.Form, {readOnly: isReadOnly, ref: "form", model: me.state.entity, onSubmit: this.onSaveClick}, 
                    isReadOnly && (
                        React.createElement(BS.Alert, {bsStyle: "danger"}, 
                            "This form is a ", React.createElement("b", null, "READ ONLY"), ". Please ", React.createElement("a", {href: "#", onClick: this.openSupport}, "CLICK HERE"), " to contact us with questions or to request changes."
                        )
                    ), 

                    !this.props.hideHeader && (
                        React.createElement("div", null, 
                            React.createElement("h1", null, "My Contact Info"), 
                            React.createElement(RequiredMessage, {className: "margin-top-10"})
                        )
                    ), 
                    React.createElement("div", {className: "row"}, 
                        this.renderUserFields()
                    ), 
                    React.createElement(Cmp.ValidationMessage, {action: props.isInWizard ? 'Next' : undefined}), 
                    React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                        !this.props.blockBack && (
                            React.createElement(Cmp.Button.Cancel, {to: "UserAccountDashboard"})
                        ), 
                        React.createElement(Cmp.Button.Save, {className: "pull-right", label: props.saveText})
                    )
                )
        );
    },
    onSaveClick: function(e) {
        var me = this,
            props = this.props,
            state = this.state;

        state.entity.save(me.refs.form.getValues(), {
            fields: ['id', 'contactInfo', 'brokerageName', 'teamName', 'website', 'nameToDisplay', 'realName']
        }).then(function(){
            me.props.model.fetch();

            if(props.onSave){
                props.onSave();
            }
        });
    }
});
module.exports = UserContact;
