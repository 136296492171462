"use strict";

var ModelClass = require('./Model'),
    FileNameUtils = require('../shared/FileNameUtils'),
    Constants = require('../constants'),
    $ = require('jquery');

var ProjectDocument = ModelClass.extend({
    urlRoot: Constants.restPath + "/project-documents",
    idAttribute: 'id',
    validation: {
        title: {
            required: true
        },
        files: function(value, attr, computedState) {
            if (attr === 'files') {
                var errorMsg = 'File is required';

                if ($.isArray(value)) {
                    if (!value.length) {
                        return errorMsg;
                    }
                    var item = value[0];

                    if (!item || (!item.name && !item.link)) {
                        return errorMsg;
                    }
                } else {
                    if (!value || !value.name) {
                        return errorMsg;
                    } 
                }
            }
        }
    },
    defaults: function() {
        return {
            id: undefined,
            title: '',
            fileName: 'Current File',
            category: 'Policy',
            description: '',
            lastUpdatedDate: null,
            link: '',
            listing: {},
            files: []
        };
    },
    getFileNameIcon: function() {
        return FileNameUtils.fileNameToFaIcon(this.get('link'));
    },

    toJSON: function(options) {
        var data = ModelClass.prototype.toJSON.apply(this, arguments);

        options = options || {};

        if (options.url) {
            delete data.files;
        }

        return data;
    },

    parse: function(response) {
        if (response && response.link) {
            response.fileName = response.originalFileName || response.link.match(/[^\\/]+$/)[0];
            response.files = [{
                link: response.link,
                name: response.fileName
            }];
        }

        return response;
    }
});

module.exports = ProjectDocument;
