"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    MetroArea = require('../models/MetroArea');

var MetroAreas = Collection.extend({
    url: function() {
        var url = Constants.restPath;

        if (this.hasProjects) {
            url = url + '/projects';
        }

        url = url + '/metroAreas';

        return url;
    },
    model: MetroArea,
    pageSize: null,
    hasProjects: false,

    stateId: undefined,

    fetch: function(options) {
        options = options || {};
        options.data = options.data || {};

        if (options.stateId !== undefined) {
            this.stateId = options.stateId;
        }

        if (this.stateId && this.stateId !== 'NONE') {
            options.data.stateId = this.stateId;
        }

        if (options.metroId !== undefined) {
            this.metroId = options.metroId;
        }

        if (this.metroId && this.metroId !== 'NONE') {
            options.data.metroId = this.metroId;
        }

        if (options.hasProjects !== undefined) {
            this.hasProjects = options.hasProjects;
        }

        return Collection.prototype.fetch.call(this, options);
    }
});

module.exports = MetroAreas;
