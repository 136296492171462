"use strict";

var React = require('react'),
    _ = require('underscore'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    Link = ReactRouter.Link,
    cx = require('classnames'),
    Constants = require('../../../constants'),
    Cmp = require('../../../components'),
    FluxBone = require('../../../mixins/FluxBone'),
    ReportModel = require('../../../models/reports/ListingAgentDashboard'),
    Validation = require('../../../partials/validation'),
    numeral = require('numeral'),
    Pagination = require('../../../partials/Pagination');


var ProjectReportDashboard = React.createClass({displayName: "ProjectReportDashboard",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset'),
        FluxBone.ModelMixin('report', 'sync'),
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function(props) {
        var query = this.context.router.getCurrentQuery(),
            params = this.context.router.getCurrentParams(),
            startDate,
            endDate,
            listingId,
            defaults,
            page,
            state = this.state || {};

        props = props || this.props;

        props.report.project = props.model;

        defaults = props.report.defaults();
        startDate = query.startDate || defaults.startDate;
        endDate = query.endDate || defaults.endDate;
        page = query.page || 1;
        listingId = params.id || props.model.id || props.report.get('listingId');

        if (state.startDate !== startDate || state.endDate !== endDate || state.listingId !== listingId || page !== state.page) {
            props.report.clear();
            props.report.pageNum = page;
            props.report.set({
                startDate: startDate,
                endDate: endDate,
                listingId: listingId
            });

            this.props.report.fetch();
        }

        return {
            startDate: startDate,
            endDate: endDate,
            listingId: listingId,
            page: page
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        return {
            report: new ReportModel()
        };
    },
    /**
     * This is to ensure that when subroutes transition they trigger recheck router state and render
     */
    componentWillReceiveProps: function(props) {
        this.setState(this.updateState(props));
    },

    shouldBeDisabled: function(props){
        props = props || this.props;
        return props.model.get('serviceTypes').length === 0; 
    },

    render: function() {
        var props = this.props,
            state = this.state,
            reportData = props.report.toJSON({
                includeAgents: true
            }),
            aggregateStats = reportData.aggregateStats,
            query = {
                startDate: state.startDate,
                endDate: state.endDate
            },
            currentRoutes = this.context.router.getCurrentRoutes(),
            currentRoute = currentRoutes[currentRoutes.length - 1],
            params = currentRoute.name === 'BuilderProjectReportAgentDashboard' ? undefined : {
                id: props.model.id
            },
            isDisabled = this.shouldBeDisabled();

        return (
            React.createElement("div", null, 
                React.createElement("h1", null, "Report: ", props.model.get('name'), ": Agents"), 
                React.createElement("h5", {className: "margin-bottom-20"}, 
                    "There are ", React.createElement("span", {className: "blue-text"}, reportData.total || 0), " agents promoting ", props.model.get('name'), "."
                ), 
                isDisabled && !props.model.isLoading() && props.model.isLoaded() && (
                    React.createElement("div", null, 
                        React.createElement(BS.Row, null, 
                            React.createElement(BS.Col, {xs: 12}, 
                                React.createElement("p", {className: "green-text"}, 
                                    React.createElement("i", {className: "fa fa-exclamation-triangle"}), " Upgrade to access this full report. ", React.createElement(Link, {to: "UserUpgradeAndBilling", params: { id: props.model.id}}, "Click Here"), " to upgrade."
                                )
                            )
                        ), 
                        React.createElement(BS.Row, null, 
                            React.createElement(BS.Col, {xs: 12}, 
                                React.createElement("h3", null, "Gain insight on which agents are actively and effectively promoting you."), 
                                React.createElement("h3", {className: "green-header"}, "Agent Reports Include:")
                            )
                        ), 
                        React.createElement(BS.Row, null, 
                            React.createElement(BS.Col, {xs: 6}, 
                                React.createElement("ul", {className: "check-list"}, 
                                    React.createElement("li", null, "Agent Name"), 
                                    React.createElement("li", null, "Agent Brokerage"), 
                                    React.createElement("li", null, "Agent Location"), 
                                    React.createElement("li", null, "Agent Performance"), 
                                    React.createElement("li", null, "Download Reports")
                                )
                            ), 
                            React.createElement(BS.Col, {xs: 6}, 
                                React.createElement("img", {className: "img-responsive", src: Constants.imageRoot + 'reports/sample_agents_report.png'})
                            )
                        ), 
                        React.createElement(BS.Row, null, 
                            React.createElement(BS.Col, {xs: 12}, 
                                React.createElement("p", {className: "green-text"}, 
                                    React.createElement("i", {className: "fa fa-exclamation-triangle"}), " Upgrade to access this full report. ", React.createElement(Link, {to: "UserUpgradeAndBilling", params: { id: props.model.id}}, "Click Here"), " to upgrade."
                                )
                            )
                        )
                    )
                ), 
                !isDisabled && !props.model.isLoading() && props.model.isLoaded() && (
                    React.createElement("div", null, 
                        React.createElement("div", {className: "margin-bottom-20"}, 
                            React.createElement(Validation.Form, {ref: "form", model: props.report, onSubmit: this.onSaveClick}, 
                                React.createElement(BS.Row, null, 
                                    React.createElement(BS.Col, {sm: 6, xs: 12}, 
                                        React.createElement(Cmp.Picker.Date, {labelClassName: "col-xs-5", wrapperClassName: "col-xs-7", label: "Filter by Date:", placeholder: "Start", name: "startDate"})
                                    ), 
                                    React.createElement(BS.Col, {sm: 4, xs: 12}, 
                                        React.createElement(Cmp.Picker.Date, {labelClassName: "col-xs-5 col-sm-2", wrapperClassName: "col-xs-7 col-sm-10", label: "to", placeholder: "Start", name: "endDate"})
                                    ), 
                                    React.createElement(BS.Col, {sm: 2, xs: 12}, 
                                        React.createElement(Cmp.Button.Save, null, "Go")
                                    )
                                )
                            )
                        ), 

                        aggregateStats && (
                            React.createElement("div", null, 
                                React.createElement(Pagination, {store: props.report, to: currentRoute.name, query: query, params: params, showTotal: true}, 
                                    React.createElement("div", {className: "pull-right"}, 
                                        React.createElement("div", {className: "btn-u btn-u-orange", onClick: this.onExportClick}, 
                                            React.createElement("i", {className: "fa fa-external-link fa-fw"}), " Export Agents"
                                        )
                                    )
                                ), 
                                React.createElement(BS.Table, {striped: true, bordered: true, condensed: true, hover: true, className: "report-agents-table stats-table"}, 
                                    React.createElement("thead", null, 
                                        React.createElement("tr", null, 
                                            React.createElement("th", null, "Agent"), 
                                            React.createElement("th", null, "Contact"), 
                                            React.createElement("th", {style: {"minWidth": "100px"}}, "Location"), 
                                            React.createElement("th", null, "Views"), 
                                            React.createElement("th", null, "Clicks"), 
                                            React.createElement("th", null, "Leads")
                                        )
                                    ), 
                                    React.createElement("tbody", null, 
                                    _.map(reportData.agents, function(agent){
                                        return (
                                            React.createElement("tr", {key: agent.id}, 
                                                React.createElement("td", null, 
                                                    agent.name, 
                                                    React.createElement("br", null), 
                                                    agent.brokerage
                                                ), 
                                                React.createElement("td", null, 
                                                    agent.email, 
                                                    React.createElement("br", null), 
                                                    agent.phone
                                                ), 
                                                React.createElement("td", null, 
                                                    agent.country, 
                                                    React.createElement("br", null), 
                                                    agent.state
                                                ), 
                                                React.createElement("td", null, agent.views), 
                                                React.createElement("td", null, agent.clicks), 
                                                React.createElement("td", null, agent.leads)
                                            )
                                        );
                                    })
                                    )
                                ), 
                                React.createElement(Pagination, {store: props.report, to: currentRoute.name, query: query, params: params, noMargin: true, showTotal: true})
                            )
                        )
                    )
                )
            )
        );
    },

    onExportClick: function(){
        this.onSaveClick(true);
    },

    onSaveClick: function(exportReport) {
        var props = this.props,
            data = this.refs.form.getValues(),
            currentRoutes = this.context.router.getCurrentRoutes(),
            currentRoute = currentRoutes[currentRoutes.length - 1];

        if(exportReport === true){
            props.report.fetch({
                download: true,
                data: {
                    startDate: data.startDate,
                    endDate: data.endDate,
                    listingId: props.model.id
                }
            });
        } else {
            this.transitionTo(currentRoute.name, {
                id: this.props.model.id
            }, {
                startDate: data.startDate,
                endDate: data.endDate,
                page: 1
            });
        }
    }
});

module.exports = ProjectReportDashboard;
