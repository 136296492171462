"use strict";

var React = require("react/addons"),
  ReactRouter = require("react-router"),
  DocumentTitle = require("react-document-title"),
  cx = require("classnames"),
  BS = require("react-bootstrap"),
  PageHeader = require("../../partials/PageHeader"),
  Constants = require("../../constants"),
  PageFooter = require("../../partials/PageFooter"),
  FluxBone = require("../../mixins/FluxBone"),
  Roles = require("../../mixins/Roles"),
  OpenSupport = require("../../mixins/OpenSupport"),
  ProjectModel = require("../../models/Project"),
  NavLink = require("../../components/NavLink"),
  ReactCatalyst = require("../../mixins/react-catalyst"),
  RouteHandler = ReactRouter.RouteHandler,
  $ = require("jquery"),
  CurrentUser = require("../../shared/CurrentUser"),
  CreditCard = require("../../partials/user/CreditCard"),
  Link = require("../../components/Link");

var viewDisplayNames = {
  ProjectReportDashboard: "Report Dashboard",
  ProjectReportAgentDashboard: "Agent Report",
  ProjectReportPerformanceDashboard: "Performance Report",
  ProjectDashboard: "Dashboard",
  ProjectEditDetails: "Information",
  ProjectAddDetails: "Information",
  ProjectEditDetails2: "Realtor Info",
  ProjectAddDetails2: "Realtor Info",
  ProjectEditContacts: "Contacts",
  ProjectAddContacts: "Contacts",
  ProjectEditImages: "Images",
  ProjectAddImages: "Images",
  ProjectEditFloorPlans: "Floor Plans",
  ProjectAddFloorPlans: "Floor Plans",
  ProjectEditDocuments: "Documents",
  ProjectAddDocuments: "Documents",
  ProjectEditPromotions: "Promotions",
  ProjectAddPromotions: "Promotions",
  ProjectEditAmenities: "Amenities",
  ProjectAddAmenities: "Amenities",
  ProjectPersonalizedAgentEmail: "Agent Branded Emails",
  ProjectPersonalizedAgentBrochure: "Agent Branded Brochures",
  ProjectEditAPIKey: "Agent Outreach Tools",
  ProjectAddAPIKey: "Agent Outreach Tools",
  ProjectReports: "Reports",
  UserUpgradeAndBilling: "Upgrade & Billing",
  ProjectEditVisibility: "Visibility",
  ProjectAddVisibility: "Visibility",
  ProjectEditUpgradeAndBillingInfo: "Payment Information"
};

var AddEditProject = React.createClass({displayName: "AddEditProject",
  mixins: [
    FluxBone.ModelMixin("model", "sync", 10),
    ReactCatalyst.LinkedStateMixin,
    Roles,
    OpenSupport
  ],

  contextTypes: {
    router: React.PropTypes.func
  },

  updateState: function(props) {
    props = props || this.props;

    var params = this.context.router.getCurrentParams(),
      id = params.id,
      model = props.model;

    model.clear();

    if (id) {
      model.set("id", id);
      model.fetch();
    }

    return {};
  },

  getInitialState: function() {
    return this.updateState();
  },

  getDefaultProps: function() {
    return {
      model: new ProjectModel()
    };
  },
  /**
   * This is to ensure that when subroutes transistion they trigger recheck router state and render
   */
  componentWillReceiveProps: function() {
    this.setState(this.updateState());
  },
  getBreadcrumbTitle: function() {
    var title =
        "Listing: " + (this.props.model.get("name") || this.props.model.id),
      routes = this.context.router.getCurrentRoutes();

    for (var i = routes.length - 1; i > 0; i--) {
      if (this["getBreadcrumbTitleFor" + routes[i].name]) {
        title = this["getBreadcrumbTitleFor" + routes[i].name]();
        break;
      } else if (viewDisplayNames[routes[i].name]) {
        title = title + " - " + viewDisplayNames[routes[i].name];
      }
    }
    return title;
  },

  getBreadcrumbTitleForProjectReports: function() {
    return (
      "Listing: " +
      (this.props.model.get("name") || this.props.model.id) +
      ": Reports"
    );
  },

  getExtraBreadcrumbItems: function() {
    var routes = this.context.router.getCurrentRoutes(),
      components;

    for (var i = routes.length - 1; i > 0; i--) {
      if (this["getExtraBreadcrumbItemsFor" + routes[i].name]) {
        components = this["getExtraBreadcrumbItemsFor" + routes[i].name](
          routes[i]
        );
        return components;
      } else if (viewDisplayNames[routes[i].name]) {
        components = this.getExtraBreadcrumbItemsForStandard(
          viewDisplayNames[routes[i].name]
        );
        return components;
      }
    }
    return null;
  },
  getExtraBreadcrumbItemsForStandard: function(title) {
    return React.createElement(NavLink, {active: true}, title);
  },

  getExtraBreadcrumbItemsForProjectEditFloorPlan: function() {
    return this.getBreadcrumbItemsForSubEditScreen(
      "floorplanid",
      viewDisplayNames["ProjectEditFloorPlans"],
      "floorPlanName",
      "floorPlansCollection",
      "ProjectEditFloorPlans"
    );
  },
  getExtraBreadcrumbItemsForProjectEditImage: function() {
    return this.getBreadcrumbItemsForSubEditScreen(
      "imageid",
      viewDisplayNames["ProjectEditImages"],
      "title",
      "imagesCollection",
      "ProjectEditImages"
    );
  },
  getExtraBreadcrumbItemsForProjectEditDocument: function() {
    return this.getBreadcrumbItemsForSubEditScreen(
      "documentId",
      viewDisplayNames["ProjectEditDocuments"],
      "title",
      "documentsCollection",
      "ProjectEditDocuments"
    );
  },
  getExtraBreadcrumbItemsForProjectEditPromotion: function() {
    return this.getBreadcrumbItemsForSubEditScreen(
      "promotionId",
      viewDisplayNames["ProjectEditPromotions"],
      "name",
      "promotionsCollection",
      "ProjectEditPromotions"
    );
  },
  getExtraBreadcrumbItemsForProjectEditUpgradeAndBillingInfo: function() {
    return [
      React.createElement(NavLink, {
        to: "ProjectEditUpgradeAndBilling", 
        params: this.context.router.getCurrentParams(), 
        key: "project-upgrade-billing-link"
      }, 
        viewDisplayNames["UserUpgradeAndBilling"]
      ),
      React.createElement(NavLink, {key: "project-upgrade-billing-info", active: true}, 
        viewDisplayNames["ProjectEditUpgradeAndBillingInfo"]
      )
    ];
  },

  getBreadcrumbItemsForSubEditScreen: function(
    paramName,
    linkTitle,
    titleProperty,
    collectionProperty,
    route
  ) {
    var params = this.context.router.getCurrentParams(),
      subItemId = params[paramName],
      model = this.props.model,
      linkParam = {
        id: model.id
      },
      subItemModel =
        model[collectionProperty] && model[collectionProperty].get(subItemId),
      title;

    subItemModel && (title = subItemModel.get(titleProperty));

    return [
      React.createElement(NavLink, {key: "project-dashbaord-link", params: linkParam, to: route}, 
        linkTitle
      ),
      React.createElement(NavLink, {key: "project-active-link", active: true}, 
        title ? title : "..."
      )
    ];
  },

  render: function() {
    var me = this,
      props = me.props,
      state = me.state,
      data = props.model.toJSON(),
      title = "Edit Project - " + (data.name || data.id),
      linkParam = {
        id: data.id
      },
      breadcrumbTitle = this.getBreadcrumbTitle(),
      to = data.id ? "ProjectEdit" : "ProjectAdd",
      displaySidebar = this.displaySidebar(),
      extraBreadcrumbItems = me.getExtraBreadcrumbItems(),
      routes = this.context.router.getCurrentRoutes(),
      currentRouteName = routes[routes.length - 1].name,
      routeName =
        routes.length >= 2 ? routes[routes.length - 2].name : undefined;

    return (
      React.createElement("div", {className: "wrapper"}, 
        React.createElement(DocumentTitle, {title: "NCH - " + breadcrumbTitle}), 
        React.createElement(PageHeader, null), 
        React.createElement("div", {className: "breadcrumbs"}, 
          React.createElement("div", {className: "container"}, 
            React.createElement("h1", {className: "pull-left"}, breadcrumbTitle.split(" - ")[0]), 
            React.createElement("ul", {className: "pull-right breadcrumb"}, 
              React.createElement(NavLink, {to: "ProjectList"}, "My Listings"), 

              extraBreadcrumbItems ? (
                React.createElement(NavLink, {to: "ProjectDashboard", params: linkParam}, 
                  data.name
                )
              ) : (
                React.createElement(NavLink, {active: true}, data.id ? data.name : "New Project")
              ), 

              extraBreadcrumbItems
            )
          )
        ), 
        React.createElement("div", {className: "container content"}, 
          React.createElement("div", {className: "row"}, 
            React.createElement("div", {
              className: "col-sm-3 md-margin-bottom-40", 
              "aria-hidden": !displaySidebar
            }, 
              React.createElement("div", {className: "margin-bottom-20"}, 
                routeName && routeName === "ProjectReports" ? (
                  React.createElement(Link, {to: "ProjectDashboard", params: linkParam}, 
                    React.createElement(BS.Button, {bsStyle: "link"}, 
                      React.createElement("i", {className: "fa fa-fw fa-arrow-left"}), " Back to Listing"
                    )
                  )
                ) : (
                  React.createElement(Link, {to: "ProjectList"}, 
                    React.createElement(BS.Button, {bsStyle: "link"}, 
                      React.createElement("i", {className: "fa fa-fw fa-arrow-left"}), " Back to My" + ' ' +
                      "Listings"
                    )
                  )
                )
              ), 
              routeName === "ProjectReports" ? (
                React.createElement("ul", {className: "list-group sidebar-nav-v1 margin-bottom-20"}, 
                  React.createElement(NavLink, {
                    to: "ProjectReportDashboard", 
                    params: linkParam, 
                    className: "list-group-item"
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-tachometer"}), " ", 
                    viewDisplayNames["ProjectReportDashboard"]
                  ), 
                  React.createElement(NavLink, {
                    to: "ProjectReportAgentDashboard", 
                    params: linkParam, 
                    className: "list-group-item"
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-users"}), " ", 
                    viewDisplayNames["ProjectReportAgentDashboard"]
                  ), 
                  React.createElement(NavLink, {
                    to: "ProjectReportPerformanceDashboard", 
                    params: linkParam, 
                    className: "list-group-item"
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-line-chart"}), " ", 
                    viewDisplayNames["ProjectReportPerformanceDashboard"]
                  )
                )
              ) : (
                React.createElement("ul", {className: "list-group sidebar-nav-v1 margin-bottom-20"}, 
                  React.createElement(NavLink, {
                    to: "ProjectDashboard", 
                    params: linkParam, 
                    className: "list-group-item"
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-tachometer"}), " Listing", " ", 
                    viewDisplayNames["ProjectDashboard"]
                  ), 
                  React.createElement(NavLink, {
                    to: to + "Details", 
                    params: linkParam, 
                    className: "list-group-item"
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-file-text-o"}), " Listing", " ", 
                    viewDisplayNames[to + "Details"]
                  ), 
                  React.createElement(NavLink, {
                    to: to + "Details2", 
                    params: linkParam, 
                    className: "list-group-item"
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-file-text-o"}), " Listing", " ", 
                    viewDisplayNames[to + "Details2"]
                  ), 
                  React.createElement(NavLink, {
                    to: to + "Contacts", 
                    params: linkParam, 
                    className: "list-group-item"
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-file-text-o"}), " Listing", " ", 
                    viewDisplayNames[to + "Contacts"]
                  ), 
                  React.createElement(NavLink, {
                    to: to + "Images", 
                    params: linkParam, 
                    className: "list-group-item"
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-image"}), " Listing", " ", 
                    viewDisplayNames[to + "Images"]
                  ), 
                  React.createElement(NavLink, {
                    to: to + "FloorPlans", 
                    params: linkParam, 
                    className: "list-group-item"
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-file-image-o"}), " Listing", " ", 
                    viewDisplayNames[to + "FloorPlans"]
                  ), 
                  React.createElement(NavLink, {
                    to: to + "Documents", 
                    params: linkParam, 
                    className: "list-group-item"
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-files-o"}), " Listing", " ", 
                    viewDisplayNames[to + "Documents"]
                  ), 
                  React.createElement(NavLink, {
                    to: to + "Promotions", 
                    params: linkParam, 
                    className: "list-group-item"
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-dollar"}), " Listing", " ", 
                    viewDisplayNames[to + "Promotions"]
                  ), 
                  React.createElement(NavLink, {
                    to: to + "Amenities", 
                    params: linkParam, 
                    className: "list-group-item"
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-coffee"}), " Listing", " ", 
                    viewDisplayNames[to + "Amenities"]
                  ), 

                  React.createElement(NavLink, {
                    to: "ProjectPersonalizedAgentEmail", 
                    params: linkParam, 
                    className: "list-group-item"
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-envelope-o"}), " ", 
                    viewDisplayNames["ProjectPersonalizedAgentEmail"]
                  ), 
                  React.createElement(NavLink, {
                    to: "ProjectPersonalizedAgentBrochure", 
                    params: linkParam, 
                    className: "list-group-item"
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-file-pdf-o"}), " ", 
                    viewDisplayNames["ProjectPersonalizedAgentBrochure"]
                  ), 

                  React.createElement(NavLink, {
                    to: to + "APIKey", 
                    params: linkParam, 
                    className: "list-group-item"
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-users"}), " ", 
                    viewDisplayNames[to + "APIKey"]
                  ), 
                  React.createElement(NavLink, {
                    to: "ProjectReports", 
                    params: linkParam, 
                    className: "list-group-item"
                  }, 
                    React.createElement("i", {className: "fa fa-fw fa-line-chart"}), " ", 
                    viewDisplayNames["ProjectReports"]
                  ), 
                  !this.hasRole(Constants.roles.LISTING_USER) && (
                    React.createElement(NavLink, {
                      to: "UserUpgradeAndBilling", 
                      params: linkParam, 
                      className: "list-group-item"
                    }, 
                      React.createElement("i", {className: "fa fa-fw fa-usd"}), " ", 
                      viewDisplayNames["UserUpgradeAndBilling"]
                    )
                  ), 
                  this.hasRole(Constants.roles.ADMIN) && (
                    React.createElement(NavLink, {
                      to: to + "Visibility", 
                      params: linkParam, 
                      className: "list-group-item"
                    }, 
                      React.createElement("i", {className: "fa fa-fw fa-eye"}), " Listing", " ", 
                      viewDisplayNames[to + "Visibility"]
                    )
                  ), 
                  currentRouteName === "ProjectEditUpgradeAndBilling" && (
                    React.createElement(CreditCard, {
                      to: "ProjectEditUpgradeAndBillingInfo", 
                      params: linkParam}
                    )
                  )
                )
              ), 
              this.hasOneRole(Constants.roles.DEVELOPER) && (
                React.createElement(Link, {
                  to: "UserUpgradeAndBilling", 
                  className: "btn-u btn-u-orange btn-block"
                }, 
                  React.createElement("i", {className: "fa fa-3x fa-paper-plane pull-left"}), 
                  React.createElement("div", {className: "text-center"}, 
                    viewDisplayNames["UserUpgradeAndBilling"], 
                    React.createElement("br", null), 
                    React.createElement("small", null, "Click here to access.")
                  )
                )
              )
            ), 
            React.createElement("div", {className: displaySidebar ? "col-sm-9" : "col-xs-12"}, 
              props.model.isReadOnly() &&
                this.isRoute("ProjectEditDetails", "ProjectEditAmenities") && (
                  React.createElement(BS.Alert, {bsStyle: "danger"}, 
                    "This is a ", React.createElement("b", null, "READ ONLY"), " Listing. Please", " ", 
                    React.createElement("a", {href: "#", onClick: this.openSupport}, 
                      "CLICK HERE"
                    ), " ", 
                    "to contact us with questions or to request changes."
                  )
                ), 
              React.createElement(RouteHandler, {
                model: props.model, 
                project: CurrentUser.isAdmin() ? props.model : undefined, 
                readOnly: 
                  this.isRoute("ProjectEditDetails", "ProjectEditAmenities") &&
                  props.model.isReadOnly()
                }
              )
            )
          )
        ), 
        React.createElement(PageFooter, null)
      )
    );
  },

  displaySidebarForProjectAddImage: false,
  displaySidebarForProjectEditImage: false,
  displaySidebarForProjectEditFloorPlan: false,
  displaySidebarForProjectAddFloorPlan: false,
  displaySidebarForProjectAddContact: false,
  displaySidebarForProjectEditDocument: false,
  displaySidebarForProjectAddDocument: false,
  displaySidebarForProjectAddPromotion: false,
  displaySidebarForProjectEditPromotion: false,
  displaySidebarForProjectEditContact: false,
  displaySidebarForProjectEditUpgradeAndBillingInfo: false,
  isRoute: function(/*route1, route2, route3, etc, checkDepth*/) {
    var routes = this.context.router.getCurrentRoutes(),
      checkDepth = arguments[arguments.length - 1] === true,
      routes = checkDepth ? routes : [routes[routes.length - 1]];

    for (var i = 0, iLen = routes.length; i < iLen; i++) {
      for (var k = 0, kLen = arguments.length; k < kLen; k++) {
        if (routes[i].name === arguments[k]) {
          return true;
        }
      }
    }

    return false;
  },

  displaySidebar: function() {
    var display,
      routes = this.context.router.getCurrentRoutes();

    for (var i = routes.length - 1; i > 0; i--) {
      display = this["displaySidebarFor" + routes[i].name];
      if (_.isFunction(display)) {
        display = display();
      }
      if (display !== undefined) {
        break;
      }
    }

    return display !== false;
  }
});
module.exports = AddEditProject;
