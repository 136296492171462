"use strict";

var ModelClass = require('./Model'),
    Constants = require('../constants'),
    CurrentUser = require('../shared/CurrentUser'),
    Agent = require('./Agent'),
    ListingsCollection = require('../collections/Projects'),
    URI = require('urijs'),
    _ = require('underscore'),
    $ = require('jquery');

var Widget = ModelClass.extend({
    urlRoot: Constants.restPath + "/widgets",
    idAttribute: 'id',
    hasOne: {
        Agent: 'agent'
    },
    hasMany: {
        ListingsCollection: 'listings'
    },
    validation: {
        name: {
            required: true
        },
        "country.id": {
            required: function(value, attr, computedState) {
                return !!computedState.widgetType && computedState.widgetType.id === 7;
            }
        },
        "state.id": {
            required: function(value, attr, computedState) {
                return !!computedState.widgetType && computedState.widgetType.id === 7;
            }
        },
        cities: {
            required: function(value, attr, computedState) {
                return !(computedState.metroArea && computedState.metroArea.id && computedState.metroArea.id !== 'NONE') && !!computedState.widgetType && computedState.widgetType.id === 7;
            }
        },
        "metroArea.id": {
            required: function(value, attr, computedState) {
                return !!computedState.widgetType && computedState.widgetType.id === 7;
            }
        },
        listings: function(value, attr, computedState) {
            var widgetType = attr.widgetType || this.get('widgetType');
            if (widgetType.id !== 7 && this.listingsCollection.length === 0) {
                return 'At least one Listing is required.';
            }
        }
    },
    validationUses: {
        "country.id": ['widgetType'],
        "state.id": ['widgetType'],
        "metroArea.id": ['widgetType']
    },
    labels: {
        "country.id": 'Country',
        "state.id": 'State',
        "metroArea.id": 'Metro'
    },
    defaults: function() {
        return {
            id: undefined,
            name: '',
            country: {
                id: undefined
            },
            state: {
                id: undefined
            },
            metroArea: {
                id: undefined
            },
            developer: {
                id: undefined
            },
            cities: undefined,
            metroOrCity: 'metro',
            viewType: '',
            widgetType: {
                id: 7
            },
            mapZoomLevel: 3,
            centerPoint: {
                latitude: undefined,
                longitude: undefined
            },
            listings: undefined,
            metroByCity: false,
            default: false
        };
    },

    parse: function(response, options) {
        var data;

        options = options || {};

        if (options.agentEmail && this.get('useDefaultAgent') !== true) {
            response.defaultAgent = response.agent;
            delete response.agent;
        }

        data = ModelClass.prototype.parse.apply(this, arguments);

        if (data) {
            // data.metroOrCity = data.metroArea && data.metroArea.id || !data.cities ? 'metro' : 'city';
            data.metroArea = !data.metroArea || !data.metroArea.id ? {
                id: 'NONE',
                name: 'No Metro'
            } : data.metroArea;

            // Helps with VB passing nulls around
            if (!data.country) {
                delete data.country;
            }
            if (!data.state) {
                delete data.state;
            }
            if (!data.metroArea) {
                delete data.metroArea;
            }
            if (!data.developer) {
                delete data.developer;
            }
            if (!data.centerPoint) {
                delete data.centerPoint;
            }
            if (!data.cities) {
                delete data.cities;
            }
            if (!data.mapZoomLevel) {
                delete data.mapZoomLevel;
            }
        }

        return data;
    },

    getWidgetTypeName: function(widgetType) {
        var type = widgetType || (this.get('widgetType') || {}).id,
            name;

        switch (type) {
            case 7:
                name = 'Automatic';
                break;
            case 1:
                name = 'List';
                break;
            case 6:
                name = 'Gallery';
                break;
            default:
                name = 'Manual';
                break;
        }

        return name;
    },

    save: function(attributes, options) {
        var widgetType;

        attributes = attributes || {};
        widgetType = attributes.widgetType || this.get('widgetType');
        options = options || {};

        if (widgetType && widgetType.id !== 7) {
            attributes.listings = this.listingsCollection.map(function(listing) {
                return {
                    id: listing.id
                };
            });
        }

        if (options.createDefaultWidget === true) {
            options.url = URI(this.url() + "/default/" + options.agentId);
        }

        attributes.agent = this.getAgentSaveData(attributes);

        var r = ModelClass.prototype.save.call(this, attributes, options);

        return r;
    },

    getAgentSaveData: function(attributes) {
        var agentId;

        attributes = attributes || {};

        if (attributes.agent && attributes.agent.id) {
            agentId = attributes.agent.id;
        } else if (this.agentModel && this.agentModel.id) {
            agentId = this.agentModel.id;
        } else if (CurrentUser && CurrentUser.agentModel && CurrentUser.agentModel.id) {
            agentId = CurrentUser.agentModel.id;
        }

        return agentId ? {
            id: agentId
        } : undefined;
    },

    toJSON: function(options) {
        var data = ModelClass.prototype.toJSON.apply(this, arguments);

        options = options || {};

        if (options.syncMethod === 'create' || options.syncMethod === 'update') {
            if (data.country && !data.country.id) {
                delete data.country;
            }
            if (data.state && !data.state.id) {
                delete data.state;
            }
            if (data.metroArea && (!data.metroArea.id || data.metroArea.id === 'NONE')) {
                data.metroArea = {};
            }
            if (!data.cities || data.cities.length === 0) {
                data.cities = [];
            }
            if (data.developer && !data.developer.id) {
                delete data.developer;

            }

            if (data.centerPoint && (!data.centerPoint.latitude || !data.centerPoint.longitude)) {
                data.centerPoint = undefined;
            }

            data.metroOrCity = data.metroOrCity.toUpperCase();
        }

        return data;
    },

    getPreviewUrl: function() {
        var id = this.id,
            host = Constants.widgetHost,
            url = URI(host).addQuery('wid', id)

        return url.toString();
    },

    fetch: function(options) {
        var me = this,
            widgetXhrPromise,
            agentXhrPromise,
            promise;


        options = options || {};
        options.data = options.data || {};
        options.data.limit = 1;

        if (options.lookup) {
            options.data = _.pick(options.lookup, 'email', 'firstName', 'lastName', 'country', 'city', 'state', 'leadCRMEmail', 'crmType', 'crmEmail');
            options.url = Constants.restPath + '/widgets/default/refindly';
            options.dataType = this.jsonPSupport && this.usingJsonP ? 'jsonp' : 'json';
            options.jsonp = 'jsonpCallback';
        }

        if (!this.id && options.agentEmail) {
            options.url = Constants.restPath + (this.jsonPSupport && this.usingJsonP ? '/jsonp' : '') + '/widgets/by-email';
            options.dataType = this.jsonPSupport && this.usingJsonP ? 'jsonp' : 'json';
            options.data.email = options.agentEmail;
        }

        widgetXhrPromise = ModelClass.prototype.fetch.call(this, options);
        promise = widgetXhrPromise;

        if (options.agentId || options.agentEmail) {
            this.agentModel.id = options.agentId;
            agentXhrPromise = this.agentModel.fetch({
                agentEmail: options.agentEmail
            });

            agentXhrPromise.fail(function() {
                if (me.get('defaultAgent')) {
                    me.agentModel.set(me.get('defaultAgent'));
                } else {
                    me.set('useDefaultAgent', true, {
                        silent: true
                    });
                }
            });

            promise = widgetXhrPromise;
            // promise = $.when(widgetXhrPromise, agentXhrPromise);
        }

        return promise;
    }
}, {
    Agent: Agent,
    ListingsCollection: ListingsCollection
});

module.exports = Widget;
