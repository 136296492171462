"use strict";

var React = require('react'),
    _ = require('underscore'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    Link = ReactRouter.Link,
    cx = require('classnames'),
    Constants = require('../../../constants'),
    Cmp = require('../../../components'),
    FluxBone = require('../../../mixins/FluxBone'),
    ReportModel = require('../../../models/reports/ListingPerformance'),
    Validation = require('../../../partials/validation'),
    Charts = require('../../../partials/chart'),
    numeral = require('numeral');


var ProjectPerformanceReport = React.createClass({displayName: "ProjectPerformanceReport",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset'),
        FluxBone.ModelMixin('report', 'sync'),
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function(props) {
        var query = this.context.router.getCurrentQuery(),
            params = this.context.router.getCurrentParams(),
            startDate,
            endDate,
            listingId,
            grouping,
            defaults,
            state = this.state || {};

        props = props || this.props;

        props.report.project = props.model;

        defaults = props.report.defaults();
        startDate = query.startDate || defaults.startDate;
        endDate = query.endDate || defaults.endDate;
        listingId = params.id || props.model.id || props.report.get('listingId');
        grouping = query.grouping || props.report.get('grouping');

        if (state.startDate !== startDate || state.endDate !== endDate || state.listingId !== listingId || state.grouping !== grouping) {
            props.report.clear();

            props.report.set({
                startDate: startDate,
                endDate: endDate,
                grouping: grouping,
                listingId: listingId
            });

            this.props.report.fetch();
        }

        return {
            startDate: startDate,
            endDate: endDate,
            grouping: grouping,
            listingId: listingId
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        return {
            report: new ReportModel()
        };
    },
    /**
     * This is to ensure that when subroutes transition they trigger recheck router state and render
     */
    componentWillReceiveProps: function(props) {
        this.setState(this.updateState(props));
    },

    shouldBeDisabled: function(props){
        props = props || this.props;
        return props.model.get('serviceTypes').length === 0; 
    },

    render: function() {
        var props = this.props,
            state = this.state,
            reportData = props.report.toJSON({
                includeListings: true
            }),
            aggregateStats = reportData.aggregateStats,
            queryParams = {
                startDate: state.startDate,
                endDate: state.endDate
            },
            isDisabled = this.shouldBeDisabled();

        return (
            React.createElement("div", null, 
                React.createElement("h1", null, "Report: ", props.model.get('name'), ": Performance"), 
                React.createElement("h5", {className: "margin-bottom-20"}, 
                    "Below are reports about the performance of your listing."
                ), 
                isDisabled && !props.model.isLoading() && props.model.isLoaded() && (
                    React.createElement("div", null, 
                        React.createElement(BS.Row, null, 
                            React.createElement(BS.Col, {xs: 12}, 
                                React.createElement("p", {className: "green-text"}, 
                                    React.createElement("i", {className: "fa fa-exclamation-triangle"}), " Upgrade to access this full report. ", React.createElement(Link, {to: "UserUpgradeAndBilling", params: { id: props.model.id}}, "Click Here"), " to upgrade."
                                )
                            )
                        ), 
                        React.createElement(BS.Row, null, 
                            React.createElement(BS.Col, {xs: 12}, 
                                React.createElement("h3", null, "Gain insight on how your listing is performing."), 
                                React.createElement("h3", {className: "green-header"}, "Performance Reports Include:")
                            )
                        ), 
                        React.createElement(BS.Row, null, 
                            React.createElement(BS.Col, {xs: 5}, 
                                React.createElement("ul", {className: "check-list"}, 
                                    React.createElement("li", null, "Number of Views"), 
                                    React.createElement("li", null, "Number of Clicks"), 
                                    React.createElement("li", null, "Number of Leads"), 
                                    React.createElement("li", null, "Export Reports")
                                )
                            ), 
                            React.createElement(BS.Col, {xs: 7}, 
                                React.createElement("img", {className: "img-responsive", src: Constants.imageRoot + 'reports/sample_performance_report.png'})
                            )
                        ), 
                        React.createElement(BS.Row, null, 
                            React.createElement(BS.Col, {xs: 12}, 
                                React.createElement("p", {className: "green-text"}, 
                                    React.createElement("i", {className: "fa fa-exclamation-triangle"}), " Upgrade to access this full report. ", React.createElement(Link, {to: "UserUpgradeAndBilling", params: { id: props.model.id}}, "Click Here"), " to upgrade."
                                )
                            )
                        )
                    )
                ), 
                !isDisabled && !props.model.isLoading() && props.model.isLoaded() && (
                    React.createElement("div", null, 
                        React.createElement("div", {className: "margin-bottom-20"}, 
                            React.createElement(Validation.Form, {ref: "form", model: props.report, onSubmit: this.onSaveClick}, 
                                React.createElement(BS.Row, null, 
                                    React.createElement(BS.Col, {xs: 12}, 
                                        React.createElement("div", {className: "pull-right"}, 
                                            React.createElement("div", {className: "btn-u btn-u-orange", onClick: this.onExportClick}, 
                                                React.createElement("i", {className: "fa fa-external-link fa-fw"}), " Export Stats"
                                            )
                                        )
                                    )
                                ), 
                                React.createElement(BS.Row, null, 
                                    React.createElement(BS.Col, {sm: 6, xs: 12}, 
                                        React.createElement(Validation.Input, {type: "select", labelClassName: "col-xs-5", wrapperClassName: "col-xs-7", label: "View by:", name: "grouping", onValueChange: this.onSaveClick}, 
                                            React.createElement("option", {value: "MONTH"}, "Month"), 
                                            React.createElement("option", {value: "WEEK"}, "Week")
                                        )
                                    )
                                ), 
                                React.createElement(BS.Row, null, 
                                    React.createElement(BS.Col, {sm: 6, xs: 12}, 
                                        React.createElement(Cmp.Picker.Date, {labelClassName: "col-xs-5", wrapperClassName: "col-xs-7", label: "Filter by Date:", placeholder: "Start", name: "startDate"})
                                    ), 
                                    React.createElement(BS.Col, {sm: 4, xs: 12}, 
                                        React.createElement(Cmp.Picker.Date, {labelClassName: "col-xs-5 col-sm-2", wrapperClassName: "col-xs-7 col-sm-10", label: "to", placeholder: "Start", name: "endDate"})
                                    ), 
                                    React.createElement(BS.Col, {sm: 2, xs: 12}, 
                                        React.createElement(Cmp.Button.Save, null, "Go")
                                    )
                                )
                            )
                        ), 

                        aggregateStats && (
                            React.createElement("div", {className: "dashboard-stats row margin-bottom-50"}, 
                                React.createElement("div", {className: "dashboard-stat col-xs-3"}, 
                                    React.createElement("div", {className: "dashboard-stat-inner"}, 
                                        React.createElement("h4", {title: "Agents"}, React.createElement("i", {className: "fa fa-fw fa-users"}), "Agents", React.createElement("small", null, "promoting your listings")), 
                                        React.createElement("div", {className: "orange-text"}, numeral(aggregateStats.agents).format('0,0[.]00'))
                                    )
                                ), 
                                /*
                                <div className="dashboard-stat col-xs-3">
                                    <div className="dashboard-stat-inner">
                                        <h4 title="Views"><i className="fa fa-fw fa-eye"/>Views<small>of your listings</small></h4>
                                        <div className="blue-text">{numeral(aggregateStats.views).format('0,0[.]00')}</div>
                                    </div>
                                </div>
                                */
                                React.createElement("div", {className: "dashboard-stat col-xs-3"}, 
                                    React.createElement("div", {className: "dashboard-stat-inner"}, 
                                        React.createElement("h4", {title: "Views"}, React.createElement("i", {className: "fa fa-fw fa-eye"}), "Views", React.createElement("small", null, "on your listings")), 
                                        React.createElement("div", {className: "blue-text"}, numeral(aggregateStats.views).format('0,0[.]00'))
                                    )
                                ), 
                                React.createElement("div", {className: "dashboard-stat col-xs-3"}, 
                                    React.createElement("div", {className: "dashboard-stat-inner"}, 
                                        React.createElement("h4", {title: "Clicks"}, React.createElement("i", {className: "fa fa-fw fa-external-link"}), "Clicks", React.createElement("small", null, "to your listing website")), 
                                        React.createElement("div", {className: "blue-text"}, numeral(aggregateStats.clicks).format('0,0[.]00'))
                                    )
                                ), 
                                React.createElement("div", {className: "dashboard-stat col-xs-3"}, 
                                    React.createElement("div", {className: "dashboard-stat-inner"}, 
                                        React.createElement("h4", {title: "Leads"}, React.createElement("i", {className: "fa fa-fw fa-fire"}), "Leads", React.createElement("small", null, "of your listings")), 
                                        React.createElement("div", {className: "blue-text"}, numeral(aggregateStats.leads).format('0,0[.]00'))
                                    )
                                )
                            )
                        ), 

                        aggregateStats && (
                            React.createElement("div", null, 
                                React.createElement("h2", null, "Views:"), 
                                React.createElement(Charts.ListingPerformance, {showViews: true, model: props.model, report: props.report, startDate: state.startDate, endDate: state.endDate, grouping: state.grouping}), 
                                React.createElement("h2", null, "Clicks:"), 
                                React.createElement(Charts.ListingPerformance, {showClicks: true, model: props.model, report: props.report, startDate: state.startDate, endDate: state.endDate, grouping: state.grouping}), 
                                React.createElement("h2", null, "Leads:"), 
                                React.createElement(Charts.ListingPerformance, {showLeads: true, model: props.model, report: props.report, startDate: state.startDate, endDate: state.endDate, grouping: state.grouping})
                            )
                        )
                    )
                )
            )
        );
    },

    onExportClick: function(){
        this.onSaveClick(true);
    },

    onSaveClick: function(exportReport) {
        var props = this.props,
            data = this.refs.form.getValues(),
            currentRoutes = this.context.router.getCurrentRoutes(),
            currentRoute = currentRoutes[currentRoutes.length - 1];

        if(exportReport === true){
            props.report.fetch({
                download: true,
                data: {
                    startDate: data.startDate,
                    endDate: data.endDate,
                    grouping: data.grouping,
                    listingId: props.model.id
                }
            });
        } else {
            this.transitionTo(currentRoute.name, {
                id: this.props.model.id
            }, {
                startDate: data.startDate,
                endDate: data.endDate,
                grouping: data.grouping,
                page: 1
            });
        }
    }
});

module.exports = ProjectPerformanceReport;
