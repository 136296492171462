"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    DocumentTitle = require('react-document-title'),
    BS = require('react-bootstrap'),
    Cmp = require('../../../components'),
    PageHeader = require('../../../partials/PageHeader'),
    DocumentModel = require('../../../models/ProjectDocument'),
    PageFooter = require('../../../partials/PageFooter'),
    Validation = require('../../../partials/validation');

var ProjectWizardDocument = React.createClass({displayName: "ProjectWizardDocument",

    mixins: [
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            entity: new DocumentModel()
        };
    },

    updateState: function() {
        this.props.entity.set({
            listing: {
                id: this.props.model.id
            }
        });
        return {
            success: undefined
        }
    },

    getInitialState: function() {
        return this.updateState();
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.reset();
    },

    render: function() {
        var me = this,
            props = me.props,
            model = props.model,
            state = me.state;

        return (
            React.createElement("div", {className: "wrapper widgets"}, 
                React.createElement(DocumentTitle, {title: "NCH - Create Listing - Add Document"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Create Listing - Step 2C: Add Document"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(Cmp.NavLink, {to: "ProjectList"}, "Projects"), 
                            React.createElement(Cmp.NavLink, {to: "ProjectWizardSteps", params: { id: model.id}}, "Additional Info"), 
                            React.createElement(Cmp.NavLink, {to: "ProjectWizardDocument", params: { id: model.id}}, "Add Document")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    state.success && (
                        React.createElement(BS.Alert, {bsStyle: "success", className: "text-center"}, 
                            React.createElement("h4", null, "Document Added Successfully"), 
                            React.createElement("div", {className: "row"}, 
                                React.createElement(BS.ButtonToolbar, {className: "center-block-inline"}, 
                                    React.createElement(Cmp.Button.Save, {onClick: this.reset}, "Add Another Document"), 
                                    React.createElement("span", {className: "pull-left btn-divider"}, "or"), 
                                    React.createElement(Cmp.Button.Cancel, {onClick: this.gotoSteps}, "Continue ", React.createElement("i", {className: "fa fa-arrow-right"}))
                                )
                            )
                        )
                    ), 
                    !state.success && (
                        React.createElement(Validation.Form, {ref: "form", model: props.entity, onSubmit: this.onSaveClick}, 
                            React.createElement("h1", null, "Add Document"), 

                            React.createElement("fieldset", null, 
                                React.createElement(Validation.Input, {type: "text", placeholder: "Enter a Name for this Document", name: "title", label: "Document name/Title"}), 
                                React.createElement(Validation.Input, {rows: "3", type: "textarea", placeholder: "Provide a description for this Promotion", name: "description", label: "Document description"}), 
                                React.createElement(Cmp.RadioGroup, {label: "Category", type: "radio", items: this.CATEGORIES, name: "category"}), 
                                React.createElement(Cmp.Picker.File, {ref: "filePicker", name: "files", url: props.entity.url(), label: "Document File", onComplete: this.onFileUpload})
                            ), 

                            React.createElement(Cmp.ValidationMessage, null), 
                            React.createElement(BS.ButtonToolbar, null, 
                                React.createElement(Cmp.Button.Cancel, {onClick: this.gotoSteps}, React.createElement("i", {className: "fa fa-arrow-left"}), " Back to Steps"), 
                                React.createElement(Cmp.Button.Save, {className: "pull-right"}, "Save & Continue ", React.createElement("i", {className: "fa fa-arrow-right"}))
                            )
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    },

    onSaveClick: function() {
        var me = this,
            filePicker = me.refs.filePicker,
            model = this.props.entity,
            isNew = model.isNew();

        model.save(me.refs.form.getValues()).then(function(m, e, r) {
            if(isNew){
                filePicker.setIdUrlPart(model.id);
            }
            if (filePicker.hasFiles()) {
                filePicker.startUpload();
            } 
        });

    },

    onFileUpload: function(success) {
        var me = this;
        if (success) {
            me.props.entity.clear();
            me.setState({success : true }, function() {
                me.refs.form && me.refs.form.forceUpdate();
            });
        };
    },

    gotoSteps: function() {
        this.transitionTo('ProjectWizardSteps', {
            id: this.props.model.id
        });
    },

    reset: function() {
        var me = this;
        me.props.entity.clear();
        me.setState(me.updateState(), function() {
            me.refs.form && me.refs.form.forceUpdate();
        });
    }
});
module.exports = ProjectWizardDocument;
