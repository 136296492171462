"use strict";

var ModelClass = require('./Model'),
    Agent = require('./Agent'),
    Broker = require('./Broker'),
    Developer = require('./Developer'),
    User = require('./User'),
    _ = require('underscore');


var UserSignup = ModelClass.extend({
    idAttribute: 'id',
    validation: {
        "user.password": {
            required: true
        },
        "contactInfo.firstName": {
            required: true
        },
        "contactInfo.lastName": {
            required: true
        },
        "user.email": {
            required: true
        },
        type: {
            required: true,
            oneOf: ['Agent', 'Broker', 'Developer', 'Builder']
        },
        businessName: {
            required: function(value, attr, computedState) {
                return !computedState || computedState.type === 'Builder' || computedState.type === 'Developer';
            }
        },
        brokerageName: {
            required: function(value, attr, computedState) {
                return !computedState || computedState.type === 'Agent' || computedState.type === 'Broker';
            }
        }
    },
    validationUses: {
        businessName: ['type'],
        brokerageName: ['type']
    },
    labels: {
        "user.password": 'Password'
    },
    defaults: function() {
        return {
            type: 'Agent',
            brokerageName: '',
            businessName: '',
            user: {},
            contactInfo: {},
            src: window && window.location ? window.location.href : undefined
        };
    },
    fetch: function() {},
    sync: function(method, model, options) {
        var data = model.toJSON(options),
            entityModel;

        entityModel = new UserSignup[data.type](data);
        entityModel.includeAssociations = true;

        return entityModel.save(null, {
            validate: false
        });
    }
}, {
    Agent: Agent,
    Broker: Broker,
    Developer: Developer,
    User: User
});

module.exports = UserSignup;
