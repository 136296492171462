'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

var _get = function get(_x, _x2, _x3) { var _again = true; _function: while (_again) { var object = _x, property = _x2, receiver = _x3; desc = parent = getter = undefined; _again = false; if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { _x = parent; _x2 = property; _x3 = receiver; _again = true; continue _function; } } else if ('value' in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } } };

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

function _inherits(subClass, superClass) { if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _DateConstants = require('./DateConstants');

var _DateConstants2 = _interopRequireDefault(_DateConstants);

function isSameDay(one, two) {
  return one.getYear() === two.getYear() && one.getMonth() === two.getMonth() && one.getDayOfMonth() === two.getDayOfMonth();
}

function beforeCurrentMonthYear(current, today) {
  if (current.getYear() < today.getYear()) {
    return 1;
  }
  return current.getYear() === today.getYear() && current.getMonth() < today.getMonth();
}

function afterCurrentMonthYear(current, today) {
  if (current.getYear() > today.getYear()) {
    return 1;
  }
  return current.getYear() === today.getYear() && current.getMonth() > today.getMonth();
}

function getIdFromDate(d) {
  return 'rc-calendar-' + d.getYear() + '-' + d.getMonth() + '-' + d.getDayOfMonth();
}

function noop() {}

function handleDayClick(current) {
  this.props.onSelect(current);
}

var DateTBody = (function (_React$Component) {
  _inherits(DateTBody, _React$Component);

  function DateTBody() {
    _classCallCheck(this, DateTBody);

    _get(Object.getPrototypeOf(DateTBody.prototype), 'constructor', this).apply(this, arguments);
  }

  _createClass(DateTBody, [{
    key: 'render',
    value: function render() {
      var props = this.props;
      var i = undefined;
      var j = undefined;
      var current = undefined;
      var dateTable = [];
      var showWeekNumber = props.showWeekNumber;
      var value = props.value;
      var today = value.clone();
      var prefixCls = props.prefixCls;
      var cellClass = prefixCls + '-cell';
      var weekNumberCellClass = prefixCls + '-week-number-cell';
      var dateClass = prefixCls + '-date';
      var dateRender = props.dateRender;
      var disabledDate = props.disabledDate;
      var dateFormatter = this.props.dateFormatter;
      var todayClass = prefixCls + '-today';
      var selectedClass = prefixCls + '-selected-day';
      var lastMonthDayClass = prefixCls + '-last-month-cell';
      var nextMonthDayClass = prefixCls + '-next-month-btn-day';
      var disabledClass = prefixCls + '-disabled-cell';
      var firstDisableClass = prefixCls + '-disabled-cell-first-of-row';
      var lastDisableClass = prefixCls + '-disabled-cell-last-of-row';
      today.setTime(Date.now());
      var month1 = value.clone();
      month1.set(value.getYear(), value.getMonth(), 1);
      var day = month1.getDayOfWeek();
      var lastMonthDiffDay = (day + 7 - value.getFirstDayOfWeek()) % 7;
      // calculate last month
      var lastMonth1 = month1.clone();
      lastMonth1.addDayOfMonth(0 - lastMonthDiffDay);
      var passed = 0;
      for (i = 0; i < _DateConstants2['default'].DATE_ROW_COUNT; i++) {
        for (j = 0; j < _DateConstants2['default'].DATE_COL_COUNT; j++) {
          current = lastMonth1;
          if (passed) {
            current = current.clone();
            current.addDayOfMonth(passed);
          }
          dateTable.push(current);
          passed++;
        }
      }
      var tableHtml = [];
      passed = 0;
      for (i = 0; i < _DateConstants2['default'].DATE_ROW_COUNT; i++) {
        var weekNumberCell = undefined;
        var dateCells = [];
        if (showWeekNumber) {
          weekNumberCell = _react2['default'].createElement(
            'td',
            { key: dateTable[passed].getWeekOfYear(), role: 'gridcell',
              className: weekNumberCellClass },
            dateTable[passed].getWeekOfYear()
          );
        }
        for (j = 0; j < _DateConstants2['default'].DATE_COL_COUNT; j++) {
          var next = null;
          var last = null;
          current = dateTable[passed];
          if (j < _DateConstants2['default'].DATE_COL_COUNT - 1) {
            next = dateTable[passed + 1];
          }
          if (j > 0) {
            last = dateTable[passed - 1];
          }
          var cls = cellClass;
          var disabled = false;
          var selected = false;

          if (isSameDay(current, today)) {
            cls += ' ' + todayClass;
          }
          if (isSameDay(current, value)) {
            cls += ' ' + selectedClass;
            selected = true;
          }
          if (beforeCurrentMonthYear(current, value)) {
            cls += ' ' + lastMonthDayClass;
          }
          if (afterCurrentMonthYear(current, value)) {
            cls += ' ' + nextMonthDayClass;
          }
          if (disabledDate) {
            if (disabledDate(current, value)) {
              cls += ' ' + disabledClass;
              disabled = true;

              if (!last || !disabledDate(last, value)) {
                cls += ' ' + firstDisableClass;
              }

              if (!next || !disabledDate(next, value)) {
                cls += ' ' + lastDisableClass;
              }
            }
          }

          var dateHtml = undefined;
          if (dateRender) {
            dateHtml = dateRender(current, value);
          } else {
            dateHtml = _react2['default'].createElement(
              'span',
              {
                key: getIdFromDate(current),
                className: dateClass,
                'aria-selected': selected,
                'aria-disabled': disabled },
              current.getDayOfMonth()
            );
          }

          dateCells.push(_react2['default'].createElement(
            'td',
            { key: passed, onClick: disabled ? noop : handleDayClick.bind(this, current), role: 'gridcell',
              title: dateFormatter.format(current), className: cls },
            dateHtml
          ));

          passed++;
        }
        tableHtml.push(_react2['default'].createElement(
          'tr',
          {
            key: i,
            role: 'row' },
          weekNumberCell,
          dateCells
        ));
      }
      return _react2['default'].createElement(
        'tbody',
        { className: prefixCls + 'tbody' },
        tableHtml
      );
    }
  }]);

  return DateTBody;
})(_react2['default'].Component);

exports['default'] = DateTBody;

DateTBody.propTypes = {
  dateFormatter: _react2['default'].PropTypes.object
};
module.exports = exports['default'];