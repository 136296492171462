'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _rcUtil = require('rc-util');

var _yearYearPanel = require('../year/YearPanel');

var _yearYearPanel2 = _interopRequireDefault(_yearYearPanel);

var ROW = 4;
var COL = 3;

function goYear(direction) {
  var next = this.state.value.clone();
  next.addYear(direction);
  this.setAndChangeValue(next);
}

function chooseMonth(month) {
  var next = this.state.value.clone();
  next.setMonth(month);
  this.setAndSelectValue(next);
}

function noop() {}

var MonthPanel = _react2['default'].createClass({
  displayName: 'MonthPanel',

  propTypes: {
    onChange: _react2['default'].PropTypes.func,
    onSelect: _react2['default'].PropTypes.func
  },

  getDefaultProps: function getDefaultProps() {
    return {
      onChange: noop,
      onSelect: noop
    };
  },

  getInitialState: function getInitialState() {
    var props = this.props;
    // bind methods
    this.nextYear = goYear.bind(this, 1);
    this.previousYear = goYear.bind(this, -1);
    this.prefixCls = props.rootPrefixCls + '-month-panel';
    return {
      value: props.value || props.defaultValue
    };
  },

  componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
    if ('value' in nextProps) {
      this.setState({
        value: nextProps.value
      });
    }
  },

  onYearPanelSelect: function onYearPanelSelect(current) {
    this.setState({
      showYearPanel: 0
    });
    this.setAndChangeValue(current);
  },

  getMonths: function getMonths() {
    var props = this.props;
    var value = this.state.value;
    var current = value.clone();
    var locale = props.locale;
    var months = [];
    var shortMonths = locale.format.shortMonths;
    var index = 0;
    for (var i = 0; i < ROW; i++) {
      months[i] = [];
      for (var j = 0; j < COL; j++) {
        current.setMonth(index);
        months[i][j] = {
          value: index,
          content: shortMonths[index],
          title: shortMonths[index]
        };
        index++;
      }
    }

    return months;
  },

  render: function render() {
    var _this = this;

    var props = this.props;
    var value = this.state.value;
    var locale = props.locale;
    var months = this.getMonths();
    var year = value.getYear();
    var currentMonth = value.getMonth();
    var prefixCls = this.prefixCls;
    var monthsEls = months.map(function (month, index) {
      var tds = month.map(function (m) {
        var _classNameMap;

        var disabled = false;
        if (props.disabledDate) {
          var testValue = value.clone();
          testValue.setMonth(m.value);
          disabled = props.disabledDate(testValue);
        }
        var classNameMap = (_classNameMap = {}, _defineProperty(_classNameMap, prefixCls + '-cell', 1), _defineProperty(_classNameMap, prefixCls + '-cell-disabled', disabled), _defineProperty(_classNameMap, prefixCls + '-selected-cell', m.value === currentMonth), _classNameMap);
        return _react2['default'].createElement(
          'td',
          { role: 'gridcell',
            key: m.value,
            onClick: disabled ? null : chooseMonth.bind(_this, m.value),
            title: m.title,
            className: (0, _rcUtil.classSet)(classNameMap) },
          _react2['default'].createElement(
            'a',
            {
              className: prefixCls + '-month' },
            m.content
          )
        );
      });
      return _react2['default'].createElement(
        'tr',
        { key: index, role: 'row' },
        tds
      );
    });

    var yearPanel = undefined;
    if (this.state.showYearPanel) {
      yearPanel = _react2['default'].createElement(_yearYearPanel2['default'], { locale: locale, value: value, rootPrefixCls: props.rootPrefixCls,
        onSelect: this.onYearPanelSelect });
    }

    return _react2['default'].createElement(
      'div',
      { className: prefixCls, style: props.style },
      _react2['default'].createElement(
        'div',
        null,
        _react2['default'].createElement(
          'div',
          { className: prefixCls + '-header' },
          _react2['default'].createElement(
            'a',
            { className: prefixCls + '-prev-year-btn',
              role: 'button',
              onClick: this.previousYear,
              title: locale.previousYear },
            '«'
          ),
          _react2['default'].createElement(
            'a',
            { className: prefixCls + '-year-select',
              role: 'button',
              onClick: this.showYearPanel,
              title: locale.yearSelect },
            _react2['default'].createElement(
              'span',
              { className: prefixCls + '-year-select-content' },
              year
            ),
            _react2['default'].createElement(
              'span',
              { className: prefixCls + '-year-select-arrow' },
              'x'
            )
          ),
          _react2['default'].createElement(
            'a',
            { className: prefixCls + '-next-year-btn',
              role: 'button',
              onClick: this.nextYear,
              title: locale.nextYear },
            '»'
          )
        ),
        _react2['default'].createElement(
          'div',
          { className: prefixCls + '-body' },
          _react2['default'].createElement(
            'table',
            { className: prefixCls + '-table', cellSpacing: '0', role: 'grid' },
            _react2['default'].createElement(
              'tbody',
              { className: prefixCls + '-tbody' },
              monthsEls
            )
          )
        )
      ),
      yearPanel
    );
  },

  setAndChangeValue: function setAndChangeValue(value) {
    this.setValue(value);
    this.props.onChange(value);
  },

  setAndSelectValue: function setAndSelectValue(value) {
    this.setValue(value);
    this.props.onSelect(value);
  },

  setValue: function setValue(value) {
    if (!('value' in this.props)) {
      this.setState({
        value: value
      });
    }
  },

  showYearPanel: function showYearPanel() {
    this.setState({
      showYearPanel: 1
    });
  }
});

exports['default'] = MonthPanel;
module.exports = exports['default'];