"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    Promotion = require('../models/Promotion');

var Promotions = Collection.extend({
    url: Promotion.prototype.urlRoot,
    model: Promotion
});

module.exports = Promotions;
