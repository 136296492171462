"use strict";

var React = require('react/addons'),
    BS = require('react-bootstrap');

var CancelProjectEdit = React.createClass({displayName: "CancelProjectEdit",
    render : function(){
      var me = this,
          props = me.props;
        return (
                    React.createElement(BS.Modal, {show: props.show, onHide: props.onHide}, 
                  React.createElement(BS.Modal.Header, {closeButton: true}, 
                      React.createElement(BS.Modal.Title, null, "Warning")
                  ), 
                  React.createElement(BS.Modal.Body, null, 
                      "You have selected to RESET the change to your project. No changes to this project will be made."
                  ), 
                  React.createElement(BS.Modal.Footer, null, 
                      React.createElement(BS.Button, {onClick: props.onCancel}, "Reset Changes")
                  )
              )
        );

    }

});
module.exports = CancelProjectEdit;
