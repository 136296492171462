"use strict";

var React = require('react'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    Link = ReactRouter.Link,
    cx = require('classnames'),
    Constants = require('../../constants'),
    Cmp = require('../../components'),
    ReportModel = require('../../models/reports/NumberOfWidgets'),
    Validation = require('../../partials/validation');


var NumberOfWidgets = React.createClass({displayName: "NumberOfWidgets",

    mixins: [
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {

        return {
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        return {
        	report : new ReportModel()
        };
    },
    /**
     * This is to ensure that when subroutes transition they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
    	var props = this.props;

        return (
	        	React.createElement("div", {className: "margin-bottom-40"}, 
			        React.createElement("h2", null, "Widget Performance Report"), 
                    React.createElement("hr", {className: "devider"}), 
			        React.createElement("p", null, 
			            "lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
			        ), 
    
                    React.createElement(Validation.Form, {ref: "form", model: props.report, onSubmit: this.onSaveClick}, 
                        React.createElement(BS.Grid, null, 
                        	React.createElement(BS.Row, null, 
                        		React.createElement(BS.Col, {md: 2}, 
    			            		React.createElement(Cmp.Picker.Date, {placeholder: "Start", label: "Start", name: "from"})
                        		), 
                        		React.createElement(BS.Col, {md: 1}), 
                        		React.createElement(BS.Col, {md: 2}, 
    					            React.createElement(Cmp.Picker.Date, {placeholder: "End", label: "End", name: "to"})
                        		), 
                        		React.createElement(BS.Col, {md: 7}, 
    			                    React.createElement(Cmp.Button.Save, null, "Export CSV File")
                        		)
                        	)
                        )
                    )

				)
        );
    },
    onSaveClick : function(){
        this.props.report.save(this.refs.form.getValues());
    }
});

module.exports = NumberOfWidgets;

