"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    RouteHandler = ReactRouter.RouteHandler;

var ProjectReports = React.createClass({displayName: "ProjectReports",
    render: function() {
        var me = this,
            props = me.props,
            state = me.state;

        return (
            React.createElement("div", null, 
                props.model.isLoaded() ? (
                    React.createElement(RouteHandler, React.__spread({},  props))
                ) : null
            )
        );
    }
});
module.exports = ProjectReports;
