'use strict';

var React = require('react'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    UserSignup = require('../../../models/UserSignup'),
    DocumentTitle = require('react-document-title'),
    CurrentUser = require('../../../shared/CurrentUser'),
    WidgetModel = require('../../../models/Widget'),
    Constants = require('../../../constants'),
    Cmp = require('../../../components'),
    Link = ReactRouter.Link,
    Validation = require('../../../partials/validation'),
    RequiredMessage = require('../../../partials/RequiredMessage'),
    fullValidation = UserSignup.prototype.validation,
    ContactForm = require('../../currentuser/account/Contact');

var stepOneValidation = {
    'user.password': fullValidation['user.password'],
    type: fullValidation['type']
};

var UserCreate = React.createClass({displayName: "UserCreate",
    mixins: [ReactRouter.Navigation],
    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        var model = new UserSignup();

        model.validation = stepOneValidation;

        return {
            userSignup: model
        };
    },

    updateState: function() {
        return {
            step: 1,
            email: this.context.router.getCurrentParams().email
        };
    },

    getInitialState: function() {
        this.props.userSignup.validation = stepOneValidation;
        return this.updateState();
    },

    componentWillMount: function() {
        this.props.userSignup.clear();
    },

    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        if (this.state.step === 1) {
            return this.getStepOne();
        } else if (this.state.step === 2) {
            return this.getStepTwo();
        }
    },

    componentDidUpdate: function(prevProps, prevState) {
        if (prevState.step !== this.state.step && window.analytics && !(CurrentUser.hasRole(Constants.roles.ADMIN) || !!CurrentUser.get('actualId'))) {
            analytics.page();
        }
    },

    getStepOne: function() {
        var inputParams = {
            type: 'text',
            trigger: 'onChange'
        },
            userTypes = Constants.userTypes,
            passwordIcon = React.createElement("i", {className: "fa fa-lock fa-fw"});

        return (
            React.createElement("div", {className: "container content"}, 
                React.createElement(DocumentTitle, {title: "NCH - Signup - Create Username / Password"}), 
                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-md-8 col-md-offset-2"}, 
                        React.createElement("h2", null, "What type of New Construction Hub account would you like to create?")
                    )
                ), 

                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-md-6 col-md-offset-3"}, 
                        React.createElement(BS.Panel, null, 
                            React.createElement(Validation.Form, {ref: "stepOneForm", model: this.props.userSignup, onSubmit: this.onStepOneSubmit, getMessage: this.getMessage, validateOnLoad: false}, 
                                React.createElement(Cmp.RadioGroup, {groupClassName: "label-align-top", type: "radio", name: "type", items: userTypes, label: "Select the type of New Construction Hub account:"}), 
                                React.createElement("label", null, "Create a password for your account:"), 
                                React.createElement(Validation.Input, React.__spread({}, 
                                    inputParams, 
                                    {labelClassName: "col-md-3", 
                                    wrapperClassName: "col-md-9", 
                                    addonBefore: passwordIcon, 
                                    type: "password", 
                                    name: "user.password", 
                                    label: "Password:"})
                                ), 

                                React.createElement(Cmp.ValidationMessage, {action: "Next"}), 
                                React.createElement(Cmp.Button.Save, {className: "pull-right"}, "Next")
                            )
                        ), 

                        this.props.Footer && React.createElement(this.props.Footer, {trackerId: Constants.trackerPrefix + 'signup-create'})
                    )
                )
            )
        );
    },

    onStepOneSubmit: function(e) {
        var me = this,
            props = me.props,
            form = me.refs.stepOneForm,
            values = form.getValues();

        values.user.username = me.state.email;
        values.user.email = me.state.email;

        values.contactInfo.email = me.state.email;

        if (form.isValid()) {
            props.userSignup
                .save(values)
                .then(function() {
                    CurrentUser.login(values.user.username, values.user.password, undefined, undefined, true)
                        .then(function() {
                            CurrentUser.fetch()
                                .then(function() {
                                    me.setState({
                                        step: 2
                                    });
                                })
                                .fail(function() {
                                    // Show Error?
                                });
                        })
                        .fail(function() {
                            // Show Error?
                        });
                })
                .fail(function() {
                    // Show Error?
                });
        }
    },

    getStepTwo: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.userSignup,
            type = model.get('type').toLowerCase(),
            title = 'Create a real estate ' + (type === 'developer' ? 'builder/developer' : type) + ' account.';

        return (
            React.createElement("div", null, 
                React.createElement(DocumentTitle, {title: "NCH - Signup - Add Details"}), 
                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-md-8 col-md-offset-2"}, 
                        React.createElement("h2", null, title), 
                        React.createElement("p", null, "Almost done! Fill out the information below"), 
                        React.createElement(RequiredMessage, null)
                    )
                ), 

                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-md-8 col-md-offset-2"}, 
                        React.createElement(BS.Panel, null, 
                            React.createElement(ContactForm, {model: CurrentUser, blockBack: true, hideHeader: true, isInWizard: true, saveText: "Next", onSave: this.onSaveContactInfo})
                        ), 
                        this.props.Footer && React.createElement(this.props.Footer, {trackerId: Constants.trackerPrefix + 'signup-contact-info'})
                    )
                )
            )
        );
    },

    onSaveContactInfo: function() {
        var me = this,
            userType = CurrentUser.getType(),
            API = me.context.router.getCurrentQuery().API,
            entity = CurrentUser.getEntity().toJSON();

        CurrentUser.intercomLogin();

        if (userType === 'Agent' || userType === 'Broker') {
            me.createDefaultWidget().then(function(widget) {
                // me.transitionTo(Constants.signupAgentRedirect || 'SignupWidget', {
                //     id: widget.id
                // }, API ? {
                //     API: API
                // } : undefined);
                if (Constants.isToolbox) {
                    me.transitionTo(
                        'Dashboard',
                        undefined,
                        API
                            ? {
                                  API: API
                              }
                            : undefined
                    );
                } else {
                    me.transitionTo(
                        'SignupUserMembership',
                        undefined,
                        API
                            ? {
                                  API: API
                              }
                            : undefined
                    );
                }
            });
        } else if (userType === 'Builder' || userType === 'Developer') {
            me.transitionTo(
                'SignupImage',
                undefined,
                API
                    ? {
                          API: API
                      }
                    : undefined
            );
        } else {
            me.transitionTo(
                'Dashboard',
                undefined,
                API
                    ? {
                          API: API
                      }
                    : undefined
            );
        }
    },

    createDefaultWidget: function() {
        var entity = CurrentUser.getEntity().toJSON(),
            widget = new WidgetModel();

        return widget.save(
            {},
            {
                createDefaultWidget: true,
                agentId: entity.id,
                validate: false
            }
        );
    }
});
module.exports = UserCreate;
