"use strict";

var Backbone = require('backbone'),
    ModelClass = require('./Model'),
    Constants = require('../constants'),
    CurrentUser = require('../shared/CurrentUser'),
    _ = require('underscore');


var DevelopmentEmail = ModelClass.extend({
    idAttribute: 'id',
    url: Constants.restPath + '/mail/project',
    validation: {
        "to": {
            required: true,
            pattern: 'email'
        },
        "subject": {
            required: true
        },
        "message": {
            required: true
        }
    },
    labels: {
        to: 'Receipient Email'
    },
    defaults: function() {
        return {
            to: '',
            subject: 'New Development',
            message: [
                'I think you might want to take a look at this new development...',
                '',
                'Sincerely,',
                CurrentUser.agentModel.getDisplayName()
            ].join('\r\n')
        };
    },
    fetch: function() {},
    sync: function(method, model, options) {
        options = options || {};
        options.method = 'POST';
        options.contentType = 'application/x-www-form-urlencoded';
        options.data = Backbone.$.param(this.toJSON());
        return ModelClass.prototype.sync.call(this, method, model, options);
    }
});

module.exports = DevelopmentEmail;
