"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    Link = ReactRouter.Link,
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    Constants = require('../../constants'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    NavLink = require('../../components/NavLink'),
    Cmp = require('../../components'),
    SidebarNav = require('../../partials/SidebarNav'),
    FluxBone = require('../../mixins/FluxBone'),
    Agents = require('../../collections/Agents'),
    CurrentUser = require('../../shared/CurrentUser'),
    Validation = require('../../partials/validation'),
    Pagination = require('../../partials/Pagination'),
    DeleteDialog = require('../../partials/DeleteDialog'),
    Roles = require('../../mixins/Roles'),
    toastr = require('toastr');

var AgentRow = React.createClass({displayName: "AgentRow",
    mixins: [
        FluxBone.ModelMixin('model', 'sync reset'),
        Roles
    ],
    getDefaultProps: function() {
        return {
            model: undefined
        };
    },
    updateState: function(props) {
        props = props || this.props;
        return {
            editing: false,
            defaultWidget: props.model.get('defaultBrokerWidget')
        };
    },
    getInitialState: function() {
        var state = this.updateState();
        state.editing = false;
        return state;
    },
    componentWillReceiveProps: function(props) {
        this.setState(this.updateState(props));
    },
    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            data = props.model.toJSON(),
            isAdmin = me.hasOneRole(Constants.roles.ADMIN),
            email = props.model.contactInfoModel.get('email');

        return (
            React.createElement("tr", {className: state.editing ? 'editing-row' : ''}, 
                props.admin ? (
                    React.createElement("td", null, React.createElement(Link, {to: "AgentEdit", params: { id: data.id}}, data.fullName || 'None'))
                ) : (
                    React.createElement("td", null, data.fullName)
                ), 
                React.createElement("td", null, data.contactInfo.email), 
                React.createElement("td", null, data.contactInfo.city && data.contactInfo.city.id ? (data.contactInfo.city.name + ', ' + data.contactInfo.city.state.name) : ''), 
                React.createElement("td", null, 
                    state.editing ? (
                        React.createElement("div", {className: "table-form"}, 
                            React.createElement(Cmp.Select.Widget, {ref: "widgetSelect", style: {width: '100px'}, defaultText: "Broker Default", value: state.defaultWidget ? state.defaultWidget.id : undefined, agent: props.model.brokerModel, name: "defaultWidget", label: "", onChange: me.updateDefaultWidget}), 
                            React.createElement("i", {className: "fa fa-2x fa-times column-action table-field-cancel", onClick: me.cancel})
                        )
                    ) : (
                        React.createElement("div", null, 
                            state.defaultWidget ? state.defaultWidget.name : 'Broker Default', " ", React.createElement("i", {className: "fa fa-pencil column-action", onClick: me.edit})
                        )
                    )
                ), 
                React.createElement("td", null, 
                    React.createElement("a", {href: "#", title: "Remove Agent", onClick: me.onDelete}, React.createElement("i", {className: "fa fa-fw fa-trash-o"})), " ", 
                    isAdmin && email && (React.createElement("a", {href: "#", onClick: me.impersonateUser.bind(null, props.model)}, React.createElement("i", {className: "fa fa-eye"})))
                )
            )
        );
    },

    impersonateUser: function(model, e) {
        e.preventDefault();
        CurrentUser.impersonateUser(model.contactInfoModel.get('email'));
    },

    edit: function() {
        this.setState({
            editing: true
        });
    },

    cancel: function() {
        this.setState({
            editing: false
        });
    },

    onDelete: function(e) {
        e.preventDefault();
        if (this.props.onDelete) {
            this.props.onDelete(e);
        }
    },

    updateDefaultWidget: function(e) {
        var me = this,
            model = me.props.model,
            oldWidgetId = model.get('defaultBrokerWidget'),
            widgetId = e.target.value,
            widgetName = widgetId ? this.refs.widgetSelect.getWidgetById(widgetId).get('name') : 'Broker Default';

        me.setState({
            defaultWidget: {
                id: widgetId || undefined,
                name: widgetName
            }
        });

        model.save({
            defaultBrokerWidget: widgetId ? {
                id: widgetId || undefined,
                name: widgetName
            } : {}
        }, {
            fields: ['defaultBrokerWidget'],
            validate: false
        }).then(function() {
            console.log('change successful', oldWidgetId, widgetId);
        }).fail(function() {
            me.setState({
                defaultWidget: oldWidgetId
            });
            model.set({
                defaultWidget: oldWidgetId
            });
        });
    }
});

var Agents = React.createClass({displayName: "Agents",

    mixins: [
        FluxBone.CollectionMixin('store', 'sync'),
        React.addons.LinkedStateMixin,
        ReactRouter.Navigation,
        Roles
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            store: new Agents()
        };
    },

    updateState: function() {
        var query = this.context.router.getCurrentQuery(),
            store = this.props.store,
            currentUserEntity = CurrentUser.getEntity();

        store.pageNum = query.page || 1;
        store.setFilters(query, {
            resetFilters: true
        });

        store.broker_id = CurrentUser.getType() === 'Agent' && currentUserEntity.get('isBroker') ? currentUserEntity.id : undefined;
        store.throttledFetch();

        return store.getFilterValues();
    },

    getInitialState: function() {
        var state = this.updateState();

        state.filtersVisible = true;

        return state;
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    toggleFilters: function(e) {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        this.setState({
            filtersVisible: !this.state.filtersVisible
        });
    },

    doSearch: function(e) {
        var me = this,
            query = {
                page: 1
            },
            state = me.state;

        if (e && e.preventDefault) {
            e.preventDefault();
        }

        _.each(me.props.store.filters, function(filter) {
            var value;

            if(filter !== 'orderBy') {
                value = state[filter];
            } else if(state.orderBy && state.dir) {
                value = [state.orderBy, state.dir].join(' ');
            }

            query[filter] = value;
        });

        this.transitionTo('AgentList', undefined, query);
    },

    render: function() {
        var me = this,
            state = me.state,
            props = me.props,
            isAdmin = me.hasOneRole(Constants.roles.ADMIN),
            isBroker = me.isBroker(),
            filterHeader = (
                React.createElement("div", null, 
                    React.createElement("h4", {className: "panel-title"}, 
                        "Filters:", 
                        isAdmin && (
                            React.createElement("div", {className: "pull-right", title: "Toggle Filter Visibility"}, 
                                React.createElement("i", {className: 'fa fa-fw ' + (state.filtersVisible ? 'fa-minus' : 'fa-plus')})
                            )
                        )
                    )
                )
            ),
            expanded = state.filtersVisible,
            titleText = isBroker ? 'My Agents' : 'Agents',
            filterValues = props.store.getFilterValues(),
            // orderBy = state.orderBy ? state.orderBy.split(' ') : [];4
            orderBy = filterValues.orderBy,
            dir = filterValues.dir;

        return (
            React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: "NCH - Agents Listing"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Agents"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(NavLink, {to: "AgentList"}, "Agents")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement("div", {className: "row half-gutter"}, 
                        React.createElement("div", {className: "col-md-3 md-margin-bottom-40"}, 
                            React.createElement(SidebarNav, null)
                        ), 
                        React.createElement("div", {className: "col-md-9"}, 
                            React.createElement("div", {className: "row half-gutter"}, 
                                React.createElement("div", {className: "col-xs-6"}, 
                                    React.createElement("h1", null, titleText)
                                ), 
                                React.createElement("div", {className: "col-xs-6"}, 
                                    isAdmin && (
                                        React.createElement(Link, {to: "AgentAdd", className: "btn btn-default pull-right"}, React.createElement("i", {className: "fa fa-plus"}), " Add Agent")
                                    ), 
                                    isBroker && (
                                        React.createElement(Link, {to: "BrokerAgentAdd", className: "btn btn-default pull-right"}, React.createElement("i", {className: "fa fa-plus"}), " Add Agent")
                                    )
                                )
                            ), 
                            React.createElement("div", {className: "row half-gutter"}, 
                                React.createElement("div", {className: "col-xs-12"}, 
                                    React.createElement("p", null, "These are the agents in your brokerage account. The agents are added to your account through a data feed. Please contact us if there are any agents missing.")
                                    /* <p><a><i className="fa fa-question-circle blue-text"> CLICK HERE</i></a> for more information about the My Agents functionality.</p> */
                                )
                            ), 

                            isAdmin && (
                                React.createElement(BS.Panel, {className: 'panel-table' + (expanded ? 'panel-table-expanded' : ''), collapsible: true, header: filterHeader, expanded: expanded, onSelect: me.toggleFilters}, 
                                    React.createElement("div", {className: "row"}, 
                                        React.createElement("form", {onSubmit: me.doSearch}, 
                                            React.createElement(Validation.Input, {type: "text", valueLink: me.linkState('agentName'), groupClassName: "col-sm-6", placeholder: "Search by Name"}), 
                                            React.createElement(Validation.Input, {type: "text", valueLink: me.linkState('email'), groupClassName: "col-sm-6", placeholder: "Search by Email"}), 
                                            React.createElement(Validation.Input, {type: "text", valueLink: me.linkState('brokerName'), groupClassName: "col-sm-6", placeholder: "Search by Broker Name"}), 
                                            React.createElement(Validation.Input, {type: "text", valueLink: me.linkState('brokerageName'), groupClassName: "col-sm-6", placeholder: "Search by Brokerage Name"}), 
                                            React.createElement(Cmp.Select.Country, {name: "country", valueLink: me.linkState('country'), groupClassName: "col-sm-3"}), 
                                            React.createElement(Cmp.Select.State, {name: "state", valueLink: me.linkState('state'), countryId: state.country, groupClassName: "col-sm-3"}), 
                                            React.createElement(Cmp.Select.Metro, {name: "metro_area", valueLink: me.linkState('metro_area'), stateId: state.state, groupClassName: "col-sm-3"}), 
                                            React.createElement(Cmp.Select.City, {name: "city", valueLink: me.linkState('city'), stateId: state.state, metroId: state.metro_area, groupClassName: "col-sm-3"}), 
                                            React.createElement("div", {className: "col-xs-12"}, 
                                                React.createElement(BS.Button, {className: "pull-right", type: "submit"}, "Search")
                                            )
                                        )
                                    )
                                )
                            ), 
                            isBroker && (
                                React.createElement(BS.Panel, {className: 'panel-table' + (expanded ? 'panel-table-expanded' : ''), collapsible: false, header: filterHeader, onSelect: me.toggleFilters}, 
                                    React.createElement("div", {className: "row"}, 
                                        React.createElement("form", {onSubmit: me.doSearch}, 
                                            React.createElement(Validation.Input, {type: "text", valueLink: me.linkState('agentName'), groupClassName: "col-xs-12 col-sm-10", placeholder: "Search by Name"}), 
                                            React.createElement("div", {className: "col-xs-12 col-sm-2"}, 
                                                React.createElement(BS.Button, {className: "pull-right", type: "submit"}, "Search")
                                            )
                                        )
                                    )
                                )
                            ), 
                            React.createElement(Pagination, {store: props.store, to: "AgentList", query: filterValues, showTotal: true}, 
                                React.createElement(Validation.Input, {type: "select", groupClassName: "pull-right margin-0", value: dir, onChange: me.setFilter.bind(null, 'dir')}, 
                                    React.createElement("option", {value: "asc"}, "ASC"), 
                                    React.createElement("option", {value: "desc"}, "DESC")
                                ), 
                                React.createElement(Validation.Input, {type: "select", groupClassName: "pull-right margin-0 margin-right-10", value: orderBy, onChange: me.setFilter.bind(null, 'orderBy')}, 
                                    React.createElement("option", {value: ""}, "Arrange By"), 
                                    React.createElement("option", {value: "fullName"}, "Name"), 
                                    React.createElement("option", {value: "contactInfo.email"}, "Email Address")
                                )
                            ), 
                            React.createElement(BS.Table, {striped: true, bordered: true, condensed: true, hover: true, id: "agents-table"}, 
                                React.createElement("thead", null, 
                                    React.createElement("tr", null, 
                                        React.createElement("th", null, "Name"), 
                                        React.createElement("th", null, "Email"), 
                                        React.createElement("th", null, "Location"), 
                                        React.createElement("th", null, "Default Widget"), 
                                        React.createElement("th", null, "Actions")
                                    )
                                ), 
                                React.createElement("tbody", null, 
                                props.store.map(function(agent){
                                    var data = agent.toJSON();

                                    return (
                                        React.createElement(AgentRow, {key: agent.id, model: agent, admin: isAdmin, broker: isBroker, onDelete: me.onDelete.bind(null, agent)})
                                    );
                                })
                                )
                            ), 
                            React.createElement(Pagination, {store: props.store, to: "AgentList", noMargin: true, query: filterValues, showTotal: true}), 
                            React.createElement(DeleteDialog, {
                                msg: "Please confirm that you would like to remove agent,", 
                                title: "ALERT! You are about to REMOVE an agent.", 
                                deleteLabel: "Remove Agent", 
                                modelToDelete: state.modelToDelete, 
                                onHide: me.onHideDeleteDialog, 
                                deleteMethod: "removeBroker", 
                                modelTitle: state.modelToDelete && state.modelToDelete.toJSON().name, 
                                onDelete: me.afterDelete})
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    },

    setFilter: function(filterName, e) {
        var me = this,
            props = me.props,
            oldOrderBy = me.state.orderBy || '',
            oldDir = me.state.dir || 'ASC',
            orderBy, dir;

        if (filterName === 'orderBy') {
            orderBy = e.target.value;
            dir = oldDir;
        } else if (filterName === 'dir') {
            dir = e.target.value;
            orderBy = oldOrderBy;
        }

        if (oldOrderBy !== orderBy || oldDir !== dir) {
            me.setState({
                orderBy: orderBy,
                dir: dir || 'ASC'
            }, function() {
                if (orderBy || dir) {
                    me.doSearch();
                }
            });
        }
    },

    onDelete: function(model) {
        this.setState({
            modelToDelete: model
        });
    },

    afterDelete: function(model) {
        var name = model.toJSON().name;

        toastr.success('Success! <br/> Your agent, ' + name + ', has been successfully removed.');

        this.props.store.remove(model);
    },

    onHideDeleteDialog: function() {
        this.setState({
            modelToDelete: undefined
        });
    },
});

module.exports = Agents;
