"use strict";

var Constants = require('../constants'),
    LeadsCollection = require('../collections/Leads'),
    utility = require('../shared/Utilities'),
    UserEntity = require('./UserEntity'),
    BrokerModel = require('./Broker'),
    _ = require('underscore'),
    $ = require('jquery');

var Agent = UserEntity.extend({
    urlRoot: Constants.restPath + "/agents",
    idAttribute: 'id',
    hasMany: {
        LeadsCollection: 'leads'
    },
    hasOne: _.extend({
        Broker: {
            responseKey: 'broker',
            alwaysInclude: true
        }
    }, UserEntity.prototype.hasOne),

    imageUrl: function() {
        return this.url();
    },

    imageValidation: {
        profileImage: {
            required: true
        },
        "contactInfo.phoneMobile": {
            required: true
        }
    },

    validation: {
        website: {
            required: false
        },
        brokerageName: {
            required: true
        },

        "contactInfo.phoneWork": {
            required: true
        },

        "contactInfo.zip": {
            pattern: 'number',
            required: false
        },

        "contactInfo.firstName": {
            required: true,
            pattern: 'no_numbers'
        },

        "contactInfo.lastName": {
            required: true,
            pattern: 'no_numbers'
        },

        // "contactInfo": utility.locationValidationNoAddress,

        "teamName": {
            required: function(value, attributes, computedState) {
                if (computedState.nameToDisplay === 'TeamName' && !value) {
                    return "Team Name is required";
                }
            },
            pattern: 'no_numbers'
        },
        "crmEmail": {
            pattern: 'email',
            required: function(value, attributes, computedState) {
                if (computedState.crmType === 'REFINDLY' && !value) {
                    return true;
                }
            }
        },
        "crmUserKey": {
            required: function(value, attributes, computedState) {
                if (computedState.crmType === 'LIONDESK' && !value) {
                    return true;
                }
            }
        }
    },

    validationUses: {
        "teamName": ['nameToDisplay'],
        "crmEmail": ['crmType'],
        "crmUserKey": ['crmType']
    },

    labels: {
        "contactInfo.phoneWork": 'Phone',
        "contactInfo.phoneMobile": 'Phone',
        "contactInfo.firstName": 'First Name',
        "contactInfo.lastName": 'Last Name',
        "crmUserKey": 'CRM user key',
        "crmEmail": 'CRM email'
    },

    defaults: function() {
        return {
            id: undefined,
            website: '',
            howHeard: '',
            createDate: new Date(),
            brokerageName: '',
            nameToDisplay: '',
            teamName: '',
            user: {},
            agentLevel: 'FREE',
            crmType: 'DEFAULT'
        };
    },

    constructor: function() {
        LeadsCollection.prototype.model.Agent = Agent; // Dependency Injection
        return UserEntity.prototype.constructor.apply(this, arguments);
    },


    toJSON: function(options) {
        var data = UserEntity.prototype.toJSON.apply(this, arguments);

        options = options || {};

        if (!options.url) {
            data.name = this.getDisplayName();
        } else {
            if (!$.isEmptyObject(data.user)) {
                data.users = [data.user];
                delete data.user;
            }
        }

        return data;
    },


    getDisplayName: function(contactName) {
        var nameToDisplay = this.get('nameToDisplay'),
            contactInfo = this.contactInfoModel.toJSON(),
            name;

        if (contactName === true) {
            nameToDisplay = 'FirstAndLast';
        }


        switch (nameToDisplay) {
            case 'Brokerage':
                name = this.get('brokerageName');
                break;
            case 'TeamName':
                name = this.get('teamName');
                break;

            case 'FirstAndLast':
            default:
                name = this.get('fullName') || (contactInfo ? (contactInfo.firstName + ' ' + contactInfo.lastName) : '');
                break;
        }

        return name;
    },

    fetch: function(options) {
        options = options || {};
        options.data = options.data || {};

        if (options.agentEmail) {
            if (this.usingJsonP) {
                options.url = Constants.restPath + '/jsonp/agents/by-email';
                options.dataType = 'jsonp';
            } else {
                options.url = this.urlRoot + '/by-email';
            }
            options.data.email = options.agentEmail;
        } else if (options.username) {
            if (this.usingJsonP) {
                options.url = Constants.restPath + '/jsonp/agents/by-username';
                options.dataType = 'jsonp';
            } else {
                options.url = this.urlRoot + '/by-username';
            }
            options.data.username = options.username;
            delete options.username;
        }

        return UserEntity.prototype.fetch.call(this, options);
    },
    save: function() {
        var users = this.get('users'),
            email = this.contactInfoModel.get('email');

        users && users.length == 1 && (users[0].email = email); //only if single user is attached


        return UserEntity.prototype.save.apply(this, arguments);
    },

    setSubscription: function(level) {
        this.set({
            agentLevel: level
        }, {
            commit: true
        });
    },

    parse: function(response) {
        var data = UserEntity.prototype.parse.apply(this, arguments);

        if (data && data.agentLevel) {
            data.defaultAgentLevel = data.agentLevel;
        }

        if (data) {
            data.defaultCrmType = data.crmType;
        }

        return data;
    },

    removeBroker: function() {
        return this.save({
            broker: {}
        }, {
            fields: ['broker'],
            validate: false
        });
    },
    isReadOnly: function() {
        return this.get('isEditable') === false;
    }
}, {
    LeadsCollection: LeadsCollection,
    Broker: BrokerModel
});

module.exports = Agent;
