var React = require('react'),
    $ = require('jquery'),
    cx = require('classnames'),
    BS = require('react-bootstrap');


var RequiredMessage = React.createClass({displayName: "RequiredMessage",

    render: function() {
        return (
            React.createElement("p", {className: cx("has-error", this.props.className)}, React.createElement("span", {className: "help-block"}, "** are required fields"))
        );
    }
});

module.exports = RequiredMessage;

