"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    Constants = require('../../../constants'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    PageHeader = require('../../../partials/PageHeader'),
    DevelopmentEmail = require('../../../models/DevelopmentEmail'),
    PageFooter = require('../../../partials/PageFooter'),
    SidebarNav = require('../../../partials/SidebarNav'),
    Cmp = require('../../../components'),
    Validation = require('../../../partials/validation');

var EmailDevelopments = React.createClass({displayName: "EmailDevelopments",

    mixins: [
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var developmentIds = this.context.router.getCurrentQuery().developmentIds;

        if(developmentIds){
            developmentIds = developmentIds.split(',');
        } else {
            developmentIds = [];
        }

        var state = {
            developmentIds: developmentIds,
            success: false
        };

        this.props.model.clear();

        return state;
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function(){
        return {
            model: new DevelopmentEmail()
        }
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            query = {
                developmentIds: this.state.developmentIds.join(',')
            };

        return (
            React.createElement("div", {className: "wrapper development-selector"}, 
                React.createElement(DocumentTitle, {title: "NCH - Email Developments"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Email Developments: Step 2 - Send Email"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(Cmp.NavLink, {to: "Dashboard"}, "Dashboard"), 
                            React.createElement(Cmp.NavLink, {to: "EmailToolsSelectDevelopments", query: query}, "Select Developments"), 
                            React.createElement(Cmp.NavLink, {to: "EmailToolsEmail", query: query}, "Email")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    state.success ? (
                        React.createElement("div", null, 
                            React.createElement(BS.Alert, {bsStyle: "success"}, 
                                "Your message has been sent"
                            ), 

                            React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                                React.createElement(Cmp.Button.Save, {to: "Dashboard"}, "Back to Dashboard"), 
                                React.createElement("span", {className: "pull-left btn-divider"}, "or"), 
                                React.createElement(BS.Button, {bsStyle: "link", onClick: this.reload}, "Send another email with the same developments")
                            )
                        )

                    ) : (
                        React.createElement(Validation.Form, {ref: "form", model: props.model, horizontal: false, onSubmit: this.onSaveClick}, 

                            React.createElement(Validation.Input, {type: "text", name: "to", label: "Receipient's Email:"}), 
                            React.createElement(Validation.Input, {type: "text", name: "subject", label: "Email Subject:"}), 
                            React.createElement(Validation.Input, {type: "textarea", rows: "5", name: "message", label: "Message:"}), 

                            React.createElement(Cmp.ValidationMessage, null), 
                            React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                                React.createElement(Cmp.Button.Save, {disabled: true}, "Email Developments"), 
                                React.createElement(Cmp.Button.Cancel, {onClick: this.onCancelClick}, "Back")
                            )
                        )
                    )
                )
            )
        );
    },

    onSaveClick: function(){
        var me = this;
        this.props.model.set('projects', this.state.developmentIds);
        this.props.model.save(this.refs.form.getValues()).then(function(){
            me.setState({
                success: true
            });
        });
    },

    onCancelClick: function(){
        this.transitionTo('EmailToolsSelectDevelopments', undefined, {
            developmentIds: this.state.developmentIds.join(',')
        });
    },

    reload: function(){
        this.setState(this.updateState());
    }
});
module.exports = EmailDevelopments;