"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    DocumentTitle = require('react-document-title'),
    BS = require('react-bootstrap'),
    Cmp = require('../../../components'),
    PageHeader = require('../../../partials/PageHeader'),
    PageFooter = require('../../../partials/PageFooter'),
    Validation = require('../../../partials/validation'),
    DeleteDialog = require('../../../partials/DeleteDialog'),
    RequiredMessage = require('../../../partials/RequiredMessage'),
    ProjectsMap = require('../../../partials/project/ProjectsMap');

var ProjectWizardDetails = React.createClass({displayName: "ProjectWizardDetails",

    mixins: [
        ReactRouter.Navigation
    ],

    statics: {
        willTransitionFrom: function(transition, me, callback) {
            var continueAnyways;

            if (me && me.refs && me.refs.form && me.refs.form.isDirty()) {
                continueAnyways = window.confirm(me.refs.form.getDirtyMessage());
                if (continueAnyways) {
                    callback();
                } else {
                    transition.cancel();
                    me.goBack();
                }
            } else {
                callback();
            }
        }
    },

    contextTypes: {
        router: React.PropTypes.func
    },

    getInitialState: function() {
        return {};
    },

    render: function() {
        var me = this,
            props = me.props,
            model = props.model,
            state = me.state,
            defaultControlAttr = {
                type: 'text',
                labelClassName: 'col-md-3',
                wrapperClassName: 'col-md-9'
            };

        return (
            React.createElement("div", {className: "wrapper widgets"}, 
                React.createElement(DocumentTitle, {title: "NCH - Create Listing"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Create Listing - Step 1: Add Project Info"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(Cmp.NavLink, {to: "ProjectList"}, "My Listings"), 
                            React.createElement(Cmp.NavLink, {to: model.id ? 'ProjectWizardDetails' : 'ProjectWizardAdd', params: { id: model.id}}, "Listing information")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 

                     React.createElement(Validation.Form, {ref: "form", model: model, className: "form form-horizontal", onSubmit: this.onSaveClick}, 
                        React.createElement("h4", null, "Project Information: ", React.createElement("small", null, "Edit details about ths project.")), 
                        React.createElement(RequiredMessage, null), 

                        React.createElement(Validation.Input, React.__spread({name: "realName", label: "Project Name:"},  defaultControlAttr)), 
                        React.createElement(Validation.Input, React.__spread({name: "website", label: "Project URL:"},  defaultControlAttr)), 
                        React.createElement(Cmp.Select.Listing, {name: "listingTypes"}), 
                        React.createElement(Validation.Input, React.__spread({name: "minPrice", label: "Starting Price (USD):"},  defaultControlAttr)), 
                        React.createElement(Validation.Input, React.__spread({name: "maxPrice", label: "Max Price (USD):"},  defaultControlAttr)), 
                        React.createElement(Validation.Input, React.__spread({name: "numberOfUnits", label: "Number of Units:"},  defaultControlAttr)), 
                        React.createElement(Validation.Input, React.__spread({name: "numberOfFloors", label: "Number of Floors:"},  defaultControlAttr)), 
                        React.createElement(Validation.Input, React.__spread({name: "developmentPhase", label: "Construction Phase:"},  defaultControlAttr, {type: "select"}), 
                            React.createElement("option", {value: ""}, "Select"), 
                            React.createElement("option", {value: "Pre-Construction"}, "Pre-Construction"), 
                            React.createElement("option", {value: "Under Construction"}, "Under Construction"), 
                            React.createElement("option", {value: "Completed"}, "Completed")
                        ), 
                        React.createElement(Validation.Input, React.__spread({name: "summary", rows: "5", label: "Project Summary:"},  defaultControlAttr, {type: "textarea"})), 
                        React.createElement(Validation.Input, React.__spread({name: "description", rows: "5", label: "Project Description:"},  defaultControlAttr, {type: "textarea"})), 

                        React.createElement("h4", null, "Project Location: ", React.createElement("small", null, "Where is this project located? NOTE: NOT sales center location.")), 
                        React.createElement("div", {className: "col-xs-12"}, 
                            React.createElement(Validation.Input, {type: "checkbox", trigger: "onChange", ignoreChange: true, useCheckedLink: true, name: "customLocation", label: "This community does not have an address yet."})
                        ), 
                        React.createElement("p", {key: "addressLabelCustom", formLinks: { "aria-hidden": this.shouldShowAddressLabel.bind(null, true)}}, "Enter the address of your community"), 
                        React.createElement("p", {key: "addressLabel", formLinks: { "aria-hidden": this.shouldShowAddressLabel.bind(null, false)}}, "Begin by entering as much of the location as you know, like city, state, neighborhood, etc"), 
                        React.createElement(Cmp.LocationSelect, React.__spread({ref: "locationInfo", name: "locationInfo", label: "Community Address:"},  defaultControlAttr, {fieldLinks: { geoLink: 'geoPosition'}})), 
                        /* <Validation.Input ref="address1" name="locationInfo.address1" label="Address:" {...defaultControlAttr}/> */
                        React.createElement(Validation.Input, React.__spread({name: "locationInfo.address2", label: "Unit / Suite:"},  defaultControlAttr, {formLinks: { "hidden": 'model.customLocation'}})), 
                        React.createElement("div", {key: "mapWrap", className: "form-group", formLinks: { "aria-hidden": '!model.customLocation'}}, 
                            React.createElement("div", {className: "col-xs-12"}, 
                                React.createElement("p", null, "Drag the map so the marker is placed where your community is located.")
                            ), 
                            React.createElement("div", {className: "col-xs-12"}, 
                                React.createElement(ProjectsMap, {ref: "map", name: "map", markCenter: true, className: "margin-bottom-15", 
                                    fieldLinks: { 
                                        latitudeLink: 'geoPosition.latitude', 
                                        longitudeLink: 'geoPosition.longitude', 
                                        locationLink: 'locationInfo'
                                    }, 
                                    formLinks: { 
                                        "aria-hidden": '!model.customLocation', 
                                        zoom: this.getMapZoom, 
                                        geoQuery: this.getGeoQueryFormLink, 
                                        defaultGeoPosition: this.getDefaultGeoPosition,
                                        readOnly: '!model.customLocation', 
                                        disabled: '!model.customLocation', 
                                        updateLocation: '!!model.customLocation'
                                    }, id: "project-add-edit-details-map", style: { height: '300px', width: '100%'}}), 
                                React.createElement("input", {name: "geoPosition.latitude", type: "hidden", float: true, precision: 4}), 
                                React.createElement("input", {name: "geoPosition.longitude", type: "hidden", float: true, precision: 4})
                            )
                        ), 
                        React.createElement(Cmp.ValidationMessage, {action: "Next"}), 
                        React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                            React.createElement(Cmp.Button.Save, {className: "pull-right"}, "Next ", React.createElement("i", {className: "fa fa-arrow-right"})), 
                            React.createElement(Cmp.Button.Cancel, {onClick: this.onCancelClick})
                        )
                    ), 
                     React.createElement(DeleteDialog, {ref: "deleteDialog", modelToDelete: state.modelToDelete, onHide: me.onHideDeleteDialog, modelTitle: state.modelToDelete?state.modelToDelete.get('name'):'', onDelete: me.gotoList})
                ), 
                React.createElement(PageFooter, null)
            )
        );
    },

    shouldShowAddressLabel: function(customLocation, state) {
        var show = false;
        if (state.model && state.model.customLocation && customLocation) {
            show = true;
        } else if (!customLocation && (!state.model || !state.model.customLocation)) {
            show = true;
        }
        return show;
    },

    getMapZoom: function(state) {
        var zoom = 10;

        // if(state.customLocation || this.props.model.get('geoPosition')){
        //     zoom = 10;
        // }

        return zoom;
    },

    getGeoQueryFormLink: function(formState) {
        var me = this,
            refs = me.refs,
            form = refs.form,
            model = me.props.model,
            modelState = formState.model,
            locationInfo = model.locationInfoModel,
            geoPosition = model.get('geoPosition'),
            value;

        if (modelState && modelState.customLocation && (modelState.locationInfo.address1 !== locationInfo.get('address1') || modelState.geoPosition.latitude !== geoPosition.latitude || modelState.geoPosition.longitude !== geoPosition.longitude) && refs.locationInfo) {
            value = refs.locationInfo.getDisplayValue() || undefined;
        }

        return value;
    },

    getDefaultGeoPosition: function(formState) {
        return this.props.model.get('geoPosition') || {};
    },


    onSaveClick: function(e) {
        var me = this,
            model = me.props.model;

        model.save(me.refs.form.getValues()).then(function() {
            model.fetch().then(me.gotoSteps);
        });
    },

    onCancelClick: function() {
        if (!!this.props.model.id) {
            this.setState({
                modelToDelete: this.props.model
            });
        } else {
            this.gotoList();
        }
    },

    onHideDeleteDialog: function() {
        this.setState({
            modelToDelete: undefined
        });
    },

    gotoSteps: function() {
        this.transitionTo('ProjectWizardWidgetImage', {
            id: this.props.model.id
        });
    },

    gotoList: function() {
        this.transitionTo('ProjectList');
    }
});
module.exports = ProjectWizardDetails;
