"use strict";

var React = require('react'),
    Cmp = require('../../components'),
    Constants = require('../../constants'),
    URI = require('urijs');

var ToolboxFooter = React.createClass({displayName: "ToolboxFooter",
    getDefaultProps: function(){
        return {
            trackerId: 'agent-toolbox'
        };
    },

    render: function() {
        var me = this,
            props = me.props;

        return (
            React.createElement("div", {className: "row"}, 
                React.createElement("div", {className: "col-xs-12"}, 
                    React.createElement(Cmp.Tracker, {params: { type: props.trackerId}}), 
                    React.createElement("div", {className: "pull-right logo-small"}, 
                        React.createElement("a", {href: URI(Constants.publicSite).addSearch({ source: 'amwlogo' }), target: "_blank"}, 
                            React.createElement("img", {src: Constants.imageRoot + 'NCH_Logo_PoweredBy_162x25.png'})
                        )
                    )
                )
            )
        );
    }
});

module.exports = ToolboxFooter;
