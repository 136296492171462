"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    _ = require('underscore'),
    Constants = require('../../../constants'),
    CurrentUser = require('../../../shared/CurrentUser'),
    DocumentTitle = require('react-document-title'),
    BS = require('react-bootstrap'),
    FluxBone = require('../../../mixins/FluxBone'),
    Cmp = require('../../../components'),
    Validation = require('../../../partials/validation'),
    $ = require('jquery'),
    Link = ReactRouter.Link;

var ProfileImage = React.createClass({displayName: "ProfileImage",

    contextTypes: {
        router: React.PropTypes.func
    },

    mixins: [
        ReactRouter.Navigation
    ],

    getInitialState: function() {
        return {};
    },

    getDefaultProps: function() {
        return {};
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.model,
            entity = model.getEntity(),
            image = entity.get('profileImage');

        if(image) {
            image = image + "?rand=" + (Math.random() * 1000);
        } else {
            image = Constants.placeholder;
        }

        return (
            React.createElement("div", null, 
                React.createElement("div", {className: "row"}, 
                    React.createElement("div", {className: "col-xs-12"}, 
                        React.createElement("h2", null, "My Profile Image"), 
                        React.createElement("p", null, "Add your brand and a personal touch by including a photo of yourself"), 
                        entity && (
                            React.createElement("div", {className: "row"}, 
                                React.createElement("div", {className: "col-xs-3"}, 
                                    React.createElement("div", {className: "profile-image"}, 
                                        /* <div className="profile-image-inner" style={{ "backgroundImage": "url(" + entity.get('profileImage') + "?rand="+ (Math.random() * 1000) +")" }}/> */
                                        React.createElement("img", {src: image, style: { maxWidth: '100%'}})
                                    )
                                ), 
                                React.createElement("div", {className: "col-xs-9"}, 
                                    React.createElement(Validation.Form, {ref: "form", horizontal: false, model: entity, onSubmit: this.onSubmit, validateOnLoad: false, skipValidation: true}, 
                                        React.createElement("div", {className: "text-center"}, 
                                            React.createElement("h3", {className: "blue-text"}, "Photo should be 150 pixels wide by 200 pixels high."), 
                                            React.createElement("p", null, "All other sizes will automatically be cropped.")
                                        ), 
                                        React.createElement(Cmp.Picker.File, {ref: "filePicker", triggerSync: true, groupClassName: "margin-top-20", trigger: "onChange", name: "image", url: _.result(entity, 'url'), label: "", model: entity, onComplete: this.onFileUpload, mime_types: [Constants.imageMimeTypes]}), 
                                        React.createElement(Cmp.ValidationMessage, null), 
                                        React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                                            React.createElement(BS.Button, {className: "pull-left", bsStyle: "warning", onClick: this.clearImage}, "Clear"), 
                                            React.createElement(Cmp.Button.Save, {className: "pull-right", label: "Save"})
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) 
        );
    },

    onSubmit: function() {
        var me = this,
            props = me.props,
            model = props.model,
            filePicker = me.refs.filePicker;

        if (filePicker.hasFiles()) {
            filePicker.startUpload();
        } else {
            this.onSave();
        }
    },

    onSave: function() {
        if (this.props.onSave) {
            this.props.onSave();
        } else if (this.props.backBtnTo) {
            this.transitionTo(this.props.backBtnTo, this.props.backBtnParams || this.context.router.getCurrentParams());
        }
    },

    onFileUpload: function(success) {
        var me = this;

        me.refs.filePicker.reset();
        me.props.model.getEntity().fetch().then(function() {
            me.forceUpdate();
            me.onSave();
        });
    },

    clearImage: function() {
        var entity = this.props.model.getEntity();
        entity.save({
            profileImage: ''
        }, {
            fields: ['profileImage'],
            validate: false
        });
        this.refs.filePicker.reset();
        this.forceUpdate();
    }
});
module.exports = ProfileImage;
