"use strict";

var ModelClass = require('./Model'),
    Backbone = require('backbone'),
    Constants = require('../constants');


var ContactAgentShield = ModelClass.extend({
    idAttribute: 'id',
    url: Constants.restPath + '/mail/contactagentshield',
    validation: {
        "name": {
            required: true
        },
        "phone": {
            required: true
        },
        "email": {
            required: true,
            pattern: 'email'
        },
        "message": {
            required: true
        }
    },
    defaults: function() {
        return {
            code : 'shield'
        };
    },
    fetch: function() {},
    sync: function(method, model, options) {
        options = options || {};
        options.method = 'POST';
        options.contentType = 'application/x-www-form-urlencoded';
        options.data = Backbone.$.param(this.toJSON());
        return ModelClass.prototype.sync.call(this, method, model, options);
    }
});

module.exports = ContactAgentShield;
