"use strict";

var React = require("react"),
  ReactRouter = require("react-router"),
  BS = require("react-bootstrap"),
  cx = require("classnames"),
  NavLink = require("../components/NavLink"),
  Link = require("../components/Link"),
  CurrentUser = require("../shared/CurrentUser"),
  EventListener = require("../mixins/event-listener"),
  Constants = require("../constants"),
  Roles = require("../mixins/Roles"),
  $ = require("jquery"),
  _ = require("underscore");

var PageHeader = React.createClass({displayName: "PageHeader",
  mixins: [
    ReactRouter.Navigation,
    EventListener("currentUser", "sync change:actualId", "onUserUpdate"),
    Roles
  ],

  contextTypes: {
    router: React.PropTypes.func
  },

  getDefaultProps: function() {
    return {
      currentUser: CurrentUser,
      isApp: true
    };
  },

  updateState: function(props) {
    var currentUser, entity;

    props = props || this.props;
    currentUser = props.currentUser;
    entity = currentUser.getEntity();

    return {
      isImpersonating: !!currentUser.get("actualId"),
      isFreeAgent:
        !Constants.isToolbox &&
        currentUser.getType() === "Agent" &&
        !entity.get("isBroker") &&
        entity.get("defaultAgentLevel") === "FREE"
    };
  },

  getInitialState: function() {
    return this.updateState();
  },

  isSignup: function() {
    var routes = this.context.router.getCurrentRoutes(),
      currentRoute = routes[routes.length - 1];
    return (
      this.props.isSignup ||
      (currentRoute.name !== "SignupUserMembership" &&
        currentRoute.name !== "SignupWidget" &&
        currentRoute.name !== "SignupWidgetHelp" &&
        !!_.find(routes, function(route) {
          return route.name === "Signup";
        }))
    );
  },

  onUserUpdate: function() {
    this.setState(this.updateState());
  },

  componentDidUpdate: function(prevProps, prevState) {
    this.handleBasicAgent(prevProps, prevState);
  },

  componentDidMount: function() {
    this.handleBasicAgent();
  },

  handleBasicAgent: function(prevProps, prevState) {
    var state = this.state;

    // Probably unnecessary. Looks like this logic is designed to generate the html for the link
    // <script charset="utf-8" src="https://js.hscta.net/cta/current.js"></script>
    // if (state.isBasicAgent && !state.isImpersonating && (!prevState || state.isBasicAgent !== prevState.isBasicAgent)) {
    //     try {
    //         hbspt.cta.load(729019, '6f06ae68-1cfc-421a-8d1a-eac840ba8f6f', {});
    //     } catch (e) {}
    // }
  },

  shouldHideAgentMessage: function() {
    var hideAgentMessage = this.props.hideAgentMessage,
      routes = this.context.router.getCurrentRoutes(),
      currentRoute = routes[routes.length - 1];

    return (
      hideAgentMessage ||
      currentRoute.name === "UserUpgradeAndBilling" ||
      currentRoute.name === "UserUpgradeAndBillingInfo" ||
      currentRoute.name === "UserMembershipOptions" ||
      currentRoute.name === "UserMembershipBillingInfo"
    );
  },

  render: function() {
    var me = this,
      props = me.props,
      state = me.state,
      isInSignup = this.isSignup(),
      currentUser = props.currentUser,
      isFreeAgent = state.isFreeAgent,
      loggedIn = !isInSignup && currentUser.isLoggedIn(),
      roles = Constants.roles,
      entity = currentUser.getEntity(),
      hideAgentMessage = me.shouldHideAgentMessage();

    return (
      React.createElement("div", {className: "header"}, 
        state.isImpersonating && (
          React.createElement("div", {className: "alert alert-info text-center"}, 
            React.createElement("strong", null, "Impersonating User ", currentUser.get("username"), "!"), " ", 
            React.createElement("a", {role: "button", onClick: this.revertImpersonation}, 
              "Click Here"
            ), " ", 
            "to revert."
          )
        ), 
        state.isFreeAgent && !hideAgentMessage && !isInSignup && (
          React.createElement("div", {
            className: "alert alert-info text-center", 
            style: { padding: "5px"}
          }, 
            React.createElement("i", {className: "fa fa-diamond"}), " You currently have a Free Agent" + ' ' +
            "Account.", " ", 
            React.createElement("a", {href: "http://cta-redirect.hubspot.com/cta/redirect/729019/6f06ae68-1cfc-421a-8d1a-eac840ba8f6f"}, 
              "Click Here"
            ), " ", 
            "to ", React.createElement("b", null, "learn more"), " about ", React.createElement("b", null, "Premium Agent"), " Accounts."
          )
        ), 
        React.createElement("div", {className: "container"}, 
          currentUser.isLoggedIn() && currentUser.isLoaded() ? (
            React.createElement(Link, {to: "Dashboard", className: "logo"}, 
              React.createElement("img", {src: Constants.imageRoot + "NCH_Logo_200x46-power-by.png"})
            )
          ) : (
            React.createElement("a", {href: Constants.publicSite, className: "logo"}, 
              React.createElement("img", {src: Constants.imageRoot + "NCH_Logo_200x46-power-by.png"})
            )
          ), 
          React.createElement("div", {className: "topbar"}, 
            loggedIn && (
              React.createElement("ul", {className: "loginbar pull-right"}, 
                currentUser.getType() === "Agent" &&
                  entity.get("defaultAgentLevel") === "PRO" &&
                  {
                    /*<NavLink href="tel:2399638535">
                      <i className="fa fa-phone blue-text" /> 239.963.8535 NCH_{" "}
                  </NavLink>*/
                  }, 
                currentUser.getType() === "Agent" &&
                  entity.get("defaultAgentLevel") === "PRO" &&
                  {
                    /*<NavLink className="devider" />*/
                  }, 
                /*<NavLink linkClassName="contact-link">
                  <i className="fa fa-envelope-o" /> Contact
                  </NavLink>
                <NavLink className="devider" />*/
                React.createElement(NavLink, {onClick: this.doLogout}, 
                  React.createElement("i", {className: "fa fa-lock"}), " Sign Out"
                )
              )
            ), 
            !loggedIn && this.props.isApp && (
              React.createElement("ul", {className: "loginbar pull-right"}, 
                React.createElement(NavLink, {linkClassName: "contact-link"}, 
                  React.createElement("i", {className: "fa fa-envelope-o blue-text"}), " Contact"
                ), 
                React.createElement(NavLink, {className: "devider"}), 
                React.createElement(NavLink, {linkClassName: "help-link"}, 
                  React.createElement("i", {className: "fa fa-question-circle blue-text"}), " Help"
                ), 
                React.createElement(NavLink, {className: "devider"}), 
                React.createElement(NavLink, {to: "Signup"}, 
                  React.createElement("i", {className: "fa fa-edit blue-text"}), " Sign Up"
                ), 
                React.createElement(NavLink, {className: "devider"}), 
                React.createElement(NavLink, {to: "Login"}, 
                  React.createElement("i", {className: "fa fa-user blue-text"}), " Login"
                )
              )
            ), 
            !loggedIn && !this.props.isApp && (
              React.createElement("ul", {className: "loginbar pull-right"}, 
                React.createElement(NavLink, {linkClassName: "contact-link"}, 
                  React.createElement("i", {className: "fa fa-envelope-o blue-text"}), " Contact"
                ), 
                React.createElement(NavLink, {className: "devider"}), 
                React.createElement(NavLink, {linkClassName: "help-link"}, 
                  React.createElement("i", {className: "fa fa-question-circle blue-text"}), " Help"
                ), 
                React.createElement(NavLink, {className: "devider"}), 
                React.createElement(NavLink, {href: "#"}, 
                  React.createElement("i", {className: "fa fa-edit blue-text"}), " Sign Up"
                )
              )
            )
          ), 
          React.createElement("button", {
            type: "button", 
            className: "navbar-toggle", 
            "data-toggle": "collapse", 
            "data-target": ".navbar-responsive-collapse"
          }, 
            React.createElement("span", {className: "sr-only"}, "Toggle navigation"), 
            React.createElement("span", {className: "fa fa-bars"})
          )
        ), 
        React.createElement("div", {className: "collapse navbar-collapse mega-menu navbar-responsive-collapse"}, 
          React.createElement("div", {className: "container"}, 
            React.createElement("ul", {className: "nav navbar-nav"}, 
              !loggedIn && (
                React.createElement(NavLink, {href: "http://www.agentshield.com", key: "home-link"}, 
                  "Home"
                )
              ), 
              /*
                            <li className="dropdown" key="agents-brokers-link">
                                <a className="dropdown-toggle" data-toggle="dropdown">For Agents &amp; Brokers</a>
                                    <ul className="dropdown-menu">
                                        <li><a href="forAgents.html">For Agents</a></li>
                                        <li><a href="forBrokers.html">For Brokerages</a></li>
                                    </ul>
                            </li>,
                            <li className="dropdown" key="builders-link">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">For Builders</a>
                                <ul className="dropdown-menu">
                                    <li><a href="forListings.html">New Construction Hub Listings</a></li>
                                    <li><a href="forDevelopers.html">Realtor Outreach Tools</a></li>
                                </ul>
                            </li>,
                            <NavLink key="contact-link">
                                Contact
                            </NavLink>
                            */
              loggedIn && !isFreeAgent && (
                React.createElement(NavLink, {to: "Dashboard"}, "Dashboard")
              ), 
              loggedIn &&
                !isFreeAgent &&
                this.hasOneRole(roles.DEVELOPER, roles.ADMIN) && (
                  React.createElement(NavLink, {to: "ProjectList", routes: Constants.routes.PROJECT}, 
                    "My Listings"
                  )
                ), 
              loggedIn &&
                !isFreeAgent &&
                this.hasOneRole(roles.AGENT, roles.BROKER) && [
                  React.createElement(NavLink, {key: "widgets-link", to: "Widgets"}, 
                    "My Widgets"
                  ),
                  React.createElement(NavLink, {key: "leads-link", to: "AgentLeads"}, 
                    "My Leads"
                  )
                ], 
              loggedIn &&
                !isFreeAgent &&
                this.hasOneRole(
                  roles.DEVELOPER,
                  roles.BUILDER,
                  roles.ADMIN
                ) && React.createElement(NavLink, {to: "Reports"}, "Reports"), 
              loggedIn && !isFreeAgent && (
                React.createElement(NavLink, {to: "UserAccount", routes: Constants.routes.USER}, 
                  "Edit Account"
                )
              ), 
              loggedIn && React.createElement(NavLink, {linkClassName: "help-link"}, "Help"), 
              loggedIn && this.hasOneRole() && (
                React.createElement(NavLink, {to: "Tips"}, "Tips")
              ), 
              loggedIn && this.hasOneRole() && (
                React.createElement(NavLink, {to: "Help"}, "Help")
              )
            )
          )
        )
      )
    );
  },

  doLogout: function() {
    var me = this;
    CurrentUser.logout().always(function() {
      me.transitionTo("Login");
    });
  },

  revertImpersonation: function() {
    var me = this;
    CurrentUser.revertImpersonation().then(function() {
      me.transitionTo("Dashboard");
    });
  }
});
module.exports = PageHeader;
