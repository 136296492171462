"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    PageHeader = require('../../../partials/PageHeader'),
    PageFooter = require('../../../partials/PageFooter'),
    FluxBone = require('../../../mixins/FluxBone'),
    CurrentUser = require('../../../shared/CurrentUser'),
    Cmp = require('../../../components'),
    RouteHandler = ReactRouter.RouteHandler,
    Roles = require('../../../mixins/Roles'),
    CreditCard = require('../../../partials/user/CreditCard'),
    Constants = require('../../../constants'),
    Link = ReactRouter.Link;

var AccountHandler = React.createClass({displayName: "AccountHandler",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset'),
        Roles
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function(props) {
        props = props || this.props;

        return {};
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        return {
            model: CurrentUser
        };
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    componentWillMount: function() {
        this.setState(this.updateState());
    },

    getBreadcrumbTitleForUserAccountContact: function() {
        return "My Contact Info";
    },
    getBreadcrumbTitleForUserAccountEdit: function() {
        return "Change Password";
    },
    getBreadcrumbTitleForAgentCrmIntegration: function() {
        return "CRM Integration";
    },
    getBreadcrumbTitleForUserAccountEmail: function() {
        return "My Email Address";
    },
    getBreadcrumbTitleForUserUpgradeAndBilling: function() {
        return "Upgrade & Billing";
    },
    getBreadcrumbTitleForUserUpgradeAndBillingInfo: function() {
        return "Payment Information";
    },
    getBreadcrumbTitleForUserMembershipBillingInfo: function() {
        return "Payment Information";
    },
    getBreadcrumbTitleForUserBilling: function() {
        return "Billing Info";
    },
    getBreadcrumbTitleForUserMembership: function() {
        var entity = this.props.model.getEntity(),
            defaultLevel = entity.get('defaultAgentLevel') || 'BASIC',
            level = entity.get('agentLevel') || 'BASIC';

        if (level !== defaultLevel) {
            return "Confirmation";
        } else {
            return "Premium Agent Settings";
        }
    },

    getBreadcrumbTitle: function() {
        var title = 'Edit Account',
            routes = this.context.router.getCurrentRoutes();

        for (var i = routes.length - 1; i > 0; i--) {
            if (this['getBreadcrumbTitleFor' + routes[i].name]) {
                title = this['getBreadcrumbTitleFor' + routes[i].name]();
                break;
            }
        }
        return title;
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.model,
            linkParam = {
                id: model.id
            },
            breadcrumbTitle = me.getBreadcrumbTitle(),
            roles = Constants.roles,
            currentRouteName = me.getCurrentRoute().name,
            routesToHideSidebar = [
                'UserBilling',
                'UserUpgradeAndBillingHandler',
                'UserUpgradeAndBilling',
                'UserUpgradeAndBillingInfo'
            ],
            displaySidebar = routesToHideSidebar.indexOf(currentRouteName) === -1 && (!this.hasOneRole(roles.AGENT) || model.getEntity().get('defaultAgentLevel') !== 'FREE');

        return (
             React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: 'NCH - ' + breadcrumbTitle}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, breadcrumbTitle), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(Cmp.NavLink, {to: "Dashboard"}, "Dashboard"), 
                            React.createElement(Cmp.NavLink, {to: "UserAccountDashboard"}, "Edit Account"), 
                            breadcrumbTitle !== 'Edit Account'  && (
                                React.createElement(Cmp.NavLink, null, breadcrumbTitle)
                            )
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 


                    React.createElement("div", {className: "row"}, 
                        React.createElement("div", {className: "col-md-3 md-margin-bottom-40", style: {display: displaySidebar ? 'block' : 'none'}}, 
                            React.createElement("div", {className: "row margin-bottom-20"}, 
                                React.createElement(Link, {to: "Dashboard"}, React.createElement(BS.Button, {bsStyle: "link"}, React.createElement("i", {className: "fa fa-arrow-left"}), " Back to Dashboard"))
                            ), 
                            React.createElement("ul", {className: "list-group sidebar-nav-v1 margin-bottom-20"}, 
                                this.hasOneRole(roles.BUILDER, roles.DEVELOPER, roles.AGENT) && 
                                    React.createElement(Cmp.NavLink, {to: "UserAccountContact", className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-fw fa-file-text-o"}), " My Contact Info"
                                    ), 
                                
                                false && this.hasOneRole(roles.AGENT) && 
                                    React.createElement(Cmp.NavLink, {to: "UserAccountImage", className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-fw fa-user"}), " My Profile Image"
                                    ), 
                                
                                React.createElement(Cmp.NavLink, {to: "UserAccountEmail", className: "list-group-item"}, 
                                    React.createElement("i", {className: "fa fa-fw fa-envelope"}), " My Email Address"
                                ), 
                                React.createElement(Cmp.NavLink, {to: "UserAccountEdit", className: "list-group-item"}, 
                                    React.createElement("i", {className: "fa fa-fw fa-lock"}), " Change Password"
                                ), 

                                this.hasOneRole(roles.AGENT) && !this.isBroker() && 
                                    React.createElement(Cmp.NavLink, {to: "AgentCrmIntegration", className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-fw fa-cloud-upload"}), " CRM Integration ", React.createElement("img", {src: Constants.imageRoot + 'PremiumIcon.png', className: "badge"})
                                    ), 
                                

                                this.hasOneRole(roles.BUILDER, roles.DEVELOPER) && !this.hasRole(roles.LISTING_USER) && 
                                    React.createElement(Cmp.NavLink, {to: "UserUpgradeAndBilling", className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-fw fa-paper-plane-o"}), " Upgrade & Billing"
                                    ), 
                                

                                this.hasOneRole(roles.AGENT) && !this.hasOneRole(roles.BROKER) && 
                                    React.createElement(Cmp.NavLink, {to: "UserMembership", className: "list-group-item"}, 
                                        React.createElement("i", {className: "fa fa-fw fa-diamond"}), " Premium Agent Settings ", React.createElement("img", {src: Constants.imageRoot + 'PremiumIcon.png', className: "badge"})
                                    )
                                
                            ), 
                            (currentRouteName === 'UserUpgradeAndBilling' || currentRouteName === 'UserMembershipOptions') && CurrentUser.getEntity().get('payWithCheck') && (
                                React.createElement("p", null, "Please Note:", React.createElement("br", null), "Your account is setup for payments via check.")
                            ), 
                            (currentRouteName === 'UserUpgradeAndBilling' || currentRouteName === 'UserMembershipOptions') && (
                                React.createElement(CreditCard, {to: currentRouteName === 'UserUpgradeAndBilling' ? 'UserUpgradeAndBillingInfo' : 'UserMembershipBillingInfo'})
                            )
                        ), 
                        React.createElement("div", {className: displaySidebar ? 'col-md-9' : 'col-md-12'}, 
                            React.createElement(RouteHandler, {model: props.model, title: 'NCH - ' + breadcrumbTitle})
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    },

    getCurrentRoute: function() {
        var routes = this.context.router.getCurrentRoutes();

        return routes[routes.length - 1];
    }
});
module.exports = AccountHandler;