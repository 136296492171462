"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    _ = require('underscore'),
    Constants = require('../../../constants'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    FluxBone = require('../../../mixins/FluxBone'),
    PageHeader = require('../../../partials/PageHeader'),
    ProjectsCollection = require('../../../collections/Projects'),
    PageFooter = require('../../../partials/PageFooter'),
    SidebarNav = require('../../../partials/SidebarNav'),
    Cmp = require('../../../components'),
    WidgetPartial = require('../../../partials/widget/Widget'),
    RouteHandler = ReactRouter.RouteHandler,
    $ = require('jquery'),
    Link = ReactRouter.Link;

var DevelopmentSelector = React.createClass({displayName: "DevelopmentSelector",

    mixins: [
        ReactRouter.Navigation,
        FluxBone.CollectionMixin('collection', 'change'),
        React.addons.LinkedStateMixin
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var developmentIds = this.context.router.getCurrentQuery().developmentIds,
            developments = [],
            collection = this.props.collection;

        if (developmentIds) {
            developmentIds = developmentIds.split(',');
        } else {
            developmentIds = [];
        }

        _.each(developmentIds, function(devId) {
            developments.push(collection.getOrFetch(devId));
        });

        var state = {
            developmentIds: developmentIds,
            developments: developments,
            error: false
        };

        return state;
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        var collection = new ProjectsCollection();
        return {
            collection: collection
        };
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            query = {
                developmentIds: this.state.developmentIds.join(',')
            };

        return (
            React.createElement("div", {className: "wrapper development-selector"}, 
                React.createElement(DocumentTitle, {title: "NCH - Email Developments"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Email Developments: Step 1 - Select Developments"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(Cmp.NavLink, {to: "Dashboard"}, "Dashboard"), 
                            React.createElement(Cmp.NavLink, {to: "EmailToolsSelectDevelopments", query: query}, "Select Developments")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 

                    React.createElement(WidgetPartial, {allowSelection: true, selectionLink: me.linkState('developments'), mode: "create"}), 

                    React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                        React.createElement(Cmp.Button.Save, {onClick: this.onSaveClick}, "Next"), 
                        React.createElement(Cmp.Button.Cancel, {onClick: this.onCancelClick})
                    )
                ), 
                state.error && (
                    React.createElement(BS.Modal, {show: !!this.state.error, onHide: this.closeDialog}, 
                        React.createElement(BS.Modal.Header, {closeButton: true}, 
                            React.createElement(BS.Modal.Title, null, "Must Select Developments")
                        ), 
                        React.createElement(BS.Modal.Body, null, 
                            "At least one development must be selected before sending an email."
                        ), 
                        React.createElement(BS.Modal.Footer, null, 
                            React.createElement(BS.Button, {onClick: this.closeDialog}, "Ok")
                        )
                    )
                )
            )
        );
    },

    onCancelClick: function() {
        this.transitionTo('Dashboard');
    },

    onSaveClick: function(e) {
        e.preventDefault();

        if (this.state.developments.length === 0) {
            this.setState({
                error: true
            });
            return;
        }

        this.transitionTo('EmailToolsEmail', undefined, {
            developmentIds: _.pluck(this.state.developments, 'id').join(',')
        })
    },

    closeDialog: function() {
        this.setState({
            error: false
        });
    }
});
module.exports = DevelopmentSelector;
