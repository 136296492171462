"use strict";

var React = require('react'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    PageHeader = require('../../partials/PageHeader'),
    PageFooter = require('../../partials/PageFooter'),
    FluxBone = require('../../mixins/FluxBone'),
    DeveloperModel = require('../../models/Developer'),
    NavLink = require('../../components/NavLink'),
    SidebarNav = require('../../partials/SidebarNav'),
    ReactRouter = require('react-router'),
    Link = ReactRouter.Link;

var DeveloperDetails = React.createClass({displayName: "DeveloperDetails",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset')
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function() {
        var params = this.context.router.getCurrentParams(),
            id = params.id;

        this.props.model.clear();
        this.props.model.set('id', id);
        this.props.model.fetch();

        return {
            id: id
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function(){
        return {
            model: new DeveloperModel(),
            modelLinkToState: true
        };
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.setState(this.updateState());
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            model = props.model,
            data = state.model,
            title = 'Developer - ' + data.name;
        return (
             React.createElement("div", {className: "wrapper"}, 
                React.createElement(DocumentTitle, {title: 'NCH - ' + title}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Developers"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(NavLink, {to: "DeveloperList"}, "Developers"), 
                            React.createElement(NavLink, {to: "DeveloperDetails", params: { id: data.id}}, data.name)
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    React.createElement("div", {className: "row half-gutter"}, 
                        React.createElement("div", {className: "col-md-3 md-margin-bottom-40"}, 
                            React.createElement(SidebarNav, null)
                        ), 
                        React.createElement("div", {className: "col-md-9"}, 
                            React.createElement("div", {className: "row half-gutter"}, 
                                React.createElement("div", {className: "col-md-9"}, 
                                    React.createElement("h1", null, "Developer: ", data.name)
                                ), 
                                React.createElement("div", {className: "col-md-3"}, 
                                    React.createElement(Link, {to: "DeveloperEdit", params: { id: data.id}, className: "btn btn-default pull-right"}, React.createElement("i", {className: "fa fa-plus"}), " Edit Developer")
                                )
                            ), 
                            React.createElement("div", {dangerouslySetInnerHTML: { __html: JSON.stringify(data)}})
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    }
});
module.exports = DeveloperDetails;
