"use strict";

var React = require('react/addons'),
    BS = require('react-bootstrap'),
    _ = require('underscore'),
    toastr = require('toastr');

var Message = React.createClass({displayName: "Message",
    getDefaultProps: function() {
        return {
            dispatcher: undefined,
            getMessage: undefined,
            onMessage: undefined,

            error: undefined,
            success: undefined,
            message: undefined
        }
    },
    getInitialState: function() {
        return {
            error: this.props.error && [].concat(this.props.error) || [],
            success: this.props.success && [].concat(this.props.success) || [],
            message: this.props.message && [].concat(this.props.message) || []
        };
    },

    componentWillReceiveProps: function(nextProps) {
        var props = this.props;

        if (props.dispatcher !== nextProps.dispatcher) {
            this.removeEvents();
        }

        if (nextProps.error) {
            this.setState({
                error: [].concat(nextProps.error)
            });
        }

        if (nextProps.success) {
            this.setState({
                success: [].concat(nextProps.success)
            });
        }

        if (nextProps.message) {
            this.setState({
                message: [].concat(nextProps.message)
            });
        }
    },

    componentDidUpdate: function(prevProps) {
        var props = this.props;

        if (props.dispatcher !== prevProps.dispatcher) {
            this.addEvents();
        }
    },

    addEvents: function() {
        if (this.props.dispatcher) {
            this.props.dispatcher.on('error', this.onDispatcherError);
            this.props.dispatcher.on('sync', this.onDisptacherSuccess);
        }
    },

    removeEvents: function() {
        if (this.props.dispatcher) {
            this.props.dispatcher.off('error', this.onDispatcherError);
            this.props.dispatcher.off('sync', this.onDisptacherSuccess);
        }
    },

    componentDidMount: function() {
        this.addEvents();
    },

    componentWillUnmount: function(){
        this.removeEvents();
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state;

        return (
            React.createElement("div", null, 
                state.error && _.map(state.error, function(error, i){
                    return (
                        React.createElement(BS.Alert, {key: 'error_'+i, bsStyle: "danger", onDismiss: me.onMessageDismiss.bind(null, 'error', i)}, 
                            error
                        )
                    );
                }), 

                state.success && _.map(state.success, function(success, i){
                    return (
                        React.createElement(BS.Alert, {key: 'success'+i, bsStyle: "success", onDismiss: me.onMessageDismiss.bind(null, 'success', i)}, 
                            success
                        )
                    );
                }), 

                state.message && _.map(state.message, function(message, i){
                    return (
                        React.createElement(BS.Alert, {key: 'message'+i, bsStyle: "message", onDismiss: me.onMessageDismiss.bind(null, 'message', i)}, 
                            message
                        )
                    );
                })
            )
        );
    },

    onDispatcherError: function(model_collection, response, options) {
        var message = 'Error Occurred. Your information is not updated. Please Try Again.';

        if (response && response.responseJSON) {
            message = response.responseJSON.userMessage || response.responseJSON.message || response.responseJSON.developerMessage || message;

            if (this.isMounted()) {
                response.handled = true;
            }
        }

        this.onMessage('error', message, model_collection, response, options);
    },

    onDisptacherSuccess: function(model_collection, response, options){
        var message = 'Your information has been successfully updated.';

        if(options && options.syncMethod === 'create' || options.syncMethod === 'update'){
            this.onMessage('success', message, model_collection, response, options);
        }
    },

    onMessage: function(type, defaultMessage, model_collection, response, options) {
        var message = defaultMessage;

        if (this.props.getMessage) {
            message = this.props.getMessage(type, model_collection, response, options);
        }

        if(message === undefined){
            message = defaultMessage;
        }

        if (message) {
            this.addMessage(type, message);

            if (this.props.onMessage) {
                this.props.onMessage('error', message, model_collection, response, options);
            }
        }
    },

    onMessageDismiss: function(type, index) {
        this.removeMessage(type, index);

        if (this.props.onMessageDismiss) {
            this.props.onMessageDismiss(type, index, message);
        }
    },

    clearMessages: function(type) {
        var state = {};

        if (type === undefined) {
            state = {
                error: [],
                success: [],
                message: []
            };
        } else {
            state[type] = [];
        }

        this.setState(state);
    },

    addMessage: function(type, message) {
        var state = {},
            messages = [].concat(this.state[type]);

        if (type === 'error'){
            state['success'] = [];
        }
        if(this.props.singleMessageOfType){
            messages = [];
        }
        messages.push(message);
        state[type] = messages;

        if(toastr[type] && !message._isReactElement){ //Do not render React Elements
            toastr[type](message);
        }

        this.setState(state);
    },

    removeMessage: function(type, message_or_index) {
        var messages = [].concat(this.state[type]),
            state = {},
            index = typeof message_or_index === 'number' ? message_or_index : messages.indexOf(message_or_index),
            message = index > -1 && messages[index];

        if (message) {
            messages.splice(index, 1);
            state[type] = messages;

            this.setState(state);
        }
    }
});
module.exports = Message;
