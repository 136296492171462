"use strict";

var React = require('react/addons'),
    ReactRouterBootstrap = require('react-router-bootstrap'),
    ButtonLink = ReactRouterBootstrap.ButtonLink,
    BS = require('react-bootstrap');

var CancelWidgetEditDialog = React.createClass({displayName: "CancelWidgetEditDialog",
  	render : function(){
      var me = this,
          props = me.props,
          model = props.model;
  		return (
        			React.createElement(BS.Modal, {show: props.show, onHide: props.onHide}, 
                  React.createElement(BS.Modal.Header, {closeButton: true}, 
                      React.createElement(BS.Modal.Title, null, "Warning")
                  ), 
                  React.createElement(BS.Modal.Body, null, 
                      model.id?"You have selected to CANCEL the change to your widget. No changes to this widget will be made.":"You have selected to CANCEL creating a new widget. No widget will be created."
                  ), 
                  React.createElement(BS.Modal.Footer, null, 
                      React.createElement(ButtonLink, {to: "Widgets"}, "Cancel Widget"), " "/*bsStyle='primary'*/
                  )
              )
  		);

  	}

});
module.exports = CancelWidgetEditDialog;
