"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    RouteHandler = ReactRouter.RouteHandler;

var Handler = React.createClass({displayName: "Handler",
    getInitialState: function() {
        return {};
    },

    getDefaultProps: function() {
        return {};
    },

    render: function() {
        return (
             React.createElement(RouteHandler, React.__spread({},  this.props))
        );
    }
});
module.exports = Handler;