"use strict";

var React = require('react/addons'),
    FluxBone = require('../../../mixins/FluxBone'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    Cmp = require('../../../components'),
    Validation = require('../../../partials/validation'),
    MessagePartial = require('../../../partials/Message'),
    CurrentUser = require('../../../shared/CurrentUser'),
    Constants = require('../../../constants'),
    Billing = require('../../../models/Billing'),
    Roles = require('../../../mixins/Roles'),
    $ = require('jquery'),
    _ = require('underscore'),
    numeral = require('numeral'),
    Link = ReactRouter.Link,
    Pagination = require('../../../partials/Pagination'),
    CardReact = require('card-react'),
    Payment = require('payment');

var BillingInfo = React.createClass({displayName: "BillingInfo",

    mixins: [
        ReactRouter.Navigation,
        Roles
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    statics: {
        willTransitionFrom: function(transition, me, callback) {
            var continueAnyways;

            if (me && me.refs && me.refs.form && me.refs.form.isDirty()) {
                continueAnyways = window.confirm(me.refs.form.getDirtyMessage());
                if (continueAnyways) {
                    me.props.currentUser.billingModel.set({
                        cc: {}
                    }, { commit: true, silent: true });
                    callback();
                } else {
                    transition.cancel();
                    me.goBack();
                }
            } else {
                callback();
            }
        }
    },

    getDefaultProps: function() {
        return {
            currentUser: CurrentUser
        };
    },

    updateState: function(props, options) {
        var query = this.context.router.getCurrentQuery();

        props = props || this.props;

        if (props.currentUser.id !== this.props.currentUser.id || (!props.currentUser.billingModel.isLoaded() && !props.currentUser.billingModel.isLoading())) {
            props.currentUser.billingModel.fetch();
        }

        return {
            thankyou: query.thankyou === 'true'
        };
    },

    getInitialState: function() {
        return this.updateState();
    },
    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function(newProps) {
        var state = this.updateState(newProps);
        this.setState(state);
    },

    componentWillMount: function() {
        this.setState(this.updateState(undefined));
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            defaultControlAttr = {
                type: 'text',
                labelClassName: 'col-md-5',
                wrapperClassName: 'col-md-7'
            },
            currentRoute = this.getCurrentRoute(),
            backBtnTo = this.getBackRoute() || 'Dashboard';

        return (
            React.createElement("div", {classNameName: "row"}, 
                React.createElement("div", {classNameName: "col-xs-12"}, 
                    React.createElement("div", {className: "row margin-bottom-20"}, 
                        React.createElement(Link, {to: backBtnTo, params: me.context.router.getCurrentParams(), query: _.omit(me.context.router.getCurrentQuery(), 'save')}, 
                            React.createElement(BS.Button, {bsStyle: "link"}, React.createElement("i", {className: "fa fa-arrow-left"}), " Back")
                        )
                    ), 
                    state.thankyou ? (
                        React.createElement("div", null, 
                            React.createElement("h1", null, "Payment Information"), 
                            React.createElement(BS.Panel, {className: "panel-split-header", header: (React.createElement("h2", null, "Thank You!"))}, 
                                "Thank you for updating your payment information."
                            )
                        )
                    ) : (
                        React.createElement("div", null, 
                            React.createElement("h1", {className: "margin-bottom-20"}, "Payment Information"), 
                            React.createElement("p", null, "Enter your credit card information below. All fields are required"), 
                            React.createElement("p", null, "Accepted Payments: ", React.createElement("i", {className: "fa fa-fw fa-2x fa-cc-visa"}), " ", React.createElement("i", {className: "fa fa-fw fa-2x fa-cc-mastercard"}), " ", React.createElement("i", {className: "fa fa-fw fa-2x fa-cc-amex"}), " ", React.createElement("i", {className: "fa fa-fw fa-2x fa-cc-discover"})/*&nbsp;<i className="fa fa-fw fa-2x fa-cc-diners-club"/>*/), 

                            React.createElement("div", {id: "card-wrapper", className: "margin-bottom-20"}), 

                            React.createElement(Validation.Form, {ref: "form", className: "form form-horizontal", onSubmit: this.onSubmit, model: props.currentUser, handleDirty: true, validateOnly: ['billing.cc.name', 'billing.cc.exp', 'billing.cc.num', 'billing.cc.cvc']}, 
                                React.createElement("div", {className: "row"}, 
                                    React.createElement("div", {className: "col-xs-6 col-sm-6"}, 
                                        React.createElement(Validation.Input, React.__spread({ref: "name", required: true, label: "Name:", placeholder: "", name: "billing.cc.name"},  defaultControlAttr))
                                    ), 
                                    React.createElement("div", {className: "col-xs-6 col-sm-6"}, 
                                        React.createElement(Validation.Input, React.__spread({ref: "exp", style: { maxWidth: '120px'}, required: true, label: "Expiration Date:", placeholder: "MM/YYYY", name: "billing.cc.exp"},  defaultControlAttr))
                                    )
                                ), 

                                React.createElement("div", {className: "row"}, 
                                    React.createElement("div", {className: "col-xs-6"}, 
                                        React.createElement(Validation.Input, React.__spread({ref: "num", required: true, label: "Credit Card Number:", inputmode: "numeric", maxLength: "19", placeholder: "", name: "billing.cc.num"},  defaultControlAttr))
                                    ), 
                                    React.createElement("div", {className: "col-xs-6"}, 
                                        React.createElement(Validation.Input, React.__spread({ref: "cvc", style: { maxWidth: '120px'}, required: true, label: "CVC (Security Code):", placeholder: "", name: "billing.cc.cvc"},  defaultControlAttr))
                                    )
                                ), 

                                React.createElement(Cmp.ValidationMessage, null), 
                                React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                                    React.createElement(Cmp.Button.Cancel, {className: "pull-left", onClick: this.onCancel}), 
                                    React.createElement(Cmp.Button.Save, {className: "pull-right"})
                                )
                            )
                        )
                    )
                )
            )
        );
    },

    onSubmit: function(e) {
        var me = this,
            values = me.refs.form.getValues();

        e && e.preventDefault();

        me.props.currentUser.billingModel.save(values.billing, {
            fetchOnSave: true
        }).then(function() {
            var backRoute = me.getBackRoute(),
                router = me.context.router;

            if (window.Intercom) {
                window.Intercom('trackEvent', 'card-updated');
            }
            if (window.analytics) {
                analytics.track('card-updated');
            }

            if (backRoute) {
                me.transitionTo(backRoute, router.getCurrentParams(), router.getCurrentQuery());
            } else {
                me.transitionTo(me.getCurrentRoute().name, router.getCurrentParams(), _.extend(router.getCurrentQuery(), { thankyou: true }));
            }
        }).fail(function(model, response, options) {
            if(model !== me.props.currentUser) {
                response = _.isString(response) ? model : response;
                options = _.isString(options) ? {} : options;
                me.props.currentUser.trigger.apply(me.props.currentUser, ['error', me.props.currentUser, response, options]);
            }
        });
    },

    onCancel: function() {
        var backRoute = this.getBackRoute() || 'Dashboard';
        this.transitionTo(backRoute, this.context.router.getCurrentParams(), _.omit(this.context.router.getCurrentQuery(), 'save'));
    },

    componentDidMount: function() {
        this.applyScripts();
    },

    componentDidUpdate: function() {
        // this.applyScripts();
    },

    applyScripts: function() {
        var numInput = $(React.findDOMNode(this.refs.form)).find('input[name="billing.cc.num"]').get(0),
            expiryInput = $(React.findDOMNode(this.refs.form)).find('input[name="billing.cc.exp"]').get(0),
            cvcInput = $(React.findDOMNode(this.refs.form)).find('input[name="billing.cc.cvc"]').get(0);

        numInput && Payment.formatCardNumber(numInput);
        expiryInput && Payment.formatCardExpiry(expiryInput);
        cvcInput && Payment.formatCardCVC(cvcInput);
    },

    getCurrentRoute: function() {
        var routes = this.context.router.getCurrentRoutes();

        return routes[routes.length - 1];
    },

    getBackRoute: function() {
        var currentRoute = this.getCurrentRoute(),
            backRoute;

        switch (currentRoute.name) {
            case 'ProjectEditUpgradeAndBillingInfo':
                backRoute = 'ProjectEditUpgradeAndBilling';
                break;
            case 'UserUpgradeAndBillingInfo':
                backRoute = 'UserUpgradeAndBilling';
                break;
            case 'UserBilling':
                backRoute = 'UserAccount';
                break;
            case 'UserMembershipBillingInfo':
                backRoute = 'UserMembershipOptions';
                break;
        }

        return backRoute;
    }

});
module.exports = BillingInfo;
