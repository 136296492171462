"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    ProjectDocument = require('../models/ProjectDocument');

var ProjectDocuments = Collection.extend({
    url: ProjectDocument.prototype.urlRoot,
    model: ProjectDocument
});

module.exports = ProjectDocuments;
