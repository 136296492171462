"use strict";

var React = require('react'),
    _ = require('underscore'),
    ReactRouter = require('react-router'),
    BS = require('react-bootstrap'),
    Link = ReactRouter.Link,
    cx = require('classnames'),
    Constants = require('../../../constants'),
    Cmp = require('../../../components'),
    FluxBone = require('../../../mixins/FluxBone'),
    ReportModel = require('../../../models/reports/ListingDashboard'),
    Validation = require('../../../partials/validation'),
    numeral = require('numeral'),
    CurrentUser = require('../../../shared/CurrentUser');


var ProjectReportDashboard = React.createClass({displayName: "ProjectReportDashboard",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset'),
        FluxBone.ModelMixin('report', 'sync'),
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function(props) {
        var query = this.context.router.getCurrentQuery(),
            startDate,
            endDate,
            defaults,
            state = this.state || {};

        props = props || this.props;

        defaults = props.report.defaults();
        startDate = query.startDate || defaults.startDate;
        endDate = query.endDate || defaults.endDate;

        if (state.startDate !== startDate || state.endDate !== endDate) {
            props.report.clear();

            props.report.set({
                startDate: startDate,
                endDate: endDate
            });

            this.props.report.fetch();
        }

        return {
            startDate: startDate,
            endDate: endDate
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        return {
            report: new ReportModel()
        };
    },

    reportUpdateState: function() {
        this.refs.form.forceUpdate();
    },
    /**
     * This is to ensure that when subroutes transition they trigger recheck router state and render
     */
    componentWillReceiveProps: function(props) {
        this.setState(this.updateState(props));
    },

    shouldDisableDatefields: function(props){
        var listings,
            disableDateFields = false;

        props = props || this.props;

        if(!props.report.isLoaded() || props.report.isLoading()){
            disableDateFields = true;
        } else {
            listings = props.report.get('listings');
            _.every(listings, function(listing){
                if(listing.level < 2){
                    disableDateFields = true;
                    return false;
                }
                return true;
            });
        }
        
        return disableDateFields;
    },

    render: function() {
        var props = this.props,
            state = this.state,
            reportData = props.report.toJSON({
                includeListings: true
            }),
            aggregateStats = reportData.aggregateStats,
            queryParams = {
                // startDate: reportData.startDate,
                // endDate: reportData.endDate
            },
            dateFieldsDisabled = this.shouldDisableDatefields();

        return (
            React.createElement("div", null, 
                React.createElement("h1", null, "Reports Dashboard"), 
                React.createElement("h5", {className: "margin-bottom-20"}, 
                    React.createElement("p", null, 
                        "Below are summary reports of your active listings. The summary provides an overview of your listing's performance."
                    ), 
                    React.createElement("p", null, 
                        "NOTE: The default date range of the stats shows from the day your listings went active in New Construction Hub."
                    )
                ), 
                React.createElement("div", {className: "margin-bottom-20"}, 
                    dateFieldsDisabled && props.report.isLoaded() && !props.report.isLoading() && (
                        React.createElement(BS.Row, null, 
                            React.createElement(BS.Col, {xs: 12}, 
                                React.createElement("p", {className: "green-text"}, 
                                    React.createElement("i", {className: "fa fa-exclamation-triangle"}), " All listings need to have a Service Level of 2 or higher to access the upgraded functionality of the Reports Dashboard. ", React.createElement(Link, {to: "UserUpgradeAndBilling"}, "Click Here"), " to view the upgrade page."
                                )
                            )
                        )
                    ), 
                    React.createElement(Validation.Form, {ref: "form", model: props.report, onSubmit: this.onSaveClick}, 
                        React.createElement(BS.Row, null, 
                            React.createElement(BS.Col, {sm: 6, xs: 12}, 
                                React.createElement(Cmp.Picker.Date, {disabled: dateFieldsDisabled, labelClassName: "col-xs-5", wrapperClassName: "col-xs-7", label: "Filter by Date:", placeholder: "Start", name: "startDate"})
                            ), 
                            React.createElement(BS.Col, {sm: 4, xs: 12}, 
                                React.createElement(Cmp.Picker.Date, {disabled: dateFieldsDisabled, labelClassName: "col-xs-5 col-sm-2", wrapperClassName: "col-xs-7 col-sm-10", label: "to", placeholder: "Start", name: "endDate"})
                            ), 
                            React.createElement(BS.Col, {sm: 2, xs: 12}, 
                                React.createElement(Cmp.Button.Save, {disabled: dateFieldsDisabled, formLinks: false}, "Go")
                            )
                        )
                    )
                ), 

                aggregateStats && (
                    React.createElement("div", {className: "dashboard-stats row margin-bottom-20"}, 
                        React.createElement("div", {className: "dashboard-stat col-xs-3"}, 
                            React.createElement("div", {className: "dashboard-stat-inner"}, 
                                React.createElement("h4", {title: "Agents"}, React.createElement("i", {className: "fa fa-fw fa-users"}), "Agents", React.createElement("small", null, "promoting your listings")), 
                                React.createElement("div", {className: "orange-text"}, numeral(aggregateStats.agents).format('0,0[.]00'))
                            )
                        ), 
                        /*
                        <div className="dashboard-stat col-xs-3">
                            <div className="dashboard-stat-inner">
                                <h4 title="Views"><i className="fa fa-fw fa-eye"/>Views<small>of your listings</small></h4>
                                <div className="blue-text">{numeral(aggregateStats.views).format('0,0[.]00')}</div>
                            </div>
                        </div>
                        */
                        React.createElement("div", {className: "dashboard-stat col-xs-3"}, 
                            React.createElement("div", {className: "dashboard-stat-inner"}, 
                                React.createElement("h4", {title: "Views"}, React.createElement("i", {className: "fa fa-fw fa-eye"}), "Views", React.createElement("small", null, "on your listings")), 
                                React.createElement("div", {className: "blue-text"}, numeral(aggregateStats.views).format('0,0[.]00'))
                            )
                        ), 
                        React.createElement("div", {className: "dashboard-stat col-xs-3"}, 
                            React.createElement("div", {className: "dashboard-stat-inner"}, 
                                React.createElement("h4", {title: "Clicks"}, React.createElement("i", {className: "fa fa-fw fa-external-link"}), "Clicks", React.createElement("small", null, "to your listing website")), 
                                React.createElement("div", {className: "blue-text"}, numeral(aggregateStats.clicks).format('0,0[.]00'))
                            )
                        ), 
                        React.createElement("div", {className: "dashboard-stat col-xs-3"}, 
                            React.createElement("div", {className: "dashboard-stat-inner"}, 
                                React.createElement("h4", {title: "Leads"}, React.createElement("i", {className: "fa fa-fw fa-fire"}), "Leads", React.createElement("small", null, "of your listings")), 
                                React.createElement("div", {className: "blue-text"}, numeral(aggregateStats.leads).format('0,0[.]00'))
                            )
                        )
                    )
                ), 
                aggregateStats && (
                    React.createElement("div", null, 
                        React.createElement("h1", null, "Listings:"), 
                        React.createElement("h5", {className: "margin-bottom-20"}, 
                            "Below are your listings. Click on a listing to view its reports."
                        ), 

                        React.createElement(BS.Table, {striped: true, bordered: true, condensed: true, hover: true, className: "stats-table"}, 
                            React.createElement("thead", null, 
                                React.createElement("tr", null, 
                                    React.createElement("th", null, "Name"), 
                                    React.createElement("th", null, "Active"), 
                                    /*<th>Views</th>*/
                                    React.createElement("th", null, "Views"), 
                                    React.createElement("th", null, "Clicks"), 
                                    React.createElement("th", null, "Leads"), 
                                    React.createElement("th", null, "Agents")
                                    /* <th></th> */
                                )
                            ), 
                            React.createElement("tbody", null, 
                            _.map(reportData.listings, function(listing){
                                var routeParams = {
                                    id: listing.id
                                };

                                if(listing.isDeveloper) {
                                    return null;
                                }

                                return (
                                    React.createElement("tr", {key: listing.id}, 
                                        React.createElement("td", null, React.createElement(Link, {to: listing.isDeveloper ? 'BuilderReportDashboard' : 'ProjectReportDashboard', params: routeParams, query: queryParams}, listing.name)), 
                                        React.createElement("td", null, listing.active ? 'Yes' : 'No'), 
                                        /*<td>{listing.views}</td>*/
                                        React.createElement("td", null, listing.views), 
                                        React.createElement("td", null, listing.clicks), 
                                        React.createElement("td", null, listing.leads), 
                                        React.createElement("td", null, React.createElement(Link, {title: "View Agent Report", to: listing.isDeveloper ? 'BuilderReportDashboard' : 'ProjectReportAgentDashboard', params: routeParams, query: queryParams}, listing.agents))
                                        /* <td>
                                            <Link title="View Project Report" to={listing.isDeveloper ? 'BuilderReportDashboard' : 'ProjectReportDashboard'} params={routeParams} query={queryParams}><i className="icon-custom  icon-color-u icon-xs fa fa-fw fa-eye"/></Link>&nbsp;
                                            <Link title="View Project Performance Report" to={listing.isDeveloper ? 'BuilderProjectReportPerformanceDashboard' : 'ProjectReportPerformanceDashboard'} params={routeParams} query={queryParams}><i className="icon-custom  icon-color-u icon-xs fa fa-fw fa-line-chart"/></Link>
                                        </td> */
                                    )
                                );
                            })
                            )
                        )
                    )
                )
            )
        );
    },

    onSaveClick: function() {
        var data = this.refs.form.getValues();
        this.transitionTo('Reports', undefined, {
            startDate: data.startDate,
            endDate: data.endDate,
            page: 1
        });
    }
});

module.exports = ProjectReportDashboard;
