"use strict";

var Components = {
    Select: {
        City: require('./CitySelect'),
        Country: require('./CountrySelect'),
        State: require('./StateSelect'),
        Developer: require('./DeveloperSelect'),
        Metro: require('./MetroSelect'),
        Listing: require('./ListingTypeSelect'),
        MarketingNetworks: require('./MarketingNetworksSelect'),
        CityCheckboxGroup: require('./CityCheckboxGroup'),
        Widget: require('./WidgetSelect'),
        Listings: require('./ListingSelect'),
        OrderBy: require('./OrderBy')
    },
    Button: {
        Save: require('./buttons/SaveButton'),
        Cancel: require('./buttons/CancelButton')
    },
    Picker: {
        Date: require('./DatePicker'),
        File: require('./FilePicker')
    },
    RadioGroup: require('./RadioGroup'),
    NavLink: require('./NavLink'),
    Tracker: require('./Tracker'),
    Carousel: require('./Carousel'),
    ValidationMessage: require('./ValidationMessage'),
    LocationSelect: require('./LocationSelect'),
    Link: require('./Link'),
    ImageThumbnail: require('./ImageThumbnail')
};

module.exports = Components;
