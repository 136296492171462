"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    FluxBone = require('../mixins/FluxBone'),
    Countries = require('../collections/Countries'),
    countriesCollection,
    Validation = require('../partials/validation');

var CountrySelect = React.createClass({displayName: "CountrySelect",

    mixins: [
        FluxBone.CollectionMixin('store', 'sync')
    ],

    getDefaultProps: function() {
        if (!countriesCollection) {
            countriesCollection = new Countries();
        }
        return {
            store: countriesCollection,
            defaultText: 'Select',
            trigger: "onChange"
        };
    },

    componentWillMount: function() {
        var store = this.props.store;
        if (!store.isLoaded() && !store.isLoading() || this.props.hasProjects !== store.hasProjects) {
            store.fetch({
                hasProjects: !!this.props.hasProjects
            });
        }
    },

    render: function() {
        var props = this.props;
        return (
            React.createElement(Validation.Input, React.__spread({ref: "input", label: "Country:"},  props, {type: "select"}), 
                React.createElement("option", {value: ""}, props.defaultText), 
                props.store.map(function(country){
                    var data = country.toJSON();
                    return (
                        React.createElement("option", {key: data.id, value: data.id}, data.name)
                    );
                })
            )
        );
    },

    getDisplayValue: function(){
        var value = this.refs.input.getValue(),
            id = value ? parseInt(value) : undefined,
            model,
            displayValue;

        if(id){
            model = this.props.store.get(id);
        }

        if(model){
            displayValue = model.get('name');
        }

        return displayValue
    }
});

module.exports = CountrySelect;
