"use strict";

var React = require('react'),
    $ = require('jquery'),
    Constants = require('../constants'),
    ReactRouter = require('react-router'),
    RouteHandler = ReactRouter.RouteHandler,
    BS = require('react-bootstrap'),
    CurrentUser = require('../shared/CurrentUser'),
    URI = require('urijs'),
    AjaxLoadingMask = require('../partials/AjaxLoadingMask'),
    AjaxErrorHandler = require('../partials/AjaxErrorHandler');

var App = React.createClass({displayName: "App",
    contextTypes: {
        router: React.PropTypes.func
    },

    updateState: function(props) {
        props = props || this.props;

        return {
            iframeHeight: 400
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            height = '' + state.iframeHeight + 'px';

        return (
            React.createElement("div", null, 
                Constants.translateLocation === 'page' && (React.createElement("div", {id: "google_translate_element"})), 
                React.createElement(AjaxErrorHandler, React.__spread({},  props)), 
                React.createElement(RouteHandler, React.__spread({CurrentUser: CurrentUser},  props)), 
                React.createElement(AjaxLoadingMask, null), 
                React.createElement(BS.Modal, {show: !!state.showContactForm, onHide: me.closeContactForm, className: "modal-preview modal-lg"}, 
                    React.createElement(BS.Modal.Header, {closeButton: true}), 
                    React.createElement(BS.Modal.Body, null, 
                        React.createElement("div", null, 
                            React.createElement("h3", {style: { position: 'relative'}}, "Contact Us:"), 
                            React.createElement("iframe", {key: "header-contact-form", allowTransparency: "true", src: "https://secure.jotformpro.com/form/60943721593157", frameBorder: "0", style: { width:'735px', height:height, border:'none', "marginTop": "-45px"}, scrolling: "no"})
                        )
                    )
                )
            )
        );
    },

    componentDidMount: function() {
        $(document).on('click', 'a', this.handleClick);
        this.toggleIntercom();

        if (Constants.translateLocation === 'page') {
            $('<script type="text/javascript">function googleTranslateElementInit() {  new google.translate.TranslateElement({pageLanguage: \'en\', layout: google.translate.TranslateElement.FloatPosition.TOP_LEFT, gaTrack: true, gaId: \'' + Constants.googleAnalyticsId + '\'}, \'google_translate_element\'); }</script><script type="text/javascript" src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>').appendTo(document.body);
        }
        $(window).on('message', this.handleSizeResponse);
    },

    componentWillUnmount: function() {
        $(document).off('click', 'a', this.handleClick);
        $(window).off('message', this.handleSizeResponse);
    },

    componentDidUpdate: function(prevProps, prevState) {
        if (prevProps.path !== this.props.path) {
            if (window.ga) {
                window.ga('send', 'pageview', {
                    location: window.location.href
                });
            }

            if (window.Intercom && !(CurrentUser.hasRole(Constants.roles.ADMIN) || !!CurrentUser.get('actualId'))) {
                Intercom('update', {
                    email: CurrentUser.get('email'),
                    user_id: CurrentUser.id,
                    url: window.location.href
                });
            }

            if (window.analytics && !(CurrentUser.hasRole(Constants.roles.ADMIN) || !!CurrentUser.get('actualId'))) {
                analytics.page();
            }

            this.toggleIntercom();
        }
    },

    toggleIntercom: function() {
        // $('body')[CurrentUser.hasRole(Constants.roles.ADMIN) || CurrentUser.hasRole(Constants.roles.AGENT) ? 'addClass' : 'removeClass']('hide-intercom');
    },

    handleClick: function(e) {
        var $a = $(e.currentTarget),
            router = this.context.router,
            href = $a.attr('href'),
            url, route, lastMatchedRoute;

        if ($a.hasClass('contact-link')) {
            e.preventDefault();
            e.stopPropagation();
            this.showContactForm();
            return;
        }

        if ($a.hasClass('help-link')) {
            e.preventDefault();
            e.stopPropagation();
            this.toggleSupport();
            return;
        }

        if (href) {
            url = href && URI(href);
            href = url.path() + url.search();
            route = router.match(href);
        }

        if (!url || (url.host() && url.host() !== URI(window.location.href).host())) {
            return;
        }

        if (e.altKey || e.metaKey || e.ctrlKey || e.shiftKey || e.isDefaultPrevented()) {
            return;
        }

        // Only match if there are 2 or more routes because the first route
        // is essentially a wildcard route and the second route is the Notfound route.
        if (route && route.routes.length >= 2 && !route.routes[route.routes.length - 1].isNotFound) {
            e.preventDefault();

            lastMatchedRoute = route.routes[route.routes.length - 1];
            router.transitionTo(lastMatchedRoute.name, route.params, route.query);
        }
    },

    showContactForm: function() {
        this.setState({
            showContactForm: true
        });
    },

    closeContactForm: function() {
        this.setState({
            showContactForm: false
        });
    },

    handleSizeResponse: function(e) {
        var originalEvent = e.originalEvent,
            data = e.originalEvent.data;
        if (originalEvent.origin == 'https://secure.jotformpro.com') {
            data = data.split(':');
            if (data[0] === 'setHeight') {
                this.setState({
                    iframeHeight: data[1]
                });
            }
        }
    },

    toggleSupport: function() {
        // var intercomeWidget = $('#intercom-conversation');

        //  if(window.Intercom && (intercomeWidget.is(':visible') && intercomeWidget.css('visibility') !== 'hidden')){
        //     window.Intercom('hide');
        // } else if(window.Intercom){
        //     window.Intercom('show');
        // }
        if (window.FreshWidget && $(FreshWidget.iframe()).is(':visible')) {
            FreshWidget.hide();
        } else if (window.FreshWidget) {
            FreshWidget.show();
            $(FreshWidget.iframe()).attr('scrolling', 'no');
        } else if (window.zE) {
            zE.activate();
        }
    }
});

module.exports = App;
