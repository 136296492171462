"use strict";

var React = require('react/addons'),
    cx = require('classnames'),
    BS = require('react-bootstrap'),
    Widget = require('../../models/Widget'),
    ProjectsCollection = require('../../collections/Projects'),
    Constants = require('../../constants'),
    FluxBone = require('../../mixins/FluxBone'),
    DevelopmentDetail = require('../project/ProjectPopup'),
    ProjectGallery = require('../project/ProjectGallery'),
    ProjectList = require('../project/ProjectList'),
    ProjectsMap = require('../project/ProjectsMap'),
    ProjectPopup = require('../project/ProjectPopup'),
    ProjectFilters = require('../project/ProjectFilters'),
    WidgetProjectSelector = require('./WidgetProjectSelector'),
    ProjectOrderBy = require('../../partials/project/OrderBy'),
    Cmp = require('../../components'),
    Pagination = require('../Pagination'),
    _ = require('underscore'),
    $ = require('jquery'),
    URI = require('urijs');

require('mapbox.js');

var Widget = React.createClass({displayName: "Widget",

    mixins: [
        FluxBone.ModelMixin('model', 'sync reset'),
        FluxBone.CollectionMixin('model.listingsCollection', 'sync'),
        FluxBone.CollectionMixin('mapCollection', 'sync'),
        React.addons.LinkedStateMixin
    ],

    getDefaultProps: function() {
        var model = new Widget(),
            mapCollection = new ProjectsCollection();

        return {
            widgetId: undefined,
            agentEmail: undefined,
            model: model,
            mapCollection: mapCollection,
            allowSelection: false,
            selection: undefined,
            selectionLink: undefined,
            mode: 'view',
            standalone: false,
            widget: undefined,
            widgetLookup: undefined,
            className: "wrapper agent-shield-widget",
            showOrderBy: false,
            format: 'project-gallery',
            gallery: ProjectGallery,
            orderBy: ProjectOrderBy,
            detailFormat: 'widget-project'
        };
    },

    getInitialState: function() {
        var props = this.props;

        props.model.listingsCollection.mode = 'remote';
        // props.model.listingsCollection.mode = props.mode === 'create' ? 'remote' : 'local';
        props.model.listingsCollection.pageSize = 12;
        props.model.listingsCollection.disableFilters = true;

        props.mapCollection.mode = 'local';
        props.mapCollection.disableFilters = true;

        props.model.listingsCollection.service = (props.mode === 'create' && this.getWidgetType() !== 7) ? 'AGENT_PORTAL' : undefined;

        return {
            loading: true,
            showFilters: this.getWidgetType() === 7,
            showMap: false,
            trackerId: (new Date()).getTime(),
            country: undefined,
            state: undefined,
            metro: undefined,
            city: undefined,
            developer: props.builder ? props.builder.get('name') : undefined,
            listings: undefined,
            hideMap: this.shouldHideMap(),
            createMap: false,
            pageNum: props.model.listingsCollection.pageNum,
            error: undefined,
            orderBy: 'NAME_ASC'
        };
    },

    componentWillMount: function() {
        this.fetchWidget();
    },

    componentWillUpdate: function(nextProps, nextState) {
        if(nextProps.builder !== this.props.builder) {
            this.setState({
                developer: nextProps.builder ? nextProps.builder.id : undefined
            });
        }
    },

    render: function() {
        var me = this,
            props = this.props,
            state = this.state,
            data = props.model.toJSON(),
            mapClassName = cx('row',{
                'margin-top-10 margin-bottom-20': state.showMap
            }),
            listingsCollection = props.model.listingsCollection,
            pagingStyle = {
                display: state.hideMap && (listingsCollection.length === 0 || listingsCollection.getTotal() <= listingsCollection.pageSize) ? 'none' : 'block' 
            };

        if(!state.loaded) {
            pagingStyle.display = 'none';
        }

        return (
            React.createElement("div", {className: props.className, style: props.style}, 
                React.createElement("div", null, 
                    React.createElement("div", null, 
                        state.loaded && this.getWidgetType() === 7 && (
                            React.createElement("div", {className: "row"}, 
                                React.createElement(ProjectFilters, {
                                    namePrefix: 'map_'+props.widgetId, 
                                    countryLink: this.linkState('country'), 
                                    stateLink: this.linkState('state'), 
                                    metroLink: this.linkState('metro'), 
                                    cityLink: this.linkState('city'), 
                                    developerLink: this.linkState('developer'), 
                                    style: {display: state.showFilters ? 'block': 'none'}, 
                                    loading: state.loading, 
                                    hidden: !state.showFilters, 
                                    showMapLink: this.linkState('showMap'), 
                                    showCountryAndState: props.mode !== 'view', 
                                    communityLink: this.linkState('community'), 
                                    onGoClick: this.onGoClick, 
                                    showSearchClear: !!(listingsCollection.lastXhrOptions && listingsCollection.lastXhrOptions.data && listingsCollection.lastXhrOptions.data.name)})
                            )
                        ), 

                        React.createElement("div", {className: "row"}, 
                            React.createElement("div", {className: props.allowSelection ? 'col-xs-8 col-md-9 col-lg-10' : 'col-xs-12'}, 


                                state.createMap && (
                                    React.createElement(ProjectsMap, {style: { display: state.hideMap ? 'none' : 'block'}, ref: "map", id: 'map_' + props.widgetId, onMarkerClick: this.onMarkerClick, collection: props.mapCollection, className: mapClassName, useClusters: true}, 
                                        React.createElement("button", {ref: "toggleMapBtn", className: "btn-u toggle-map", "aria-hidden": !state.showMap, onClick: this.onShowMapButtonClick}, state.showMap ? 'Hide Map' : 'Show Map')
                                    )
                                ), 
                                

                                React.createElement("div", {className: "row", style: pagingStyle}, 
                                    React.createElement("div", {className: "panel panel-default panel-navigation margin-top-0 with-map-button"}, 
                                        React.createElement("div", {className: "panel-body"}, 
                                            React.createElement(Pagination, {className: "pull-left", showTotal: true, totalSuffixText: props.totalSuffixText, store: listingsCollection, onClick: this.pageGallery}), 
                                            React.createElement("div", {className: "actions"}, 
                                                /*
                                                <BS.Button bsStyle="link" active className="col-divider"><i className="fa fa-eye"/> Communities</BS.Button>
                                                <BS.Button bsStyle="link">Builders</BS.Button>
                                                */
                                               props.showOrderBy && (React.createElement(props.orderBy, {inline: true, groupClassName: "order-by", valueLink: this.linkState('orderBy')})), 
                                                !state.hideMap && (
                                                    React.createElement(BS.Button, {bsStyle: "link", className: "col-divider-xs showMapButton", active: !state.showMap, onClick: this.onShowMapButtonClick}, React.createElement("i", {className: "fa fa-map-marker"}), state.showMap ? ' Hide Map' : ' Show Map')
                                                ), 
                                                !state.hideMap && (
                                                    React.createElement("img", {src: Constants.imageRoot + 'map_panel_corner.png', className: "map-overlay", "data-toggle": "tooltip", title: "", "data-original-title": "Map coming soon"})
                                                )
                                            )
                                        )
                                    )
                                ), 

                                this.getWidgetType() === 7 || (this.getWidgetType() === 6 && props.mode === 'view') ? (
                                    React.createElement(props.gallery, {trackerId: state.trackerId, fixedWidth: props.standalone, widget: props.model, store: listingsCollection, onItemClick: this.showProjectPopup, onViewBuilderCommunities: me.onViewBuilderCommunities})
                                ) : (
                                    React.createElement(ProjectList, {trackerId: state.trackerId, widget: props.model, store: listingsCollection, selectionValue: me.getSelectionValue(), showSelect: props.allowSelection, mode: props.mode, onSelect: this.selectProject, onItemClick: this.showProjectPopup})
                                ), 

                                !state.loading && listingsCollection.length === 0 && state.error && state.error.emptyProjects && props.mode === 'view' && (
                                    React.createElement(BS.Alert, {bsStyle: "warning"}, 
                                        React.createElement("div", {className: "text-center"}, "No Listings available.")
                                    )
                                ), 

                                React.createElement("div", {className: "row", style: pagingStyle}, 
                                    React.createElement("div", {className: "panel panel-default panel-navigation margin-top-0"}, 
                                        React.createElement("div", {className: "panel-body"}, 
                                            React.createElement(Pagination, {className: "pull-left", showTotal: true, totalSuffixText: props.totalSuffixText, store: listingsCollection, onClick: this.pageGallery}), 
                                            props.standalone && (
                                                React.createElement("div", {className: "pull-right"}, 
                                                    React.createElement("div", {className: "logo-small"}, 
                                                        React.createElement("a", {href: URI(Constants.publicSite).addSearch({ source: 'amwlogo' }), target: "_blank"}, 
                                                            React.createElement("img", {src: Constants.imageRoot + 'NCH_Logo_PoweredBy_162x25.png'})
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            ), 
                            props.allowSelection && (
                                React.createElement(WidgetProjectSelector, {className: "col-xs-4 col-md-3 col-lg-2", selectionValue: me.getSelectionValue(), onDeselect: me.deselectProject})
                            )
                        )
                    ), 
                    React.createElement(ProjectPopup, {showSelect: props.allowSelection, onSelect: this.selectProject.bind(null, state.project), showAgentDetails: this.isCreateMode(), ref: "projectDetail", show: !!state.project, model: state.project, agent: props.model.agentModel, widget: props.model, onClose: this.hidePopup}), 
                    React.createElement("div", {className: 'loadIndicator ' + (state.loading ? 'display-block' : 'display-none')}), 
                    !state.loading && props.mode !== 'create' && (
                        React.createElement(Cmp.Tracker, {params: { widgetId: props.model.id, agentId: props.model.agentModel.id, type: 'widget', pageSize: listingsCollection.pageSize, pageNum: listingsCollection.pageNum, country: state.country, state: state.state, city: _.map(state.city, function(c){ return c.id }).join(','), metroArea: state.metro, developer: state.developer}})
                    ), 
                    state.error && (
                        React.createElement(Cmp.Tracker, {params: state.error})
                    )
                )
            )
        );
    },

    onViewBuilderCommunities: function(builder) {
        if(this.props.onViewBuilderCommunities) {
            this.props.onViewBuilderCommunities(builder);
        }
    },

    onGoClick: function() {
        this.fetchProjects({
            pageNum: 1
        });
    },

    onShowMapButtonClick: function(e) {
        var me = this,
            state = me.state,
            showMap = !state.showMap;

        e.preventDefault();

        me.setState({
            createMap: true,
            showMap: showMap
        }, function() {
            if(showMap && !state.createMap){
                // this.props.model.listingsCollection.mode = 'local';
                me.fetchProjects().then(me.centerMapOnWidget);
            }
        });
    },

    selectProject: function(projectToSelect, e) {
        var selection = [].concat(this.getSelectionValue() || []),
            project = this.getSelectedProjectById(projectToSelect.id);

        e.preventDefault();

        if (!project) {
            selection.push(projectToSelect);
            this.setLinkedProp('selection', selection);
        }

        this.setState({
            project: undefined
        });

        if(this.props.onSelect){
            this.props.onSelect(projectToSelect, selection);
        }
    },

    deselectProject: function(projectToDeselect, e) {
        var selection = [].concat(this.getSelectionValue() || []),
            project = this.getSelectedProjectById(projectToDeselect.id);

        e.preventDefault();

        if (project) {
            selection.splice(selection.indexOf(project), 1);
            this.setLinkedProp('selection', selection);

            if(this.props.onDeselect){
                this.props.onDeselect(projectToDeselect, selection);
            }
        }
    },

    setLinkedProp: function(name, value) {
        var linkName = name + 'Link';

        if (this.props[linkName]) {
            this.props[linkName].requestChange(value);
        }
    },

    isProjectSelected: function(project) {
        return !!this.getSelectedProjectById(project.id);
    },

    getSelectedProjectById: function(id) {
        return _.findWhere(this.getSelectionValue(), {
            id: id
        });
    },

    isCreateMode: function() {
        return this.props.mode === 'create';
    },

    isViewMode: function() {
        return this.props.mode === 'view';
    },

    shouldHideMap: function(props) {
        props = props || this.props;
        return this.isCreateMode() || this.getWidgetType() !== 7 || props.hideMap;
    },

    getWidgetType: function() {
        var model = this.props.model,
            widgetType = model.get('widgetType');
        return widgetType ? widgetType.id : undefined;
    },

    getSelectionValue: function() {
        var value = this.props.selection;
        if (this.props.selectionLink) {
            value = this.props.selectionLink.value;
        }
        return value;
    },

    componentDidUpdate: function(prevProps, prevState) {
        var me = this,
            state = me.state,
            refs = me.refs,
            collection = me.props.model.listingsCollection;

        me.updateShowMap(prevState);

        if ((this.props.builder && this.props.builder !== prevProps.builder) || (!state.showFilters && !collection.isLoaded() && !collection.isLoading()) || (this.getWidgetType() === 7 || this.isCreateMode()) && ((collection.mode === 'remote' && state.pageNum !== prevState.pageNum) || state.country !== prevState.country || state.state !== prevState.state || state.metro !== prevState.metro || state.city !== prevState.city || state.developer !== prevState.developer || state.orderBy != prevState.orderBy) || (collection.mode === 'local' && state.orderBy !== prevState.orderBy)) {
            me.fetchProjects({
                pageNum : state.country !== prevState.country || state.state !== prevState.state || state.metro !== prevState.metro || state.city !== prevState.city || state.developer !== prevState.developer || state.orderBy !== prevState.orderBy ? 1 : state.pageNum
            }).then(me.centerMapOnWidget);
        }

        if(state.createMap && state.createMap !== prevState.createMap){
            me.getInitialMapHeight();
            setTimeout(function(){
                me.centerMapOnWidget(true);
            }, 10);
        }
    },

    updateShowMap: function(prevState) {
        var me = this,
            state = me.state,
            refs = me.refs,
            $toggleMapBtn = $(React.findDOMNode(refs.toggleMapBtn));


        if (!state.hideMap && state.createMap) {
            if (state.showMap && (prevState && !prevState.showMap)) {
                $(React.findDOMNode(refs.map)).find('.widget-map').stop().animate({
                    height: this.initialMapHeight + 'px'
                }, {
                    duration: 'slow',
                    complete: refs.map.invalidateSize
                });
            } else if (!state.showMap && (!prevState || prevState.showMap)) {
                $(React.findDOMNode(refs.map)).find('.widget-map').stop().animate({
                    height: 0
                    // height: $toggleMapBtn.outerHeight(true) + $toggleMapBtn.position().top + 'px'
                }, {
                    duration: 'slow',
                    complete: refs.map.invalidateSize
                });
            } else {
                this.refs.map.invalidateSize();
            }
        }
    },

    componentDidMount: function() {
        if (this.props.widget) {
            this.fetchProjects().then(this.centerMapOnWidget);
        }
        this.getInitialMapHeight();
        this.updateShowMap();
    },

    getInitialMapHeight: function(){
        if(this.state.createMap){
            this.initialMapHeight = $(React.findDOMNode(this.refs.map)).height();
        }
    },

    fetchWidget: function() {
        var me = this,
            props = this.props;

        this.setState({
            loading: true
        });

        if(props.widgetLookup){
            props.model.fetch({
                lookup: props.widgetLookup
            }).then(me.onWidgetLoad).fail(me.onLoadFinished).fail(me.onWidgetError);
        } else if (props.widget) {
            props.model.set(props.model.parse(props.widget));
            me.onWidgetLoad();
        } else if (props.widgetId === 'new') {
            me.fetchProjects().fail(me.onLoadFinished);
        } else if (props.model && !props.model.isLoaded() && !props.model.isLoading()) {
            props.model.set('id', props.widgetId);
            props.model.fetch({
                agentEmail: props.agentEmail
            }).then(me.onWidgetLoad).fail(me.onLoadFinished).fail(me.onWidgetError);
        } else if (props.model && !props.model.isLoaded()) {
            props.model.once('sync', me.onWidgetLoad);
            props.model.once('error', me.onWidgetError);
        } else if (props.model) {
            me.onWidgetLoad();
        }
    },

    onWidgetLoad: function() {
        var me = this,
            props = me.props,
            collection = props.model.listingsCollection;

        me.updateStateFromModel();
        me.centerMapOnWidget();

        me.setState({
            error: undefined
        });

        if (props.mode === 'view' && me.getWidgetType() !== 7 && !collection.isLoaded() && !collection.isLoading()) {
            me.fetchProjects({
                data: {
                    widgetId: props.model.id
                }
            }).then(me.centerMapOnWidget());
        } else if(props.mode === 'view' && collection.length === 0 && collection.isLoaded() && !collection.isLoading()) {
            me.onProjectError(true);
            me.onLoadFinished();
        }
    },

    updateStateFromModel: function() {
        var me = this,
            data = me.props.model.toJSON(),
            city = data.cities,
            props = this.props;

        // Only show the selection for a single city atm.
        if (_.isArray(city) && city.length === 1) {
            city = city[0].id;
        }

        me.setState({
            loaded: true,
            loading: false,
            country: data.country && data.country.id,
            state: data.state && data.state.id,
            metro: data.metroArea && data.metroArea.id,
            city: city || undefined,
            developer: data.developer && data.developer.id,
            hideMap: this.shouldHideMap(),
            showFilters: this.props.hideFilters !== true && this.getWidgetType() === 7
        });

        if(this.getWidgetType() !== 7 && me.props.mode !== 'create'){
            me.props.model.listingsCollection.mode = 'local';
        }

        if (!this.state.hideMap && me.refs.map) {
            me.refs.map.centerMap([data.centerPoint.latitude, data.centerPoint.longitude], data.mapZoomLevel);
        }

        props.model.listingsCollection.service = (props.mode === 'create' && this.getWidgetType() !== 7) ? 'AGENT_PORTAL' : undefined;
    },

    centerMapOnWidget: function(force) {
        var props = this.props,
            model = props.model,
            centerPoint = model.get('centerPoint'),
            zoom = model.get('mapZoomLevel'),
            defaults = model.defaults();

        if (!this.isMounted() || this.state.hideMap || !this.state.createMap) {
            return;
        }

        if (force || !this.state.showFilters && props.model.listingsCollection.length > 0) {
            this.refs.map.centerMap();
        } else if (this.checkIfFiltersChangedFromWidget() && props.model.listingsCollection.length > 0) {
            this.refs.map.centerMap();
        } else if (centerPoint.latitude !== defaults.centerPoint.latitude || centerPoint.longitude !== defaults.centerPoint.longitude) {
            // Only centerMap on lat / long if the lat / long aren't the defaults set in models/Widget.js. Without the map the lat/long
            // and zoom do not get adjusted as filters change so we want the auto bounds always on.
            this.refs.map.centerMap([centerPoint.latitude, centerPoint.longitude], zoom);
        } else {
            this.refs.map.centerMap();
        }
    },

    checkIfFiltersChangedFromWidget: function() {
        var changed = false,
            widget = this.props.model.toJSON(),
            state = this.state;

        if (state.country) {
            changed = !widget.country || widget.country.id != state.country;
        }

        if (!changed && state.state !== undefined) {
            changed = !widget.state || widget.state.id != state.state;
        }

        if (!changed && state.metro !== undefined) {
            changed = !widget.metroArea || widget.metroArea.id != state.metro;
        }

        if (!changed && state.city !== undefined) {
            changed = !widget.city || widget.city != state.city;
        }

        if (!changed && state.developer !== undefined) {
            changed = !widget.developer || widget.developer.id != state.developer;
        }

        return changed;
    },

    fetchProjects: function(options) {
        var me = this,
            props = me.props,
            model = props.model,
            state = me.state,
            city = state.city,
            listingXhr,
            listingMapXhr,
            listingMapOptions,
            xhrs;

        options = options || {};

        me.setState({
            loading: true
        });

        if (_.isArray(city)) {
            city = _.map(city, function(m) {
                return m.id;
            });
        }

        options = _.extend({
            pageNum: state.pageNum,
            data: me.getWidgetType() === 7 ? {
                country: state.country || undefined,
                state: state.state || undefined,
                metro_area: state.metro || undefined,
                city: city || undefined,
                developer: state.developer || undefined,
                filterForWidget: true,
                active: true,
                sortOrder: state.orderBy || undefined,
                name: state.community || undefined,
                companyName: state.community || undefined,
                format: props.format
            } : {
                filterForWidget: true,
                active: true,
                widgetId: props.mode === 'view' ? model.id : undefined,
                sortOrder: state.orderBy || undefined
            },
            traditional: true
        }, options);

        listingXhr = model.listingsCollection.fetch(_.deepClone(options, 2));

        if(options.pageNum === 1 && state.showMap) {
            listingMapOptions = _.deepClone(options, 2);
            listingMapOptions.data.format = 'project-widget-map';
            listingMapXhr = props.mapCollection.fetch(listingMapOptions);
        }

        if(listingMapXhr) {
            xhrs = $.when(listingXhr, listingMapXhr);
        } else {
            xhrs = $.when(listingXhr);
        }

        return xhrs.always(me.onLoadFinished).always(me.onProjectsLoad).fail(me.onProjectError);
    },

    onWidgetError: function() {
        var props = this.props,
            state = this.state,
            listingsCollection = props.model.listingsCollection,
            error = {
                widgetId: props.model.id, 
                agentId: props.model.agentModel.id, 
                type: 'widget', 
                pageSize: listingsCollection.pageSize, 
                pageNum: listingsCollection.pageNum, 
                country: state.country, 
                state: state.state, 
                city: _.isArray(state.city) ? _.map(state.city, function(c){ return c.id }).join(',') : state.city,
                metroArea: state.metro, 
                developer: state.developer,
                url: window.location.href,
                widgetLoadError: true
            };

        this.setState({
            error: error
        });
    },

    onProjectError: function(isEmpty) {
        var props = this.props,
            state = this.state,
            listingsCollection = props.model.listingsCollection,
            error = {
                widgetId: props.model.id, 
                agentId: props.model.agentModel.id, 
                type: 'widget', 
                pageSize: listingsCollection.pageSize, 
                pageNum: listingsCollection.pageNum, 
                country: state.country, 
                state: state.state, 
                city: _.isArray(state.city) ? _.map(state.city, function(c){ return c.id }).join(',') : state.city, 
                metroArea: state.metro, 
                developer: state.developer,
                url: window.location.href,
                emptyProjects: isEmpty === true,
                projectsLoadError: true
            };

        this.setState({
            error: error
        });
    },

    onLoadFinished: function() {
        this.setState({
            loading: false
        });
    },

    onProjectsLoad: function() {
        var props = this.props,
            listingsCollection = props.model.listingsCollection;

        if(listingsCollection.length === 0) {
            this.onProjectError(true);
        } else {
            this.setState({
                error: undefined
            });
        }
    },

    onMarkerClick: function(e, project) {
        var me = this;
        me.setState({
            loading: true
        });
        project.fetch().then(function() {
            me.setState({
                project: project
            });
        }).always(function() {
            me.setState({
                loading: false
            });
        });
    },

    pageGallery: function(e, page) {
        var pageNum = parseInt(e.currentTarget.getAttribute('data-page'));
        this.props.model.listingsCollection.pageNum = pageNum;
        this.setState({
            pageNum: pageNum,
            trackerId: (new Date()).getTime()
        });
    },

    showProjectPopup: function(project) {
        var me = this;
        me.setState({
            loading: true
        });
        project.fetch({
            data: {
                format: this.props.detailFormat || undefined
            }
        }).then(function() {
            me.setState({
                project: project
            });
        }).always(function() {
            me.setState({
                loading: false
            });
        });
    },

    hidePopup: function() {
        this.setState({
            project: undefined
        });
    }
});

module.exports = Widget;
