"use strict";

var React = require('react'),
    ContactAgentShield = require('../models/ContactAgentShield'),
    Cmp = require('../components'),
    BS = require('react-bootstrap'),
    Validation = require('../partials/validation');

var ContactForm = React.createClass({displayName: "ContactForm",

    updateState: function(props) {
        props = props || this.props;

        var model = props.contactModel;

        model.clear();
        model.set({
            name: props.name,
            email: props.email,
            code: props.code,
            subject: props.subject,
            message: props.message
        });

        return {
            submitted: false
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    getDefaultProps: function() {
        return {
            contactModel: new ContactAgentShield(),
            name: undefined,
            email: undefined,
            code: undefined,
            errorCode: undefined,
            message: undefined
        };
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function(props) {
        this.setState(this.updateState(props));
    },
    onSaveClick: function(e) {
        var me = this,
            props = me.props,
            values = me.refs.form.getValues(),
            pageVisited = props.url || window.location.href;

        if(pageVisited){
            values.message = 'Page Visited: ' + pageVisited + '\r\n' + values.message;
        }

        props.contactModel.save(values).then(function() {
            if(props.onSubmit){
                props.onSubmit(props.contactModel);
            }
        });
    },

    render: function() {
        var props = this.props;

        return (
            React.createElement(Validation.Form, {ref: "form", horizontal: false, className: "margin-bottom-15", model: props.contactModel, onSubmit: this.onSaveClick, validateOnLoad: false}, 

                React.createElement(Validation.Input, {type: "text", placeholder: "Enter name", name: "name", label: "Name:"}), 

                React.createElement(Validation.Input, {type: "text", placeholder: "Enter email", name: "email", label: "Email address:"}), 

                React.createElement(Validation.Input, {type: "text", placeholder: "Enter phone number", name: "phone", label: "Phone Number:"}), 

                React.createElement(Validation.Input, {rows: "3", type: "textarea", placeholder: "Enter message", name: "message", label: "Message:"}), 

                React.createElement(Cmp.ValidationMessage, null), 
                React.createElement(BS.ButtonToolbar, null, 
                    React.createElement(Cmp.Button.Save, null, "Submit"), 
                    React.createElement("span", {className: "help-block display-inline-block"}, " Thank you in advance for your help!")
                )
            )
        );
    }
});
module.exports = ContactForm;
