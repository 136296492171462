"use strict";

/**
 * NOTES
 *
 * - Build using:
 * 		- https://github.com/thedersen/backbone.validation
 * 		- https://github.com/christianalfoni/formsy-react
 *
 * - ValidationInput must be wrapped by a ValidationForm in order to work fully
 * - Validations are defined in the model using backbone.validation JSON validation schema https://github.com/thedersen/backbone.validation
 * - Validations can be referenced in a deep linked state:
 * 		- validation : { 'contactInfo.firstName' : required: true }
 * - ValidationInput gets it's validation via the [name] property
 * - ValidationInput's [name] property supports deeply nested data from model:
 * 		- name="contactInfo.firstName" 
 * 		- This name also needs to match the validation KEY given in the Model validation object
 * - Currently no built-in support for async validations but support could be added.
 * - Any component could use the validation so long as it:
 * 		- Has a [name] property which matches one of the validations
 * 		- Calls attachToForm and detachFromForm on componentDidMount / componentWillUnmount
 * 		- Supports passing a [valueLink] property which ValidationForm will pass into the component and works just like standard valueLink
 *
 * Implementation Notes:
 * - ModelMixin may be unnecessary in form views unless there is something else that relies on it
 * - modelLinkToState is unnecessary as the Validation has it built-in
 * - LinkedStateMixin may be unnecessary in form views unless there is something else that relies on it
 * - Form values are held on the Valdation.Form component by calling form.getValues()
 * - valueLinks are genereted using the [name] property automatically
 * - [formLinks] property allows you to bind additional form data to components. Unlike in input [name] [formLink] references need to include the prefix 'model.' if they want to access the model state. 
 * - [formLinks] takes an object like so:
 * 		- { disabled: '!isValid', countryId: 'model.countryId' }
 *
 * - Curently performance is in need of improvement
 * - Skip non input based components for now. Add the validations into the model and make notes of the screens that have them.
 */

module.exports = {
    Input: require('./ValidationInput'),
    Form: require('./ValidationForm'),
    Mixin: require('./ValidationMixin')
};
