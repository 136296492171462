"use strict";

var React = require('react'),
    ReactRouter = require('react-router'),
    Constants = require('../constants'),
    ReactRouterLink = ReactRouter.Link,
    _ = require('underscore');

var Link = React.createClass({displayName: "Link",
    render: function() {
        var props = this.props,
            query = _.extend({}, _.result(Constants, 'query'), props.query);

        return (
            React.createElement(ReactRouterLink, React.__spread({},  props, {query: query}))
        );
    }
});

module.exports = Link;
