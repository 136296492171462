"use strict";

var Collection = require('./Collection'),
    Constants = require('../constants'),
    Broker = require('../models/Broker');

var Brokers = Collection.extend({
    url: Broker.prototype.urlRoot,
    model: Broker
});

module.exports = Brokers;
