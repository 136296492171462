"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    DocumentTitle = require('react-document-title'),
    BS = require('react-bootstrap'),
    Cmp = require('../../../components'),
    PageHeader = require('../../../partials/PageHeader'),
    FloorPlanModel = require('../../../models/FloorPlan'),
    PageFooter = require('../../../partials/PageFooter'),
    Validation = require('../../../partials/validation');

var ProjectWizardFloorPlan = React.createClass({displayName: "ProjectWizardFloorPlan",

    mixins: [
        ReactRouter.Navigation
    ],

    contextTypes: {
        router: React.PropTypes.func
    },

    getDefaultProps: function() {
        return {
            entity: new FloorPlanModel()
        };
    },

    updateState: function() {
        this.props.entity.set({
            listing: {
                id: this.props.model.id
            }
        });
        return {
            success: undefined
        }
    },

    getInitialState: function() {
        return this.updateState();
    },

    /**
     * This is to ensure that when subroutes transistion they trigger recheck router state and render
     */
    componentWillReceiveProps: function() {
        this.reset();
    },

    render: function() {
        var me = this,
            props = me.props,
            model = props.model,
            state = me.state,
            defaultControlAttr = {
                type: 'text',
                labelClassName: 'label'
            },
            bedroomItems = [
                { id: 0, name : 'Studio'},
                { id: 1, name : '1'},
                { id: 2, name : '2'},
                { id: 3, name : '3'},
                { id: 4, name : '4'},
                { id: 5, name : '5+'}
            ],
            bathRoomItems = [
                { id: 0, name : '0'},
                { id: 1, name : '1'},
                { id: 2, name : '2'},
                { id: 3, name : '3'},
                { id: 4, name : '4'},
                { id: 5, name : '5+'}
            ],
            priceAttr = {
                labelClassName: 'col-xs-2',
                wrapperClassName: 'col-xs-10'
            };


        return (
            React.createElement("div", {className: "wrapper widgets"}, 
                React.createElement(DocumentTitle, {title: "NCH - Create Listing - Add Floor Plan"}), 
                React.createElement(PageHeader, null), 
                React.createElement("div", {className: "breadcrumbs"}, 
                    React.createElement("div", {className: "container"}, 
                        React.createElement("h1", {className: "pull-left"}, "Create Listing - Step 2B: Add Floor Plan"), 
                        React.createElement("ul", {className: "pull-right breadcrumb"}, 
                            React.createElement(Cmp.NavLink, {to: "ProjectList"}, "Projects"), 
                            React.createElement(Cmp.NavLink, {to: "ProjectWizardSteps", params: { id: model.id}}, "Additional Info"), 
                            React.createElement(Cmp.NavLink, {to: "ProjectWizardFloorPlan", params: { id: model.id}}, "Add Floor Plan")
                        )
                    )
                ), 
                React.createElement("div", {className: "container content"}, 
                    state.success && (
                        React.createElement(BS.Alert, {bsStyle: "success", className: "text-center"}, 
                            React.createElement("h4", null, "Floor Plan Added Successfully"), 
                            React.createElement("div", {className: "row"}, 
                                React.createElement(BS.ButtonToolbar, {className: "center-block-inline"}, 
                                    React.createElement(Cmp.Button.Save, {onClick: this.reset}, "Add Another Floor Plan"), 
                                    React.createElement("span", {className: "pull-left btn-divider"}, "or"), 
                                    React.createElement(Cmp.Button.Cancel, {onClick: this.gotoSteps}, "Continue ", React.createElement("i", {className: "fa fa-arrow-right"}))
                                )
                            )
                        )
                    ), 
                    !state.success && (
                        React.createElement(Validation.Form, {ref: "form", model: props.entity, onSubmit: this.onSaveClick}, 
                            React.createElement("h1", null, "Add Floor Plan"), 

                            React.createElement(Validation.Input, React.__spread({name: "floorPlanName", label: "Floor Plan Name:"},  defaultControlAttr, {placeholder: "Enter a Name for this Floor Plan"})), 
                            React.createElement(Validation.Input, React.__spread({name: "description", label: "Description"},  defaultControlAttr, {type: "textarea", placeholder: "Provide a description for this Floor Plan", className: "floor-plan-description"})), 

                            React.createElement(Validation.Input, React.__spread({},  defaultControlAttr, {type: "", label: "Price Range"}), 
                                React.createElement(BS.Row, null, 
                                    React.createElement(BS.Col, {md: 6}, 
                                        React.createElement(Validation.Input, React.__spread({name: "startingPrice", label: "From"},  defaultControlAttr,  priceAttr, {placeholder: "Starting Price"}))
                                    ), 
                                    React.createElement(BS.Col, {md: 6}, 
                                        React.createElement(Validation.Input, React.__spread({name: "endingPrice", label: "To"},  defaultControlAttr,  priceAttr, {placeholder: "Max Price"}))
                                    )
                                )
                            ), 
                            React.createElement(Cmp.RadioGroup, React.__spread({valueAsInteger: true},  defaultControlAttr, {label: "Bedrooms", name: "bedrooms", type: "radio", items: bedroomItems, className: "bedrooms"})), 
                            React.createElement(Cmp.RadioGroup, React.__spread({valueAsInteger: true},  defaultControlAttr, {label: "Full Bathrooms", name: "fullBaths", type: "radio", items: bathRoomItems})), 
                            React.createElement(Cmp.RadioGroup, React.__spread({valueAsInteger: true},  defaultControlAttr, {label: "Half Bathrooms", name: "halfBaths", type: "radio", items: bathRoomItems})), 

                            React.createElement(Validation.Input, React.__spread({},  defaultControlAttr, {name: "livingAreaSqft", label: "Living Area (SqFt)"},  defaultControlAttr, {placeholder: "Enter the size of the Living Area"})), 
                            React.createElement(Validation.Input, React.__spread({},  defaultControlAttr, {name: "totalAreaSqft", label: "Total Area (SqFt)"},  defaultControlAttr, {placeholder: "Enter the total size of the Floor Plan"})), 

                            React.createElement(Cmp.Picker.File, {ref: "filePicker", name: "files", url: props.entity.url(), label: "Floor Plan Files", onComplete: this.onFileUpload}), 

                            React.createElement(Cmp.ValidationMessage, null), 
                            React.createElement(BS.ButtonToolbar, null, 
                                React.createElement(Cmp.Button.Cancel, {onClick: this.gotoSteps}, React.createElement("i", {className: "fa fa-arrow-left"}), " Back to Steps"), 
                                React.createElement(Cmp.Button.Save, {className: "pull-right"}, "Save & Continue ", React.createElement("i", {className: "fa fa-arrow-right"}))
                            )
                        )
                    )
                ), 
                React.createElement(PageFooter, null)
            )
        );
    },

    onSaveClick: function() {
        var me = this,
            filePicker = me.refs.filePicker,
            model = this.props.entity,
            isNew = model.isNew();

        model.save(me.refs.form.getValues()).then(function(m, e, r) {
            if(isNew){
                filePicker.setIdUrlPart(model.id);
            }
            if (filePicker.hasFiles()) {
                filePicker.startUpload();
            } else {
                me.setState({success : true }, function() {
                    me.refs.form && me.refs.form.forceUpdate();
                });
            }
        });

    },

    onFileUpload: function(success) {
        var me = this;
        if (success) {
            me.props.entity.clear();
            me.setState({success : true }, function() {
                me.refs.form && me.refs.form.forceUpdate();
            });
        };
    },

    gotoSteps: function() {
        this.transitionTo('ProjectWizardSteps', {
            id: this.props.model.id
        });
    },

    reset: function() {
        var me = this;
        me.setState(me.updateState(), function() {
            me.refs.form && me.refs.form.forceUpdate();
        });
    }
});
module.exports = ProjectWizardFloorPlan;
