"use strict";

var React = require('react'),
    BS = require('react-bootstrap'),
    cx = require('classnames'),
    _ = require('underscore'),
    $ = require('jquery'),
    Lead = require('../../models/Lead'),
    Constants = require('../../constants'),
    Validation = require('../../partials/validation'),
    Cmp = require('../../components'),
    URI = require('urijs');


var ProjectContactForm = React.createClass({displayName: "ProjectContactForm",

    getDefaultProps: function() {
        return {
            onSubmit: undefined,
            model: undefined,
            agent: undefined,
            widget: undefined,
            hideHeader: false,
            lead: new Lead(),
            singleColumn: false
        };
    },

    getInitialState: function() {
        this.resetLead();
        this.props.lead.set('isBuilder', this.props.model && this.props.model.listingModelType === 'developer');
        return {
            success: undefined
        };
    },

    componentWillReceiveProps: function(newProps) {
        if (newProps.model !== this.props.model) {
            this.reset();
            newProps.lead.set('isBuilder', newProps.model && newProps.model.listingModelType === 'developer');
        }
    },

    render: function() {
        var me = this,
            props = me.props,
            agent = props.agent ? props.agent.toJSON() : undefined,
            developer = props.model.developerModel && props.model.developerModel.id ? props.model.developerModel.toJSON() : undefined,
            className = cx('row', props.className),
            defaultAttrs = {
                type: 'text',
                trigger: 'onChange',
                hideLabel: props.hideFormLabels
            },
            columnClassName = cx({
                'col-xs-12': true,
                'col-md-6': !props.singleColumn
            });

        return (
           React.createElement("div", {className: "row"}, 
                React.createElement("div", {className: "col-xs-12", style: { display: props.hideHeader ? 'none' : 'block'}}, 
                    React.createElement("div", {className: "headline headline-inverse"})
                ), 
                    
                React.createElement("div", {className: "col-xs-12 col-md-7 padding-top-15"}, 
                    React.createElement("div", {className: "row margin-bottom-15"}, 
                        /* [AS-1742] - widgets: Agent images are showing. Should not be displaying yet. */
                        agent && agent.agentLevel === 'PRO' && agent.profileImage && (
                            React.createElement("div", {className: "col-xs-12 col-sm-5"}, 
                                React.createElement("div", {className: "profile-image margin-bottom-15", style: { border: 0}}, 
                                    React.createElement("img", {src: agent && agent.profileImage ? agent.profileImage.replace('http://', '//').replace('//www.agentshield.com.s3.amazonaws.com', '//s3.amazonaws.com/www.agentshield.com') : Constants.placeholder.replace('http://', '//').replace('//www.agentshield.com.s3.amazonaws.com', '//s3.amazonaws.com/www.agentshield.com')})
                                )
                            )
                        ), 
                        React.createElement("div", {className: cx('col-xs-12 profile-details', { 'col-sm-7' : agent && agent.agentLevel === 'PRO' && agent.profileImage })}, 
                            agent && (
                                React.createElement("div", null, 
                                    React.createElement("p", null, 
                                        React.createElement("span", null, agent.name), 
                                        agent.agentLevel === 'PRO' && agent.contactInfo.phoneMobile && (
                                            React.createElement("span", null, React.createElement("br", null), React.createElement("i", {className: "fa fa-phone"}), " ", agent.contactInfo.phoneMobile)
                                        ), 
                                        agent.name !== agent.brokerageName && (
                                            React.createElement("span", null, React.createElement("br", null), agent.brokerageName)
                                        )
                                    )
                                )
                            ), 
                            developer && (
                                React.createElement("p", null, "Offered by: ", developer.name)
                            ), 
                            React.createElement("hr", null)
                        )
                    )
                ), 
                React.createElement("div", {className: "col-xs-12 col-md-5"}, 
                    React.createElement("h2", {className: "headline-header headline-header-inverse", style: { display: props.hideHeader ? 'none' : 'block'}}, "Request More Information"), 
                    this.state.success && (
                        React.createElement(BS.Alert, {bsStyle: "success"}, 
                            this.state.success
                        )
                    ), 
                    React.createElement("div", {style: {display: me.state.success ? 'none' : 'block'}}, 
                        React.createElement(Validation.Form, {ref: "form", horizontal: false, model: props.lead, validateOnLoad: false}, 
                            React.createElement("p", null, props.message || ('Don&#39;t miss out! Complete the form to get more details about '+ props.model.get('name') + 'including any buyer incentives and specials.')), 
                            props.singleColumn ? (
                                React.createElement("div", {className: "row"}, 
                                    React.createElement("div", {className: columnClassName}, 
                                        React.createElement(Validation.Input, React.__spread({},  defaultAttrs, {tabIndex: "1", name: "contactInfo.firstName", label: "First Name:"})), 
                                        React.createElement(Validation.Input, React.__spread({},  defaultAttrs, {tabIndex: "2", name: "contactInfo.lastName", label: "Last Name:"}))
                                    ), 
                                    React.createElement("div", {className: columnClassName}, 
                                        React.createElement(Validation.Input, React.__spread({},  defaultAttrs, {tabIndex: "3", name: "contactInfo.email", label: "Email Address:"})), 
                                        React.createElement(Validation.Input, React.__spread({},  defaultAttrs, {tabIndex: "4", name: "contactInfo.phoneHome", label: "Phone Number: (optional)"}))
                                    )
                                )
                            ) : (
                                React.createElement("div", {className: "row"}, 
                                    React.createElement("div", {className: columnClassName}, 
                                        React.createElement(Validation.Input, React.__spread({},  defaultAttrs, {tabIndex: "1", name: "contactInfo.firstName", label: "First Name:"})), 
                                        React.createElement(Validation.Input, React.__spread({},  defaultAttrs, {tabIndex: "3", name: "contactInfo.email", label: "Email Address:"}))
                                    ), 
                                    React.createElement("div", {className: columnClassName}, 
                                        React.createElement(Validation.Input, React.__spread({},  defaultAttrs, {tabIndex: "2", name: "contactInfo.lastName", label: "Last Name:"})), 
                                        React.createElement(Validation.Input, React.__spread({},  defaultAttrs, {tabIndex: "4", name: "contactInfo.phoneHome", label: "Phone Number: (optional)"}))
                                    )
                                )
                            ), 
                            React.createElement(Validation.Input, React.__spread({},  defaultAttrs, {tabIndex: "5", type: "textarea", name: "comments", label: "Message: (optional)"})), 
                            React.createElement(Validation.Input, {type: "hidden", name: "agent.id"}), 
                            React.createElement(Validation.Input, {type: "hidden", name: "widget.id"}), 
                            React.createElement(Validation.Input, {type: "hidden", name: "listing.id"}), 
                            React.createElement(Validation.Input, {type: "hidden", name: "contactMethod"}), 
                            React.createElement(Validation.Input, {type: "hidden", name: "collectedFromUrl"}), 
                            React.createElement(Cmp.ValidationMessage, {message: "** Some required information is missing."}), 
                            React.createElement(BS.ButtonToolbar, null, 
                                React.createElement(Cmp.Button.Save, {onClick: this.onSaveClick, className: "pull-right btn-block"}, React.createElement("i", {className: "fa fa-envelope"}), " Request Info")
                            )
                        )
                    )
                )
            )
        );
    },

    onSaveClick: function(e) {
        var me = this,
            props = me.props,
            data = me.refs.form.getValues(),
            leadUrl;

        e.preventDefault();

        props.lead.save(data).then(me.onSuccessfulSubmit);
    },

    reset: function() {
        this.setState(this.getInitialState());
        this.resetLead();
    },

    resetLead: function() {
        this.props.lead.clear();
        this.props.lead.set({
            widget: this.props.widget ? {
                id: this.props.widget.id
            } : {},
            agent: {
                id: this.props.agent.id
            },
            contactMethod: 'Widget Contact Form',
            listing: {
                id: this.props.model.id
            }
        });
    },

    onSuccessfulSubmit: function() {
        this.setState({
            success: 'Thank you for your inquiry. Someone will be in touch with you shortly!'
        });
    }
});

module.exports = ProjectContactForm;
