"use strict";

var Constants = require('../../constants'),
    Model = require('../Model'),
    moment = require('moment'),
    $ = require('jquery'),
    Projects = require('../../collections/Projects');

var ListingOverview = Model.extend({
    urlRoot: Constants.restPath + "/reports/listing-details-overview",
    hasMany: {},
    defaults: function() {
        var to = moment.utc(),
            from = moment.utc(this.project ? this.project.get('dateCreated') : undefined);

        return {
            startDate: from.valueOf(),
            endDate: to.valueOf()
        };
    },

    fetch: function(options) {
        options = options || {};
        options.data = options.data || {};

        options.data.to = moment.utc(this.get('endDate'), 'x').format('YYYY-MM-DD');
        options.data.from = moment.utc(this.get('startDate'), 'x').format('YYYY-MM-DD');
        options.data.listingId = this.get('listingId');

        Model.prototype.fetch.call(this, options);
    },

    parse: function(response) {
        var data = Model.prototype.parse.apply(this, arguments);

        if (data.startDate) {
            data.startDate = moment.utc(data.startDate, 'YYYY-MM-DD').format('x');
        }

        if (data.endDate) {
            data.endDate = moment.utc(data.endDate, 'YYYY-MM-DD').format('x');
        }

        if (data.topAgents) {
            data.topAgents = _.sortBy(data.topAgents, function(agent) {
                // Sort by clicks/views descending.
                return -agent.views;
            });
        }

        return data;
    }
}, {});

module.exports = ListingOverview;
