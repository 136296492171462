"use strict";

var React = require('react/addons'),
    ReactRouter = require('react-router'),
    DocumentTitle = require('react-document-title'),
    cx = require('classnames'),
    FluxBone = require('../../mixins/FluxBone'),
    BS = require('react-bootstrap'),
    ProjectModel = require('../../models/Project'),
    Cmp = require('../../components'),
    Validation = require('../../partials/validation'),
    Roles = require('../../mixins/Roles'),
    Constants = require('../../constants'),
    CancelProjectEdit = require('../../partials/project/CancelProjectEdit'),
    RequiredMessage = require('../../partials/RequiredMessage'),

    ProjectsMap = require('../../partials/project/ProjectsMap');

var ProjectDetails = React.createClass({displayName: "ProjectDetails",

    mixins: [
        ReactRouter.Navigation,
        Roles
    ],

    statics: {
        willTransitionFrom: function(transition, me, callback) {
            var continueAnyways;

            if (me.refs && me.refs.form && me.refs.form.isDirty()) {
                continueAnyways = window.confirm(me.refs.form.getDirtyMessage());
                if (continueAnyways) {
                    callback();
                } else {
                    transition.cancel();
                    me.goBack();
                }
            } else {
                callback();
            }
        }
    },

    contextTypes: {
        router: React.PropTypes.func
    },

    getInitialState: function() {
        return {
            showCancelDialog: false
        };
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            defaultControlAttr = {
                type: 'text',
                labelClassName: 'col-md-3',
                wrapperClassName: 'col-md-9'
            },
            roles = Constants.roles,
            listingImage = props.model.get('image');

        if (listingImage) {
            listingImage = listingImage + "?rand=" + (Math.random() * 1000);
        } else {
            listingImage = Constants.placeholder;
        }

        return (
            React.createElement("div", null, 
                 React.createElement(Validation.Form, {ref: "form", model: props.model, className: "form form-horizontal", onSubmit: this.onSaveClick, readOnly: props.model.isReadOnly(), handleDirty: true}, 
                    React.createElement("h4", null, "Listing Information: ", React.createElement("br", null), React.createElement("small", null, "Edit details about your community.")), 
                    React.createElement(RequiredMessage, null), 
                    React.createElement(Validation.Input, React.__spread({name: "realName", label: "Community Name:"},  defaultControlAttr)), 
                    React.createElement(Validation.Input, React.__spread({name: "website", label: "Community URL:"},  defaultControlAttr)), 
                    React.createElement(Cmp.Select.Listing, {name: "listingTypes"}), 
                    React.createElement(Validation.Input, React.__spread({name: "minPrice", label: "Starting Price (USD):"},  defaultControlAttr)), 
                    React.createElement(Validation.Input, React.__spread({name: "maxPrice", label: "Max Price (USD):"},  defaultControlAttr)), 
                    React.createElement(Validation.Input, React.__spread({name: "numberOfUnits", label: "Number of Units:"},  defaultControlAttr)), 
                    React.createElement(Validation.Input, React.__spread({name: "numberOfFloors", label: "Number of Floors:"},  defaultControlAttr)), 
                    React.createElement(Validation.Input, React.__spread({name: "developmentPhase", label: "Construction Phase:"},  defaultControlAttr, {type: "select"}), 
                        React.createElement("option", {value: ""}, "Select"), 
                        React.createElement("option", {value: "Pre-Construction"}, "Pre-Construction"), 
                        React.createElement("option", {value: "Under Construction"}, "Under Construction"), 
                        React.createElement("option", {value: "Completed"}, "Completed")
                    ), 
                    React.createElement(Validation.Input, React.__spread({name: "summary", rows: "5", label: "Summary:"},  defaultControlAttr, {type: "textarea"})), 
                    /* <Validation.Input name="description" rows="5" label="Project Description:" {...defaultControlAttr} type="textarea"/> */

                    React.createElement("h4", null, "Community Location: ", React.createElement("br", null), React.createElement("small", null, "Where is the community located? Enter the address for the community.")), 
                    React.createElement("div", {className: "col-xs-12"}, 
                        React.createElement(Validation.Input, {type: "checkbox", trigger: "onChange", ignoreChange: true, useCheckedLink: true, name: "customLocation", label: "This community does not have an address yet."})
                    ), 
                    React.createElement("p", {key: "addressLabelCustom", formLinks: { "aria-hidden": this.shouldShowAddressLabel.bind(null, true)}}, "Enter the address of your community"), 
                    React.createElement("p", {key: "addressLabel", formLinks: { "aria-hidden": this.shouldShowAddressLabel.bind(null, false)}}, "Begin by entering as much of the location as you know, like city, state, neighborhood, etc"), 
                    React.createElement(Cmp.LocationSelect, React.__spread({ref: "locationInfo", name: "locationInfo", label: "Address:"},  defaultControlAttr, {fieldLinks: { geoLink: 'geoPosition'}})), 
                    /* <Validation.Input ref="address1" name="locationInfo.address1" label="Address:" {...defaultControlAttr}/> */
                    React.createElement(Validation.Input, React.__spread({name: "locationInfo.address2", label: "Unit / Suite:"},  defaultControlAttr, {formLinks: { "hidden": 'model.customLocation'}})), 
                    React.createElement("div", {key: "mapWrap", className: "form-group", formLinks: { "aria-hidden": '!model.customLocation'}}, 
                        React.createElement("div", {className: "col-xs-12"}, 
                            React.createElement("p", null, "Drag the map so the marker is placed where your community is located.")
                        ), 
                        React.createElement("div", {className: "col-xs-12"}, 
                            React.createElement(ProjectsMap, {ref: "map", name: "map", markCenter: true, className: "margin-bottom-15", reverseGeoQuery: false, 
                                fieldLinks: { 
                                    latitudeLink: 'geoPosition.latitude', 
                                    longitudeLink: 'geoPosition.longitude', 
                                    locationLink: 'locationInfo'
                                }, 
                                formLinks: { 
                                    "aria-hidden": '!model.customLocation', 
                                    zoom: this.getMapZoom, 
                                    geoQuery: this.getGeoQueryFormLink, 
                                    defaultGeoPosition: this.getDefaultGeoPosition,
                                    readOnly: '!model.customLocation', 
                                    disabled: '!model.customLocation', 
                                    updateLocation: '!!model.customLocation'
                                }, id: "project-add-edit-details-map", style: { height: '300px', width: '100%'}})
                        )
                    ), 
                    /*
                    <Cmp.Select.Country ref="country" invalidMsg="Country is required" name="locationInfo.city.country.id" label="Country:" {...defaultControlAttr}/>
                    <Cmp.Select.State ref="state" name="locationInfo.city.state.id" invalidMsg="State / Region / Province is required" formLinks={{countryId:'model.locationInfo.city.country.id'}} label="State / Region:" {...defaultControlAttr}/>
                    <Cmp.Select.City ref="city" name="locationInfo.city.id" invalidMsg="City / Town is required" formLinks={{stateId:'model.locationInfo.city.state.id'}} label="City / Town:" {...defaultControlAttr}/>
                    <Validation.Input ref="zip" name="locationInfo.zip" label="Zip / Postal Code:" {...defaultControlAttr}/>
                    */
                    React.createElement(Cmp.ValidationMessage, null), 
                    React.createElement(BS.ButtonToolbar, {className: "no-margin"}, 
                        React.createElement(Cmp.Button.Cancel, {onClick: this.onCancelClick}), 
                        React.createElement(Cmp.Button.Save, {className: "pull-right"})
                    )
                ), 
                React.createElement(CancelProjectEdit, {model: props.model, show: state.showCancelDialog, onHide: this.onHideCancelDialog, onCancel: this.doFormReset})
            )
        );
    },

    shouldShowAddressLabel: function(customLocation, state) {
        var show = false;
        if (state.model && state.model.customLocation && customLocation) {
            show = true;
        } else if(!customLocation && (!state.model || !state.model.customLocation)) {
            show = true;
        }
        return show;
    },

    getMapZoom: function(state) {
        var zoom = 10;

        // if(state.customLocation || this.props.model.get('geoPosition')){
        //     zoom = 10;
        // }

        return zoom;
    },

    getGeoQueryFormLink: function(formState) {
        var me = this,
            refs = me.refs,
            form = refs.form,
            model = me.props.model,
            modelState = formState.model,
            locationInfo = model.locationInfoModel,
            geoPosition = model.get('geoPosition'),
            value;

        if (modelState && modelState.customLocation && (modelState.locationInfo.address1 !== locationInfo.get('address1') || modelState.geoPosition.latitude !== geoPosition.latitude || modelState.geoPosition.longitude !== geoPosition.longitude) && refs.locationInfo) {
            value = refs.locationInfo.getDisplayValue() || undefined;
        }

        return value;
    },

    getDefaultGeoPosition: function(formState) {
        return this.props.model.get('geoPosition') || {};
    },

    onSaveClick: function(e) {
        var me = this,
            filePicker = me.refs.filePicker;

        this.props.model.save(this.refs.form.getValues()).then(function() {

        });
    },

    onFileUpload: function(uploader, file) {
        this.doFormReset();
    },

    doFormReset: function() {
        var me = this;
        this.props.model.fetch().then(function() {
            me.setState({
                showCancelDialog: false
            });
        });
    },

    onHideCancelDialog: function() {
        this.setState({
            showCancelDialog: false
        });

    },

    onCancelClick: function() {
        this.setState({
            showCancelDialog: true
        });
    }
});
module.exports = ProjectDetails;
