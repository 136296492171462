"use strict";

var React = require('react/addons'),
    BS = require('react-bootstrap'),
    _ = require('underscore'),
    cx = require('classnames'),
    $ = require('jquery');

var WidgetProjectSelector = React.createClass({displayName: "WidgetProjectSelector",
    getInitialState: function() {
        return {
            top: 0
        }
    },
    componentDidMount: function() {
        window.addEventListener('scroll', this.handleScroll);
    },

    componentWillUnmount: function() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    handleScroll: function(event) {
        var extraOffset = 3,
            $el = $(React.findDOMNode(this)),
            $parent = $el.parent(),
            height = $el.outerHeight(),
            parentTop = $parent.offset().top,
            parentHeight = $parent.height(),
            offset = height + extraOffset,
            target = event.target || event.srcElement,
            scrollTop = target.body.scrollTop,
            effectiveTop = 0;


        if (offset + scrollTop - parentTop > parentHeight) {
            effectiveTop = parentHeight - offset;
        } else {
            if (scrollTop > offset) {
                effectiveTop = scrollTop - offset;
            }
            if (scrollTop > parentTop) {
                effectiveTop = scrollTop - parentTop;
            }
        }


        this.setState({
            top: effectiveTop
        })
    },

    render: function() {
        var props = this.props,
            state = this.state;

        return (
            React.createElement("div", {className: cx('projects-selection', this.props.className), style: {'top' : state.top}}, 
                React.createElement("h4", null, "Selected"), 
                React.createElement("div", {className: "panel panel-default"}, 
                    _.map(props.selectionValue, function(project){
                        return (
                            React.createElement("div", {onClick: props.onDeselect.bind(null, project), key: project.id, className: "project-selection"}, 
                                React.createElement("i", {className: "icon-custom icon-sm rounded-x icon-bg-u icon-line fa fa-arrow-left"}), 
                                " ", project.get('name')
                            )
                        );
                    })
                )
            )
        );
    }
});
module.exports = WidgetProjectSelector;
