"use strict";

var React = require('react/addons'),
    SaveButton = require('./buttons/SaveButton'),
    CancelButton = require('./buttons/CancelButton'),
    BS = require('react-bootstrap'),
    _ = require('underscore'),
    $ = require('jquery'),
    Uploader = window.plupload && window.plupload.Uploader,
    Link = require('react-router').Link,
    cx = require('classnames'),
    filesize = require('filesize'),
    Validation = require('../partials/validation'),
    ImageThumbnail = require('./ImageThumbnail');

var FilePicker = React.createClass({displayName: "FilePicker",

    mixins: [
        Validation.Mixin
    ],

    getDefaultProps: function() {
        return {
            onComplete: undefined,
            value: undefined,
            valueLink: undefined,
            fileName: undefined,
            required: false,
            showRequired: true,
            multiple: false,
            trigger: 'onChange',
            readOnly: false,
            dropZoneText: (React.createElement("span", null, 
                React.createElement("i", {className: "fa fa-2x fa-cloud-upload blue-text"}), " Drop your file here to upload, or"
            )),
            mime_types: [],
            formGroup: true
        };
    },

    getInitialState: function() {
        return this.updateState();
    },

    updateState: function() {
        return {
            uploadProgress: undefined
        };
    },
    componentDidMount: function() {
        var uploadEl = React.findDOMNode(this.refs.fileUploadEl),
            dropZone = React.findDOMNode(this.refs.dropZone);

        this.uploader = this.getUploader({
            browse_button: uploadEl,
            drop_element: dropZone,
            filters: this.props.mime_types
        });
        this.uploader.init();
        this.uploader.settings.baseUrl = this.uploader.settings.url;
        this.uploader.bind('UploadProgress', this.onUploadProgress, this);
        this.uploader.bind('BeforeUpload', this.onBeforeUpload, this);
        this.uploader.bind('UploadComplete', this.onUploadComplete, this);
        this.uploader.bind('QueueChanged', this.updateQueueState, this);
        this.uploader.bind('Error', this.onError, this);

    },

    componentWillUnmount: function() {
        if(this.uploader) {
            this.uploader.unbindAll();
            this.uploader.destroy();
        }
    },

    updateQueueState: function(uploader) {
        var me = this;
        if (!this.props.multiple && uploader.files.length > 1) {
            uploader.splice(0, uploader.files.length - 1);
        }
        me.setState({
            files: uploader.files
        }, function() {
            me.requestChange(this.props.multiple ? [].concat(this.getValue(), uploader.files) : uploader.files[0]);
        });
    },

    requestChange: function(value) {
        var me = this;

        if (me.props.valueLink) {
            me.props.valueLink.requestChange(value);
        }

        if (me.onChangeTimeout) {
            clearTimeout(me.onChangeTimeout);
        }

        me.onChangeTimeout = setTimeout(function() {
            me.onChangeTimeout = undefined;
            me.onValidateEvent();
        }, 10);
    },

    startUpload: function() {
        var files = this.uploader.files;
        if (files.length > 0) {
            this.uploader.start();
        } else {
            if (typeof this.onComplete === 'function') {
                this.onComplete();
            }
        }
    },

    onBeforeUpload: function(uploader, file) {
        this.uploader.settings.url = this.uploader.settings.baseUrl + '/' + file.name;

        if (this.props.onBeforeUpload) {
            this.props.onBeforeUpload(uploader, file);
        }

        file.status = 'Uploading...';
        this.setState({
            files: this.uploader.files
        });
    },

    getUploader: function(options) {

        options = _.extend({
            url: this.props.url + '/file',
            multi_selection: this.props.multiple,
            runtimes: 'html5, html4',
            multipart: this.props.multipart,
            file_data_name: this.props.file_data_name,
            init: {
                FileUploaded: function(uploader, file, xhrResponse) {
                    var decodedResponse;
                    try {
                        decodedResponse = $.parseJSON(xhrResponse.response);
                    } catch (e) {}

                }
            }
        }, options || {});

        var uploader = new Uploader(options);

        uploader.bind("Init", function(up, params) {
            console.log('runtime is ' + params.runtime);
        });

        return uploader;
    },

    componentWillReceiveProps: function(nextProps) {
        var newValue = this.getValue(nextProps),
            value = this.getValue(this.props);

        if (this.props.url !== nextProps.url) {
            this.uploader.settings.baseUrl = nextProps.url + '/file';
        }

        if (newValue !== value) {
            this.setState({
                files: newValue
            });
        }
    },
    onError: function(up, xhrResponse) {
        var decodedResponse = $.parseJSON(xhrResponse.response);
        this.props.model && this.props.model.trigger("error", null, {
            responseJSON: decodedResponse
        });
    },

    onUploadProgress: function(up, file) {
        this.setState(function(previousState, currentProps) {
            previousState.uploadProgress = file.percent;
            return previousState;
        });
    },

    onUploadComplete: function(up, files) {
        var success = files[0] && files[0].status !== window.plupload.FAILED;

        this.setState(function(previousState, currentProps) {
            previousState.uploadProgress = undefined;
            up.files.splice(0, up.files.length);
            previousState.files = up.files;
            previousState.updateImage = true;

            return previousState;
        });

        if (typeof this.props.onComplete === 'function') {
            this.props.onComplete(success);
        }

        if (this.props.model && this.props.triggerSync) {
            this.props.model.trigger("sync", this.props.model, {}, {
                syncMethod: 'update'
            });
        }
    },

    setIdUrlPart: function(id) {
        this.uploader.settings.baseUrl = this.props.url + '/' + id + '/file';
    },

    hasFiles: function() {
        return this.uploader.files.length > 0;
    },

    onFileDelete: function(file, e) {
        var me = this,
            files = [].concat(me.getValue()),
            indexOfFile = files.indexOf(file);

        e.preventDefault();

        if (file.id) {
            file.status = -1;
            $.ajax({
                url: me.uploader.settings.baseUrl + '/' + file.id,
                method: 'DELETE'
            }).then(function() {
                files.splice(indexOfFile, 1);
                me.setState({
                    files: files
                });
                me.requestChange(me.props.multiple ? files : undefined);
            });
        } else {
            me.uploader.removeFile(file);
            files.splice(indexOfFile, 1);
        }

        me.setState({
            files: files
        });
    },

    render: function() {
        var me = this,
            props = me.props,
            state = me.state,
            value = me.getValue() ? [].concat(me.getValue()) : [],
            help = props.error || props.help,
            hasPreviewPane = props.showPreview && !props.multiple,
            hasFiles = value && value.length > 0 && value[0].link;
            //console.log('value', value);
        return (
            React.createElement("div", {className: me.getClassName()}, 
                React.createElement("section", null, 
                    props.label && (
                        React.createElement("label", {className: "control-label label"}, React.createElement("span", null, props.label))
                    ), 

                    React.createElement("div", {className: "row half-gutter"}, 
                        hasPreviewPane && (
                            React.createElement("div", {className: "col-xs-6 col-sm-4"}, 
                                _.map(value, function(file){
                                    return (
                                        React.createElement("div", {key: file.id || file.name, className: cx({'empty-image': !file.link})}, 
                                            React.createElement("a", {href: file.link, className: "fancybox", title: file.name}, 
                                                React.createElement(ImageThumbnail, {update: state.updateImage, onChange: me.onImageChange, className: "", innerClassName: "img-responsive img-center", 
                                                src: 
                                                    !!file.thumbLink ? file.thumbLink.replace('http://', 'https://').replace('//www.agentshield.com.s3.amazonaws.com', '//s3.amazonaws.com/www.agentshield.com') 
                                                    : (!!file.link) ? file.link.replace('http://', 'https://').replace('//www.agentshield.com.s3.amazonaws.com', '//s3.amazonaws.com/www.agentshield.com') : ''}), 
                                                React.createElement("span", {className: "block-ellipsis text-center"}, file.name)
                                            )
                                        )
                                    );
                                }), 
                                !value || value.length === 0 && (
                                    React.createElement("div", {className: "empty-image"}, 
                                        "No Image.", React.createElement("br", null), "Upload an image."
                                    )
                                )
                            )
                        ), 

                        React.createElement("div", {className: cx({ 'col-xs-6 col-sm-8' : hasPreviewPane})}, 
                            props.sizingText, 
                            props.instructionText, 
                            React.createElement("div", {className: cx('file-drop-zone'), style: {'textAlign': 'center'}, ref: "dropZone"}, 
                                !props.readOnly && (
                                    React.createElement("div", null, 
                                        React.createElement("div", null, props.dropZoneText, " ", React.createElement("a", {href: "#", ref: "fileUploadEl"}, "browse"), ".")
                                    )
                                ), 


                                React.createElement(BS.Row, {className: cx('margin-top-10', { 'margin-bottom-10' : value.length > 0 })}, 
                                    React.createElement(BS.Col, {xs: 12, sm: 6, smOffset: 3}, 
                                        props.showPreview && props.multiple && (
                                            React.createElement("div", {className: "row"}, 
                                                _.map(value, function(file){
                                                    return (
                                                        React.createElement("div", {key: file.id || file.name, className: "col-xs-6 col-sm-4"}, 
                                                            React.createElement("a", {href: file.link, className: "fancybox", title: file.name}, 
                                                                React.createElement(ImageThumbnail, {className: "", innerClassName: "img-responsive img-center", src: file.thumbLink || file.link}), 
                                                                React.createElement("span", {className: "block-ellipsis"}, file.name)
                                                            )
                                                        )
                                                    );
                                                })
                                            )
                                        ), 

                                        !props.showPreview && props.multiple && value.length > 0 && (
                                            React.createElement(BS.Table, {striped: true, condensed: true, bordered: true, className: "table-align-middle"}, 
                                                React.createElement("thead", null, 
                                                    React.createElement("tr", null, 
                                                        !props.readOnly && (React.createElement("th", {className: "text-center"}, "Status")), 
                                                        React.createElement("th", {className: "text-center"}, "Name"), 
                                                        !props.readOnly && (React.createElement("th", {className: "text-center"}, "Actions"))
                                                    )
                                                ), 
                                                React.createElement("tbody", null, 
                                                    _.map(value, function(file){
                                                        var status;

                                                        switch(file.status){
                                                            case 5:
                                                                status = 'Uploaded';
                                                            break;
                                                            case 2:
                                                                status = 'Uploading...';
                                                            break;
                                                            case -1:
                                                                status = 'Deleting...';
                                                            break;
                                                            case 1:
                                                            default:
                                                                status = 'Ready for Upload';
                                                            break;
                                                        }

                                                        return (
                                                            React.createElement("tr", {key: file.id || file.name}, 
                                                                !props.readOnly && (React.createElement("td", null, status)), 
                                                                React.createElement("td", null, React.createElement("a", {href: file.link, target: "_blank"}, file.name)), 
                                                                !props.readOnly && (
                                                                React.createElement("td", null, 
                                                                    React.createElement("a", {href: "#", onClick: me.onFileDelete.bind(me, file)}, 
                                                                        React.createElement("i", {className: "icon-custom icon-color-u icon-sm fa fa-trash-o"})
                                                                    )
                                                                )
                                                                )
                                                            )
                                                        );
                                                    })
                                                )
                                            )
                                            ), 
                                            !props.showPreview && !props.multiple && value.length > 0 && (
                                                (!value[0].link) ?
                                                    console.log('vAL LINK', value)
                                                :
                                                    console.log('vAL 0', value[0])
                                            ), 
                                            !props.showPreview && !props.multiple && value.length > 0 && (
                                                (!value[0].link) ?
                                                    React.createElement("a", {href: !!value[0].link ? value[0].link.replace('http://', '//').replace('//www.agentshield.com.s3.amazonaws.com', '//s3.amazonaws.com/www.agentshield.com') : '', target: "_blank"}, value[0].name ? value[0].name : "Current file")
                                                :
                                                    React.createElement("a", {href: value[0], target: "_blank"}, "Current file")
                                            )
                                        )
                                    )
    
                            )
                        )
                    ), 
                    help && (
                        React.createElement("span", {className: "help-block"}, help)
                    )
                ), 
                React.createElement(BS.Modal, {show: !!me.state.uploadProgress, onHide: me.closeDialog, dialogClassName: "loading-mask"}, 
                    React.createElement(BS.Modal.Header, null, 
                        React.createElement(BS.Modal.Title, null, "Uploading file")
                    ), 
                    React.createElement(BS.Modal.Body, null, 
                        React.createElement(BS.ProgressBar, {striped: true, bsStyle: "success", now: 100})
                    )
                )
            )
        );
    },

    onImageChange: function() {
        this.setState({
            updateImage: false
        });
    },

    componentDidUpdate: function() {
        if (this.props.showPreview) {
            $(React.findDOMNode(this)).find('.fancybox').fancybox();
        }

        this.uploader.multi_selection = this.props.multiple;
    },

    getValue: function(props) {
        var value;

        props = props || this.props;

        value = this.props.value;

        if (this.props.valueLink) {
            value = this.props.valueLink.value;
        }

        return value;
    },

    getClassName: function() {
        var props = this.props,
            state = this.state,
            className = cx({
                'form-group': props.formGroup,
                'has-feedback': !!props.error || props.hasFeedback,
                'has-error': !!props.error,
                'form-group-required': props.required && props.showRequired,
                'file-picker': true
            }, props.groupClassName);

        return className;
    },

    // Required prop of modal even though we specify when it hides
    closeDialog: function() {},

    reset: function() {
        this.uploader.splice(0, this.uploader.files.length);
    }
});

module.exports = FilePicker;
