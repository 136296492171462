'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

var _get = function get(_x, _x2, _x3) { var _again = true; _function: while (_again) { var object = _x, property = _x2, receiver = _x3; desc = parent = getter = undefined; _again = false; if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { _x = parent; _x2 = property; _x3 = receiver; _again = true; continue _function; } } else if ('value' in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } } };

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

function _inherits(subClass, superClass) { if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _monthMonthPanel = require('../month/MonthPanel');

var _monthMonthPanel2 = _interopRequireDefault(_monthMonthPanel);

var _gregorianCalendarFormat = require('gregorian-calendar-format');

var _gregorianCalendarFormat2 = _interopRequireDefault(_gregorianCalendarFormat);

var _yearYearPanel = require('../year/YearPanel');

var _yearYearPanel2 = _interopRequireDefault(_yearYearPanel);

var _rcUtil = require('rc-util');

var _rcUtil2 = _interopRequireDefault(_rcUtil);

var toFragment = _rcUtil2['default'].Children.mapSelf;

var CalendarHeader = (function (_React$Component) {
  _inherits(CalendarHeader, _React$Component);

  function CalendarHeader(props) {
    _classCallCheck(this, CalendarHeader);

    _get(Object.getPrototypeOf(CalendarHeader.prototype), 'constructor', this).call(this, props);
    this.state = {};
    this.yearFormatter = new _gregorianCalendarFormat2['default'](props.locale.yearFormat);
    this.monthFormatter = new _gregorianCalendarFormat2['default'](props.locale.monthFormat);
    this.showMonthPanel = this.showMonthPanel.bind(this);
    this.showYearPanel = this.showYearPanel.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  _createClass(CalendarHeader, [{
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      var locale = this.props.locale;
      if (nextProps.locale !== locale) {
        this.yearFormatter = new _gregorianCalendarFormat2['default'](locale.yearFormat);
        this.monthFormatter = new _gregorianCalendarFormat2['default'](locale.monthFormat);
      }
    }
  }, {
    key: 'onSelect',
    value: function onSelect(value) {
      this.setState({
        showMonthPanel: 0,
        showYearPanel: 0
      });
      this.props.onValueChange(value);
    }
  }, {
    key: 'getMonthYearElement',
    value: function getMonthYearElement() {
      var props = this.props;
      var prefixCls = props.prefixCls;
      var locale = props.locale;
      var value = this.props.value;
      var monthBeforeYear = locale.monthBeforeYear;
      var selectClassName = prefixCls + '-' + (monthBeforeYear ? 'my-select' : 'ym-select');
      var year = _react2['default'].createElement(
        'a',
        { className: prefixCls + '-year-select',
          role: 'button',
          onClick: this.showYearPanel,
          title: locale.monthSelect },
        this.yearFormatter.format(value)
      );
      var month = _react2['default'].createElement(
        'a',
        { className: prefixCls + '-month-select',
          role: 'button',
          onClick: this.showMonthPanel,
          title: locale.monthSelect },
        this.monthFormatter.format(value)
      );
      var my = [];
      if (monthBeforeYear) {
        my = [month, year];
      } else {
        my = [year, month];
      }
      return _react2['default'].createElement(
        'span',
        { className: selectClassName },
        toFragment(my)
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var props = this.props;
      var state = this.state;
      var prefixCls = props.prefixCls;
      var locale = props.locale;
      var value = props.value;
      var PanelClass = null;
      if (state.showMonthPanel) {
        PanelClass = _monthMonthPanel2['default'];
      } else if (state.showYearPanel) {
        PanelClass = _yearYearPanel2['default'];
      }
      var panel = undefined;
      if (PanelClass) {
        panel = _react2['default'].createElement(PanelClass, { locale: locale, defaultValue: value, rootPrefixCls: prefixCls, onSelect: this.onSelect });
      }
      return _react2['default'].createElement(
        'div',
        { className: prefixCls + '-header' },
        _react2['default'].createElement(
          'a',
          { className: prefixCls + '-prev-year-btn',
            role: 'button',
            onClick: props.previousYear,
            title: locale.previousYear },
          '«'
        ),
        _react2['default'].createElement(
          'a',
          { className: prefixCls + '-prev-month-btn',
            role: 'button',
            onClick: props.previousMonth,
            title: locale.previousMonth },
          '‹'
        ),
        this.getMonthYearElement(),
        _react2['default'].createElement(
          'a',
          { className: prefixCls + '-next-month-btn',
            onClick: props.nextMonth,
            title: locale.nextMonth },
          '›'
        ),
        _react2['default'].createElement(
          'a',
          { className: prefixCls + '-next-year-btn',
            onClick: props.nextYear,
            title: locale.nextYear },
          '»'
        ),
        panel
      );
    }
  }, {
    key: 'showMonthPanel',
    value: function showMonthPanel() {
      this.setState({
        showMonthPanel: 1,
        showYearPanel: 0
      });
    }
  }, {
    key: 'showYearPanel',
    value: function showYearPanel() {
      this.setState({
        showMonthPanel: 0,
        showYearPanel: 1
      });
    }
  }]);

  return CalendarHeader;
})(_react2['default'].Component);

exports['default'] = CalendarHeader;

CalendarHeader.propTypes = {
  locale: _react2['default'].PropTypes.object,
  value: _react2['default'].PropTypes.object,
  onValueChange: _react2['default'].PropTypes.func
};
module.exports = exports['default'];